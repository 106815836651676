/* Main container for the card section */
.main {
  width: 100%;
  padding: 24px 40px;
  box-sizing: border-box;
}

/* Container for flex layout and responsive cards */
.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px; /* Space between rows */
  max-width: 1200px; /* Limit the width of the container for larger screens */
  margin: 0 auto; /* Center the container within the page */
}

/* Card design with better size control */
.cards {
  width: 100%;
  max-width: 300px; /* Max width of each card */
  height: auto;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  gap: 16px;
}

.cards img {
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.links {
  padding: 10px;
  margin-top: 8px;
  border: none;
  text-align: center;
  border-radius: 4px;
  text-decoration: none;
  background-color: #dc3545;
  cursor: pointer;
}

.links .link {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}

.links:hover {
  background-color: #ab2f3c;
}
.cards:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@media (min-width: 1024px) {
  .cards {
    flex: 1 1 calc(33.33% - 24px); /* 3 cards per row with space between them */
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .cards {
    flex: 1 1 calc(50% - 24px); /* 2 cards per row with space between them */
  }
}

@media (max-width: 767px) {
  .cards {
    flex: 1 1 100%; /* Full-width cards */
  }
  .services {
    padding: 24px 0;
  }
}
