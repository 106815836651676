/*This is Handling the Outer Layout of Sider*/

.sider-disp-center {
  background-color: #1f2531;
  width: 100%;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.sider {
  height: 100vh;
  padding: 10px;
  width: 80%;
  left: 0;
  top: 0;

  background-color: #1f2531;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

/* This is handling the inner elements of the sider */
.sider-ele {
  width: 100%;
  height: 22px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #b4b4b4;

  display: flex;
  align-items: center;
}

/* Handling the Style and Font of SiderElement */
.sider-ele-tag {
  padding-left: 5px;
}

/* .sider-ele-nav {
  color: #f4f4f4;
} */

@media (max-width: 1030px) {
  .sider-disp-center {
    width: 110%;
  }

  .sider-ele {
    font-weight: 500;
    font-size: 9px;
  }
}
