/* screen - teacher-assignment-list-student */

.Assignment-name-in-desk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92%;
  margin: 10px 30px;
}

.Assignment-name-in-desk-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92%;

  margin: 10px 30px;
}

.Assignment-name-in-desk-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92%;
  margin: 10px 30px;
}

.Assignment-name-head-div-3 {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.Assignment-name-in-desk-4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92%;
  margin: 10px 30px;
}

.teacher-assignment-list-student .Assignment-name-head-div {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;

  width: 300px;
  margin-right: 70px;
}

.montserrat-semi-bold-blue-zodiac-18px {
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-white-12px {
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-assignment-list-student {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1224px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.teacher-assignment-list-student .accountbalance-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-assignment-list-student .poweroff-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
  margin-top: 100px;
}

.teacher-assignment-list-student .add-7y4ohx {
  background-color: transparent;
  height: auto;
  left: 316px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-assignment-list-student .add-RqwtHL {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 17.59px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 37px;
}
.teacher-assignment-list-student .add-delete-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 287px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 87px;
}
.teacher-assignment-list-student .analytics-24px-3-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-assignment-list-student .arrowback-24px-1-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-bottom: -10px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-assignment-list-student .assignment-1-submitted-jEma4X {
  background-color: transparent;
  height: auto;
  /* left: 26px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  /* top: 69px; */
  width: 99px;
}
.teacher-assignment-list-student .assignment-2-assigned-8dgkHt {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 69px;
  width: 99px;
}
.teacher-assignment-list-student .assignment-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-assignment-list-student .quiz-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-assignment-list-student .assignment-24px-3-KhDVDB {
  background-color: transparent;
  height: 18px;
  /* left: 9px; */
  overflow: hidden;
  /* position: absolute; */
  /* top: 9px; */
  width: 18px;
}
.teacher-assignment-list-student .assignment-24px-3-SlQdaF {
  background-color: transparent;
  height: 18px;
  left: 9px;
  overflow: hidden;
  position: absolute;
  top: 9px;
  width: 18px;
}
.teacher-assignment-list-student .back-to-all-stu-and-classrooms-GyJUNH {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 23px;
  min-height: 36px;
  position: relative;
  width: 100%;
}
.teacher-assignment-list-student .biba-sobti-JJC5aN {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-assignment-list-student .bibasobtigmailcom-JJC5aN {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-assignment-list-student .booked-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 53px;
}
.teacher-assignment-list-student .booked-959ciF {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 53px;
}
.teacher-assignment-list-student .choose-file-GC1et8 {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 66px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 61px;
}
.teacher-assignment-list-student .choose-from-exi-ing-assignments-gD87cZ {
  background-color: transparent;
  color: var(--blue-zodiac);

  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;

  margin-top: 1px;

  position: relative;
  /* text-align: left; */
  width: auto;
}
.teacher-assignment-list-student .choose-subject-i65718422371261-V1Il0C {
  align-self: center;
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-bottom: 3px;
  min-height: 12px;
  min-width: 97px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-assignment-list-student .class-name-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 93px;
}
.teacher-assignment-list-student .completed-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 200px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 79px;
}
.teacher-assignment-list-student .component-1-0xy0vn {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
  margin-left: 5%;
}
.teacher-assignment-list-student .component-29-8dgkHt {
  background-color: transparent;
  height: 36px;
  left: 56px;
  position: absolute;
  top: 16px;
  width: 36px;
}
.teacher-assignment-list-student .component-29-I0Q5xv {
  background-color: transparent;
  background-image: url(./img/ellipse-4-2@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-assignment-list-student .component-29-jEma4X {
  background-color: transparent;
  height: 36px;
  /* left: 56px;
  position: absolute;
  top: 16px; */
  width: 36px;
}
.teacher-assignment-list-student .component-30-gD87cZ {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 33px;
  justify-content: flex-start;
  margin-left: 32px;
  min-width: 191px;
  position: relative;
}
.teacher-assignment-list-student .description-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-assignment-list-student .ellipse-2-APAcB6 {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.teacher-assignment-list-student .ellipse-4-KhDVDB {
  background-color: transparent;
  height: 38px;
  /* left: -0px; */
  /* position: absolute; */
  /* top: -1px; */
  width: 37px;
}
.teacher-assignment-list-student .ellipse-4-SlQdaF {
  background-color: transparent;
  height: 38px;
  left: -0px;
  position: absolute;
  top: -1px;
  width: 37px;
}
.teacher-assignment-list-student .expandmore-24px-1-V1Il0C {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  margin-left: 52px;
  overflow: hidden;
  position: relative;
  width: 20px;
}
.teacher-assignment-list-student .feb-18-2021-IMMU6x {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 116px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 113px;
}
.teacher-assignment-list-student .flex-col-0sTO9E {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.teacher-assignment-list-student .flex-col-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 8%;
  margin-top: 45px;
  min-height: 879px;
  position: relative;
  width: 51%;
}
.teacher-assignment-list-student .flex-col-VMr6Om {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 6%;
  margin-top: 51px;
  min-height: 922px;
  position: relative;
  width: 40%;
}
.teacher-assignment-list-student .flex-row-0xy0vn {
  align-items: flex-end;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 60px;
  position: relative;
  width: 100%;
}
.teacher-assignment-list-student .flex-row-4iD7zR {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 44px;
  justify-content: flex-start;
  margin-top: 26px;
  position: relative;
  width: 100%;
}
.teacher-assignment-list-student .flex-row-WcGkdi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-student .flex-row-djxPV6 {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 23px;
  justify-content: flex-start;
  min-width: 377px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-student .flex-row-oV5UrE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 1;
  height: 167px;
  justify-content: space-evenly;
  margin-top: 49px;
  position: relative;
  width: 100%;
  overflow-x: scroll;
}
.teacher-assignment-list-student .flex-row-t6Autm {
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  margin-left: 32px;
  margin-top: 35px;
  min-width: 580px;
  position: relative;
  width: auto;
}

.Assignment-name-head-div-input {
  width: 210px;
  margin: 0px;
}

.teacher-assignment-list-student .flex-row-uPORJx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  margin-top: 35px;
  position: relative;
  width: 100%;
  min-height: 470px;
}
.teacher-assignment-list-student .give-a-new-assignment-ossvxU {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 44px;
  position: relative;
  width: 100%;
}
.teacher-assignment-list-student .group-112-djxPV6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 726px;
  margin-top: 14px;
  position: relative;
  width: 403px;
}
.teacher-assignment-list-student .group-113-8pxyib {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 763px;
  position: relative;
  width: 387px;
}
.teacher-assignment-list-student .group-114-lcFreP {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 763px;
  justify-content: flex-start;
  margin-top: 79px;
  min-width: 383px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-student .group-115-lcFreP {
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  min-width: 376px;
  position: relative;
  width: auto;
  margin: auto;
}
.teacher-assignment-list-student .group-146-jEma4X {
  background-color: transparent;
  height: 18px;
  left: 38px;
  position: absolute;
  top: 120px;
  width: 74px;
}
.teacher-assignment-list-student .group-160-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 88px;
  min-width: 24px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-student .group-162-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 778px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.teacher-assignment-list-student .group-163-L8UHM8 {
  background-color: #1f2531;
  height: 1224px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 63px;
}
.teacher-assignment-list-student .group-164-L8UHM8 {
  background-color: transparent;
  height: 778px;
  left: 20px;
  position: absolute;
  top: 200px;
  width: 24px;
}
.teacher-assignment-list-student .group-165-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 1024px;
  position: relative;
  width: 63px;
}
.teacher-assignment-list-student .group-167-y1HRxC {
  background-color: transparent;
  height: 36px;
  left: 25px;
  position: absolute;
  top: 16px;
  width: 36px;
}
.teacher-assignment-list-student .group-22-cLbj0f {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  min-width: 316px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-student .group-46-y1HRxC {
  background-color: transparent;
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-assignment-list-student .group-57-QkTteQ {
  background-color: transparent;
  flex-shrink: 1;
  height: 155px;
  position: relative;
  width: 151px;
}
.teacher-assignment-list-student .group-58-QkTteQ {
  background-color: transparent;
  flex-shrink: 1;
  height: 155px;
  margin-left: 68px;
  position: relative;
  width: 153px;
}
.teacher-assignment-list-student .group-90-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 174px;
  width: 369px;
}
.teacher-assignment-list-student .group-91-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 371px;
}
.teacher-assignment-list-student .group-92-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 369px;
}
.teacher-assignment-list-student .group-93-8MUoy6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 11px;
  min-height: 262px;
  position: absolute;
  top: 133px;
  width: 371px;
}
.teacher-assignment-list-student .group-94-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 369px;
}
.teacher-assignment-list-student .group-95-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 369px;
}
.teacher-assignment-list-student .group-96-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 369px;
}
.teacher-assignment-list-student .group-97-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 587px;
  width: 369px;
}
.teacher-assignment-list-student .group-98-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 646px;
  width: 369px;
}
.teacher-assignment-list-student .group-9VbO77 {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  position: relative;
  width: 20px;
}
.teacher-assignment-list-student .group-FHHSuW {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-assignment-list-student .group-UKbWOd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.teacher-assignment-list-student .group-alxHTq {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-assignment-list-student .group-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: flex-start;
  left: 0px;
  min-width: 20px;
  position: absolute;
  top: 448px;
  width: auto;
}
.teacher-assignment-list-student .group-tQmilE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.teacher-assignment-list-student .help-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-assignment-list-student .historytoggleoff-24px-1-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-assignment-list-student .line-5-HhoarN {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.teacher-assignment-list-student .line-6-HhoarN {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.teacher-assignment-list-student .notifications-24px-2-1-cLbj0f {
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.teacher-assignment-list-student .open-8dgkHt {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 6px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 66px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 124px;
  width: 20px;
}
.teacher-assignment-list-student .open-calendar-IMMU6x {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.teacher-assignment-list-student .open-nxpXxz {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 6px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 27px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 4px;
  width: 20px;
}
.teacher-assignment-list-student .opened-1ihGMx {
  background-color: transparent;
  height: auto;
  left: 304px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 53px;
}
.teacher-assignment-list-student .opened-K0vR9a {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-assignment-list-student .opened-SbEpTm {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-assignment-list-student .opened-TYZK0W {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-assignment-list-student .opened-aOipQ0 {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-assignment-list-student .opened-oArczI {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-assignment-list-student .opened-xEcNns {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-assignment-list-student .or-0xy0vn {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 165px;
  margin-top: 44px;
  min-height: 22px;
  min-width: 31px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-assignment-list-student .organic-4-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 67px;
}
.teacher-assignment-list-student .organic-5-wTq9AN {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 67px;
}
.teacher-assignment-list-student .overlap-group-0xy0vn {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-top: 54px;
  position: relative;
  width: 100%;
}
.teacher-assignment-list-student .overlap-group-RqwtHL {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 365px;
}
.teacher-assignment-list-student .overlap-group1-RgyNpo {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  /* margin-left: 109px; */
  position: relative;
  width: 210px;
}
.teacher-assignment-list-student .overlap-group2-0xy0vn {
  align-self: center;
  background-color: transparent;
  height: 33px;
  margin-right: 120px;
  margin-top: 47px;
  position: relative;
  width: 191px;
}
.teacher-assignment-list-student .rectangle-31-8MUoy6 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 726px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.teacher-assignment-list-student .rectangle-40-8MUoy6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.teacher-assignment-list-student .rectangle-40-8dgkHt {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 155px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 149px;
}
.teacher-assignment-list-student .rectangle-40-DxZ3Ki {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-assignment-list-student .rectangle-40-jEma4X {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 155px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 149px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.teacher-assignment-list-student .rectangle-41-8MUoy6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.teacher-assignment-list-student .rectangle-41-8dgkHt {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 18px;
  left: 38px;
  position: absolute;
  top: 120px;
  width: 72px;
}
.teacher-assignment-list-student .rectangle-41-nxpXxz {
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 18px;
  /* left: 0px;
  position: absolute;
  top: 0px; */
  width: 72px;
}
.teacher-assignment-list-student .rectangle-42-GC1et8 {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 2px 2px #ccc;
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 210px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;

  text-align: center;
  padding: 10px;
  color: #dc3545;
}
.teacher-assignment-list-student .rectangle-43-52Wjwg {
  background-color: var(--blue-zodiac);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 33px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 191px;
}
.teacher-assignment-list-student .rectangle-44-K0vR9a {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-assignment-list-student .rectangle-44-SbEpTm {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-assignment-list-student .rectangle-44-TYZK0W {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-assignment-list-student .rectangle-44-aOipQ0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-assignment-list-student .rectangle-44-oArczI {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-assignment-list-student .rectangle-44-xEcNns {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-assignment-list-student .rectangle-47-8MUoy6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.teacher-assignment-list-student .rectangle-48-wTq9AN {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-assignment-list-student .rectangle-49-1ihGMx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-assignment-list-student .rectangle-50-7y4ohx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-assignment-list-student .rectangle-7-ossvxU {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-bottom: 9.73px;
  margin-left: 43px;
  position: relative;
  width: 1px;
}
.teacher-assignment-list-student .school-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-assignment-list-student .span0-90XWKj {
  color: black;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.teacher-assignment-list-student .span0-PJpsBj {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-assignment-list-student .span0-id3RTJ {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-assignment-list-student .span0-x4zmOI {
  color: black;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.teacher-assignment-list-student .span1-90XWKj {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.teacher-assignment-list-student .span1-PJpsBj {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-assignment-list-student .span1-id3RTJ {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-assignment-list-student .span1-x4zmOI {
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.teacher-assignment-list-student .status-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 208px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.teacher-assignment-list-student .stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-assignment-list-student .submit-52Wjwg {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 66px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 61px;
}
.teacher-assignment-list-student .time-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.teacher-assignment-list-student .upcoming-wTq9AN {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 76px;
}
.teacher-assignment-list-student .upload-a-new-assignment-RgyNpo {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 3px;
  min-height: 22px;
  min-width: 238px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-assignment-list-student .vatsal-agarwal-DxZ3Ki {
  background-color: transparent;
  color: black;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 25px;
  width: 181px;
}
.teacher-assignment-list-student .vector-0sTO9E {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.teacher-assignment-list-student .vector-3ahjCQ {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-assignment-list-student .vector-4dJ36g {
  background-color: transparent;
  height: 1px;
  left: 9px;
  position: absolute;
  top: 9px;
  width: 1px;
}
.teacher-assignment-list-student .vector-56AgMz {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.teacher-assignment-list-student .vector-5TSepZ {
  background-color: transparent;
  height: 1px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.teacher-assignment-list-student .vector-5yVVio {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-assignment-list-student .vector-8k85HE {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 170px;
  position: relative;
  width: 24px;
}
.teacher-assignment-list-student .vector-90KxxC {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-assignment-list-student .vector-9xgjqN {
  background-color: transparent;
  height: 14px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.teacher-assignment-list-student .vector-APAcB6 {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.teacher-assignment-list-student .vector-B8xRgA {
  background-color: transparent;
  height: 16px;
  left: 0px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-assignment-list-student .vector-BKwYFx {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.teacher-assignment-list-student .vector-Chnqxl {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.teacher-assignment-list-student .vector-EaMiW0 {
  background-color: transparent;
  height: 20px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 18px;
}
.teacher-assignment-list-student .vector-I0Q5xv {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-assignment-list-student .vector-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-assignment-list-student .vector-L9lx3B {
  background-color: transparent;
  height: 16px;
  /* left: 2px;
  position: absolute;
  top: 0px; */
  width: 15px;
}
.teacher-assignment-list-student .vector-Ni3obm {
  background-color: transparent;
  height: 16px;
  left: 2px;
  position: absolute;
  top: 0px;
  width: 15px;
}
.teacher-assignment-list-student .vector-SCg0eG {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  position: relative;
  width: 24px;
}
.teacher-assignment-list-student .vector-SIAzDu {
  background-color: transparent;
  height: 20px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 16px;
}
.teacher-assignment-list-student .vector-TCNT1O {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-assignment-list-student .vector-UgkvXI {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-assignment-list-student .vector-WcGkdi {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.teacher-assignment-list-student .vector-XV2kdn {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.teacher-assignment-list-student .vector-dlRKJA {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-assignment-list-student .vector-fPPgx3 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-assignment-list-student .vector-grLYV9 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-assignment-list-student .vector-iniHBi {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-assignment-list-student .vector-jWA1w5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.teacher-assignment-list-student .vector-kCLhu1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-assignment-list-student .vector-mju0OS {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-assignment-list-student .vector-nQxC9t {
  background-color: transparent;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 7px;
  width: 10px;
}
.teacher-assignment-list-student .vector-oRqxWx {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-assignment-list-student .vector-s2Y5x6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.teacher-assignment-list-student .vector-uzm8xK {
  background-color: transparent;
  height: 1px;
  left: 9px;
  position: absolute;
  top: 9px;
  width: 1px;
}
.teacher-assignment-list-student .vector-xbu9jm {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.teacher-assignment-list-student .vector-yQhZ26 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-assignment-list-student .vector-zxrhU8 {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-assignment-list-student .x10pm-SbEpTm {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 45px;
}
.teacher-assignment-list-student .x12pm-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 47px;
}
.teacher-assignment-list-student .x1pm-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 133px;
  width: 33px;
}
.teacher-assignment-list-student .x2pm-1ihGMx {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 39px;
}
.teacher-assignment-list-student .x3pm-wTq9AN {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 31px;
}
.teacher-assignment-list-student .x4pm-oArczI {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-assignment-list-student .x5pm-7y4ohx {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-assignment-list-student .x6pm-K0vR9a {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-assignment-list-student .x7pm-TYZK0W {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-assignment-list-student .x8pm-aOipQ0 {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-assignment-list-student .x9pm-xEcNns {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - teacher-assignment-list-mobile */

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-white-12px {
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-assignment-list-mobile {
  align-items: center;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 2006px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 17px;
  position: relative;
  width: 414px;
}
.teacher-assignment-list-mobile .add-JxMwXJ {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 30.24px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 35px;
}
.teacher-assignment-list-mobile .add-delete-ZkK6i9 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 22px;
  min-height: 21px;
  position: relative;
  text-align: left;
  width: 92px;
}
.teacher-assignment-list-mobile .add-nFGcNF {
  background-color: transparent;
  height: auto;
  left: 283px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-assignment-list-mobile .arrowback-24px-1-bxkR86 {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-bottom: 2px;
  overflow: hidden;
  position: relative;
  margin-top: 12px;
  width: 24px;
}
.teacher-assignment-list-mobile .assignment-1-submitted-ggUFIx {
  background-color: transparent;
  height: auto;
  /* left: 34px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute;
  top: 85px; */
  width: 129px;
}
.teacher-assignment-list-mobile .assignment-2-graded-rMbmmK {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 85px;
  width: 129px;
}
.teacher-assignment-list-mobile .assignment-24px-3-Qhfvqx {
  background-color: transparent;
  height: 22px;
  left: 12px;
  overflow: hidden;
  position: absolute;
  top: 11px;
  width: 24px;
}
.teacher-assignment-list-mobile .assignment-24px-3-YFw2SD {
  background-color: transparent;
  height: 22px;
  left: 12px;
  overflow: hidden;
  position: absolute;
  top: 11px;
  width: 24px;
}
.teacher-assignment-list-mobile .back-to-all-students-bxkR86 {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 16px;
  min-height: 36px;
  position: relative;
  width: 324px;
}
.teacher-assignment-list-mobile .booked-tD1Dnp {
  background-color: transparent;
  height: auto;
  left: 283px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 50px;
}
.teacher-assignment-list-mobile .booked-xTp40i {
  background-color: transparent;
  height: auto;
  left: 283px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 204px;
  width: 50px;
}
.teacher-assignment-list-mobile .choose-file-nqcpBp {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 66px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 61px;
}
.teacher-assignment-list-mobile .choose-from-exi-ing-assignments-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  /* flex-shrink: 1; */
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* height: auto;
  letter-spacing: 0px;
  line-height: normal; */
  margin-right: 2px;
  margin-top: 25px;
  margin-bottom: 7px;
  /* min-height: 17px; */
  min-width: 246px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-assignment-list-mobile .choose-subject-i65717352371261-02ks1v {
  align-self: center;
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-bottom: 3px;
  min-height: 12px;
  min-width: 97px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-assignment-list-mobile .class-name-ZkK6i9 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 20px;
  min-height: 21px;
  position: relative;
  text-align: left;
  width: 94px;
}
.teacher-assignment-list-mobile .completed-xTp40i {
  background-color: transparent;
  height: auto;
  left: 183px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 74px;
}
.teacher-assignment-list-mobile .component-1-dXBp8909 {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.teacher-assignment-list-mobile .component-29-ggUFIx {
  background-color: transparent;
  height: 44px;
  left: 73px;
  position: absolute;
  top: 20px;
  width: 47px;
}
.teacher-assignment-list-mobile .component-29-rMbmmK {
  background-color: transparent;
  height: 44px;
  left: 73px;
  position: absolute;
  top: 20px;
  width: 47px;
}
.teacher-assignment-list-mobile .component-30-C61RwL {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 33px;
  justify-content: flex-start;
  margin-right: 3px;
  margin-top: 16px;
  min-width: 191px;
  padding: 6px 11px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.teacher-assignment-list-mobile .ellipse-4-Qhfvqx {
  background-color: transparent;
  height: 45px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 48px;
}
.teacher-assignment-list-mobile .ellipse-4-YFw2SD {
  background-color: transparent;
  height: 45px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 48px;
}
.teacher-assignment-list-mobile .expandmore-24px-1-02ks1v {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  margin-left: 52px;
  overflow: hidden;
  position: relative;
  width: 20px;
}
.teacher-assignment-list-mobile .feb-18-2021-iaim4d {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 87px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 117px;
}
.teacher-assignment-list-mobile .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-mobile .flex-row-CWcvtF {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 21px;
  justify-content: flex-start;
  margin-right: 2.63px;
  margin-top: 33px;
  min-width: 340px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-mobile .flex-row-RrQxtm {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 23px;
  justify-content: flex-start;
  margin-left: 3px;
  min-width: 353px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-mobile .give-a-new-assignment-XvbDcl {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 36px;
  position: relative;
  width: 363px;
}
.teacher-assignment-list-mobile .group-145-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 36px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 52px;
  min-width: 364px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-mobile .group-147-EaZ6o4 {
  background-color: transparent;
  flex-shrink: 1;
  height: 190px;
  position: relative;
  width: 198px;
  margin-top: 10px;
}
.teacher-assignment-list-mobile .group-147-tSPgja {
  background-color: transparent;
  flex-shrink: 1;
  height: 190px;
  position: relative;
  width: 198px;
}
.teacher-assignment-list-mobile .group-148-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 190px;
  justify-content: flex-start;
  margin-right: 4px;
  margin-top: 85px;
  min-width: 194px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-mobile .group-149-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 190px;
  justify-content: flex-start;
  margin-right: 4px;
  margin-top: 55px;
  min-width: 194px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-mobile .group-150-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 36px;
  justify-content: flex-start;
  margin-left: 1px;
  margin-top: 80px;
  min-width: 363px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-mobile .group-151-C61RwL {
  /* background-color: transparent; */
  flex-shrink: 1;
  height: 33px;
  margin-left: 7px;
  margin-top: 12px;
  margin-bottom: 4px;
  position: relative;
  width: 193px;

  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 2px 2px #ccc;
  /* height: 33px;
  left: 0px;
  position: absolute;
  top: 0px; */
  /* width: 191px; */
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;

  text-align: center;
  padding: 7px;
  color: #dc3545;
}
.teacher-assignment-list-mobile .group-152-2K5hL5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 658px;
  position: relative;
  width: 371px;
}
.teacher-assignment-list-mobile .group-153-RrQxtm {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 658px;
  justify-content: flex-start;
  margin-top: 2px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-mobile .group-154-C61RwL {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 35px;
  min-height: 738px;
  position: relative;
  width: 371px;
}
.teacher-assignment-list-mobile .group-46-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  margin-left: 1px;
  margin-top: 43px;
  position: relative;
  width: 363px;
}
.teacher-assignment-list-mobile .group-58-Kb6fuY {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 190px;
  justify-content: flex-start;
  min-width: 194px;
  position: relative;
  width: auto;
}
.teacher-assignment-list-mobile .group-58-z6Eanu {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 1;
  height: 190px;
  justify-content: space-around;
  min-width: 194px;
  position: relative;
  width: auto;
  overflow: auto;
}
.teacher-assignment-list-mobile .group-90-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 128px;
  width: 346px;
}
.teacher-assignment-list-mobile .group-91-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 6px;
  position: absolute;
  top: 187px;
  width: 348px;
}
.teacher-assignment-list-mobile .group-92-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 6px;
  position: absolute;
  top: 245px;
  width: 346px;
}
.teacher-assignment-list-mobile .group-93-xTp40i {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 8px;
  min-height: 262px;
  position: absolute;
  top: 86px;
  width: 348px;
}
.teacher-assignment-list-mobile .group-94-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 363px;
  width: 346px;
}
.teacher-assignment-list-mobile .group-95-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 422px;
  width: 346px;
}
.teacher-assignment-list-mobile .group-96-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 481px;
  width: 346px;
}
.teacher-assignment-list-mobile .group-97-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 540px;
  width: 346px;
}
.teacher-assignment-list-mobile .group-98-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 599px;
  width: 346px;
}
.teacher-assignment-list-mobile .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.teacher-assignment-list-mobile .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.teacher-assignment-list-mobile .mathematics-class-9-AvtzuR {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-assignment-list-mobile .open-calendar-iaim4d {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.teacher-assignment-list-mobile .open-ggUFIx {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 153px;
  width: 29px;
}
.teacher-assignment-list-mobile .open-rMbmmK {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 153px;
  width: 29px;
}
.teacher-assignment-list-mobile .opened-1VCCbO {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-assignment-list-mobile .opened-As88TX {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-assignment-list-mobile .opened-K48Bcv {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.teacher-assignment-list-mobile .opened-TKEXTm {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-assignment-list-mobile .opened-WWbugp {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-assignment-list-mobile .opened-cwGgeD {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-assignment-list-mobile .opened-sA7dyK {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-assignment-list-mobile .or-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 7px;
  margin-top: 20px;
  min-height: 17px;
  min-width: 25px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-assignment-list-mobile .organic-4-xTp40i {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 69px;
}
.teacher-assignment-list-mobile .organic-5-EpciUK {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.teacher-assignment-list-mobile .overlap-group-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  /* margin-left: 5px; */
  margin-top: 5px;
  position: relative;
  width: 191px;
}
.merge-in-input {
  width: 60%;
  margin: 5px;
}

.teacher-assignment-list-mobile .overlap-group1-JxMwXJ {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 342px;
}
.teacher-assignment-list-mobile .rectangle-31-xTp40i {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 658px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-assignment-list-mobile .rectangle-40-AvtzuR {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-assignment-list-mobile .rectangle-40-ggUFIx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 190px;
  /* left: 0px;
  position: absolute;
  top: 0px; */
  width: 194px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.teacher-assignment-list-mobile .rectangle-40-rMbmmK {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 190px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 194px;
}
.teacher-assignment-list-mobile .rectangle-40-xTp40i {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 7px;
  position: absolute;
  top: 10px;
  width: 342px;
}
.teacher-assignment-list-mobile .rectangle-41-ggUFIx {
  /* background-color: var(--white); */
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 22px;
  /* left: 65px;
  position: absolute;
  top: 146px; */
  width: 72px;
}
.teacher-assignment-list-mobile .rectangle-41-rMbmmK {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 22px;
  left: 65px;
  position: absolute;
  top: 146px;
  width: 72px;
}
.teacher-assignment-list-mobile .rectangle-41-xTp40i {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 6px;
  position: absolute;
  top: 128px;
  width: 342px;
}
.teacher-assignment-list-mobile .rectangle-42-nqcpBp {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 2px 2px #ccc;
  height: 33px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 191px;
}
.teacher-assignment-list-mobile .rectangle-43-4eduM0 {
  background-color: var(--blue-zodiac);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 33px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 191px;
}
.teacher-assignment-list-mobile .rectangle-44-1VCCbO {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-assignment-list-mobile .rectangle-44-As88TX {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-assignment-list-mobile .rectangle-44-TKEXTm {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-assignment-list-mobile .rectangle-44-WWbugp {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-assignment-list-mobile .rectangle-44-cwGgeD {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-assignment-list-mobile .rectangle-44-sA7dyK {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-assignment-list-mobile .rectangle-47-xTp40i {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 7px;
  position: absolute;
  top: 69px;
  width: 342px;
}
.teacher-assignment-list-mobile .rectangle-48-EpciUK {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-assignment-list-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.teacher-assignment-list-mobile .rectangle-49-K48Bcv {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-assignment-list-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-assignment-list-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-assignment-list-mobile .rectangle-50-nFGcNF {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-assignment-list-mobile .span0-8H9rje {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.teacher-assignment-list-mobile .span0-f1YMcf {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-assignment-list-mobile .span0-gfYSUz {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-assignment-list-mobile .span0-icxssQ {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.teacher-assignment-list-mobile .span1-8H9rje {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.teacher-assignment-list-mobile .span1-f1YMcf {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-assignment-list-mobile .span1-gfYSUz {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-assignment-list-mobile .span1-icxssQ {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.teacher-assignment-list-mobile .status-ZkK6i9 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 19px;
  min-height: 21px;
  position: relative;
  text-align: left;
  width: 49px;
}
.teacher-assignment-list-mobile .submit-4eduM0 {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 66px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 11px;
  width: 61px;
}
.teacher-assignment-list-mobile .time-ZkK6i9 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 21px;
  position: relative;
  text-align: left;
  width: 42px;
}
.teacher-assignment-list-mobile .upcoming-EpciUK {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.teacher-assignment-list-mobile .upload-a-new-assignment-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2px;
  margin-top: 10px;
  min-height: 17px;
  min-width: 186px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-assignment-list-mobile .vatsal-agarwal-AvtzuR {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 140px;
}
.teacher-assignment-list-mobile .vector-0sFba3 {
  background-color: transparent;
  height: 19px;
  /* left: 3px;
  position: absolute;
  top: 1px; */
  width: 18px;
}
.teacher-assignment-list-mobile .vector-5FRq6D {
  background-color: transparent;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 7px;
  width: 10px;
}
.teacher-assignment-list-mobile .vector-8zdz4v {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-assignment-list-mobile .vector-9DUZ6p {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-assignment-list-mobile .vector-Y2V8yh {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-assignment-list-mobile .vector-bUPQ9O {
  background-color: transparent;
  height: 19px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 18px;
}
.teacher-assignment-list-mobile .vector-tDx0rx {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-assignment-list-mobile .vector-wGeoOk {
  background-color: transparent;
  height: 1px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.teacher-assignment-list-mobile .x10pm-WWbugp {
  background-color: transparent;
  height: auto;
  left: 7px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 42px;
}
.teacher-assignment-list-mobile .x12pm-xTp40i {
  background-color: transparent;
  height: auto;
  left: 16px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 44px;
}
.teacher-assignment-list-mobile .x1pm-xTp40i {
  background-color: transparent;
  height: auto;
  left: 16px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 86px;
  width: 31px;
}
.teacher-assignment-list-mobile .x2pm-K48Bcv {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.teacher-assignment-list-mobile .x3pm-EpciUK {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.teacher-assignment-list-mobile .x4pm-As88TX {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-assignment-list-mobile .x5pm-nFGcNF {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-assignment-list-mobile .x6pm-cwGgeD {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-assignment-list-mobile .x7pm-TKEXTm {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-assignment-list-mobile .x8pm-1VCCbO {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-assignment-list-mobile .x9pm-sA7dyK {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}

.teacher-assignment-list-student .historytoggleoff-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .teacher-assignment-list-student {
    display: none;
  }

  .Assignment-name-head-div-3 {
    font-size: 12px;
    margin: 6px;
  }

  .Assignment-name-head-div {
    font-size: 12px;
    margin: 6px;
    width: auto;
  }

  .Assignment-name-in-desk-3,
  .Assignment-name-in-desk-4,
  .Assignment-name-in-desk-2,
  .Assignment-name-in-desk {
    flex-direction: column;
    width: 92%;
    margin: 5px;
  }
}
@media screen and (min-width: 600px) {
  .teacher-assignment-list-mobile {
    display: none;
  }
}
