.card-011-1 {
  border-radius: 14px;
  z-index: 10;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input {
  margin: 0 auto;
  width: 100%;
  height: 35px;
  padding-left: 10px;
  border-radius: 5px;
  border: 2px solid rgba(217, 83, 79, 0.4);
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat", Helvetica;
}

textarea {
  padding: 15px 10px;
  margin: 0 auto;
  width: 100%;
  min-height: 35px;

  border-radius: 5px;
  border: 2px solid rgba(217, 83, 79, 0.4);
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat", Helvetica;
}

.row {
  margin-bottom: 30px;
}

.send_otp {
  width: 25%;
  height: 45px;
  margin-bottom: 3px;
  font-size: 10px;
  padding-bottom: 4px;
  color: white;
  overflow: hidden;
  font-family: "Montserrat", Helvetica;
  font-weight: 600;
  margin-left: 10px;
}

.submit {
  border-radius: 9px;
  color: white;
  width: 20%;
  margin: 0 auto;
}

.login-modal {
  width: 40%;
  height: 500px;
  margin: auto;
  margin-top: 120px;
  background-color: whitesmoke;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  padding: 24px 0;
}

.login-modal .login-modal-title {
  width: 70%;
  margin: auto;
  text-align: center;
  margin-bottom: 24px;
}

@media screen and (max-width: 1200px) {
  .card-011-1 {
    margin: 50px 100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .card-011-1 {
    margin: 50px 50px;
  }
}

@media screen and (max-width: 768px) {
  .card-011-1 input {
    margin-bottom: 20px;
  }

  .input2 {
    margin-bottom: 0px;
  }

  .submit {
    width: 30%;
  }
}

@media screen and (max-width: 600px) {
  .card-011-1 {
    margin: 50px 30px;
    padding: 40px 30px;
  }

  .submit {
    width: 100%;
  }
  .submit2 {
    width: 100% !important;
  }
  .input3 {
    width: 100% !important;
  }

  .bot-mar {
    margin-bottom: 10px;
  }

  .dropdown1 {
    margin-bottom: 20px;
  }
  .send_otp {
    width: 35%;
    height: 30px;
    margin-top: 8px;
    margin-left: 2px;
  }

  .div-class-otp-modal {
    width: 90%;
    min-width: 285px;
  }

  .margin-Sjdf21 {
    width: 80%;
    min-width: 214px;
  }

  .Display-but-reSend {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 450;
    font-size: 12px;
  }

  .Display-but-SendOtp:enabled {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 450;
    font-size: 12px;
  }

  .Display-but-SendOtp:disabled {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 450;
    font-size: 12px;
  }
}

.div-class-otp-modal-outline {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 600;

  transition: transform 0.1s ease-out;
}

.Open {
  transform: scale(1);
}

.Close {
  transform: scale(0);
}

.div-class-otp-modal {
  width: 30%;
  height: auto;
  min-height: 300px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.display-otp-inp-cont {
  width: auto;
  text-align: center;
}

.enter-otp-indic23 {
  width: auto;
  height: 40px;
  margin: 8px 0px;

  font-size: 14px;
  font-weight: 600;
  color: #dc3545;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.margin-Sjdf21 {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Display-but-reSend {
  border: 1px solid #dc3545;
  border-radius: 5px;
  background-color: white;
  color: #dc3545;
  padding: 5px;
  min-height: 30px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.Display-but-SendOtp:enabled {
  border: 1px solid #dc3545;
  border-radius: 5px;
  background-color: #dc3545;
  color: white;
  padding: 5px;
  min-height: 30px;
  margin-left: 5px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.Display-but-SendOtp:disabled {
  border: none;
  border-radius: 5px;
  background-color: #a0414aa7;
  color: white;
  padding: 5px;
  min-height: 32px;
  margin-left: 5px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  cursor: not-allowed;
}

.wrong-otp-error {
  padding: 5px;
  margin-bottom: 5px;
  font-size: 10px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #dc3545;
}

.toggleButton {
  background-color: #f9f9f9;
  color: #000; /* Default text color */
  border: none; /* Default border */
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin: 0 5px;
  transition: all 0.3s ease;
  font-weight: bold;
}

.toggleButton:hover {
  background-color: #dc3545; /* Hover background */
}

.activeButton {
  background-color: #dc3545; /* Active background */
  color: #fff; /* Active text color */
}
h1 {
  font-size: 24px;
  font-weight: 700;
  color: #dc3545;
  text-align: center;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .phno {
    width: 100%;
  }
}
