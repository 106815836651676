.side-popup-1 #popup {
    position: fixed;
    right: 20px;
    bottom: 30px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #ec3545;
    z-index: 100;
    border: 1px solid rgb(236, 53, 69);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  }
  
.side-popup-1 #popup i {
    color: white;
    font-size: 25px;
  }
  
.side-popup-1 #popupbox {
    visibility: hidden;
    height: 220px;
    width: 220px;
    position: fixed;
    right: 20px;
    bottom: 0;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    background-color: white;
    border-radius: 8px;
    
    transition: all .5s ease-in-out;
    opacity: 0;
    z-index: -1;
  }
  
.side-popup-1 .disp {
    visibility: visible !important;
    z-index: 300 !important;
    opacity: 1 !important;
    bottom: 100px !important;
  }
  
.side-popup-1 #popupbox .heading {
    text-align: center;
    background-color: #ec3545;
    height: 45px;
    padding: 10px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

.side-popup-1 #popupbox .heading p {
      color: white;
      font-size: 25px;
      font-weight: 400;
      width: 100%;
  }
  
.side-popup-1 #popup2 {
    position: fixed;
    right: 20px;
    bottom: 120px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #ec3545;
    z-index: 100;
    border: 1px solid #ec3545;
    transition: all .5s ease-in-out;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  }
  
.side-popup-1 #popup2 i {
    color: white;
    font-size: 25px;
  }
  
.side-popup-1 #popupbox2 {
    visibility: hidden;
    height: 300px;
    width: 220px;
    position: fixed;
    right: 20px;
    bottom: 100px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    background-color: white;
    border-radius: 8px;
  
    transition: all .5s ease-in-out;
    opacity: 0;
    z-index: -1;
  }
  
.side-popup-1 #popupbox2 .heading {
    text-align: center;
    background-color: #ec3545;
    height: 45px;
    padding: 10px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

.side-popup-1 #popupbox2 .heading p {
    color: white;
    font-size: 25px;
    font-weight: 400;
    width: 100%;
  }
  
.side-popup-1 .change1 {
    bottom: 340px !important;
  }
  
.side-popup-1 .change2.disp2 {
    visibility: visible !important;
    z-index: 300 !important;
    opacity: 1 !important;
    bottom: 410px !important;
  }
  
.side-popup-1 .disp2 {
    visibility: visible !important;
    z-index: 300 !important;
    opacity: 1 !important;
    bottom: 190px !important;
  }

.side-popup-1  #popupbox .list1 {
    height: 159px;
  }

.side-popup-1 .list1 li {
    padding: 10px 0;
    box-shadow: 0 8px 10px -6px rgba(0, 0, 0, 0.2);
    margin: 5px 10px;
    text-align: center;
  }

.side-popup-1 .contact_list > li:hover {
    background-color: #ec3545;
    color: white;
    margin: 5px 0;
  }

.side-popup-1 .bold {
      display: inline;
      font-weight: 550;
      font-style: italic;
  }

.side-popup-1 .support-text {
      padding: 10px;
      height: 218px;
      overflow: auto;
  }

.side-popup-1 .support-text textarea {
    width: 100%;
    border-style: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 5px;
  }

.side-popup-1 .support-submit {
      margin: 10px 0 10px 58px;
      color: white;
      background-color: #ec3545;
      border-style: none;
      height: 30px;
      width: 90px;
      border-radius: 5px;
  }

.side-popup-1 .start_chat {
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
  }

.side-popup-1 .chat_form {
    width: 100%;
  }

.side-popup-1 .chat_form input {
    width: 80%;
    border-style: none;
  }

.side-popup-1 .chat_form button {
    width: 20%;
    height: 100%;
    border-style: none;
    background-color: white;
  }

.side-popup-1 .chat_messages {
    width: 100%;
    list-style-type: none;
  }

.side-popup-1 .message_title > h4 {
    color: #ec3545;
    font-size: 15px;
    font-weight: 450;
    margin-bottom: 5px;
  }

.side-popup-1 .message_body > p {
    font-size: 14px;
    margin-bottom: 12px;
    line-height: 15px;
  }