/* screen - student-quiz */

.border-1px-black {
  border: 1px solid var(--black);
}

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-14px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.student-quiz {
  background-color: rgba(253, 254, 255, 1);
  height: 100vh;

  width: 100%;
}

.student-quiz .analytics-24px-3-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 644px;
  width: 24px;
}

.student-quiz .apply-filters-C61RwL {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;

  letter-spacing: 0px;
  line-height: normal;
}

.student-quiz .assignment-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 348px;
  width: 24px;
}

.student-quiz .biba-sobti-VdQhAS {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 92px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}

.student-quiz .bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 47px;
  width: auto;
}

.student-quiz .chemistry-ms-harpreet-kaur-WRwqIS {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: auto;
}

.student-quiz .choose-subject-i63336282371261-02ks1v {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 42.41%;
}

.student-quiz .choose-subject-i63336292371261-ftWxNG {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 38.74%;
}

.student-quiz .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;
  left: 123px;
  position: absolute;
  top: 45px;
  width: 212px;
}

.student-quiz .component-29-4VjeAJ {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 36px;
}

.student-quiz .component-29-5ACEAM {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 36px;
}

.student-quiz .component-29-CLMm6m {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 36px;
}

.student-quiz .component-29-Yiw8ux {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 36px;
}

.student-quiz .component-30-C61RwL {
  background-color: var(--white);
  left: 10%;
  position: absolute;
  top: 297px;
  width: 17%;
}

.student-quiz .component-31-C61RwL {
  left: 29%;
  position: absolute;
  top: 297px;
  width: 17%;
}

.student-quiz .description-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 496px;
  width: 24px;
}

.student-quiz .doubt-forum-C61RwL {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 10px;
  width: 168px;
}

.student-quiz .ellipse-2-OSIBbx {
  background-color: transparent;
  height: 19.05%;
  left: 57.14%;
  position: absolute;
  top: 21.43%;
  width: 19.05%;
}

.student-quiz .expandmore-24px-1-02ks1v {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-quiz .expandmore-24px-1-ftWxNG {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-quiz .graded-AvtzuR {
  background-color: transparent;
  color: rgba(5, 187, 138, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  /* left: 575px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: right;
  /* top: 26px; */
  width: auto;
  margin-left: auto;
}

.student-quiz .group-0M78Jh {
  background-color: transparent;
  background-image: url(./img/vector-202@2x.png);
  background-size: 100% 100%;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-160-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  position: absolute;
  top: 718px;
  width: 24px;
}

.student-quiz .group-189-C61RwL {
  background-color: transparent;
  height: 36px;
  width: 36px;
}

.student-quiz .group-190-C61RwL {
  background-color: transparent;
  height: 36px;
  left: 180px;
  position: absolute;
  top: 480px;
  width: 36px;
}

.student-quiz .group-191-C61RwL {
  background-color: transparent;
  height: 36px;
  left: 180px;
  position: absolute;
  top: 581px;
  width: 36px;
}

.student-quiz .group-192-C61RwL {
  background-color: transparent;
  height: 36px;
  left: 180px;
  position: absolute;
  top: 682px;
  width: 36px;
}

.student-quiz .group-1G6kDx {
  background-color: transparent;
  background-image: url(./img/vector-43@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-quiz .group-22-C61RwL {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}

.student-quiz .group-26DuQS {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-quiz .component-29-AvtzuR {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 36px;
  width: 36px;
}

.student-quiz .assignment-24px-3-BZ3lUO {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}

.student-quiz .vector-Pdmq9p {
  background-color: transparent;
  height: 86.11%;
  left: 11.11%;
  position: absolute;
  top: 2.78%;
  width: 77.78%;
}

.student-quiz .group-2XlVrD {
  background-color: transparent;
  height: 20px;
  /* left: 7px;
  position: absolute;
  top: 9px; */
  width: 20px;
}

.student-quiz .group-30TA9U {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz .group-3YhNjD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-3qdjky {
  background-color: transparent;
  background-image: url(./img/vector-40@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz .group-46-C61RwL-eexs {
  background-color: transparent;
  height: 35%;
  left: 10%;
  position: absolute;
  top: 363px;
  width: 49%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.student-quiz .group-47-C61RwL {
  background-color: transparent;
  height: 67px;
  left: 155px;
  position: absolute;
  top: 464px;
  width: 663px;
}

.student-quiz .group-48-C61RwL {
  background-color: transparent;
  height: 67px;
  left: 155px;
  position: absolute;
  top: 565px;
  width: 663px;
}

.student-quiz .group-49-C61RwL {
  background-color: transparent;
  height: 67px;
  left: 155px;
  position: absolute;
  top: 666px;
  width: 663px;
}

.student-quiz .group-4fKC6i {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-quiz .group-4wy4Bx {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-5YlXKz {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-quiz .group-5jt8cF {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-quiz .group-5r1RGr {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-quiz .group-65-2XlVrD {
  background-color: transparent;
  height: 36px;
  /* left: 0px;
  position: absolute;
  top: 0px; */
  width: 36px;
}

.student-quiz .group-65-cUTH82 {
  background-color: transparent;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}

.student-quiz .group-65-hw2l6s {
  background-color: transparent;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}

.student-quiz .group-65-leIp5j {
  background-color: transparent;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}

.student-quiz .group-8RWWXx {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-quiz .group-8SehwL {
  background-color: transparent;
  background-image: url(./img/vector-48@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-quiz .group-8mXtiA {
  background-color: transparent;
  background-image: url(./img/vector-48@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-quiz .group-Ambq12 {
  background-color: transparent;
  background-image: url(./img/vector-49@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-quiz .group-B0zdg8 {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-quiz .group-BVoyFW {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-quiz .group-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 22px;
  position: absolute;
  top: 422px;
  width: 20px;
}

.student-quiz .group-CZ3XDU {
  background-color: transparent;
  background-image: url(./img/vector-43@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-quiz .group-Cff0bY {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-E9Z3Qz {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-quiz .group-Eub1uD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-FDKNYY {
  background-color: transparent;
  background-image: url(./img/vector-48@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-quiz .group-GQEqeI {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-GQNshl {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-Gv5bm4 {
  background-color: transparent;
  background-image: url(./img/vector-40@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz .group-H4kFYy {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-quiz .group-Hc5Phf {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-quiz .group-HhBLmf {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-HkDxar {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-I6cVpt {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-Ie5dUU {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-quiz .group-J1LPKs {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-K12spc {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz .group-KLBpwU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-quiz .group-KWN2RE {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-quiz .group-KcOtnV {
  background-color: transparent;
  background-image: url(./img/vector-48@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-quiz .group-KsMB6y {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-quiz .group-LCuxKV {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-quiz .group-LMly0o {
  background-color: transparent;
  background-image: url(./img/vector-43@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-quiz .group-NOXmfT {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-OUAngl {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-quiz .group-SfTnen {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-quiz .group-SlOO8W {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-quiz .group-SlxGcy {
  background-color: transparent;
  background-image: url(./img/vector-40@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz .group-Sw5xnU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-quiz .group-W5IipO {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-WAmUNE {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-quiz .group-X6Y0CP {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-quiz .group-XHqu7O {
  background-color: transparent;
  background-image: url(./img/vector-40@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz .group-Z0P29R {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-quiz .group-Zmqz7u {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-quiz .group-a2vI4G {
  background-color: transparent;
  background-image: url(./img/vector-37@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-quiz .group-b1ls5q {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-quiz .group-bznq7Z {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-quiz .group-cTYyqt {
  background-color: transparent;
  background-image: url(./img/vector-37@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-quiz .group-cUTH82 {
  background-color: transparent;
  height: 20px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 20px;
}

.student-quiz .group-ce7Ifp {
  background-color: transparent;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 20px;
}

.student-quiz .group-dimqSz {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-quiz .group-f6NdeG {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-quiz .group-fbkhx3 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz .group-fxegOB {
  background-color: transparent;
  background-image: url(./img/vector-49@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-quiz .group-hNGwvV {
  background-color: transparent;
  background-image: url(./img/vector-37@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-quiz .group-hcfGEs {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-quiz .group-hoVMgD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-htgOUZ {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-quiz .group-hw2l6s {
  background-color: transparent;
  height: 20px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 20px;
}

.student-quiz .group-hxa4BC {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-quiz .group-jp29XK {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-kHXLPa {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-kK588U {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-quiz .group-lX9zMw {
  background-color: transparent;
  background-image: url(./img/vector-49@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-quiz .group-leIp5j {
  background-color: transparent;
  height: 20px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 20px;
}

.student-quiz .group-lmXTEf {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-quiz .group-m8nkkI {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-quiz .group-mnqxdV {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-quiz .group-oRRBjL {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-quiz .group-paC7Rl {
  background-color: transparent;
  background-image: url(./img/vector-43@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-quiz .group-pmZUQc {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-quiz .group-sx6xBo {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-tDnSYz {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-quiz .group-tr5zNE {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz .group-wbAI42 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz .group-wotaxO {
  background-color: transparent;
  background-image: url(./img/vector-49@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-quiz .group-wpJ3xR {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-quiz .group-xKrbSU {
  background-color: transparent;
  background-image: url(./img/vector-37@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-quiz .group-xfUToA {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-quiz .group-zkxeqe {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-quiz .help-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 570px;
  width: 24px;
}

.student-quiz .how-to-solve-this-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1031px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 439px;
  width: 290px;
}

.student-quiz .join-4-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 974px;
  overflow: hidden;
  position: absolute;
  top: 261px;
  width: 46px;
}

.student-quiz .join-5-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 974px;
  overflow: hidden;
  position: absolute;
  top: 348px;
  width: 46px;
}

.student-quiz .join-6-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 978px;
  overflow: hidden;
  position: absolute;
  top: 435px;
  width: 46px;
}

.student-quiz .join-7-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 981px;
  overflow: hidden;
  position: absolute;
  top: 519px;
  width: 46px;
}

.student-quiz .line-5-9p2GBv {
  background-color: transparent;
  height: 99.05%;
  left: 0.9%;
  position: absolute;
  top: 0.48%;
  width: 99.29%;
}

.student-quiz .line-6-9p2GBv {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}

.student-quiz .mathematics-dr-yashna-sharma-AvtzuR {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: auto;
}

.student-quiz .mathematics-dr-yashna-sharma-uAs3px {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: auto;
}

.student-quiz .my-quiz-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 10%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 210px;
  width: 310px;
}

.student-quiz .not-submitted-WRwqIS {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 552px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}

.student-quiz .notifications-24px-2-1-C61RwL {
  background-color: transparent;
  height: 42px;
  left: 976px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 42px;
}

.student-quiz .physics-ms-bindu-kapoor-YQC0Kc {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: auto;
}

.student-quiz .please-check-my-approach-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1034px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 523px;
  width: 290px;
}

.student-quiz .quiz-1-AvtzuR {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 109px;
}

.student-quiz .quiz-1-WRwqIS {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 109px;
}

.student-quiz .quiz-1-YQC0Kc {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 109px;
}

.student-quiz .quiz-2-uAs3px {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 112px;
}

.student-quiz .rectangle-43-C61RwL .doubt-row {
  display: flex;
  justify-content: space-evenly;
  margin: 4px;
  align-items: center;
}

.student-quiz .rectangle-24-C61RwL-stu-s1 {
  background-color: #1f2531;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 63px;

  overflow-y: scroll;
  padding: 150px 0px 20px 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.student-quiz .rectangle-40-AvtzuR {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  width: 95%;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  padding: 10px 30px;
}

.student-quiz .rectangle-40-AvtzuR .quizTile-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 4px;
  height: 30px;
}

.student-quiz .rectangle-40-WRwqIS {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 657px;
}

.student-quiz .rectangle-40-YQC0Kc {
  background-color: transparent;
  height: 77px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 667px;
}

.student-quiz .rectangle-40-uAs3px {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 657px;
}

.student-quiz .rectangle-41-C61RwL {
  background-color: #dc3545;
  border-radius: 5px;
  height: 38px;
  left: 48%;
  position: absolute;
  top: 298px;
  width: 8.08%;

  text-align: center;
  padding: 13px;
}

.student-quiz .rectangle-41-YQC0Kc {
  background-color: transparent;
  height: 77px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 667px;
}

.student-quiz .rectangle-43-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* height: 417px; */
  right: 5%;
  position: absolute;
  top: 197px;
  width: 29%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding: 72px 24px 40px;
  min-height: 160px;
}

.student-quiz .rectangle-7-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 630px;
  position: absolute;
  top: 631px;
  width: 1px;
}

.student-quiz .school-24px-1-AvtzuR {
  background-color: transparent;
  height: 24px;
  /* left: 317px; */
  overflow: hidden;
  /* position: absolute; */
  /* top: 21px; */
  width: 24px;
}

.student-quiz .span0-RD25Gb {
  color: rgb(220, 53, 69);
  font-style: normal;
}

.student-quiz .span1-RD25Gb {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}

.student-quiz .stickynote2-24px-1-Cp4Be6 {
  background-color: transparent;
  height: 24px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 24px;
}

.student-quiz .supportagent-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 792px;
  width: 24px;
}

.student-quiz .ungraded-YQC0Kc {
  background-color: transparent;
  height: auto;
  left: 569px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}

.student-quiz .ungraded-uAs3px {
  background-color: transparent;
  height: auto;
  left: 569px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}

.student-quiz .vector-0HfMqN {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-quiz .vector-1G6kDx {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-quiz .vector-1YY105 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-quiz .vector-1ewXKa {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-quiz .vector-26DuQS {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-quiz .vector-2LH7tZ {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}

.student-quiz .vector-2kxNnt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-quiz .vector-30TA9U {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-quiz .vector-3q0WPs {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-quiz .vector-3qdjky {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-quiz .vector-4ae2Rf {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-quiz .vector-4fKC6i {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-quiz .vector-4sG9FF {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-quiz .vector-4vfNnN {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-quiz .vector-5FRq6D {
  background-color: transparent;
  height: 32.5%;
  left: 25%;
  position: absolute;
  top: 34.98%;
  width: 50%;
}

.student-quiz .vector-5S9pFx {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-quiz .vector-5YlXKz {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-quiz .vector-5r1RGr {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-quiz .vector-5x4wyG {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-quiz .vector-6XJ1dC {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}

.student-quiz .vector-7QdKbH {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-quiz .vector-7pUNkI {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-quiz .vector-7txfVx {
  background-color: transparent;
  height: 58.33%;
  left: 20.83%;
  position: absolute;
  top: 20.83%;
  width: 58.33%;
}

.student-quiz .vector-7uUfdl {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-quiz .vector-8JNBpx {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-quiz .vector-8RWWXx {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-quiz .vector-8SehwL {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-quiz .vector-8U01yU {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-quiz .vector-8XEWy4 {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-quiz .vector-8mXtiA {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-quiz .vector-8xAgQn {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-quiz .vector-9opORi {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-quiz .vector-9vYPU5 {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-quiz .vector-AONfqU {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-quiz .vector-Ambq12 {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-quiz .vector-AuhUI1 {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-quiz .vector-B0zdg8 {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-quiz .vector-BVoyFW {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-quiz .vector-C61RwL {
  background-color: transparent;
  height: 2.34%;
  left: 1.32%;
  position: absolute;
  top: 92.77%;
  width: 1.67%;
}

.student-quiz .vector-CLdsug {
  background-color: transparent;
  height: 82.14%;
  left: 16.67%;
  position: absolute;
  top: 9.97%;
  width: 66.67%;
}

.student-quiz .vector-CMx3Uj {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-quiz .vector-CZ3XDU {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-quiz .vector-ChPPby {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-quiz .vector-DcBIgL {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-quiz .vector-Dn0VOv {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-quiz .vector-E3VqMR {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-quiz .vector-E9Z3Qz {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-quiz .vector-EXppWg {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}

.student-quiz .vector-FDKNYY {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-quiz .vector-Fb0JQL {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-quiz .vector-FxTcxt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-quiz .vector-G0yBDR {
  background-color: transparent;
  height: 83.33%;
  left: 16.67%;
  position: absolute;
  top: 8.33%;
  width: 66.67%;
}

.student-quiz .vector-Gv5bm4 {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-quiz .vector-H4kFYy {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-quiz .vector-HTuX41 {
  background-color: transparent;
  height: 32.5%;
  left: 25%;
  position: absolute;
  top: 34.98%;
  width: 50%;
}

.student-quiz .vector-Hc5Phf {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-quiz .vector-JWb765 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-quiz .vector-K12spc {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-quiz .vector-KLBpwU {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-quiz .vector-KLFwa3 {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-quiz .vector-KRXr9B {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-quiz .vector-KWN2RE {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-quiz .vector-KcOtnV {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-quiz .vector-LCuxKV {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-quiz .vector-LMly0o {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-quiz .vector-LgtYic {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-quiz .vector-MWjU8S {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-quiz .vector-MtJxOL {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-quiz .vector-N78QUN {
  background-color: transparent;
  height: 2.5%;
  left: 48.75%;
  position: absolute;
  top: 48.75%;
  width: 2.5%;
}

.student-quiz .vector-NLtoul {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-quiz .vector-OB8oOM {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-quiz .vector-OSIBbx {
  background-color: transparent;
  height: 1.19%;
  left: 49.4%;
  position: absolute;
  top: 49.4%;
  width: 1.19%;
}

.student-quiz .vector-OUAngl {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-quiz .vector-OYLvDa {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}

.student-quiz .vector-OsxaKh {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-quiz .vector-Ozaj6l {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-quiz .vector-QxuFcp {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-quiz .vector-ROQVGS {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}

.student-quiz .vector-SfTnen {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-quiz .vector-SlOO8W {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-quiz .vector-SlxGcy {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-quiz .vector-Sw5xnU {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-quiz .vector-T0rcQo {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-quiz .vector-Ta8Heo {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-quiz .vector-TlPffh {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-quiz .vector-TmFVy3 {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-quiz .vector-UUKtms {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-quiz .vector-UaM55c {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-quiz .vector-UxrCfI {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-quiz .vector-VAjk24 {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-quiz .vector-VJLVH1 {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-quiz .vector-VMr6Om {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 26.76%;
  width: 1.67%;
}

.student-quiz .vector-VP2Rux {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-quiz .vector-VdQhAS {
  background-color: transparent;
  height: 100%;
  left: 73.42%;
  position: absolute;
  top: 0px;
  width: 25.32%;
}

.student-quiz .vector-W1hXZ4 {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-quiz .vector-WAmUNE {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-quiz .vector-WK7UJi {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-quiz .vector-WVknxn {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-quiz .vector-WbzDQU {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-quiz .vector-X6Y0CP {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-quiz .vector-XHqu7O {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-quiz .vector-XQwS4n {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-quiz .vector-XjFi5l {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-quiz .vector-XqVjZB {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-quiz .vector-XwxuE1 {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-quiz .vector-YarvLR {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-quiz .vector-Z0P29R {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-quiz .vector-a2vI4G {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-quiz .vector-apzjgl {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-quiz .vector-b1ls5q {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-quiz .vector-baKUI6 {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-quiz .vector-bznq7Z {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-quiz .vector-c18eCt {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-quiz .vector-cTYyqt {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-quiz .vector-dBr6KK {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-quiz .vector-dLw05Z {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-quiz .vector-dimqSz {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-quiz .vector-dmgbrE {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-quiz .vector-dsyqYk {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-quiz .vector-e4RWvp {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}

.student-quiz .vector-eh9WzI {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-quiz .vector-emEBgq {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}

.student-quiz .vector-f6NdeG {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-quiz .vector-fCysJq {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-quiz .vector-fbkhx3 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-quiz .vector-fxegOB {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-quiz .vector-g9aAPA {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-quiz .vector-gsSdey {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-quiz .vector-hNGwvV {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-quiz .vector-hPKSzt {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-quiz .vector-hcfGEs {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-quiz .vector-htgOUZ {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-quiz .vector-hxa4BC {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-quiz .vector-ib8Dlq {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-quiz .vector-irJBpK {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-quiz .vector-jQrdMY {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-quiz .vector-jmCVFs {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-quiz .vector-jo50B0 {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}

.student-quiz .vector-kK588U {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-quiz .vector-l8qsXH {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-quiz .vector-lWWNsc {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-quiz .vector-lX9zMw {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-quiz .vector-lmXTEf {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-quiz .vector-m8nkkI {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-quiz .vector-mmO9xv {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-quiz .vector-mzXdH9 {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 19.53%;
  width: 1.67%;
}

.student-quiz .vector-n1UKnn {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-quiz .vector-nXJ6dP {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-quiz .vector-oOS98D {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-quiz .vector-oRRBjL {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-quiz .vector-oZNlX3 {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-quiz .vector-objxfZ {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-quiz .vector-paC7Rl {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-quiz .vector-pagq92 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-quiz .vector-pgMXxb {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-quiz .vector-pmZUQc {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-quiz .vector-pxZ96q {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-quiz .vector-qF2ch0 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-quiz .vector-qGJxwJ {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-quiz .vector-qHVi3n {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-quiz .vector-qOr5jx {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}

.student-quiz .vector-qmzfLw {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-quiz .vector-qy1MCO {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-quiz .vector-r18CAb {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-quiz .vector-r4buxr {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-quiz .vector-rnzczl {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-quiz .vector-sCPPBx {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-quiz .vector-tDnSYz {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-quiz .vector-tQ6NQH {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}

.student-quiz .vector-tSUwp7 {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-quiz .vector-uhbuVM {
  background-color: transparent;
  height: 36.11%;
  left: 20.01%;
  position: absolute;
  top: 16.53%;
  width: 60%;
}

.student-quiz .vector-v9Qr6G {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-quiz .vector-vJyLWf {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-quiz .vector-wGeoOk {
  background-color: transparent;
  height: 2.5%;
  left: 48.75%;
  position: absolute;
  top: 48.75%;
  width: 2.5%;
}

.student-quiz .vector-wGsDUK {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-quiz .vector-wU39qv {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-quiz .vector-wbAI42 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-quiz .vector-wotaxO {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-quiz .vector-wpJ3xR {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-quiz .vector-x7OZie {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-quiz .vector-xFdzbn {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}

.student-quiz .vector-xKrbSU {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-quiz .vector-xYu4ww {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-quiz .vector-xfUToA {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-quiz .vector-xr8pKE {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-quiz .vector-xy9YKY {
  background-color: transparent;
  height: 11.11%;
  left: 30%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-quiz .vector-ydL8U7 {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-quiz .vector-ye3NYT {
  background-color: transparent;
  height: 11.11%;
  left: 60%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-quiz .vector-ywnyBE {
  background-color: transparent;
  height: 83.33%;
  left: 8.33%;
  position: absolute;
  top: 8.33%;
  width: 83.33%;
}

.student-quiz .vector-zJJzco {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-quiz .vector-zLY8MO {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-quiz .vector-zkxeqe {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-quiz .view-all-C61RwL {
  background-color: transparent;
  height: auto;
  right: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 75px;
}

.student-quiz .view-forum-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1227px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 298px;
  width: 113px;
}

.student-quiz .view-forum-QxM5SU {
  background-color: transparent;
  height: auto;
  left: 1230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 554px;
  width: 113px;
}

.student-quiz .view-forum-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 1227px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 374px;
  width: 113px;
}

.student-quiz .view-forum-mzXdH9 {
  background-color: transparent;
  height: auto;
  left: 1227px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 470px;
  width: 113px;
}

.student-quiz .what-is-the-si-unit-of-current-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1031px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 267px;
  width: 290px;
}

.student-quiz .what-is-the-sol-f-this-question-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1031px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 343px;
  width: 290px;
}

.student-quiz .x14-C61RwL {
  background-color: transparent;
  color: rgba(244, 244, 244, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 1163px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 357px;
  width: auto;
}

:root {
  --black: rgba(0, 0, 0, 0.1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --white: rgba(255, 255, 255, 1);
}

/* screen - student-quiz-list-mobile */

.border-1px-black {
  border: 1px solid var(--black);
}

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-14px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.student-quiz-list-mobile {
  background-color: rgba(253, 254, 255, 1);
  height: 100vh;
  margin: 0px;
  min-height: 1009px;
  min-width: 414px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.student-quiz-list-mobile .apply-filters-g6la27 {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: 36.36%;
  left: 15.05%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21.79%;
  width: 80.65%;
}

.student-quiz-list-mobile .chemistry-ms-harpreet-kaur-3U5qE3 {
  background-color: transparent;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 340px;
  width: 157px;
}

.student-quiz-list-mobile .choose-subject-i63333002371261-iR8muk {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 47.37%;
}

.student-quiz-list-mobile .choose-subject-i63333012371261-DRd40k {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 43.02%;
}

.student-quiz-list-mobile .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 45px;
  left: 26px;
  position: absolute;
  top: 24px;
  width: 87px;
}

.student-quiz-list-mobile .component-29-87p3Hy {
  background-color: transparent;
  background-image: url(./img/ellipse-4-4@2x.png);
  background-size: 100% 100%;
  height: 38px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}

.student-quiz-list-mobile .component-29-eYqyUC {
  background-color: transparent;
  background-image: url(./img/ellipse-4-4@2x.png);
  background-size: 100% 100%;
  height: 38px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}

.student-quiz-list-mobile .component-29-lvfzGr {
  background-color: transparent;
  background-image: url(./img/ellipse-4-4@2x.png);
  background-size: 100% 100%;
  height: 38px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}

.student-quiz-list-mobile .component-29-wAIKMX {
  background-color: transparent;
  background-image: url(./img/ellipse-4-4@2x.png);
  background-size: 100% 100%;
  height: 38px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}

.student-quiz-list-mobile .component-30-ZnSQrh-01-1 {
  background-color: var(--white);
  border-radius: 10px;
  height: 33px;
  left: 0px;
  position: absolute;
  top: 0px;
  min-width: 171px;
}

.student-quiz-list-mobile .component-31-ZnSQrh {
  background-color: var(--white);
  border-radius: 10px;
  height: 33px;
  left: 197px;
  position: absolute;
  top: 0px;
  width: 172px;
}

.student-quiz-list-mobile .component-6-C61RwL {
  background-color: transparent;
  height: 18px;
  left: 362px;
  position: absolute;
  top: 37px;
  width: 23px;
}

.student-quiz-list-mobile .doubt-forum-rFX9Nh {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 25px;
  width: 134px;
}

.student-quiz-list-mobile .expandmore-24px-1-DRd40k {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-quiz-list-mobile .expandmore-24px-1-iR8muk {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-quiz-list-mobile .graded-0K3xy9 {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 300px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: 52px;
}

.student-quiz-list-mobile .graded-To2iXC {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 300px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: 65px;
}

.student-quiz-list-mobile .group-100-C61RwL {
  background-color: transparent;
  height: 38px;
  left: 27px;
  position: absolute;
  top: 425px;
  width: 36px;
}

.student-quiz-list-mobile .group-101-C61RwL {
  background-color: transparent;
  height: 38px;
  left: 27px;
  position: absolute;
  top: 526px;
  width: 36px;
}

.student-quiz-list-mobile .group-102-C61RwL {
  background-color: transparent;
  height: 38px;
  left: 27px;
  position: absolute;
  top: 627px;
  width: 36px;
}

.student-quiz-list-mobile .group-1pzCME {
  background-color: transparent;
  background-image: url(./img/vector-302@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-quiz-list-mobile .group-3HWdqU {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-quiz-list-mobile .group-3eHi1a {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-quiz-list-mobile .group-3xCoUY {
  background-color: transparent;
  height: 3px;
  left: 37px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-quiz-list-mobile .group-46-3U5qE3 {
  background-color: transparent;
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}

.student-quiz-list-mobile .group-47-3U5qE3 {
  background-color: transparent;
  height: 67px;
  left: 0px;
  position: absolute;
  top: 101px;
  width: 376px;
}

.student-quiz-list-mobile .group-48-3U5qE3 {
  background-color: transparent;
  height: 67px;
  left: 0px;
  position: absolute;
  top: 202px;
  width: 376px;
}

.student-quiz-list-mobile .group-4Tztbv {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz-list-mobile .group-5ZFw7U {
  background-color: transparent;
  background-image: url(./img/vector-303@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-quiz-list-mobile .group-64-87p3Hy {
  background-color: transparent;
  background-image: url(./img/vector-363@2x.png);
  background-size: 100% 100%;
  height: 50%;
  left: 19.44%;
  position: absolute;
  top: 25%;
  width: 61.11%;
}

.student-quiz-list-mobile .group-64-eYqyUC {
  background-color: transparent;
  background-image: url(./img/vector-363@2x.png);
  background-size: 100% 100%;
  height: 50%;
  left: 19.44%;
  position: absolute;
  top: 25%;
  width: 61.11%;
}

.student-quiz-list-mobile .group-64-lvfzGr {
  background-color: transparent;
  background-image: url(./img/vector-363@2x.png);
  background-size: 100% 100%;
  height: 50%;
  left: 19.44%;
  position: absolute;
  top: 25%;
  width: 61.11%;
}

.student-quiz-list-mobile .group-64-wAIKMX {
  background-color: transparent;
  background-image: url(./img/vector-363@2x.png);
  background-size: 100% 100%;
  height: 50%;
  left: 19.44%;
  position: absolute;
  top: 25%;
  width: 61.11%;
}

.student-quiz-list-mobile .group-65-C61RwL {
  background-color: transparent;
  height: 38px;
  left: 27px;
  position: absolute;
  top: 324px;
  width: 36px;
}

.student-quiz-list-mobile .group-6dsxAM {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-quiz-list-mobile .group-6u2TAk {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-quiz-list-mobile .group-85-C61RwL {
  background-color: transparent;
  height: 270px;
  left: 22px;
  position: absolute;
  top: 708px;
  width: 394px;
}

.student-quiz-list-mobile .group-94-IUHrKR {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 102.2%;
}

.student-quiz-list-mobile .group-95-C61RwL {
  background-color: transparent;
  height: 21px;
  left: 162px;
  position: absolute;
  top: 260px;
  width: 91px;
}

.student-quiz-list-mobile .group-96-C61Rw-L-0101 {
  background-color: transparent;
  height: 370px;
  left: 22px;
  position: absolute;
  top: 308px;
  width: 376px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: scroll;
}

.student-quiz-list-mobile .group-99-C61RwL {
  background-color: transparent;
  height: 33px;
  left: 24px;
  position: absolute;
  top: 200px;
  width: 369px;
}

.student-quiz-list-mobile .group-9neAS5 {
  background-color: transparent;
  background-image: url(./img/vector-310@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-quiz-list-mobile .group-B3X4ab {
  background-color: transparent;
  background-image: url(./img/vector-303@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-quiz-list-mobile .group-BpxbEL {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz-list-mobile .group-DhmCtC {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-quiz-list-mobile .group-FASyuA {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz-list-mobile .group-HTx4Jc {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz-list-mobile .group-IfQt5H {
  background-color: transparent;
  background-image: url(./img/vector-311@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-quiz-list-mobile .group-N2Y59c {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz-list-mobile .group-O5Cxw9 {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-quiz-list-mobile .group-Ob1Rqs {
  background-color: transparent;
  background-image: url(./img/vector-256@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-quiz-list-mobile .group-Qv0mZ4 {
  background-color: transparent;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-quiz-list-mobile .group-S9AR9M {
  background-color: transparent;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz-list-mobile .group-T7EbAp {
  background-color: transparent;
  height: 3px;
  left: 37px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-quiz-list-mobile .group-TdE1pD {
  background-color: transparent;
  background-image: url(./img/vector-265@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-quiz-list-mobile .group-TtUkiV {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-quiz-list-mobile .group-VOGBVy {
  background-color: transparent;
  background-image: url(./img/vector-308@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-quiz-list-mobile .group-VRMQru {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz-list-mobile .group-WfnLBo {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz-list-mobile .group-bX4Dvh {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz-list-mobile .group-bcFrzZ {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz-list-mobile .group-bof6oJ {
  background-color: transparent;
  height: 3px;
  left: 39px;
  position: absolute;
  top: 10px;
  width: 4px;
}

.student-quiz-list-mobile .group-c0ymqA {
  background-color: transparent;
  background-image: url(./img/vector-308@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-quiz-list-mobile .group-c9XjZ6 {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-quiz-list-mobile .group-dS4UXQ {
  background-color: transparent;
  background-image: url(./img/vector-311@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-quiz-list-mobile .group-dV81ro {
  background-color: transparent;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-quiz-list-mobile .group-dcaDkc {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-quiz-list-mobile .group-gn7VaX {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz-list-mobile .group-gvV2Rx {
  background-color: transparent;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-quiz-list-mobile .group-hhAbtl {
  background-color: transparent;
  background-image: url(./img/vector-264@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-quiz-list-mobile .group-iieIco {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz-list-mobile .group-jLf4aV {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-quiz-list-mobile .group-k7b1Mi {
  background-color: transparent;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz-list-mobile .group-lw8NAW {
  background-color: transparent;
  height: 3px;
  left: 39px;
  position: absolute;
  top: 10px;
  width: 4px;
}

.student-quiz-list-mobile .group-mokeKx {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-quiz-list-mobile .group-ms1pvS {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-quiz-list-mobile .group-nD2hpN {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-quiz-list-mobile .group-nzNou6 {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-quiz-list-mobile .group-qGye3v {
  background-color: transparent;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-quiz-list-mobile .group-qpftC5 {
  background-color: transparent;
  background-image: url(./img/vector-310@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-quiz-list-mobile .group-sku7Fc {
  background-color: transparent;
  height: 3px;
  left: 37px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-quiz-list-mobile .group-tQwNUN {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-quiz-list-mobile .group-toBf7r {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-quiz-list-mobile .group-wQSXdG {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-quiz-list-mobile .group-x2R7zB {
  background-color: transparent;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-quiz-list-mobile .group-xB1UPi {
  background-color: transparent;
  background-image: url(./img/vector-302@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-quiz-list-mobile .group-xS7o0X {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-quiz-list-mobile .group-xhwx9b {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-quiz-list-mobile .group-xtOtSY {
  background-color: transparent;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-quiz-list-mobile .group-zxfIi7 {
  background-color: transparent;
  height: 3px;
  left: 39px;
  position: absolute;
  top: 10px;
  width: 4px;
}

.student-quiz-list-mobile .how-to-solve-this-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 70px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 214px;
  width: 296px;
}

.student-quiz-list-mobile .join-7-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 11px;
  overflow: hidden;
  position: absolute;
  top: 68px;
  width: 47px;
}

.student-quiz-list-mobile .join-8-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 9px;
  overflow: hidden;
  position: absolute;
  top: 139px;
  width: 47px;
}

.student-quiz-list-mobile .rectangle-44-rFX9Nh .doubt-row {
  display: flex;
  justify-content: space-evenly;
  margin: 4px;
  align-items: center;
}

.student-quiz-list-mobile .join-9-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 9px;
  overflow: hidden;
  position: absolute;
  top: 210px;
  width: 47px;
}

.student-quiz-list-mobile .line-5-9p2GBv {
  background-color: transparent;
  height: 101.11%;
  left: 0.26%;
  position: absolute;
  top: -0.56%;
  width: 100.57%;
}

.student-quiz-list-mobile .line-6-9p2GBv {
  background-color: transparent;
  height: 102.22%;
  left: -0.29%;
  position: absolute;
  top: -1.11%;
  width: 100.57%;
}

.student-quiz-list-mobile .mathematics-dr-yashna-sharma-91RuQZ {
  background-color: transparent;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: 177px;
}

.student-quiz-list-mobile .mathematics-dr-yashna-sharma-To2iXC {
  background-color: transparent;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: 183px;
}

.student-quiz-list-mobile .my-quiz-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 132px;
  width: 310px;
}

.student-quiz-list-mobile .physics-ms-bindu-kapoor-0K3xy9 {
  background-color: transparent;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: 147px;
}

.student-quiz-list-mobile .quiz-1-0K3xy9 {
  background-color: transparent;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 114px;
}

.student-quiz-list-mobile .quiz-1-3U5qE3 {
  background-color: transparent;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 319px;
  width: 116px;
}

.student-quiz-list-mobile .quiz-1-91RuQZ {
  background-color: transparent;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 113px;
}

.student-quiz-list-mobile .quiz-2-To2iXC {
  background-color: transparent;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 116px;
}

.student-quiz-list-mobile .rectangle-40-0K3xy9 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 370px;
}

.student-quiz-list-mobile .rectangle-40-3U5qE3-0001-2 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;

  width: 360px;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  padding: 8px 15px;
}

.student-quiz-list-mobile .rectangle-40-91RuQZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 370px;
}

.student-quiz-list-mobile .rectangle-40-To2iXC {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 370px;
}

.student-quiz-list-mobile .rectangle-41-g6la27 {
  background-color: rgb(220, 53, 69);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 97.85%;
}

.student-quiz-list-mobile .rectangle-44-rFX9Nh {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* height: 270px; */
  left: 0px;
  position: absolute;
  top: 0px;
  width: 367px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  min-height: 120px;

  padding: 66px 0;
}

.student-quiz-list-mobile .rectangle-48-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-quiz-list-mobile .rectangle-49-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 38.89%;
  width: 100%;
}

.student-quiz-list-mobile .rectangle-50-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 77.78%;
  width: 100%;
}

.student-quiz-list-mobile .span0-RD25Gb {
  color: rgb(220, 53, 69);
  font-style: normal;
}

.student-quiz-list-mobile .span1-RD25Gb {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}

.student-quiz-list-mobile .submitted-91RuQZ {
  background-color: transparent;
  color: rgba(5, 187, 138, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 293px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: 69px;
}

.student-quiz-list-mobile .ungraded-3U5qE3 {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 293px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 329px;
  width: 77px;
}

.student-quiz-list-mobile .vector-0IPvau {
  background-color: transparent;
  height: 29.85%;
  left: 37.87%;
  position: absolute;
  top: 2.72%;
  width: 34.05%;
}

.student-quiz-list-mobile .vector-1Nxj6x {
  background-color: transparent;
  height: 121.98%;
  left: -7.1%;
  position: absolute;
  top: -10.99%;
  width: 114.19%;
}

.student-quiz-list-mobile .vector-1TafeU {
  background-color: transparent;
  height: 121.99%;
  left: -21.65%;
  position: absolute;
  top: -10.99%;
  width: 143.3%;
}

.student-quiz-list-mobile .vector-1oS1NN {
  background-color: transparent;
  height: 144.3%;
  left: -6.65%;
  position: absolute;
  top: -22.15%;
  width: 113.29%;
}

.student-quiz-list-mobile .vector-1pzCME {
  background-color: transparent;
  height: 52.24%;
  left: 49.49%;
  position: absolute;
  top: 8.1%;
  width: 45.75%;
}

.student-quiz-list-mobile .vector-1xHKrh {
  background-color: transparent;
  height: 11.94%;
  left: 23.98%;
  position: absolute;
  top: 18.13%;
  width: 8.51%;
}

.student-quiz-list-mobile .vector-2rF7C5 {
  background-color: transparent;
  height: 110.03%;
  left: -6.59%;
  position: absolute;
  top: -5.02%;
  width: 113.19%;
}

.student-quiz-list-mobile .vector-3HWdqU {
  background-color: transparent;
  height: 101.5%;
  left: 11.25%;
  position: absolute;
  top: -1.01%;
  width: 85.12%;
}

.student-quiz-list-mobile .vector-3X2JZC {
  background-color: transparent;
  height: 144.31%;
  left: -4.02%;
  position: absolute;
  top: -22.16%;
  width: 108.03%;
}

.student-quiz-list-mobile .vector-3xCoUY {
  background-color: transparent;
  height: 13.43%;
  left: 63.81%;
  position: absolute;
  top: 41.24%;
  width: 18.09%;
}

.student-quiz-list-mobile .vector-5SSNkN {
  background-color: transparent;
  height: 29.85%;
  left: 37.64%;
  position: absolute;
  top: 2.71%;
  width: 22.34%;
}

.student-quiz-list-mobile .vector-5YyGRW {
  background-color: transparent;
  height: 19.4%;
  left: 24.76%;
  position: absolute;
  top: 22.08%;
  width: 13.83%;
}

.student-quiz-list-mobile .vector-5ZFw7U {
  background-color: transparent;
  height: 29.85%;
  left: 2.08%;
  position: absolute;
  top: 8.12%;
  width: 45.75%;
}

.student-quiz-list-mobile .vector-5ZxPCv {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}

.student-quiz-list-mobile .vector-6Pj9gj {
  background-color: transparent;
  height: 5.97%;
  left: 50.75%;
  position: absolute;
  top: 63.23%;
  width: 7.45%;
}

.student-quiz-list-mobile .vector-6dsxAM {
  background-color: transparent;
  height: 7.46%;
  left: 67.81%;
  position: absolute;
  top: 51.44%;
  width: 7.45%;
}

.student-quiz-list-mobile .vector-6lEMsv {
  background-color: transparent;
  height: 34.33%;
  left: 2.6%;
  position: absolute;
  top: 22.69%;
  width: 58.52%;
}

.student-quiz-list-mobile .vector-6u2TAk {
  background-color: transparent;
  height: 41.8%;
  left: 4.2%;
  position: absolute;
  top: 13.83%;
  width: 55.33%;
}

.student-quiz-list-mobile .vector-9neAS5 {
  background-color: transparent;
  height: 14.93%;
  left: 17.28%;
  position: absolute;
  top: 40.61%;
  width: 11.7%;
}

.student-quiz-list-mobile .vector-ADvjvZ {
  background-color: transparent;
  height: 29.85%;
  left: 37.87%;
  position: absolute;
  top: 2.72%;
  width: 34.05%;
}

.student-quiz-list-mobile .vector-AEuo4j {
  background-color: transparent;
  height: 4.48%;
  left: 12.21%;
  position: absolute;
  top: 12.28%;
  width: 28.73%;
}

.student-quiz-list-mobile .vector-AKNQwM {
  background-color: transparent;
  height: 11.94%;
  left: 24.31%;
  position: absolute;
  top: 18.13%;
  width: 14.9%;
}

.student-quiz-list-mobile .vector-AUE2eU {
  background-color: transparent;
  height: 101.25%;
  left: -4.96%;
  position: absolute;
  top: 0.47%;
  width: 109.92%;
}

.student-quiz-list-mobile .vector-B3X4ab {
  background-color: transparent;
  height: 29.85%;
  left: 2.08%;
  position: absolute;
  top: 8.12%;
  width: 45.75%;
}

.student-quiz-list-mobile .vector-CKdTAP {
  background-color: transparent;
  height: 144.31%;
  left: -19.17%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}

.student-quiz-list-mobile .vector-DhmCtC {
  background-color: transparent;
  height: 13.43%;
  left: 63.32%;
  position: absolute;
  top: 41.24%;
  width: 25.53%;
}

.student-quiz-list-mobile .vector-FBXFcM {
  background-color: transparent;
  height: 34.33%;
  left: 2.6%;
  position: absolute;
  top: 22.69%;
  width: 58.52%;
}

.student-quiz-list-mobile .vector-FRe9kA {
  background-color: transparent;
  height: 121.98%;
  left: -7.1%;
  position: absolute;
  top: -10.99%;
  width: 114.19%;
}

.student-quiz-list-mobile .vector-FbIeqH {
  background-color: transparent;
  height: 8.96%;
  left: 25.41%;
  position: absolute;
  top: 36.57%;
  width: 6.38%;
}

.student-quiz-list-mobile .vector-GULZOy {
  background-color: transparent;
  height: 121.96%;
  left: -8.82%;
  position: absolute;
  top: -10.98%;
  width: 117.65%;
}

.student-quiz-list-mobile .vector-HDxFmN {
  background-color: transparent;
  height: 8.96%;
  left: 25.3%;
  position: absolute;
  top: 37.19%;
  width: 12.77%;
}

.student-quiz-list-mobile .vector-IfQt5H {
  background-color: transparent;
  height: 49.26%;
  left: 29.5%;
  position: absolute;
  top: 51.43%;
  width: 48.94%;
}

.student-quiz-list-mobile .vector-IpBwGI {
  background-color: transparent;
  height: 32.84%;
  left: 2.49%;
  position: absolute;
  top: 24.54%;
  width: 21.28%;
}

.student-quiz-list-mobile .vector-LS3dNl {
  background-color: transparent;
  height: 8.96%;
  left: 25.41%;
  position: absolute;
  top: 36.57%;
  width: 6.38%;
}

.student-quiz-list-mobile .vector-LpgxzN {
  background-color: transparent;
  height: 19.4%;
  left: 24.76%;
  position: absolute;
  top: 22.08%;
  width: 13.83%;
}

.student-quiz-list-mobile .vector-N2Y59c {
  background-color: transparent;
  height: 82.1%;
  left: 7.01%;
  position: absolute;
  top: 2.36%;
  width: 93.63%;
}

.student-quiz-list-mobile .vector-NF9mxS {
  background-color: transparent;
  height: 5.97%;
  left: 23.91%;
  position: absolute;
  top: 28.23%;
  width: 2.13%;
}

.student-quiz-list-mobile .vector-O5Cxw9 {
  background-color: transparent;
  height: 13.43%;
  left: 63.32%;
  position: absolute;
  top: 41.24%;
  width: 25.53%;
}

.student-quiz-list-mobile .vector-OWyIvf {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}

.student-quiz-list-mobile .vector-Ob1Rqs {
  background-color: transparent;
  height: 52.24%;
  left: 49.49%;
  position: absolute;
  top: 8.1%;
  width: 45.75%;
}

.student-quiz-list-mobile .vector-P1lxK3 {
  background-color: transparent;
  height: 5.97%;
  left: 23.91%;
  position: absolute;
  top: 28.23%;
  width: 2.13%;
}

.student-quiz-list-mobile .vector-PC91KU {
  background-color: transparent;
  height: 144.3%;
  left: -6.65%;
  position: absolute;
  top: -22.15%;
  width: 113.29%;
}

.student-quiz-list-mobile .vector-PD0S0h {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.36%;
}

.student-quiz-list-mobile .vector-PFKICe {
  background-color: transparent;
  height: 11.94%;
  left: 23.98%;
  position: absolute;
  top: 18.13%;
  width: 8.51%;
}

.student-quiz-list-mobile .vector-PtriGM {
  background-color: transparent;
  height: 11.94%;
  left: 23.77%;
  position: absolute;
  top: 18.13%;
  width: 15.96%;
}

.student-quiz-list-mobile .vector-QDyA6g {
  background-color: transparent;
  height: 110.03%;
  left: -6.59%;
  position: absolute;
  top: -5.02%;
  width: 113.19%;
}

.student-quiz-list-mobile .vector-Qv0mZ4 {
  background-color: transparent;
  height: 29.85%;
  left: 2.08%;
  position: absolute;
  top: 8.12%;
  width: 45.75%;
}

.student-quiz-list-mobile .vector-Qv8Xbp {
  background-color: transparent;
  height: 144.31%;
  left: -19.17%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}

.student-quiz-list-mobile .vector-RYpCgT {
  background-color: transparent;
  height: 121.98%;
  left: -6.78%;
  position: absolute;
  top: -10.99%;
  width: 113.56%;
}

.student-quiz-list-mobile .vector-S9AR9M {
  background-color: transparent;
  height: 70.16%;
  left: -0.55%;
  position: absolute;
  top: 8.33%;
  width: 95.76%;
}

.student-quiz-list-mobile .vector-T0btkN {
  background-color: transparent;
  height: 11.94%;
  left: 23.98%;
  position: absolute;
  top: 18.13%;
  width: 8.51%;
}

.student-quiz-list-mobile .vector-T7EbAp {
  background-color: transparent;
  height: 13.43%;
  left: 63.28%;
  position: absolute;
  top: 41.24%;
  width: 19.15%;
}

.student-quiz-list-mobile .vector-TdE1pD {
  background-color: transparent;
  height: 49.26%;
  left: 28.97%;
  position: absolute;
  top: 51.43%;
  width: 50.01%;
}

.student-quiz-list-mobile .vector-U0LxYw {
  background-color: transparent;
  height: 4.05%;
  left: 71.01%;
  position: absolute;
  top: -1.45%;
  width: 13.74%;
}

.student-quiz-list-mobile .vector-UUUxJF {
  background-color: transparent;
  height: 121.96%;
  left: -8.82%;
  position: absolute;
  top: -10.98%;
  width: 117.65%;
}

.student-quiz-list-mobile .vector-VOGBVy {
  background-color: transparent;
  height: 41.8%;
  left: 3.8%;
  position: absolute;
  top: 13.83%;
  width: 20.22%;
}

.student-quiz-list-mobile .vector-VOHrmv {
  background-color: transparent;
  height: 2.5%;
  left: 48.61%;
  position: absolute;
  top: 48.75%;
  width: 2.78%;
}

.student-quiz-list-mobile .vector-VRMQru {
  background-color: transparent;
  height: 82.1%;
  left: 7.01%;
  position: absolute;
  top: 2.36%;
  width: 93.63%;
}

.student-quiz-list-mobile .vector-Vr5tiq {
  background-color: transparent;
  height: 29.85%;
  left: 37.64%;
  position: absolute;
  top: 2.71%;
  width: 22.34%;
}

.student-quiz-list-mobile .vector-WCljiC {
  background-color: transparent;
  height: 19.4%;
  left: 24.76%;
  position: absolute;
  top: 22.08%;
  width: 13.83%;
}

.student-quiz-list-mobile .vector-WfnLBo {
  background-color: transparent;
  height: 82.1%;
  left: 7.01%;
  position: absolute;
  top: 2.36%;
  width: 93.63%;
}

.student-quiz-list-mobile .vector-WqgE0F {
  background-color: transparent;
  height: 19.4%;
  left: 29.04%;
  position: absolute;
  top: 81.41%;
  width: 29.79%;
}

.student-quiz-list-mobile .vector-Y59V0s {
  background-color: transparent;
  height: 4.05%;
  left: 71.01%;
  position: absolute;
  top: -1.45%;
  width: 13.74%;
}

.student-quiz-list-mobile .vector-Y9TIUK {
  background-color: transparent;
  height: 121.99%;
  left: -21.65%;
  position: absolute;
  top: -10.99%;
  width: 143.3%;
}

.student-quiz-list-mobile .vector-YM4yxX {
  background-color: transparent;
  height: 5.97%;
  left: 36.78%;
  position: absolute;
  top: 28.23%;
  width: 3.19%;
}

.student-quiz-list-mobile .vector-Zh51an {
  background-color: transparent;
  height: 144.3%;
  left: -6.65%;
  position: absolute;
  top: -22.15%;
  width: 113.29%;
}

.student-quiz-list-mobile .vector-aXV5cr {
  background-color: transparent;
  height: 5.97%;
  left: 23.91%;
  position: absolute;
  top: 28.23%;
  width: 2.13%;
}

.student-quiz-list-mobile .vector-bKRlnb {
  background-color: transparent;
  height: 110.03%;
  left: -6.59%;
  position: absolute;
  top: -5.02%;
  width: 113.19%;
}

.student-quiz-list-mobile .vector-bnkfUA {
  background-color: transparent;
  height: 4.48%;
  left: 12.23%;
  position: absolute;
  top: 12.28%;
  width: 8.51%;
}

.student-quiz-list-mobile .vector-bof6oJ {
  background-color: transparent;
  height: 29.85%;
  left: 1.84%;
  position: absolute;
  top: 8.24%;
  width: 19.15%;
}

.student-quiz-list-mobile .vector-c0ymqA {
  background-color: transparent;
  height: 41.8%;
  left: 3.8%;
  position: absolute;
  top: 13.83%;
  width: 20.22%;
}

.student-quiz-list-mobile .vector-c9XjZ6 {
  background-color: transparent;
  height: 13.43%;
  left: 63.32%;
  position: absolute;
  top: 41.24%;
  width: 25.53%;
}

.student-quiz-list-mobile .vector-cJDtwl {
  background-color: transparent;
  height: 5.97%;
  left: 50.75%;
  position: absolute;
  top: 63.23%;
  width: 7.45%;
}

.student-quiz-list-mobile .vector-cNJjv2 {
  background-color: transparent;
  height: 32.84%;
  left: 2.49%;
  position: absolute;
  top: 24.54%;
  width: 21.28%;
}

.student-quiz-list-mobile .vector-d2duO5 {
  background-color: transparent;
  height: 4.05%;
  left: 71.01%;
  position: absolute;
  top: -1.45%;
  width: 13.74%;
}

.student-quiz-list-mobile .vector-dG0Ldx {
  background-color: transparent;
  height: 5.97%;
  left: 50.75%;
  position: absolute;
  top: 63.23%;
  width: 7.45%;
}

.student-quiz-list-mobile .vector-dS2uCE {
  background-color: transparent;
  height: 8.96%;
  left: 25.3%;
  position: absolute;
  top: 37.19%;
  width: 12.77%;
}

.student-quiz-list-mobile .vector-dS4UXQ {
  background-color: transparent;
  height: 49.26%;
  left: 29.5%;
  position: absolute;
  top: 51.43%;
  width: 48.94%;
}

.student-quiz-list-mobile .vector-dV81ro {
  background-color: transparent;
  height: 70.16%;
  left: -0.67%;
  position: absolute;
  top: 8.45%;
  width: 24.47%;
}

.student-quiz-list-mobile .vector-dcaDkc {
  background-color: transparent;
  height: 14.93%;
  left: 17.32%;
  position: absolute;
  top: 40.61%;
  width: 28.73%;
}

.student-quiz-list-mobile .vector-e2yeN1 {
  background-color: transparent;
  height: 29.85%;
  left: 37.87%;
  position: absolute;
  top: 2.72%;
  width: 34.05%;
}

.student-quiz-list-mobile .vector-eMfSFf {
  background-color: transparent;
  height: 32.5%;
  left: 23.47%;
  position: absolute;
  top: 34.98%;
  width: 53.06%;
}

.student-quiz-list-mobile .vector-gvV2Rx {
  background-color: transparent;
  height: 70.16%;
  left: -0.67%;
  position: absolute;
  top: 8.45%;
  width: 24.47%;
}

.student-quiz-list-mobile .vector-halcXV {
  background-color: transparent;
  height: 144.31%;
  left: -19.17%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}

.student-quiz-list-mobile .vector-hhAbtl {
  background-color: transparent;
  height: 14.93%;
  left: 17.28%;
  position: absolute;
  top: 40.61%;
  width: 11.7%;
}

.student-quiz-list-mobile .vector-i64Q95 {
  background-color: transparent;
  height: 19.4%;
  left: 29.04%;
  position: absolute;
  top: 81.41%;
  width: 29.79%;
}

.student-quiz-list-mobile .vector-jLf4aV {
  background-color: transparent;
  height: 7.46%;
  left: 67.81%;
  position: absolute;
  top: 51.44%;
  width: 7.45%;
}

.student-quiz-list-mobile .vector-k7b1Mi {
  background-color: transparent;
  height: 70.16%;
  left: -0.55%;
  position: absolute;
  top: 8.33%;
  width: 95.76%;
}

.student-quiz-list-mobile .vector-kwqROy {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}

.student-quiz-list-mobile .vector-lFxDi9 {
  background-color: transparent;
  height: 19.4%;
  left: 29.04%;
  position: absolute;
  top: 81.41%;
  width: 29.79%;
}

.student-quiz-list-mobile .vector-lj5Xre {
  background-color: transparent;
  height: 121.96%;
  left: -8.82%;
  position: absolute;
  top: -10.98%;
  width: 117.65%;
}

.student-quiz-list-mobile .vector-lkVzGd {
  background-color: transparent;
  height: 4.48%;
  left: 12.23%;
  position: absolute;
  top: 12.28%;
  width: 8.51%;
}

.student-quiz-list-mobile .vector-lw8NAW {
  background-color: transparent;
  height: 29.85%;
  left: 1.84%;
  position: absolute;
  top: 8.24%;
  width: 19.15%;
}

.student-quiz-list-mobile .vector-mGXgmO {
  background-color: transparent;
  height: 4.48%;
  left: 12.23%;
  position: absolute;
  top: 12.28%;
  width: 8.51%;
}

.student-quiz-list-mobile .vector-mokeKx {
  background-color: transparent;
  height: 14.93%;
  left: 17.32%;
  position: absolute;
  top: 40.61%;
  width: 28.73%;
}

.student-quiz-list-mobile .vector-ms1pvS {
  background-color: transparent;
  height: 41.8%;
  left: 4.2%;
  position: absolute;
  top: 13.83%;
  width: 55.33%;
}

.student-quiz-list-mobile .vector-nD2hpN {
  background-color: transparent;
  height: 101.5%;
  left: 11.25%;
  position: absolute;
  top: -1.01%;
  width: 85.12%;
}

.student-quiz-list-mobile .vector-ngma9X {
  background-color: transparent;
  height: 101.25%;
  left: -4.96%;
  position: absolute;
  top: 0.47%;
  width: 109.92%;
}

.student-quiz-list-mobile .vector-nzNou6 {
  background-color: transparent;
  height: 101.5%;
  left: 11.25%;
  position: absolute;
  top: -1.01%;
  width: 85.12%;
}

.student-quiz-list-mobile .vector-nzuIpS {
  background-color: transparent;
  height: 5.97%;
  left: 36.78%;
  position: absolute;
  top: 28.23%;
  width: 3.19%;
}

.student-quiz-list-mobile .vector-oafYiW {
  background-color: transparent;
  height: 121.98%;
  left: -6.78%;
  position: absolute;
  top: -10.99%;
  width: 113.56%;
}

.student-quiz-list-mobile .vector-oez9Pb {
  background-color: transparent;
  height: 8.96%;
  left: 25.3%;
  position: absolute;
  top: 37.19%;
  width: 12.77%;
}

.student-quiz-list-mobile .vector-pxIogv {
  background-color: transparent;
  height: 121.98%;
  left: -6.78%;
  position: absolute;
  top: -10.99%;
  width: 113.56%;
}

.student-quiz-list-mobile .vector-qGye3v {
  background-color: transparent;
  height: 70.16%;
  left: -0.55%;
  position: absolute;
  top: 8.33%;
  width: 95.76%;
}

.student-quiz-list-mobile .vector-qpftC5 {
  background-color: transparent;
  height: 14.93%;
  left: 17.28%;
  position: absolute;
  top: 40.61%;
  width: 11.7%;
}

.student-quiz-list-mobile .vector-rGRikn {
  background-color: transparent;
  height: 101.25%;
  left: -4.96%;
  position: absolute;
  top: 0.47%;
  width: 109.92%;
}

.student-quiz-list-mobile .vector-rXxPBk {
  background-color: transparent;
  height: 11.94%;
  left: 24.31%;
  position: absolute;
  top: 18.13%;
  width: 14.9%;
}

.student-quiz-list-mobile .vector-reGMLO {
  background-color: transparent;
  height: 120.65%;
  left: -21.65%;
  position: absolute;
  top: -10.33%;
  width: 143.31%;
}

.student-quiz-list-mobile .vector-sku7Fc {
  background-color: transparent;
  height: 13.43%;
  left: 63.81%;
  position: absolute;
  top: 41.24%;
  width: 18.09%;
}

.student-quiz-list-mobile .vector-tQwNUN {
  background-color: transparent;
  height: 14.93%;
  left: 17.32%;
  position: absolute;
  top: 40.61%;
  width: 28.73%;
}

.student-quiz-list-mobile .vector-toBf7r {
  background-color: transparent;
  height: 41.8%;
  left: 4.2%;
  position: absolute;
  top: 13.83%;
  width: 55.33%;
}

.student-quiz-list-mobile .vector-tprfVp {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.36%;
}

.student-quiz-list-mobile .vector-utKR0x {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.36%;
}

.student-quiz-list-mobile .vector-vPuJH9 {
  background-color: transparent;
  height: 32.84%;
  left: 2.49%;
  position: absolute;
  top: 24.54%;
  width: 21.28%;
}

.student-quiz-list-mobile .vector-x2R7zB {
  background-color: transparent;
  height: 70.16%;
  left: -0.67%;
  position: absolute;
  top: 8.45%;
  width: 24.47%;
}

.student-quiz-list-mobile .vector-x5Jnp7 {
  background-color: transparent;
  height: 121.99%;
  left: -21.65%;
  position: absolute;
  top: -10.99%;
  width: 143.3%;
}

.student-quiz-list-mobile .vector-xB1UPi {
  background-color: transparent;
  height: 52.24%;
  left: 49.49%;
  position: absolute;
  top: 8.1%;
  width: 45.75%;
}

.student-quiz-list-mobile .vector-xJcTLo {
  background-color: transparent;
  height: 121.98%;
  left: -7.1%;
  position: absolute;
  top: -10.99%;
  width: 114.19%;
}

.student-quiz-list-mobile .vector-xMrRfb {
  background-color: transparent;
  height: 4.48%;
  left: 12.21%;
  position: absolute;
  top: 12.28%;
  width: 28.73%;
}

.student-quiz-list-mobile .vector-xVlxUo {
  background-color: transparent;
  height: 2.5%;
  left: 48.6%;
  position: absolute;
  top: 48.75%;
  width: 2.79%;
}

.student-quiz-list-mobile .vector-xhepvl {
  background-color: transparent;
  height: 5.97%;
  left: 36.78%;
  position: absolute;
  top: 28.23%;
  width: 3.19%;
}

.student-quiz-list-mobile .vector-xhwx9b {
  background-color: transparent;
  height: 7.46%;
  left: 67.81%;
  position: absolute;
  top: 51.44%;
  width: 7.45%;
}

.student-quiz-list-mobile .vector-xsv7LI {
  background-color: transparent;
  height: 32.5%;
  left: 23.63%;
  position: absolute;
  top: 34.98%;
  width: 52.75%;
}

.student-quiz-list-mobile .vector-xtOtSY {
  background-color: transparent;
  height: 41.8%;
  left: 3.8%;
  position: absolute;
  top: 13.83%;
  width: 20.22%;
}

.student-quiz-list-mobile .vector-xvjzYd {
  background-color: transparent;
  height: 4.48%;
  left: 12.21%;
  position: absolute;
  top: 12.28%;
  width: 28.73%;
}

.student-quiz-list-mobile .vector-ymbxcA {
  background-color: transparent;
  height: 29.85%;
  left: 37.64%;
  position: absolute;
  top: 2.71%;
  width: 22.34%;
}

.student-quiz-list-mobile .vector-zXuwwt {
  background-color: transparent;
  height: 34.33%;
  left: 2.6%;
  position: absolute;
  top: 22.69%;
  width: 58.52%;
}

.student-quiz-list-mobile .vector-zeZVsH {
  background-color: transparent;
  height: 8.96%;
  left: 24.88%;
  position: absolute;
  top: 36.57%;
  width: 7.45%;
}

.student-quiz-list-mobile .vector-zxfIi7 {
  background-color: transparent;
  height: 29.85%;
  left: 1.84%;
  position: absolute;
  top: 8.24%;
  width: 19.15%;
}

.student-quiz-list-mobile .view-all-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 303px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: 77px;
}

.student-quiz-list-mobile .view-forum-CN8bZ4 {
  background-color: transparent;
  height: auto;
  left: 257px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 235px;
  width: 115px;
}

.student-quiz-list-mobile .view-forum-CNpLuU {
  background-color: transparent;
  height: auto;
  left: 257px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 169px;
  width: 115px;
}

.student-quiz-list-mobile .view-forum-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 257px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 103px;
  width: 115px;
}

.student-quiz-list-mobile .what-is-the-si-unit-of-current-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 70px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 74px;
  width: 296px;
}

.student-quiz-list-mobile .what-is-the-sol-f-this-question-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 70px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 140px;
  width: 296px;
}

:root {
  --black: rgba(0, 0, 0, 0.1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --white: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 599px) {
  .student-quiz {
    display: none;
  }

  .quizTile-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 5px;
    height: 30px;
  }

  .graded-AvtzuR {
    background-color: transparent;
    color: rgba(5, 187, 138, 1);
    font-family: "Montserrat", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    height: auto;
    /* left: 575px; */
    letter-spacing: 0px;
    line-height: normal;
    /* position: absolute; */
    text-align: right;
    /* top: 26px; */
    width: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 600px) {
  .student-quiz-list-mobile {
    display: none;
  }
}
