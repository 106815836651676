.wwraper {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.career-form-wrapper {
  border-radius: 8px;
  width: 60%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.career-input-wrapper {
  margin-top: 2%;
}

.internship-page-wrapper {
  width: 80%;
  margin: 3% 0%;
}

.internship-form-wrapper {
  border: 5px solid #dc3545;
  border-radius: 5px;
  padding: 30px;
}

.trg-franchise-from-wrapper {
  width: 60%;
  border: 5px solid #dc3545;
  border-radius: 8px;
  padding: 3%;
}

.trg-franchise-wrapper {
  width: 80%;
  margin: 3% 0%;
}

@media screen and (max-width: 600px) {
  .career-form-wrapper {
    width: 90%;
  }
  .career-input-wrapper {
    padding: 0px 20px;
  }
  .internship-page-wrapper {
    width: 90%;
    margin: 5% 0%;
  }
  .internship-form-wrapper {
    padding: 15px;
  }
  .trg-franchise-from-wrapper {
    width: 100%;
  }
  .trg-franchise-wrapper {
    width: 90%;
  }
}
