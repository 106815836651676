/* screen - student-performance */

.montserrat-medium-blue-zodiac-18px {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-gray-5px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 5px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-gray-7px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-8px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-niagara-5px {
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 5px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-niagara-8px {
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}
.student-performance {
  background-color: rgba(253, 254, 255, 1);
  height: 100vh;
  width: 100%;
}
.student-performance .apply-filters-C61RwL {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: 1.17%;
  left: 27.92%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28.02%;
  width: 4.44%;
}
.student-performance .asked-kber4s {
  background-color: transparent;
  height: auto;
  left: 378px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 149px;
  width: auto;
}
.student-performance .assigned-R3ERnh {
  background-color: transparent;
  height: auto;
  left: 471px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 61px;
  width: auto;
}
.student-performance .assigned-Z1JSTw {
  background-color: transparent;
  height: auto;
  left: 471px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 61px;
  width: auto;
}
.student-performance .assignment-1-aCi14x {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.student-performance .assignment-1-gqbUX1 {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.student-performance .assignment-1-ql7mN0 {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.student-performance .assignment-1-sDLNVS {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.student-performance .assignment-24px-3-DVuPOa {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance .assignment-24px-3-Ll6NDG {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance .assignment-24px-3-MxHjgv {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance .assignment-24px-3-iPxDjX {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance .assignment-24px-3-jHyK8g {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance .assignment-24px-3-kNZYuA {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance .assignment-24px-3-lxV7ix {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance .assignment-24px-3-z4xZZj {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance .assignments-R3ERnh {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 28px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: auto;
}
.student-performance .attendance-75-8FQU39 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 412px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 25px;
  width: auto;
}
.student-performance .biba-sobti-VdQhAS {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 9px;
  width: 71%;
}
.student-performance .bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 42px;
  width: 72%;
}
.student-performance .booked-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 378px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 176px;
  width: auto;
}
.student-performance .cancelled-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 378px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 203px;
  width: auto;
}
.student-performance .choose-subject-i63127302371261-02ks1v {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 43.98%;
}
.student-performance .classes-8FQU39 {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 28px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: auto;
}
.student-performance .completed-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 378px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 149px;
  width: auto;
}
.student-performance .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;
  left: 155px;
  position: absolute;
  top: 45px;
  width: 212px;
}
.student-performance .component-29-2osFvC {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 24px;
  left: 17px;
  position: absolute;
  top: 14px;
  width: 24px;
}
.student-performance .component-29-CX7m3G {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 24px;
  left: 17px;
  position: absolute;
  top: 14px;
  width: 24px;
}
.student-performance .component-29-aCi14x {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 24px;
  left: 17px;
  position: absolute;
  top: 14px;
  width: 24px;
}
.student-performance .component-29-gqbUX1 {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 24px;
  left: 17px;
  position: absolute;
  top: 14px;
  width: 24px;
}
.student-performance .component-29-hlxJRL {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 24px;
  left: 17px;
  position: absolute;
  top: 14px;
  width: 24px;
}
.student-performance .component-29-ql7mN0 {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 24px;
  left: 17px;
  position: absolute;
  top: 14px;
  width: 24px;
}
.student-performance .component-29-sDLNVS {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 24px;
  left: 17px;
  position: absolute;
  top: 14px;
  width: 24px;
}
.student-performance .component-29-sbe5OJ {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 24px;
  left: 17px;
  position: absolute;
  top: 14px;
  width: 24px;
}
.student-performance .component-30-C61RwL {
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 33px;
  left: 155px;
  position: absolute;
  top: 307px;
  width: 191px;
}
.student-performance .donut-chart-1-8FQU39 {
  background-color: transparent;
  height: 254px;
  left: 69px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 230px;
}
.student-performance .donut-chart-1-R3ERnh {
  background-color: transparent;
  height: 254px;
  left: 69px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 230px;
}
.student-performance .donut-chart-1-Z1JSTw {
  background-color: transparent;
  height: 254px;
  left: 69px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 230px;
}
.student-performance .donut-chart-1-kber4s {
  background-color: transparent;
  height: 254px;
  left: 69px;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 230px;
}
.student-performance .doubts-kber4s {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 28px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: auto;
}
.student-performance .ellipse-2-OSIBbx {
  background-color: transparent;
  height: 19.05%;
  left: 57.14%;
  position: absolute;
  top: 21.43%;
  width: 19.05%;
}
.student-performance .ellipse-5-8FQU39 {
  background-color: transparent;
  height: 12px;
  left: 355px;
  position: absolute;
  top: 149px;
  width: 12px;
}
.student-performance .ellipse-5-R3ERnh {
  background-color: transparent;
  height: 12px;
  left: 448px;
  position: absolute;
  top: 61px;
  width: 12px;
}
.student-performance .ellipse-5-Z1JSTw {
  background-color: transparent;
  height: 12px;
  left: 448px;
  position: absolute;
  top: 61px;
  width: 12px;
}
.student-performance .ellipse-5-kber4s {
  background-color: transparent;
  height: 12px;
  left: 355px;
  position: absolute;
  top: 149px;
  width: 12px;
}
.student-performance .ellipse-6-8FQU39 {
  background-color: transparent;
  height: 12px;
  left: 355px;
  position: absolute;
  top: 176px;
  width: 12px;
}
.student-performance .ellipse-6-R3ERnh {
  background-color: transparent;
  height: 12px;
  left: 448px;
  position: absolute;
  top: 88px;
  width: 12px;
}
.student-performance .ellipse-6-Z1JSTw {
  background-color: transparent;
  height: 12px;
  left: 448px;
  position: absolute;
  top: 88px;
  width: 12px;
}
.student-performance .ellipse-6-kber4s {
  background-color: transparent;
  height: 12px;
  left: 355px;
  position: absolute;
  top: 176px;
  width: 12px;
}
.student-performance .ellipse-7-8FQU39 {
  background-color: transparent;
  height: 12px;
  left: 355px;
  position: absolute;
  top: 203px;
  width: 12px;
}
.student-performance .expandmore-24px-1-02ks1v {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}
.student-performance .group-22-C61RwL {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}
.student-performance .group-57-8FQU39 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 560px;
}
.student-performance .group-57-R3ERnh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 560px;
}
.student-performance .group-57-Z1JSTw {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 560px;
}
.student-performance .group-57-kber4s {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 560px;
}
.student-performance .group-69-C61RwL {
  background-color: transparent;
  height: 335px;
  left: 10%;
  position: absolute;
  top: 371px;
  width: 570px;
}
.student-performance .group-70-C61RwL {
  background-color: transparent;
  height: 335px;
  right: 5%;
  position: absolute;
  top: 371px;
  width: 566px;
}
.student-performance .group-71-C61RwL {
  background-color: transparent;
  height: 335px;
  left: 10%;
  position: absolute;
  top: 766px;
  width: 566px;
}
.student-performance .group-72-C61RwL {
  background-color: transparent;
  height: 52px;
  left: 183px;
  position: absolute;
  top: 841px;
  width: 263px;
}
.student-performance .group-73-C61RwL {
  background-color: transparent;
  height: 52px;
  left: 183px;
  position: absolute;
  top: 904px;
  width: 263px;
}
.student-performance .group-74-C61RwL {
  background-color: transparent;
  height: 52px;
  left: 183px;
  position: absolute;
  top: 967px;
  width: 263px;
}
.student-performance .group-75-C61RwL {
  background-color: transparent;
  height: 52px;
  left: 182px;
  position: absolute;
  top: 1030px;
  width: 263px;
}
.student-performance .group-76-C61RwL {
  background-color: transparent;
  height: 335px;
  right: 5%;
  position: absolute;
  top: 765px;
  width: 566px;
}
.student-performance .group-77-C61RwL {
  background-color: transparent;
  height: 52px;
  left: 820px;
  position: absolute;
  top: 840px;
  width: 263px;
}
.student-performance .group-78-C61RwL {
  background-color: transparent;
  height: 52px;
  left: 820px;
  position: absolute;
  top: 903px;
  width: 263px;
}
.student-performance .group-79-C61RwL {
  background-color: transparent;
  height: 52px;
  left: 820px;
  position: absolute;
  top: 966px;
  width: 263px;
}
.student-performance .group-80-C61RwL {
  background-color: transparent;
  height: 52px;
  left: 819px;
  position: absolute;
  top: 1029px;
  width: 263px;
}
.student-performance .group-C61RwL {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 77.34%;
  width: 1.67%;
}
.student-performance .group-VMr6Om {
  background-color: transparent;
  height: 24px;
  left: 24px;
  position: absolute;
  top: 879px;
  width: 24px;
}
.student-performance .group-XSCsvl {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-performance .group-mzXdH9 {
  background-color: transparent;
  height: 1.77%;
  left: 1.53%;
  position: absolute;
  top: 41.21%;
  width: 1.39%;
}
.student-performance .line-5-9p2GBv {
  background-color: transparent;
  height: 99.05%;
  left: 0.78%;
  position: absolute;
  top: 0.48%;
  width: 99.53%;
}
.student-performance .line-6-9p2GBv {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}
.student-performance .mathematics-dr-yashna-sharma-2osFvC {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: auto;
}
.student-performance .mathematics-dr-yashna-sharma-CX7m3G {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: auto;
}
.student-performance .mathematics-dr-yashna-sharma-aCi14x {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: auto;
}
.student-performance .mathematics-dr-yashna-sharma-gqbUX1 {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: auto;
}
.student-performance .mathematics-dr-yashna-sharma-hlxJRL {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: auto;
}
.student-performance .mathematics-dr-yashna-sharma-ql7mN0 {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: auto;
}
.student-performance .mathematics-dr-yashna-sharma-sDLNVS {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: auto;
}
.student-performance .mathematics-dr-yashna-sharma-sbe5OJ {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: auto;
}
.student-performance .my-performance-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 40px;

  left: 6%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 220px;
  width: 80%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.student-performance .notifications-24px-2-1-C61RwL {
  background-color: transparent;
  height: 42px;
  left: 976px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 42px;
}
.student-performance .quiz-1-2osFvC {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.student-performance .quiz-1-CX7m3G {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.student-performance .quiz-1-hlxJRL {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.student-performance .quiz-1-sbe5OJ {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.student-performance .quizzes-Z1JSTw {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 28px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: auto;
}
.student-performance .rectangle-24-C61RwL-stu-s1 {
  background-color: #1f2531;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 63px;

  overflow-y: scroll;
  padding: 150px 0px 20px 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.student-performance .rectangle-40-2osFvC {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 52px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 257px;
}
.student-performance .rectangle-40-CX7m3G {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 52px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 257px;
}
.student-performance .rectangle-40-aCi14x {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 52px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 257px;
}
.student-performance .rectangle-40-gqbUX1 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 52px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 257px;
}
.student-performance .rectangle-40-hlxJRL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 52px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 257px;
}
.student-performance .rectangle-40-ql7mN0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 52px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 257px;
}
.student-performance .rectangle-40-sDLNVS {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 52px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 257px;
}
.student-performance .rectangle-40-sbe5OJ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 52px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 257px;
}
.student-performance .rectangle-41-C61RwL {
  background-color: rgb(220, 53, 69);
  border-radius: 10px;
  height: 3.22%;
  left: 26.46%;
  position: absolute;
  top: 26.94%;
  width: 7.08%;
}
.student-performance .rectangle-7-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 630px;
  position: absolute;
  top: 631px;
  width: 1px;
}
.student-performance .responded-kber4s {
  background-color: transparent;
  height: auto;
  left: 378px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 176px;
  width: auto;
}
/* .student-performance .span0-3EE9bF {
  color: rgb(220, 53, 69);
  font-style: normal;
} */
.student-performance .span1-3EE9bF {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 700;
}
.student-performance .stickynote2-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 793px;
  width: 24px;
}
.student-performance .submitted-2osFvC {
  background-color: transparent;
  height: auto;
  left: 203px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: auto;
}
.student-performance .submitted-CX7m3G {
  background-color: transparent;
  height: auto;
  left: 199px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: auto;
}
.student-performance .submitted-R3ERnh {
  background-color: transparent;
  height: auto;
  left: 471px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 88px;
  width: auto;
}
.student-performance .submitted-Z1JSTw {
  background-color: transparent;
  height: auto;
  left: 471px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 88px;
  width: auto;
}
.student-performance .submitted-aCi14x {
  background-color: transparent;
  height: auto;
  left: 199px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: auto;
}
.student-performance .submitted-gqbUX1 {
  background-color: transparent;
  height: auto;
  left: 199px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: auto;
}
.student-performance .submitted-hlxJRL {
  background-color: transparent;
  height: auto;
  left: 199px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: auto;
}
.student-performance .submitted-ql7mN0 {
  background-color: transparent;
  height: auto;
  left: 199px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: auto;
}
.student-performance .submitted-sDLNVS {
  background-color: transparent;
  height: auto;
  left: 199px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: auto;
}
.student-performance .submitted-sbe5OJ {
  background-color: transparent;
  height: auto;
  left: 199px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: auto;
}
.student-performance .vector-2P4qUJ {
  background-color: transparent;
  height: 2.16%;
  left: 1.53%;
  position: absolute;
  top: 19.51%;
  width: 1.67%;
}
.student-performance .vector-2bEgZy {
  background-color: transparent;
  height: 82.5%;
  left: 0px;
  position: absolute;
  top: 18.75%;
  width: 80%;
}
.student-performance .vector-3ouhqe {
  background-color: transparent;
  height: 82.5%;
  left: 20%;
  position: absolute;
  top: -1.25%;
  width: 80%;
}
.student-performance .vector-5FRq6D {
  background-color: transparent;
  height: 32.5%;
  left: 25%;
  position: absolute;
  top: 34.98%;
  width: 50%;
}
.student-performance .vector-5pa4xi {
  background-color: transparent;
  height: 102.08%;
  left: 0px;
  position: absolute;
  top: -1.04%;
  width: 100%;
}
.student-performance .vector-5tYqrB {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}
.student-performance .vector-8gxHlR {
  background-color: transparent;
  height: 37.5%;
  left: 18.76%;
  position: absolute;
  top: 15.83%;
  width: 62.5%;
}
.student-performance .vector-9Sr3Gx {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}
.student-performance .vector-C61RwL {
  background-color: transparent;
  height: 2.16%;
  left: 1.32%;
  position: absolute;
  top: 94.87%;
  width: 1.67%;
}
.student-performance .vector-CLdsug {
  background-color: transparent;
  height: 82.14%;
  left: 16.67%;
  position: absolute;
  top: 9.97%;
  width: 66.67%;
}
.student-performance .vector-G1vXPs {
  background-color: transparent;
  height: 58.33%;
  left: 20.83%;
  position: absolute;
  top: 20.83%;
  width: 58.33%;
}
.student-performance .vector-HlKRTo {
  background-color: transparent;
  height: 2.16%;
  left: 1.74%;
  position: absolute;
  top: 34.06%;
  width: 1.67%;
}
.student-performance .vector-IRl1U7 {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}
.student-performance .vector-J1YQmd {
  background-color: transparent;
  height: 2.16%;
  left: 1.67%;
  position: absolute;
  top: 55.84%;
  width: 1.67%;
}
.student-performance .vector-MX8ksh {
  background-color: transparent;
  height: 4.17%;
  left: 47.92%;
  position: absolute;
  top: 47.92%;
  width: 4.17%;
}
.student-performance .vector-McuNxD {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}
.student-performance .vector-NOXmfT {
  background-color: transparent;
  height: 1.88%;
  left: 48.96%;
  position: absolute;
  top: 49.06%;
  width: 2.08%;
}
.student-performance .vector-OSIBbx {
  background-color: transparent;
  height: 1.19%;
  left: 49.4%;
  position: absolute;
  top: 49.4%;
  width: 1.19%;
}
.student-performance .vector-Ojow4P {
  background-color: transparent;
  height: 4.17%;
  left: 47.92%;
  position: absolute;
  top: 47.92%;
  width: 4.17%;
}
.student-performance .vector-QxM5SU {
  background-color: transparent;
  height: 2.16%;
  left: 1.53%;
  position: absolute;
  top: 26.74%;
  width: 1.67%;
}
.student-performance .vector-RkGPJa {
  background-color: transparent;
  height: 14.58%;
  left: 58.75%;
  position: absolute;
  top: 48.26%;
  width: 12.5%;
}
.student-performance .vector-ThyROi {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-performance .vector-UwmKsc {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}
.student-performance .vector-VMr6Om {
  background-color: transparent;
  height: 0px;
  left: 2.34%;
  position: absolute;
  top: 49.59%;
  width: 0px;
}
.student-performance .vector-VdQhAS {
  background-color: transparent;
  height: 100.64%;
  left: 73.42%;
  position: absolute;
  top: -0.32%;
  width: 25.32%;
}
.student-performance .vector-VkPPtx {
  background-color: transparent;
  height: 0px;
  left: 2.34%;
  position: absolute;
  top: 64.04%;
  width: 0px;
}
.student-performance .vector-YDNU6K {
  background-color: transparent;
  height: 4.17%;
  left: 47.92%;
  position: absolute;
  top: 47.92%;
  width: 4.17%;
}
.student-performance .vector-YWzA7M {
  background-color: transparent;
  height: 2.16%;
  left: 1.74%;
  position: absolute;
  top: 63.16%;
  width: 1.67%;
}
.student-performance .vector-Z3Homl {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}
.student-performance .vector-Z87Q8p {
  background-color: transparent;
  height: 4.17%;
  left: 47.92%;
  position: absolute;
  top: 47.92%;
  width: 4.17%;
}
.student-performance .vector-a5WPlX {
  background-color: transparent;
  height: 0px;
  left: 2.34%;
  position: absolute;
  top: 35.13%;
  width: 0px;
}
.student-performance .vector-hGGfYL {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}
.student-performance .vector-hMWl3h {
  background-color: transparent;
  height: 4.17%;
  left: 47.92%;
  position: absolute;
  top: 47.92%;
  width: 4.17%;
}
.student-performance .vector-hyPzor {
  background-color: transparent;
  height: 4.17%;
  left: 47.92%;
  position: absolute;
  top: 47.92%;
  width: 4.17%;
}
.student-performance .vector-ilb7Z4 {
  background-color: transparent;
  height: 14.58%;
  left: 28.75%;
  position: absolute;
  top: 48.26%;
  width: 12.5%;
}
.student-performance .vector-mzXdH9 {
  background-color: transparent;
  height: 2.16%;
  left: 1.81%;
  position: absolute;
  top: 48.61%;
  width: 1.67%;
}
.student-performance .vector-o8aFZp {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}
.student-performance .vector-oLJRUl {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}
.student-performance .vector-qr8e7q {
  background-color: transparent;
  height: 0px;
  left: 2.34%;
  position: absolute;
  top: 56.81%;
  width: 0px;
}
.student-performance .vector-wGeoOk {
  background-color: transparent;
  height: 2.5%;
  left: 48.75%;
  position: absolute;
  top: 48.75%;
  width: 2.5%;
}
.student-performance .vector-x8YWNM {
  background-color: transparent;
  height: 4.17%;
  left: 47.92%;
  position: absolute;
  top: 47.92%;
  width: 4.17%;
}
.student-performance .vector-xjYbuu {
  background-color: transparent;
  height: 4.17%;
  left: 47.92%;
  position: absolute;
  top: 47.92%;
  width: 4.17%;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - student-performance-mobile */

.montserrat-medium-blue-zodiac-18px {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-gray-5px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 5px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-gray-7px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-8px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-niagara-5px {
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 5px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-niagara-8px {
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}
.student-performance-mobile {
  background-color: rgba(253, 254, 255, 1);
  height: 100vh;
  margin: 0px;
  min-height: 1285px;
  min-width: 414px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.student-performance-mobile .apply-filters-C61RwL {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: 1.03%;
  left: 65.94%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15.27%;
  width: 15.46%;
}
.student-performance-mobile .asked-kber4s {
  background-color: transparent;
  height: auto;
  left: 254px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 98px;
  width: 37px;
}
.student-performance-mobile .assigned-R3ERnh {
  background-color: transparent;
  height: auto;
  left: 292px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 36px;
  width: 54px;
}
.student-performance-mobile .assigned-Z7HFmV {
  background-color: transparent;
  height: auto;
  left: 292px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 291px;
  width: 54px;
}
.student-performance-mobile .assignment-1-7olElM {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 4px;
  width: 64px;
}
.student-performance-mobile .assignment-1-ahGy4x {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 7px;
  width: 64px;
}
.student-performance-mobile .assignment-1-gqbUX1 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.student-performance-mobile .assignment-1-jQ4qbJ {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 7px;
  width: 64px;
}
.student-performance-mobile .assignment-1-ojBfAf {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 7px;
  width: 64px;
}
.student-performance-mobile .assignment-24px-3-Ll6NDG {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance-mobile .assignment-24px-3-VlxxiV {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance-mobile .assignment-24px-3-f6UJq1 {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance-mobile .assignment-24px-3-hqjSEz {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance-mobile .assignment-24px-3-iPxDjX {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance-mobile .assignment-24px-3-jHyK8g {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance-mobile .assignment-24px-3-kNZYuA {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: absolute;
  top: 25%;
  width: 50%;
}
.student-performance-mobile .assignment-24px-3-lxV7ix {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance-mobile .assignment-24px-3-xwG3Ia {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}
.student-performance-mobile .assignments-R3ERnh {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 11px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 125px;
}
.student-performance-mobile .attendance-75-8FQU39 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 234px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 17px;
  width: 126px;
}
.student-performance-mobile .booked-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 254px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 118px;
  width: 43px;
}
.student-performance-mobile .cancelled-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 254px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 136px;
  width: 54px;
}
.student-performance-mobile .choose-subject-i63126022371261-02ks1v {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 43.98%;
}
.student-performance-mobile .classes-8FQU39 {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 74px;
}
.student-performance-mobile .completed-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 254px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 100px;
  width: 64px;
}
.student-performance-mobile .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 45px;
  left: 26px;
  position: absolute;
  top: 24px;
  width: 87px;
}
.student-performance-mobile .component-29-2osFvC {
  background-color: transparent;
  height: 14px;
  left: 11px;
  position: absolute;
  top: 8px;
  width: 15px;
}
.student-performance-mobile .component-29-7olElM {
  background-color: transparent;
  background-image: url(./img/ellipse-4-8@2x.png);
  background-size: 100% 100%;
  height: 14px;
  left: 11px;
  position: absolute;
  top: 8px;
  width: 15px;
}
.student-performance-mobile .component-29-CX7m3G {
  background-color: transparent;
  background-image: url(./img/ellipse-4-8@2x.png);
  background-size: 100% 100%;
  height: 14px;
  left: 11px;
  position: absolute;
  top: 8px;
  width: 15px;
}
.student-performance-mobile .component-29-ahGy4x {
  background-color: transparent;
  background-image: url(./img/ellipse-4-8@2x.png);
  background-size: 100% 100%;
  height: 14px;
  left: 11px;
  position: absolute;
  top: 8px;
  width: 15px;
}
.student-performance-mobile .component-29-gqbUX1 {
  background-color: transparent;
  background-image: url(./img/ellipse-4-12@2x.png);
  background-size: 100% 100%;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.student-performance-mobile .component-29-hlxJRL {
  background-color: transparent;
  background-image: url(./img/ellipse-4-8@2x.png);
  background-size: 100% 100%;
  height: 14px;
  left: 11px;
  position: absolute;
  top: 8px;
  width: 15px;
}
.student-performance-mobile .component-29-jQ4qbJ {
  background-color: transparent;
  background-image: url(./img/ellipse-4-8@2x.png);
  background-size: 100% 100%;
  height: 14px;
  left: 11px;
  position: absolute;
  top: 8px;
  width: 15px;
}
.student-performance-mobile .component-29-ojBfAf {
  background-color: transparent;
  background-image: url(./img/ellipse-4-8@2x.png);
  background-size: 100% 100%;
  height: 14px;
  left: 11px;
  position: absolute;
  top: 8px;
  width: 15px;
}
.student-performance-mobile .component-29-sbe5OJ {
  background-color: transparent;
  background-image: url(./img/ellipse-4-8@2x.png);
  background-size: 100% 100%;
  height: 14px;
  left: 11px;
  position: absolute;
  top: 8px;
  width: 15px;
}
.student-performance-mobile .component-30-C61RwL {
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 33px;
  left: 26px;
  position: absolute;
  top: 186px;
  width: 191px;
}
.student-performance-mobile .component-6-C61RwL {
  background-color: transparent;
  height: 18px;
  left: 362px;
  position: absolute;
  top: 37px;
  width: 23px;
}
.student-performance-mobile .donut-chart-1-8FQU39 {
  background-color: transparent;
  height: 172px;
  left: 46px;
  object-fit: cover;
  position: absolute;
  top: 36px;
  width: 155px;
}
.student-performance-mobile .donut-chart-1-R3ERnh {
  background-color: transparent;
  height: 71px;
  left: 197px;
  object-fit: cover;
  position: absolute;
  top: 17px;
  width: 64px;
}
.student-performance-mobile .donut-chart-1-kber4s {
  background-color: transparent;
  height: 172px;
  left: 46px;
  object-fit: cover;
  position: absolute;
  top: 36px;
  width: 155px;
}
.student-performance-mobile .donut-chart-2-R3ERnh {
  background-color: transparent;
  height: 71px;
  left: 197px;
  object-fit: cover;
  position: absolute;
  top: 272px;
  width: 64px;
}
.student-performance-mobile .doubts-kber4s {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 72px;
}
.student-performance-mobile .ellipse-4-kNZYuA {
  background-color: transparent;
  height: 104.72%;
  left: -2.13%;
  position: absolute;
  top: -2.36%;
  width: 104.26%;
}
.student-performance-mobile .ellipse-5-8FQU39 {
  background-color: transparent;
  height: 8px;
  left: 238px;
  position: absolute;
  top: 100px;
  width: 9px;
}
.student-performance-mobile .ellipse-5-R3ERnh {
  background-color: transparent;
  height: 9px;
  left: 277px;
  position: absolute;
  top: 38px;
  width: 8px;
}
.student-performance-mobile .ellipse-5-kber4s {
  background-color: transparent;
  height: 8px;
  left: 238px;
  position: absolute;
  top: 100px;
  width: 9px;
}
.student-performance-mobile .ellipse-6-8FQU39 {
  background-color: transparent;
  height: 8px;
  left: 238px;
  position: absolute;
  top: 118px;
  width: 9px;
}
.student-performance-mobile .ellipse-6-R3ERnh {
  background-color: transparent;
  height: 8px;
  left: 277px;
  position: absolute;
  top: 56px;
  width: 8px;
}
.student-performance-mobile .ellipse-6-kber4s {
  background-color: transparent;
  height: 8px;
  left: 238px;
  position: absolute;
  top: 118px;
  width: 9px;
}
.student-performance-mobile .ellipse-7-8FQU39 {
  background-color: transparent;
  height: 8px;
  left: 238px;
  position: absolute;
  top: 136px;
  width: 9px;
}
.student-performance-mobile .ellipse-7-R3ERnh {
  background-color: transparent;
  height: 8px;
  left: 277px;
  position: absolute;
  top: 293px;
  width: 8px;
}
.student-performance-mobile .ellipse-8-R3ERnh {
  background-color: transparent;
  height: 8px;
  left: 277px;
  position: absolute;
  top: 311px;
  width: 8px;
}
.student-performance-mobile .expandmore-24px-1-02ks1v {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}
.student-performance-mobile .group-57-8FQU39 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 225px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.student-performance-mobile .group-57-R3ERnh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 225px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.student-performance-mobile .group-57-Z1JSTw {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 225px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.student-performance-mobile .group-57-kber4s {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 225px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.student-performance-mobile .group-69-C61RwL {
  background-color: transparent;
  height: 225px;
  left: 19px;
  position: absolute;
  top: 241px;
  width: 386px;
}
.student-performance-mobile .group-70-C61RwL {
  background-color: transparent;
  height: 225px;
  left: 19px;
  position: absolute;
  top: 497px;
  width: 382px;
}
.student-performance-mobile .group-71-C61RwL {
  background-color: transparent;
  height: 343px;
  left: 19px;
  position: absolute;
  top: 753px;
  width: 386px;
}
.student-performance-mobile .group-72-C61RwL {
  background-color: transparent;
  height: 35px;
  left: -203px;
  position: absolute;
  top: 921px;
  width: 179px;
}
.student-performance-mobile .group-76-C61RwL {
  background-color: transparent;
  height: 225px;
  left: 19px;
  position: absolute;
  top: 1009px;
  width: 378px;
}
.student-performance-mobile .group-77-C61RwL {
  background-color: transparent;
  height: 30px;
  left: 34px;
  position: absolute;
  top: 1063px;
  width: 175px;
}
.student-performance-mobile .group-78-C61RwL {
  background-color: transparent;
  height: 30px;
  left: 38px;
  position: absolute;
  top: 1101px;
  width: 165px;
}
.student-performance-mobile .group-79-C61RwL {
  background-color: transparent;
  height: 30px;
  left: 38px;
  position: absolute;
  top: 1139px;
  width: 165px;
}
.student-performance-mobile .group-80-C61RwL {
  background-color: transparent;
  height: 30px;
  left: 38px;
  position: absolute;
  top: 1177px;
  width: 165px;
}
.student-performance-mobile .group-81-C61RwL {
  background-color: transparent;
  height: 30px;
  left: 33px;
  position: absolute;
  top: 811px;
  width: 170px;
}
.student-performance-mobile .group-82-C61RwL {
  background-color: transparent;
  height: 30px;
  left: 38px;
  position: absolute;
  top: 848px;
  width: 165px;
}
.student-performance-mobile .group-83-C61RwL {
  background-color: transparent;
  height: 30px;
  left: 38px;
  position: absolute;
  top: 885px;
  width: 165px;
}
.student-performance-mobile .group-84-C61RwL {
  background-color: transparent;
  height: 30px;
  left: 38px;
  position: absolute;
  top: 922px;
  width: 165px;
}
.student-performance-mobile .line-5-9p2GBv {
  background-color: transparent;
  height: 101.11%;
  left: 0.55%;
  position: absolute;
  top: -0.56%;
  width: 100%;
}
.student-performance-mobile .line-6-9p2GBv {
  background-color: transparent;
  height: 102.22%;
  left: -0.29%;
  position: absolute;
  top: -1.11%;
  width: 100.57%;
}
.student-performance-mobile .mathematics-dr-yashna-sharma-2osFvC {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 13.5px;
  width: 89px;
}
.student-performance-mobile .mathematics-dr-yashna-sharma-7olElM {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 89px;
}
.student-performance-mobile .mathematics-dr-yashna-sharma-CX7m3G {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 89px;
}
.student-performance-mobile .mathematics-dr-yashna-sharma-ahGy4x {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 89px;
}
.student-performance-mobile .mathematics-dr-yashna-sharma-gqbUX1 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.student-performance-mobile .mathematics-dr-yashna-sharma-hlxJRL {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 89px;
}
.student-performance-mobile .mathematics-dr-yashna-sharma-jQ4qbJ {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 89px;
}
.student-performance-mobile .mathematics-dr-yashna-sharma-ojBfAf {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 89px;
}
.student-performance-mobile .mathematics-dr-yashna-sharma-sbe5OJ {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 89px;
}
.student-performance-mobile .my-performance-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 38px;

  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 130px;
  width: 325px;
}
.student-performance-mobile .quiz-1-2osFvC {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 4px;
  width: 38px;
}
.student-performance-mobile .quiz-1-CX7m3G {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 8px;
  width: 38px;
}
.student-performance-mobile .quiz-1-hlxJRL {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 8px;
  width: 38px;
}
.student-performance-mobile .quiz-1-sbe5OJ {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 8px;
  width: 38px;
}
.student-performance-mobile .quizzes-Z1JSTw {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 74px;
}
.student-performance-mobile .rectangle-40-2osFvC {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 169px;
}
.student-performance-mobile .rectangle-40-7olElM {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 169px;
}
.student-performance-mobile .rectangle-40-CX7m3G {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 159px;
}
.student-performance-mobile .rectangle-40-ahGy4x {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 159px;
}
.student-performance-mobile .rectangle-40-gqbUX1 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.student-performance-mobile .rectangle-40-hlxJRL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 159px;
}
.student-performance-mobile .rectangle-40-jQ4qbJ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 159px;
}
.student-performance-mobile .rectangle-40-ojBfAf {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 159px;
}
.student-performance-mobile .rectangle-40-sbe5OJ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 159px;
}
.student-performance-mobile .rectangle-41-C61RwL {
  background-color: rgb(220, 53, 69);
  border-radius: 10px;
  height: 2.84%;
  left: 60.87%;
  position: absolute;
  top: 14.32%;
  width: 24.64%;
}
.student-performance-mobile .rectangle-48-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.student-performance-mobile .rectangle-49-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 38.89%;
  width: 100%;
}
.student-performance-mobile .rectangle-50-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 77.78%;
  width: 100%;
}
.student-performance-mobile .responded-kber4s {
  background-color: transparent;
  height: auto;
  left: 254px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 116px;
  width: 62px;
}
/* .student-performance-mobile .span0-3EE9bF {
  color: rgb(220, 53, 69);
  font-style: normal;
} */
.student-performance-mobile .span1-3EE9bF {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.student-performance-mobile .submitted-2osFvC {
  background-color: transparent;
  height: auto;
  left: 125px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 9px;
  width: 35px;
}
.student-performance-mobile .submitted-7olElM {
  background-color: transparent;
  height: auto;
  left: 124px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 9px;
  width: 35px;
}
.student-performance-mobile .submitted-CX7m3G {
  background-color: transparent;
  height: auto;
  left: 117px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 13px;
  width: 35px;
}
.student-performance-mobile .submitted-R3ERnh {
  background-color: transparent;
  height: auto;
  left: 292px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 54px;
  width: 58px;
}
.student-performance-mobile .submitted-Z7HFmV {
  background-color: transparent;
  height: auto;
  left: 292px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 309px;
  width: 58px;
}
.student-performance-mobile .submitted-ahGy4x {
  background-color: transparent;
  height: auto;
  left: 117px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 13px;
  width: 35px;
}
.student-performance-mobile .submitted-gqbUX1 {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.student-performance-mobile .submitted-hlxJRL {
  background-color: transparent;
  height: auto;
  left: 117px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 13px;
  width: 35px;
}
.student-performance-mobile .submitted-jQ4qbJ {
  background-color: transparent;
  height: auto;
  left: 117px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 13px;
  width: 35px;
}
.student-performance-mobile .submitted-ojBfAf {
  background-color: transparent;
  height: auto;
  left: 117px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 13px;
  width: 35px;
}
.student-performance-mobile .submitted-sbe5OJ {
  background-color: transparent;
  height: auto;
  left: 117px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 13px;
  width: 35px;
}
.student-performance-mobile .vector-5FRq6D {
  background-color: transparent;
  height: 32.5%;
  left: 25%;
  position: absolute;
  top: 34.98%;
  width: 50%;
}
.student-performance-mobile .vector-6dDhYK {
  background-color: transparent;
  height: 7.22%;
  left: 46.64%;
  position: absolute;
  top: 46.39%;
  width: 6.73%;
}
.student-performance-mobile .vector-9Sr3Gx {
  background-color: transparent;
  height: 93.89%;
  left: 9.64%;
  position: absolute;
  top: -1.11%;
  width: 80.72%;
}
.student-performance-mobile .vector-A3ehtq {
  background-color: transparent;
  height: 93.89%;
  left: 9.64%;
  position: absolute;
  top: -1.11%;
  width: 80.72%;
}
.student-performance-mobile .vector-JTwnfR {
  background-color: transparent;
  height: 93.89%;
  left: 9.64%;
  position: absolute;
  top: -1.11%;
  width: 80.72%;
}
.student-performance-mobile .vector-MX8ksh {
  background-color: transparent;
  height: 7.22%;
  left: 46.64%;
  position: absolute;
  top: 46.39%;
  width: 6.73%;
}
.student-performance-mobile .vector-Mbe0xv {
  background-color: transparent;
  height: 7.22%;
  left: 46.64%;
  position: absolute;
  top: 46.39%;
  width: 6.73%;
}
.student-performance-mobile .vector-Ojow4P {
  background-color: transparent;
  height: 7.22%;
  left: 46.64%;
  position: absolute;
  top: 46.39%;
  width: 6.73%;
}
.student-performance-mobile .vector-YDNU6K {
  background-color: transparent;
  height: 6.2%;
  left: 46.9%;
  position: absolute;
  top: 46.9%;
  width: 6.2%;
}
.student-performance-mobile .vector-Z3Homl {
  background-color: transparent;
  height: 93.89%;
  left: 9.64%;
  position: absolute;
  top: -1.11%;
  width: 80.72%;
}
.student-performance-mobile .vector-hGGfYL {
  background-color: transparent;
  height: 93.05%;
  left: 9.68%;
  position: absolute;
  top: -0.69%;
  width: 80.64%;
}
.student-performance-mobile .vector-hMWl3h {
  background-color: transparent;
  height: 7.22%;
  left: 46.64%;
  position: absolute;
  top: 46.39%;
  width: 6.73%;
}
.student-performance-mobile .vector-o8aFZp {
  background-color: transparent;
  height: 86.67%;
  left: 6.28%;
  position: absolute;
  top: 2.5%;
  width: 87.45%;
}
.student-performance-mobile .vector-oLJRUl {
  background-color: transparent;
  height: 93.89%;
  left: 9.64%;
  position: absolute;
  top: -1.11%;
  width: 80.72%;
}
.student-performance-mobile .vector-p03cwU {
  background-color: transparent;
  height: 7.22%;
  left: 46.64%;
  position: absolute;
  top: 46.39%;
  width: 6.73%;
}
.student-performance-mobile .vector-qNxI6b {
  background-color: transparent;
  height: 7.22%;
  left: 46.64%;
  position: absolute;
  top: 46.39%;
  width: 6.73%;
}
.student-performance-mobile .vector-utxsmi {
  background-color: transparent;
  height: 93.89%;
  left: 9.64%;
  position: absolute;
  top: -1.11%;
  width: 80.72%;
}
.student-performance-mobile .vector-wGeoOk {
  background-color: transparent;
  height: 2.5%;
  left: 48.75%;
  position: absolute;
  top: 48.75%;
  width: 2.5%;
}
.student-performance-mobile .vector-x03bhR {
  background-color: transparent;
  height: 93.89%;
  left: 9.64%;
  position: absolute;
  top: -1.11%;
  width: 80.72%;
}
.student-performance-mobile .vector-xjYbuu {
  background-color: transparent;
  height: 7.22%;
  left: 46.64%;
  position: absolute;
  top: 46.39%;
  width: 6.73%;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .student-performance {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .student-performance-mobile {
    display: none;
  }
}

.Modal_in_student_per {
  position: fixed;
  top: 5%;
  left: 3%;

  z-index: 600;
  width: 94%;
  min-height: 640px;

  background: rgb(252, 252, 252);
  border-radius: 5px;

  padding: 20px 6px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 2px 2px #ccc;

  transition: transform 0.4s ease-in-out;
}

.Modal_inner_scroll {
  width: 100%;
  height: 600px;

  overflow-y: auto;
  display: grid;

  grid-template-columns: 1fr;
}

.Div_to_have_chart {
  width: auto;
  height: 300px;
  padding: 5px;
  margin: 10px 0px;

  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.showModalTogler {
  width: 120px;
  height: 30px;

  border: 1px solid #ccc;
  border-radius: 10px;

  font-size: 11px;
  font-weight: 550;
}

@media screen and (min-width: 600px) {
  .Modal_in_student_per {
    position: fixed;
    top: 2%;
    left: 15%;

    z-index: 600;
    width: 70%;
    height: 700px;

    background: rgb(252, 252, 252);
    border-radius: 5px;

    padding: 20px 6px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 2px 2px #ccc;

    transition: transform 0.4s ease-in-out;
  }

  .Modal_inner_scroll {
    width: 100%;
    height: 700px;

    overflow-y: auto;
    display: grid;

    grid-template-columns: 1fr 1fr;
  }

  .Div_to_have_chart {
    width: auto;
    height: 300px;
    padding: 10px;
    margin: 10px;

    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }

  .showModalTogler {
    width: 180px;
    height: 30px;

    border: 1px solid #ccc;
    border-radius: 10px;

    font-size: 15px;
    font-weight: 550;
  }
}

@media screen and (min-width: 600px) and (max-width: 1320px) {
  .student-performance .group-69-C61RwL {
    background-color: transparent;
    height: 335px;
    left: 10%;
    position: absolute;
    top: 371px;
    width: 570px;
  }

  .student-performance .group-71-C61RwL {
    background-color: transparent;
    height: 335px;
    left: 10%;
    position: absolute;
    top: 1161px;
    width: 566px;
  }

  .student-performance .group-70-C61RwL {
    background-color: transparent;
    height: 335px;
    left: 10%;
    position: absolute;
    top: 766px;
    width: 566px;
  }

  .student-performance .group-76-C61RwL {
    background-color: transparent;
    height: 335px;
    left: 10%;
    position: absolute;
    top: 1556px;
    width: 566px;
  }
}
