.main {
  width: 100%;
  padding: 40px 40px;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.main h1 {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #dc3545;
  line-height: 1.5;
}
.main h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
}
.main p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 4px;
}

.bold {
  font-weight: 600;
}

.main li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  margin-left: 10px;
  list-style: disc inside;
  margin-bottom: 4px;
}
@media screen and (max-width: 768px) {
  .main {
    padding: 20px 16px;
    gap: 24px;
  }
  .main h1 {
    font-size: 20px;
  }

  .main h2 {
    font-size: 18px;
  }
  .main h3 {
    font-size: 16px;
  }
  .main p {
    font-size: 15px;
  }
  .main li {
    font-size: 15px;
  }
}
