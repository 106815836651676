.sidepopup-new #popup {
    position: fixed;
    right: 20px;
    bottom: 30px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #ec3545;
    z-index: 100;
    border: 1px solid rgb(236, 53, 69);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  }
  
.sidepopup-new #popup i {
    color: white;
    font-size: 25px;
  }
  
.sidepopup-new #popupbox {
    visibility: hidden;
    height: 220px;
    width: 220px;
    position: fixed;
    right: 20px;
    bottom: 0;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    background-color: white;
    border-radius: 8px;
    
    transition: all .5s ease-in-out;
    opacity: 0;
    z-index: -1;
  }
  
.sidepopup-new .disp {
    visibility: visible !important;
    z-index: 300 !important;
    opacity: 1 !important;
    bottom: 100px !important;
  }
  
.sidepopup-new #popupbox .heading {
    text-align: center;
    background-color: #ec3545;
    height: 45px;
    padding: 10px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

.sidepopup-new #popupbox .heading p {
      color: white;
      font-size: 25px;
      font-weight: 400;
      width: 100%;
  }
  
.sidepopup-new #popup2 {
    position: fixed;
    right: 20px;
    bottom: 120px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #ec3545;
    z-index: 100;
    border: 1px solid #ec3545;
    transition: all .5s ease-in-out;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  }
  
.sidepopup-new #popup2 i {
    color: white;
    font-size: 25px;
  }
  
.sidepopup-new #popupbox2 {
    visibility: hidden;
    height: 300px;
    width: 220px;
    position: fixed;
    right: 20px;
    bottom: 100px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    background-color: white;
    border-radius: 8px;
  
    transition: all .5s ease-in-out;
    opacity: 0;
    z-index: -1;
  }
  
.sidepopup-new #popupbox2 .heading {
    text-align: center;
    background-color: #ec3545;
    height: 45px;
    padding: 10px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

.sidepopup-new #popupbox2 .heading p {
    color: white;
    font-size: 25px;
    font-weight: 400;
    width: 100%;
  }
  
.sidepopup-new .change1 {
    bottom: 340px !important;
  }
  
.sidepopup-new .change2.disp2 {
    visibility: visible !important;
    z-index: 300 !important;
    opacity: 1 !important;
    bottom: 410px !important;
  }
  
.sidepopup-new .disp2 {
    visibility: visible !important;
    z-index: 300 !important;
    opacity: 1 !important;
    bottom: 190px !important;
  }

.sidepopup-new #popupbox .list1 {
    height: 159px;
  }

.sidepopup-new .list1 li {
    padding: 10px 0;
    box-shadow: 0 8px 10px -6px rgba(0, 0, 0, 0.2);
    margin: 5px 10px;
    text-align: center;
  }

.sidepopup-new .contact_list > li:hover {
    background-color: #ec3545;
    color: white;
    margin: 5px 0;
  }

.sidepopup-new .bold {
      display: inline;
      font-weight: 550;
      font-style: italic;
  }
