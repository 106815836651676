/* screen - relation-manager-time-calendar */

.montserrat-medium-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-24px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-64px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-24px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.relation-manager-time-calendar {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 0 1px;
  position: relative;
  width: 100%;
}
.relation-manager-time-calendar .accountbalance-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relation-manager-time-calendar .add-AffcxF {
  background-color: transparent;
  height: auto;
  left: 619px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 400px;
  width: 29px;
}
.relation-manager-time-calendar .add-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 619px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 220px;
  width: 29px;
}
.relation-manager-time-calendar .add-delete-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 600px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: 110px;
}
.relation-manager-time-calendar .add-jI4gQt {
  background-color: transparent;
  height: auto;
  left: 621px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 340px;
  width: 29px;
}
.relation-manager-time-calendar .analytics-24px-4-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relation-manager-time-calendar .arrowback-24px-1-OlYFha {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relation-manager-time-calendar .biba-sobti-h8V8ZR {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.relation-manager-time-calendar .bibasobtigmailcom-h8V8ZR {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.relation-manager-time-calendar .category-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 301px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 76px;
}
.relation-manager-time-calendar .choose-time-for-booking-Fjgz1C {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 40px;
  min-height: 36px;
  position: relative;
  text-align: left;
  width: 432px;
}
.relation-manager-time-calendar .class-name-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: 124px;
}
.relation-manager-time-calendar .completed-PXwdVf {
  background-color: transparent;
  height: auto;
  /* left: 386px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 94px; */
  width: 73px;
}
.relation-manager-time-calendar .component-1-GyJUNH {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.relation-manager-time-calendar .component-4-A6SpMB {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 194px;
  position: relative;
  width: 158px;
}
.relation-manager-time-calendar .component-5-A6SpMB {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 194px;
  margin-left: 43px;
  position: relative;
  width: 158px;
}
.relation-manager-time-calendar .component-6-fhQzNT {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 194px;
  justify-content: flex-end;
  left: 0px;
  min-width: 304px;
  padding: 37px 149.7px;
  position: absolute;
  top: 0px;
  width: auto;
}
.relation-manager-time-calendar .course-fhQzNT {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 154px;
  width: auto;
}
.relation-manager-time-calendar .delete-PXwdVf {
  background-color: transparent;
  color: var(--crusta);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  /* left: 607px; */
  letter-spacing: 0px;
  line-height: 30px;
  /* position: absolute; */
  text-align: left;
  /* top: 269px; */
  white-space: nowrap;
  min-width: 56px;
}
.relation-manager-time-calendar .ellipse-2-RYyMFx {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.relation-manager-time-calendar .feb-18-2021-OlYFha {
  align-self: flex-end;
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 402px;
  min-height: 29px;
  position: relative;
  text-align: left;
  width: 180px;
}
.relation-manager-time-calendar .flex-col-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 85px;
  margin-top: 45px;
  min-height: 977px;
  position: relative;
  width: 1256px;
}
.relation-manager-time-calendar .flex-col-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 795px;
  position: relative;
  width: 705px;
}
.relation-manager-time-calendar .flex-col-jXTb6S {
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 79px;
  min-height: 812px;
  position: relative;
  width: 421px;
}
.relation-manager-time-calendar .flex-col-vgli2x {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.relation-manager-time-calendar .flex-row-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 105px;
  justify-content: flex-start;
  min-width: 1233px;
  position: relative;
  width: auto;
}
.relation-manager-time-calendar .flex-row-Fjgz1C {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 194px;
  justify-content: flex-start;
  margin-left: 1px;
  min-width: 704px;
  position: relative;
  width: auto;
}
.relation-manager-time-calendar .flex-row-LOFkVa {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 32px;
  justify-content: flex-start;
  margin-top: 14px;
  min-width: 691px;
  position: relative;
  width: auto;
}
.relation-manager-time-calendar .flex-row-axsvJs {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.relation-manager-time-calendar .flex-row-oV5UrE {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 830px;
  justify-content: flex-start;
  margin-top: 42px;
  min-width: 1205px;
  position: relative;
  width: auto;
}
.relation-manager-time-calendar .go-back-OlYFha {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 13px;
  min-height: 29px;
  position: relative;
  text-align: left;
  width: 105px;
}
.relation-manager-time-calendar .grade-fhQzNT {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 111px;
  width: auto;
}
.relation-manager-time-calendar .group-125-8nIBmz {
  background-color: var(--blue-zodiac);
  border-radius: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 318px;
}
.relation-manager-time-calendar .group-202-OSnTIv {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 637px;
  position: relative;
  width: 421px;
}
.relation-manager-time-calendar .group-204-OSnTIv {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-left: 21px;
  margin-top: 131px;
  position: relative;
  width: 320px;
}
.relation-manager-time-calendar .group-209-C61RwL-10-1 {
  align-items: flex-end;
  background-color: #1f2531;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  height: 100%;
  padding: 50px 18px;
  position: fixed;
  z-index: 10;
  width: 65px;
}
.relation-manager-time-calendar .group-22-GyJUNH {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  margin-top: 6px;
  min-width: 316px;
  position: relative;
  width: auto;
}
.relation-manager-time-calendar .group-91-TdC5KL {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 369px;
}
.relation-manager-time-calendar .group-92-TdC5KL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 365px;
}
.relation-manager-time-calendar .group-94-TdC5KL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 365px;
}
.relation-manager-time-calendar .group-95-TdC5KL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 365px;
}
.relation-manager-time-calendar .group-96-TdC5KL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 365px;
}
.relation-manager-time-calendar .group-99-TdC5KL {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 351px;
  width: 369px;
}
.relation-manager-time-calendar .group-AAVFy6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.relation-manager-time-calendar .group-XDTIuB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.relation-manager-time-calendar .group-aTF8dW {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.relation-manager-time-calendar .group-rAjf8W {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.relation-manager-time-calendar .help-24px-2-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relation-manager-time-calendar .historytoggleoff-24px-1-D0BVcB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relation-manager-time-calendar .in-progess-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 210px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 72px;
}
.relation-manager-time-calendar .kunal-kashyap-3u6tAP {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 105px;
}
.relation-manager-time-calendar .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.relation-manager-time-calendar .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.relation-manager-time-calendar .messages-8nIBmz {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 64px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 11px;
  width: auto;
}
.relation-manager-time-calendar .notifications-24px-2-1-GyJUNH {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-bottom: 13px;
  margin-left: 641px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.relation-manager-time-calendar .open-issue-list-Gw8Huj {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 5px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.relation-manager-time-calendar .organic-4-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 208px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 94px;
  width: 66px;
}
.relation-manager-time-calendar .organic-5-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 208px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 276px;
  width: 66px;
}
.relation-manager-time-calendar .overlap-group-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 448px;
  margin-top: 31px;
  position: relative;
  width: 705px;
}
.relation-manager-time-calendar .overlap-group-Gw8Huj {
  background-color: transparent;
  flex-shrink: 1;
  height: 600px;
  margin-top: 14px;
  position: relative;
  width: 383px;
}
.relation-manager-time-calendar .overlap-group1-A6SpMB {
  background-color: transparent;
  flex-shrink: 1;
  height: 194px;
  margin-left: 41px;
  position: relative;
  width: 304px;
}
.relation-manager-time-calendar .pending-wGQuAH {
  background-color: transparent;
  height: auto;
  left: 209px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 56px;
}
.relation-manager-time-calendar .rectangle-31-PXwdVf-10-1-1 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  height: auto;
  min-height: 470px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
}
.relation-manager-time-calendar .rectangle-31-TdC5KL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 600px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.relation-manager-time-calendar .rectangle-40-PXwdVf {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  width: 672px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  margin-top: 24px;
}
.relation-manager-time-calendar .rectangle-40-TdC5KL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.relation-manager-time-calendar .rectangle-41-PXwdVf {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 15px;
  position: absolute;
  top: 201px;
  width: 672px;
}
.relation-manager-time-calendar .rectangle-41-TdC5KL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.relation-manager-time-calendar .rectangle-44-PXwdVf {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 15px;
  position: absolute;
  top: 328px;
  width: 672px;
}
.relation-manager-time-calendar .rectangle-47-PXwdVf {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 17px;
  position: absolute;
  top: 140px;
  width: 672px;
}
.relation-manager-time-calendar .rectangle-47-TdC5KL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.relation-manager-time-calendar .rectangle-48-3u6tAP {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.relation-manager-time-calendar .rectangle-48-PXwdVf {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 15px;
  position: absolute;
  top: 261px;
  width: 672px;
}
.relation-manager-time-calendar .rectangle-48-wGQuAH {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.relation-manager-time-calendar .rectangle-7-PXwdVf {
  background-color: transparent;
  height: 1px;
  left: 456px;
  position: absolute;
  top: 92px;
  width: 1px;
}
.relation-manager-time-calendar .s-no-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.relation-manager-time-calendar .school-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relation-manager-time-calendar .sections-teachi-g-i636387253102-Xri3Jx {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  width: auto;
}
.relation-manager-time-calendar .sections-teachi-g-i636387453102-nTYimd {
  background-color: transparent;
  height: auto;
  left: 7px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  width: auto;
}
.relation-manager-time-calendar .shreyans-jain-wGQuAH {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 93px;
}
.relation-manager-time-calendar .sos-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 315px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 34px;
}
.relation-manager-time-calendar .sos-jR05m3 {
  background-color: transparent;
  height: auto;
  left: 315px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 34px;
}
.relation-manager-time-calendar .standard-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 301px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 366px;
  width: 61px;
}
.relation-manager-time-calendar .status-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 295px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: 64px;
}
.relation-manager-time-calendar .status-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 220px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.relation-manager-time-calendar .student-name-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 83px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 107px;
}
.relation-manager-time-calendar .subject-fhQzNT {
  background-color: transparent;
  height: auto;
  left: 27px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 68px;
  width: auto;
}
.relation-manager-time-calendar .teacher-name-fhQzNT {
  background-color: transparent;
  height: auto;
  left: 27px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  width: auto;
}
.relation-manager-time-calendar .ticket-3u6tAP {
  background-color: transparent;
  height: auto;
  left: 211px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 47px;
}
.relation-manager-time-calendar .time-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: 54px;
}
.relation-manager-time-calendar .upcoming-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 394px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 276px;
  width: 68px;
}
.relation-manager-time-calendar .vatsal-agarwal-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 85px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 104px;
}
.relation-manager-time-calendar .vector-1duCl1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relation-manager-time-calendar .vector-2CQzkB {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.relation-manager-time-calendar .vector-2xKpiG {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relation-manager-time-calendar .vector-40xw7g {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.relation-manager-time-calendar .vector-8nIBmz {
  background-color: transparent;
  height: 12px;
  left: 282px;
  position: absolute;
  top: 17px;
  width: 18px;
}
.relation-manager-time-calendar .vector-AxqJxr {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.relation-manager-time-calendar .vector-Ayxm2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  position: relative;
  width: 24px;
}
.relation-manager-time-calendar .vector-BRkMYc {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.relation-manager-time-calendar .vector-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.relation-manager-time-calendar .vector-I29KJn {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relation-manager-time-calendar .vector-QexexC {
  background-color: transparent;
  height: 30px;
  left: 10px;
  position: absolute;
  top: 7px;
  width: 30px;
}
.relation-manager-time-calendar .vector-RYyMFx {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.relation-manager-time-calendar .vector-WxmxmD {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.relation-manager-time-calendar .vector-XxcEsk {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relation-manager-time-calendar .vector-YPcxtl {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.relation-manager-time-calendar .vector-ZAQVda {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.relation-manager-time-calendar .vector-Zppvke {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.relation-manager-time-calendar .vector-axsvJs {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.relation-manager-time-calendar .vector-uxDhU3 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.relation-manager-time-calendar .vector-vgli2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.relation-manager-time-calendar .vector-x0nTHj {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.relation-manager-time-calendar .vector-x6oIim {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.relation-manager-time-calendar .vector-xKmPAn {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relation-manager-time-calendar .x1-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 15px;
}
.relation-manager-time-calendar .x12pm-PXwdVf-inai-aia {
  background-color: transparent;
  height: auto;
  /* left: 32px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 94px; */
  min-width: 60px;
  width: auto;
}
.relation-manager-time-calendar .x1pm-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 158px;
  width: 31px;
}
.relation-manager-time-calendar .x2-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 130px;
  width: 15px;
}
.relation-manager-time-calendar .x2pm-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 218px;
  width: 31px;
}
.relation-manager-time-calendar .x3-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 189px;
  width: 15px;
}
.relation-manager-time-calendar .x3pm-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 275px;
  width: 31px;
}
.relation-manager-time-calendar .x4-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 15px;
}
.relation-manager-time-calendar .x4-i636387253103-Xri3Jx {
  background-color: transparent;
  height: auto;
  left: 42px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 79px;
  width: auto;
}
.relation-manager-time-calendar .x4-i636387353103-hPEL6O {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 78px;
  min-width: 2px;
  position: relative;
  text-align: center;
  width: auto;
}
.relation-manager-time-calendar .x4-i636387453103-nTYimd {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 79px;
  width: auto;
}
.relation-manager-time-calendar .x4pm-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 340px;
  width: 31px;
}
.relation-manager-time-calendar .x5-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 304px;
  width: 15px;
}
.relation-manager-time-calendar .x6-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 366px;
  width: 15px;
}
.relation-manager-time-calendar .x7-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 422px;
  width: 15px;
}
.relation-manager-time-calendar .x8-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 481px;
  width: 15px;
}
.relation-manager-time-calendar .x9-TdC5KL {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 540px;
  width: 15px;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-blue-zodiac-12px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-gray-20px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - relational-manager-calendar-time-mobile */

.montserrat-medium-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-24px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-64px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-24px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.relational-manager-calendar-time-mobile {
  align-items: center;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 0;
  position: relative;
  width: 414px;
}
.relational-manager-calendar-time-mobile .add-JoE0OI {
  background-color: transparent;
  height: auto;
  left: 326px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 38px;
}
.relational-manager-calendar-time-mobile .add-OUIM6H {
  background-color: transparent;
  height: auto;
  left: 333px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 35px;
}
.relational-manager-calendar-time-mobile .add-delete-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 296px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 90px;
}
.relational-manager-calendar-time-mobile .add-mdOnc7 {
  background-color: transparent;
  height: auto;
  left: 326px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 38px;
}
.relational-manager-calendar-time-mobile .arrowback-24px-1-NwNIwt {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-calendar-time-mobile .booked-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 286px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 477px;
  width: 50px;
}
.relational-manager-calendar-time-mobile .booked-Cb1J56 {
  background-color: transparent;
  height: auto;
  left: 284px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 50px;
}
.relational-manager-calendar-time-mobile .category-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 263px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 92px;
}
.relational-manager-calendar-time-mobile .choose-time-for-booking-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12px;
  margin-top: 28px;
  min-height: 26px;
  position: relative;
  text-align: left;
  width: 338px;
}
.relational-manager-calendar-time-mobile .class-name-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 90px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 96px;
}
.relational-manager-calendar-time-mobile .completed-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 184px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 74px;
}
.relational-manager-calendar-time-mobile .completed-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 206px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 57px;
  width: 81px;
}
.relational-manager-calendar-time-mobile .component-1-dXBp89 {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.relational-manager-calendar-time-mobile .component-4-BdpSxK {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 157px;
  position: relative;
  width: 158px;
}
.relational-manager-calendar-time-mobile .component-5-BdpSxK {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 157px;
  margin-left: 46px;
  position: relative;
  width: 158px;
}
.relational-manager-calendar-time-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.relational-manager-calendar-time-mobile .component-7-D1n0rW {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 171px;
  justify-content: flex-end;
  left: 0px;
  min-width: 304px;
  padding: 23.4px 149.7px;
  position: absolute;
  top: 0px;
  width: auto;
}
.relational-manager-calendar-time-mobile .course-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 132px;
  width: auto;
}
.relational-manager-calendar-time-mobile .delete-bC1JcG {
  background-color: transparent;
  color: var(--crusta);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 320px;
  letter-spacing: 0px;
  line-height: 30px;
  position: absolute;
  text-align: left;
  top: 8px;
  white-space: nowrap;
  width: 57px;
}
.relational-manager-calendar-time-mobile .feb-18-2021-NwNIwt-10-10 {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 148px;
  min-height: 22px;
  min-width: 110px;
  position: relative;
  text-align: left;
  width: auto;
}
.relational-manager-calendar-time-mobile .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.relational-manager-calendar-time-mobile .flex-row-VMr6Om {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 157px;
  justify-content: flex-start;
  margin-top: 28px;
  min-width: 362px;
  position: relative;
  width: auto;
}
.relational-manager-calendar-time-mobile .flex-row-mzXdH9 {
  align-items: flex-start;
  align-self: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: 17px;
  min-width: 372px;
  position: relative;
  width: auto;
}
.relational-manager-calendar-time-mobile .go-back-NwNIwt {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 1px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 89px;
}
.relational-manager-calendar-time-mobile .grade-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 95px;
  width: auto;
}
.relational-manager-calendar-time-mobile .group-112-C61RwL-11-1 {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 402px;
  margin-top: 26px;
  position: relative;
  left: 0px;

  width: 380px;
  text-align: center;
}
.relational-manager-calendar-time-mobile .group-210-C61RwL {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-right: 2px;
  margin-top: 28px;
  min-height: 581px;
  position: relative;
  width: 381px;
}
.relational-manager-calendar-time-mobile .group-87-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 171px;
  margin-left: 10px;
  margin-top: 78px;
  position: relative;
  width: 312px;
}
.relational-manager-calendar-time-mobile .group-90-32Z2fi {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 170px;
  width: 346px;
}
.relational-manager-calendar-time-mobile .group-90-Ajm6uA {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 161px;
  width: 380px;
}
.relational-manager-calendar-time-mobile .group-91-32Z2fi {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 464px;
  width: 348px;
}
.relational-manager-calendar-time-mobile .group-91-Ajm6uA {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 220px;
  width: 384px;
}
.relational-manager-calendar-time-mobile .group-92-32Z2fi {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 287px;
  width: 346px;
}
.relational-manager-calendar-time-mobile .group-92-Ajm6uA {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 279px;
  width: 380px;
}
.relational-manager-calendar-time-mobile .group-93-Ajm6uA {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 338px;
  width: 380px;
}
.relational-manager-calendar-time-mobile .group-94-32Z2fi {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 405px;
  width: 346px;
}
.relational-manager-calendar-time-mobile .group-95-32Z2fi {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 346px;
  width: 346px;
}
.relational-manager-calendar-time-mobile .group-96-32Z2fi {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 233px;
  width: 346px;
}
.relational-manager-calendar-time-mobile .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.relational-manager-calendar-time-mobile .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.relational-manager-calendar-time-mobile .open-issue-list-Uoyr1g {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 4px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.relational-manager-calendar-time-mobile .opened-GcMqx5 {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.relational-manager-calendar-time-mobile .opened-Gfzi7S {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.relational-manager-calendar-time-mobile .opened-eI2rAV {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.relational-manager-calendar-time-mobile .opened-t6sl2a {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.relational-manager-calendar-time-mobile .opened-xy5Uks {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.relational-manager-calendar-time-mobile .organic-4-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 69px;
}
.relational-manager-calendar-time-mobile .organic-4-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 100px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 57px;
  width: 69px;
}
.relational-manager-calendar-time-mobile .organic-5-4Mx5vA {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.relational-manager-calendar-time-mobile .organic-5-bC1JcG {
  background-color: transparent;
  height: auto;
  left: 91px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.relational-manager-calendar-time-mobile .overlap-group-Uoyr1g {
  background-color: transparent;
  flex-shrink: 1;
  height: 546px;
  margin-top: 12px;
  position: relative;
  width: 359px;
}
.relational-manager-calendar-time-mobile .rectangle-31-32Z2fi {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 546px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.relational-manager-calendar-time-mobile .rectangle-31-Ajm6uA-10-101 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 402px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.relational-manager-calendar-time-mobile .rectangle-40-32Z2fi {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 52px;
  width: 342px;
}
.relational-manager-calendar-time-mobile .rectangle-40-Ajm6uA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 44px;
  width: 376px;
}
.relational-manager-calendar-time-mobile .rectangle-41-32Z2fi {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 7px;
  position: absolute;
  top: 170px;
  width: 342px;
}
.relational-manager-calendar-time-mobile .rectangle-41-Ajm6uA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 162px;
  width: 376px;
}
.relational-manager-calendar-time-mobile .rectangle-44-GcMqx5 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-calendar-time-mobile .rectangle-44-JoE0OI {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.relational-manager-calendar-time-mobile .rectangle-44-xy5Uks {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-calendar-time-mobile .rectangle-47-32Z2fi {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 111px;
  width: 342px;
}
.relational-manager-calendar-time-mobile .rectangle-47-Ajm6uA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 103px;
  width: 376px;
}
.relational-manager-calendar-time-mobile .rectangle-48-4Mx5vA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-calendar-time-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.relational-manager-calendar-time-mobile .rectangle-48-bC1JcG {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.relational-manager-calendar-time-mobile .rectangle-49-Gfzi7S {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-calendar-time-mobile .rectangle-49-OUIM6H {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.relational-manager-calendar-time-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.relational-manager-calendar-time-mobile .rectangle-49-eI2rAV {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-calendar-time-mobile .rectangle-49-t6sl2a {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-calendar-time-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.relational-manager-calendar-time-mobile .rectangle-50-mdOnc7 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.relational-manager-calendar-time-mobile .s-no-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 42px;
}
.relational-manager-calendar-time-mobile
  .sections-teachi-g-i636398553102-fVQN4V {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 41px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
  width: auto;
}
.relational-manager-calendar-time-mobile
  .sections-teachi-g-i636398653102-PBxgdW {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
  width: auto;
}
.relational-manager-calendar-time-mobile .status-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 49px;
}
.relational-manager-calendar-time-mobile .status-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 214px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 54px;
}
.relational-manager-calendar-time-mobile .student-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 78px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 94px;
}
.relational-manager-calendar-time-mobile .subject-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 58px;
  width: auto;
}
.relational-manager-calendar-time-mobile .teacher-name-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: auto;
}
.relational-manager-calendar-time-mobile .time-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 18px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 46px;
}
.relational-manager-calendar-time-mobile .upcoming-4Mx5vA {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.relational-manager-calendar-time-mobile .upcoming-bC1JcG {
  background-color: transparent;
  height: auto;
  left: 197px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 78px;
}
.relational-manager-calendar-time-mobile .vector-8g5xxj {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-calendar-time-mobile .vector-P0KAxp {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.relational-manager-calendar-time-mobile .x12pm-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 44px;
}
.relational-manager-calendar-time-mobile .x12pm-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 18px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 57px;
  width: 48px;
}
.relational-manager-calendar-time-mobile .x1pm-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 128px;
  width: 31px;
}
.relational-manager-calendar-time-mobile .x1pm-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 18px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 120px;
  width: 34px;
}
.relational-manager-calendar-time-mobile .x2pm-Gfzi7S {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.relational-manager-calendar-time-mobile .x2pm-OUIM6H {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 40px;
}
.relational-manager-calendar-time-mobile .x2pm-eI2rAV {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.relational-manager-calendar-time-mobile .x2pm-t6sl2a {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.relational-manager-calendar-time-mobile .x3pm-4Mx5vA {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.relational-manager-calendar-time-mobile .x3pm-bC1JcG {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.relational-manager-calendar-time-mobile .x4-i636398553103-fVQN4V {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 46px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 64px;
  width: auto;
}
.relational-manager-calendar-time-mobile .x4-i636398653103-PBxgdW {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 52px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 64px;
  width: auto;
}
.relational-manager-calendar-time-mobile .x4-i636398853103-j2Gv3j {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 78px;
  min-width: 2px;
  position: relative;
  text-align: center;
  width: auto;
}
.relational-manager-calendar-time-mobile .x4pm-GcMqx5 {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.relational-manager-calendar-time-mobile .x4pm-JoE0OI {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 38px;
}
.relational-manager-calendar-time-mobile .x5pm-mdOnc7 {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 38px;
}
.relational-manager-calendar-time-mobile .x6pm-xy5Uks {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}

.slot-modal {
  width: 40%;
  height: 200px;
  margin: auto;
  margin-top: 90px;
  background-color: whitesmoke;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  padding: 24px 0;
}

.slot-modal .slot-modal-title {
  width: 70%;
  margin: auto;
  text-align: center;
  margin-bottom: 24px;
}

.slot-modal .slot-modal-body {
  padding: 0 14px;
  width: 80%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
}

.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-gray-20px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .relation-manager-time-calendar {
    display: none;
  }
  .rectangle-40-PXwdVf {
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    height: 30px;
    width: 93%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-top: 10px;
  }

  .delete-PXwdVf {
    background-color: transparent;
    color: var(--crusta);
    font-family: "Montserrat", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    height: auto;
    /* left: 607px; */
    letter-spacing: 0px;
    line-height: 30px;
    /* position: absolute; */
    text-align: left;
    /* top: 269px; */
    white-space: nowrap;
    min-width: 56px;
  }
  .thue-ee {
    min-width: 55px;
  }
  .thue-ee-1 {
    min-width: 45px;
  }
  .rectangle-31-Ajm6uA-10-101-head {
    width: 92%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;

    color: var(--gray);
    font-family: "Roboto", Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
}
@media screen and (min-width: 600px) {
  .relational-manager-calendar-time-mobile {
    display: none;
  }
}
