@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,500,600|Roboto:700,500");
/* screen - teacher-curriculm-mobile */

.montserrat-medium-gray-7px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-niagara-8px {
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-white-18px {
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-curriculm-mobile {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 1942px;
  height: auto;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 0;
  position: relative;
  width: 414px;
}
.teacher-curriculm-mobile .add-2E20TK {
  background-color: transparent;
  height: auto;
  left: 283px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-curriculm-mobile .add-BsH3n1 {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 30.24px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 35px;
}
.teacher-curriculm-mobile .add-delete-oNdXAy {
  background-color: transparent;
  height: auto;
  left: 264px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 92px;
}

.teacher-curriculum-list .historytoggleoff-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-curriculum-list .quiz-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-curriculum-list .poweroff-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
  margin-top: 100px;
}

.teacher-curriculm-mobile .assignment-1-EXDUvW {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.teacher-curriculm-mobile .assignment-1-LFvdd7 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.teacher-curriculm-mobile .assignment-1-URlHrD {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.teacher-curriculm-mobile .assignment-1-VqvJAl {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.teacher-curriculm-mobile .assignment-24px-3-1HHR9j {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.teacher-curriculm-mobile .assignment-24px-3-e62HuU {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.teacher-curriculm-mobile .assignment-24px-3-tOztDT {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.teacher-curriculm-mobile .assignment-24px-3-xgoJzt {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.teacher-curriculm-mobile .booked-TjBK2q {
  background-color: transparent;
  height: auto;
  left: 285px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 56px;
  width: 50px;
}
.teacher-curriculm-mobile .booked-oNdXAy {
  background-color: transparent;
  height: auto;
  left: 285px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 237px;
  width: 50px;
}
.teacher-curriculm-mobile .chemistry-grade-9-mTlOWV {
  background-color: transparent;
  height: auto;
  left: 44px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 117px;
  width: 166px;
}
.teacher-curriculm-mobile .class-name-oNdXAy {
  background-color: transparent;
  height: auto;
  left: 79px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 94px;
}
.teacher-curriculm-mobile .completed-oNdXAy {
  background-color: transparent;
  height: auto;
  left: 185px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 56px;
  width: 74px;
}
.teacher-curriculm-mobile .component-1-y0eQTV {
  align-self: flex-start;
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.teacher-curriculm-mobile .component-29-3SUx3P {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 61px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 61px;
}
.teacher-curriculm-mobile .component-29-EXDUvW {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.teacher-curriculm-mobile .component-29-I7m5kz {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 61px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 61px;
}
.teacher-curriculm-mobile .component-29-LFvdd7 {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.teacher-curriculm-mobile .component-29-URlHrD {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.teacher-curriculm-mobile .component-29-VqvJAl {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.teacher-curriculm-mobile .component-29-vvk7lK {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 61px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 61px;
}
.teacher-curriculm-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 13px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.teacher-curriculm-mobile .description-24px-1-3SUx3P {
  background-color: transparent;
  height: 30px;
  left: 16px;
  overflow: hidden;
  position: absolute;
  top: 15px;
  width: 30px;
}
.teacher-curriculm-mobile .description-24px-1-I7m5kz {
  background-color: transparent;
  height: 30px;
  left: 16px;
  overflow: hidden;
  position: absolute;
  top: 15px;
  width: 30px;
}
.teacher-curriculm-mobile .description-24px-1-vvk7lK {
  background-color: transparent;
  height: 30px;
  left: 16px;
  overflow: hidden;
  position: absolute;
  top: 15px;
  width: 30px;
}
.teacher-curriculm-mobile .ellipse-4-1HHR9j {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.teacher-curriculm-mobile .ellipse-4-e62HuU {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.teacher-curriculm-mobile .ellipse-4-tOztDT {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.teacher-curriculm-mobile .ellipse-4-xgoJzt {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.teacher-curriculm-mobile .feb-18-2021-qacxfW {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 87px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 117px;
}
.teacher-curriculm-mobile .flex-col-Xi618D {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 50px;
  min-height: 1108px;
  position: relative;
  width: 311px;
}
.teacher-curriculm-mobile .flex-col-dXBp89 {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 9.44px;
  min-height: 162px;
  position: relative;
  width: 179px;
}
.teacher-curriculm-mobile .flex-row-0VemYL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 23px;
  justify-content: flex-start;
  margin-left: 5px;
  min-width: 353px;
  position: relative;
  width: auto;
}
.teacher-curriculm-mobile .flex-row-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 1108px;
  justify-content: flex-start;
  margin-left: -203.86px;
  min-width: 589px;
  position: relative;
  width: auto;
}
.teacher-curriculm-mobile .group-135-C61RwL {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-right: 2px;
  margin-top: 52px;
  min-height: 731px;
  position: relative;
  width: 383px;
}
.teacher-curriculm-mobile .group-170-5jtcXK {
  background-color: transparent;
  flex-shrink: 1;
  height: 61px;
  position: relative;
  width: 61px;
}
.teacher-curriculm-mobile .group-170-Jxka9Z {
  background-color: transparent;
  flex-shrink: 1;
  height: 61px;
  position: relative;
  width: 61px;
}
.teacher-curriculm-mobile .group-170-tmaPcu {
  background-color: transparent;
  flex-shrink: 1;
  height: 61px;
  position: relative;
  width: 61px;
}
.teacher-curriculm-mobile .group-171-UEeHnL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 61px;
  justify-content: flex-start;
  left: 94px;
  min-width: 61px;
  position: absolute;
  top: 27px;
  width: auto;
}
.teacher-curriculm-mobile .group-172-aCxeED {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 61px;
  justify-content: flex-start;
  left: 95px;
  min-width: 61px;
  position: absolute;
  top: 27px;
  width: auto;
}
.teacher-curriculm-mobile .group-173-8SqM8O {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 61px;
  justify-content: flex-start;
  left: 95px;
  min-width: 61px;
  position: absolute;
  top: 27px;
  width: auto;
}
.teacher-curriculm-mobile .group-57-UEeHnL {
  background-color: transparent;
  height: 600px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 255px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: auto;
}
.teacher-curriculm-mobile .group-58-aCxeED {
  background-color: transparent;
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 255px;
}
.teacher-curriculm-mobile .group-72-0PZcXW {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-left: 0.67px;
  position: relative;
  width: 179px;
}
.teacher-curriculm-mobile .group-73-0PZcXW {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-left: 0.67px;
  margin-top: 7px;
  position: relative;
  width: 179px;
}
.teacher-curriculm-mobile .group-74-0PZcXW {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-left: 0.67px;
  margin-top: 7px;
  position: relative;
  width: 179px;
}
.teacher-curriculm-mobile .group-75-0PZcXW {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-top: 7px;
  position: relative;
  width: 179px;
}
.teacher-curriculm-mobile .group-76-8SqM8O {
  background-color: transparent;
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 255px;
}
.teacher-curriculm-mobile .group-90-oNdXAy {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 161px;
  width: 346px;
}
.teacher-curriculm-mobile .group-91-oNdXAy {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 220px;
  width: 348px;
}
.teacher-curriculm-mobile .group-92-oNdXAy {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 278px;
  width: 346px;
}
.teacher-curriculm-mobile .group-93-oNdXAy {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 10px;
  min-height: 262px;
  position: absolute;
  top: 119px;
  width: 348px;
}
.teacher-curriculm-mobile .group-94-oNdXAy {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 396px;
  width: 346px;
}
.teacher-curriculm-mobile .group-95-oNdXAy {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 455px;
  width: 346px;
}
.teacher-curriculm-mobile .group-96-oNdXAy {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 514px;
  width: 346px;
}
.teacher-curriculm-mobile .group-97-oNdXAy {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 573px;
  width: 346px;
}
.teacher-curriculm-mobile .group-98-oNdXAy {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 632px;
  width: 346px;
}
.teacher-curriculm-mobile .line-5-VqzB3J {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.teacher-curriculm-mobile .line-6-VqzB3J {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.teacher-curriculm-mobile .mathematics-dr-yashna-sharma-EXDUvW {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.teacher-curriculm-mobile .mathematics-dr-yashna-sharma-LFvdd7 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.teacher-curriculm-mobile .mathematics-dr-yashna-sharma-URlHrD {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.teacher-curriculm-mobile .mathematics-dr-yashna-sharma-VqvJAl {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.teacher-curriculm-mobile .mathematics-grade-9-2luC33 {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  width: 166px;
}
.teacher-curriculm-mobile .my-curriculums-y0eQTV {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 1px;
  margin-top: 52px;
  min-height: 36px;
  position: relative;
  width: 310px;
}
.teacher-curriculm-mobile .open-calendar-qacxfW {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.teacher-curriculm-mobile .opened-6saVYL {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-curriculm-mobile .opened-FoyR1x {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-curriculm-mobile .opened-RsTPc5 {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-curriculm-mobile .opened-SZLSD7 {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.teacher-curriculm-mobile .opened-SlNxBa {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-curriculm-mobile .opened-jfMMFR {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-curriculm-mobile .opened-yoedw0 {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-curriculm-mobile .organic-4-oNdXAy {
  background-color: transparent;
  height: auto;
  left: 89px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 56px;
  width: 69px;
}
.teacher-curriculm-mobile .organic-5-Pkus28 {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.teacher-curriculm-mobile .overlap-group-BsH3n1 {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 342px;
}
.teacher-curriculm-mobile .overlap-group-y0eQTV {
  background-color: transparent;
  flex-shrink: 1;
  height: 262px;
  margin-top: 63px;
  position: relative;
  width: 255px;
}
.teacher-curriculm-mobile .overlap-group1-y0eQTV {
  background-color: transparent;
  flex-shrink: 1;
  height: 262px;
  margin-right: 1px;
  margin-top: 63px;
  position: relative;
  width: 255px;
}
.teacher-curriculm-mobile .overlap-group2-y0eQTV {
  background-color: transparent;
  flex-shrink: 1;
  height: 262px;
  margin-right: 1px;
  margin-top: 63px;
  position: relative;
  width: 255px;
}
.teacher-curriculm-mobile .overlap-group3-0VemYL {
  background-color: transparent;
  flex-shrink: 1;
  height: 687px;
  margin-top: 21px;
  position: relative;
  width: 359px;
}
.teacher-curriculm-mobile .physics-grade-9-s5c6Qy {
  background-color: transparent;
  height: auto;
  left: 44px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 117px;
  width: 166px;
}
.teacher-curriculm-mobile .rectangle-31-oNdXAy {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 687px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-curriculm-mobile .rectangle-40-2luC33 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 262px;
  width: 251px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 30px 10px;
}
.teacher-curriculm-mobile .rectangle-40-EXDUvW {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.teacher-curriculm-mobile .rectangle-40-LFvdd7 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.teacher-curriculm-mobile .rectangle-40-URlHrD {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.teacher-curriculm-mobile .rectangle-40-VqvJAl {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.teacher-curriculm-mobile .rectangle-40-mTlOWV {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 251px;
}
.teacher-curriculm-mobile .rectangle-40-oNdXAy {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 9px;
  position: absolute;
  top: 43px;
  width: 342px;
}
.teacher-curriculm-mobile .rectangle-40-s5c6Qy {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 251px;
}
.teacher-curriculm-mobile .rectangle-41-2luC33 {
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  width: 122px;
}
.teacher-curriculm-mobile .rectangle-41-mTlOWV {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 65px;
  position: absolute;
  top: 202px;
  width: 122px;
}
.teacher-curriculm-mobile .rectangle-41-oNdXAy {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 8px;
  position: absolute;
  top: 161px;
  width: 342px;
}
.teacher-curriculm-mobile .rectangle-41-s5c6Qy {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 65px;
  position: absolute;
  top: 202px;
  width: 122px;
}
.teacher-curriculm-mobile .rectangle-44-6saVYL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-curriculm-mobile .rectangle-44-FoyR1x {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-curriculm-mobile .rectangle-44-RsTPc5 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-curriculm-mobile .rectangle-44-SlNxBa {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-curriculm-mobile .rectangle-44-jfMMFR {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-curriculm-mobile .rectangle-44-yoedw0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-curriculm-mobile .rectangle-47-oNdXAy {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 9px;
  position: absolute;
  top: 102px;
  width: 342px;
}
.teacher-curriculm-mobile .rectangle-48-Pkus28 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-curriculm-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.teacher-curriculm-mobile .rectangle-49-SZLSD7 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-curriculm-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-curriculm-mobile .rectangle-50-2E20TK {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-curriculm-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-curriculm-mobile .span0-UFJofL {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.teacher-curriculm-mobile .span0-WEfEkI {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-curriculm-mobile .span0-bwYA4C {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.teacher-curriculm-mobile .span0-hMrtWj {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.teacher-curriculm-mobile .span1-UFJofL {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.teacher-curriculm-mobile .span1-WEfEkI {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-curriculm-mobile .span1-bwYA4C {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.teacher-curriculm-mobile .span1-hMrtWj {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.teacher-curriculm-mobile .status-oNdXAy {
  background-color: transparent;
  height: auto;
  left: 192px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 49px;
}
.teacher-curriculm-mobile .submitted-EXDUvW {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-curriculm-mobile .submitted-LFvdd7 {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-curriculm-mobile .submitted-URlHrD {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-curriculm-mobile .submitted-VqvJAl {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-curriculm-mobile .time-oNdXAy {
  background-color: transparent;
  height: auto;
  left: 16px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 42px;
}
.teacher-curriculm-mobile .upcoming-Pkus28 {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.teacher-curriculm-mobile .vector-0tkpcQ {
  background-color: transparent;
  height: 26px;
  left: 5px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-curriculm-mobile .vector-JGyev7 {
  background-color: transparent;
  height: 8px;
  left: 1px;
  position: absolute;
  top: -0px;
  width: 7px;
}
.teacher-curriculm-mobile .vector-VZdL8H {
  background-color: transparent;
  height: 8px;
  left: 1px;
  position: absolute;
  top: -0px;
  width: 7px;
}
.teacher-curriculm-mobile .vector-WcNXVM {
  background-color: transparent;
  height: 7px;
  left: 1px;
  position: absolute;
  top: 0px;
  width: 7px;
}
.teacher-curriculm-mobile .vector-XgFQAe {
  background-color: transparent;
  height: 8px;
  left: 1px;
  position: absolute;
  top: -0px;
  width: 7px;
}
.teacher-curriculm-mobile .vector-XrUhr7 {
  background-color: transparent;
  height: 26px;
  left: 5px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-curriculm-mobile .vector-eH2jVX {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.teacher-curriculm-mobile .vector-fyPUk2 {
  background-color: transparent;
  height: 1px;
  left: 15px;
  position: absolute;
  top: 15px;
  width: 1px;
}
.teacher-curriculm-mobile .vector-opEko5 {
  background-color: transparent;
  height: 1px;
  left: 15px;
  position: absolute;
  top: 15px;
  width: 1px;
}
.teacher-curriculm-mobile .vector-rATNsx {
  background-color: transparent;
  height: 26px;
  left: 5px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-curriculm-mobile .vector-sxwcxa {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.teacher-curriculm-mobile .vector-v2cgy1 {
  background-color: transparent;
  height: 1px;
  left: 15px;
  position: absolute;
  top: 15px;
  width: 1px;
}
.teacher-curriculm-mobile .vector-wEaHDs {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.teacher-curriculm-mobile .vector-xkta9U {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.teacher-curriculm-mobile .view-2luC33 {
  background-color: transparent;
  height: auto;
  left: 113px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 211px;
  width: auto;
}
.teacher-curriculm-mobile .view-mTlOWV {
  background-color: transparent;
  height: auto;
  left: 113px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 211px;
  width: auto;
}
.teacher-curriculm-mobile .view-s5c6Qy {
  background-color: transparent;
  height: auto;
  left: 113px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 211px;
  width: auto;
}
.teacher-curriculm-mobile .x10pm-SlNxBa {
  background-color: transparent;
  height: auto;
  left: 7px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 42px;
}
.teacher-curriculm-mobile .x12pm-oNdXAy {
  background-color: transparent;
  height: auto;
  left: 18px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 56px;
  width: 44px;
}
.teacher-curriculm-mobile .x1pm-oNdXAy {
  background-color: transparent;
  height: auto;
  left: 18px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 119px;
  width: 31px;
}
.teacher-curriculm-mobile .x2pm-SZLSD7 {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.teacher-curriculm-mobile .x3pm-Pkus28 {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.teacher-curriculm-mobile .x4pm-6saVYL {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-curriculm-mobile .x5pm-2E20TK {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-curriculm-mobile .x6pm-yoedw0 {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-curriculm-mobile .x7pm-FoyR1x {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-curriculm-mobile .x8pm-RsTPc5 {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-curriculm-mobile .x9pm-jfMMFR {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - teacher-curriculum-list */

.montserrat-medium-gray-7px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-niagara-8px {
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-white-18px {
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-curriculum-list {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.teacher-curriculum-list .accountbalance-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-curriculum-list .add-KKOYJf {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 17.59px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 37px;
}
.teacher-curriculum-list .add-cfQi7W {
  background-color: transparent;
  height: auto;
  left: 316px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-curriculum-list .add-delete-ePioxc {
  background-color: transparent;
  height: auto;
  left: 287px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 87px;
}
.teacher-curriculum-list .analytics-24px-3-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-curriculum-list .assignment-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-curriculum-list .biba-sobti-h8V8ZR {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-curriculum-list .bibasobtigmailcom-h8V8ZR {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-curriculum-list .booked-IxVOPE {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 53px;
}
.teacher-curriculum-list .booked-ePioxc {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 53px;
}
.teacher-curriculum-list .chemistry-grade-9-zoyRd5 {
  background-color: transparent;
  height: auto;
  left: 44px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 117px;
  width: 166px;
}
.teacher-curriculum-list .class-name-ePioxc {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 93px;
}
.teacher-curriculum-list .completed-ePioxc {
  background-color: transparent;
  height: auto;
  left: 200px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 79px;
}
.teacher-curriculum-list .component-1-GyJUNH {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.teacher-curriculum-list .component-29-1BiNqL {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 61px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 61px;
}
.teacher-curriculum-list .component-29-la42fO {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 61px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 61px;
}
.teacher-curriculum-list .component-29-zNLUbz {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 61px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 61px;
}
.teacher-curriculum-list .description-24px-1-1BiNqL {
  background-color: transparent;
  height: 30px;
  /* left: 16px; */
  overflow: hidden;
  /* position: absolute; */
  /* top: 15px; */
  width: 30px;
}
.teacher-curriculum-list .description-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-curriculum-list .description-24px-1-la42fO {
  background-color: transparent;
  height: 30px;
  left: 16px;
  overflow: hidden;
  position: absolute;
  top: 15px;
  width: 30px;
}
.teacher-curriculum-list .description-24px-1-zNLUbz {
  background-color: transparent;
  height: 30px;
  left: 16px;
  overflow: hidden;
  position: absolute;
  top: 15px;
  width: 30px;
}
.teacher-curriculum-list .ellipse-2-RYyMFx {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.teacher-curriculum-list .feb-18-2021-xQuxwr {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 116px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 113px;
}
.teacher-curriculum-list .flex-col-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 75px;
  margin-top: 45px;
  min-height: 928px;
  position: relative;
  width: 95%;
}
.teacher-curriculum-list .flex-col-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex-shrink: 1;
  height: 600px;
  justify-content: flex-start;
  min-height: 671px;
  position: relative;
  width: 48%;
  overflow: auto;
}
.teacher-curriculum-list .flex-col-jXTb6S {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 61px;
  margin-top: 87px;
  min-height: 335px;
  position: relative;
  width: 255px;
}
.teacher-curriculum-list .flex-col-vgli2x {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.teacher-curriculum-list .flex-row-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 105px;
  justify-content: space-between;
  position: relative;
  width: 90%;
  padding-right: 5%;
}
.teacher-curriculum-list .flex-row-WcGkdi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.teacher-curriculum-list .flex-row-oV5UrE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 763px;
  justify-content: flex-start;
  margin-left: 17px;
  margin-top: 60px;
  position: relative;
  width: 95%;
  padding-left: 2%;
}
.teacher-curriculum-list .flex-row-xL4dLV {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 23px;
  justify-content: flex-start;
  min-width: 377px;
  position: relative;
  width: auto;
}
.teacher-curriculum-list .group-112-xL4dLV {
  background-color: transparent;
  flex-shrink: 1;
  height: 726px;
  margin-top: 14px;
  position: relative;
  width: 403px;
}
.teacher-curriculum-list .group-113-eLYKxH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 763px;
  position: relative;
  width: 387px;
}
.teacher-curriculum-list .group-114-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 763px;
  justify-content: flex-start;
  margin-left: 5%;
  position: relative;
  width: 45%;
}
.teacher-curriculum-list .group-160-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 88px;
  min-width: 24px;
  position: relative;
  width: auto;
}
.teacher-curriculum-list .group-162-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 778px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.teacher-curriculum-list .group-163-L8UHM8 {
  background-color: #1f2531;
  height: 1024px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 63px;
}
.teacher-curriculum-list .group-164-L8UHM8 {
  background-color: transparent;
  height: 778px;
  left: 20px;
  position: absolute;
  top: 200px;
  width: 24px;
}
.teacher-curriculum-list .group-165-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 1024px;
  position: relative;
  width: 63px;
}
.teacher-curriculum-list .group-170-5bgbym {
  background-color: transparent;
  flex-shrink: 1;
  height: 61px;
  position: relative;
  width: 61px;
}
.teacher-curriculum-list .group-170-d13GHo {
  background-color: transparent;
  flex-shrink: 1;
  height: 61px;
  position: relative;
  width: 61px;
}
.teacher-curriculum-list .group-170-wcx4GC {
  background-color: transparent;
  flex-shrink: 1;
  height: 61px;
  position: relative;
  width: 61px;
}
.teacher-curriculum-list .group-171-PXwdVf {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 61px;
  justify-content: flex-start;
  left: 95px;
  min-width: 61px;
  position: absolute;
  top: 27px;
  width: auto;
}
.teacher-curriculum-list .group-172-1UTYTe {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 61px;
  justify-content: flex-start;
  left: 95px;
  min-width: 61px;
  position: absolute;
  top: 27px;
  width: auto;
}
.teacher-curriculum-list .group-173-rPx5JA {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 61px;
  justify-content: flex-start;
  left: 95px;
  min-width: 61px;
  position: absolute;
  top: 27px;
  width: auto;
}
.teacher-curriculum-list .group-22-GyJUNH {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  margin-top: 6px;
  min-width: 316px;
  position: relative;
  width: auto;
}
.teacher-curriculum-list .group-58-rPx5JA {
  background-color: transparent;
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 255px;
}
.teacher-curriculum-list .group-59-1UTYTe {
  background-color: transparent;
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 255px;
}
.teacher-curriculum-list .group-90-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 174px;
  width: 369px;
}
.teacher-curriculum-list .group-91-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 371px;
}
.teacher-curriculum-list .group-92-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 369px;
}
.teacher-curriculum-list .group-93-ePioxc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 11px;
  min-height: 262px;
  position: absolute;
  top: 133px;
  width: 371px;
}
.teacher-curriculum-list .group-94-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 369px;
}
.teacher-curriculum-list .group-95-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 369px;
}
.teacher-curriculum-list .group-96-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 369px;
}
.teacher-curriculum-list .group-97-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 587px;
  width: 369px;
}
.teacher-curriculum-list .group-98-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 646px;
  width: 369px;
}
.teacher-curriculum-list .group-9VbO77 {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  position: relative;
  width: 20px;
}
.teacher-curriculum-list .group-FHHSuW {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-curriculum-list .group-UKbWOd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.teacher-curriculum-list .group-alxHTq {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-curriculum-list .group-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: flex-start;
  left: 0px;
  min-width: 20px;
  position: absolute;
  top: 448px;
  width: auto;
}
.teacher-curriculum-list .group-tQmilE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.teacher-curriculum-list .help-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-curriculum-list .historytoggleoff-24px-1-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-curriculum-list .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.teacher-curriculum-list .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.teacher-curriculum-list .mathematics-grade-10-PxGwRP {
  background-color: transparent;
  height: auto;
  left: 44px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 117px;
  width: 166px;
}
.teacher-curriculum-list .mathematics-grade-9-PXwdVf {
  background-color: transparent;
  height: auto;
  /* left: 44px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  /* top: 117px; */
  width: 166px;
}
.teacher-curriculum-list .my-curriculums-Fjgz1C {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 36px;
  position: relative;
  width: 100%;
}
.teacher-curriculum-list .notifications-24px-2-1-GyJUNH {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-bottom: 13px;
  margin-left: 641px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.teacher-curriculum-list .open-calendar-xQuxwr {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.teacher-curriculum-list .opened-C2l7Eu {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-curriculum-list .opened-FwJWKX {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-curriculum-list .opened-G1xvhx {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-curriculum-list .opened-MZRcus {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-curriculum-list .opened-XCjVcD {
  background-color: transparent;
  height: auto;
  left: 304px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 53px;
}
.teacher-curriculum-list .opened-hUx6Ho {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-curriculum-list .opened-oOO7Ym {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-curriculum-list .organic-4-ePioxc {
  background-color: transparent;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 67px;
}
.teacher-curriculum-list .organic-5-Hx49NZ {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 67px;
}
.teacher-curriculum-list .overlap-group-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 262px;
  margin-top: 51px;
  position: relative;
  width: 251px;
}
.teacher-curriculum-list .overlap-group-KKOYJf {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 365px;
}
.teacher-curriculum-list .overlap-group1-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 262px;
  margin-top: 60px;
  position: relative;
  width: 255px;
}
.teacher-curriculum-list .overlap-group2-OSnTIv {
  background-color: transparent;
  flex-shrink: 1;
  height: 262px;
  position: relative;
  width: 255px;
}
.teacher-curriculum-list .rectangle-31-ePioxc {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 726px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.teacher-curriculum-list .rectangle-40-PXwdVf {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 262px;
  padding: 30px 10px;
  /* left: 0px;
  position: absolute;
  top: 0px; */
  width: 251px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.teacher-curriculum-list .rectangle-40-PxGwRP {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 251px;
}
.teacher-curriculum-list .rectangle-40-ePioxc {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.teacher-curriculum-list .rectangle-40-zoyRd5 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 251px;
}
.teacher-curriculum-list .rectangle-41-PXwdVf {
  /* background-color: var(--white); */
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  width: 122px;
}
.teacher-curriculum-list .rectangle-41-PxGwRP {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 65px;
  position: absolute;
  top: 202px;
  width: 122px;
}
.teacher-curriculum-list .rectangle-41-ePioxc {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.teacher-curriculum-list .rectangle-41-zoyRd5 {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 65px;
  position: absolute;
  top: 202px;
  width: 122px;
}
.teacher-curriculum-list .rectangle-44-C2l7Eu {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-curriculum-list .rectangle-44-FwJWKX {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-curriculum-list .rectangle-44-G1xvhx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-curriculum-list .rectangle-44-MZRcus {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-curriculum-list .rectangle-44-hUx6Ho {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-curriculum-list .rectangle-44-oOO7Ym {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-curriculum-list .rectangle-47-ePioxc {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.teacher-curriculum-list .rectangle-48-Hx49NZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-curriculum-list .rectangle-49-XCjVcD {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-curriculum-list .rectangle-50-cfQi7W {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-curriculum-list .rectangle-7-OSnTIv {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-right: 46.01px;
  margin-top: 72px;
  position: relative;
  width: 1px;
}
.teacher-curriculum-list .school-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-curriculum-list .span0-79e0bL {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-curriculum-list .span0-SA4YBx {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.teacher-curriculum-list .span0-aXUnt1 {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.teacher-curriculum-list .span0-akvcnV {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.teacher-curriculum-list .span1-79e0bL {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-curriculum-list .span1-SA4YBx {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.teacher-curriculum-list .span1-aXUnt1 {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.teacher-curriculum-list .span1-akvcnV {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.teacher-curriculum-list .status-ePioxc {
  background-color: transparent;
  height: auto;
  left: 208px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.teacher-curriculum-list .stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-curriculum-list .time-ePioxc {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.teacher-curriculum-list .upcoming-Hx49NZ {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 76px;
}
.teacher-curriculum-list .vector-3ahjCQ {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-curriculum-list .vector-40xw7g {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.teacher-curriculum-list .vector-56AgMz {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.teacher-curriculum-list .vector-8k85HE {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 170px;
  position: relative;
  width: 24px;
}
.teacher-curriculum-list .vector-90KxxC {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-curriculum-list .vector-9xgjqN {
  background-color: transparent;
  height: 14px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.teacher-curriculum-list .vector-B8xRgA {
  background-color: transparent;
  height: 16px;
  left: 0px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-curriculum-list .vector-BKwYFx {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.teacher-curriculum-list .vector-DOgkCL {
  background-color: transparent;
  height: 26px;
  left: 5px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-curriculum-list .vector-EaMiW0 {
  background-color: transparent;
  height: 20px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 18px;
}
.teacher-curriculum-list .vector-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-curriculum-list .vector-NwJCyv {
  background-color: transparent;
  height: 1px;
  left: 15px;
  position: absolute;
  top: 15px;
  width: 1px;
}
.teacher-curriculum-list .vector-OpeSlE {
  background-color: transparent;
  height: 26px;
  left: 5px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-curriculum-list .vector-R5ZAnD {
  background-color: transparent;
  height: 26px;
  left: 5px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-curriculum-list .vector-RYyMFx {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.teacher-curriculum-list .vector-SCg0eG {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  position: relative;
  width: 24px;
}
.teacher-curriculum-list .vector-SIAzDu {
  background-color: transparent;
  height: 20px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 16px;
}
.teacher-curriculum-list .vector-TCNT1O {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-curriculum-list .vector-TpoqgZ {
  background-color: transparent;
  height: 1px;
  left: 15px;
  position: absolute;
  top: 15px;
  width: 1px;
}
.teacher-curriculum-list .vector-UgkvXI {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-curriculum-list .vector-WcGkdi {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.teacher-curriculum-list .vector-XV2kdn {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.teacher-curriculum-list .vector-b1bsPs {
  background-color: transparent;
  height: 1px;
  left: 15px;
  position: absolute;
  top: 15px;
  width: 1px;
}
.teacher-curriculum-list .vector-dlRKJA {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-curriculum-list .vector-fPPgx3 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-curriculum-list .vector-grLYV9 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-curriculum-list .vector-iniHBi {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-curriculum-list .vector-jWA1w5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.teacher-curriculum-list .vector-kCLhu1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-curriculum-list .vector-mju0OS {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-curriculum-list .vector-oRqxWx {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-curriculum-list .vector-s2Y5x6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.teacher-curriculum-list .vector-vgli2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.teacher-curriculum-list .vector-xbu9jm {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.teacher-curriculum-list .vector-yQhZ26 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-curriculum-list .view-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 113px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 211px;
  width: auto;
}
.teacher-curriculum-list .view-PxGwRP {
  background-color: transparent;
  height: auto;
  left: 113px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 211px;
  width: auto;
}
.teacher-curriculum-list .view-zoyRd5 {
  background-color: transparent;
  height: auto;
  left: 113px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 211px;
  width: auto;
}
.teacher-curriculum-list .x10pm-C2l7Eu {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 45px;
}
.teacher-curriculum-list .x12pm-ePioxc {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 47px;
}
.teacher-curriculum-list .x1pm-ePioxc {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 133px;
  width: 33px;
}
.teacher-curriculum-list .x2pm-XCjVcD {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 39px;
}
.teacher-curriculum-list .x3pm-Hx49NZ {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 31px;
}
.teacher-curriculum-list .x4pm-G1xvhx {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-curriculum-list .x5pm-cfQi7W {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-curriculum-list .x6pm-hUx6Ho {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-curriculum-list .x7pm-MZRcus {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-curriculum-list .x8pm-FwJWKX {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-curriculum-list .x9pm-oOO7Ym {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .teacher-curriculum-list {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .teacher-curriculm-mobile {
    display: none;
  }
}
