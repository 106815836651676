.Teacher_Lecture_Layout {
    width: 97%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}


.sider-teacher-Notes {
    width: 90px;
    height: 100vh;

    position: relative;
    background-color: #212a3b;
}

.sider-teacher-Notes .sider-teacher-Notes-in-wdt-80 {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background-color: #212a3b;
}

.icon-in-hc-color-2 {
    font-size: 26;
    color: rgb(220, 53, 69);
}

@media screen and (min-width: 840px) {
    .Teacher_Lecture_Layout {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
}

@media screen and (max-width: 841px) {
    .sider-teacher-Notes {
        display: none;
    }
}