.width-in-notes-class {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.width-in-notes-class .logo-in-class-10 {
  /* border: 1px solid gray; */
  width: 160px;
  height: 80px;

  margin: 20px;
  align-self: flex-start;
}

.width-in-notes-class .status-1 {
  padding: 7px;
  width: 90%;
  margin-top: 20px;
  min-height: 120px;
  /* background-color: #dc3545; */

  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.width-in-notes-class .status-1 .Dropdown-lec-notes {
  width: 90%;
  height: auto;
  margin: 4px;

  box-shadow: 0px 1px 1px rgb(224, 224, 224);
}

.width-in-notes-class .head-in-lec-notes {
  width: 90%;
  margin: 10px;

  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  color: #dc3545;
}

.width-in-notes-class .input-in-lec-notes {
  width: 90%;
  height: 40px;
  margin: 4px;

  padding: 12px 0px;
  border: 1px solid rgb(228, 228, 228);
  box-shadow: 0px 1px 1px rgb(224, 224, 224);
  border-radius: 5px;

  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  color: #dc3545;
}

.width-in-notes-class .button-in-lec-notes {
  width: 90%;
  height: 40px;
  padding: 11px;
  margin: 4px;

  border-radius: 5px;
  border: none;
  color: white;
  background-color: #dc3545;

  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", Arial, Helvetica;
}

.width-in-notes-class .status-2-all-notes {
  padding: 7px;
  width: 90%;
  margin-top: 10px;
  /* background-color: #dc3545; */

  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 20px;
}

.width-in-notes-class .status-2-all-notes-in {
  padding: 8px;
  height: 300px;
  /* background-color: #dc3545; */

  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  align-items: flex-start;
}

.width-in-notes-class .status-2-all-notes .status-in-lec-notes {
  width: 99%;
  margin-top: 10px;
  min-height: 48px;

  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 10px;
}

.status-2-all-notes .status-in-lec-notes .content-in-lecture-notes {
  width: 96%;
  margin: 5px;
  color: rgba(126, 126, 126, 1);

  font-family: "Montserrat", Arial, Helvetica;
  font-size: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-in-lec-notes .content-in-lecture-notes .notes-heading-font-color {
  color: #dc3545;
  margin: 1px;

  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", Arial, Helvetica;
}

.content-in-lecture-notes .display-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-in-lecture-notes .Content-icon-in-lec-notes {
  font-size: 17px;
  color: gray;
  margin: 5px;
}

.content-in-lecture-notes .Content-icon-in-lec-notes :hover {
  color: #dc3545;
}

@media screen and (max-width: 841px) {
  .logo-in-class-10 {
    display: none;
  }
}

@media screen and (min-width: 840px) {
  .width-in-notes-class {
    width: 80%;
  }

  .width-in-notes-class .status-2-all-notes {
    margin-top: 5px;
  }

  .width-in-notes-class .status-2-all-notes-in {
    height: 280px;
  }
}
