.salesStatistics_detail_layout {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.header-wdt-100 {
    width: 100%;
}

.sider-SalesStatistics-detail {
    width: 50px;
    height: 100vh;
    position: fixed;
    background-color: #1f2531;
}

.sider-hc-teacher-list-in-wdt-80 {
    width: 100%;
    height: 70%;
    margin: 80px 0px 30px;
    color: gray;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.icon-in-hc-color {
    color: rgba(179, 179, 179, 1.0);
    font-size: 24px;
}
.content-in-SalesStatistics-layout {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.status1 {
    padding: 7px;
    width: 90%;
    margin-top: 20px;
    background-color: #dc3545;

    box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
    border-radius: 20px;

    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 841px) {
    .salesStatistics_detail_layout .sider-SalesStatistics-detail {
        display: none;
    }
}



@media screen and (min-width: 840px) {
    .header-wdt-100 {
        display: none;
    }

    .salesStatistics_detail_layout {
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }

    .content-in-SalesStatistics-layout {
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}



/* .Header {
  z-index: -10;
  background: white;
  height: 52px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin: 1.2em;
  font-size: 18px;
}

.heder-men-not {
  font-size: 18px;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.notification {
  margin: 1em;
}

.menu {
  margin: 1.2em;
} */