.hero {
  /* border-radius: 15px 50px; */
  height: 500px;
  width: 100%;
}

.annnouce a {
  text-decoration: none;
  color: #dc3545;
  font-weight: 600;
}

.annnouce a:hover {
  color: #2e82e8;
}

