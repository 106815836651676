/* screen - student-lecture-note-list */

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-14px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-white-18px {
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.student-lecture-note-list {
  background-color: rgba(253, 254, 255, 1);
  height: auto;
  width: 100%;
}

.student-lecture-note-list .lecture-note-list-col {
  position: relative;
  top: 0;
  left: 63px;
  width: calc(100% - 63px);
  height: 100%;
}

.student-lecture-note-list .analytics-24px-3-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 644px;
  width: 24px;
}

.student-lecture-note-list .assignment-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 348px;
  width: 24px;
}

.student-lecture-note-list .biba-sobti-VdQhAS {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 92px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}

.student-lecture-note-list .bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 47px;
  width: auto;
}

.student-lecture-note-list .chemistry-grade-9-rdL5Qr {
  background-color: transparent;
  height: auto;
  left: 44px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 117px;
  width: 166px;
}

.student-lecture-note-list .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;
  left: 4.1%;
  position: absolute;
  top: 45px;
  width: 212px;
}

.student-lecture-note-list .component-29-i2K799 {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 61px;
  width: 61px;
}

.student-lecture-note-list .component-29-q2BeyX {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 61px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 61px;
}

.student-lecture-note-list .component-29-ynAtcP {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 61px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 61px;
}

.student-lecture-note-list .description-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 496px;
  width: 24px;
}

.student-lecture-note-list .doubt-forum-C61RwL {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 20px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 20px;
  width: 128px;
}

.student-lecture-note-list .ellipse-2-OSIBbx {
  background-color: transparent;
  height: 19.05%;
  left: 57.14%;
  position: absolute;
  top: 21.43%;
  width: 19.05%;
}

.student-lecture-note-list .group-0M78Jh {
  background-color: transparent;
  background-size: 100% 100%;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-160-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  position: absolute;
  top: 718px;
  width: 24px;
}

.student-lecture-note-list .group-161-kpbtyZ {
  background-color: transparent;
  height: 26px;
  left: 18px;
  position: absolute;
  top: 16px;
  width: 26px;
}

.student-lecture-note-list .group-162-C61RwL {
  background-color: transparent;
  height: 26px;
  left: 640px;
  position: absolute;
  top: 339px;
  width: 26px;
}

.student-lecture-note-list .group-163-C61RwL {
  background-color: transparent;
  height: 26px;
  left: 269px;
  position: absolute;
  top: 661px;
  width: 26px;
}

.student-lecture-note-list .group-1G6kDx {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-lecture-note-list .group-200-C61RwL {
  background-color: transparent;
  height: 61px;
  left: 251px;
  position: absolute;
  top: 323px;
  width: 61px;
}

.student-lecture-note-list .group-22-C61RwL {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}

.student-lecture-note-list .group-26DuQS {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-lecture-note-list .group-30TA9U {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-note-list .group-3YhNjD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-3qdjky {
  background-color: transparent;
  background-image: url(./img/vector-39@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-note-list .group-4fKC6i {
  background-color: transparent;
  background-image: url(./img/vector-40@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-lecture-note-list .group-4wy4Bx {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-57-C61RwL {
  background-color: transparent;
  height: 600px;
  left: 6%;
  position: absolute;
  top: 297px;
  width: 35%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow: auto;
}

.student-lecture-note-list .group-58-C61RwL {
  background-color: transparent;
  height: 262px;
  left: 526px;
  position: absolute;
  top: 297px;
  width: 255px;
}

.student-lecture-note-list .group-59-C61RwL {
  background-color: transparent;
  height: 262px;
  left: 155px;
  position: absolute;
  top: 619px;
  width: 255px;
}

.student-lecture-note-list .group-5YlXKz {
  background-color: transparent;
  background-image: url(./img/vector-40@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-lecture-note-list .group-5jt8cF {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-5r1RGr {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-lecture-note-list .group-60-kpbtyZ {
  background-color: transparent;
  height: 61px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 61px;
}

.student-lecture-note-list .group-61-C61RwL {
  background-color: transparent;
  height: 61px;
  left: 622px;
  position: absolute;
  top: 323px;
  width: 61px;
}

.student-lecture-note-list .group-62-C61RwL {
  background-color: transparent;
  height: 61px;
  left: 251px;
  position: absolute;
  top: 645px;
  width: 61px;
}

.student-lecture-note-list .group-8RWWXx {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-lecture-note-list .group-8SehwL {
  background-color: transparent;
  background-image: url(./img/vector-47@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-lecture-note-list .group-8mXtiA {
  background-color: transparent;
  background-image: url(./img/vector-47@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-lecture-note-list .group-Ambq12 {
  background-color: transparent;
  background-image: url(./img/vector-48@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-lecture-note-list .group-B0zdg8 {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-lecture-note-list .group-BVoyFW {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-lecture-note-list .group-CZ3XDU {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-lecture-note-list .group-E9Z3Qz {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-lecture-note-list .group-Eub1uD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-FDKNYY {
  background-color: transparent;
  background-image: url(./img/vector-47@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-lecture-note-list .group-GQEqeI {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-GQNshl {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-Gv5bm4 {
  background-color: transparent;
  background-image: url(./img/vector-39@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-note-list .group-H4kFYy {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-lecture-note-list .group-Hc5Phf {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-lecture-note-list .group-HkDxar {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-I6cVpt {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-Ie5dUU {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-lecture-note-list .group-J1LPKs {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-K12spc {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-note-list .group-KLBpwU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-lecture-note-list .group-KWN2RE {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-lecture-note-list .group-KcOtnV {
  background-color: transparent;
  background-image: url(./img/vector-47@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-lecture-note-list .group-KsMB6y {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-lecture-note-list .group-LCuxKV {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-lecture-note-list .group-LMly0o {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-lecture-note-list .group-OUAngl {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-lecture-note-list .group-SfTnen {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-lecture-note-list .group-SlOO8W {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-lecture-note-list .group-SlxGcy {
  background-color: transparent;
  background-image: url(./img/vector-39@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-note-list .group-Sw5xnU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-lecture-note-list .group-W5IipO {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-WAmUNE {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-lecture-note-list .group-X6Y0CP {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-lecture-note-list .group-XHqu7O {
  background-color: transparent;
  background-image: url(./img/vector-39@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-note-list .group-Z0P29R {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-lecture-note-list .group-Zmqz7u {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-lecture-note-list .group-a2vI4G {
  background-color: transparent;
  background-image: url(./img/vector-36@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-lecture-note-list .group-b1ls5q {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-lecture-note-list .group-bznq7Z {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-lecture-note-list .group-cTYyqt {
  background-color: transparent;
  background-image: url(./img/vector-36@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-lecture-note-list .group-ce7Ifp {
  background-color: transparent;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 20px;
}

.student-lecture-note-list .group-dimqSz {
  background-color: transparent;
  background-image: url(./img/vector-40@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-lecture-note-list .group-f6NdeG {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-lecture-note-list .group-fbkhx3 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-note-list .group-fxegOB {
  background-color: transparent;
  background-image: url(./img/vector-48@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-lecture-note-list .group-hNGwvV {
  background-color: transparent;
  background-image: url(./img/vector-36@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-lecture-note-list .group-hcfGEs {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-lecture-note-list .group-hoVMgD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-htgOUZ {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-lecture-note-list .group-hxa4BC {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-lecture-note-list .group-kK588U {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-lecture-note-list .group-lX9zMw {
  background-color: transparent;
  background-image: url(./img/vector-48@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-lecture-note-list .group-lmXTEf {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-lecture-note-list .group-m8nkkI {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-lecture-note-list .group-mnqxdV {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-lecture-note-list .group-oRRBjL {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-lecture-note-list .group-paC7Rl {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-lecture-note-list .group-pmZUQc {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-lecture-note-list .group-sx6xBo {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-tDnSYz {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-lecture-note-list .group-tr5zNE {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-note-list .group-wbAI42 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-note-list .group-wotaxO {
  background-color: transparent;
  background-image: url(./img/vector-48@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-lecture-note-list .group-wpJ3xR {
  background-color: transparent;
  background-image: url(./img/vector-40@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-lecture-note-list .group-xKrbSU {
  background-color: transparent;
  background-image: url(./img/vector-36@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-lecture-note-list .group-xfUToA {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-lecture-note-list .group-zkxeqe {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-lecture-note-list .help-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 570px;
  width: 24px;
}

.student-lecture-note-list .how-to-solve-this-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1031px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 439px;
  width: 290px;
}

.student-lecture-note-list .join-4-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 974px;
  overflow: hidden;
  position: absolute;
  top: 261px;
  width: 46px;
}

.student-lecture-note-list .join-5-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 974px;
  overflow: hidden;
  position: absolute;
  top: 348px;
  width: 46px;
}

.student-lecture-note-list .join-6-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 978px;
  overflow: hidden;
  position: absolute;
  top: 435px;
  width: 46px;
}

.student-lecture-note-list .join-7-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 981px;
  overflow: hidden;
  position: absolute;
  top: 519px;
  width: 46px;
}

.student-lecture-note-list .line-5-9p2GBv {
  background-color: transparent;
  height: 99.05%;
  left: 0.9%;
  position: absolute;
  top: 0.48%;
  width: 99.29%;
}

.student-lecture-note-list .line-6-9p2GBv {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}

.student-lecture-note-list .mathematics-grade-9-AwXtS8 {
  background-color: transparent;
  height: auto;
  /* left: 44px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  /* top: 117px; */
  width: 166px;
}

.student-lecture-note-list .my-lecture-notes-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 6%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 210px;
  width: 342px;
}

.student-lecture-note-list .notifications-24px-2-1-C61RwL {
  background-color: transparent;
  height: 42px;
  left: 976px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 42px;
}

.student-lecture-note-list .physics-grade-9-GZmYSZ {
  background-color: transparent;
  height: auto;
  left: 44px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 117px;
  width: 166px;
}

.student-lecture-note-list .please-check-my-approach-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1034px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 523px;
  width: 290px;
}

.student-lecture-note-list .rectangle-24-C61RwL-stu-s1 {
  background-color: #1f2531;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 63px;

  overflow-y: scroll;
  padding: 150px 0px 20px 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.student-lecture-note-list .rectangle-40-AwXtS8 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 262px;
  /* left: 0px;
  position: absolute;
  top: 0px; */
  width: 251px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 30px 10px;
}

.student-lecture-note-list .rectangle-40-GZmYSZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 251px;
}

.student-lecture-note-list .rectangle-40-rdL5Qr {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 251px;
}

.student-lecture-note-list .rectangle-41-AwXtS8 {
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* left: 60px; */
  /* position: absolute; */
  /* top: 195px; */
  text-align: center;
  padding: 8px;
}

.student-lecture-note-list .rectangle-41-GZmYSZ {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 65px;
  position: absolute;
  top: 202px;
  width: 122px;
}

.student-lecture-note-list .rectangle-41-rdL5Qr {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 65px;
  position: absolute;
  top: 202px;
  width: 122px;
}

.student-lecture-note-list .rectangle-43-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* height: 417px; */
  right: 8%;
  position: absolute;
  top: 197px;
  width: 30%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding: 72px 24px 40px;
  min-height: 160px;
}

.student-lecture-note-list .rectangle-7-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 630px;
  position: absolute;
  top: 631px;
  width: 1px;
}

.student-lecture-note-list .school-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 422px;
  width: 24px;
}

.student-lecture-note-list .span0-A5sNXg {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.student-lecture-note-list .span0-PNUW6A {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.student-lecture-note-list .span0-sKAmxU {
  color: rgb(220, 53, 69);
  font-style: normal;
}

.student-lecture-note-list .span0-xWOZar {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.student-lecture-note-list .span1-A5sNXg {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.student-lecture-note-list .span1-PNUW6A {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.student-lecture-note-list .span1-sKAmxU {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}

.student-lecture-note-list .span1-xWOZar {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.student-lecture-note-list .stickynote2-24px-1-2V1Wct {
  background-color: transparent;
  height: 26px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 26px;
}

.student-lecture-note-list .stickynote2-24px-1-AQVGOI {
  background-color: transparent;
  height: 26px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 26px;
}

.student-lecture-note-list .stickynote2-24px-1-Cp4Be6 {
  background-color: transparent;
  height: 24px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 24px;
}

.student-lecture-note-list .stickynote2-24px-1-QlfYLY {
  background-color: transparent;
  height: 26px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 26px;
}

.student-lecture-note-list .supportagent-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 792px;
  width: 24px;
}

.student-lecture-note-list .vector-0HfMqN {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-lecture-note-list .vector-1G6kDx {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-lecture-note-list .vector-1YY105 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-lecture-note-list .vector-1ewXKa {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-lecture-note-list .vector-26DuQS {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-lecture-note-list .vector-2kxNnt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-lecture-note-list .vector-30TA9U {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-lecture-note-list .vector-31rn3Y {
  background-color: transparent;
  height: 61.54%;
  left: 19.23%;
  position: absolute;
  top: 19.23%;
  width: 61.54%;
}

.student-lecture-note-list .vector-3q0WPs {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-lecture-note-list .vector-3qdjky {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-lecture-note-list .vector-4ae2Rf {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-lecture-note-list .vector-4fKC6i {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-lecture-note-list .vector-4sG9FF {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-lecture-note-list .vector-4vfNnN {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-lecture-note-list .vector-5S9pFx {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-lecture-note-list .rectangle-43-C61RwL .doubt-row {
  display: flex;
  justify-content: space-evenly;
  margin: 4px;
  align-items: center;
}

.student-lecture-note-list .vector-5YlXKz {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-lecture-note-list .vector-5r1RGr {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-lecture-note-list .vector-5x4wyG {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-lecture-note-list .vector-6XJ1dC {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}

.student-lecture-note-list .vector-7QdKbH {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-lecture-note-list .vector-7lctNO {
  background-color: transparent;
  height: 76.92%;
  left: 11.54%;
  position: absolute;
  top: 11.54%;
  width: 76.92%;
}

.student-lecture-note-list .vector-7pUNkI {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-lecture-note-list .vector-7txfVx {
  background-color: transparent;
  height: 58.33%;
  left: 20.83%;
  position: absolute;
  top: 20.83%;
  width: 58.33%;
}

.student-lecture-note-list .vector-7uUfdl {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-lecture-note-list .vector-8JNBpx {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-lecture-note-list .vector-8RWWXx {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-lecture-note-list .vector-8SehwL {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-lecture-note-list .vector-8U01yU {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-lecture-note-list .vector-8XEWy4 {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-lecture-note-list .vector-8mXtiA {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-lecture-note-list .vector-8xAgQn {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-lecture-note-list .vector-9opORi {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-lecture-note-list .vector-9vYPU5 {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-lecture-note-list .vector-AONfqU {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-lecture-note-list .vector-Ambq12 {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-lecture-note-list .vector-AuhUI1 {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-lecture-note-list .vector-B0zdg8 {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-lecture-note-list .vector-BVoyFW {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-lecture-note-list .vector-C61RwL {
  background-color: transparent;
  height: 2.34%;
  left: 1.32%;
  position: absolute;
  top: 92.77%;
  width: 1.67%;
}

.student-lecture-note-list .vector-CLdsug {
  background-color: transparent;
  height: 82.14%;
  left: 16.67%;
  position: absolute;
  top: 9.97%;
  width: 66.67%;
}

.student-lecture-note-list .vector-CMx3Uj {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-lecture-note-list .vector-CZ3XDU {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-lecture-note-list .vector-CZQNIo {
  background-color: transparent;
  height: 75%;
  left: 4.17%;
  position: absolute;
  top: 12.5%;
  width: 91.67%;
}

.student-lecture-note-list .vector-ChPPby {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-lecture-note-list .vector-DcBIgL {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-lecture-note-list .vector-Dn0VOv {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-lecture-note-list .vector-E3VqMR {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-lecture-note-list .vector-E9Z3Qz {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-lecture-note-list .vector-FDKNYY {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-lecture-note-list .vector-Fb0JQL {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-lecture-note-list .vector-FxTcxt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-lecture-note-list .vector-G0yBDR {
  background-color: transparent;
  height: 83.33%;
  left: 16.67%;
  position: absolute;
  top: 8.33%;
  width: 66.67%;
}

.student-lecture-note-list .vector-Gv5bm4 {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-lecture-note-list .vector-H4kFYy {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-lecture-note-list .vector-Hc5Phf {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-lecture-note-list .vector-JWb765 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-lecture-note-list .vector-K12spc {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-lecture-note-list .vector-KDTVhH {
  background-color: transparent;
  height: 1.92%;
  left: 49.04%;
  position: absolute;
  top: 49.04%;
  width: 1.92%;
}

.student-lecture-note-list .vector-KLBpwU {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-lecture-note-list .vector-KLFwa3 {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-lecture-note-list .vector-KRXr9B {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-lecture-note-list .vector-KWN2RE {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-lecture-note-list .vector-KcOtnV {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-lecture-note-list .vector-LCuxKV {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-lecture-note-list .vector-LMly0o {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-lecture-note-list .vector-LgtYic {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-lecture-note-list .vector-MWjU8S {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-lecture-note-list .vector-MtJxOL {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-lecture-note-list .vector-NLtoul {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-lecture-note-list .vector-OB8oOM {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-lecture-note-list .vector-OSIBbx {
  background-color: transparent;
  height: 1.19%;
  left: 49.4%;
  position: absolute;
  top: 49.4%;
  width: 1.19%;
}

.student-lecture-note-list .vector-OUAngl {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-lecture-note-list .vector-OsxaKh {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-lecture-note-list .vector-Ozaj6l {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-lecture-note-list .vector-QxuFcp {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-lecture-note-list .vector-SfTnen {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-lecture-note-list .vector-SlOO8W {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-lecture-note-list .vector-SlxGcy {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-lecture-note-list .vector-Sw5xnU {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-lecture-note-list .vector-T0rcQo {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-lecture-note-list .vector-Ta8Heo {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-lecture-note-list .vector-TlPffh {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-lecture-note-list .vector-TmFVy3 {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-lecture-note-list .vector-TvsQX8 {
  background-color: transparent;
  height: 61.54%;
  left: 19.23%;
  position: absolute;
  top: 19.23%;
  width: 61.54%;
}

.student-lecture-note-list .vector-UUKtms {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-lecture-note-list .vector-UaM55c {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-lecture-note-list .vector-UxrCfI {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-lecture-note-list .vector-VAjk24 {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-lecture-note-list .vector-VJLVH1 {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-lecture-note-list .vector-VMr6Om {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 26.76%;
  width: 1.67%;
}

.student-lecture-note-list .vector-VP2Rux {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-lecture-note-list .vector-VdQhAS {
  background-color: transparent;
  height: 100%;
  left: 73.42%;
  position: absolute;
  top: 0px;
  width: 25.32%;
}

.student-lecture-note-list .vector-W1hXZ4 {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-lecture-note-list .vector-WAmUNE {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-lecture-note-list .vector-WK7UJi {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-lecture-note-list .vector-WVknxn {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-lecture-note-list .vector-WbzDQU {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-lecture-note-list .vector-X6Y0CP {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-lecture-note-list .vector-XHqu7O {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-lecture-note-list .vector-XQwS4n {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-lecture-note-list .vector-XjFi5l {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-lecture-note-list .vector-XqVjZB {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-lecture-note-list .vector-XwxuE1 {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-lecture-note-list .vector-YarvLR {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-lecture-note-list .vector-Z0P29R {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-lecture-note-list .vector-a2vI4G {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-lecture-note-list .vector-apzjgl {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-lecture-note-list .vector-b1ls5q {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-lecture-note-list .vector-bIaha6 {
  background-color: transparent;
  height: 76.92%;
  left: 11.54%;
  position: absolute;
  top: 11.54%;
  width: 76.92%;
}

.student-lecture-note-list .vector-baKUI6 {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-lecture-note-list .vector-bznq7Z {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-lecture-note-list .vector-c18eCt {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-lecture-note-list .vector-cTYyqt {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-lecture-note-list .vector-ce7HL0 {
  background-color: transparent;
  height: 1.92%;
  left: 49.04%;
  position: absolute;
  top: 49.04%;
  width: 1.92%;
}

.student-lecture-note-list .vector-d3kxdK {
  background-color: transparent;
  height: 76.92%;
  left: 11.54%;
  position: absolute;
  top: 11.54%;
  width: 76.92%;
}

.student-lecture-note-list .vector-dBr6KK {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-lecture-note-list .vector-dLw05Z {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-lecture-note-list .vector-dimqSz {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-lecture-note-list .vector-dmgbrE {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-lecture-note-list .vector-dsyqYk {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-lecture-note-list .vector-eh9WzI {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-lecture-note-list .vector-f6NdeG {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-lecture-note-list .vector-fCysJq {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-lecture-note-list .vector-fbkhx3 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-lecture-note-list .vector-fxegOB {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-lecture-note-list .vector-g9aAPA {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-lecture-note-list .vector-gsSdey {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-lecture-note-list .vector-hNGwvV {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-lecture-note-list .vector-hPKSzt {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-lecture-note-list .vector-hcfGEs {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-lecture-note-list .vector-htgOUZ {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-lecture-note-list .vector-hxa4BC {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-lecture-note-list .vector-iEudy6 {
  background-color: transparent;
  height: 61.54%;
  left: 19.23%;
  position: absolute;
  top: 19.23%;
  width: 61.54%;
}

.student-lecture-note-list .vector-ib8Dlq {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-lecture-note-list .vector-irJBpK {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-lecture-note-list .vector-jQrdMY {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-lecture-note-list .vector-jmCVFs {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-lecture-note-list .vector-kK588U {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-lecture-note-list .vector-l8qsXH {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-lecture-note-list .vector-lWWNsc {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-lecture-note-list .vector-lX9zMw {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-lecture-note-list .vector-lmXTEf {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-lecture-note-list .vector-m8nkkI {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-lecture-note-list .vector-mmO9xv {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-lecture-note-list .vector-mzXdH9 {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 19.53%;
  width: 1.67%;
}

.student-lecture-note-list .vector-n1UKnn {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-lecture-note-list .vector-nXJ6dP {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-lecture-note-list .vector-oOS98D {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-lecture-note-list .vector-oRRBjL {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-lecture-note-list .vector-oZNlX3 {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-lecture-note-list .vector-objxfZ {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-lecture-note-list .vector-paC7Rl {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-lecture-note-list .vector-pagq92 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-lecture-note-list .vector-pgMXxb {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-lecture-note-list .vector-pmZUQc {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-lecture-note-list .vector-pxZ96q {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-lecture-note-list .vector-qF2ch0 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-lecture-note-list .vector-qGJxwJ {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-lecture-note-list .vector-qHVi3n {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-lecture-note-list .vector-qmzfLw {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-lecture-note-list .vector-qy1MCO {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-lecture-note-list .vector-r18CAb {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-lecture-note-list .vector-r4buxr {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-lecture-note-list .vector-rX1xqz {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-lecture-note-list .vector-rnzczl {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-lecture-note-list .vector-sCPPBx {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-lecture-note-list .vector-sSEX1y {
  background-color: transparent;
  height: 1.92%;
  left: 49.04%;
  position: absolute;
  top: 49.04%;
  width: 1.92%;
}

.student-lecture-note-list .vector-tDnSYz {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-lecture-note-list .vector-tSUwp7 {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-lecture-note-list .vector-uhbuVM {
  background-color: transparent;
  height: 36.11%;
  left: 20.01%;
  position: absolute;
  top: 16.53%;
  width: 60%;
}

.student-lecture-note-list .vector-v9Qr6G {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-lecture-note-list .vector-vJyLWf {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-lecture-note-list .vector-wU39qv {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-lecture-note-list .vector-wbAI42 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-lecture-note-list .vector-wotaxO {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-lecture-note-list .vector-wpJ3xR {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-lecture-note-list .vector-x7OZie {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-lecture-note-list .vector-xKrbSU {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-lecture-note-list .vector-xYu4ww {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-lecture-note-list .vector-xfUToA {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-lecture-note-list .vector-xr8pKE {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-lecture-note-list .vector-xy9YKY {
  background-color: transparent;
  height: 11.11%;
  left: 30%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-lecture-note-list .vector-ydL8U7 {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-lecture-note-list .vector-ye3NYT {
  background-color: transparent;
  height: 11.11%;
  left: 60%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-lecture-note-list .vector-ywnyBE {
  background-color: transparent;
  height: 83.33%;
  left: 8.33%;
  position: absolute;
  top: 8.33%;
  width: 83.33%;
}

.student-lecture-note-list .vector-zJJzco {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-lecture-note-list .vector-zLY8MO {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-lecture-note-list .vector-zkxeqe {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-lecture-note-list .view-AwXtS8 {
  background-color: transparent;
  color: black;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;

  width: auto;
}

.student-lecture-note-list .view-GZmYSZ {
  background-color: transparent;
  color: black;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  right: 114px;
  text-align: left;
  top: 211px;
  width: auto;
}

.student-lecture-note-list .view-all-C61RwL {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  right: 20px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 22px;
  width: 75px;
}

.student-lecture-note-list .view-forum-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1227px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 298px;
  width: 113px;
}

.student-lecture-note-list .view-forum-QxM5SU {
  background-color: transparent;
  height: auto;
  left: 1230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 554px;
  width: 113px;
}

.student-lecture-note-list .view-forum-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 1227px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 374px;
  width: 113px;
}

.student-lecture-note-list .view-forum-mzXdH9 {
  background-color: transparent;
  height: auto;
  left: 1227px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 470px;
  width: 113px;
}

.student-lecture-note-list .view-rdL5Qr {
  background-color: transparent;
  color: black;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  right: 114px;
  text-align: left;
  top: 211px;
  width: auto;
}

.student-lecture-note-list .what-is-the-si-unit-of-current-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1031px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 267px;
  width: 290px;
}

.student-lecture-note-list .what-is-the-sol-f-this-question-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1031px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 343px;
  width: 290px;
}

.student-lecture-note-list .x14-C61RwL {
  background-color: transparent;
  color: rgba(244, 244, 244, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 1163px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 357px;
  width: auto;
}

:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --white: rgba(255, 255, 255, 1);
}

/* screen - student-lecture-notes-list-mobile */

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-14px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-white-18px {
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.student-lecture-notes-list-mobile {
  background-color: rgba(253, 254, 255, 1);
  height: 100vh;
  margin: 0px;
  min-height: 1494px;
  min-width: 414px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.student-lecture-notes-list-mobile .chemistry-grade-9-Z1JSTw {
  background-color: transparent;
  height: auto;
  left: 44px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 117px;
  width: 166px;
}

.student-lecture-notes-list-mobile .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 45px;
  left: 26px;
  position: absolute;
  top: 24px;
  width: 87px;
}

.student-lecture-notes-list-mobile .component-29-D1n0rW {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 61px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 61px;
}

.student-lecture-notes-list-mobile .component-29-Up6zcy {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 61px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 61px;
}

.student-lecture-notes-list-mobile .component-29-cPi90N {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 61px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 61px;
}

.student-lecture-notes-list-mobile .component-6-C61RwL {
  background-color: transparent;
  height: 18px;
  left: 362px;
  position: absolute;
  top: 37px;
  width: 23px;
}

.student-lecture-notes-list-mobile .description-24px1-1-D1n0rW {
  /* background-color: var(--white); */
  height: 26px;
  left: 18px;
  overflow: hidden;
  position: absolute;
  top: 16px;
  width: 26px;
}

.student-lecture-notes-list-mobile .description-24px1-1-Up6zcy {
  /* background-color: var(--white); */
  height: 26px;
  left: 18px;
  overflow: hidden;
  position: absolute;
  top: 16px;
  width: 26px;
}

.student-lecture-notes-list-mobile .description-24px1-1-cPi90N {
  /* background-color: var(--white); */
  height: 26px;
  left: 18px;
  overflow: hidden;
  position: absolute;
  top: 16px;
  width: 26px;
}

.student-lecture-notes-list-mobile .doubt-forum-rFX9Nh {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 25px;
  width: 134px;
}

.student-lecture-notes-list-mobile .group-1pzCME {
  background-color: transparent;
  background-image: url(./img/vector-298@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-lecture-notes-list-mobile .group-3HWdqU {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-lecture-notes-list-mobile .group-3eHi1a {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-lecture-notes-list-mobile .group-3xCoUY {
  background-color: transparent;
  height: 3px;
  left: 37px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-lecture-notes-list-mobile .group-4Tztbv {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-notes-list-mobile .group-57-C61RwL {
  background-color: transparent;
  height: 262px;
  left: 82px;
  position: absolute;
  top: 220px;
  width: 255px;
}

.student-lecture-notes-list-mobile .group-58-C61RwL {
  background-color: transparent;
  height: 262px;
  left: 81px;
  position: absolute;
  top: 545px;
  width: 255px;
}

.student-lecture-notes-list-mobile .group-5ZFw7U {
  background-color: transparent;
  background-image: url(./img/vector-299@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-lecture-notes-list-mobile .group-60-C61RwL {
  background-color: transparent;
  height: 61px;
  left: 176px;
  position: absolute;
  top: 247px;
  width: 61px;
}

.student-lecture-notes-list-mobile .group-6dsxAM {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-lecture-notes-list-mobile .group-6u2TAk {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-lecture-notes-list-mobile .group-76-C61RwL {
  background-color: transparent;
  height: 262px;
  left: 81px;
  position: absolute;
  top: 870px;
  width: 255px;
}

.student-lecture-notes-list-mobile .group-85-C61RwL {
  background-color: transparent;
  height: 270px;
  left: 26px;
  position: absolute;
  top: 1195px;
  width: 394px;
}

.student-lecture-notes-list-mobile .group-86-C61RwL {
  background-color: transparent;
  height: 61px;
  left: 176px;
  position: absolute;
  top: 572px;
  width: 61px;
}

.student-lecture-notes-list-mobile .group-87-C61RwL {
  background-color: transparent;
  height: 61px;
  left: 176px;
  position: absolute;
  top: 897px;
  width: 61px;
}

.student-lecture-notes-list-mobile .group-9neAS5 {
  background-color: transparent;
  background-image: url(./img/vector-306@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-lecture-notes-list-mobile .group-B3X4ab {
  background-color: transparent;
  background-image: url(./img/vector-299@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-lecture-notes-list-mobile .group-BpxbEL {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-notes-list-mobile .group-DhmCtC {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-lecture-notes-list-mobile .group-FASyuA {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-notes-list-mobile .group-HTx4Jc {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-notes-list-mobile .group-IfQt5H {
  background-color: transparent;
  background-image: url(./img/vector-307@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-lecture-notes-list-mobile .group-N2Y59c {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-notes-list-mobile .group-O5Cxw9 {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-lecture-notes-list-mobile .group-Ob1Rqs {
  background-color: transparent;
  background-image: url(./img/vector-252@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-lecture-notes-list-mobile .group-Qv0mZ4 {
  background-color: transparent;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-lecture-notes-list-mobile .group-S9AR9M {
  background-color: transparent;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-notes-list-mobile .group-T7EbAp {
  background-color: transparent;
  height: 3px;
  left: 37px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-lecture-notes-list-mobile .group-TdE1pD {
  background-color: transparent;
  background-image: url(./img/vector-261@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-lecture-notes-list-mobile .group-TtUkiV {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-lecture-notes-list-mobile .group-VOGBVy {
  background-color: transparent;
  background-image: url(./img/vector-304@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-lecture-notes-list-mobile .group-VRMQru {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-notes-list-mobile .group-WfnLBo {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-notes-list-mobile .group-bX4Dvh {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-notes-list-mobile .group-bcFrzZ {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-notes-list-mobile .group-bof6oJ {
  background-color: transparent;
  height: 3px;
  left: 39px;
  position: absolute;
  top: 10px;
  width: 4px;
}

.student-lecture-notes-list-mobile .group-c0ymqA {
  background-color: transparent;
  background-image: url(./img/vector-304@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-lecture-notes-list-mobile .group-c9XjZ6 {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-lecture-notes-list-mobile .group-dS4UXQ {
  background-color: transparent;
  background-image: url(./img/vector-307@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-lecture-notes-list-mobile .group-dV81ro {
  background-color: transparent;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-lecture-notes-list-mobile .group-dcaDkc {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-lecture-notes-list-mobile .group-gn7VaX {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-notes-list-mobile .group-gvV2Rx {
  background-color: transparent;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-lecture-notes-list-mobile .group-hhAbtl {
  background-color: transparent;
  background-image: url(./img/vector-260@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-lecture-notes-list-mobile .group-iieIco {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-notes-list-mobile .group-jLf4aV {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-lecture-notes-list-mobile .group-k7b1Mi {
  background-color: transparent;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-notes-list-mobile .group-lw8NAW {
  background-color: transparent;
  height: 3px;
  left: 39px;
  position: absolute;
  top: 10px;
  width: 4px;
}

.student-lecture-notes-list-mobile .group-mokeKx {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-lecture-notes-list-mobile .group-ms1pvS {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-lecture-notes-list-mobile .group-nD2hpN {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-lecture-notes-list-mobile .group-nzNou6 {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-lecture-notes-list-mobile .group-qGye3v {
  background-color: transparent;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-lecture-notes-list-mobile .group-qpftC5 {
  background-color: transparent;
  background-image: url(./img/vector-306@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-lecture-notes-list-mobile .group-sku7Fc {
  background-color: transparent;
  height: 3px;
  left: 37px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-lecture-notes-list-mobile .group-tQwNUN {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-lecture-notes-list-mobile .group-toBf7r {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-lecture-notes-list-mobile .group-wQSXdG {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-lecture-notes-list-mobile .group-x2R7zB {
  background-color: transparent;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-lecture-notes-list-mobile .group-xB1UPi {
  background-color: transparent;
  background-image: url(./img/vector-298@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-lecture-notes-list-mobile .group-xS7o0X {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-lecture-notes-list-mobile .group-xhwx9b {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-lecture-notes-list-mobile .group-xtOtSY {
  background-color: transparent;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-lecture-notes-list-mobile .group-zxfIi7 {
  background-color: transparent;
  height: 3px;
  left: 39px;
  position: absolute;
  top: 10px;
  width: 4px;
}

.student-lecture-notes-list-mobile .how-to-solve-this-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 70px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 214px;
  width: 296px;
}

.student-lecture-notes-list-mobile .join-7-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 11px;
  overflow: hidden;
  position: absolute;
  top: 68px;
  width: 47px;
}

.student-lecture-notes-list-mobile .join-8-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 9px;
  overflow: hidden;
  position: absolute;
  top: 139px;
  width: 47px;
}

.student-lecture-notes-list-mobile .join-9-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 9px;
  overflow: hidden;
  position: absolute;
  top: 210px;
  width: 47px;
}

.student-lecture-notes-list-mobile .line-5-9p2GBv {
  background-color: transparent;
  height: 101.11%;
  left: 0.55%;
  position: absolute;
  top: -0.56%;
  width: 100%;
}

.student-lecture-notes-list-mobile .line-6-9p2GBv {
  background-color: transparent;
  height: 102.22%;
  left: -0.29%;
  position: absolute;
  top: -1.11%;
  width: 100.57%;
}

.student-lecture-notes-list-mobile .mathematics-grade-9-AwXtS8 {
  background-color: transparent;
  height: auto;
  left: 44px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 117px;
  width: 166px;
}

.student-lecture-notes-list-mobile .my-lecture-notes-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 121px;
  width: 338px;
}

.student-lecture-notes-list-mobile .physics-grade-9-GZmYSZ {
  background-color: transparent;
  height: auto;
  left: 44px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 117px;
  width: 166px;
}

.student-lecture-notes-list-mobile .rectangle-40-AwXtS8 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 251px;
}

.student-lecture-notes-list-mobile .rectangle-40-GZmYSZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 251px;
}

.student-lecture-notes-list-mobile .rectangle-40-Z1JSTw {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 262px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 251px;
}

.student-lecture-notes-list-mobile .rectangle-41-AwXtS8 {
  border-radius: 5px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); */
  left: 60px;
  position: absolute;
  top: 195px;

  text-align: center;
  padding: 8px;
}

.student-lecture-notes-list-mobile .rectangle-41-GZmYSZ {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 65px;
  position: absolute;
  top: 202px;
  width: 122px;
}

.student-lecture-notes-list-mobile .rectangle-41-Z1JSTw {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 65px;
  position: absolute;
  top: 202px;
  width: 122px;
}

.student-lecture-notes-list-mobile .rectangle-44-rFX9Nh {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* height: 270px; */
  left: 0px;
  position: absolute;
  top: 0px;
  width: 367px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 120px;
  align-items: flex-start;
  padding: 66px 0;
}

.student-lecture-notes-list-mobile .rectangle-48-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-lecture-notes-list-mobile .rectangle-49-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 38.89%;
  width: 100%;
}

.student-lecture-notes-list-mobile .rectangle-50-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 77.78%;
  width: 100%;
}

.student-lecture-notes-list-mobile .span0-A5sNXg {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.student-lecture-notes-list-mobile .span0-PNUW6A {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.student-lecture-notes-list-mobile .span0-rN1zVH {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.student-lecture-notes-list-mobile .span0-sKAmxU {
  color: rgb(220, 53, 69);
  font-style: normal;
}

.student-lecture-notes-list-mobile .span1-A5sNXg {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.student-lecture-notes-list-mobile .span1-PNUW6A {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.student-lecture-notes-list-mobile .span1-rN1zVH {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.student-lecture-notes-list-mobile .span1-sKAmxU {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}

.student-lecture-notes-list-mobile .vector-0IPvau {
  background-color: transparent;
  height: 29.85%;
  left: 37.87%;
  position: absolute;
  top: 2.72%;
  width: 34.05%;
}

.student-lecture-notes-list-mobile .vector-1Nxj6x {
  background-color: transparent;
  height: 121.98%;
  left: -7.1%;
  position: absolute;
  top: -10.99%;
  width: 114.19%;
}

.student-lecture-notes-list-mobile .vector-1TafeU {
  background-color: transparent;
  height: 121.99%;
  left: -21.65%;
  position: absolute;
  top: -10.99%;
  width: 143.3%;
}

.student-lecture-notes-list-mobile .vector-1oS1NN {
  background-color: transparent;
  height: 144.3%;
  left: -6.65%;
  position: absolute;
  top: -22.15%;
  width: 113.29%;
}

.student-lecture-notes-list-mobile .vector-1pzCME {
  background-color: transparent;
  height: 52.24%;
  left: 49.49%;
  position: absolute;
  top: 8.1%;
  width: 45.75%;
}

.student-lecture-notes-list-mobile .vector-1xHKrh {
  background-color: transparent;
  height: 11.94%;
  left: 23.98%;
  position: absolute;
  top: 18.13%;
  width: 8.51%;
}

.student-lecture-notes-list-mobile .vector-2rF7C5 {
  background-color: transparent;
  height: 110.03%;
  left: -6.59%;
  position: absolute;
  top: -5.02%;
  width: 113.19%;
}

.student-lecture-notes-list-mobile .vector-3HWdqU {
  background-color: transparent;
  height: 101.5%;
  left: 11.25%;
  position: absolute;
  top: -1.01%;
  width: 85.12%;
}

.student-lecture-notes-list-mobile .vector-3X2JZC {
  background-color: transparent;
  height: 144.31%;
  left: -4.02%;
  position: absolute;
  top: -22.16%;
  width: 108.03%;
}

.student-lecture-notes-list-mobile .vector-3xCoUY {
  background-color: transparent;
  height: 13.43%;
  left: 63.81%;
  position: absolute;
  top: 41.24%;
  width: 18.09%;
}

.student-lecture-notes-list-mobile .vector-5SSNkN {
  background-color: transparent;
  height: 29.85%;
  left: 37.64%;
  position: absolute;
  top: 2.71%;
  width: 22.34%;
}

.student-lecture-notes-list-mobile .vector-5YyGRW {
  background-color: transparent;
  height: 19.4%;
  left: 24.76%;
  position: absolute;
  top: 22.08%;
  width: 13.83%;
}

.student-lecture-notes-list-mobile .vector-5ZFw7U {
  background-color: transparent;
  height: 29.85%;
  left: 2.08%;
  position: absolute;
  top: 8.12%;
  width: 45.75%;
}

.student-lecture-notes-list-mobile .vector-5ZxPCv {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}

.student-lecture-notes-list-mobile .vector-6Pj9gj {
  background-color: transparent;
  height: 5.97%;
  left: 50.75%;
  position: absolute;
  top: 63.23%;
  width: 7.45%;
}

.student-lecture-notes-list-mobile .vector-6dsxAM {
  background-color: transparent;
  height: 7.46%;
  left: 67.81%;
  position: absolute;
  top: 51.44%;
  width: 7.45%;
}

.student-lecture-notes-list-mobile .vector-6lEMsv {
  background-color: transparent;
  height: 34.33%;
  left: 2.6%;
  position: absolute;
  top: 22.69%;
  width: 58.52%;
}

.student-lecture-notes-list-mobile .vector-6u2TAk {
  background-color: transparent;
  height: 41.8%;
  left: 4.2%;
  position: absolute;
  top: 13.83%;
  width: 55.33%;
}

.student-lecture-notes-list-mobile .vector-77LDLf {
  background-color: transparent;
  height: 84.62%;
  left: 15.38%;
  position: absolute;
  top: 7.69%;
  width: 69.23%;
}

.student-lecture-notes-list-mobile .vector-9neAS5 {
  background-color: transparent;
  height: 14.93%;
  left: 17.28%;
  position: absolute;
  top: 40.61%;
  width: 11.7%;
}

.student-lecture-notes-list-mobile .vector-ADvjvZ {
  background-color: transparent;
  height: 29.85%;
  left: 37.87%;
  position: absolute;
  top: 2.72%;
  width: 34.05%;
}

.student-lecture-notes-list-mobile .vector-AEuo4j {
  background-color: transparent;
  height: 4.48%;
  left: 12.21%;
  position: absolute;
  top: 12.28%;
  width: 28.73%;
}

.student-lecture-notes-list-mobile .vector-AKNQwM {
  background-color: transparent;
  height: 11.94%;
  left: 24.31%;
  position: absolute;
  top: 18.13%;
  width: 14.9%;
}

.student-lecture-notes-list-mobile .vector-AUE2eU {
  background-color: transparent;
  height: 101.25%;
  left: -4.96%;
  position: absolute;
  top: 0.47%;
  width: 109.92%;
}

.student-lecture-notes-list-mobile .vector-B3X4ab {
  background-color: transparent;
  height: 29.85%;
  left: 2.08%;
  position: absolute;
  top: 8.12%;
  width: 45.75%;
}

.student-lecture-notes-list-mobile .vector-CKdTAP {
  background-color: transparent;
  height: 144.31%;
  left: -19.17%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}

.student-lecture-notes-list-mobile .vector-DHn8hq {
  background-color: transparent;
  height: 1.92%;
  left: 49.04%;
  position: absolute;
  top: 49.04%;
  width: 1.92%;
}

.student-lecture-notes-list-mobile .vector-DhmCtC {
  background-color: transparent;
  height: 13.43%;
  left: 63.32%;
  position: absolute;
  top: 41.24%;
  width: 25.53%;
}

.student-lecture-notes-list-mobile .vector-FBXFcM {
  background-color: transparent;
  height: 34.33%;
  left: 2.6%;
  position: absolute;
  top: 22.69%;
  width: 58.52%;
}

.student-lecture-notes-list-mobile .vector-FRe9kA {
  background-color: transparent;
  height: 121.98%;
  left: -7.1%;
  position: absolute;
  top: -10.99%;
  width: 114.19%;
}

.student-lecture-notes-list-mobile .rectangle-44-rFX9Nh .doubt-row {
  display: flex;
  margin: 4px;
  align-items: center;
  justify-content: space-evenly;
}

.student-lecture-notes-list-mobile .vector-FbIeqH {
  background-color: transparent;
  height: 8.96%;
  left: 25.41%;
  position: absolute;
  top: 36.57%;
  width: 6.38%;
}

.student-lecture-notes-list-mobile .vector-GULZOy {
  background-color: transparent;
  height: 121.96%;
  left: -8.82%;
  position: absolute;
  top: -10.98%;
  width: 117.65%;
}

.student-lecture-notes-list-mobile .vector-HDxFmN {
  background-color: transparent;
  height: 8.96%;
  left: 25.3%;
  position: absolute;
  top: 37.19%;
  width: 12.77%;
}

.student-lecture-notes-list-mobile .vector-IfQt5H {
  background-color: transparent;
  height: 49.26%;
  left: 29.5%;
  position: absolute;
  top: 51.43%;
  width: 48.94%;
}

.student-lecture-notes-list-mobile .vector-IpBwGI {
  background-color: transparent;
  height: 32.84%;
  left: 2.49%;
  position: absolute;
  top: 24.54%;
  width: 21.28%;
}

.student-lecture-notes-list-mobile .vector-LS3dNl {
  background-color: transparent;
  height: 8.96%;
  left: 25.41%;
  position: absolute;
  top: 36.57%;
  width: 6.38%;
}

.student-lecture-notes-list-mobile .vector-LpgxzN {
  background-color: transparent;
  height: 19.4%;
  left: 24.76%;
  position: absolute;
  top: 22.08%;
  width: 13.83%;
}

.student-lecture-notes-list-mobile .vector-N2Y59c {
  background-color: transparent;
  height: 82.1%;
  left: 7.01%;
  position: absolute;
  top: 2.36%;
  width: 93.63%;
}

.student-lecture-notes-list-mobile .vector-NF9mxS {
  background-color: transparent;
  height: 5.97%;
  left: 23.91%;
  position: absolute;
  top: 28.23%;
  width: 2.13%;
}

.student-lecture-notes-list-mobile .vector-O5Cxw9 {
  background-color: transparent;
  height: 13.43%;
  left: 63.32%;
  position: absolute;
  top: 41.24%;
  width: 25.53%;
}

.student-lecture-notes-list-mobile .vector-OWyIvf {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}

.student-lecture-notes-list-mobile .vector-Ob1Rqs {
  background-color: transparent;
  height: 52.24%;
  left: 49.49%;
  position: absolute;
  top: 8.1%;
  width: 45.75%;
}

.student-lecture-notes-list-mobile .vector-P1lxK3 {
  background-color: transparent;
  height: 5.97%;
  left: 23.91%;
  position: absolute;
  top: 28.23%;
  width: 2.13%;
}

.student-lecture-notes-list-mobile .vector-PC91KU {
  background-color: transparent;
  height: 144.3%;
  left: -6.65%;
  position: absolute;
  top: -22.15%;
  width: 113.29%;
}

.student-lecture-notes-list-mobile .vector-PD0S0h {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.36%;
}

.student-lecture-notes-list-mobile .vector-PFKICe {
  background-color: transparent;
  height: 11.94%;
  left: 23.98%;
  position: absolute;
  top: 18.13%;
  width: 8.51%;
}

.student-lecture-notes-list-mobile .vector-PtriGM {
  background-color: transparent;
  height: 11.94%;
  left: 23.77%;
  position: absolute;
  top: 18.13%;
  width: 15.96%;
}

.student-lecture-notes-list-mobile .vector-QDyA6g {
  background-color: transparent;
  height: 110.03%;
  left: -6.59%;
  position: absolute;
  top: -5.02%;
  width: 113.19%;
}

.student-lecture-notes-list-mobile .vector-Qv0mZ4 {
  background-color: transparent;
  height: 29.85%;
  left: 2.08%;
  position: absolute;
  top: 8.12%;
  width: 45.75%;
}

.student-lecture-notes-list-mobile .vector-Qv8Xbp {
  background-color: transparent;
  height: 144.31%;
  left: -19.17%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}

.student-lecture-notes-list-mobile .vector-RYpCgT {
  background-color: transparent;
  height: 121.98%;
  left: -6.78%;
  position: absolute;
  top: -10.99%;
  width: 113.56%;
}

.student-lecture-notes-list-mobile .vector-S9AR9M {
  background-color: transparent;
  height: 70.16%;
  left: -0.55%;
  position: absolute;
  top: 8.33%;
  width: 95.76%;
}

.student-lecture-notes-list-mobile .vector-SctNoZ {
  background-color: transparent;
  height: 1.92%;
  left: 49.04%;
  position: absolute;
  top: 49.04%;
  width: 1.92%;
}

.student-lecture-notes-list-mobile .vector-T0btkN {
  background-color: transparent;
  height: 11.94%;
  left: 23.98%;
  position: absolute;
  top: 18.13%;
  width: 8.51%;
}

.student-lecture-notes-list-mobile .vector-T7EbAp {
  background-color: transparent;
  height: 13.43%;
  left: 63.28%;
  position: absolute;
  top: 41.24%;
  width: 19.15%;
}

.student-lecture-notes-list-mobile .vector-TdE1pD {
  background-color: transparent;
  height: 49.26%;
  left: 28.97%;
  position: absolute;
  top: 51.43%;
  width: 50.01%;
}

.student-lecture-notes-list-mobile .vector-U0LxYw {
  background-color: transparent;
  height: 4.05%;
  left: 71.01%;
  position: absolute;
  top: -1.45%;
  width: 13.74%;
}

.student-lecture-notes-list-mobile .vector-UUUxJF {
  background-color: transparent;
  height: 121.96%;
  left: -8.82%;
  position: absolute;
  top: -10.98%;
  width: 117.65%;
}

.student-lecture-notes-list-mobile .vector-VOGBVy {
  background-color: transparent;
  height: 41.8%;
  left: 3.8%;
  position: absolute;
  top: 13.83%;
  width: 20.22%;
}

.student-lecture-notes-list-mobile .vector-VRMQru {
  background-color: transparent;
  height: 82.1%;
  left: 7.01%;
  position: absolute;
  top: 2.36%;
  width: 93.63%;
}

.student-lecture-notes-list-mobile .vector-Vr5tiq {
  background-color: transparent;
  height: 29.85%;
  left: 37.64%;
  position: absolute;
  top: 2.71%;
  width: 22.34%;
}

.student-lecture-notes-list-mobile .vector-WCljiC {
  background-color: transparent;
  height: 19.4%;
  left: 24.76%;
  position: absolute;
  top: 22.08%;
  width: 13.83%;
}

.student-lecture-notes-list-mobile .vector-WfnLBo {
  background-color: transparent;
  height: 82.1%;
  left: 7.01%;
  position: absolute;
  top: 2.36%;
  width: 93.63%;
}

.student-lecture-notes-list-mobile .vector-WqgE0F {
  background-color: transparent;
  height: 19.4%;
  left: 29.04%;
  position: absolute;
  top: 81.41%;
  width: 29.79%;
}

.student-lecture-notes-list-mobile .vector-Y59V0s {
  background-color: transparent;
  height: 4.05%;
  left: 71.01%;
  position: absolute;
  top: -1.45%;
  width: 13.74%;
}

.student-lecture-notes-list-mobile .vector-Y9TIUK {
  background-color: transparent;
  height: 121.99%;
  left: -21.65%;
  position: absolute;
  top: -10.99%;
  width: 143.3%;
}

.student-lecture-notes-list-mobile .vector-YM4yxX {
  background-color: transparent;
  height: 5.97%;
  left: 36.78%;
  position: absolute;
  top: 28.23%;
  width: 3.19%;
}

.student-lecture-notes-list-mobile .vector-Zh51an {
  background-color: transparent;
  height: 144.3%;
  left: -6.65%;
  position: absolute;
  top: -22.15%;
  width: 113.29%;
}

.student-lecture-notes-list-mobile .vector-aXV5cr {
  background-color: transparent;
  height: 5.97%;
  left: 23.91%;
  position: absolute;
  top: 28.23%;
  width: 2.13%;
}

.student-lecture-notes-list-mobile .vector-bKRlnb {
  background-color: transparent;
  height: 110.03%;
  left: -6.59%;
  position: absolute;
  top: -5.02%;
  width: 113.19%;
}

.student-lecture-notes-list-mobile .vector-bnkfUA {
  background-color: transparent;
  height: 4.48%;
  left: 12.23%;
  position: absolute;
  top: 12.28%;
  width: 8.51%;
}

.student-lecture-notes-list-mobile .vector-bof6oJ {
  background-color: transparent;
  height: 29.85%;
  left: 1.84%;
  position: absolute;
  top: 8.24%;
  width: 19.15%;
}

.student-lecture-notes-list-mobile .vector-c0ymqA {
  background-color: transparent;
  height: 41.8%;
  left: 3.8%;
  position: absolute;
  top: 13.83%;
  width: 20.22%;
}

.student-lecture-notes-list-mobile .vector-c9XjZ6 {
  background-color: transparent;
  height: 13.43%;
  left: 63.32%;
  position: absolute;
  top: 41.24%;
  width: 25.53%;
}

.student-lecture-notes-list-mobile .vector-cJDtwl {
  background-color: transparent;
  height: 5.97%;
  left: 50.75%;
  position: absolute;
  top: 63.23%;
  width: 7.45%;
}

.student-lecture-notes-list-mobile .vector-cNJjv2 {
  background-color: transparent;
  height: 32.84%;
  left: 2.49%;
  position: absolute;
  top: 24.54%;
  width: 21.28%;
}

.student-lecture-notes-list-mobile .vector-d2duO5 {
  background-color: transparent;
  height: 4.05%;
  left: 71.01%;
  position: absolute;
  top: -1.45%;
  width: 13.74%;
}

.student-lecture-notes-list-mobile .vector-dG0Ldx {
  background-color: transparent;
  height: 5.97%;
  left: 50.75%;
  position: absolute;
  top: 63.23%;
  width: 7.45%;
}

.student-lecture-notes-list-mobile .vector-dS2uCE {
  background-color: transparent;
  height: 8.96%;
  left: 25.3%;
  position: absolute;
  top: 37.19%;
  width: 12.77%;
}

.student-lecture-notes-list-mobile .vector-dS4UXQ {
  background-color: transparent;
  height: 49.26%;
  left: 29.5%;
  position: absolute;
  top: 51.43%;
  width: 48.94%;
}

.student-lecture-notes-list-mobile .vector-dV81ro {
  background-color: transparent;
  height: 70.16%;
  left: -0.67%;
  position: absolute;
  top: 8.45%;
  width: 24.47%;
}

.student-lecture-notes-list-mobile .vector-dcaDkc {
  background-color: transparent;
  height: 14.93%;
  left: 17.32%;
  position: absolute;
  top: 40.61%;
  width: 28.73%;
}

.student-lecture-notes-list-mobile .vector-e2yeN1 {
  background-color: transparent;
  height: 29.85%;
  left: 37.87%;
  position: absolute;
  top: 2.72%;
  width: 34.05%;
}

.student-lecture-notes-list-mobile .vector-gvV2Rx {
  background-color: transparent;
  height: 70.16%;
  left: -0.67%;
  position: absolute;
  top: 8.45%;
  width: 24.47%;
}

.student-lecture-notes-list-mobile .vector-halcXV {
  background-color: transparent;
  height: 144.31%;
  left: -19.17%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}

.student-lecture-notes-list-mobile .vector-hhAbtl {
  background-color: transparent;
  height: 14.93%;
  left: 17.28%;
  position: absolute;
  top: 40.61%;
  width: 11.7%;
}

.student-lecture-notes-list-mobile .vector-i64Q95 {
  background-color: transparent;
  height: 19.4%;
  left: 29.04%;
  position: absolute;
  top: 81.41%;
  width: 29.79%;
}

.student-lecture-notes-list-mobile .vector-jLf4aV {
  background-color: transparent;
  height: 7.46%;
  left: 67.81%;
  position: absolute;
  top: 51.44%;
  width: 7.45%;
}

.student-lecture-notes-list-mobile .vector-k7b1Mi {
  background-color: transparent;
  height: 70.16%;
  left: -0.55%;
  position: absolute;
  top: 8.33%;
  width: 95.76%;
}

.student-lecture-notes-list-mobile .vector-kwqROy {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}

.student-lecture-notes-list-mobile .vector-lFxDi9 {
  background-color: transparent;
  height: 19.4%;
  left: 29.04%;
  position: absolute;
  top: 81.41%;
  width: 29.79%;
}

.student-lecture-notes-list-mobile .vector-lj5Xre {
  background-color: transparent;
  height: 121.96%;
  left: -8.82%;
  position: absolute;
  top: -10.98%;
  width: 117.65%;
}

.student-lecture-notes-list-mobile .vector-lkVzGd {
  background-color: transparent;
  height: 4.48%;
  left: 12.23%;
  position: absolute;
  top: 12.28%;
  width: 8.51%;
}

.student-lecture-notes-list-mobile .vector-lw8NAW {
  background-color: transparent;
  height: 29.85%;
  left: 1.84%;
  position: absolute;
  top: 8.24%;
  width: 19.15%;
}

.student-lecture-notes-list-mobile .vector-mGXgmO {
  background-color: transparent;
  height: 4.48%;
  left: 12.23%;
  position: absolute;
  top: 12.28%;
  width: 8.51%;
}

.student-lecture-notes-list-mobile .vector-mokeKx {
  background-color: transparent;
  height: 14.93%;
  left: 17.32%;
  position: absolute;
  top: 40.61%;
  width: 28.73%;
}

.student-lecture-notes-list-mobile .vector-ms1pvS {
  background-color: transparent;
  height: 41.8%;
  left: 4.2%;
  position: absolute;
  top: 13.83%;
  width: 55.33%;
}

.student-lecture-notes-list-mobile .vector-nAPXU6 {
  background-color: transparent;
  height: 84.62%;
  left: 15.38%;
  position: absolute;
  top: 7.69%;
  width: 69.23%;
}

.student-lecture-notes-list-mobile .vector-nD2hpN {
  background-color: transparent;
  height: 101.5%;
  left: 11.25%;
  position: absolute;
  top: -1.01%;
  width: 85.12%;
}

.student-lecture-notes-list-mobile .vector-ngma9X {
  background-color: transparent;
  height: 101.25%;
  left: -4.96%;
  position: absolute;
  top: 0.47%;
  width: 109.92%;
}

.student-lecture-notes-list-mobile .vector-nzNou6 {
  background-color: transparent;
  height: 101.5%;
  left: 11.25%;
  position: absolute;
  top: -1.01%;
  width: 85.12%;
}

.student-lecture-notes-list-mobile .vector-nzuIpS {
  background-color: transparent;
  height: 5.97%;
  left: 36.78%;
  position: absolute;
  top: 28.23%;
  width: 3.19%;
}

.student-lecture-notes-list-mobile .vector-oafYiW {
  background-color: transparent;
  height: 121.98%;
  left: -6.78%;
  position: absolute;
  top: -10.99%;
  width: 113.56%;
}

.student-lecture-notes-list-mobile .vector-oez9Pb {
  background-color: transparent;
  height: 8.96%;
  left: 25.3%;
  position: absolute;
  top: 37.19%;
  width: 12.77%;
}

.student-lecture-notes-list-mobile .vector-pxIogv {
  background-color: transparent;
  height: 121.98%;
  left: -6.78%;
  position: absolute;
  top: -10.99%;
  width: 113.56%;
}

.student-lecture-notes-list-mobile .vector-qGye3v {
  background-color: transparent;
  height: 70.16%;
  left: -0.55%;
  position: absolute;
  top: 8.33%;
  width: 95.76%;
}

.student-lecture-notes-list-mobile .vector-qpftC5 {
  background-color: transparent;
  height: 14.93%;
  left: 17.28%;
  position: absolute;
  top: 40.61%;
  width: 11.7%;
}

.student-lecture-notes-list-mobile .vector-rGRikn {
  background-color: transparent;
  height: 101.25%;
  left: -4.96%;
  position: absolute;
  top: 0.47%;
  width: 109.92%;
}

.student-lecture-notes-list-mobile .vector-rXxPBk {
  background-color: transparent;
  height: 11.94%;
  left: 24.31%;
  position: absolute;
  top: 18.13%;
  width: 14.9%;
}

.student-lecture-notes-list-mobile .vector-reGMLO {
  background-color: transparent;
  height: 120.65%;
  left: -21.65%;
  position: absolute;
  top: -10.33%;
  width: 143.31%;
}

.student-lecture-notes-list-mobile .vector-sku7Fc {
  background-color: transparent;
  height: 13.43%;
  left: 63.81%;
  position: absolute;
  top: 41.24%;
  width: 18.09%;
}

.student-lecture-notes-list-mobile .vector-tQwNUN {
  background-color: transparent;
  height: 14.93%;
  left: 17.32%;
  position: absolute;
  top: 40.61%;
  width: 28.73%;
}

.student-lecture-notes-list-mobile .vector-toBf7r {
  background-color: transparent;
  height: 41.8%;
  left: 4.2%;
  position: absolute;
  top: 13.83%;
  width: 55.33%;
}

.student-lecture-notes-list-mobile .vector-tprfVp {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.36%;
}

.student-lecture-notes-list-mobile .vector-uVo48g {
  background-color: transparent;
  height: 1.92%;
  left: 49.04%;
  position: absolute;
  top: 49.04%;
  width: 1.92%;
}

.student-lecture-notes-list-mobile .vector-utKR0x {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.36%;
}

.student-lecture-notes-list-mobile .vector-vPuJH9 {
  background-color: transparent;
  height: 32.84%;
  left: 2.49%;
  position: absolute;
  top: 24.54%;
  width: 21.28%;
}

.student-lecture-notes-list-mobile .vector-x2R7zB {
  background-color: transparent;
  height: 70.16%;
  left: -0.67%;
  position: absolute;
  top: 8.45%;
  width: 24.47%;
}

.student-lecture-notes-list-mobile .vector-x5Jnp7 {
  background-color: transparent;
  height: 121.99%;
  left: -21.65%;
  position: absolute;
  top: -10.99%;
  width: 143.3%;
}

.student-lecture-notes-list-mobile .vector-xB1UPi {
  background-color: transparent;
  height: 52.24%;
  left: 49.49%;
  position: absolute;
  top: 8.1%;
  width: 45.75%;
}

.student-lecture-notes-list-mobile .vector-xJcTLo {
  background-color: transparent;
  height: 121.98%;
  left: -7.1%;
  position: absolute;
  top: -10.99%;
  width: 114.19%;
}

.student-lecture-notes-list-mobile .vector-xMrRfb {
  background-color: transparent;
  height: 4.48%;
  left: 12.21%;
  position: absolute;
  top: 12.28%;
  width: 28.73%;
}

.student-lecture-notes-list-mobile .vector-xfooms {
  background-color: transparent;
  height: 84.62%;
  left: 15.38%;
  position: absolute;
  top: 7.69%;
  width: 69.23%;
}

.student-lecture-notes-list-mobile .vector-xhepvl {
  background-color: transparent;
  height: 5.97%;
  left: 36.78%;
  position: absolute;
  top: 28.23%;
  width: 3.19%;
}

.student-lecture-notes-list-mobile .vector-xhwx9b {
  background-color: transparent;
  height: 7.46%;
  left: 67.81%;
  position: absolute;
  top: 51.44%;
  width: 7.45%;
}

.student-lecture-notes-list-mobile .vector-xtOtSY {
  background-color: transparent;
  height: 41.8%;
  left: 3.8%;
  position: absolute;
  top: 13.83%;
  width: 20.22%;
}

.student-lecture-notes-list-mobile .vector-xvjzYd {
  background-color: transparent;
  height: 4.48%;
  left: 12.21%;
  position: absolute;
  top: 12.28%;
  width: 28.73%;
}

.student-lecture-notes-list-mobile .vector-ymbxcA {
  background-color: transparent;
  height: 29.85%;
  left: 37.64%;
  position: absolute;
  top: 2.71%;
  width: 22.34%;
}

.student-lecture-notes-list-mobile .vector-zXuwwt {
  background-color: transparent;
  height: 34.33%;
  left: 2.6%;
  position: absolute;
  top: 22.69%;
  width: 58.52%;
}

.student-lecture-notes-list-mobile .vector-zeZVsH {
  background-color: transparent;
  height: 8.96%;
  left: 24.88%;
  position: absolute;
  top: 36.57%;
  width: 7.45%;
}

.student-lecture-notes-list-mobile .vector-zxfIi7 {
  background-color: transparent;
  height: 29.85%;
  left: 1.84%;
  position: absolute;
  top: 8.24%;
  width: 19.15%;
}

.student-lecture-notes-list-mobile .view-AwXtS8 {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;

  width: auto;
}

.student-lecture-notes-list-mobile .view-GZmYSZ {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  right: 114px;
  text-align: left;
  top: 211px;
  width: auto;
}

.student-lecture-notes-list-mobile .view-Z1JSTw {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  right: 114px;
  text-align: left;
  top: 211px;
  width: auto;
}

.student-lecture-notes-list-mobile .view-all-rFX9Nh {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 303px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: 77px;
}

.student-lecture-notes-list-mobile .view-forum-CN8bZ4 {
  background-color: transparent;
  height: auto;
  left: 257px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 235px;
  width: 115px;
}

.student-lecture-notes-list-mobile .view-forum-CNpLuU {
  background-color: transparent;
  height: auto;
  left: 257px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 169px;
  width: 115px;
}

.student-lecture-notes-list-mobile .view-forum-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 257px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 103px;
  width: 115px;
}

.student-lecture-notes-list-mobile .what-is-the-si-unit-of-current-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 70px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 74px;
  width: 296px;
}

.student-lecture-notes-list-mobile .what-is-the-sol-f-this-question-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 70px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 140px;
  width: 296px;
}

:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --white: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 599px) {
  .student-lecture-note-list {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .student-lecture-notes-list-mobile {
    display: none;
  }
}
