.main {
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  line-height: 1.3;
  box-sizing: border-box;
  background-color: #fdfdfd;
}

.main .infoBox {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding-top: 20px;
}
.main h1 {
  font-size: 24px;
  font-weight: 700;
  color: #dc3545;
  line-height: 1.5;
  text-align: center;
}
.main h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  color: #1d74ca;
}
.main p {
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
  margin-bottom: 0;
}
.pSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.main li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  margin-left: 10px;
  list-style: disc;
}

.bold {
  font-weight: 600;
}
.main h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  color: black;
}
.sections {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
}

.innerSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
}

.book_btn {
  background-color: #dc3545;
  color: #fff;
  font-weight: 600;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
}

.book_btn:hover {
  background-color: #c42f3e;
}

/* table styles */
.tableDiv {
  padding: 16px;
  box-sizing: border-box;
  width: 20%;
  border: 1px solid #dc3545;
}

.verticalTable th,
td {
  border: 3px solid #dc3545;
  border-collapse: collapse;
  padding: 16px;
}
.verticalTable table {
  width: 100%;
  background-color: #f5f5f5;
}
.verticalTable th {
  width: 30%;
  font-weight: 600;
}
.verticalTable td {
  width: 70%;
}

.normalTable {
  width: 100%;
  background-color: #f5f5f5;
}
.normalTable th,
td {
  border: 3px solid #dc3545;
  border-collapse: collapse;
  padding: 16px;
}

.normalTable th {
  font-weight: 600;
}

table {
  background-color: #f5f5f5;
}

@media screen and (max-width: 768px) {
  .main {
    padding: 28px 16px;
    gap: 24px;
  }
  .main .infoBox {
    display: flex;
    flex-direction: column;
    gap: 34px;
  }
  .main h1 {
    font-size: 20px;
  }
  .main h2 {
    font-size: 18px;
  }
  .main h3 {
    font-size: 16px;
  }
  .main p {
    font-size: 15px;
  }
  .main li {
    font-size: 15px;
  }
  .book_btn {
    width: 100%;
    padding: 10px;
  }
  .tableDiv {
    overflow: auto;
    padding: 12px;
    width: 33.33%;
  }
  .btnDiv {
    width: 100%;
  }
  .verticalTable th,
  td {
    border: 3px solid #dc3545;
    border-collapse: collapse;
    padding: 12px;
  }
  .normalTable th,
  td {
    border: 3px solid #dc3545;
    border-collapse: collapse;
    padding: 12px;
  }
}
