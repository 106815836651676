body {
  font-family: "Montserrat", sans-serif;
}


.heading_box {
  margin: 50px auto;
  width: 95%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  background-color: #ec3545;
  border-radius: 8px;
}

.heading_box p {
  font-size: 30px;
  font-weight: 500;
  color: white;
}

.drop-container {
  /* margin: 70px 0; */
  display: flex;
  /* width: 100%; */
}

.drop-button {
  width: 100%;
}

.dropdownn {
  width: 80%;
}

.material-list-css {
  text-align: center;
}

@media screen and (max-width: 720px) {
  .drop-container {
    flex-direction: column;
  }

  .drop-button {
    width: 100%;
    margin: 10px auto;
    font-size: 18px !important;
  }

  .dropdownn {
    margin: auto;
  }
}
