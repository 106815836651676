/* screen - student-calendar-time-mobile */

.montserrat-medium-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-24px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-64px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-gray-20px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.student-calendar-time-mobile {
  background-color: rgba(253, 254, 255, 1);
  height: 100vh;
  margin: 0px;
  min-height: 1550px;
  min-width: 414px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.student-calendar-time-mobile .add-JoE0OI {
  background-color: transparent;
  height: auto;
  left: 326px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 38px;
}
.student-calendar-time-mobile .add-OUIM6H {
  background-color: transparent;
  height: auto;
  left: 333px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 35px;
}
.student-calendar-time-mobile .add-delete-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 296px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 90px;
}
.student-calendar-time-mobile .add-mdOnc7 {
  background-color: transparent;
  height: auto;
  left: 326px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 38px;
}
.student-calendar-time-mobile .arrowback-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 10px;
  overflow: hidden;
  position: absolute;
  top: 574px;
  width: 24px;
}
.student-calendar-time-mobile .choose-time-for-booking-C61RwL {
  background-color: transparent;
  color: #dc3545;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 531px;
  width: 338px;
}
.student-calendar-time-mobile .class-name-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 90px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 96px;
}
.student-calendar-time-mobile .completed-Ajm6uA {
  background-color: transparent;
  height: auto;
  /* left: 206px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 70px; */
  width: 81px;
}
.student-calendar-time-mobile .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 45px;
  left: 26px;
  position: absolute;
  top: 24px;
  width: 87px;
}
.student-calendar-time-mobile .component-4-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 157px;
  left: 26px;
  position: absolute;
  top: 346px;
  width: 158px;
}
.student-calendar-time-mobile .component-5-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 157px;
  left: 230px;
  position: absolute;
  top: 346px;
  width: 158px;
}
.student-calendar-time-mobile .component-6-C61RwL {
  background-color: transparent;
  height: 18px;
  left: 362px;
  position: absolute;
  top: 37px;
  width: 23px;
}
.student-calendar-time-mobile .component-7-D1n0rW {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 171px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 304px;
}
.student-calendar-time-mobile .course-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 132px;
  width: auto;
}
.student-calendar-time-mobile .delete-bC1JcG {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 320px;
  letter-spacing: 0px;
  line-height: 30px;
  position: absolute;
  text-align: left;
  top: 8px;
  white-space: nowrap;
  width: 57px;
}
.student-calendar-time-mobile .doubt-forum-rFX9Nh {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 25px;
  width: 131px;
}
.student-calendar-time-mobile .feb-18-2021-C61RwL {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 272px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 574px;
  width: auto;
}
.student-calendar-time-mobile .go-back-C61RwL {
  background-color: transparent;
  height: auto;
  left: 35px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 574px;
  width: 89px;
}
.student-calendar-time-mobile .grade-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 95px;
  width: auto;
}
.student-calendar-time-mobile .group-112-C61RwL {
  background-color: transparent;
  height: 26.52%;
  left: 2.17%;
  position: absolute;
  top: 39.42%;
  width: 99.28%;
}
.student-calendar-time-mobile .group-1pzCME {
  background-color: transparent;
  background-image: url(./img/vector-83@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-calendar-time-mobile .group-3HWdqU {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-calendar-time-mobile .group-3eHi1a {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-calendar-time-mobile .group-3xCoUY {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-calendar-time-mobile .group-4Tztbv {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-calendar-time-mobile .group-5ZFw7U {
  background-color: transparent;
  background-image: url(./img/vector-38@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-calendar-time-mobile .group-6dsxAM {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-calendar-time-mobile .group-6u2TAk {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-calendar-time-mobile .group-85-C61RwL {
  background-color: transparent;
  height: 270px;
  left: 26px;
  position: absolute;
  top: 1050px;
  width: 385px;
}
.student-calendar-time-mobile .group-86-C61RwL {
  background-color: transparent;
  height: 165px;
  left: 29px;
  position: absolute;
  top: 1350px;
  width: 363px;
  margin-bottom: 10px;
}
.student-calendar-time-mobile .group-87-C61RwL {
  background-color: transparent;
  height: 171px;
  left: 56px;
  position: absolute;
  top: 147px;
  width: 312px;
}
.student-calendar-time-mobile .group-90-Ajm6uA {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 174px;
  width: 380px;
}
.student-calendar-time-mobile .group-91-Ajm6uA {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 384px;
}
.student-calendar-time-mobile .group-92-Ajm6uA {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 380px;
}
.student-calendar-time-mobile .group-93-Ajm6uA {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 351px;
  width: 380px;
}
.student-calendar-time-mobile .group-9neAS5 {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-calendar-time-mobile .group-B3X4ab {
  background-color: transparent;
  background-image: url(./img/vector-38@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-calendar-time-mobile .group-BpxbEL {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-calendar-time-mobile .group-DhmCtC {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}
.student-calendar-time-mobile .group-FASyuA {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-calendar-time-mobile .group-HTx4Jc {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-calendar-time-mobile .group-IfQt5H {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-calendar-time-mobile .group-N2Y59c {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-calendar-time-mobile .group-O5Cxw9 {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}
.student-calendar-time-mobile .group-Ob1Rqs {
  background-color: transparent;
  background-image: url(./img/vector-37@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-calendar-time-mobile .group-Qv0mZ4 {
  background-color: transparent;
  background-image: url(./img/vector-38@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-calendar-time-mobile .group-S9AR9M {
  background-color: transparent;
  background-image: url(./img/vector-35@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-calendar-time-mobile .group-T7EbAp {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-calendar-time-mobile .group-TdE1pD {
  background-color: transparent;
  background-image: url(./img/vector-46@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-calendar-time-mobile .group-TtUkiV {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-calendar-time-mobile .group-VOGBVy {
  background-color: transparent;
  background-image: url(./img/vector-43@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-calendar-time-mobile .group-VRMQru {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-calendar-time-mobile .group-WfnLBo {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-calendar-time-mobile .group-bX4Dvh {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-calendar-time-mobile .group-bcFrzZ {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-calendar-time-mobile .group-bof6oJ {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-calendar-time-mobile .group-c0ymqA {
  background-color: transparent;
  background-image: url(./img/vector-43@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-calendar-time-mobile .group-c9XjZ6 {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}
.student-calendar-time-mobile .group-dS4UXQ {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-calendar-time-mobile .group-dV81ro {
  background-color: transparent;
  background-image: url(./img/vector-82@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-calendar-time-mobile .group-dcaDkc {
  background-color: transparent;
  background-image: url(./img/vector-44@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-calendar-time-mobile .group-gn7VaX {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-calendar-time-mobile .group-gvV2Rx {
  background-color: transparent;
  background-image: url(./img/vector-82@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-calendar-time-mobile .group-hhAbtl {
  background-color: transparent;
  background-image: url(./img/vector-45@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-calendar-time-mobile .group-iieIco {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-calendar-time-mobile .group-jLf4aV {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-calendar-time-mobile .group-k7b1Mi {
  background-color: transparent;
  background-image: url(./img/vector-35@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-calendar-time-mobile .group-lw8NAW {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-calendar-time-mobile .group-mokeKx {
  background-color: transparent;
  background-image: url(./img/vector-44@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-calendar-time-mobile .group-ms1pvS {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-calendar-time-mobile .group-nD2hpN {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-calendar-time-mobile .group-nzNou6 {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-calendar-time-mobile .group-qGye3v {
  background-color: transparent;
  background-image: url(./img/vector-35@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-calendar-time-mobile .group-qpftC5 {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-calendar-time-mobile .group-sku7Fc {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-calendar-time-mobile .group-tQwNUN {
  background-color: transparent;
  background-image: url(./img/vector-44@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-calendar-time-mobile .group-toBf7r {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-calendar-time-mobile .group-wQSXdG {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-calendar-time-mobile .group-x2R7zB {
  background-color: transparent;
  background-image: url(./img/vector-36@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-calendar-time-mobile .group-xB1UPi {
  background-color: transparent;
  background-image: url(./img/vector-83@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-calendar-time-mobile .group-xS7o0X {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-calendar-time-mobile .group-xhwx9b {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-calendar-time-mobile .group-xtOtSY {
  background-color: transparent;
  background-image: url(./img/vector-43@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-calendar-time-mobile .group-zxfIi7 {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-calendar-time-mobile .how-to-solve-this-rFX9Nh {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 214px;
  width: 289px;
}
.student-calendar-time-mobile .join-7-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 11px;
  overflow: hidden;
  position: absolute;
  top: 68px;
  width: 46px;
}
.student-calendar-time-mobile .join-8-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 9px;
  overflow: hidden;
  position: absolute;
  top: 139px;
  width: 46px;
}
.student-calendar-time-mobile .join-9-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 9px;
  overflow: hidden;
  position: absolute;
  top: 210px;
  width: 46px;
}
.student-calendar-time-mobile .line-5-9p2GBv {
  background-color: transparent;
  height: 101.11%;
  left: 0.55%;
  position: absolute;
  top: -0.56%;
  width: 100%;
}
.student-calendar-time-mobile .line-6-9p2GBv {
  background-color: transparent;
  height: 102.22%;
  left: -0.29%;
  position: absolute;
  top: -1.11%;
  width: 100.57%;
}
.student-calendar-time-mobile .organic-4-Ajm6uA {
  background-color: transparent;
  height: auto;
  /* left: 100px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 70px; */
  width: 69px;
}
.student-calendar-time-mobile .organic-5-bC1JcG {
  background-color: transparent;
  height: auto;
  left: 91px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.student-calendar-time-mobile .pending-assignments-cPi90N {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 2px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0px;
  width: auto;
}
.student-calendar-time-mobile .rectangle-31-Ajm6uA-00s {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 96.11%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 52px;
}
.student-calendar-time-mobile .rectangle-40-Ajm6uA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  /* left: 10px;
  position: absolute;
  top: 57px; */
  width: 376px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-top: 24px;
}
.student-calendar-time-mobile .rectangle-41-Ajm6uA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 376px;
}
.student-calendar-time-mobile .rectangle-44-JoE0OI {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.student-calendar-time-mobile .rectangle-44-cPi90N {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 49px;
  left: 0px;
  position: absolute;
  top: 44px;
  width: 359px;
}
.student-calendar-time-mobile .rectangle-44-rFX9Nh {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 270px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 358px;
}
.student-calendar-time-mobile .rectangle-47-Ajm6uA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 376px;
}
.student-calendar-time-mobile .rectangle-48-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.student-calendar-time-mobile .rectangle-48-bC1JcG {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.student-calendar-time-mobile .rectangle-49-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 38.89%;
  width: 100%;
}
.student-calendar-time-mobile .rectangle-49-OUIM6H {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.student-calendar-time-mobile .rectangle-50-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 77.78%;
  width: 100%;
}
.student-calendar-time-mobile .rectangle-50-mdOnc7 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.student-calendar-time-mobile .rectangle-52-cPi90N {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 49px;
  left: 0px;
  position: absolute;
  top: 116px;
  width: 359px;
}
.student-calendar-time-mobile .sections-teachi-g-i631221653102-p4wM3n {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: 30.57%;
  left: 25.92%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 12.89%;
  width: 49.37%;
}
.student-calendar-time-mobile .sections-teachi-g-i631221753102-9wWKr1 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: 30.57%;
  left: 11.99%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 12.89%;
  width: 77.22%;
}
.student-calendar-time-mobile .status-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 214px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 54px;
}
.student-calendar-time-mobile .subject-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 58px;
  width: auto;
}
.student-calendar-time-mobile .teacher-name-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: auto;
}
.student-calendar-time-mobile .time-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 18px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 46px;
}
.student-calendar-time-mobile .upcoming-bC1JcG {
  background-color: transparent;
  height: auto;
  left: 197px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 78px;
}
.student-calendar-time-mobile .vector-0IPvau {
  background-color: transparent;
  height: 29.85%;
  left: 37.46%;
  position: absolute;
  top: 2.72%;
  width: 34.88%;
}
.student-calendar-time-mobile .vector-1Nxj6x {
  background-color: transparent;
  height: 121.98%;
  left: -8.49%;
  position: absolute;
  top: -10.99%;
  width: 116.98%;
}
.student-calendar-time-mobile .vector-1TafeU {
  background-color: transparent;
  height: 121.99%;
  left: -5.05%;
  position: absolute;
  top: -10.99%;
  width: 110.09%;
}
.student-calendar-time-mobile .vector-1pzCME {
  background-color: transparent;
  height: 52.24%;
  left: 49.48%;
  position: absolute;
  top: 8.1%;
  width: 45.78%;
}
.student-calendar-time-mobile .vector-1xHKrh {
  background-color: transparent;
  height: 11.94%;
  left: 23.87%;
  position: absolute;
  top: 18.13%;
  width: 8.72%;
}
.student-calendar-time-mobile .vector-2rF7C5 {
  background-color: transparent;
  height: 110.03%;
  left: -7.97%;
  position: absolute;
  top: -5.02%;
  width: 115.95%;
}
.student-calendar-time-mobile .vector-3HWdqU {
  background-color: transparent;
  height: 101.5%;
  left: 11.3%;
  position: absolute;
  top: -1.01%;
  width: 85.01%;
}
.student-calendar-time-mobile .vector-3xCoUY {
  background-color: transparent;
  height: 13.43%;
  left: 63.59%;
  position: absolute;
  top: 41.24%;
  width: 18.53%;
}
.student-calendar-time-mobile .vector-5SSNkN {
  background-color: transparent;
  height: 29.85%;
  left: 37.37%;
  position: absolute;
  top: 2.71%;
  width: 22.89%;
}
.student-calendar-time-mobile .vector-5YyGRW {
  background-color: transparent;
  height: 19.4%;
  left: 25.14%;
  position: absolute;
  top: 22.08%;
  width: 13.08%;
}
.student-calendar-time-mobile .vector-5ZFw7U {
  background-color: transparent;
  height: 29.85%;
  left: 2.07%;
  position: absolute;
  top: 8.12%;
  width: 45.78%;
}
.student-calendar-time-mobile .vector-5ZxPCv {
  background-color: transparent;
  height: 144.31%;
  left: -20.86%;
  position: absolute;
  top: -22.16%;
  width: 141.73%;
}
.student-calendar-time-mobile .vector-6Pj9gj {
  background-color: transparent;
  height: 5.97%;
  left: 51.2%;
  position: absolute;
  top: 63.23%;
  width: 6.54%;
}
.student-calendar-time-mobile .vector-6dsxAM {
  background-color: transparent;
  height: 7.46%;
  left: 67.71%;
  position: absolute;
  top: 51.44%;
  width: 7.63%;
}
.student-calendar-time-mobile .vector-6lEMsv {
  background-color: transparent;
  height: 34.33%;
  left: 2.43%;
  position: absolute;
  top: 22.69%;
  width: 58.85%;
}
.student-calendar-time-mobile .vector-6u2TAk {
  background-color: transparent;
  height: 41.8%;
  left: 4.07%;
  position: absolute;
  top: 13.83%;
  width: 55.59%;
}
.student-calendar-time-mobile .vector-9neAS5 {
  background-color: transparent;
  height: 14.93%;
  left: 17.13%;
  position: absolute;
  top: 40.61%;
  width: 11.99%;
}
.student-calendar-time-mobile .vector-ADvjvZ {
  background-color: transparent;
  height: 29.85%;
  left: 37.46%;
  position: absolute;
  top: 2.72%;
  width: 34.88%;
}
.student-calendar-time-mobile .vector-AEuo4j {
  background-color: transparent;
  height: 4.48%;
  left: 11.86%;
  position: absolute;
  top: 12.28%;
  width: 29.43%;
}
.student-calendar-time-mobile .vector-AKNQwM {
  background-color: transparent;
  height: 11.94%;
  left: 24.12%;
  position: absolute;
  top: 18.13%;
  width: 15.26%;
}
.student-calendar-time-mobile .vector-AUE2eU {
  background-color: transparent;
  height: 101.25%;
  left: -6.3%;
  position: absolute;
  top: 0.47%;
  width: 112.6%;
}
.student-calendar-time-mobile .vector-B3X4ab {
  background-color: transparent;
  height: 29.85%;
  left: 2.07%;
  position: absolute;
  top: 8.12%;
  width: 45.78%;
}
.student-calendar-time-mobile .vector-DhmCtC {
  background-color: transparent;
  height: 13.43%;
  left: 63.56%;
  position: absolute;
  top: 41.24%;
  width: 25.07%;
}
.student-calendar-time-mobile .vector-FBXFcM {
  background-color: transparent;
  height: 34.33%;
  left: 2.43%;
  position: absolute;
  top: 22.69%;
  width: 58.85%;
}
.student-calendar-time-mobile .vector-FRe9kA {
  background-color: transparent;
  height: 121.98%;
  left: -8.49%;
  position: absolute;
  top: -10.99%;
  width: 116.98%;
}
.student-calendar-time-mobile .vector-FbIeqH {
  background-color: transparent;
  height: 8.96%;
  left: 25.33%;
  position: absolute;
  top: 36.57%;
  width: 6.54%;
}
.student-calendar-time-mobile .vector-GULZOy {
  background-color: transparent;
  height: 121.96%;
  left: -10.26%;
  position: absolute;
  top: -10.98%;
  width: 120.52%;
}
.student-calendar-time-mobile .vector-HDxFmN {
  background-color: transparent;
  height: 8.96%;
  left: 25.14%;
  position: absolute;
  top: 37.19%;
  width: 13.08%;
}
.student-calendar-time-mobile .vector-IfQt5H {
  background-color: transparent;
  height: 49.26%;
  left: 29.45%;
  position: absolute;
  top: 51.43%;
  width: 49.05%;
}
.student-calendar-time-mobile .vector-IpBwGI {
  background-color: transparent;
  height: 32.84%;
  left: 2.23%;
  position: absolute;
  top: 24.54%;
  width: 21.8%;
}
.student-calendar-time-mobile .vector-LS3dNl {
  background-color: transparent;
  height: 8.96%;
  left: 25.33%;
  position: absolute;
  top: 36.57%;
  width: 6.54%;
}
.student-calendar-time-mobile .vector-LpgxzN {
  background-color: transparent;
  height: 19.4%;
  left: 25.14%;
  position: absolute;
  top: 22.08%;
  width: 13.08%;
}
.student-calendar-time-mobile .vector-N2Y59c {
  background-color: transparent;
  height: 82.1%;
  left: 6.95%;
  position: absolute;
  top: 2.36%;
  width: 93.73%;
}
.student-calendar-time-mobile .vector-NF9mxS {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.18%;
}
.student-calendar-time-mobile .vector-O5Cxw9 {
  background-color: transparent;
  height: 13.43%;
  left: 63.56%;
  position: absolute;
  top: 41.24%;
  width: 25.07%;
}
.student-calendar-time-mobile .vector-OWyIvf {
  background-color: transparent;
  height: 144.31%;
  left: -20.86%;
  position: absolute;
  top: -22.16%;
  width: 141.73%;
}
.student-calendar-time-mobile .vector-Ob1Rqs {
  background-color: transparent;
  height: 52.24%;
  left: 49.48%;
  position: absolute;
  top: 8.1%;
  width: 45.78%;
}
.student-calendar-time-mobile .vector-P1lxK3 {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.18%;
}
.student-calendar-time-mobile .vector-PFKICe {
  background-color: transparent;
  height: 11.94%;
  left: 23.87%;
  position: absolute;
  top: 18.13%;
  width: 8.72%;
}
.student-calendar-time-mobile .vector-PtriGM {
  background-color: transparent;
  height: 11.94%;
  left: 24.12%;
  position: absolute;
  top: 18.13%;
  width: 15.26%;
}
.student-calendar-time-mobile .vector-QDyA6g {
  background-color: transparent;
  height: 110.03%;
  left: -7.97%;
  position: absolute;
  top: -5.02%;
  width: 115.95%;
}
.student-calendar-time-mobile .vector-Qv0mZ4 {
  background-color: transparent;
  height: 29.85%;
  left: 2.07%;
  position: absolute;
  top: 8.12%;
  width: 45.78%;
}
.student-calendar-time-mobile .vector-RYpCgT {
  background-color: transparent;
  height: 121.98%;
  left: -8.17%;
  position: absolute;
  top: -10.99%;
  width: 116.33%;
}
.student-calendar-time-mobile .vector-S9AR9M {
  background-color: transparent;
  height: 70.16%;
  left: -0.63%;
  position: absolute;
  top: 8.33%;
  width: 95.91%;
}
.student-calendar-time-mobile .vector-Srtp8D {
  background-color: transparent;
  height: 144.3%;
  left: -3.79%;
  position: absolute;
  top: -22.15%;
  width: 107.57%;
}
.student-calendar-time-mobile .vector-T0btkN {
  background-color: transparent;
  height: 11.94%;
  left: 23.87%;
  position: absolute;
  top: 18.13%;
  width: 8.72%;
}
.student-calendar-time-mobile .vector-T7EbAp {
  background-color: transparent;
  height: 13.43%;
  left: 63.59%;
  position: absolute;
  top: 41.24%;
  width: 18.53%;
}
.student-calendar-time-mobile .vector-TdE1pD {
  background-color: transparent;
  height: 49.26%;
  left: 29.45%;
  position: absolute;
  top: 51.43%;
  width: 49.05%;
}
.student-calendar-time-mobile .vector-U0LxYw {
  background-color: transparent;
  height: 4.05%;
  left: 70.85%;
  position: absolute;
  top: -1.45%;
  width: 14.08%;
}
.student-calendar-time-mobile .vector-UUUxJF {
  background-color: transparent;
  height: 121.96%;
  left: -10.26%;
  position: absolute;
  top: -10.98%;
  width: 120.52%;
}
.student-calendar-time-mobile .vector-VOGBVy {
  background-color: transparent;
  height: 41.8%;
  left: 4.1%;
  position: absolute;
  top: 13.83%;
  width: 19.62%;
}
.student-calendar-time-mobile .vector-VRMQru {
  background-color: transparent;
  height: 82.1%;
  left: 6.95%;
  position: absolute;
  top: 2.36%;
  width: 93.73%;
}
.student-calendar-time-mobile .vector-Vr5tiq {
  background-color: transparent;
  height: 29.85%;
  left: 37.37%;
  position: absolute;
  top: 2.71%;
  width: 22.89%;
}
.student-calendar-time-mobile .vector-WCljiC {
  background-color: transparent;
  height: 19.4%;
  left: 25.14%;
  position: absolute;
  top: 22.08%;
  width: 13.08%;
}
.student-calendar-time-mobile .vector-WfnLBo {
  background-color: transparent;
  height: 82.1%;
  left: 6.95%;
  position: absolute;
  top: 2.36%;
  width: 93.73%;
}
.student-calendar-time-mobile .vector-WqgE0F {
  background-color: transparent;
  height: 19.4%;
  left: 29.22%;
  position: absolute;
  top: 81.41%;
  width: 29.43%;
}
.student-calendar-time-mobile .vector-Y59V0s {
  background-color: transparent;
  height: 4.05%;
  left: 70.85%;
  position: absolute;
  top: -1.45%;
  width: 14.08%;
}
.student-calendar-time-mobile .vector-Y9TIUK {
  background-color: transparent;
  height: 121.99%;
  left: -5.05%;
  position: absolute;
  top: -10.99%;
  width: 110.09%;
}
.student-calendar-time-mobile .vector-YM4yxX {
  background-color: transparent;
  height: 5.97%;
  left: 36.74%;
  position: absolute;
  top: 28.23%;
  width: 3.27%;
}
.student-calendar-time-mobile .vector-aXV5cr {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.18%;
}
.student-calendar-time-mobile .vector-bKRlnb {
  background-color: transparent;
  height: 110.03%;
  left: -7.97%;
  position: absolute;
  top: -5.02%;
  width: 115.95%;
}
.student-calendar-time-mobile .vector-bnkfUA {
  background-color: transparent;
  height: 4.48%;
  left: 12.13%;
  position: absolute;
  top: 12.28%;
  width: 8.72%;
}
.student-calendar-time-mobile .vector-bof6oJ {
  background-color: transparent;
  height: 29.85%;
  left: 2.15%;
  position: absolute;
  top: 8.24%;
  width: 18.53%;
}
.student-calendar-time-mobile .vector-c0ymqA {
  background-color: transparent;
  height: 41.8%;
  left: 4.1%;
  position: absolute;
  top: 13.83%;
  width: 19.62%;
}
.student-calendar-time-mobile .vector-c9XjZ6 {
  background-color: transparent;
  height: 13.43%;
  left: 63.56%;
  position: absolute;
  top: 41.24%;
  width: 25.07%;
}
.student-calendar-time-mobile .vector-cJDtwl {
  background-color: transparent;
  height: 5.97%;
  left: 51.2%;
  position: absolute;
  top: 63.23%;
  width: 6.54%;
}
.student-calendar-time-mobile .vector-cNJjv2 {
  background-color: transparent;
  height: 32.84%;
  left: 2.23%;
  position: absolute;
  top: 24.54%;
  width: 21.8%;
}
.student-calendar-time-mobile .vector-d2duO5 {
  background-color: transparent;
  height: 4.05%;
  left: 70.85%;
  position: absolute;
  top: -1.45%;
  width: 14.08%;
}
.student-calendar-time-mobile .vector-dG0Ldx {
  background-color: transparent;
  height: 5.97%;
  left: 51.2%;
  position: absolute;
  top: 63.23%;
  width: 6.54%;
}
.student-calendar-time-mobile .vector-dS2uCE {
  background-color: transparent;
  height: 8.96%;
  left: 25.14%;
  position: absolute;
  top: 37.19%;
  width: 13.08%;
}
.student-calendar-time-mobile .vector-dS4UXQ {
  background-color: transparent;
  height: 49.26%;
  left: 29.45%;
  position: absolute;
  top: 51.43%;
  width: 49.05%;
}
.student-calendar-time-mobile .vector-dV81ro {
  background-color: transparent;
  height: 70.16%;
  left: -0.97%;
  position: absolute;
  top: 8.45%;
  width: 25.07%;
}
.student-calendar-time-mobile .vector-dcaDkc {
  background-color: transparent;
  height: 14.93%;
  left: 17.51%;
  position: absolute;
  top: 40.61%;
  width: 28.34%;
}
.student-calendar-time-mobile .vector-e2yeN1 {
  background-color: transparent;
  height: 29.85%;
  left: 37.46%;
  position: absolute;
  top: 2.72%;
  width: 34.88%;
}
.student-calendar-time-mobile .vector-ec7mDZ {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-calendar-time-mobile .vector-gvV2Rx {
  background-color: transparent;
  height: 70.16%;
  left: -0.97%;
  position: absolute;
  top: 8.45%;
  width: 25.07%;
}
.student-calendar-time-mobile .vector-hhAbtl {
  background-color: transparent;
  height: 14.93%;
  left: 17.13%;
  position: absolute;
  top: 40.61%;
  width: 11.99%;
}
.student-calendar-time-mobile .vector-i64Q95 {
  background-color: transparent;
  height: 19.4%;
  left: 29.22%;
  position: absolute;
  top: 81.41%;
  width: 29.43%;
}
.student-calendar-time-mobile .vector-jLf4aV {
  background-color: transparent;
  height: 7.46%;
  left: 67.71%;
  position: absolute;
  top: 51.44%;
  width: 7.63%;
}
.student-calendar-time-mobile .vector-jaA0fc {
  background-color: transparent;
  height: 144.31%;
  left: -3.79%;
  position: absolute;
  top: -22.16%;
  width: 107.57%;
}
.student-calendar-time-mobile .vector-k7b1Mi {
  background-color: transparent;
  height: 70.16%;
  left: -0.63%;
  position: absolute;
  top: 8.33%;
  width: 95.91%;
}
.student-calendar-time-mobile .vector-kwqROy {
  background-color: transparent;
  height: 144.31%;
  left: -20.86%;
  position: absolute;
  top: -22.16%;
  width: 141.73%;
}
.student-calendar-time-mobile .vector-lFxDi9 {
  background-color: transparent;
  height: 19.4%;
  left: 29.22%;
  position: absolute;
  top: 81.41%;
  width: 29.43%;
}
.student-calendar-time-mobile .vector-lj5Xre {
  background-color: transparent;
  height: 121.96%;
  left: -10.26%;
  position: absolute;
  top: -10.98%;
  width: 120.52%;
}
.student-calendar-time-mobile .vector-lkVzGd {
  background-color: transparent;
  height: 4.48%;
  left: 12.13%;
  position: absolute;
  top: 12.28%;
  width: 8.72%;
}
.student-calendar-time-mobile .vector-lw8NAW {
  background-color: transparent;
  height: 29.85%;
  left: 2.15%;
  position: absolute;
  top: 8.24%;
  width: 18.53%;
}
.student-calendar-time-mobile .vector-mGXgmO {
  background-color: transparent;
  height: 4.48%;
  left: 12.13%;
  position: absolute;
  top: 12.28%;
  width: 8.72%;
}
.student-calendar-time-mobile .vector-mokeKx {
  background-color: transparent;
  height: 14.93%;
  left: 17.51%;
  position: absolute;
  top: 40.61%;
  width: 28.34%;
}
.student-calendar-time-mobile .vector-ms1pvS {
  background-color: transparent;
  height: 41.8%;
  left: 4.07%;
  position: absolute;
  top: 13.83%;
  width: 55.59%;
}
.student-calendar-time-mobile .vector-nD2hpN {
  background-color: transparent;
  height: 101.5%;
  left: 11.3%;
  position: absolute;
  top: -1.01%;
  width: 85.01%;
}
.student-calendar-time-mobile .vector-ngma9X {
  background-color: transparent;
  height: 101.25%;
  left: -6.3%;
  position: absolute;
  top: 0.47%;
  width: 112.6%;
}
.student-calendar-time-mobile .vector-nzNou6 {
  background-color: transparent;
  height: 101.5%;
  left: 11.3%;
  position: absolute;
  top: -1.01%;
  width: 85.01%;
}
.student-calendar-time-mobile .vector-nzuIpS {
  background-color: transparent;
  height: 5.97%;
  left: 36.74%;
  position: absolute;
  top: 28.23%;
  width: 3.27%;
}
.student-calendar-time-mobile .vector-oafYiW {
  background-color: transparent;
  height: 121.98%;
  left: -8.17%;
  position: absolute;
  top: -10.99%;
  width: 116.33%;
}
.student-calendar-time-mobile .vector-oez9Pb {
  background-color: transparent;
  height: 8.96%;
  left: 25.14%;
  position: absolute;
  top: 37.19%;
  width: 13.08%;
}
.student-calendar-time-mobile .vector-pummtu {
  background-color: transparent;
  height: 144.3%;
  left: -3.79%;
  position: absolute;
  top: -22.15%;
  width: 107.57%;
}
.student-calendar-time-mobile .vector-pxIogv {
  background-color: transparent;
  height: 121.98%;
  left: -8.17%;
  position: absolute;
  top: -10.99%;
  width: 116.33%;
}
.student-calendar-time-mobile .vector-qGye3v {
  background-color: transparent;
  height: 70.16%;
  left: -0.63%;
  position: absolute;
  top: 8.33%;
  width: 95.91%;
}
.student-calendar-time-mobile .vector-qpftC5 {
  background-color: transparent;
  height: 14.93%;
  left: 17.13%;
  position: absolute;
  top: 40.61%;
  width: 11.99%;
}
.student-calendar-time-mobile .vector-rGRikn {
  background-color: transparent;
  height: 101.25%;
  left: -6.3%;
  position: absolute;
  top: 0.47%;
  width: 112.6%;
}
.student-calendar-time-mobile .vector-rXxPBk {
  background-color: transparent;
  height: 11.94%;
  left: 24.12%;
  position: absolute;
  top: 18.13%;
  width: 15.26%;
}
.student-calendar-time-mobile .vector-sku7Fc {
  background-color: transparent;
  height: 13.43%;
  left: 63.59%;
  position: absolute;
  top: 41.24%;
  width: 18.53%;
}
.student-calendar-time-mobile .vector-tQwNUN {
  background-color: transparent;
  height: 14.93%;
  left: 17.51%;
  position: absolute;
  top: 40.61%;
  width: 28.34%;
}
.student-calendar-time-mobile .vector-toBf7r {
  background-color: transparent;
  height: 41.8%;
  left: 4.07%;
  position: absolute;
  top: 13.83%;
  width: 55.59%;
}
.student-calendar-time-mobile .vector-vPuJH9 {
  background-color: transparent;
  height: 32.84%;
  left: 2.23%;
  position: absolute;
  top: 24.54%;
  width: 21.8%;
}
.student-calendar-time-mobile .vector-wEJYwt {
  background-color: transparent;
  height: 66.67%;
  left: 16.67%;
  position: absolute;
  top: 16.67%;
  width: 66.67%;
}
.student-calendar-time-mobile .vector-x2R7zB {
  background-color: transparent;
  height: 70.16%;
  left: -0.97%;
  position: absolute;
  top: 8.45%;
  width: 25.07%;
}
.student-calendar-time-mobile .vector-x5Jnp7 {
  background-color: transparent;
  height: 121.99%;
  left: -5.05%;
  position: absolute;
  top: -10.99%;
  width: 110.09%;
}
.student-calendar-time-mobile .vector-xB1UPi {
  background-color: transparent;
  height: 52.24%;
  left: 49.48%;
  position: absolute;
  top: 8.1%;
  width: 45.78%;
}
.student-calendar-time-mobile .vector-xJcTLo {
  background-color: transparent;
  height: 121.98%;
  left: -8.49%;
  position: absolute;
  top: -10.99%;
  width: 116.98%;
}
.student-calendar-time-mobile .vector-xMrRfb {
  background-color: transparent;
  height: 4.48%;
  left: 11.86%;
  position: absolute;
  top: 12.28%;
  width: 29.43%;
}
.student-calendar-time-mobile .vector-xOJZuX {
  background-color: transparent;
  height: 144.31%;
  left: -3.79%;
  position: absolute;
  top: -22.16%;
  width: 107.57%;
}
.student-calendar-time-mobile .vector-xhepvl {
  background-color: transparent;
  height: 5.97%;
  left: 37.29%;
  position: absolute;
  top: 28.23%;
  width: 2.18%;
}
.student-calendar-time-mobile .vector-xhwx9b {
  background-color: transparent;
  height: 7.46%;
  left: 67.71%;
  position: absolute;
  top: 51.44%;
  width: 7.63%;
}
.student-calendar-time-mobile .vector-xtOtSY {
  background-color: transparent;
  height: 41.8%;
  left: 4.1%;
  position: absolute;
  top: 13.83%;
  width: 19.62%;
}
.student-calendar-time-mobile .vector-xvjzYd {
  background-color: transparent;
  height: 4.48%;
  left: 11.86%;
  position: absolute;
  top: 12.28%;
  width: 29.43%;
}
.student-calendar-time-mobile .vector-ymbxcA {
  background-color: transparent;
  height: 29.85%;
  left: 37.37%;
  position: absolute;
  top: 2.71%;
  width: 22.89%;
}
.student-calendar-time-mobile .vector-zXuwwt {
  background-color: transparent;
  height: 34.33%;
  left: 2.43%;
  position: absolute;
  top: 22.69%;
  width: 58.85%;
}
.student-calendar-time-mobile .vector-zeZVsH {
  background-color: transparent;
  height: 8.96%;
  left: 25.33%;
  position: absolute;
  top: 36.57%;
  width: 6.54%;
}
.student-calendar-time-mobile .vector-zxfIi7 {
  background-color: transparent;
  height: 29.85%;
  left: 2.15%;
  position: absolute;
  top: 8.24%;
  width: 18.53%;
}
.student-calendar-time-mobile .view-all-cPi90N {
  background-color: transparent;
  height: auto;
  left: 309px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 6px;
  width: auto;
}
.student-calendar-time-mobile .view-all-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 296px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: 75px;
}
.student-calendar-time-mobile .view-forum-CN8bZ4 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 251px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 235px;
  width: 113px;
}
.student-calendar-time-mobile .view-forum-CNpLuU {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 251px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 169px;
  width: 113px;
}
.student-calendar-time-mobile .view-forum-rFX9Nh {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 251px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 103px;
  width: 113px;
}
.student-calendar-time-mobile .what-is-the-si-unit-of-current-rFX9Nh {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 74px;
  width: 289px;
}
.student-calendar-time-mobile .what-is-the-sol-f-this-question-rFX9Nh {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 140px;
  width: 289px;
}
.student-calendar-time-mobile .x12pm-Ajm6uA {
  background-color: transparent;
  height: auto;
  /* left: 18px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 70px; */
  width: 48px;
}
.student-calendar-time-mobile .x1pm-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 18px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 133px;
  width: 34px;
}
.student-calendar-time-mobile .x2pm-OUIM6H {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 40px;
}
.student-calendar-time-mobile .x3pm-bC1JcG {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.student-calendar-time-mobile .x4-i631221653103-p4wM3n {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: 43.31%;
  left: 28.93%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 40.72%;
  width: 43.04%;
}
.student-calendar-time-mobile .x4-i631221753103-9wWKr1 {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: 43.31%;
  left: 32.73%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 40.72%;
  width: 35.44%;
}
.student-calendar-time-mobile .x4-i631221953103-j2Gv3j {
  background-color: transparent;
  height: 45.61%;
  left: 50.09%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 40.72%;
  width: 0.66%;
}
.student-calendar-time-mobile .x4pm-JoE0OI {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 38px;
}
.student-calendar-time-mobile .x5pm-mdOnc7 {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 38px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - student-time-calendar */

.montserrat-medium-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-24px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-64px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-gray-20px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.student-time-calendar {
  background-color: rgba(253, 254, 255, 1);
  height: 100vh;
  width: 100%;
}
.student-time-calendar .add-C61RwL {
  background-color: transparent;
  height: auto;
  left: 793px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 759px;
  width: 29px;
}
.student-time-calendar .add-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 795px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 879px;
  width: 29px;
}
.student-time-calendar .add-delete-C61RwL {
  background-color: transparent;
  height: auto;
  left: 790px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 565px;
  width: 110px;
}
.student-time-calendar .add-mzXdH9 {
  background-color: transparent;
  height: auto;
  left: 793px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 939px;
  width: 29px;
}
.student-time-calendar .analytics-24px-3-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 644px;
  width: 24px;
}
.student-time-calendar .arrowback-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 182px;
  overflow: hidden;
  position: absolute;
  top: 489px;
  width: 24px;
}
.student-time-calendar .assignment-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 348px;
  width: 24px;
}
.student-time-calendar .biba-sobti-VdQhAS {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 92px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.student-time-calendar .bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: black;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 47px;
  width: auto;
}
.student-time-calendar .choose-time-for-booking-C61RwL {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 174px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 426px;
  width: 432px;
}
.student-time-calendar .class-name-C61RwL {
  background-color: transparent;
  height: auto;
  left: 353px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 565px;
  width: 124px;
}
.student-time-calendar .completed-C61RwL {
  background-color: transparent;
  height: auto;
  /* left: 560px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 633px; */
  width: 73px;
}
.student-time-calendar .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;
  left: 123px;
  position: absolute;
  top: 45px;
  width: 212px;
}
.student-time-calendar .component-4-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 194px;
  left: 12%;
  position: absolute;
  top: 192px;
  width: 158px;
}
.student-time-calendar .component-5-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 194px;
  left: 45.6%;
  position: absolute;
  top: 192px;
  width: 158px;
}
.student-time-calendar .component-6-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 194px;
  left: 24%;
  position: absolute;
  top: 192px;
  width: 304px;
}
.student-time-calendar .course-C61RwL {
  background-color: transparent;
  height: auto;
  left: 400px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 346px;
  width: auto;
}
.student-time-calendar .delete-C61RwL {
  background-color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  /* left: 781px; */
  letter-spacing: 0px;
  line-height: 30px;
  /* position: absolute; */
  text-align: left;
  /* top: 808px; */
  white-space: nowrap;
  width: 56px;
}
.student-time-calendar .description-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 496px;
  width: 24px;
}
.student-time-calendar .doubt-forum-C61RwL {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 980px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 309px;
  width: 168px;
}
.student-time-calendar .ellipse-2-OSIBbx {
  background-color: transparent;
  height: 19.05%;
  left: 57.14%;
  position: absolute;
  top: 21.43%;
  width: 19.05%;
}
.student-time-calendar .feb-18-2021-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 50%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 492px;
  width: 200px;
}
.student-time-calendar .go-back-C61RwL {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 219px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 489px;
  width: 105px;
}
.student-time-calendar .grade-C61RwL {
  background-color: transparent;
  height: auto;
  left: 400px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 303px;
  width: auto;
}
.student-time-calendar .group-0M78Jh {
  background-color: transparent;
  background-image: url(./img/vector-339@2x.png);
  background-size: 100% 100%;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-160-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  position: absolute;
  top: 718px;
  width: 24px;
}
.student-time-calendar .group-1G6kDx {
  background-color: transparent;
  background-image: url(./img/vector-182@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-time-calendar .group-22-C61RwL {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}
.student-time-calendar .group-26DuQS {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-time-calendar .group-30TA9U {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-time-calendar .group-3YhNjD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-3qdjky {
  background-color: transparent;
  background-image: url(./img/vector-179@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-time-calendar .group-4fKC6i {
  background-color: transparent;
  background-image: url(./img/vector-180@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-time-calendar .group-4wy4Bx {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-5YlXKz {
  background-color: transparent;
  background-image: url(./img/vector-180@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-time-calendar .group-5jt8cF {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-5r1RGr {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-time-calendar .group-8RWWXx {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}
.student-time-calendar .group-8SehwL {
  background-color: transparent;
  background-image: url(./img/vector-187@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-time-calendar .group-8mXtiA {
  background-color: transparent;
  background-image: url(./img/vector-187@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-time-calendar .group-Ambq12 {
  background-color: transparent;
  background-image: url(./img/vector-188@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-time-calendar .group-B0zdg8 {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-time-calendar .group-BVoyFW {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-time-calendar .group-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 22px;
  position: absolute;
  top: 422px;
  width: 20px;
}
.student-time-calendar .group-CZ3XDU {
  background-color: transparent;
  background-image: url(./img/vector-182@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-time-calendar .group-E9Z3Qz {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-time-calendar .group-Eub1uD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-FDKNYY {
  background-color: transparent;
  background-image: url(./img/vector-187@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-time-calendar .group-GQEqeI {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-GQNshl {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-Gv5bm4 {
  background-color: transparent;
  background-image: url(./img/vector-179@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-time-calendar .group-H4kFYy {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-time-calendar .group-Hc5Phf {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-time-calendar .group-HkDxar {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-I6cVpt {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-Ie5dUU {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-time-calendar .group-J1LPKs {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-K12spc {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-time-calendar .group-KLBpwU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-time-calendar .group-KWN2RE {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-time-calendar .group-KcOtnV {
  background-color: transparent;
  background-image: url(./img/vector-187@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-time-calendar .group-KsMB6y {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-time-calendar .group-LCuxKV {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-time-calendar .group-LMly0o {
  background-color: transparent;
  background-image: url(./img/vector-182@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-time-calendar .group-NOXmfT {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-OUAngl {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}
.student-time-calendar .group-SfTnen {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-time-calendar .group-SlOO8W {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-time-calendar .group-SlxGcy {
  background-color: transparent;
  background-image: url(./img/vector-179@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-time-calendar .group-Sw5xnU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-time-calendar .group-W5IipO {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-WAmUNE {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-time-calendar .group-X6Y0CP {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-time-calendar .group-XHqu7O {
  background-color: transparent;
  background-image: url(./img/vector-179@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-time-calendar .group-Z0P29R {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-time-calendar .group-Zmqz7u {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-time-calendar .group-a2vI4G {
  background-color: transparent;
  background-image: url(./img/vector-176@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-time-calendar .group-b1ls5q {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-time-calendar .group-bznq7Z {
  background-color: transparent;
  background-image: url(./img/vector-181@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-time-calendar .group-cTYyqt {
  background-color: transparent;
  background-image: url(./img/vector-176@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-time-calendar .group-ce7Ifp {
  background-color: transparent;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 20px;
}
.student-time-calendar .group-dimqSz {
  background-color: transparent;
  background-image: url(./img/vector-180@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-time-calendar .group-f6NdeG {
  background-color: transparent;
  background-image: url(./img/vector-181@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-time-calendar .group-fbkhx3 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-time-calendar .group-fxegOB {
  background-color: transparent;
  background-image: url(./img/vector-188@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-time-calendar .group-hNGwvV {
  background-color: transparent;
  background-image: url(./img/vector-176@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-time-calendar .group-hcfGEs {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}
.student-time-calendar .group-hoVMgD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-htgOUZ {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-time-calendar .group-hxa4BC {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-time-calendar .group-kK588U {
  background-color: transparent;
  background-image: url(./img/vector-181@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-time-calendar .group-lX9zMw {
  background-color: transparent;
  background-image: url(./img/vector-188@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-time-calendar .group-lmXTEf {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-time-calendar .group-m8nkkI {
  background-color: transparent;
  background-image: url(./img/vector-181@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-time-calendar .group-mnqxdV {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-time-calendar .group-oRRBjL {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-time-calendar .group-paC7Rl {
  background-color: transparent;
  background-image: url(./img/vector-182@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-time-calendar .group-pmZUQc {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-time-calendar .group-sx6xBo {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-tDnSYz {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-time-calendar .group-tr5zNE {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-time-calendar .group-wbAI42 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-time-calendar .group-wotaxO {
  background-color: transparent;
  background-image: url(./img/vector-188@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-time-calendar .group-wpJ3xR {
  background-color: transparent;
  background-image: url(./img/vector-180@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-time-calendar .group-xKrbSU {
  background-color: transparent;
  background-image: url(./img/vector-176@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-time-calendar .group-xfUToA {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-time-calendar .group-zkxeqe {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}
.student-time-calendar .help-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 570px;
  width: 24px;
}
.student-time-calendar .how-to-solve-this-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1034px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 532px;
  width: 290px;
}
.student-time-calendar .join-4-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 977px;
  overflow: hidden;
  position: absolute;
  top: 354px;
  width: 46px;
}
.student-time-calendar .join-5-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 977px;
  overflow: hidden;
  position: absolute;
  top: 441px;
  width: 46px;
}
.student-time-calendar .join-6-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 981px;
  overflow: hidden;
  position: absolute;
  top: 528px;
  width: 46px;
}
.student-time-calendar .join-7-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 984px;
  overflow: hidden;
  position: absolute;
  top: 612px;
  width: 46px;
}
.student-time-calendar .line-5-9p2GBv {
  background-color: transparent;
  height: 99.05%;
  left: 0.9%;
  position: absolute;
  top: 0.48%;
  width: 99.29%;
}
.student-time-calendar .line-6-9p2GBv {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}
.student-time-calendar .notifications-24px-2-1-C61RwL {
  background-color: transparent;
  height: 42px;
  left: 976px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 42px;
}
.student-time-calendar .organic-4-C61RwL {
  background-color: transparent;
  height: auto;
  /* left: 382px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 633px; */
  width: 66px;
}
.student-time-calendar .organic-5-C61RwL {
  background-color: transparent;
  height: auto;
  left: 382px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 815px;
  width: 66px;
}
.student-time-calendar .pending-assignments-C61RwL {
  background-color: transparent;
  color: #dc3545;
  height: auto;
  left: 72%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 737px;
  width: 218px;
}
.student-time-calendar .please-check-my-approach-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1037px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 616px;
  width: 290px;
}
.student-time-calendar .rectangle-24-C61RwL-stu-s1 {
  background-color: #1f2531;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 63px;

  overflow-y: scroll;
  padding: 150px 0px 20px 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.student-time-calendar .rectangle-31-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 43.75%;
  left: 172px;
  position: absolute;
  top: 560px;
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}
.student-time-calendar .rectangle-32-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 59px;
  right: 3%;
  position: absolute;
  top: 771px;
  width: 386px;
}
.student-time-calendar .rectangle-33-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 59px;
  left: 962px;
  position: absolute;
  top: 850px;
  width: 386px;
}
.student-time-calendar .rectangle-40-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  /* left: 191px; */
  /* position: absolute; */
  /* top: 619px; */
  width: 672px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  margin-top: 24px;
}
.student-time-calendar .rectangle-41-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 189px;
  position: absolute;
  top: 740px;
  width: 672px;
}
.student-time-calendar .rectangle-42-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 114px;
  right: 5%;
  position: absolute;
  padding: 10px;
  top: 154px;
  width: 28%;
}
.student-time-calendar .rectangle-43-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 417px;
  left: 961px;
  position: absolute;
  top: 290px;
  width: 387px;
}
.student-time-calendar .rectangle-44-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 189px;
  position: absolute;
  top: 867px;
  width: 672px;
}
.student-time-calendar .rectangle-47-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 191px;
  position: absolute;
  top: 679px;
  width: 672px;
}
.student-time-calendar .rectangle-48-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 189px;
  position: absolute;
  top: 800px;
  width: 672px;
}
.student-time-calendar .rectangle-7-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 630px;
  position: absolute;
  top: 631px;
  width: 1px;
}
.student-time-calendar .sections-teachi-g-i631213753102-p4wM3n {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: 22.68%;
  left: 28.45%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 12.89%;
  width: 44.94%;
}
.student-time-calendar .sections-teachi-g-i631213953102-9wWKr1 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: 22.68%;
  left: 15.79%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 12.89%;
  width: 69.62%;
}
.student-time-calendar .status-C61RwL {
  background-color: transparent;
  height: auto;
  left: 484px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 565px;
  width: 64px;
}
.student-time-calendar .stickynote2-24px-1-Cp4Be6 {
  background-color: transparent;
  height: 24px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 24px;
}
.student-time-calendar .subject-C61RwL {
  background-color: transparent;
  height: auto;
  left: 401px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 260px;
  width: auto;
}
.student-time-calendar .supportagent-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 792px;
  width: 24px;
}
.student-time-calendar .teacher-name-C61RwL {
  background-color: transparent;
  height: auto;
  left: 25%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 217px;
  font-size: 18px;
  width: auto;
}
.student-time-calendar .time-C61RwL {
  background-color: transparent;
  height: auto;
  left: 230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 565px;
  width: 54px;
}
.student-time-calendar .upcoming-C61RwL {
  background-color: transparent;
  height: auto;
  left: 568px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 815px;
  width: 68px;
}
.student-time-calendar .vector-0HfMqN {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}
.student-time-calendar .vector-1G6kDx {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}
.student-time-calendar .vector-1YY105 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}
.student-time-calendar .vector-1ewXKa {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}
.student-time-calendar .vector-26DuQS {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}
.student-time-calendar .vector-2kxNnt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}
.student-time-calendar .vector-30TA9U {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}
.student-time-calendar .vector-3q0WPs {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}
.student-time-calendar .vector-3qdjky {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}
.student-time-calendar .vector-4ae2Rf {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}
.student-time-calendar .vector-4fKC6i {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}
.student-time-calendar .vector-4sG9FF {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}
.student-time-calendar .vector-4vfNnN {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}
.student-time-calendar .vector-5S9pFx {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}
.student-time-calendar .vector-5YlXKz {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}
.student-time-calendar .vector-5r1RGr {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}
.student-time-calendar .vector-5x4wyG {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}
.student-time-calendar .vector-6XJ1dC {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}
.student-time-calendar .vector-7QdKbH {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}
.student-time-calendar .vector-7pUNkI {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-time-calendar .vector-7txfVx {
  background-color: transparent;
  height: 58.33%;
  left: 20.83%;
  position: absolute;
  top: 20.83%;
  width: 58.33%;
}
.student-time-calendar .vector-7uUfdl {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}
.student-time-calendar .vector-8JNBpx {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}
.student-time-calendar .vector-8RWWXx {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}
.student-time-calendar .vector-8SehwL {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}
.student-time-calendar .vector-8U01yU {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}
.student-time-calendar .vector-8XEWy4 {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}
.student-time-calendar .vector-8mXtiA {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}
.student-time-calendar .vector-8xAgQn {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}
.student-time-calendar .vector-9opORi {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}
.student-time-calendar .vector-9vYPU5 {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}
.student-time-calendar .vector-AONfqU {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}
.student-time-calendar .vector-Ambq12 {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}
.student-time-calendar .vector-AuhUI1 {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}
.student-time-calendar .vector-B0zdg8 {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}
.student-time-calendar .vector-BVoyFW {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}
.student-time-calendar .vector-C61RwL {
  background-color: transparent;
  height: 2.34%;
  left: 1.32%;
  position: absolute;
  top: 92.77%;
  width: 1.67%;
}
.student-time-calendar .vector-CLdsug {
  background-color: transparent;
  height: 82.14%;
  left: 16.67%;
  position: absolute;
  top: 9.97%;
  width: 66.67%;
}
.student-time-calendar .vector-CMx3Uj {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}
.student-time-calendar .vector-CZ3XDU {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}
.student-time-calendar .vector-ChPPby {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}
.student-time-calendar .vector-DcBIgL {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}
.student-time-calendar .vector-Dn0VOv {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}
.student-time-calendar .vector-E3VqMR {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}
.student-time-calendar .vector-E9Z3Qz {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}
.student-time-calendar .vector-FDKNYY {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}
.student-time-calendar .vector-Fb0JQL {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}
.student-time-calendar .vector-FxTcxt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}
.student-time-calendar .vector-G0yBDR {
  background-color: transparent;
  height: 83.33%;
  left: 16.67%;
  position: absolute;
  top: 8.33%;
  width: 66.67%;
}
.student-time-calendar .vector-Gv5bm4 {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}
.student-time-calendar .vector-H4kFYy {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}
.student-time-calendar .vector-Hc5Phf {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}
.student-time-calendar .vector-JWb765 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}
.student-time-calendar .vector-K12spc {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}
.student-time-calendar .vector-KLBpwU {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}
.student-time-calendar .vector-KLFwa3 {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}
.student-time-calendar .vector-KRXr9B {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}
.student-time-calendar .vector-KWN2RE {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}
.student-time-calendar .vector-KcOtnV {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}
.student-time-calendar .vector-LCuxKV {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}
.student-time-calendar .vector-LMly0o {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}
.student-time-calendar .vector-LgtYic {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}
.student-time-calendar .vector-MWjU8S {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}
.student-time-calendar .vector-MtJxOL {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}
.student-time-calendar .vector-NLtoul {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}
.student-time-calendar .vector-OB8oOM {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}
.student-time-calendar .vector-OSIBbx {
  background-color: transparent;
  height: 1.19%;
  left: 49.4%;
  position: absolute;
  top: 49.4%;
  width: 1.19%;
}
.student-time-calendar .vector-OUAngl {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}
.student-time-calendar .vector-OYLvDa {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}
.student-time-calendar .vector-OsxaKh {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}
.student-time-calendar .vector-Ozaj6l {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}
.student-time-calendar .vector-QxuFcp {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}
.student-time-calendar .vector-SfTnen {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}
.student-time-calendar .vector-SlOO8W {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}
.student-time-calendar .vector-SlxGcy {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}
.student-time-calendar .vector-Sw5xnU {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}
.student-time-calendar .vector-T0rcQo {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}
.student-time-calendar .vector-Ta8Heo {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}
.student-time-calendar .vector-TlPffh {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}
.student-time-calendar .vector-TmFVy3 {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}
.student-time-calendar .vector-UUKtms {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}
.student-time-calendar .vector-UaM55c {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-time-calendar .vector-UxrCfI {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}
.student-time-calendar .vector-VAjk24 {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}
.student-time-calendar .vector-VJLVH1 {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}
.student-time-calendar .vector-VMr6Om {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 26.76%;
  width: 1.67%;
}
.student-time-calendar .vector-VP2Rux {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}
.student-time-calendar .vector-VdQhAS {
  background-color: transparent;
  height: 100%;
  left: 73.42%;
  position: absolute;
  top: 0px;
  width: 25.32%;
}
.student-time-calendar .vector-W1hXZ4 {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}
.student-time-calendar .vector-WAmUNE {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}
.student-time-calendar .vector-WK7UJi {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}
.student-time-calendar .vector-WVknxn {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}
.student-time-calendar .vector-WbzDQU {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-time-calendar .vector-X6Y0CP {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}
.student-time-calendar .vector-XHqu7O {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}
.student-time-calendar .vector-XQwS4n {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}
.student-time-calendar .vector-XjFi5l {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}
.student-time-calendar .vector-XqVjZB {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}
.student-time-calendar .vector-XwxuE1 {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}
.student-time-calendar .vector-YarvLR {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}
.student-time-calendar .vector-Z0P29R {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}
.student-time-calendar .vector-a2vI4G {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}
.student-time-calendar .vector-apzjgl {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}
.student-time-calendar .vector-b1ls5q {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}
.student-time-calendar .vector-baKUI6 {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}
.student-time-calendar .vector-bznq7Z {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}
.student-time-calendar .vector-c18eCt {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}
.student-time-calendar .vector-cTYyqt {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}
.student-time-calendar .vector-dBr6KK {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}
.student-time-calendar .vector-dLw05Z {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-time-calendar .vector-dimqSz {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}
.student-time-calendar .vector-dmgbrE {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}
.student-time-calendar .vector-dsyqYk {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}
.student-time-calendar .vector-ec7mDZ {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-time-calendar .vector-eh9WzI {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}
.student-time-calendar .vector-f6NdeG {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}
.student-time-calendar .vector-fCysJq {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}
.student-time-calendar .vector-fbkhx3 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}
.student-time-calendar .vector-fxegOB {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}
.student-time-calendar .vector-g9aAPA {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}
.student-time-calendar .vector-gsSdey {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}
.student-time-calendar .vector-hNGwvV {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}
.student-time-calendar .vector-hPKSzt {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}
.student-time-calendar .vector-hcfGEs {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}
.student-time-calendar .vector-htgOUZ {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}
.student-time-calendar .vector-hxa4BC {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}
.student-time-calendar .vector-ib8Dlq {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}
.student-time-calendar .vector-irJBpK {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-time-calendar .vector-jQrdMY {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}
.student-time-calendar .vector-jmCVFs {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}
.student-time-calendar .vector-kK588U {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}
.student-time-calendar .vector-l8qsXH {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}
.student-time-calendar .vector-lWWNsc {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}
.student-time-calendar .vector-lX9zMw {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}
.student-time-calendar .vector-lmXTEf {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}
.student-time-calendar .vector-m8nkkI {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}
.student-time-calendar .vector-mmO9xv {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}
.student-time-calendar .vector-mzXdH9 {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 19.53%;
  width: 1.67%;
}
.student-time-calendar .vector-n1UKnn {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}
.student-time-calendar .vector-nXJ6dP {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}
.student-time-calendar .vector-oOS98D {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}
.student-time-calendar .vector-oRRBjL {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}
.student-time-calendar .vector-oZNlX3 {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}
.student-time-calendar .vector-objxfZ {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}
.student-time-calendar .vector-paC7Rl {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}
.student-time-calendar .vector-pagq92 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}
.student-time-calendar .vector-pgMXxb {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}
.student-time-calendar .vector-pmZUQc {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}
.student-time-calendar .vector-pxZ96q {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}
.student-time-calendar .vector-qF2ch0 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}
.student-time-calendar .vector-qGJxwJ {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}
.student-time-calendar .vector-qHVi3n {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}
.student-time-calendar .vector-qmzfLw {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}
.student-time-calendar .vector-qy1MCO {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}
.student-time-calendar .vector-r18CAb {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}
.student-time-calendar .vector-r4buxr {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}
.student-time-calendar .vector-rnzczl {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}
.student-time-calendar .vector-sCPPBx {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}
.student-time-calendar .vector-tDnSYz {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}
.student-time-calendar .vector-tQ6NQH {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}
.student-time-calendar .vector-tSUwp7 {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}
.student-time-calendar .vector-uhbuVM {
  background-color: transparent;
  height: 36.11%;
  left: 20.01%;
  position: absolute;
  top: 16.53%;
  width: 60%;
}
.student-time-calendar .vector-v9Qr6G {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}
.student-time-calendar .vector-vJyLWf {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}
.student-time-calendar .vector-wEJYwt {
  background-color: transparent;
  height: 66.67%;
  left: 16.67%;
  position: absolute;
  top: 16.67%;
  width: 66.67%;
}
.student-time-calendar .vector-wU39qv {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}
.student-time-calendar .vector-wbAI42 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}
.student-time-calendar .vector-wotaxO {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}
.student-time-calendar .vector-wpJ3xR {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}
.student-time-calendar .vector-x7OZie {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}
.student-time-calendar .vector-xKrbSU {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}
.student-time-calendar .vector-xYu4ww {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}
.student-time-calendar .vector-xfUToA {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}
.student-time-calendar .vector-xr8pKE {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}
.student-time-calendar .vector-xy9YKY {
  background-color: transparent;
  height: 11.11%;
  left: 30%;
  position: absolute;
  top: 50%;
  width: 10%;
}
.student-time-calendar .vector-ydL8U7 {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-time-calendar .vector-ye3NYT {
  background-color: transparent;
  height: 11.11%;
  left: 60%;
  position: absolute;
  top: 50%;
  width: 10%;
}
.student-time-calendar .vector-ywnyBE {
  background-color: transparent;
  height: 83.33%;
  left: 8.33%;
  position: absolute;
  top: 8.33%;
  width: 83.33%;
}
.student-time-calendar .vector-zJJzco {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}
.student-time-calendar .vector-zLY8MO {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}
.student-time-calendar .vector-zkxeqe {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}
.student-time-calendar .view-all-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1284px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 739px;
  width: 53px;
}
.student-time-calendar .view-all-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 1282px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 316px;
  width: 75px;
}
.student-time-calendar .view-forum-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 391px;
  width: 113px;
}
.student-time-calendar .view-forum-QxM5SU {
  background-color: transparent;
  height: auto;
  left: 1233px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 647px;
  width: 113px;
}
.student-time-calendar .view-forum-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 1230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 467px;
  width: 113px;
}
.student-time-calendar .view-forum-mzXdH9 {
  background-color: transparent;
  height: auto;
  left: 1230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 563px;
  width: 113px;
}
.student-time-calendar .what-is-the-si-unit-of-current-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1034px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 360px;
  width: 290px;
}
.student-time-calendar .what-is-the-sol-f-this-question-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1034px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 436px;
  width: 290px;
}
.student-time-calendar .x12pm-C61RwL {
  background-color: transparent;
  height: auto;
  /* left: 206px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 633px; */
  width: 38px;
}
.student-time-calendar .x14-C61RwL {
  background-color: transparent;
  color: rgba(244, 244, 244, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 1166px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 450px;
  width: auto;
}
.student-time-calendar .x1pm-C61RwL {
  background-color: transparent;
  height: auto;
  left: 206px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 697px;
  width: 31px;
}
.student-time-calendar .x2pm-C61RwL {
  background-color: transparent;
  height: auto;
  left: 206px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 757px;
  width: 31px;
}
.student-time-calendar .x3pm-C61RwL {
  background-color: transparent;
  height: auto;
  left: 206px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 814px;
  width: 31px;
}
.student-time-calendar .x4-i631213753103-p4wM3n {
  background-color: transparent;
  height: 40.21%;
  left: 26.4%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 40.72%;
  width: 48.73%;
}
.student-time-calendar .x4-i631213853103-5mfU7d {
  background-color: transparent;
  height: 40.21%;
  left: 50.09%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 40.72%;
  width: 0.66%;
}
.student-time-calendar .x4-i631213953103-9wWKr1 {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: 40.21%;
  left: 30.2%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 40.72%;
  width: 40.51%;
}
.student-time-calendar .x4pm-C61RwL {
  background-color: transparent;
  height: auto;
  left: 206px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 879px;
  width: 31px;
}
.student-time-calendar .you-have-no-upcoming-tests-C61RwL {
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 1.4em;
  font-style: normal;
  font-weight: 600;
  height: auto;

  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;

  width: 349px;
}

.slot-modal {
  width: 40%;
  height: 200px;
  margin: auto;
  margin-top: 90px;
  background-color: whitesmoke;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  padding: 24px 0;
}

.slot-modal .slot-modal-title {
  width: 70%;
  margin: auto;
  text-align: center;
  margin-bottom: 24px;
}

.slot-modal .slot-modal-body {
  padding: 0 14px;
  width: 80%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
}

.warning {
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
  color: red;
}

:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .student-time-calendar {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .student-calendar-time-mobile {
    display: none;
  }
}
