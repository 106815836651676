.main {
  width: 100%;
  padding: 40px;
  list-style: 1.3;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.heading {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #dc3545;
  line-height: 1.5;
}
.heading2 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: green;
  list-style: 1.3;
  margin-bottom: 10px;
}
.heading3 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: black;
  list-style: 1.3;
  margin-bottom: 10px;
}
.title {
  font-size: 20px;
  font-weight: 600;
  list-style: 1.3;
  margin-bottom: 10px;
}

.main p {
  font-size: 16px;
  font-weight: 400;
  list-style: 1.3;
  margin-bottom: 4px;
}
.bold {
  font-weight: 600;
}
.main li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  margin-left: 10px;
  margin-bottom: 4px;
  list-style: disc inside;
}
.fromWraper {
  width: 60%;
  border: 5px solid #dc3545;
  border-radius: 8px;
  padding: 40px 32px;
}

@media screen and (max-width: 768px) {
  .main {
    padding: 28px 16px;
    gap: 24px;
  }
  .heading {
    font-size: 20px;
  }
  .heading2 {
    font-size: 18px;
  }
  .heading3 {
    font-size: 16px;
  }
  .main p {
    font-size: 15px;
  }
  .main li {
    font-size: 15px;
  }
  .fromWraper {
    width: 100%;
    border: 3px solid #dc3545;
    border-radius: 8px;
    padding: 16px 12px;
  }
}
