@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,500,600|Roboto:700,500");
/* screen - teacher-calendar-dates-mobile */

.montserrat-bold-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.montserrat-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-nobel-16px {
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-nobel-20px {
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-64px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-20px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-24px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.teacher-calendar-dates-mobile {
  background-color: rgba(253, 254, 255, 1);
  height: 1206px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 414px;
}
.teacher-calendar-dates-mobile .choose-date-for-opening-slots-C61RwL {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 531px;
  width: 338px;
}
.teacher-calendar-dates-mobile .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 45px;
  left: 26px;
  position: absolute;
  top: 24px;
  width: 87px;
}
.teacher-calendar-dates-mobile .component-4-x75Vd1 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 157px;
  margin-top: 33px;
  position: relative;
  width: 158px;
}
.teacher-calendar-dates-mobile .component-5-gT8vIR {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 157px;
  margin-top: 33px;
  position: relative;
  width: 158px;
}
.teacher-calendar-dates-mobile .component-6-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 362px;
  min-height: 18px;
  position: absolute;
  top: 37px;
  width: 23px;
}
.teacher-calendar-dates-mobile .component-7-x75Vd1 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 157px;
  padding: 20.2px 30.1px;
  position: relative;
  width: 158px;
}
.teacher-calendar-dates-mobile .component-8-gT8vIR {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 157px;
  padding: 20.2px 17.1px;
  position: relative;
  width: 158px;
}
.teacher-calendar-dates-mobile .doubt-forum-rFX9Nh {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 25px;
  width: 131px;
}
.teacher-calendar-dates-mobile .ellipse-2-C61RwL {
  background-color: transparent;
  height: 6px;
  left: 44px;
  position: absolute;
  top: 784px;
  width: 4px;
}
.teacher-calendar-dates-mobile .ellipse-3-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 194px;
  position: absolute;
  top: 760px;
  width: 35px;
}
.teacher-calendar-dates-mobile .feb-2021-C61RwL {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 569px;
  width: 90%;
}
.teacher-calendar-dates-mobile .flex-col-OgaGtS {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 347px;
  position: relative;
  width: 158px;
}
.teacher-calendar-dates-mobile .flex-col-P2Vk9m {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 46px;
  min-height: 347px;
  position: relative;
  width: 158px;
}
.teacher-calendar-dates-mobile .fri-C61RwL {
  background-color: transparent;
  height: auto;
  left: 253px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 620px;
  width: 33px;
}
.teacher-calendar-dates-mobile .group-124-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 347px;
  justify-content: flex-start;
  left: 26px;
  min-width: 362px;
  position: absolute;
  top: 120px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-1pzCME {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-3HWdqU {
  background-color: transparent;
  height: 25px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-calendar-dates-mobile .group-3eHi1a {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 46px;
}
.teacher-calendar-dates-mobile .group-3xCoUY {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-4Tztbv {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-calendar-dates-mobile .group-5ZFw7U {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-6dsxAM {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 12px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-6u2TAk {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-85-C61RwL {
  background-color: transparent;
  height: 270px;
  left: 26px;
  position: absolute;
  top: 915px;
  width: 385px;
}
.teacher-calendar-dates-mobile .group-9neAS5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-B3X4ab {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-BpxbEL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-calendar-dates-mobile .group-DhmCtC {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 29px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-FASyuA {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-calendar-dates-mobile .group-HTx4Jc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-calendar-dates-mobile .group-IfQt5H {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-N2Y59c {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-O5Cxw9 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 29px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-Ob1Rqs {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-Qv0mZ4 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-S9AR9M {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 26px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-T7EbAp {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-TdE1pD {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-TtUkiV {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 46px;
}
.teacher-calendar-dates-mobile .group-VOGBVy {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-VRMQru {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-WfnLBo {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-bX4Dvh {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-calendar-dates-mobile .group-bcFrzZ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-calendar-dates-mobile .group-bof6oJ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 38px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-c0ymqA {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-c9XjZ6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 29px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-dS4UXQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-dV81ro {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-dcaDkc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-gn7VaX {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-calendar-dates-mobile .group-gvV2Rx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-hhAbtl {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-iieIco {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-calendar-dates-mobile .group-jLf4aV {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 12px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-k7b1Mi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 26px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-lw8NAW {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 38px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-mokeKx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-ms1pvS {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-nD2hpN {
  background-color: transparent;
  height: 25px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-calendar-dates-mobile .group-nzNou6 {
  background-color: transparent;
  height: 25px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-calendar-dates-mobile .group-qGye3v {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 26px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-qpftC5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-sku7Fc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-tQwNUN {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-toBf7r {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-wQSXdG {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 46px;
}
.teacher-calendar-dates-mobile .group-x2R7zB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-xB1UPi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-xS7o0X {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-calendar-dates-mobile .group-xhwx9b {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 12px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-xtOtSY {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-calendar-dates-mobile .group-zxfIi7 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 38px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-dates-mobile .how-to-solve-this-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 214px;
  width: 289px;
}
.teacher-calendar-dates-mobile .join-7-rFX9Nh {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 35px;
  justify-content: flex-start;
  left: 11px;
  overflow: hidden;
  padding: 0.8px 0;
  position: absolute;
  top: 68px;
  width: 46px;
}
.teacher-calendar-dates-mobile .join-8-rFX9Nh {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 35px;
  justify-content: flex-start;
  left: 9px;
  overflow: hidden;
  padding: 0.8px 0;
  position: absolute;
  top: 139px;
  width: 46px;
}
.teacher-calendar-dates-mobile .join-9-rFX9Nh {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 35px;
  justify-content: flex-start;
  left: 9px;
  overflow: hidden;
  padding: 0.8px 0;
  position: absolute;
  top: 210px;
  width: 46px;
}
.teacher-calendar-dates-mobile .line-5-9p2GBv {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.teacher-calendar-dates-mobile .line-6-9p2GBv {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.teacher-calendar-dates-mobile .mon-C61RwL {
  background-color: transparent;
  height: auto;
  left: 23px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 620px;
  width: 47px;
}
.teacher-calendar-dates-mobile .next-month-C61RwL {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 272px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 569px;
  width: auto;
}
.teacher-calendar-dates-mobile .rectangle-44-rFX9Nh {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 270px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 358px;
}
.teacher-calendar-dates-mobile .rectangle-48-5mfU7d {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.teacher-calendar-dates-mobile .rectangle-49-5mfU7d {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-calendar-dates-mobile .rectangle-50-5mfU7d {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-calendar-dates-mobile .sat-C61RwL {
  background-color: transparent;
  height: auto;
  left: 309px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 620px;
  width: 34px;
}
.teacher-calendar-dates-mobile .sections-teachi-g-i650262853102-KyDRqz {
  background-color: transparent;
  height: auto;
  left: 41px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .sections-teachi-g-i650262953102-xVxRxD {
  background-color: transparent;
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
  width: auto;
}
.teacher-calendar-dates-mobile .sections-teachi-g-i650263053102-s8ffFt {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 24px;
  min-width: 96px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-calendar-dates-mobile .sections-teachi-g-i650263153102-87a2V4 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 24px;
  min-width: 121px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-calendar-dates-mobile .span0-KoN7KL {
  color: rgba(19, 37, 71, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.teacher-calendar-dates-mobile .span1-KoN7KL {
  color: rgba(19, 37, 71, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.teacher-calendar-dates-mobile .sun-C61RwL {
  background-color: transparent;
  height: auto;
  left: 365px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 620px;
  width: 48px;
}
.teacher-calendar-dates-mobile .thu-C61RwL {
  background-color: transparent;
  height: auto;
  left: 197px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 620px;
  width: 42px;
}
.teacher-calendar-dates-mobile .tue-C61RwL {
  background-color: transparent;
  height: auto;
  left: 82px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 620px;
  width: 43px;
}
.teacher-calendar-dates-mobile .vector-0IPvau {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-calendar-dates-mobile .vector-1Nxj6x {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-1TafeU {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-1oS1NN {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-1pzCME {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-calendar-dates-mobile .vector-1xHKrh {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-2rF7C5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-38tHsM {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-3HWdqU {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-calendar-dates-mobile .vector-3X2JZC {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-3xCoUY {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-5SSNkN {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 11px;
}
.teacher-calendar-dates-mobile .vector-5YyGRW {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-calendar-dates-mobile .vector-5ZFw7U {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-calendar-dates-mobile .vector-5ZxPCv {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-calendar-dates-mobile .vector-6Pj9gj {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-6dsxAM {
  background-color: transparent;
  height: 3px;
  left: 31px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-6lEMsv {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-calendar-dates-mobile .vector-6u2TAk {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 26px;
}
.teacher-calendar-dates-mobile .vector-9neAS5 {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 6px;
}
.teacher-calendar-dates-mobile .vector-ADvjvZ {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-calendar-dates-mobile .vector-AEuo4j {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-calendar-dates-mobile .vector-AKNQwM {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-calendar-dates-mobile .vector-AUE2eU {
  background-color: transparent;
  height: 25px;
  left: -0px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-calendar-dates-mobile .vector-B3X4ab {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-calendar-dates-mobile .vector-CKdTAP {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-calendar-dates-mobile .vector-CaPVo9 {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 12px;
}
.teacher-calendar-dates-mobile .vector-DhmCtC {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 12px;
}
.teacher-calendar-dates-mobile .vector-FBXFcM {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-calendar-dates-mobile .vector-FRe9kA {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-FbIeqH {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-GULZOy {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-HDxFmN {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-calendar-dates-mobile .vector-IfQt5H {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-calendar-dates-mobile .vector-IpBwGI {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-calendar-dates-mobile .vector-LS3dNl {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-LpgxzN {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-calendar-dates-mobile .vector-MNlTtX {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-N2Y59c {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-calendar-dates-mobile .vector-NF9mxS {
  background-color: transparent;
  height: 2px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 1px;
}
.teacher-calendar-dates-mobile .vector-O5Cxw9 {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 12px;
}
.teacher-calendar-dates-mobile .vector-OWyIvf {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-calendar-dates-mobile .vector-Ob1Rqs {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-calendar-dates-mobile .vector-P1lxK3 {
  background-color: transparent;
  height: 2px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 1px;
}
.teacher-calendar-dates-mobile .vector-PC91KU {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-PD0S0h {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-PFKICe {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-PtriGM {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-calendar-dates-mobile .vector-QDyA6g {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-Qv0mZ4 {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-calendar-dates-mobile .vector-Qv8Xbp {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-calendar-dates-mobile .vector-RYpCgT {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-S9AR9M {
  background-color: transparent;
  height: 24px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 45px;
}
.teacher-calendar-dates-mobile .vector-Srtp8D {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-T0btkN {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-T7EbAp {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-TdE1pD {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-calendar-dates-mobile .vector-U0LxYw {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-UUUxJF {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-VOGBVy {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-VRMQru {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-calendar-dates-mobile .vector-Vr5tiq {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 11px;
}
.teacher-calendar-dates-mobile .vector-WCljiC {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-calendar-dates-mobile .vector-WfnLBo {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-calendar-dates-mobile .vector-WnZAcK {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 8px;
}
.teacher-calendar-dates-mobile .vector-WqgE0F {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-calendar-dates-mobile .vector-Y59V0s {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-Y9TIUK {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-YM4yxX {
  background-color: transparent;
  height: 2px;
  left: 17px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-Zh51an {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-aXV5cr {
  background-color: transparent;
  height: 2px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 1px;
}
.teacher-calendar-dates-mobile .vector-bKRlnb {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-bnkfUA {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-bof6oJ {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-c0ymqA {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-c9XjZ6 {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 12px;
}
.teacher-calendar-dates-mobile .vector-cJDtwl {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-cKyO6s {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-cNJjv2 {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-calendar-dates-mobile .vector-d2duO5 {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-dG0Ldx {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-dS2uCE {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-calendar-dates-mobile .vector-dS4UXQ {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-calendar-dates-mobile .vector-dV81ro {
  background-color: transparent;
  height: 24px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 12px;
}
.teacher-calendar-dates-mobile .vector-dcaDkc {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-calendar-dates-mobile .vector-e2yeN1 {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-calendar-dates-mobile .vector-eF7kig {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 12px;
}
.teacher-calendar-dates-mobile .vector-gvV2Rx {
  background-color: transparent;
  height: 24px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 12px;
}
.teacher-calendar-dates-mobile .vector-halcXV {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-calendar-dates-mobile .vector-hhAbtl {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 6px;
}
.teacher-calendar-dates-mobile .vector-i64Q95 {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-calendar-dates-mobile .vector-jLf4aV {
  background-color: transparent;
  height: 3px;
  left: 31px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-jaA0fc {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-k7b1Mi {
  background-color: transparent;
  height: 24px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 45px;
}
.teacher-calendar-dates-mobile .vector-kwqROy {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-calendar-dates-mobile .vector-lFxDi9 {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-calendar-dates-mobile .vector-lj5Xre {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-lkVzGd {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-lw8NAW {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-mGXgmO {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-mokeKx {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-calendar-dates-mobile .vector-ms1pvS {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 26px;
}
.teacher-calendar-dates-mobile .vector-nD2hpN {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-calendar-dates-mobile .vector-ngma9X {
  background-color: transparent;
  height: 25px;
  left: -0px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-calendar-dates-mobile .vector-nzNou6 {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-calendar-dates-mobile .vector-nzuIpS {
  background-color: transparent;
  height: 2px;
  left: 17px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-oafYiW {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-oez9Pb {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-calendar-dates-mobile .vector-pummtu {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-pxIogv {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-qGye3v {
  background-color: transparent;
  height: 24px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 45px;
}
.teacher-calendar-dates-mobile .vector-qpftC5 {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 6px;
}
.teacher-calendar-dates-mobile .vector-r0opiw {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 12px;
}
.teacher-calendar-dates-mobile .vector-rGRikn {
  background-color: transparent;
  height: 25px;
  left: -0px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-calendar-dates-mobile .vector-rXxPBk {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-calendar-dates-mobile .vector-reGMLO {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-sku7Fc {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-tQwNUN {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-calendar-dates-mobile .vector-toBf7r {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 26px;
}
.teacher-calendar-dates-mobile .vector-tprfVp {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-utKR0x {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-vPuJH9 {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-calendar-dates-mobile .vector-x2R7zB {
  background-color: transparent;
  height: 24px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 12px;
}
.teacher-calendar-dates-mobile .vector-x5Jnp7 {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-calendar-dates-mobile .vector-xB1UPi {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-calendar-dates-mobile .vector-xJcTLo {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-calendar-dates-mobile .vector-xL5OUN {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 8px;
}
.teacher-calendar-dates-mobile .vector-xMrRfb {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-calendar-dates-mobile .vector-xOJZuX {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-xQdiT6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-xhepvl {
  background-color: transparent;
  height: 2px;
  left: 17px;
  position: absolute;
  top: 9px;
  width: 1px;
}
.teacher-calendar-dates-mobile .vector-xhwx9b {
  background-color: transparent;
  height: 3px;
  left: 31px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-xtOtSY {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-calendar-dates-mobile .vector-xvjzYd {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-calendar-dates-mobile .vector-ymbxcA {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 11px;
}
.teacher-calendar-dates-mobile .vector-zXuwwt {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-calendar-dates-mobile .vector-zeZVsH {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-calendar-dates-mobile .vector-zxfIi7 {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-calendar-dates-mobile .view-all-rFX9Nh {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 296px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: 75px;
}
.teacher-calendar-dates-mobile .view-forum-CN8bZ4 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 251px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 235px;
  width: 113px;
}
.teacher-calendar-dates-mobile .view-forum-CNpLuU {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 251px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 169px;
  width: 113px;
}
.teacher-calendar-dates-mobile .view-forum-rFX9Nh {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 251px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 103px;
  width: 113px;
}
.teacher-calendar-dates-mobile .wed-C61RwL {
  background-color: transparent;
  height: auto;
  left: 138px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 620px;
  width: 48px;
}
.teacher-calendar-dates-mobile .what-is-the-si-unit-of-current-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 74px;
  width: 289px;
}
.teacher-calendar-dates-mobile .what-is-the-sol-f-this-question-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 140px;
  width: 289px;
}
.teacher-calendar-dates-mobile .x1-C61RwL {
  background-color: transparent;
  height: auto;
  left: 261px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 670px;
  width: 8px;
}
.teacher-calendar-dates-mobile .x10-C61RwL {
  background-color: transparent;
  height: auto;
  left: 365px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 718px;
  width: 31px;
}
.teacher-calendar-dates-mobile .x11-C61RwL {
  background-color: transparent;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 766px;
  width: 22px;
}
.teacher-calendar-dates-mobile .x12-C61RwL {
  background-color: transparent;
  height: auto;
  left: 86px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 766px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x13-C61RwL {
  background-color: transparent;
  height: auto;
  left: 143px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 766px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x14-C61RwL {
  background-color: transparent;
  color: var(--wild-sand);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 197px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 766px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x15-C61RwL {
  background-color: transparent;
  height: auto;
  left: 253px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 766px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x16-C61RwL {
  background-color: transparent;
  height: auto;
  left: 309px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 766px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x17-C61RwL {
  background-color: transparent;
  height: auto;
  left: 365px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 766px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x18-C61RwL {
  background-color: transparent;
  height: auto;
  left: 29px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 814px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x19-C61RwL {
  background-color: transparent;
  height: auto;
  left: 86px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 814px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x2-C61RwL {
  background-color: transparent;
  height: auto;
  left: 317px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 670px;
  width: 11px;
}
.teacher-calendar-dates-mobile .x20-C61RwL {
  background-color: transparent;
  height: auto;
  left: 142px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 814px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x21-C61RwL {
  background-color: transparent;
  height: auto;
  left: 197px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 814px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x22-C61RwL {
  background-color: transparent;
  height: auto;
  left: 253px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 814px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x23-C61RwL {
  background-color: transparent;
  height: auto;
  left: 309px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 814px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x24-C61RwL {
  background-color: transparent;
  height: auto;
  left: 365px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 814px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x25-C61RwL {
  background-color: transparent;
  height: auto;
  left: 30px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 862px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x26-C61RwL {
  background-color: transparent;
  height: auto;
  left: 86px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 862px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x27-C61RwL {
  background-color: transparent;
  height: auto;
  left: 142px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 862px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x28-C61RwL {
  background-color: transparent;
  height: auto;
  left: 197px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 862px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x28-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 29px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 670px;
  width: 26px;
}
.teacher-calendar-dates-mobile .x29-C61RwL {
  background-color: transparent;
  height: auto;
  left: 253px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 862px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x29-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 85px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 670px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x3-C61RwL {
  background-color: transparent;
  height: auto;
  left: 373px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 670px;
  width: 11px;
}
.teacher-calendar-dates-mobile .x30-C61RwL {
  background-color: transparent;
  height: auto;
  left: 309px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 862px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x30-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 141px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 670px;
  width: 35px;
}
.teacher-calendar-dates-mobile .x31-C61RwL {
  background-color: transparent;
  height: auto;
  left: 365px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 862px;
  width: 30px;
}
.teacher-calendar-dates-mobile .x31-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 197px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 670px;
  width: 29px;
}
.teacher-calendar-dates-mobile .x4-C61RwL {
  background-color: transparent;
  height: auto;
  left: 35px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 718px;
  width: 12px;
}
.teacher-calendar-dates-mobile .x4-i650262853103-KyDRqz {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 46px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 64px;
  width: auto;
}
.teacher-calendar-dates-mobile .x4-i650262953103-xVxRxD {
  background-color: transparent;
  color: rgba(5, 187, 138, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 52px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 64px;
  width: auto;
}
.teacher-calendar-dates-mobile .x4-i650263053103-s8ffFt {
  align-self: center;
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 1.43px;
  margin-top: 19px;
  min-height: 68px;
  min-width: 68px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-calendar-dates-mobile .x4-i650263153103-87a2V4 {
  align-self: center;
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 1.43px;
  margin-top: 19px;
  min-height: 68px;
  min-width: 56px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-calendar-dates-mobile .x5-C61RwL {
  background-color: transparent;
  height: auto;
  left: 93px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 718px;
  width: 11px;
}
.teacher-calendar-dates-mobile .x6-C61RwL {
  background-color: transparent;
  height: auto;
  left: 152px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 718px;
  width: 11px;
}
.teacher-calendar-dates-mobile .x7-C61RwL {
  background-color: transparent;
  height: auto;
  left: 207px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 718px;
  width: 11px;
}
.teacher-calendar-dates-mobile .x8-C61RwL {
  background-color: transparent;
  height: auto;
  left: 261px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 718px;
  width: 12px;
}
.teacher-calendar-dates-mobile .x9-C61RwL {
  background-color: transparent;
  height: auto;
  left: 317px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 718px;
  width: 11px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --nobel: rgba(179, 179, 179, 1);
  --white: rgba(255, 255, 255, 1);
  --wild-sand: rgba(244, 244, 244, 1);
}
/* screen - teacher-date-calendar */

.montserrat-bold-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.montserrat-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-nobel-16px {
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-nobel-20px {
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-64px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-20px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-24px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.teacher-date-calendar {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.teacher-date-calendar .accountbalance-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-date-calendar .analytics-24px-3-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-date-calendar .assignment-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-date-calendar .biba-sobti-h8V8ZR {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-date-calendar .bibasobtigmailcom-h8V8ZR {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-date-calendar .choose-date-for-opening-slots-Fjgz1C {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 40px;
  min-height: 36px;
  position: relative;
  text-align: left;
  width: 478px;
}
.teacher-date-calendar .component-1-GyJUNH {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.teacher-date-calendar .component-4-tpPGFo {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 194px;
  padding: 25px 21.1px;
  position: absolute;
  top: 0px;
  width: 158px;
}
.teacher-date-calendar .component-4-xRyHXX {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 194px;
  padding: 25px 21.1px;
  position: relative;
  width: 158px;
}

.teacher-date-calendar .component-9-xRyHXX {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 194px;
  padding: 25px 21.1px;
  position: relative;
  width: 158px;
}
.teacher-date-calendar .component-5-tpPGFo {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 194px;
  padding: 25px 21.1px;
  position: absolute;
  top: 0px;
  width: 158px;
}
.teacher-date-calendar .component-5-xRyHXX {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 194px;
  padding: 25px 21.1px;
  position: relative;
  width: 158px;
}
.teacher-date-calendar .component-6-xRyHXX {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 194px;
  padding: 25px 21.1px;
  position: relative;
  width: 158px;
}
.teacher-date-calendar .component-7-xRyHXX {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 24px;
  min-height: 194px;
  padding: 25px 5.1px;
  position: relative;
  width: 158px;
}
.teacher-date-calendar .description-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  position: relative;
  width: 24px;
}
.teacher-date-calendar .doubt-forum-kWMJYE {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 38px;
  position: relative;
  text-align: left;
  width: 165px;
}
.teacher-date-calendar .ellipse-2-RYyMFx {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.teacher-date-calendar .ellipse-2-hjNn7h {
  background-color: transparent;
  height: 8px;
  left: 15px;
  position: absolute;
  top: 27px;
  width: 8px;
}
.teacher-date-calendar .ellipse-3-HmczKw {
  background-color: transparent;
  height: 45px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 44px;
}
.teacher-date-calendar .feb-2021-gI0kab {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 39px;
  position: relative;
  width: 142px;
}
.teacher-date-calendar .flex-col-2HqSz2 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 435px;
  position: relative;
  width: 163px;
}
.teacher-date-calendar .flex-col-2uH8vm {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 3.14px;
  margin-left: 50px;
  min-height: 361px;
  position: relative;
  width: 54px;
}
.teacher-date-calendar .flex-col-C61RwL {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  width: 90%;
  margin-top: 45px;
  min-height: 881px;
  position: relative;
  left: 193px;
}
.teacher-date-calendar .flex-col-IrNDxV {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 3.51px;
  margin-left: 50px;
  min-height: 361px;
  position: relative;
  width: 65px;
}
.teacher-date-calendar .flex-col-L5w8ri {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 3.51px;
  margin-left: 47px;
  min-height: 361px;
  position: relative;
  width: 39px;
}
.teacher-date-calendar .flex-col-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  height: 734px;
  position: relative;
  width: 90%;
}
.teacher-date-calendar .flex-col-SHV8jQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 3.51px;
  margin-left: 47px;
  min-height: 361px;
  position: relative;
  width: 48px;
}
.teacher-date-calendar .flex-col-YXZao0 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 206px;
  position: relative;
  width: 45px;
}
.teacher-date-calendar .flex-col-cNcdMy {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 10px;
  min-height: 236px;
  position: relative;
  width: 306px;
}
.teacher-date-calendar .flex-col-cxxBQR {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 50px;
  min-height: 365px;
  position: relative;
  width: 51px;
}
.teacher-date-calendar .flex-col-ifokZ5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 107px;
  position: relative;
  width: 373px;
}
.teacher-date-calendar .flex-col-m0tnSy {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 361px;
  position: relative;
  width: 62px;
}
.teacher-date-calendar .flex-col-pn1t1C {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 3.51px;
  margin-left: 46px;
  min-height: 361px;
  position: relative;
  width: 56px;
}
.teacher-date-calendar .flex-col-vgli2x {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.teacher-date-calendar .flex-row-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 105px;
  justify-content: space-between;
  width: 90%;
}
.teacher-date-calendar .flex-row-2uH8vm {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 435px;
  justify-content: flex-start;
  margin-bottom: -0.33px;
  min-width: 565px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .flex-row-8LpBgd {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 38px;
  justify-content: flex-start;
  margin-left: 2.95px;
  min-width: 370px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .flex-row-WcGkdi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .flex-row-gI0kab {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 365px;
  justify-content: flex-start;
  margin-top: 31px;
  min-width: 163px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .flex-row-ifokZ5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 236px;
  justify-content: flex-start;
  margin-top: 20px;
  min-width: 362px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .flex-row-oV5UrE {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 734px;
  justify-content: flex-end;
  margin-top: 42px;
  position: relative;
  width: 100%;
}
.teacher-date-calendar .flex-row-xSwPjz {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 35px;
  justify-content: flex-start;
  margin-top: 7px;
  min-width: 341px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .fri-Kv35Vm {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 2.67px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 36px;
}
.teacher-date-calendar .group-0MwMsW {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-date-calendar .group-0noJTG {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-date-calendar .group-108-tpPGFo {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 194px;
  justify-content: flex-start;
  left: 0px;
  position: absolute;
  top: 0px;
  width: auto;
}

.teacher-date-calendar .group-card-1 {
  height: 194px;
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}

.teacher-date-calendar .group-card-2 {
  height: 194px;
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}

.teacher-date-calendar .group-109-osnVng {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 12px;
  min-height: 363px;
  position: absolute;
  top: 19px;
  width: 391px;
}
.teacher-date-calendar .group-110-ibeLuz {
  background-color: transparent;
  height: 417px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.teacher-date-calendar .group-160-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 88px;
  min-width: 24px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-162-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 778px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.teacher-date-calendar .group-163-L8UHM8 {
  background-color: #1f2531;
  height: 1024px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 63px;
}
.teacher-date-calendar .group-164-L8UHM8 {
  background-color: transparent;
  height: 778px;
  left: 20px;
  position: absolute;
  top: 200px;
  width: 24px;
}
.teacher-date-calendar .group-165-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 1024px;
  position: relative;
  width: 63px;
}
.teacher-date-calendar .group-22-GyJUNH {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  margin-top: 6px;
  min-width: 316px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-23-PXwdVf {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 434px;
  justify-content: flex-start;
  left: 0px;
  min-width: 754px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-date-calendar .group-2DCOK5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-2aT3pW {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-3yhmFW {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 37px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-6VaUSp {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 11px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-date-calendar .group-6fEsqp {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-7K6Ftm {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-7xD0wZ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-date-calendar .group-818PcY {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 28px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-9Awfnt {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-9VbO77 {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  position: relative;
  width: 20px;
}
.teacher-date-calendar .group-AfGGUp {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-Ax2sEx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-DZZSPe {
  background-color: transparent;
  height: 25px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-date-calendar .group-EnJ7Ag {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 45px;
}
.teacher-date-calendar .group-F6IzAk {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-date-calendar .group-FHHSuW {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-date-calendar .group-FNo6OK {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-Fi0kih {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-date-calendar .group-GMqgeu {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 25px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-H84AaM {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-Hmxxti {
  background-color: transparent;
  height: 25px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-date-calendar .group-JesRfB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-KaIGeA {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 45px;
}
.teacher-date-calendar .group-LkGb3H {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 11px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-date-calendar .group-M8orKX {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 37px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-NAUvQq {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-date-calendar .group-Nrxux9 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-OFFAUJ {
  background-color: transparent;
  height: 25px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-date-calendar .group-OYM0pi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-Pe8YqM {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 25px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-QN8j06 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-QQutKq {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-date-calendar .group-TO9bVr {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 28px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-TcMc9a {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 37px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-UKbWOd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.teacher-date-calendar .group-Wq9p3U {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 25px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-X5Buk7 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-XdP8x8 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-Z5NZ6I {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 25px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-Zfhbs7 {
  background-color: transparent;
  height: 25px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-date-calendar .group-Zh8ovx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-date-calendar .group-aVkVMZ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-date-calendar .group-acSz5R {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-alxHTq {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-date-calendar .group-cr11rD {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-date-calendar .group-drnUQd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-e7bVof {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-eFq9MW {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-eQ6afi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-gFEPeF {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-hzgSv9 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 11px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-date-calendar .group-jKgQIv {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-jMXwwN {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 11px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-date-calendar .group-jbVVGK {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-date-calendar .group-kxoVjy {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-kxzEdu {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-lpXGDF {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-lxcIer {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 45px;
}
.teacher-date-calendar .group-m7Hr1i {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-date-calendar .group-mxAj4U {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-mxPxxt {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-oXUKwx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: flex-start;
  left: 0px;
  min-width: 20px;
  position: absolute;
  top: 448px;
  width: auto;
}
.teacher-date-calendar .group-ohTPWK {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-pVT1g5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-date-calendar .group-q5W9I4 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-r5tEVY {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 37px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-rUUXfv {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 28px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-rkqGZN {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 45px;
}
.teacher-date-calendar .group-ryoVAE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-tQmilE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.teacher-date-calendar .group-tf1cjm {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-date-calendar .group-u9YpNt {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-xDmLj1 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 28px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-date-calendar .group-xOmSrh {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-xRgTks {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-date-calendar .group-xlfqZD {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-xrhlQr {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-date-calendar .group-xuqLOv {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-date-calendar .group-ygyB1s {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-date-calendar .help-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-date-calendar .historytoggleoff-24px-1-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-date-calendar .how-to-solve-this-sUKexE {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 40px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 285px;
}
.teacher-date-calendar .join-4-yG0ewK {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 35px;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0.8px 0;
  position: relative;
  width: 45px;
}
.teacher-date-calendar .join-5-puFVGm {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 35px;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0.8px 0;
  position: relative;
  width: 45px;
}
.teacher-date-calendar .join-6-puFVGm {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 35px;
  justify-content: flex-start;
  margin-left: 0px;
  margin-top: 52px;
  overflow: hidden;
  padding: 0.8px 0;
  position: relative;
  width: 45px;
}
.teacher-date-calendar .join-7-puFVGm {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 35px;
  justify-content: flex-start;
  margin-left: 0px;
  margin-top: 49px;
  overflow: hidden;
  padding: 0.8px 0;
  position: relative;
  width: 45px;
}
.teacher-date-calendar .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.teacher-date-calendar .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.teacher-date-calendar .mon-DXimwk {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 62px;
}
.teacher-date-calendar .next-month-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 503px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 5px;
  width: auto;
}
.teacher-date-calendar .notifications-24px-2-1-GyJUNH {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-bottom: 13px;
  margin-left: 641px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.teacher-date-calendar .overlap-group-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 434px;
  margin-left: 1px;
  margin-top: 30px;
  position: relative;
  width: 754px;
}
.teacher-date-calendar .overlap-group1-QkTteQ {
  background-color: transparent;
  flex-shrink: 1;
  height: 417px;
  margin-bottom: 107.34px;
  margin-left: 29px;
  position: relative;
  width: 383px;
}
.teacher-date-calendar .overlap-group2-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 194px;
  position: relative;
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: flex-start;
}
.teacher-date-calendar .overlap-group3-As8JWv {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-right: 8.96px;
  margin-top: 38px;
  position: relative;
  width: 33px;
}
.teacher-date-calendar .overlap-group4-oCqZvM {
  align-self: flex-start;
  background-color: transparent;
  flex-shrink: 1;
  height: 45px;
  margin-top: 31px;
  position: relative;
  width: 44px;
}
.teacher-date-calendar .please-check-my-approach-sUKexE {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 2.95px;
  margin-top: 28px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 285px;
}
.teacher-date-calendar .rectangle-43-osnVng {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 417px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 380px;
}
.teacher-date-calendar .rectangle-7-PXwdVf {
  background-color: transparent;
  height: 1px;
  left: 455px;
  position: absolute;
  top: 139px;
  width: 1px;
}
.teacher-date-calendar .sat-irHcBx {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 2.67px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 45px;
}
.teacher-date-calendar .school-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  position: relative;
  width: 24px;
}
.teacher-date-calendar .sections-teachi-g-i650231553102-LDUMpT {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 114px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-date-calendar .sections-teachi-g-i650231653102-7m8xJk {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 145px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-date-calendar .sections-teachi-g-i650231753102-wsCHL7 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  width: auto;
}
.teacher-date-calendar .sections-teachi-g-i650231853102-rcScQn {
  background-color: transparent;
  height: auto;
  left: 7px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  width: auto;
}
.teacher-date-calendar .sections-teachi-g-i650231953102-JfGNqo {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 114px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-date-calendar .sections-teachi-g-i650232053102-0RoRVJ {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 114px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-date-calendar .span0-slaq6m {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
}
.teacher-date-calendar .span1-slaq6m {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
  font-weight: 400;
}
.teacher-date-calendar .stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-date-calendar .sun-uREHIf {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 54px;
}
.teacher-date-calendar .thu-oCqZvM {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 3.75px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 53px;
}
.teacher-date-calendar .tue-As8JWv {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 51px;
}
.teacher-date-calendar .vector-0128Hi {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-date-calendar .vector-05Z8um {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-date-calendar .vector-0MwMsW {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 5px;
}
.teacher-date-calendar .vector-0aSbXg {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-date-calendar .vector-0noJTG {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-date-calendar .vector-0rkFx2 {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 10px;
}
.teacher-date-calendar .vector-1FEkcw {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-date-calendar .vector-1r2wRo {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-date-calendar .vector-2AzRuS {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-date-calendar .vector-2DCOK5 {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 8px;
}
.teacher-date-calendar .vector-2FzNFd {
  background-color: transparent;
  height: 2px;
  left: 16px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-date-calendar .vector-2Sfryx {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-date-calendar .vector-2aT3pW {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 25px;
}
.teacher-date-calendar .vector-3ahjCQ {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-date-calendar .vector-3yhmFW {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-date-calendar .vector-40xw7g {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.teacher-date-calendar .vector-4boi7B {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 3px;
}
.teacher-date-calendar .vector-4u3EoS {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-date-calendar .vector-53Uszj {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-date-calendar .vector-56AgMz {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.teacher-date-calendar .vector-5ZxRCM {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-5fgTxT {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-5qdw01 {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 3px;
}
.teacher-date-calendar .vector-655IBj {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-date-calendar .vector-6VaUSp {
  background-color: transparent;
  height: 3px;
  left: 30px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-date-calendar .vector-6fEsqp {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-date-calendar .vector-7K6Ftm {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 11px;
}
.teacher-date-calendar .vector-7oQ8ZY {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-7xD0wZ {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-date-calendar .vector-818PcY {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 11px;
}
.teacher-date-calendar .vector-8fu9vL {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-8hRnLY {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-date-calendar .vector-8k85HE {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 170px;
  position: relative;
  width: 24px;
}
.teacher-date-calendar .vector-90KxxC {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-date-calendar .vector-98pnx9 {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 13px;
}
.teacher-date-calendar .vector-9Awfnt {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 8px;
}
.teacher-date-calendar .vector-9YPDDi {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-date-calendar .vector-9bna7A {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-date-calendar .vector-9cSzAv {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-date-calendar .vector-9iSnQB {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-date-calendar .vector-9xgjqN {
  background-color: transparent;
  height: 14px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.teacher-date-calendar .vector-AfGGUp {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-date-calendar .vector-B8xRgA {
  background-color: transparent;
  height: 16px;
  left: 0px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-date-calendar .vector-BKwYFx {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-CLPHBl {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-date-calendar .vector-Cb4fip {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-date-calendar .vector-CpJPex {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-date-calendar .vector-DFvx2T {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 10px;
}
.teacher-date-calendar .vector-DZZSPe {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-date-calendar .vector-EaMiW0 {
  background-color: transparent;
  height: 20px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 18px;
}
.teacher-date-calendar .vector-F2zNuN {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 10px;
}
.teacher-date-calendar .vector-F6IzAk {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-date-calendar .vector-FNo6OK {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-date-calendar .vector-Fi0kih {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-date-calendar .vector-G90Aft {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-date-calendar .vector-GMqgeu {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 43px;
}
.teacher-date-calendar .vector-GrSAQq {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-date-calendar .vector-H84AaM {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-date-calendar .vector-HXxNRG {
  background-color: transparent;
  height: 25px;
  left: -1px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-date-calendar .vector-HgBZq1 {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 1px;
}
.teacher-date-calendar .vector-Hmxxti {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-date-calendar .vector-ITYbuh {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-date-calendar .vector-J9li6P {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-date-calendar .vector-JbyGxB {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 13px;
}
.teacher-date-calendar .vector-KNYdf7 {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-date-calendar .vector-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-date-calendar .vector-Ld7TFB {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-date-calendar .vector-LkGb3H {
  background-color: transparent;
  height: 3px;
  left: 30px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-date-calendar .vector-LwGpKI {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 13px;
}
.teacher-date-calendar .vector-Lzn3Ax {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-date-calendar .vector-LzooRC {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-date-calendar .vector-M1xsyG {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-date-calendar .vector-M8orKX {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-date-calendar .vector-MPeuv5 {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-date-calendar .vector-NAUvQq {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 5px;
}
.teacher-date-calendar .vector-Nk0z4r {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-date-calendar .vector-Nrxux9 {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-date-calendar .vector-O1EeiU {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-date-calendar .vector-OFFAUJ {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-date-calendar .vector-OYM0pi {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-date-calendar .vector-Oyn7l7 {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-date-calendar .vector-Pe8YqM {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 43px;
}
.teacher-date-calendar .vector-PzpJjl {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-date-calendar .vector-QN8j06 {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 8px;
}
.teacher-date-calendar .vector-QNjvhW {
  background-color: transparent;
  height: 25px;
  left: -1px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-date-calendar .vector-QQutKq {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 5px;
}
.teacher-date-calendar .vector-R1UB6y {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-date-calendar .vector-RAXgCU {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-date-calendar .vector-RSGd0R {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-date-calendar .vector-RYyMFx {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.teacher-date-calendar .vector-SCg0eG {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  position: relative;
  width: 24px;
}
.teacher-date-calendar .vector-SIAzDu {
  background-color: transparent;
  height: 20px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 16px;
}
.teacher-date-calendar .vector-SKX31i {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-Svsuax {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-date-calendar .vector-TCNT1O {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-date-calendar .vector-TO9bVr {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 11px;
}
.teacher-date-calendar .vector-TRInqs {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-date-calendar .vector-TW2xB1 {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 6px;
}
.teacher-date-calendar .vector-TcMc9a {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-date-calendar .vector-UgkvXI {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-date-calendar .vector-VC0Nk7 {
  background-color: transparent;
  height: 2px;
  left: 16px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-date-calendar .vector-VJDcue {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-date-calendar .vector-W5f1QN {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-WDVEJx {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-date-calendar .vector-WcGkdi {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.teacher-date-calendar .vector-WkCx0q {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-date-calendar .vector-Wq9p3U {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 43px;
}
.teacher-date-calendar .vector-WrYwzQ {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 3px;
}
.teacher-date-calendar .vector-X5Buk7 {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-date-calendar .vector-XV2kdn {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.teacher-date-calendar .vector-XcYa8E {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-date-calendar .vector-XdP8x8 {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-date-calendar .vector-Y113xM {
  background-color: transparent;
  height: 25px;
  left: -1px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-date-calendar .vector-YEcgPt {
  background-color: transparent;
  height: 2px;
  left: 10px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-date-calendar .vector-YQ2MuZ {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 1px;
}
.teacher-date-calendar .vector-YU8xRt {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-date-calendar .vector-Yn07kQ {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-date-calendar .vector-Yptxyd {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-date-calendar .vector-Z5NZ6I {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 43px;
}
.teacher-date-calendar .vector-ZENYyP {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-date-calendar .vector-ZIZ7qx {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-date-calendar .vector-ZeITGT {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-Zfhbs7 {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-date-calendar .vector-Zh8ovx {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 5px;
}
.teacher-date-calendar .vector-Zrpz4Q {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-date-calendar .vector-ZudypZ {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-date-calendar .vector-aVkVMZ {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-date-calendar .vector-aWzdE4 {
  background-color: transparent;
  height: 2px;
  left: 10px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-date-calendar .vector-acSz5R {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 8px;
}
.teacher-date-calendar .vector-ad4cdW {
  background-color: transparent;
  height: 2px;
  left: 10px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-date-calendar .vector-awZXJC {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-date-calendar .vector-bpF9Ps {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-date-calendar .vector-bpuydN {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 1px;
}
.teacher-date-calendar .vector-cr11rD {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-date-calendar .vector-dlRKJA {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-date-calendar .vector-dlhgNl {
  background-color: transparent;
  height: 25px;
  left: -1px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-date-calendar .vector-drnUQd {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-date-calendar .vector-e7bVof {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 25px;
}
.teacher-date-calendar .vector-f3xGHq {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-date-calendar .vector-fPPgx3 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-date-calendar .vector-g9bP8Z {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 6px;
}
.teacher-date-calendar .vector-gcadlU {
  background-color: transparent;
  height: 2px;
  left: 16px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-date-calendar .vector-grLYV9 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-date-calendar .vector-guPYiL {
  background-color: transparent;
  height: 2px;
  left: 16px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-date-calendar .vector-hECENF {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-date-calendar .vector-haExoU {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-date-calendar .vector-hmCphG {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-hzgSv9 {
  background-color: transparent;
  height: 3px;
  left: 30px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-date-calendar .vector-iniHBi {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-date-calendar .vector-isZOGx {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 6px;
}
.teacher-date-calendar .vector-j156G8 {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-date-calendar .vector-jKgQIv {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 11px;
}
.teacher-date-calendar .vector-jMXwwN {
  background-color: transparent;
  height: 3px;
  left: 30px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-date-calendar .vector-jWA1w5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.teacher-date-calendar .vector-jb9azt {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-date-calendar .vector-jbVVGK {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-date-calendar .vector-kCLhu1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-date-calendar .vector-kNhyzJ {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-date-calendar .vector-kk8EIE {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-date-calendar .vector-kxigIt {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-kxoVjy {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 11px;
}
.teacher-date-calendar .vector-lpXGDF {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 25px;
}
.teacher-date-calendar .vector-m7Hr1i {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-date-calendar .vector-mLTNAX {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-date-calendar .vector-mju0OS {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-date-calendar .vector-mxAj4U {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-date-calendar .vector-nAOx8z {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 10px;
}
.teacher-date-calendar .vector-nrMwSG {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-date-calendar .vector-o19yaP {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 13px;
}
.teacher-date-calendar .vector-o1uZkn {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-date-calendar .vector-oRqxWx {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-date-calendar .vector-oXUKwx {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 25px;
}
.teacher-date-calendar .vector-ohTPWK {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-date-calendar .vector-oigITL {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-date-calendar .vector-oxOpIl {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-date-calendar .vector-pVT1g5 {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-date-calendar .vector-pm07X8 {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 6px;
}
.teacher-date-calendar .vector-q3Qapb {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-date-calendar .vector-q5W9I4 {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-date-calendar .vector-qxc4BL {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-r5tEVY {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-date-calendar .vector-r9Udky {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-date-calendar .vector-rMJikR {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-date-calendar .vector-rUUXfv {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 11px;
}
.teacher-date-calendar .vector-rdztpa {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-date-calendar .vector-rxBx6y {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-date-calendar .vector-s2WRpc {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 1px;
}
.teacher-date-calendar .vector-s2Y5x6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-s88dHZ {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-date-calendar .vector-sPk8tv {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-date-calendar .vector-tFsXF5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-date-calendar .vector-tf1cjm {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-date-calendar .vector-txvJzZ {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-date-calendar .vector-uIIHmN {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}

.teacher-date-calendar .historytoggleoff-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-date-calendar .quiz-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-date-calendar .poweroff-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
  margin-top: 100px;
}

.teacher-date-calendar .vector-uckeyu {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 3px;
}
.teacher-date-calendar .vector-vXKkDg {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-vgli2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.teacher-date-calendar .vector-wBWGOE {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-date-calendar .vector-weRA3u {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-date-calendar .vector-wlyf1q {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-date-calendar .vector-ww0L0I {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-date-calendar .vector-xDmLj1 {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 11px;
}
.teacher-date-calendar .vector-xDv0Cl {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-date-calendar .vector-xRgTks {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-date-calendar .vector-xT42DS {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 3px;
}
.teacher-date-calendar .vector-xVCbTi {
  background-color: transparent;
  height: 2px;
  left: 10px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-date-calendar .vector-xWEyTT {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-date-calendar .vector-xbu9jm {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.teacher-date-calendar .vector-xhNnqm {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-date-calendar .vector-xuqLOv {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 11px;
}
.teacher-date-calendar .vector-yMPOlA {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-date-calendar .vector-yQhZ26 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-date-calendar .vector-ygyB1s {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-date-calendar .view-all-kWMJYE {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-bottom: 2px;
  margin-left: 131px;
  min-height: 29px;
  position: relative;
  text-align: left;
  width: 74px;
}
.teacher-date-calendar .view-forum-1V41qS {
  align-self: flex-end;
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 8px;
  min-height: 25px;
  position: relative;
  text-align: left;
  width: 111px;
}
.teacher-date-calendar .view-forum-8LpBgd {
  align-self: flex-end;
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 13.75px;
  margin-top: 2px;
  min-height: 25px;
  position: relative;
  text-align: left;
  width: 111px;
}
.teacher-date-calendar .view-forum-Y0Gp4F {
  align-self: flex-end;
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2.95px;
  margin-top: 8px;
  min-height: 25px;
  position: relative;
  text-align: left;
  width: 111px;
}
.teacher-date-calendar .view-forum-sUKexE {
  align-self: flex-end;
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2.95px;
  margin-top: 8px;
  min-height: 25px;
  position: relative;
  text-align: left;
  width: 111px;
}
.teacher-date-calendar .wed-MMz0cz {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 65px;
}
.teacher-date-calendar .what-is-the-si-unit-of-current-yG0ewK {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 10px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 285px;
}
.teacher-date-calendar .what-is-the-sol-f-this-question-sUKexE {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 285px;
}
.teacher-date-calendar .x1-Kv35Vm {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.1px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 13px;
}
.teacher-date-calendar .x10-uREHIf {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12.59px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 29px;
}
.teacher-date-calendar .x11-DXimwk {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.96px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 22px;
}
.teacher-date-calendar .x12-As8JWv {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 10.77px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.teacher-date-calendar .x13-MMz0cz {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 14.4px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.teacher-date-calendar .x14-HmczKw {
  background-color: transparent;
  color: var(--wild-sand);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 29px;
}
.teacher-date-calendar .x14-ibeLuz {
  background-color: transparent;
  color: var(--wild-sand);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 208px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 153px;
  width: auto;
}
.teacher-date-calendar .x15-Kv35Vm {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.1px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.teacher-date-calendar .x16-irHcBx {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 3.62px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.teacher-date-calendar .x17-uREHIf {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 6.38px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.teacher-date-calendar .x18-DXimwk {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.96px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 29px;
}
.teacher-date-calendar .x19-As8JWv {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 10.77px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.teacher-date-calendar .x2-irHcBx {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 11.72px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 18px;
}
.teacher-date-calendar .x20-MMz0cz {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 14.4px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 35px;
}
.teacher-date-calendar .x21-oCqZvM {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12.46px;
  margin-top: 31px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.teacher-date-calendar .x22-Kv35Vm {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 6.29px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 33px;
}
.teacher-date-calendar .x23-irHcBx {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 1.81px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 33px;
}
.teacher-date-calendar .x24-uREHIf {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 2.76px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 35px;
}
.teacher-date-calendar .x25-DXimwk {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.96px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 33px;
}
.teacher-date-calendar .x26-hjNn7h {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0px;
  width: 33px;
}
.teacher-date-calendar .x27-MMz0cz {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12.59px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 33px;
}
.teacher-date-calendar .x28-DXimwk {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 3.53px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 35px;
}
.teacher-date-calendar .x28-oCqZvM {
  align-self: flex-start;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 4.7px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 35px;
}
.teacher-date-calendar .x29-As8JWv {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 5.34px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 33px;
}
.teacher-date-calendar .x29-Kv35Vm {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 6.29px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 33px;
}
.teacher-date-calendar .x3-uREHIf {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12.59px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 18px;
}
.teacher-date-calendar .x30-MMz0cz {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 7.15px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 35px;
}
.teacher-date-calendar .x30-irHcBx {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 35px;
}
.teacher-date-calendar .x31-oCqZvM {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 5.21px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.teacher-date-calendar .x31-uREHIf {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 6.5px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.teacher-date-calendar .x4-DXimwk {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 10.78px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 20px;
}
.teacher-date-calendar .x4-i650231553103-LDUMpT {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 18.23px;
  margin-top: 25px;
  min-height: 78px;
  min-width: 77px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-date-calendar .x4-i650231653103-7m8xJk {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 2.43px;
  margin-top: 25px;
  min-height: 78px;
  min-width: 77px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-date-calendar .x4-i650231753103-wsCHL7 {
  background-color: transparent;
  height: auto;
  left: 42px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 79px;
  width: auto;
}
.teacher-date-calendar .x4-i650231853103-rcScQn {
  background-color: transparent;
  color: rgba(5, 187, 138, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 79px;
  width: auto;
}
.teacher-date-calendar .x4-i650231953103-JfGNqo {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 18.23px;
  margin-top: 25px;
  min-height: 78px;
  min-width: 77px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-date-calendar .x4-i650232053103-0RoRVJ {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 18.23px;
  margin-top: 25px;
  min-height: 78px;
  min-width: 77px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-date-calendar .x5-As8JWv {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.96px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 18px;
}
.teacher-date-calendar .x6-MMz0cz {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12.59px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 18px;
}
.teacher-date-calendar .x7-oCqZvM {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 10.64px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 18px;
}
.teacher-date-calendar .x8-Kv35Vm {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.1px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 20px;
}
.teacher-date-calendar .x9-irHcBx {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 11.72px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 18px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --nobel: rgba(179, 179, 179, 1);
  --white: rgba(255, 255, 255, 1);
  --wild-sand: rgba(244, 244, 244, 1);
}
@media screen and (max-width: 599px) {
  .teacher-date-calendar {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .teacher-calendar-dates-mobile {
    display: none;
  }
}

.sider-teacher-general-div {
  width: 63px;
  height: 100vh;
  z-index: 200;

  position: fixed;
  top: 0px;
  left: 0px;
  overflow-y: auto;
  background-color: #212a3b;
}

.sider-teacher-general-div .sider-teacher-in-general-80 {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: #212a3b;
}

@media screen and (max-width: 600px) {
  .sider-teacher-Notes {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .teacher-date-calendar .overlap-group2-Fjgz1C {
    flex-direction: column;
    height: auto;
  }
  .teacher-date-calendar .flex-col-C61RwL {
    min-height: 1040px;
  }
  .teacher-date-calendar .group-card-1 {
    width: 80%;
  }
  .teacher-date-calendar .group-card-2 {
    margin-top: 30px;
    width: 80%;
  }
}