.rectangle-24-C61RwL {
  background-color: #1f2531;
  height: 1132px;
  position: absolute;
  top: 0px;
  width: 63px;
}
.vector-C61RwL {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 146.87%;
  width: 1.67%;
}
.vector-VMr6Om {
  background-color: transparent;
  height: 0px;
  left: 24px;
  position: absolute;
  top: 49.59%;
  width: 0px;
}
.vector-mzXdH9 {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 66.61%;
  width: 1.67%;
}
.vector-QxM5SU {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 31.74%;
  width: 1.67%;
}
.vector-2P4qUJ {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 19.51%;
  width: 1.67%;
}
.vector-J1YQmd {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 79.84%;
  width: 1.67%;
}
.vector-HlKRTo {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 44.06%;
  width: 1.67%;
}
.group-VMr6Om {
  background-color: transparent;
  height: 24px;
  left: 24px;
  position: absolute;
  top: 879px;
  width: 24px;
}
.group-XSCsvl {
  background-color: transparent;
  height: 2.16%;
  left: 2px;
  position: absolute;
  top: -280.16%;
  width: 1.67%;
}
.vector-VkPPtx {
  background-color: transparent;
  height: 0px;
  left: 24px;
  position: absolute;
  top: 64.04%;
  width: 0px;
}
.vector-YWzA7M {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 92.16%;
  width: 1.67%;
}
.group-mzXdH9 {
  background-color: transparent;
  height: 1.77%;
  left: 24px;
  position: absolute;
  top: 55.21%;
  width: 1.39%;
}
.stickynote2-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 24px;
  overflow: hidden;
  position: absolute;
  top: 104.24%;
  width: 24px;
}
.line-6-9p2GBv {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}
.line-5-9p2GBv {
  background-color: transparent;
  height: 99.05%;
  left: 0.9%;
  position: absolute;
  top: 0.48%;
  width: 99.29%;
}
.component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;
  left: 123px;
  position: absolute;
  top: 45px;
  width: 212px;
}
.group-22-C61RwL {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}
.vector-VdQhAS {
  background-color: transparent;
  height: 100%;
  left: 73.42%;
  position: absolute;
  top: 0px;
  width: 25.32%;
}
.biba-sobti-VdQhAS {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 92px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 47px;
  width: auto;
}
.rating_stars {
  height: 100px;
  margin-top: 20px;
  border: black solid 0.5px;
  border-radius: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.group-164-L8UHM8 {
  background-color: transparent;
  height: 778px;
  left: 20px;
  position: absolute;
  top: -278px;
  width: 24px;
}
.group-162-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 778px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.historytoggleoff-24px-1-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.group-FHHSuW {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.historytoggleoff-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.school-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.description-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.help-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.analytics-24px-3-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.assignment-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.quiz-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.accountbalance-24px-1-KSgdom {
  background-color: var(--blue-zodiac);
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.poweroff-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
  margin-top: 100px;
}
.group-163-L8UHM8 {
  background-color: #1f2531;
  height: 700px;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 63px;
}
.group-165-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 231px;
  position: relative;
  width: 63px;
}
