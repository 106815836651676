.my_students-list .rectangle-20-Cd7ClN-in-accounts-student {
  background-color: #dc3545;
    border-radius: 29px;
    height: 53px;
    left: -27px;
    position: absolute;
    top: 0px;
    width: 1197px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rectangle-30-y1HRxC-10-c-accounts-student {
  /* background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  min-height: 555px;
  min-width: 749px;
  padding: 15px;

  display: flex;
  flex-direction: column;
  align-items: flex-start; */

  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 555px;

  left: -27px;
  position: absolute;
  top: 0px;
  min-width: 1196px;
/* overflow-y: auto; */
  padding: 10px;
}
.group-56-y1HRxC-9-ew-accounts-student {
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    height: 36px;
    width: 1163px;
    margin-top: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 8px;
}
.my_students-list .list-of-students-5GLwEc-accounts-student {
    background-color: transparent;
    color: #dc3545;
    flex-shrink: 1;
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    height: auto;
    letter-spacing: 0px;
    line-height: normal;
    min-height: 26px;
    position: relative;
    text-align: left;
    width: 868px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.montserrat-semi-bold-blue-zodiac-10px-accounts-student
{
  color: var(--blue-zodiac);
    font-family: "Montserrat", Helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    padding: 20px 27px;
    width: fit-content;
}
.roboto-medium-white-20px-accounts-student{
    color: var(--white);
    font-family: "Roboto", Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    width: -webkit-fill-available;
    padding: 13px;
}
.nfkjbdajhbjd-fajdn3-accounts-student {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* padding-right: 8px; */
  overflow-y: auto;
  margin-top: 46px;
  margin-left: 3px;
}
.rectangle-20-aIq8ji-7-3-accounts-student {
 background-color: #dc3545;
    border-radius: 29px;
    height: 42px;
    left: 6px;
    position: absolute;
    top: 10px;
    width: 359px;
    padding: 7px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
