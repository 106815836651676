@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
  }
}
.dropdown-menu .show {
  transition: all 0.5s ease-in-out !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.navbar-toggler-icon {
  display: none !important;
}

@media (min-width: 992px) {
  .icon-nav {
    display: none !important;
  }
}
.navbar-toggler:hover {
  box-shadow: none !important;
}

.headroom {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}

.headroom--unpinned {
  transform: translateY(-100%);
}

.headroom--pinned {
  transform: translateY(0);
}
