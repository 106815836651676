.status_2_iis_22 {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.Dropdown-lec-notes-2-44 {
  width: 28%;
}

@media screen and (max-width: 720px) {
  .status_2_iis_22 {
    width: 100% !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .Dropdown-lec-notes-2-44 {
    width: 88%;
    margin: 5px 2px;
  }
}
