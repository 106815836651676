.Dropdown-lec-notes-3 {
  width: 100%;
    
}

.employee_dropdown {
  top: -15px;
    left: -30px;
    border-radius: 5px;
    border: 1px solid rgb(187, 185, 185);
    font-family: Montserrat, Helvetica;
}