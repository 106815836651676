.main {
  width: 100%;
  padding: 24px 0;
}

.card {
  background-color: #dc3545;
  width: auto;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
}

.textbox {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}
.card p {
  color: #fff;
}
