.landing-page-layout-100 {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background-color: "#dc3545";
  /* overflow-y: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none;  */
}

.landing-page-layout-100::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

#carousal_Section {
  padding-bottom: 40px;
}
#services {
  background-color: #dc3545;
}

#announcemet {
  display: grid;
  justify-items: center;
  margin-bottom: 5%;
  padding: 40px;
}
.divannounce {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.headers {
  font-family: "'Montserrat', Arial, Helvetica, sans-serif";
  letter-spacing: 2;
  font-weight: 600;
  font-size: 28px;
  color: #fff;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px 0;
}

#services h3 {
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 4px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

#services p {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}
#reviews {
  padding: 40px;
}

#trg-mobile-feature {
  background-color: #dc3545;
  padding: 40px 0;
  margin-top: 40px;
}

.login_modal_layout_100 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.login-modal-2-01 {
  z-index: 600;
  position: absolute;
  top: 135px;
  width: 35%;
  padding: 15px;

  max-height: 550px;

  background-color: white;

  box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.2), 0 10px 10px 0 rgba(0, 0, 0, 0.24);
  border-radius: 14px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  transition: transform 0.3s ease-out;
}

.login-modal-title-2-10x {
  width: auto;
  padding: 15px;

  font-size: 24px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: bolder;
  color: #e3241d;
}

.form-login-modal-2 {
  width: 80%;
  margin: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.form-input-login-modal-2 {
  width: 100%;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  outline: 0;
  font-size: 16px;

  border-radius: 10px;
  border: 0;
  box-sizing: border-box;
}

.button-login-modal {
  width: 98%;
  height: 40px;
  padding: 8px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  outline: 0;
  font-size: 18px;
  font-weight: bolder;

  border-radius: 8px;
  border: 0;
  background: #e3241d;

  color: #ffffff;
  cursor: pointer;
}

.message-2-land-log {
  padding: 5px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  color: #b3b3b3;
  font-size: 14px;
  text-align: center;
}

.Open-Landing {
  transform: translateY(0);
}

.Close-Landing {
  transform: translateY(-150%);
}

@media (max-width: 840px) {
  .login-modal-2-01 {
    width: 85%;
    top: 92px;
    max-height: 460px;

    padding: 8px 0px;
  }

  .login-modal-title-2-10x {
    font-size: 18px;
    font-weight: bold;
  }

  .form-login-modal-2 {
    width: 85%;
    margin: 5px;
  }

  .form-input-login-modal-2 {
    font-size: 13px;
  }

  .button-login-modal {
    height: 36px;
    font-size: 14px;
  }

  .message-2-land-log {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  #announcemet {
    padding: 28px 16px;
  }
  /* #gurus {
    padding: 0 16px !important;
  } */

  #reviews {
    padding: 0 16px;
  }
  .headers {
    font-size: 24px;
  }
}
