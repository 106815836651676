input[type="text"]:disabled {
  cursor: not-allowed;
}

.card_cb {
  margin: 50px 200px;
  padding: 60px;
  border-radius: 14px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.enrolled-student-form-1 input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.enrolled-student-form-1 input {
  margin: 0 auto;
  width: 100%;
  height: 35px;
  padding-left: 10px;
  border-radius: 5px;
  border: 2px solid rgba(217, 83, 79, 0.4);
  font-size: 15px;
  font-weight: 400;
  font-family: 'Montserrat', Helvetica;
}

.row {
  margin-bottom: 30px;
}

.submit {
  border-radius: 9px;
  color: white;
  width: 20%;
  margin: 0 auto;
}

.div-amount-user-disp {
  width: 100%;
  margin: 22px 0px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;

  color: rgb(10, 10, 29);

  display: flex;
  align-items: center;
  justify-content: space-between;

}

@media screen and (max-width: 1200px) {
  .card_cb {
    margin: 50px 100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .card_cb {
    margin: 50px 50px;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
.enrolled-student-form-1 input {
    margin-bottom: 20px;
  }

  .input2 {
    margin-bottom: 0px;
  }

  .smtext {
    margin-bottom: 20px;
  }

  .submit {
    width: 30%;
  }

  .div-amount-user-disp {
    font-size: 18px;
    font-weight: 600;
  }
}

@media screen and (max-width: 600px) {
  .smtext {
    margin-bottom: 0;
  }

  .card_cb {
    margin: 50px 30px;
    padding: 40px;
  }

  .submit {
    width: 40%;
  }

  .div-amount-user-disp {
    min-height: 42px;
    flex-direction: column;

    font-size: 14px;
    font-weight: 700;
  }

  .btn {
    font-size: 10px;
    min-height: 45px;
  }
}