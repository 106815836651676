.amount-display {
    width: 100%;
    margin: 22px 0px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    color: rgb(10, 10, 29);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 600px) {
    .card input {
        margin-bottom: 20px !important;
    }

    .amountt {
        margin: 0 !important;
        font-size: 12px;
    }

    .mbottom {
        margin-bottom: 20px;
    }

    .amount-display {
        font-size: 14px;
        font-weight: 700;
        display: flex;
        align-items: center;
    }

    .amountt-div {
        width: 40%;
    }
}