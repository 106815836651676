/* screen - student-calendar-dates-mobile */

.montserrat-bold-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-24px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-nobel-16px {
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-nobel-20px {
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.student-calendar-dates-mobile {
  background-color: rgba(253, 254, 255, 1);
  height: 100vh;
  margin: 0px;
  min-height: 1402px;
  min-width: 414px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.student-calendar-dates-mobile .choose-date-for-booking-C61RwL {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 531px;
  width: 338px;
}

.student-calendar-dates-mobile .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 45px;
  left: 26px;
  position: absolute;
  top: 24px;
  width: 87px;
}

.student-calendar-dates-mobile .component-4-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 157px;
  left: 26px;
  position: absolute;
  top: 346px;
  width: 158px;
}

.student-calendar-dates-mobile .component-5-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 157px;
  left: 230px;
  position: absolute;
  top: 346px;
  width: 158px;
}

.student-calendar-dates-mobile .component-6-C61RwL {
  background-color: transparent;
  height: 18px;
  left: 362px;
  position: absolute;
  top: 37px;
  width: 23px;
}

.student-calendar-dates-mobile .component-7-D1n0rW {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 171px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 304px;
}

.student-calendar-dates-mobile .course-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 132px;
  width: auto;
}

.student-calendar-dates-mobile .doubt-forum-rFX9Nh {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 25px;
  width: 131px;
}

.student-calendar-dates-mobile .ellipse-2-2p4Gmi {
  background-color: transparent;
  height: 2.57%;
  left: 4.45%;
  position: absolute;
  top: 79.59%;
  width: 0.85%;
}

.student-calendar-dates-mobile .ellipse-3-2p4Gmi {
  background-color: transparent;
  height: 14.68%;
  left: 36.42%;
  position: absolute;
  top: 49.87%;
  width: 7.45%;
}

.student-calendar-dates-mobile .feb-2021-C61RwL {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 569px;
  width: 89px;
}

.student-calendar-dates-mobile .fri-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 0px;
  width: 33px;
}

.student-calendar-dates-mobile .grade-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 95px;
  width: auto;
}

.student-calendar-dates-mobile .group-1pzCME {
  background-color: transparent;
  background-image: url(./img/vector-81@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-calendar-dates-mobile .group-3HWdqU {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-calendar-dates-mobile .group-3eHi1a {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-calendar-dates-mobile .group-3xCoUY {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-calendar-dates-mobile .group-4Tztbv {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-calendar-dates-mobile .group-5ZFw7U {
  background-color: transparent;
  background-image: url(./img/vector-36@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-calendar-dates-mobile .group-6dsxAM {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-calendar-dates-mobile .group-6u2TAk {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-calendar-dates-mobile .group-85-C61RwL {
  background-color: transparent;
  height: 270px;
  left: 26px;
  position: absolute;
  top: 1000px;
  width: 385px;
}

.student-calendar-dates-mobile .group-86-C61RwL {
  background-color: transparent;
  height: 165px;
  left: 29px;
  position: absolute;
  top: 1300px;
  width: 363px;
}

.student-calendar-dates-mobile .group-87-C61RwL {
  background-color: transparent;
  height: 171px;
  left: 56px;
  position: absolute;
  top: 147px;
  width: 312px;
}

.student-calendar-dates-mobile .group-88-C61RwL {
  background-color: transparent;
  height: 19.43%;
  left: 5.56%;
  position: absolute;
  top: 44.22%;
  width: 113.41%;
}

.student-calendar-dates-mobile .group-9neAS5 {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-calendar-dates-mobile .group-B3X4ab {
  background-color: transparent;
  background-image: url(./img/vector-36@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-calendar-dates-mobile .group-BpxbEL {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-calendar-dates-mobile .group-DhmCtC {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-calendar-dates-mobile .group-FASyuA {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-calendar-dates-mobile .group-HTx4Jc {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-calendar-dates-mobile .group-IfQt5H {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-calendar-dates-mobile .group-N2Y59c {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-calendar-dates-mobile .group-O5Cxw9 {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-calendar-dates-mobile .group-Ob1Rqs {
  background-color: transparent;
  background-image: url(./img/vector-35@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-calendar-dates-mobile .group-Qv0mZ4 {
  background-color: transparent;
  background-image: url(./img/vector-36@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-calendar-dates-mobile .group-S9AR9M {
  background-color: transparent;
  background-image: url(./img/vector-33@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-calendar-dates-mobile .group-T7EbAp {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-calendar-dates-mobile .group-TdE1pD {
  background-color: transparent;
  background-image: url(./img/vector-44@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-calendar-dates-mobile .group-TtUkiV {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-calendar-dates-mobile .group-VOGBVy {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-calendar-dates-mobile .group-VRMQru {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-calendar-dates-mobile .group-WfnLBo {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-calendar-dates-mobile .group-bX4Dvh {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-calendar-dates-mobile .group-bcFrzZ {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-calendar-dates-mobile .group-bof6oJ {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-calendar-dates-mobile .group-c0ymqA {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-calendar-dates-mobile .group-c9XjZ6 {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-calendar-dates-mobile .group-dS4UXQ {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-calendar-dates-mobile .group-dV81ro {
  background-color: transparent;
  background-image: url(./img/vector-80@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-calendar-dates-mobile .group-dcaDkc {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-calendar-dates-mobile .group-gn7VaX {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-calendar-dates-mobile .group-gvV2Rx {
  background-color: transparent;
  background-image: url(./img/vector-80@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-calendar-dates-mobile .group-hhAbtl {
  background-color: transparent;
  background-image: url(./img/vector-43@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-calendar-dates-mobile .group-iieIco {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-calendar-dates-mobile .group-jLf4aV {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-calendar-dates-mobile .group-k7b1Mi {
  background-color: transparent;
  background-image: url(./img/vector-33@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-calendar-dates-mobile .group-lw8NAW {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-calendar-dates-mobile .group-mokeKx {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-calendar-dates-mobile .group-ms1pvS {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-calendar-dates-mobile .group-nD2hpN {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-calendar-dates-mobile .group-nzNou6 {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-calendar-dates-mobile .group-qGye3v {
  background-color: transparent;
  background-image: url(./img/vector-33@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-calendar-dates-mobile .group-qpftC5 {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-calendar-dates-mobile .group-sku7Fc {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-calendar-dates-mobile .group-tQwNUN {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-calendar-dates-mobile .group-toBf7r {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-calendar-dates-mobile .group-wQSXdG {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-calendar-dates-mobile .group-x2R7zB {
  background-color: transparent;
  background-image: url(./img/vector-34@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-calendar-dates-mobile .group-xB1UPi {
  background-color: transparent;
  background-image: url(./img/vector-81@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-calendar-dates-mobile .group-xS7o0X {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-calendar-dates-mobile .group-xhwx9b {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-calendar-dates-mobile .group-xtOtSY {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-calendar-dates-mobile .group-zxfIi7 {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-calendar-dates-mobile .how-to-solve-this-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 214px;
  width: 289px;
}

.student-calendar-dates-mobile .join-7-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 11px;
  overflow: hidden;
  position: absolute;
  top: 68px;
  width: 46px;
}

.student-calendar-dates-mobile .join-8-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 9px;
  overflow: hidden;
  position: absolute;
  top: 139px;
  width: 46px;
}

.student-calendar-dates-mobile .join-9-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 9px;
  overflow: hidden;
  position: absolute;
  top: 210px;
  width: 46px;
}

.student-calendar-dates-mobile .line-5-9p2GBv {
  background-color: transparent;
  height: 101.11%;
  left: 0.55%;
  position: absolute;
  top: -0.56%;
  width: 100%;
}

.student-calendar-dates-mobile .line-6-9p2GBv {
  background-color: transparent;
  height: 102.22%;
  left: -0.29%;
  position: absolute;
  top: -1.11%;
  width: 100.57%;
}

.student-calendar-dates-mobile .mon-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 0px;
  width: 47px;
}

.student-calendar-dates-mobile .next-month-C61RwL {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 272px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 569px;
  width: auto;
}

.student-calendar-dates-mobile .pending-assignments-cPi90N {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 2px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0px;
  width: auto;
}

.student-calendar-dates-mobile .rectangle-44-cPi90N {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 49px;
  left: 0px;
  position: absolute;
  top: 44px;
  width: 359px;
}

.student-calendar-dates-mobile .rectangle-44-rFX9Nh {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* height: 270px; */
  min-height: 120px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 358px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 66px 0;
}

.student-calendar-dates-mobile .rectangle-48-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-calendar-dates-mobile .rectangle-49-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 38.89%;
  width: 100%;
}

.student-calendar-dates-mobile .rectangle-50-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 77.78%;
  width: 100%;
}

.student-calendar-dates-mobile .rectangle-52-cPi90N {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 49px;
  left: 0px;
  position: absolute;
  top: 116px;
  width: 359px;
}

.student-calendar-dates-mobile .sat-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 286px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 0px;
  width: 34px;
}

.student-calendar-dates-mobile .sections-teachi-g-i613101253102-p4wM3n {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: 30.57%;
  left: 25.92%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 12.89%;
  width: 49.37%;
}

.student-calendar-dates-mobile .sections-teachi-g-i613101353102-9wWKr1 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: 30.57%;
  left: 11.99%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 12.89%;
  width: 77.22%;
}

.student-calendar-dates-mobile .span0-KoN7KL {
  color: rgba(19, 37, 71, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.student-calendar-dates-mobile .span1-KoN7KL {
  color: rgba(19, 37, 71, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.student-calendar-dates-mobile .subject-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 58px;
  width: auto;
}

.student-calendar-dates-mobile .sun-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 342px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 0px;
  width: 48px;
}

.student-calendar-dates-mobile .teacher-name-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: auto;
}

.student-calendar-dates-mobile .thu-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 174px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 0px;
  width: 42px;
}

.student-calendar-dates-mobile .tue-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 59px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 0px;
  width: 43px;
}

.student-calendar-dates-mobile .vector-0IPvau {
  background-color: transparent;
  height: 29.85%;
  left: 37.46%;
  position: absolute;
  top: 2.72%;
  width: 34.88%;
}

.student-calendar-dates-mobile .vector-1Nxj6x {
  background-color: transparent;
  height: 121.98%;
  left: -8.49%;
  position: absolute;
  top: -10.99%;
  width: 116.98%;
}

.student-calendar-dates-mobile .vector-1TafeU {
  background-color: transparent;
  height: 121.99%;
  left: -5.05%;
  position: absolute;
  top: -10.99%;
  width: 110.09%;
}

.student-calendar-dates-mobile .vector-1pzCME {
  background-color: transparent;
  height: 52.24%;
  left: 49.48%;
  position: absolute;
  top: 8.1%;
  width: 45.78%;
}

.student-calendar-dates-mobile .vector-1xHKrh {
  background-color: transparent;
  height: 11.94%;
  left: 23.87%;
  position: absolute;
  top: 18.13%;
  width: 8.72%;
}

.student-calendar-dates-mobile .vector-2rF7C5 {
  background-color: transparent;
  height: 110.03%;
  left: -7.97%;
  position: absolute;
  top: -5.02%;
  width: 115.95%;
}

.student-calendar-dates-mobile .vector-3HWdqU {
  background-color: transparent;
  height: 101.5%;
  left: 11.3%;
  position: absolute;
  top: -1.01%;
  width: 85.01%;
}

.student-calendar-dates-mobile .vector-3xCoUY {
  background-color: transparent;
  height: 13.43%;
  left: 63.59%;
  position: absolute;
  top: 41.24%;
  width: 18.53%;
}

.student-calendar-dates-mobile .vector-5SSNkN {
  background-color: transparent;
  height: 29.85%;
  left: 37.37%;
  position: absolute;
  top: 2.71%;
  width: 22.89%;
}

.student-calendar-dates-mobile .vector-5YyGRW {
  background-color: transparent;
  height: 19.4%;
  left: 25.14%;
  position: absolute;
  top: 22.08%;
  width: 13.08%;
}

.student-calendar-dates-mobile .vector-5ZFw7U {
  background-color: transparent;
  height: 29.85%;
  left: 2.07%;
  position: absolute;
  top: 8.12%;
  width: 45.78%;
}

.student-calendar-dates-mobile .vector-5ZxPCv {
  background-color: transparent;
  height: 144.31%;
  left: -20.86%;
  position: absolute;
  top: -22.16%;
  width: 141.73%;
}

.student-calendar-dates-mobile .vector-6Pj9gj {
  background-color: transparent;
  height: 5.97%;
  left: 51.2%;
  position: absolute;
  top: 63.23%;
  width: 6.54%;
}

.student-calendar-dates-mobile .vector-6dsxAM {
  background-color: transparent;
  height: 7.46%;
  left: 67.71%;
  position: absolute;
  top: 51.44%;
  width: 7.63%;
}

.student-calendar-dates-mobile .vector-6lEMsv {
  background-color: transparent;
  height: 34.33%;
  left: 2.43%;
  position: absolute;
  top: 22.69%;
  width: 58.85%;
}

.student-calendar-dates-mobile .vector-6u2TAk {
  background-color: transparent;
  height: 41.8%;
  left: 4.07%;
  position: absolute;
  top: 13.83%;
  width: 55.59%;
}

.student-calendar-dates-mobile .vector-9neAS5 {
  background-color: transparent;
  height: 14.93%;
  left: 17.13%;
  position: absolute;
  top: 40.61%;
  width: 11.99%;
}

.student-calendar-dates-mobile .vector-ADvjvZ {
  background-color: transparent;
  height: 29.85%;
  left: 37.46%;
  position: absolute;
  top: 2.72%;
  width: 34.88%;
}

.student-calendar-dates-mobile .vector-AEuo4j {
  background-color: transparent;
  height: 4.48%;
  left: 11.86%;
  position: absolute;
  top: 12.28%;
  width: 29.43%;
}

.student-calendar-dates-mobile .vector-AKNQwM {
  background-color: transparent;
  height: 11.94%;
  left: 24.12%;
  position: absolute;
  top: 18.13%;
  width: 15.26%;
}

.student-calendar-dates-mobile .vector-AUE2eU {
  background-color: transparent;
  height: 101.25%;
  left: -6.3%;
  position: absolute;
  top: 0.47%;
  width: 112.6%;
}

.student-calendar-dates-mobile .vector-B3X4ab {
  background-color: transparent;
  height: 29.85%;
  left: 2.07%;
  position: absolute;
  top: 8.12%;
  width: 45.78%;
}

.student-calendar-dates-mobile .vector-DhmCtC {
  background-color: transparent;
  height: 13.43%;
  left: 63.56%;
  position: absolute;
  top: 41.24%;
  width: 25.07%;
}

.student-calendar-dates-mobile .vector-FBXFcM {
  background-color: transparent;
  height: 34.33%;
  left: 2.43%;
  position: absolute;
  top: 22.69%;
  width: 58.85%;
}

.student-calendar-dates-mobile .vector-FRe9kA {
  background-color: transparent;
  height: 121.98%;
  left: -8.49%;
  position: absolute;
  top: -10.99%;
  width: 116.98%;
}

.student-calendar-dates-mobile .vector-FbIeqH {
  background-color: transparent;
  height: 8.96%;
  left: 25.33%;
  position: absolute;
  top: 36.57%;
  width: 6.54%;
}

.student-calendar-dates-mobile .vector-GULZOy {
  background-color: transparent;
  height: 121.96%;
  left: -10.26%;
  position: absolute;
  top: -10.98%;
  width: 120.52%;
}

.student-calendar-dates-mobile .vector-HDxFmN {
  background-color: transparent;
  height: 8.96%;
  left: 25.14%;
  position: absolute;
  top: 37.19%;
  width: 13.08%;
}

.student-calendar-dates-mobile .vector-IfQt5H {
  background-color: transparent;
  height: 49.26%;
  left: 29.45%;
  position: absolute;
  top: 51.43%;
  width: 49.05%;
}

.student-calendar-dates-mobile .vector-IpBwGI {
  background-color: transparent;
  height: 32.84%;
  left: 2.23%;
  position: absolute;
  top: 24.54%;
  width: 21.8%;
}

.student-calendar-dates-mobile .vector-LS3dNl {
  background-color: transparent;
  height: 8.96%;
  left: 25.33%;
  position: absolute;
  top: 36.57%;
  width: 6.54%;
}

.student-calendar-dates-mobile .vector-LpgxzN {
  background-color: transparent;
  height: 19.4%;
  left: 25.14%;
  position: absolute;
  top: 22.08%;
  width: 13.08%;
}

.student-calendar-dates-mobile .vector-N2Y59c {
  background-color: transparent;
  height: 82.1%;
  left: 6.95%;
  position: absolute;
  top: 2.36%;
  width: 93.73%;
}

.student-calendar-dates-mobile .vector-NF9mxS {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.18%;
}

.student-calendar-dates-mobile .vector-O5Cxw9 {
  background-color: transparent;
  height: 13.43%;
  left: 63.56%;
  position: absolute;
  top: 41.24%;
  width: 25.07%;
}

.student-calendar-dates-mobile .vector-OWyIvf {
  background-color: transparent;
  height: 144.31%;
  left: -20.86%;
  position: absolute;
  top: -22.16%;
  width: 141.73%;
}

.student-calendar-dates-mobile .vector-Ob1Rqs {
  background-color: transparent;
  height: 52.24%;
  left: 49.48%;
  position: absolute;
  top: 8.1%;
  width: 45.78%;
}

.student-calendar-dates-mobile .vector-P1lxK3 {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.18%;
}

.student-calendar-dates-mobile .vector-PFKICe {
  background-color: transparent;
  height: 11.94%;
  left: 23.87%;
  position: absolute;
  top: 18.13%;
  width: 8.72%;
}

.student-calendar-dates-mobile .vector-PtriGM {
  background-color: transparent;
  height: 11.94%;
  left: 24.12%;
  position: absolute;
  top: 18.13%;
  width: 15.26%;
}

.student-calendar-dates-mobile .vector-QDyA6g {
  background-color: transparent;
  height: 110.03%;
  left: -7.97%;
  position: absolute;
  top: -5.02%;
  width: 115.95%;
}

.student-calendar-dates-mobile .vector-Qv0mZ4 {
  background-color: transparent;
  height: 29.85%;
  left: 2.07%;
  position: absolute;
  top: 8.12%;
  width: 45.78%;
}

.student-calendar-dates-mobile .vector-RYpCgT {
  background-color: transparent;
  height: 121.98%;
  left: -8.17%;
  position: absolute;
  top: -10.99%;
  width: 116.33%;
}

.student-calendar-dates-mobile .vector-S9AR9M {
  background-color: transparent;
  height: 70.16%;
  left: -0.63%;
  position: absolute;
  top: 8.33%;
  width: 95.91%;
}

.student-calendar-dates-mobile .vector-Srtp8D {
  background-color: transparent;
  height: 144.3%;
  left: -3.79%;
  position: absolute;
  top: -22.15%;
  width: 107.57%;
}

.student-calendar-dates-mobile .vector-T0btkN {
  background-color: transparent;
  height: 11.94%;
  left: 23.87%;
  position: absolute;
  top: 18.13%;
  width: 8.72%;
}

.student-calendar-dates-mobile .vector-T7EbAp {
  background-color: transparent;
  height: 13.43%;
  left: 63.59%;
  position: absolute;
  top: 41.24%;
  width: 18.53%;
}

.student-calendar-dates-mobile .vector-TdE1pD {
  background-color: transparent;
  height: 49.26%;
  left: 29.45%;
  position: absolute;
  top: 51.43%;
  width: 49.05%;
}

.student-calendar-dates-mobile .vector-U0LxYw {
  background-color: transparent;
  height: 4.05%;
  left: 70.85%;
  position: absolute;
  top: -1.45%;
  width: 14.08%;
}

.student-calendar-dates-mobile .vector-UUUxJF {
  background-color: transparent;
  height: 121.96%;
  left: -10.26%;
  position: absolute;
  top: -10.98%;
  width: 120.52%;
}

.student-date-calendar .rectangle-43-C61RwL .doubt-row {
  /* display: flex;
  justify-content: space-evenly;
  margin: 4px;
  align-items: center; */

  display: flex;
  justify-content: space-evenly;
  margin: 4px;
  align-items: center;
}

.student-calendar-dates-mobile .vector-VOGBVy {
  background-color: transparent;
  height: 41.8%;
  left: 4.1%;
  position: absolute;
  top: 13.83%;
  width: 19.62%;
}

.student-calendar-dates-mobile .vector-VRMQru {
  background-color: transparent;
  height: 82.1%;
  left: 6.95%;
  position: absolute;
  top: 2.36%;
  width: 93.73%;
}

.student-calendar-dates-mobile .vector-Vr5tiq {
  background-color: transparent;
  height: 29.85%;
  left: 37.37%;
  position: absolute;
  top: 2.71%;
  width: 22.89%;
}

.student-calendar-dates-mobile .vector-WCljiC {
  background-color: transparent;
  height: 19.4%;
  left: 25.14%;
  position: absolute;
  top: 22.08%;
  width: 13.08%;
}

.student-calendar-dates-mobile .vector-WfnLBo {
  background-color: transparent;
  height: 82.1%;
  left: 6.95%;
  position: absolute;
  top: 2.36%;
  width: 93.73%;
}

.student-calendar-dates-mobile .vector-WqgE0F {
  background-color: transparent;
  height: 19.4%;
  left: 29.22%;
  position: absolute;
  top: 81.41%;
  width: 29.43%;
}

.student-calendar-dates-mobile .vector-Y59V0s {
  background-color: transparent;
  height: 4.05%;
  left: 70.85%;
  position: absolute;
  top: -1.45%;
  width: 14.08%;
}

.student-calendar-dates-mobile .vector-Y9TIUK {
  background-color: transparent;
  height: 121.99%;
  left: -5.05%;
  position: absolute;
  top: -10.99%;
  width: 110.09%;
}

.student-calendar-dates-mobile .vector-YM4yxX {
  background-color: transparent;
  height: 5.97%;
  left: 36.74%;
  position: absolute;
  top: 28.23%;
  width: 3.27%;
}

.student-calendar-dates-mobile .vector-aXV5cr {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.18%;
}

.student-calendar-dates-mobile .vector-bKRlnb {
  background-color: transparent;
  height: 110.03%;
  left: -7.97%;
  position: absolute;
  top: -5.02%;
  width: 115.95%;
}

.student-calendar-dates-mobile .vector-bnkfUA {
  background-color: transparent;
  height: 4.48%;
  left: 12.13%;
  position: absolute;
  top: 12.28%;
  width: 8.72%;
}

.student-calendar-dates-mobile .vector-bof6oJ {
  background-color: transparent;
  height: 29.85%;
  left: 2.15%;
  position: absolute;
  top: 8.24%;
  width: 18.53%;
}

.student-calendar-dates-mobile .vector-c0ymqA {
  background-color: transparent;
  height: 41.8%;
  left: 4.1%;
  position: absolute;
  top: 13.83%;
  width: 19.62%;
}

.student-calendar-dates-mobile .vector-c9XjZ6 {
  background-color: transparent;
  height: 13.43%;
  left: 63.56%;
  position: absolute;
  top: 41.24%;
  width: 25.07%;
}

.student-calendar-dates-mobile .vector-cJDtwl {
  background-color: transparent;
  height: 5.97%;
  left: 51.2%;
  position: absolute;
  top: 63.23%;
  width: 6.54%;
}

.student-calendar-dates-mobile .vector-cNJjv2 {
  background-color: transparent;
  height: 32.84%;
  left: 2.23%;
  position: absolute;
  top: 24.54%;
  width: 21.8%;
}

.student-calendar-dates-mobile .vector-d2duO5 {
  background-color: transparent;
  height: 4.05%;
  left: 70.85%;
  position: absolute;
  top: -1.45%;
  width: 14.08%;
}

.student-calendar-dates-mobile .vector-dG0Ldx {
  background-color: transparent;
  height: 5.97%;
  left: 51.2%;
  position: absolute;
  top: 63.23%;
  width: 6.54%;
}

.student-calendar-dates-mobile .vector-dS2uCE {
  background-color: transparent;
  height: 8.96%;
  left: 25.14%;
  position: absolute;
  top: 37.19%;
  width: 13.08%;
}

.student-calendar-dates-mobile .vector-dS4UXQ {
  background-color: transparent;
  height: 49.26%;
  left: 29.45%;
  position: absolute;
  top: 51.43%;
  width: 49.05%;
}

.student-calendar-dates-mobile .vector-dV81ro {
  background-color: transparent;
  height: 70.16%;
  left: -0.97%;
  position: absolute;
  top: 8.45%;
  width: 25.07%;
}

.student-calendar-dates-mobile .vector-dcaDkc {
  background-color: transparent;
  height: 14.93%;
  left: 17.51%;
  position: absolute;
  top: 40.61%;
  width: 28.34%;
}

.student-calendar-dates-mobile .vector-e2yeN1 {
  background-color: transparent;
  height: 29.85%;
  left: 37.46%;
  position: absolute;
  top: 2.72%;
  width: 34.88%;
}

.student-calendar-dates-mobile .vector-gvV2Rx {
  background-color: transparent;
  height: 70.16%;
  left: -0.97%;
  position: absolute;
  top: 8.45%;
  width: 25.07%;
}

.student-calendar-dates-mobile .vector-hhAbtl {
  background-color: transparent;
  height: 14.93%;
  left: 17.13%;
  position: absolute;
  top: 40.61%;
  width: 11.99%;
}

.student-calendar-dates-mobile .vector-i64Q95 {
  background-color: transparent;
  height: 19.4%;
  left: 29.22%;
  position: absolute;
  top: 81.41%;
  width: 29.43%;
}

.student-calendar-dates-mobile .vector-jLf4aV {
  background-color: transparent;
  height: 7.46%;
  left: 67.71%;
  position: absolute;
  top: 51.44%;
  width: 7.63%;
}

.student-calendar-dates-mobile .vector-jaA0fc {
  background-color: transparent;
  height: 144.31%;
  left: -3.79%;
  position: absolute;
  top: -22.16%;
  width: 107.57%;
}

.student-calendar-dates-mobile .vector-k7b1Mi {
  background-color: transparent;
  height: 70.16%;
  left: -0.63%;
  position: absolute;
  top: 8.33%;
  width: 95.91%;
}

.student-calendar-dates-mobile .vector-kwqROy {
  background-color: transparent;
  height: 144.31%;
  left: -20.86%;
  position: absolute;
  top: -22.16%;
  width: 141.73%;
}

.student-calendar-dates-mobile .vector-lFxDi9 {
  background-color: transparent;
  height: 19.4%;
  left: 29.22%;
  position: absolute;
  top: 81.41%;
  width: 29.43%;
}

.student-calendar-dates-mobile .vector-lj5Xre {
  background-color: transparent;
  height: 121.96%;
  left: -10.26%;
  position: absolute;
  top: -10.98%;
  width: 120.52%;
}

.student-calendar-dates-mobile .vector-lkVzGd {
  background-color: transparent;
  height: 4.48%;
  left: 12.13%;
  position: absolute;
  top: 12.28%;
  width: 8.72%;
}

.student-calendar-dates-mobile .vector-lw8NAW {
  background-color: transparent;
  height: 29.85%;
  left: 2.15%;
  position: absolute;
  top: 8.24%;
  width: 18.53%;
}

.student-calendar-dates-mobile .vector-mGXgmO {
  background-color: transparent;
  height: 4.48%;
  left: 12.13%;
  position: absolute;
  top: 12.28%;
  width: 8.72%;
}

.student-calendar-dates-mobile .vector-mokeKx {
  background-color: transparent;
  height: 14.93%;
  left: 17.51%;
  position: absolute;
  top: 40.61%;
  width: 28.34%;
}

.student-calendar-dates-mobile .vector-ms1pvS {
  background-color: transparent;
  height: 41.8%;
  left: 4.07%;
  position: absolute;
  top: 13.83%;
  width: 55.59%;
}

.student-calendar-dates-mobile .vector-nD2hpN {
  background-color: transparent;
  height: 101.5%;
  left: 11.3%;
  position: absolute;
  top: -1.01%;
  width: 85.01%;
}

.student-calendar-dates-mobile .vector-ngma9X {
  background-color: transparent;
  height: 101.25%;
  left: -6.3%;
  position: absolute;
  top: 0.47%;
  width: 112.6%;
}

.student-calendar-dates-mobile .vector-nzNou6 {
  background-color: transparent;
  height: 101.5%;
  left: 11.3%;
  position: absolute;
  top: -1.01%;
  width: 85.01%;
}

.student-calendar-dates-mobile .vector-nzuIpS {
  background-color: transparent;
  height: 5.97%;
  left: 36.74%;
  position: absolute;
  top: 28.23%;
  width: 3.27%;
}

.student-calendar-dates-mobile .vector-oafYiW {
  background-color: transparent;
  height: 121.98%;
  left: -8.17%;
  position: absolute;
  top: -10.99%;
  width: 116.33%;
}

.student-calendar-dates-mobile .vector-oez9Pb {
  background-color: transparent;
  height: 8.96%;
  left: 25.14%;
  position: absolute;
  top: 37.19%;
  width: 13.08%;
}

.student-calendar-dates-mobile .vector-pummtu {
  background-color: transparent;
  height: 144.3%;
  left: -3.79%;
  position: absolute;
  top: -22.15%;
  width: 107.57%;
}

.student-calendar-dates-mobile .vector-pxIogv {
  background-color: transparent;
  height: 121.98%;
  left: -8.17%;
  position: absolute;
  top: -10.99%;
  width: 116.33%;
}

.student-calendar-dates-mobile .vector-qGye3v {
  background-color: transparent;
  height: 70.16%;
  left: -0.63%;
  position: absolute;
  top: 8.33%;
  width: 95.91%;
}

.student-calendar-dates-mobile .vector-qpftC5 {
  background-color: transparent;
  height: 14.93%;
  left: 17.13%;
  position: absolute;
  top: 40.61%;
  width: 11.99%;
}

.student-calendar-dates-mobile .vector-rGRikn {
  background-color: transparent;
  height: 101.25%;
  left: -6.3%;
  position: absolute;
  top: 0.47%;
  width: 112.6%;
}

.student-calendar-dates-mobile .vector-rXxPBk {
  background-color: transparent;
  height: 11.94%;
  left: 24.12%;
  position: absolute;
  top: 18.13%;
  width: 15.26%;
}

.student-calendar-dates-mobile .vector-sku7Fc {
  background-color: transparent;
  height: 13.43%;
  left: 63.59%;
  position: absolute;
  top: 41.24%;
  width: 18.53%;
}

.student-calendar-dates-mobile .vector-tQwNUN {
  background-color: transparent;
  height: 14.93%;
  left: 17.51%;
  position: absolute;
  top: 40.61%;
  width: 28.34%;
}

.student-calendar-dates-mobile .vector-toBf7r {
  background-color: transparent;
  height: 41.8%;
  left: 4.07%;
  position: absolute;
  top: 13.83%;
  width: 55.59%;
}

.student-calendar-dates-mobile .vector-vPuJH9 {
  background-color: transparent;
  height: 32.84%;
  left: 2.23%;
  position: absolute;
  top: 24.54%;
  width: 21.8%;
}

.student-calendar-dates-mobile .vector-x2R7zB {
  background-color: transparent;
  height: 70.16%;
  left: -0.97%;
  position: absolute;
  top: 8.45%;
  width: 25.07%;
}

.student-calendar-dates-mobile .vector-x5Jnp7 {
  background-color: transparent;
  height: 121.99%;
  left: -5.05%;
  position: absolute;
  top: -10.99%;
  width: 110.09%;
}

.student-calendar-dates-mobile .vector-xB1UPi {
  background-color: transparent;
  height: 52.24%;
  left: 49.48%;
  position: absolute;
  top: 8.1%;
  width: 45.78%;
}

.student-calendar-dates-mobile .vector-xJcTLo {
  background-color: transparent;
  height: 121.98%;
  left: -8.49%;
  position: absolute;
  top: -10.99%;
  width: 116.98%;
}

.student-calendar-dates-mobile .vector-xMrRfb {
  background-color: transparent;
  height: 4.48%;
  left: 11.86%;
  position: absolute;
  top: 12.28%;
  width: 29.43%;
}

.student-calendar-dates-mobile .vector-xOJZuX {
  background-color: transparent;
  height: 144.31%;
  left: -3.79%;
  position: absolute;
  top: -22.16%;
  width: 107.57%;
}

.student-calendar-dates-mobile .vector-xhepvl {
  background-color: transparent;
  height: 5.97%;
  left: 37.29%;
  position: absolute;
  top: 28.23%;
  width: 2.18%;
}

.student-calendar-dates-mobile .vector-xhwx9b {
  background-color: transparent;
  height: 7.46%;
  left: 67.71%;
  position: absolute;
  top: 51.44%;
  width: 7.63%;
}

.student-calendar-dates-mobile .vector-xtOtSY {
  background-color: transparent;
  height: 41.8%;
  left: 4.1%;
  position: absolute;
  top: 13.83%;
  width: 19.62%;
}

.student-calendar-dates-mobile .vector-xvjzYd {
  background-color: transparent;
  height: 4.48%;
  left: 11.86%;
  position: absolute;
  top: 12.28%;
  width: 29.43%;
}

.student-calendar-dates-mobile .vector-ymbxcA {
  background-color: transparent;
  height: 29.85%;
  left: 37.37%;
  position: absolute;
  top: 2.71%;
  width: 22.89%;
}

.student-calendar-dates-mobile .vector-zXuwwt {
  background-color: transparent;
  height: 34.33%;
  left: 2.43%;
  position: absolute;
  top: 22.69%;
  width: 58.85%;
}

.student-calendar-dates-mobile .vector-zeZVsH {
  background-color: transparent;
  height: 8.96%;
  left: 25.33%;
  position: absolute;
  top: 36.57%;
  width: 6.54%;
}

.student-calendar-dates-mobile .vector-zxfIi7 {
  background-color: transparent;
  height: 29.85%;
  left: 2.15%;
  position: absolute;
  top: 8.24%;
  width: 18.53%;
}

.student-calendar-dates-mobile .view-all-cPi90N {
  background-color: transparent;
  height: auto;
  left: 309px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 6px;
  width: auto;
}

.student-calendar-dates-mobile .view-all-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 296px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: 75px;
}

.student-calendar-dates-mobile .view-forum-CN8bZ4 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 251px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 235px;
  width: 113px;
}

.student-calendar-dates-mobile .view-forum-CNpLuU {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 251px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 169px;
  width: 113px;
}

.student-calendar-dates-mobile .view-forum-rFX9Nh {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 251px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 103px;
  width: 113px;
}

.student-calendar-dates-mobile .wed-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 115px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 0px;
  width: 48px;
}

.student-calendar-dates-mobile .what-is-the-si-unit-of-current-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 74px;
  width: 289px;
}

.student-calendar-dates-mobile .what-is-the-sol-f-this-question-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 140px;
  width: 289px;
}

.student-calendar-dates-mobile .x1-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 238px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 50px;
  width: 8px;
}

.student-calendar-dates-mobile .x10-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 342px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 98px;
  width: 31px;
}

.student-calendar-dates-mobile .x11-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 146px;
  width: 22px;
}

.student-calendar-dates-mobile .x12-C61RwL {
  background-color: transparent;
  height: auto;
  left: 86px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 766px;
  width: 30px;
}

.student-calendar-dates-mobile .x13-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 120px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 146px;
  width: 30px;
}

.student-calendar-dates-mobile .x14-2p4Gmi {
  background-color: transparent;
  color: var(--wild-sand);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 174px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 146px;
  width: 30px;
}

.student-calendar-dates-mobile .x15-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 146px;
  width: 30px;
}

.student-calendar-dates-mobile .x16-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 286px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 146px;
  width: 30px;
}

.student-calendar-dates-mobile .x17-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 342px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 146px;
  width: 30px;
}

.student-calendar-dates-mobile .x18-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 6px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 194px;
  width: 30px;
}

.student-calendar-dates-mobile .x19-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 63px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 194px;
  width: 30px;
}

.student-calendar-dates-mobile .x2-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 294px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 50px;
  width: 11px;
}

.student-calendar-dates-mobile .x20-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 119px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 194px;
  width: 30px;
}

.student-calendar-dates-mobile .x21-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 174px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 194px;
  width: 30px;
}

.student-calendar-dates-mobile .x22-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 194px;
  width: 30px;
}

.student-calendar-dates-mobile .x23-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 286px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 194px;
  width: 30px;
}

.student-calendar-dates-mobile .x24-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 342px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 194px;
  width: 30px;
}

.student-calendar-dates-mobile .x25-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 7px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 242px;
  width: 30px;
}

.student-calendar-dates-mobile .x26-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 63px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 242px;
  width: 30px;
}

.student-calendar-dates-mobile .x27-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 119px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 242px;
  width: 30px;
}

.student-calendar-dates-mobile .x28-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 174px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 242px;
  width: 30px;
}

.student-calendar-dates-mobile .x28-Fsxpn4 {
  background-color: transparent;
  height: auto;
  left: 6px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 50px;
  width: 26px;
}

.student-calendar-dates-mobile .x29-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 242px;
  width: 30px;
}

.student-calendar-dates-mobile .x29-Fsxpn4 {
  background-color: transparent;
  height: auto;
  left: 62px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 50px;
  width: 30px;
}

.student-calendar-dates-mobile .x3-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 350px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 50px;
  width: 11px;
}

.student-calendar-dates-mobile .x30-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 286px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 242px;
  width: 30px;
}

.student-calendar-dates-mobile .x30-Fsxpn4 {
  background-color: transparent;
  height: auto;
  left: 118px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 50px;
  width: 35px;
}

.student-calendar-dates-mobile .x31-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 342px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 242px;
  width: 30px;
}

.student-calendar-dates-mobile .x31-Fsxpn4 {
  background-color: transparent;
  height: auto;
  left: 174px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 50px;
  width: 29px;
}

.student-calendar-dates-mobile .x4-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 12px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 98px;
  width: 12px;
}

.student-calendar-dates-mobile .x4-i613101253103-p4wM3n {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: 43.31%;
  left: 28.93%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 40.72%;
  width: 43.04%;
}

.student-calendar-dates-mobile .x4-i613101353103-9wWKr1 {
  background-color: transparent;
  color: rgba(5, 187, 138, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: 43.31%;
  left: 32.73%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 40.72%;
  width: 35.44%;
}

.student-calendar-dates-mobile .x4-i613101553103-j2Gv3j {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: 45.61%;
  left: 50.09%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 40.72%;
  width: 0.66%;
}

.student-calendar-dates-mobile .x5-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 70px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 98px;
  width: 11px;
}

.student-calendar-dates-mobile .x6-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 129px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 98px;
  width: 11px;
}

.student-calendar-dates-mobile .x7-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 184px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 98px;
  width: 11px;
}

.student-calendar-dates-mobile .x8-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 238px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 98px;
  width: 12px;
}

.student-calendar-dates-mobile .x9-2p4Gmi {
  background-color: transparent;
  height: auto;
  left: 294px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 98px;
  width: 11px;
}

:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --nobel: rgba(179, 179, 179, 1);
  --white: rgba(255, 255, 255, 1);
  --wild-sand: rgba(244, 244, 244, 1);
}

/* screen - student-date-calendar */

.montserrat-bold-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-24px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-nobel-16px {
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-nobel-20px {
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.student-date-calendar {
  background-color: rgba(253, 254, 255, 1);
  height: 100vh;
  width: 100%;
}

.group-22-GyJUNH-student-dash {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  justify-content: flex-end;
  min-width: 316px;
  width: min-content;
}

.student-re-flex {
  width: 87%;
  position: relative;
  top: 20px;
  left: 110px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.student-date-calendar .analytics-24px-3-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 644px;
  width: 24px;
}

.student-date-calendar .assignment-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 348px;
  width: 24px;
}

.student-date-calendar .biba-sobti-VdQhAS {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 92px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}

.student-date-calendar .bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 47px;
  width: auto;
}

.student-date-calendar .choose-date-for-booking-C61RwL {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 13%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 426px;
  width: 432px;
}

.student-date-calendar .component-1-C61RwLz-3 {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;

  width: 212px;
}

.student-date-calendar .component-4-C61RwL-rrre {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 194px;
  left: 12%;
  position: absolute;
  top: 192px;
  width: 158px;
}

.student-date-calendar .component-5-C61RwL-002 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 194px;
  left: 30%;
  position: absolute;
  top: 192px;
  width: 158px;
}

.student-date-calendar .component-6-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 194px;
  left: 374px;
  position: absolute;
  top: 192px;
  width: 304px;
}

.student-date-calendar .course-C61RwL {
  background-color: transparent;
  height: auto;
  left: 400px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 346px;
  width: auto;
}

.student-date-calendar .description-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 496px;
  width: 24px;
}

.student-date-calendar .doubt-forum-C61RwL {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 10px;
  width: 168px;
}

.student-date-calendar .ellipse-2-2eLxbt {
  background-color: transparent;
  height: 1.73%;
  left: 17.49%;
  position: absolute;
  top: 98.29%;
  width: 0.99%;
}

.student-date-calendar .ellipse-2-OSIBbx {
  background-color: transparent;
  height: 19.05%;
  left: 57.14%;
  position: absolute;
  top: 21.43%;
  width: 19.05%;
}

.student-date-calendar .ellipse-3-2eLxbt {
  background-color: transparent;
  height: 10.25%;
  left: 43.23%;
  position: absolute;
  top: 58.43%;
  width: 5.77%;
}

.student-date-calendar .feb-2021-2eLxbt {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 0px;
  width: 142px;
}

.student-date-calendar .fri-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 433px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 36px;
}

.student-date-calendar .grade-C61RwL {
  background-color: transparent;
  height: auto;
  left: 400px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 303px;
  width: auto;
}

.student-date-calendar .group-0M78Jh {
  background-color: transparent;
  background-image: url(./img/vector-335@2x.png);
  background-size: 100% 100%;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-160-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  position: absolute;
  top: 718px;
  width: 24px;
}

.student-date-calendar .group-1G6kDx {
  background-color: transparent;
  background-image: url(./img/vector-180@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-date-calendar .group-22-C61RwL {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}

.student-date-calendar .group-23-C61RwL-0ds {
  background-color: transparent;
  height: 42.42%;
  left: 13%;
  position: absolute;
  top: 500px;
  width: 52.36%;
}

.student-date-calendar .group-26DuQS {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-date-calendar .group-30TA9U {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-date-calendar .group-3YhNjD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-3qdjky {
  background-color: transparent;
  background-image: url(./img/vector-177@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-date-calendar .group-4fKC6i {
  background-color: transparent;
  background-image: url(./img/vector-178@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-date-calendar .group-4wy4Bx {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-5YlXKz {
  background-color: transparent;
  background-image: url(./img/vector-178@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-date-calendar .group-5jt8cF {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-5r1RGr {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-date-calendar .group-8RWWXx {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-date-calendar .group-8SehwL {
  background-color: transparent;
  background-image: url(./img/vector-185@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-date-calendar .group-8mXtiA {
  background-color: transparent;
  background-image: url(./img/vector-185@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-date-calendar .group-Ambq12 {
  background-color: transparent;
  background-image: url(./img/vector-186@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-date-calendar .group-B0zdg8 {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-date-calendar .group-BVoyFW {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-date-calendar .group-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 22px;
  position: absolute;
  top: 422px;
  width: 20px;
}

.student-date-calendar .group-CZ3XDU {
  background-color: transparent;
  background-image: url(./img/vector-180@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-date-calendar .group-E9Z3Qz {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-date-calendar .group-Eub1uD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-FDKNYY {
  background-color: transparent;
  background-image: url(./img/vector-185@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-date-calendar .group-GQEqeI {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-GQNshl {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-Gv5bm4 {
  background-color: transparent;
  background-image: url(./img/vector-177@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-date-calendar .group-H4kFYy {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-date-calendar .group-Hc5Phf {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-date-calendar .group-HkDxar {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-I6cVpt {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-Ie5dUU {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-date-calendar .group-J1LPKs {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-K12spc {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-date-calendar .group-KLBpwU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-date-calendar .group-KWN2RE {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-date-calendar .group-KcOtnV {
  background-color: transparent;
  background-image: url(./img/vector-185@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-date-calendar .group-KsMB6y {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-date-calendar .group-LCuxKV {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-date-calendar .group-LMly0o {
  background-color: transparent;
  background-image: url(./img/vector-180@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-date-calendar .group-NOXmfT {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-OUAngl {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-date-calendar .group-SfTnen {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-date-calendar .group-SlOO8W {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-date-calendar .group-SlxGcy {
  background-color: transparent;
  background-image: url(./img/vector-177@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-date-calendar .group-Sw5xnU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-date-calendar .group-W5IipO {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-WAmUNE {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-date-calendar .group-X6Y0CP {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-date-calendar .group-XHqu7O {
  background-color: transparent;
  background-image: url(./img/vector-177@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-date-calendar .group-Z0P29R {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-date-calendar .group-Zmqz7u {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-date-calendar .group-a2vI4G {
  background-color: transparent;
  background-image: url(./img/vector-174@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-date-calendar .group-b1ls5q {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-date-calendar .group-bznq7Z {
  background-color: transparent;
  background-image: url(./img/vector-179@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-date-calendar .group-cTYyqt {
  background-color: transparent;
  background-image: url(./img/vector-174@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-date-calendar .group-ce7Ifp {
  background-color: transparent;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 20px;
}

.student-date-calendar .group-dimqSz {
  background-color: transparent;
  background-image: url(./img/vector-178@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-date-calendar .group-f6NdeG {
  background-color: transparent;
  background-image: url(./img/vector-179@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-date-calendar .group-fbkhx3 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-date-calendar .group-fxegOB {
  background-color: transparent;
  background-image: url(./img/vector-186@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-date-calendar .group-hNGwvV {
  background-color: transparent;
  background-image: url(./img/vector-174@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-date-calendar .group-hcfGEs {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-date-calendar .group-hoVMgD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-htgOUZ {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-date-calendar .group-hxa4BC {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-date-calendar .group-kK588U {
  background-color: transparent;
  background-image: url(./img/vector-179@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-date-calendar .group-lX9zMw {
  background-color: transparent;
  background-image: url(./img/vector-186@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-date-calendar .group-lmXTEf {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-date-calendar .group-m8nkkI {
  background-color: transparent;
  background-image: url(./img/vector-179@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-date-calendar .group-mnqxdV {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-date-calendar .group-oRRBjL {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-date-calendar .group-paC7Rl {
  background-color: transparent;
  background-image: url(./img/vector-180@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-date-calendar .group-pmZUQc {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-date-calendar .group-sx6xBo {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-tDnSYz {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-date-calendar .group-tr5zNE {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-date-calendar .group-wbAI42 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-date-calendar .group-wotaxO {
  background-color: transparent;
  background-image: url(./img/vector-186@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-date-calendar .group-wpJ3xR {
  background-color: transparent;
  background-image: url(./img/vector-178@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-date-calendar .group-xKrbSU {
  background-color: transparent;
  background-image: url(./img/vector-174@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-date-calendar .group-xfUToA {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-date-calendar .group-zkxeqe {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-date-calendar .help-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 570px;
  width: 24px;
}

.student-date-calendar .how-to-solve-this-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1034px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 532px;
  width: 290px;
}

.student-date-calendar .join-4-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 977px;
  overflow: hidden;
  position: absolute;
  top: 354px;
  width: 46px;
}

.student-date-calendar .join-5-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 977px;
  overflow: hidden;
  position: absolute;
  top: 441px;
  width: 46px;
}

.student-date-calendar .join-6-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 981px;
  overflow: hidden;
  position: absolute;
  top: 528px;
  width: 46px;
}

.student-date-calendar .join-7-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 984px;
  overflow: hidden;
  position: absolute;
  top: 612px;
  width: 46px;
}

.student-date-calendar .line-5-9p2GBv {
  background-color: transparent;
  height: 99.05%;
  left: 0.9%;
  position: absolute;
  top: 0.48%;
  width: 99.29%;
}

.student-date-calendar .line-6-9p2GBv {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}

.student-date-calendar .mon-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 62px;
}

.student-date-calendar .next-month-C61RwL {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 678px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 497px;
  width: auto;
}

.student-date-calendar .notifications-24px-2-1-C61RwL {
  background-color: transparent;
  height: 42px;
  left: 976px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 42px;
}

.student-date-calendar .pending-assignments-C61RwL {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  right: 20%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 737px;
  width: 218px;
}

.student-date-calendar .please-check-my-approach-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1037px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 616px;
  width: 290px;
}

.student-date-calendar .rectangle-24-C61RwL-stu-s1 {
  background-color: #1f2531;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 63px;

  overflow-y: scroll;
  padding: 150px 0px 20px 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.student-date-calendar .rectangle-32-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 59px;
  right: 6%;
  position: absolute;
  top: 771px;
  width: 30%;
}

.student-date-calendar .rectangle-33-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 59px;
  right: 6%;
  position: absolute;
  top: 850px;
  width: 30%;
}

.student-date-calendar .rectangle-42-C61RwL-003 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 114px;
  right: 6%;
  padding: 10px;
  position: absolute;
  top: 154px;
  width: 30%;
}

.student-date-calendar .rectangle-43-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* height: 417px; */
  right: 6%;
  position: absolute;
  top: 297px;
  width: 29%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding: 72px 24px 40px;
  min-height: 160px;
}

.student-date-calendar .rectangle-7-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 630px;
  position: absolute;
  top: 631px;
  width: 1px;
}

.student-date-calendar .sat-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 520px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 45px;
}

.student-date-calendar .sections-teaching-i61370653102-p4wM3n {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: 22.68%;
  left: 28.45%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 12.89%;
  width: 44.94%;
}

.student-date-calendar .sections-teaching-i61370853102-9wWKr1 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: 22.68%;
  left: 15.79%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 12.89%;
  width: 69.62%;
}

.student-date-calendar .span0-xWPrGR {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
}

.student-date-calendar .span1-xWPrGR {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
  font-weight: 400;
}

.student-date-calendar .stickynote2-24px-1-Cp4Be6 {
  background-color: transparent;
  height: 24px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 24px;
}

.student-date-calendar .subject-C61RwL {
  background-color: transparent;
  height: auto;
  left: 401px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 260px;
  width: auto;
}

.student-date-calendar .sun-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 616px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 54px;
}

.student-date-calendar .supportagent-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 792px;
  width: 24px;
}

.student-date-calendar .teacher-name-C61RwL {
  background-color: transparent;
  height: auto;
  left: 401px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 217px;
  width: auto;
}

.student-date-calendar .thu-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 329px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 53px;
}

.student-date-calendar .tue-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 112px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 51px;
}

.student-date-calendar .vector-0HfMqN {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-date-calendar .vector-1G6kDx {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-date-calendar .vector-1YY105 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-date-calendar .vector-1ewXKa {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-date-calendar .vector-26DuQS {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-date-calendar .vector-2kxNnt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-date-calendar .vector-30TA9U {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-date-calendar .vector-3q0WPs {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-date-calendar .vector-3qdjky {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-date-calendar .vector-4ae2Rf {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-date-calendar .vector-4fKC6i {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-date-calendar .vector-4sG9FF {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-date-calendar .vector-4vfNnN {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-date-calendar .vector-5S9pFx {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-date-calendar .vector-5YlXKz {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-date-calendar .vector-5r1RGr {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-date-calendar .vector-5x4wyG {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-date-calendar .vector-6XJ1dC {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}

.student-date-calendar .vector-7QdKbH {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-date-calendar .vector-7pUNkI {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-date-calendar .vector-7txfVx {
  background-color: transparent;
  height: 58.33%;
  left: 20.83%;
  position: absolute;
  top: 20.83%;
  width: 58.33%;
}

.student-date-calendar .vector-7uUfdl {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-date-calendar .vector-8JNBpx {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-date-calendar .vector-8RWWXx {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-date-calendar .vector-8SehwL {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-date-calendar .vector-8U01yU {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-date-calendar .vector-8XEWy4 {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-date-calendar .vector-8mXtiA {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-date-calendar .vector-8xAgQn {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-date-calendar .vector-9opORi {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-date-calendar .vector-9vYPU5 {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-date-calendar .vector-AONfqU {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-date-calendar .vector-Ambq12 {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-date-calendar .vector-AuhUI1 {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-calendar-dates-mobile .rectangle-44-rFX9Nh .doubt-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 4px;
}

.student-date-calendar .vector-B0zdg8 {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-date-calendar .vector-BVoyFW {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-date-calendar .vector-C61RwL {
  background-color: transparent;
  height: 2.34%;
  left: 1.32%;
  position: absolute;
  top: 92.77%;
  width: 1.67%;
}

.student-date-calendar .vector-CLdsug {
  background-color: transparent;
  height: 82.14%;
  left: 16.67%;
  position: absolute;
  top: 9.97%;
  width: 66.67%;
}

.student-date-calendar .vector-CMx3Uj {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-date-calendar .vector-CZ3XDU {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-date-calendar .vector-ChPPby {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-date-calendar .vector-DcBIgL {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-date-calendar .vector-Dn0VOv {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-date-calendar .vector-E3VqMR {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-date-calendar .vector-E9Z3Qz {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-date-calendar .vector-FDKNYY {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-date-calendar .vector-Fb0JQL {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-date-calendar .vector-FxTcxt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-date-calendar .vector-G0yBDR {
  background-color: transparent;
  height: 83.33%;
  left: 16.67%;
  position: absolute;
  top: 8.33%;
  width: 66.67%;
}

.student-date-calendar .vector-Gv5bm4 {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-date-calendar .vector-H4kFYy {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-date-calendar .vector-Hc5Phf {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-date-calendar .vector-JWb765 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-date-calendar .vector-K12spc {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-date-calendar .vector-KLBpwU {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-date-calendar .vector-KLFwa3 {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-date-calendar .vector-KRXr9B {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-date-calendar .vector-KWN2RE {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-date-calendar .vector-KcOtnV {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-date-calendar .vector-LCuxKV {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-date-calendar .vector-LMly0o {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-date-calendar .vector-LgtYic {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-date-calendar .vector-MWjU8S {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-date-calendar .vector-MtJxOL {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-date-calendar .vector-NLtoul {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-date-calendar .vector-OB8oOM {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-date-calendar .vector-OSIBbx {
  background-color: transparent;
  height: 1.19%;
  left: 49.4%;
  position: absolute;
  top: 49.4%;
  width: 1.19%;
}

.student-date-calendar .vector-OUAngl {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-date-calendar .vector-OYLvDa {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}

.student-date-calendar .vector-OsxaKh {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-date-calendar .vector-Ozaj6l {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-date-calendar .vector-QxuFcp {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-date-calendar .vector-SfTnen {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-date-calendar .vector-SlOO8W {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-date-calendar .vector-SlxGcy {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-date-calendar .vector-Sw5xnU {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-date-calendar .vector-T0rcQo {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-date-calendar .vector-Ta8Heo {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-date-calendar .vector-TlPffh {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-date-calendar .vector-TmFVy3 {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-date-calendar .vector-UUKtms {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-date-calendar .vector-UaM55c {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-date-calendar .vector-UxrCfI {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-date-calendar .vector-VAjk24 {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-date-calendar .vector-VJLVH1 {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-date-calendar .vector-VMr6Om {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 26.76%;
  width: 1.67%;
}

.student-date-calendar .vector-VP2Rux {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-date-calendar .vector-VdQhAS {
  background-color: transparent;
  height: 100%;
  left: 73.42%;
  position: absolute;
  top: 0px;
  width: 25.32%;
}

.student-date-calendar .vector-W1hXZ4 {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-date-calendar .vector-WAmUNE {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-date-calendar .vector-WK7UJi {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-date-calendar .vector-WVknxn {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-date-calendar .vector-WbzDQU {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-date-calendar .vector-X6Y0CP {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-date-calendar .vector-XHqu7O {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-date-calendar .vector-XQwS4n {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-date-calendar .vector-XjFi5l {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-date-calendar .vector-XqVjZB {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-date-calendar .vector-XwxuE1 {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-date-calendar .vector-YarvLR {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-date-calendar .vector-Z0P29R {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-date-calendar .vector-a2vI4G {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-date-calendar .vector-apzjgl {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-date-calendar .vector-b1ls5q {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-date-calendar .vector-baKUI6 {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-date-calendar .vector-bznq7Z {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-date-calendar .vector-c18eCt {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-date-calendar .vector-cTYyqt {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-date-calendar .vector-dBr6KK {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-date-calendar .vector-dLw05Z {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-date-calendar .vector-dimqSz {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-date-calendar .vector-dmgbrE {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-date-calendar .vector-dsyqYk {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-date-calendar .vector-eh9WzI {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-date-calendar .vector-f6NdeG {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-date-calendar .vector-fCysJq {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-date-calendar .vector-fbkhx3 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-date-calendar .vector-fxegOB {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-date-calendar .vector-g9aAPA {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-date-calendar .vector-gsSdey {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-date-calendar .vector-hNGwvV {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-date-calendar .vector-hPKSzt {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-date-calendar .vector-hcfGEs {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-date-calendar .vector-htgOUZ {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-date-calendar .vector-hxa4BC {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-date-calendar .vector-ib8Dlq {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-date-calendar .vector-irJBpK {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-date-calendar .vector-jQrdMY {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-date-calendar .vector-jmCVFs {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-date-calendar .vector-kK588U {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-date-calendar .vector-l8qsXH {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-date-calendar .vector-lWWNsc {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-date-calendar .vector-lX9zMw {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-date-calendar .vector-lmXTEf {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-date-calendar .vector-m8nkkI {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-date-calendar .vector-mmO9xv {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-date-calendar .vector-mzXdH9 {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 19.53%;
  width: 1.67%;
}

.student-date-calendar .vector-n1UKnn {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-date-calendar .vector-nXJ6dP {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-date-calendar .vector-oOS98D {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-date-calendar .vector-oRRBjL {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-date-calendar .vector-oZNlX3 {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-date-calendar .vector-objxfZ {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-date-calendar .vector-paC7Rl {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-date-calendar .vector-pagq92 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-date-calendar .vector-pgMXxb {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-date-calendar .vector-pmZUQc {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-date-calendar .vector-pxZ96q {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-date-calendar .vector-qF2ch0 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-date-calendar .vector-qGJxwJ {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-date-calendar .vector-qHVi3n {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-date-calendar .vector-qmzfLw {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-date-calendar .vector-qy1MCO {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-date-calendar .vector-r18CAb {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-date-calendar .vector-r4buxr {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-date-calendar .vector-rnzczl {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-date-calendar .vector-sCPPBx {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-date-calendar .vector-tDnSYz {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-date-calendar .vector-tQ6NQH {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}

.student-date-calendar .vector-tSUwp7 {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-date-calendar .vector-uhbuVM {
  background-color: transparent;
  height: 36.11%;
  left: 20.01%;
  position: absolute;
  top: 16.53%;
  width: 60%;
}

.student-date-calendar .vector-v9Qr6G {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-date-calendar .vector-vJyLWf {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-date-calendar .vector-wU39qv {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-date-calendar .vector-wbAI42 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-date-calendar .vector-wotaxO {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-date-calendar .vector-wpJ3xR {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-date-calendar .vector-x7OZie {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-date-calendar .vector-xKrbSU {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-date-calendar .vector-xYu4ww {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-date-calendar .vector-xfUToA {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-date-calendar .vector-xr8pKE {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-date-calendar .vector-xy9YKY {
  background-color: transparent;
  height: 11.11%;
  left: 30%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-date-calendar .vector-ydL8U7 {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-date-calendar .vector-ye3NYT {
  background-color: transparent;
  height: 11.11%;
  left: 60%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-date-calendar .vector-ywnyBE {
  background-color: transparent;
  height: 83.33%;
  left: 8.33%;
  position: absolute;
  top: 8.33%;
  width: 83.33%;
}

.student-date-calendar .vector-zJJzco {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-date-calendar .vector-zLY8MO {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-date-calendar .vector-zkxeqe {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-date-calendar .view-all-C61RwL {
  background-color: transparent;
  height: auto;
  right: 8%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 739px;
  width: 53px;
}

.student-date-calendar .view-all-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 80%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 75px;
}

.student-date-calendar .view-forum-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 391px;
  width: 113px;
}

.student-date-calendar .view-forum-QxM5SU {
  background-color: transparent;
  height: auto;
  left: 1233px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 647px;
  width: 113px;
}

.student-date-calendar .view-forum-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 1230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 467px;
  width: 113px;
}

.student-date-calendar .view-forum-mzXdH9 {
  background-color: transparent;
  height: auto;
  left: 1230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 563px;
  width: 113px;
}

.student-date-calendar .wed-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 214px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 65px;
}

.student-date-calendar .what-is-the-si-unit-of-current-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1034px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 360px;
  width: 290px;
}

.student-date-calendar .what-is-the-sol-f-this-question-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1034px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 436px;
  width: 290px;
}

.student-date-calendar .x1-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 439px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 121px;
  width: 13px;
}

.student-date-calendar .x10-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 622px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 191px;
  width: 29px;
}

.student-date-calendar .x11-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 260px;
  width: 22px;
}

.student-date-calendar .x12-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 119px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 260px;
  width: 27px;
}

.student-date-calendar .x13-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 225px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 260px;
  width: 27px;
}

.student-date-calendar .x14-2eLxbt {
  background-color: transparent;
  color: var(--wild-sand);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 334px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 260px;
  width: 29px;
}

.student-date-calendar .x14-C61RwL {
  background-color: transparent;
  color: var(--wild-sand);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 1166px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 450px;
  width: auto;
}

.student-date-calendar .x15-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 432px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 260px;
  width: 27px;
}

.student-date-calendar .x16-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 521px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 260px;
  width: 27px;
}

.student-date-calendar .x17-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 622px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 260px;
  width: 27px;
}

.student-date-calendar .x18-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 12px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 330px;
  width: 29px;
}

.student-date-calendar .x19-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 119px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 330px;
  width: 27px;
}

.student-date-calendar .x2-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 526px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 121px;
  width: 18px;
}

.student-date-calendar .x20-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 222px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 330px;
  width: 35px;
}

.student-date-calendar .x21-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 334px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 330px;
  width: 27px;
}

.student-date-calendar .x22-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 430px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 330px;
  width: 33px;
}

.student-date-calendar .x23-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 519px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 330px;
  width: 33px;
}

.student-date-calendar .x24-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 618px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 330px;
  width: 35px;
}

.student-date-calendar .x25-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 400px;
  width: 33px;
}

.student-date-calendar .x26-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 117px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 400px;
  width: 33px;
}

.student-date-calendar .x27-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 224px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 400px;
  width: 33px;
}

.student-date-calendar .x28-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 330px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 400px;
  width: 35px;
}

.student-date-calendar .x28-XMxXA9 {
  background-color: transparent;
  height: auto;
  left: 12px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 121px;
  width: 35px;
}

.student-date-calendar .x29-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 430px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 400px;
  width: 33px;
}

.student-date-calendar .x29-XMxXA9 {
  background-color: transparent;
  height: auto;
  left: 119px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 121px;
  width: 33px;
}

.student-date-calendar .x3-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 627px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 121px;
  width: 18px;
}

.student-date-calendar .x30-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 517px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 400px;
  width: 35px;
}

.student-date-calendar .x30-XMxXA9 {
  background-color: transparent;
  height: auto;
  left: 225px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 121px;
  width: 35px;
}

.student-date-calendar .x31-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 622px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 400px;
  width: 27px;
}

.student-date-calendar .x31-XMxXA9 {
  background-color: transparent;
  height: auto;
  left: 338px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 121px;
  width: 27px;
}

.student-date-calendar .x4-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 191px;
  width: 20px;
}

.student-date-calendar .x4-i61370653103-p4wM3n {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: 40.21%;
  left: 26.4%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 40.72%;
  width: 48.73%;
}

.student-date-calendar .x4-i61370753103-5mfU7d {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: 40.21%;
  left: 50.09%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 40.72%;
  width: 0.66%;
}

.student-date-calendar .x4-i61370853103-9wWKr1 {
  background-color: transparent;
  color: rgba(5, 187, 138, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: 40.21%;
  left: 30.2%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 40.72%;
  width: 40.51%;
}

.student-date-calendar .x5-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 124px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 191px;
  width: 18px;
}

.student-date-calendar .x6-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 231px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 191px;
  width: 18px;
}

.student-date-calendar .x7-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 339px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 191px;
  width: 18px;
}

.student-date-calendar .x8-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 435px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 191px;
  width: 20px;
}

.student-date-calendar .x9-2eLxbt {
  background-color: transparent;
  height: auto;
  left: 526px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 191px;
  width: 18px;
}

.student-date-calendar .you-have-no-upcoming-tests-C61RwL {
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  width: 349px;
}

:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --nobel: rgba(179, 179, 179, 1);
  --white: rgba(255, 255, 255, 1);
  --wild-sand: rgba(244, 244, 244, 1);
}

@media screen and (max-width: 599px) {
  .student-date-calendar {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .student-calendar-dates-mobile {
    display: none;
  }
}

.flex-col-vgli2x-student-dash {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  position: relative;
  width: max-content;
}

.biba-sobti-h8V8ZR-student-dash {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: relative;
  text-align: left;
  width: auto;
}
.bibasobtigmailcom-h8V8ZR-student-dash {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 2%;
  position: relative;
  text-align: left;
  width: auto;
}
.vector-vgli2x-student-dash {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
