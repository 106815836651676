.forget_outer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.forget_layout {
  width: 35%;
  margin-top: 80px;
  max-height: 520px;
  background-color: rgba(255, 255, 255, 0.966);
  box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.2), 0 10px 10px 0 rgba(0, 0, 0, 0.24);
  border-radius: 14px;
  padding: 24px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.forget_Page_Heading {
  margin: 10px;
  padding: 10px;
  text-align: center;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #e3241d;
}

.forget_Form_Handler {
  margin: 10px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forget_Form_Input {
  width: 100%;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  outline: 0;
  font-size: 18px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 25px !important;
}

.forget_Butt_Sub {
  width: 98%;
  height: 42px;
  padding: 8px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  outline: 0;
  font-size: 18px;
  font-weight: bolder;
  border-radius: 8px;
  border: 0;
  background: #e3241d;
  color: #ffffff;
  cursor: pointer;
}

.forget_back {
  display: flex;
  justify-content: flex-start;
  margin-top: 18px;
  float: left;
  transition: all linear 0.2s;
}

.forget_back .icon {
  padding: 2px;
  color: #b3b3b3;
  font-size: 14px;
  transition: all linear 0.2s;
}

.forget_back:hover *{
  transform: scale(1.1, 1.1);
  color: black;
  font-weight: 600;
  cursor: pointer;
}


.forget_Message {
  padding: 2px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  color: #b3b3b3;
  font-size: 14px;
  transition: all linear 0.2s;
}

@media screen and (max-width: 840px) {
  .forget_layout {
    width: 90%;
    max-height: 480px;
    margin: 30px 0px;
  }

  .forget_Page_Heading {
    width: 90%;
    margin: 5px;
    padding: 8px;
    font-weight: 700;
    font-size: 22px;
  }

  .forget_Form_Input {
    font-size: 16px;
  }

  .forget_Butt_Sub {
    height: 35px;
    font-size: 16px;
    font-weight: bold;
  }
}

@media screen and (min-width: 840px) and (max-width: 1200px) {
  .forget_layout {
      width: 50%;
  }
}