/* screen - higher-committee-ticket-list */

.higher-committee-ticket-list {
  align-items: flex-start;
  background-color: var(--coconut);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
} 
/* .higher-committee-ticket-list .accountbalance-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.higher-committee-ticket-list .analytics-24px-4-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
} */
.higher-committee-ticket-list .biba-sobti-vgli2x {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2px;
  margin-top: 20px;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.higher-committee-ticket-list .bibasobtigmailcom-vgli2x {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2px;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.higher-committee-ticket-list .category-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 301px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 76px;
}
.higher-committee-ticket-list .category-fqPQjE {
  background-color: transparent;
  height: auto;
  left: 625px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 85px;
}
.higher-committee-ticket-list .class-name-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 359px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 100px;
}
.higher-committee-ticket-list .component-1-GyJUNH {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.higher-committee-ticket-list .dashboard-24px-1-1UTYTe {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 596px;
  overflow: hidden;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.higher-committee-ticket-list .dashboard-24px-1-PXwdVf {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 374px;
  overflow: hidden;
  padding: 0 11.8px;
  position: absolute;
  top: 49px;
  width: 24px;
}
.higher-committee-ticket-list .date-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 628px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 224px;
  width: 44px;
}
.higher-committee-ticket-list .date-fqPQjE {
  background-color: transparent;
  height: auto;
  left: 536px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 59px;
}
.higher-committee-ticket-list .ellipse-2-RYyMFx {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.higher-committee-ticket-list .flex-col-C61RwL {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 63px;
  padding-left: 4%;
  margin-top: 45px;
  min-height: 980px;
  position: relative;
  width: calc(100% - 63px);
}
.higher-committee-ticket-list .flex-col-QkTteQ {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 703px;
  position: relative;
  width: 60%;
}
.higher-committee-ticket-list .flex-row-0xy0vn {
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 105px;
  justify-content: space-between;
  position: relative;
  width: 90%;
  margin-right: 8%;
}
.higher-committee-ticket-list .flex-row-axsvJs {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.higher-committee-ticket-list .flex-row-oV5UrE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 720px;
  justify-content: flex-start;
  margin-top: 62px;
  position: relative;
  width: 100%;
}
.higher-committee-ticket-list .group-117-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 66px;
  width: 728px;
}
.higher-committee-ticket-list .group-118-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 126px;
  width: 728px;
}
.higher-committee-ticket-list .group-119-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 178px;
  width: 728px;
}
.higher-committee-ticket-list .group-120-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 234px;
  width: 728px;
}
.higher-committee-ticket-list .group-121-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 290px;
  width: 728px;
}
.higher-committee-ticket-list .group-122-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 346px;
  width: 728px;
}
.higher-committee-ticket-list .group-123-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 412px;
  width: 728px;
}
.higher-committee-ticket-list .group-124-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 478px;
  width: 728px;
}
.higher-committee-ticket-list .group-125-4xLMVj {
  background-color: #dc3545;
  border-radius: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 318px;
}
.higher-committee-ticket-list .group-202-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 83px;
  min-height: 637px;
  position: relative;
  width: 421px;
}
.higher-committee-ticket-list .group-204-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-right: 59px;
  margin-top: 49px;
  position: relative;
  width: 320px;
}
.higher-committee-ticket-list .group-209-C61RwL {
  align-items: flex-end;
  background-color: #1f2531;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 1024px;
  padding: 50px 18px;
  position: relative;
  width: 63px;
}
.higher-committee-ticket-list .username-container {
  width: auto;
  display: flex;
}
.higher-committee-ticket-list .group-22-GyJUNH {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 8px;
  margin-left: 22px;
  min-height: 57px;
  position: relative;
  width: 225px;
}
.higher-committee-ticket-list .group-45-1UTYTe {
  background-color: transparent;
  height: 53px;
  left: 0px;
  position: absolute;
  top: 6px;
  width: 759px;
}
.higher-committee-ticket-list .group-56-PXwdVf-089 {
  background-color: var(--coconut);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  /* left: 33px;
  position: absolute;
  top: 10px; */
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 15px;
  padding: 10px 15px;
}

.higher-committee-ticket-list .group-91-C8nS5Z {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 369px;
}
.higher-committee-ticket-list .group-92-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 365px;
}
.higher-committee-ticket-list .group-94-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 365px;
}
.higher-committee-ticket-list .group-95-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 365px;
}
.higher-committee-ticket-list .group-96-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 365px;
}
.higher-committee-ticket-list .group-99-C8nS5Z {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 351px;
  width: 369px;
}
.higher-committee-ticket-list .group-AAVFy6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.higher-committee-ticket-list .group-XDTIuB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.higher-committee-ticket-list .group-aTF8dW {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.higher-committee-ticket-list .group-rAjf8W {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.higher-committee-ticket-list .help-24px-2-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.higher-committee-ticket-list .historytoggleoff-24px-1-D0BVcB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.higher-committee-ticket-list .in-progess-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 210px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 72px;
}
.higher-committee-ticket-list .issue-id-fqPQjE {
  background-color: transparent;
  height: auto;
  left: 24px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 80px;
}
.higher-committee-ticket-list .kunal-kashyap-mHjvIC {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 105px;
}
.higher-committee-ticket-list .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.higher-committee-ticket-list .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.higher-committee-ticket-list .messages-4xLMVj {
  background-color: transparent;
  height: auto;
  left: 64px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 11px;
  width: auto;
}
.higher-committee-ticket-list .my-tickets-Fjgz1C-xzs {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 26px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.higher-committee-ticket-list .next-PXwdVf {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 711px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}
.higher-committee-ticket-list .notifications-24px-2-1-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-bottom: 13px;
  margin-left: 643px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.higher-committee-ticket-list .open-issue-list-4FsOUs {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 5px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.higher-committee-ticket-list .overlap-group-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 575px;
  margin-top: 22px;
  position: relative;
  width: 100%;
}
.higher-committee-ticket-list .overlap-group1-Fjgz1C {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 59px;
  margin-top: 21px;
  position: relative;
  width: 100%;
}
.higher-committee-ticket-list .overlap-group2-4FsOUs {
  background-color: transparent;
  flex-shrink: 1;
  height: 600px;
  margin-top: 14px;
  position: relative;
  width: 383px;
}
.higher-committee-ticket-list .pending-lb6EYp {
  background-color: transparent;
  height: auto;
  left: 209px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 56px;
}
.higher-committee-ticket-list .rectangle-20-fqPQjE0-hc-t {
  background-color: #dc3545;
  border-radius: 29px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;

  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 15px 25px;
}
.higher-committee-ticket-list .rectangle-30-PXwdVf-092-1 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 575px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;

  padding: 15px;
}

.payga4wk-z-bshl-Sgr7kw-102-tic {
  text-align: center;
  min-width: 68px;
}
.x499900-Sgr7kw-102-tic {
  text-align: center;
  min-width: 100px;
}
.x07-dec-2020-Sgr7kw-102-tic {
  text-align: center;
  min-width: 110px;
}

.x014009-pm-Sgr7kw-tic {
  text-align: center;
  min-width: 110px;
}
.x014009-pm-Sgr7kw-tic-1 {
  text-align: center;
  min-width: 120px;
}

.andb586-Sgr7kw-102-tic {
  text-align: center;
  min-width: 75px;
}

.rectangle-31-C8nS5Z {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 600px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.higher-committee-ticket-list .rectangle-40-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.higher-committee-ticket-list .rectangle-41-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.higher-committee-ticket-list .rectangle-47-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.higher-committee-ticket-list .rectangle-48-lb6EYp {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.higher-committee-ticket-list .rectangle-48-mHjvIC {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.higher-committee-ticket-list .rectangle-7-PXwdVf {
  background-color: transparent;
  height: 1px;
  left: 525px;
  position: absolute;
  top: 274px;
  width: 1px;
}
.higher-committee-ticket-list .s-no-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.higher-committee-ticket-list .s-no-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 56px;
}
.higher-committee-ticket-list .school-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.higher-committee-ticket-list .shreyans-jain-lb6EYp {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 93px;
}
.higher-committee-ticket-list .sos-3H8fcZ {
  background-color: transparent;
  height: auto;
  left: 315px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 34px;
}
.higher-committee-ticket-list .sos-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 315px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 34px;
}
.higher-committee-ticket-list .span0-xITcw8 {
  color: #dc3545;
  font-style: normal;
}
.higher-committee-ticket-list .span1-xITcw8 {
  color: #dc3545;
  font-style: normal;
  font-weight: 600;
}
.higher-committee-ticket-list .standard-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 301px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 366px;
  width: 61px;
}
.higher-committee-ticket-list .status-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 220px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.higher-committee-ticket-list .student-classroom-fqPQjE {
  background-color: transparent;
  height: auto;
  left: 135px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 183px;
}
.higher-committee-ticket-list .student-name-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 83px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 107px;
}
.higher-committee-ticket-list .student-name-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 118px;
}
.higher-committee-ticket-list .ticket-mHjvIC {
  background-color: transparent;
  height: auto;
  left: 211px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 47px;
}
.higher-committee-ticket-list .ticket-status-fqPQjE {
  background-color: transparent;
  height: auto;
  left: 357px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 123px;
}
.higher-committee-ticket-list .time-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 724px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 48px;
}
.higher-committee-ticket-list .vatsal-agarwal-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 85px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 104px;
}
.higher-committee-ticket-list .vector-1duCl1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.higher-committee-ticket-list .vector-2CQzkB {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.higher-committee-ticket-list .vector-2xKpiG {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.higher-committee-ticket-list .vector-40xw7g {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.higher-committee-ticket-list .vector-4xLMVj {
  background-color: transparent;
  height: 12px;
  left: 282px;
  position: absolute;
  top: 17px;
  width: 18px;
}
.higher-committee-ticket-list .vector-AxqJxr {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.higher-committee-ticket-list .vector-Axu9vm {
  background-color: transparent;
  height: 30px;
  left: 10px;
  position: absolute;
  top: 7px;
  width: 30px;
}
.higher-committee-ticket-list .logout-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 356px;
  position: relative;
  width: 24px;
}
.higher-committee-ticket-list .vector-BRkMYc {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.higher-committee-ticket-list .vector-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.higher-committee-ticket-list .vector-GyJUNH {
  align-self: flex-start;
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 7px;
  margin-top: 6px;
  position: relative;
  width: 80px;
}
.higher-committee-ticket-list .vector-I29KJn {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.higher-committee-ticket-list .vector-NlXsxj {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.sider-hc-teacher-list {
  width: 50px;
  height: 100vh;

  background-color: #1f2531;
}

.sider-hc-teacher-list-in-wdt-80 {
  width: 100%;
  height: 70%;
  margin: 120px 0px 30px;
  color: gray;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.icon-in-hc-color {
  color: rgba(179, 179, 179, 1);
  font-size: 24px;
}
.higher-committee-ticket-list .vector-OUzaf1 {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.higher-committee-ticket-list .vector-RYyMFx {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.higher-committee-ticket-list .vector-WxmxmD {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.higher-committee-ticket-list .vector-XxcEsk {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.higher-committee-ticket-list .vector-YPcxtl {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.higher-committee-ticket-list .vector-Zppvke {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.higher-committee-ticket-list .vector-axsvJs {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.higher-committee-ticket-list .vector-uxDhU3 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.higher-committee-ticket-list .vector-x0nTHj {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.higher-committee-ticket-list .vector-x6oIim {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.higher-committee-ticket-list .x1-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 15px;
}
.higher-committee-ticket-list .x1-PXwdVf {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 645px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}
.higher-committee-ticket-list .x2-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 130px;
  width: 15px;
}
.higher-committee-ticket-list .x2-PXwdVf {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 667px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}
.higher-committee-ticket-list .x3-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 189px;
  width: 15px;
}
.higher-committee-ticket-list .x3-PXwdVf {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 689px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}
.higher-committee-ticket-list .x4-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 15px;
}
.higher-committee-ticket-list .x5-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 304px;
  width: 15px;
}
.higher-committee-ticket-list .x6-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 366px;
  width: 15px;
}
.higher-committee-ticket-list .x7-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 422px;
  width: 15px;
}
.higher-committee-ticket-list .x8-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 481px;
  width: 15px;
}
.higher-committee-ticket-list .x9-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 540px;
  width: 15px;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-bold-white-10px {
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-white-20px {
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - higher-committee-ticket-mobile */

.higher-committee-ticket-mobile {
  align-items: flex-end;
  background-color: var(--coconut);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 1552px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 6px;
  position: relative;
  width: 414px;
}
.higher-committee-ticket-mobile .booked-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 286px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 477px;
  width: 50px;
}
.higher-committee-ticket-mobile .booked-rg1zwV {
  background-color: transparent;
  height: auto;
  left: 284px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 50px;
}
.higher-committee-ticket-mobile .category-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 263px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 92px;
}
.higher-committee-ticket-mobile .category-aIq8ji {
  background-color: transparent;
  height: auto;
  left: 290px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 13px;
  width: 66px;
}
.higher-committee-ticket-mobile .class-name-eVSctS {
  background-color: transparent;
  height: auto;
  left: 167px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 48px;
}
.higher-committee-ticket-mobile .completed-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 184px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 74px;
}
.higher-committee-ticket-mobile .component-1-dXBp89 {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.higher-committee-ticket-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.higher-committee-ticket-mobile .dashboard-24px-1-759J8x {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 288px;
  overflow: hidden;
  padding: 0 5.3px;
  position: absolute;
  top: 0px;
  width: 11px;
}
.higher-committee-ticket-mobile .date-aIq8ji {
  background-color: transparent;
  height: auto;
  left: 241px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 13px;
  width: 35px;
}
.higher-committee-ticket-mobile .date-eVSctS {
  background-color: transparent;
  height: auto;
  left: 292px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 250px;
  width: 22px;
}
.higher-committee-ticket-mobile .flex-row-C61RwL {
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.higher-committee-ticket-mobile .group-124-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 47px;
  width: 348px;
}
.higher-committee-ticket-mobile .group-125-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 87px;
  width: 348px;
}
.higher-committee-ticket-mobile .group-126-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 127px;
  width: 348px;
}
.higher-committee-ticket-mobile .group-127-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-right: 3px;
  margin-top: 58px;
  min-height: 605px;
  position: relative;
  width: 379px;
}
.higher-committee-ticket-mobile .group-127-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 167px;
  width: 348px;
}
.higher-committee-ticket-mobile .group-128-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 207px;
  width: 348px;
}
.higher-committee-ticket-mobile .group-129-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 247px;
  width: 348px;
}
.higher-committee-ticket-mobile .group-130-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 287px;
  width: 348px;
}
.higher-committee-ticket-mobile .group-131-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 327px;
  width: 348px;
}
.higher-committee-ticket-mobile .group-132-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 367px;
  width: 348px;
}
.higher-committee-ticket-mobile .group-133-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 407px;
  width: 348px;
}
.higher-committee-ticket-mobile .group-134-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 447px;
  width: 348px;
}
.higher-committee-ticket-mobile .group-210-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 72px;
  min-height: 581px;
  position: relative;
  width: 381px;
}
.higher-committee-ticket-mobile .group-45-759J8x {
  background-color: transparent;
  height: 42px;
  left: 0px;
  position: absolute;
  top: 6px;
  width: 369px;
}
.higher-committee-ticket-mobile .group-56-eVSctS-01010 {
  background-color: var(--coconut);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;

  width: 335px;
  margin-top: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
}

.payga4wk-z-bshl-Sgr7kw-chan-in {
  text-align: center;
  line-height: 30px;
  min-width: 30px;
}
.x499900-Sgr7kw-chan-in {
  text-align: center;
  min-width: 43px;
}
.x07-dec-2020-Sgr7kw-chan-in {
  text-align: center;
  min-width: 55px;
}
.x014009-pm-Sgr7kw-chan-in {
  text-align: center;
  min-width: 95px;
}
.andb586-Sgr7kw-chan-in {
  text-align: center;
  min-width: 38px;
}

.montserrat-semi-bold-blue-zodiac-8px {
  font-size: 9px;
  font-weight: 600;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.higher-committee-ticket-mobile .group-90-SuOXvz {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 170px;
  width: 346px;
}
.higher-committee-ticket-mobile .group-91-SuOXvz {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 464px;
  width: 348px;
}
.higher-committee-ticket-mobile .group-92-SuOXvz {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 287px;
  width: 346px;
}
.higher-committee-ticket-mobile .group-94-SuOXvz {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 405px;
  width: 346px;
}
.higher-committee-ticket-mobile .group-95-SuOXvz {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 346px;
  width: 346px;
}
.higher-committee-ticket-mobile .group-96-SuOXvz {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 233px;
  width: 346px;
}
.higher-committee-ticket-mobile .issue-id-aIq8ji {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 13px;
  width: 57px;
}
.higher-committee-ticket-mobile .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.higher-committee-ticket-mobile .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.higher-committee-ticket-mobile .my-tickets-2zqKZg {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 10px;
  min-height: 26px;
  position: relative;
  width: 308px;
}
.higher-committee-ticket-mobile .next-eVSctS {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 322px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 31px;
}
.higher-committee-ticket-mobile .open-issue-list-Uoyr1g {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 4px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.higher-committee-ticket-mobile .opened-8KZUmx {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.higher-committee-ticket-mobile .opened-GW4GxG {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.higher-committee-ticket-mobile .opened-VzbLLP {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.higher-committee-ticket-mobile .opened-dKpRzw {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.higher-committee-ticket-mobile .opened-qtwhT7 {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.higher-committee-ticket-mobile .organic-4-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 69px;
}
.higher-committee-ticket-mobile .organic-5-mVyX3I {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.higher-committee-ticket-mobile .overlap-group-2zqKZg {
  background-color: transparent;
  flex-shrink: 1;
  height: 511px;
  margin-left: -0px;
  margin-top: 7px;
  position: relative;
  width: 361px;
}
.higher-committee-ticket-mobile .overlap-group1-2zqKZg {
  background-color: transparent;
  flex-shrink: 1;
  height: 48px;
  margin-left: 0px;
  margin-top: 13px;
  position: relative;
  width: 369px;
}
.higher-committee-ticket-mobile .overlap-group2-Uoyr1g {
  background-color: transparent;
  flex-shrink: 1;
  height: 546px;
  margin-top: 12px;
  position: relative;
  width: 359px;
}
.higher-committee-ticket-mobile .rectangle-20-aIq8ji-mob-hc-t {
  background-color: #dc3545;
  border-radius: 29px;
  height: 42px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
}
.higher-committee-ticket-mobile .rectangle-30-eVSctS-chn {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 511px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;

  padding: 8px;
}
.higher-committee-ticket-mobile .rectangle-31-SuOXvz {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 546px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.higher-committee-ticket-mobile .rectangle-40-SuOXvz {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 52px;
  width: 342px;
}
.higher-committee-ticket-mobile .rectangle-41-SuOXvz {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 7px;
  position: absolute;
  top: 170px;
  width: 342px;
}
.higher-committee-ticket-mobile .rectangle-44-8KZUmx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.higher-committee-ticket-mobile .rectangle-44-VzbLLP {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.higher-committee-ticket-mobile .rectangle-47-SuOXvz {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 111px;
  width: 342px;
}
.higher-committee-ticket-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.higher-committee-ticket-mobile .rectangle-48-mVyX3I {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.higher-committee-ticket-mobile .rectangle-49-GW4GxG {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.higher-committee-ticket-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.higher-committee-ticket-mobile .rectangle-49-dKpRzw {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.higher-committee-ticket-mobile .rectangle-49-qtwhT7 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.higher-committee-ticket-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.higher-committee-ticket-mobile .s-no-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 42px;
}
.higher-committee-ticket-mobile .s-no-eVSctS {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 27px;
}
.higher-committee-ticket-mobile .span0-6gnXOk {
  color: #dc3545;
  font-style: normal;
}
.higher-committee-ticket-mobile .span1-6gnXOk {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
  font-weight: 500;
}
.higher-committee-ticket-mobile .span2-6gnXOk {
  color: #dc3545;
  font-style: normal;
  font-weight: 600;
}
.higher-committee-ticket-mobile .status-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 49px;
}
.higher-committee-ticket-mobile .student-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 78px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 94px;
}
.higher-committee-ticket-mobile .student-classroom-aIq8ji {
  background-color: transparent;
  height: auto;
  left: 73px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6px;
  width: 89px;
}
.higher-committee-ticket-mobile .student-name-eVSctS {
  background-color: transparent;
  height: auto;
  left: 62px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 56px;
}
.higher-committee-ticket-mobile .ticket-status-aIq8ji {
  background-color: transparent;
  height: auto;
  left: 154px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6px;
  width: 83px;
}
.higher-committee-ticket-mobile .time-eVSctS {
  background-color: transparent;
  height: auto;
  left: 337px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 23px;
}
.higher-committee-ticket-mobile .upcoming-mVyX3I {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.higher-committee-ticket-mobile .vector-L9XHu9 {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.higher-committee-ticket-mobile .x1-eVSctS {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 7px;
}
.higher-committee-ticket-mobile .x12pm-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 44px;
}
.higher-committee-ticket-mobile .x1pm-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 128px;
  width: 31px;
}
.higher-committee-ticket-mobile .x2-eVSctS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 292px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 9px;
}
.higher-committee-ticket-mobile .x2pm-GW4GxG {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.higher-committee-ticket-mobile .x2pm-dKpRzw {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.higher-committee-ticket-mobile .x2pm-qtwhT7 {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.higher-committee-ticket-mobile .x3-eVSctS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 307px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 9px;
}
.higher-committee-ticket-mobile .x3pm-mVyX3I {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.higher-committee-ticket-mobile .x4pm-8KZUmx {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.higher-committee-ticket-mobile .x6pm-VzbLLP {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-bold-white-14px {
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-white-20px {
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}

.MuiSvgIcon-root {
  fill: rgb(179, 179, 179);
}

@media screen and (max-width: 599px) {
  .higher-committee-ticket-list {
    display: none;
  }
  .sider-hc-ticket-list {
    display: none;
  }

  .Rahkfaj-sdank23k {
    padding-right: 7px;
    overflow-y: auto;
  }
}
@media screen and (min-width: 600px) {
  .higher-committee-ticket-mobile {
    display: none;
  }
}

.higher-committee-ticket-list .sider-hc-ticket-list {
  width: 65px;
  height: 100%;
  z-index: 10;

  position: fixed;
  top: 0;
  background-color: #212a3b;
}

.higher-committee-ticket-list .Rahkfaj-sdank23k {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;
}

@media screen and (max-width: 992px) {
 .higher-committee-ticket-list .flex-col-QkTteQ {
    width: 80%;
  }
}