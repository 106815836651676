* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.conatiner {
  width: 100%;
  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wraper {
  width: 90%;
  padding: 5px;
  
}

li {
  list-style-type: none;
  
}

/* Marquee styles */
.marquee {
  --gap: 0rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  width: 100%;
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: fit-content;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

/* Enable animation */
.enable-animation .marquee__content {
  animation: scroll 25s linear infinite;
}

/* Attempt to size parent based on content. Keep in mind that the parent width is equal to both content containers that stretch to fill the parent. */
.marquee--fit-content {
  max-width: fit-content;
}

/* A fit-content sizing fix: Absolute position the duplicate container. This will set the size of the parent wrapper to a single child container. Shout out to Olavi's article that had this solution 👏 @link: https://olavihaapala.fi/2021/02/23/modern-marquee.html  */
.marquee--pos-absolute .marquee__content:last-child {
  position: absolute;
  top: 0;
  left: 0;
}

/* Enable position absolute animation on the duplicate content (last-child) */
.enable-animation .marquee--pos-absolute .marquee__content:last-child {
  animation-name: scroll-abs;
}

@keyframes scroll-abs {
  from {
    transform: translateX(calc(100% + var(--gap)));
  }
  to {
    transform: translateX(0);
  }
}

/* Other page demo styles */
.marquee__content > * {
  flex: 0 0 auto;
  color: #dc3545;
  font-size: 20px;
  font-weight: 600;
  margin: 2px;
  padding: 5px 2rem;
  border-radius: 0.25rem;
  text-align: center;
}
