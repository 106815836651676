.Backdrop {
  position: fixed;
  left: 0;
  top: 0;

  z-index: 400;
  width: 100%;
  height: 100%;

  background-color: black;
  opacity: 0.7;
}

.Open {
  display: block;
}

.Close {
  display: none;
}
