.Header {
  z-index: -10;
  background: white;
  height: 52px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin: 1.2em;
  font-size: 18px;
}

.heder-men-not {
  font-size: 18px;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.notification {
  margin: 1em;
}

.menu {
  margin: 1.2em;
}
