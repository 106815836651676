.headers {
  font-family: "'Montserrat', Arial, Helvetica, sans-serif";
  letter-spacing: 2;
  font-weight: 600;
  font-size: 26px;
  color: #fff;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px 0;
}

@media screen and (max-width: 768px) {
  .headers {
    font-size: 20px;
  }
}
