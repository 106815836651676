/* This is handling the whole status component layout */
.status-display {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

/* This handles the fist block - teacher status */
.status1 {
  padding: 7px;
  width: 90%;
  margin-top: 20px;
  background-color: #dc3545;

  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 20px;

  display: flex;
  justify-content: space-between;
}

.status2 {
  padding: 7px;
  width: 90%;
  margin-top: 30px;
  background-color: rgb(247, 195, 195);

  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status3 {
  padding: 7px;
  width: 90%;
  margin-top: 30px;
  background-color: rgb(250, 228, 228);
  border: 1px solid white;
  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 20px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.content-teacher {
  width: auto;
  padding: 5px;
}

.content-teacher-in {
  color: white;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.2em; 
    display: flex; 
    flex-direction: column;
    line-height: 36px;
    padding-left: 9px;
}

.content-status {
  margin-top: 15px;
  width: 80%;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 10px;
  color: white;

  display: flex;
  flex-direction: column;
}

.content-status-in {
  margin: 5px;
  color: rgba(126, 126, 126, 1);
  display: flex;
  align-items: center;
}

.Logo-in {
  width: auto;
  min-height: 103px;

  margin: 5px;
}

.class-button {
  margin-top: 20px;
  width: 150px;
  height: 28px;
  background-color: #05bb8a;
  border: 1px solid #06091b;
  border-radius: 10px;
}

.font-family-mont {
  width: auto;
  padding-top: 30px;
  padding-left: 15px;

  display: flex;
  flex-direction: column;
}

.font-family-mont-1_1p {
  padding: 1px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: grey;
}

.font-family-mont-2_2p {
  padding: 1px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;

  color: grey;
}

.font-family-mont-3_3p {
  padding: 1px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;

  color: black;
}

.content-teacher-in-my {
  width: 100%;
  margin-top: 4px;
}

.my-class-v {
  display: flex;
  justify-content: space-between;
}

.my-class-v-in {
  padding-left: 5px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.2em;

  color: rgb(220, 53, 69);
}

.content-right {
  width: 65px;
  height: 15px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.7em;

  color: rgba(126, 126, 126, 1);
}

.cast-for-edu {
  width: auto;
  padding: 4px;
  margin: 7px;

  border-radius: 20px;

  background-color: #132547;
  color: #f4f4f4;
}

.x5834-1-C61RwL {
  height: 103px;
  padding: 5px;

  object-fit: cover;
  background-color: transparent;
}

@media screen and (min-width: 840px) {
  .status-display {
    padding-top: 10px;
    max-width: 35%;
    min-width: 535px;
  }

  .status1,
  .status2 {
    min-height: 155px;
  }

  .status3 {
    min-height: 275px;
  }

  .content-teacher {
    min-width: 275px;
  }

  .content-teacher-in {
    font-size: 1.7em;
  }

  .content-status {
    font-size: 16px;
  }

  .font-family-mont-1_1p {
    font-size: 24px;

    color: grey;
  }

  .font-family-mont-2_2p {
    font-size: 18px;
  }

  .font-family-mont-3_3p {
    font-size: 15px;
    color: black;
  }

  .my-class-v-in {
    font-size: 1.7em;
  }

  .content-right {
    min-width: 82px;
    font-size: 1.2em;
  }

  .x5834-1-C61RwL {
    height: 120px;
  }
}
