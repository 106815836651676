.component-1-C61RwL_x {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;
  left: 123px;
  position: absolute;
  top: 45px;
  width: 212px;
}
.line-5-9p2GBv_x {
  background-color: transparent;
  height: 99.05%;
  left: 0.9%;
  position: absolute;
  top: 0.48%;
  width: 99.29%;
}
.line-6-9p2GBv_x {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}
.star_1 {
  height: 50px;
  margin-left: 5px;
  padding-top: 20px;
  padding-bottom: 5px;
}
.rating_stars_x {
  padding: 2px 2px 2px 2px;
}

.ques_1 {
  margin: 5px;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}
.feedback_title_x {
  font-size: 30px;
  font-weight: bolder;
  margin-bottom: 20px;
}

.vsjL-sdn10cm {
  margin-left: 40%;
  margin-top: 4%;
  width: 128px;
}

.form-control {
  min-height: 40px;
}

@media screen and (max-width: 599px) {
  .ques_1 {
    margin: 5px 2px 2px 2px;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
  }

  .star_1 {
    height: 40px;
    margin-left: 2px;
    padding-top: 15px;
  }

  .component-1-C61RwL_x {
    background-color: transparent;
    /* border: 1px solid var(--blue-zodiac); */
    height: 80px;
    left: 40px;
    position: absolute;
    top: 45px;
    width: 182px;
  }

  .vsjL-sdn10cm {
    margin-left: 36%;
    margin-top: 3%;
    width: 80px;
  }
}
