/* screen - student-dashboard */


.table-container {
  width: 100%;
  max-width: 100%;
}

.table-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.table-wrapper {
  max-height: 50px; /* Ensuring vertical scrolling */
  overflow-y: auto;
  overflow-x: auto; /* Ensuring horizontal scrolling */
  border: 1px solid #ddd;
  border-radius: 5px;
}

.styled-table {
  width: 100%;
  min-width: 800px; /* Ensuring horizontal scroll if necessary */
  border-collapse: collapse;
}

.styled-table th, .styled-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.styled-table th {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
  z-index: 2;
}

.no-data {
  text-align: center;
  font-style: italic;
}


.border-1px-coconut-2 {
  border: 1px solid var(--coconut-2);
}

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-24px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-16px {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-14px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-nobel-18px {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.student-dashboard {
  background-color: var(--coconut);
  /* background-color: #e3fdfc; */
  height: 100vh;
  width: 100%;
}

.student-dashboard .analytics-24px-3-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 47px;
  overflow: hidden;
  position: absolute;
  top: 675px;
  width: 24px;
}

.student-dashboard .assignment-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 47px;
  overflow: hidden;
  position: absolute;
  top: 411px;
  width: 24px;
}

.student-dashboard .assignment-provided-C61RwL {
  background-color: transparent;
  height: auto;
  left: 23.45%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 619px;
  width: 186px;
}

.student-dashboard .assignment-solved-C61RwL {
  background-color: transparent;
  height: auto;
  left: 23.45%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 669px;
  width: 186px;
}

.student-dashboard .assignments-C61RwL {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 412px;
  width: auto;
}

.student-dashboard .biba-sobti-VdQhAS {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 90px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}

.student-dashboard .bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 24px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 47px;
  width: auto;
}

.student-dashboard .calendar-C61RwL {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 346px;
  width: auto;
}

.student-dashboard .component-1-C61RwL {
  background-color: transparent;
  left: 46px;
  position: absolute;
  top: 65px;
  height: 100px;
  text-align: center;
}

.student-dashboard .curriculum-C61RwL {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 544px;
  width: auto;
}

.student-dashboard .dashboard-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 735px;
  overflow: hidden;
  position: absolute;
  top: 429px;
  width: 24px;
}

.student-dashboard .dashboard-C61RwL {
  background-color: transparent;
  color: #fff;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;

  width: auto;
  margin-left: 5px;
}

.student-dashboard .description-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 47px;
  overflow: hidden;
  position: absolute;
  top: 543px;
  width: 24px;
}

.student-dashboard .doubt-forum-C61RwL {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 10px;
  width: 168px;
}

.student-dashboard .doubts-C61RwL {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 610px;
  width: auto;
}

.student-dashboard .ellipse-2-OSIBbx {
  background-color: transparent;
  height: 19.05%;
  left: 57.14%;
  position: absolute;
  top: 21.43%;
  width: 19.05%;
}

.student-dashboard .friday-WQXeF1 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 64%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 44px;
  width: auto;
}

.student-dashboard .group-0M78Jh {
  background-color: transparent;
  background-image: url(./img/vector-18@2x.png);
  background-size: 100% 100%;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-106-C61RwL {
  background-color: transparent;
  height: 29px;
  left: 24%;
  position: absolute;
  top: 406px;
  width: 257px;
}

.student-dashboard .group-13-C61RwL {
  background-color: transparent;
  height: 251px;
  left: 21.4%;
  position: absolute;
  top: 290px;
  width: 37%;
}

.student-dashboard .group-14-C61RwL {
  background-color: transparent;
  height: 206px;
  left: 21.4%;
  position: absolute;
  top: 51px;
  width: 37%;
}

.student-dashboard .group-160-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 45px;
  position: absolute;
  top: 741px;
  width: 24px;
}

.student-dashboard .group-1G6kDx {
  background-color: transparent;
  background-image: url(./img/vector-58@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-dashboard .group-22-C61RwL {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}

.student-dashboard .group-26DuQS {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-dashboard .group-30TA9U {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-dashboard .group-38-r5Ayig {
  /* background-color: var(--white); */
  background-color: rgb(247, 195, 195);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 172px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-39-tu69oo {
  background-color: transparent;
  flex-shrink: 1;
  height: 172px;
  position: relative;
  width: auto;
}

.student-dashboard .group-3YhNjD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-3qdjky {
  background-color: transparent;
  background-image: url(./img/vector-55@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-dashboard .group-40-Hw0YGE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 172px;
  justify-content: flex-start;
  min-width: 554px;
  position: relative;
  width: auto;
}

.student-dashboard .group-41-G4Fvxm {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 172px;
  justify-content: flex-start;
  min-width: 554px;
  position: relative;
  width: auto;
}

.student-dashboard .group-42-SMU6q4 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 172px;
  justify-content: flex-start;
  min-width: 554px;
  position: relative;
  width: auto;
}

.student-dashboard .group-43-WQXeF1 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 172px;
  justify-content: flex-start;
  left: 0px;
  min-width: 554px;
  position: absolute;
  top: 0px;
  width: auto;
}

.student-dashboard .group-4fKC6i {
  background-color: transparent;
  background-image: url(./img/vector-56@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-dashboard .group-4wy4Bx {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-5YlXKz {
  background-color: transparent;
  background-image: url(./img/vector-56@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-dashboard .group-5jt8cF {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-5r1RGr {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-dashboard .group-8RWWXx {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-dashboard .group-8SehwL {
  background-color: transparent;
  background-image: url(./img/vector-63@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-dashboard .group-8mXtiA {
  background-color: transparent;
  background-image: url(./img/vector-63@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-dashboard .group-Ambq12 {
  background-color: transparent;
  background-image: url(./img/vector-64@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-dashboard .group-B0zdg8 {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-dashboard .group-BVoyFW {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-dashboard .group-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 47px;
  position: absolute;
  top: 477px;
  width: 20px;
}

.student-dashboard .group-CZ3XDU {
  background-color: transparent;
  background-image: url(./img/vector-58@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-dashboard .group-E9Z3Qz {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-dashboard .group-Eub1uD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-FDKNYY {
  background-color: transparent;
  background-image: url(./img/vector-63@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-dashboard .group-GQEqeI {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-GQNshl {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-Gv5bm4 {
  background-color: transparent;
  background-image: url(./img/vector-55@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-dashboard .group-H4kFYy {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-dashboard .group-Hc5Phf {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-dashboard .group-HkDxar {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-I6cVpt {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-Ie5dUU {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-dashboard .group-J1LPKs {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-K12spc {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-dashboard .group-KLBpwU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-dashboard .group-KWN2RE {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-dashboard .group-KcOtnV {
  background-color: transparent;
  background-image: url(./img/vector-63@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-dashboard .group-KsMB6y {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-dashboard .group-LCuxKV {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-dashboard .group-LMly0o {
  background-color: transparent;
  background-image: url(./img/vector-58@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-dashboard .group-NOXmfT {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-OUAngl {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-dashboard .group-SfTnen {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-dashboard .group-SlOO8W {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-dashboard .group-SlxGcy {
  background-color: transparent;
  background-image: url(./img/vector-55@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-dashboard .group-Sw5xnU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-dashboard .group-W5IipO {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-WAmUNE {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-dashboard .group-X6Y0CP {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-dashboard .group-XHqu7O {
  background-color: transparent;
  background-image: url(./img/vector-55@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-dashboard .group-Z0P29R {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-dashboard .group-Zmqz7u {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-dashboard .group-a2vI4G {
  background-color: transparent;
  background-image: url(./img/vector-52@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-dashboard .group-b1ls5q {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-dashboard .group-bznq7Z {
  background-color: transparent;
  background-image: url(./img/vector-57@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-dashboard .group-cTYyqt {
  background-color: transparent;
  background-image: url(./img/vector-52@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-dashboard .group-ce7Ifp {
  background-color: transparent;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 20px;
}

.student-dashboard .group-dimqSz {
  background-color: transparent;
  background-image: url(./img/vector-56@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-dashboard .group-f6NdeG {
  background-color: transparent;
  background-image: url(./img/vector-57@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-dashboard .group-fbkhx3 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-dashboard .group-fxegOB {
  background-color: transparent;
  background-image: url(./img/vector-64@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-dashboard .group-hNGwvV {
  background-color: transparent;
  background-image: url(./img/vector-52@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-dashboard .group-hcfGEs {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-dashboard .group-hoVMgD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-htgOUZ {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-dashboard .group-hxa4BC {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-dashboard .group-kK588U {
  background-color: transparent;
  background-image: url(./img/vector-57@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-dashboard .group-lX9zMw {
  background-color: transparent;
  background-image: url(./img/vector-64@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-dashboard .group-lmXTEf {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-dashboard .group-m8nkkI {
  background-color: transparent;
  background-image: url(./img/vector-57@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-dashboard .group-mnqxdV {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-dashboard .group-oRRBjL {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-dashboard .group-paC7Rl {
  background-color: transparent;
  background-image: url(./img/vector-58@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-dashboard .group-pmZUQc {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-dashboard .group-sx6xBo {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-tDnSYz {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-dashboard .group-tr5zNE {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-dashboard .group-wbAI42 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-dashboard .group-wotaxO {
  background-color: transparent;
  background-image: url(./img/vector-64@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-dashboard .group-wpJ3xR {
  background-color: transparent;
  background-image: url(./img/vector-56@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-dashboard .group-xKrbSU {
  background-color: transparent;
  background-image: url(./img/vector-52@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-dashboard .group-xfUToA {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-dashboard .group-zkxeqe {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-dashboard .help-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 47px;
  overflow: hidden;
  position: absolute;
  top: 609px;
  width: 24px;
}

.student-dashboard .how-to-solve-this-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1034px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 532px;
  width: 290px;
}

.student-dashboard .join-4-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 977px;
  overflow: hidden;
  position: absolute;
  top: 354px;
  width: 46px;
}

.student-dashboard .join-5-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 977px;
  overflow: hidden;
  position: absolute;
  top: 441px;
  width: 46px;
}

.student-dashboard .join-6-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 981px;
  overflow: hidden;
  position: absolute;
  top: 528px;
  width: 46px;
}

.student-dashboard .join-7-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 984px;
  overflow: hidden;
  position: absolute;
  top: 612px;
  width: 46px;
}

.student-dashboard .join-class-PwO50P {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 89px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 4px;
  width: auto;
}

.student-dashboard .lecture-notes-C61RwL {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 742px;
  width: auto;
}

.student-dashboard .line-5-9p2GBv {
  background-color: transparent;
  height: 99.47%;
  left: 0.96%;
  position: absolute;
  top: 0.26%;
  width: 99.18%;
}

.student-dashboard .line-6-9p2GBv {
  background-color: transparent;
  height: 101.58%;
  left: 0px;
  position: absolute;
  top: -0.79%;
  width: 100.27%;
}

.student-dashboard .logout-C61RwL {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 955px;
  width: auto;
}

.student-dashboard .mathematics-section-g-WQXeF1 {
  background-color: transparent;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 35px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 55px;
  width: 239px;
}

.student-dashboard .notifications-24px-2-1-C61RwL {
  background-color: transparent;
  height: 42px;
  left: 976px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 42px;
}

.student-dashboard .number-of-tests-given-C61RwL {
  background-color: transparent;
  height: auto;
  left: 23.45%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 757px;
  width: 195px;
}

.student-dashboard .number-of-tests-taken-C61RwL {
  background-color: transparent;
  height: auto;
  left: 23.45%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 711px;
  width: 195px;
}

.student-dashboard .pending-assignments-C61RwL {
  background-color: transparent;
  height: auto;
  right: 16%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 737px;
  width: 218px;
}

.student-dashboard .performance-C61RwL {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 676px;
  width: auto;
}

.student-dashboard .please-check-my-approach-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1037px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 616px;
  width: 290px;
}

.student-dashboard .quiz-C61RwL {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 478px;
  width: auto;
}

.student-dashboard .rectangle-1-C61RwL {
  background-color: #1f2531;
  /* background-color: rgb(231, 231, 231); */
  height: 100vh;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 18.5%;
  min-width: 240px;
  padding: 30px 45px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  overflow-y: hidden;
}

.class-in_sdsds {
  margin-bottom: 20px;
}

.meneu-s-djksnsk12 {
  align-self: center;

  display: flex;
  align-items: center;
}

.student-dashboard .rectangle-30-AX7pWk {
  /* background-color: var(--white); */
  background-color: #dc3545;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 206px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-dashboard .rectangle-30-C61RwL {
  /* background-color: var(--white); */
  background-color: rgb(250, 228, 228);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 312px;
  left: 21.4%;
  position: absolute;
  top: 495px;
  width: 37%;
}

.student-dashboard .rectangle-32-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 59px;
  right: 5%;
  position: absolute;
  top: 771px;
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
}

.student-dashboard .rectangle-32-C61RwL .assignment-rowCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 36px;
}

.student-dashboard .rectangle-33-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 59px;
  right: 5%;
  position: absolute;
  top: 850px;
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
}

.student-dashboard .rectangle-33-C61RwL .assignment-rowCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 36px;
}

.student-dashboard .rectangle-40-PwO50P {
  height: 37px;
  left: -4px;
  position: absolute;
  top: 0px;
  width: 263px;
}

.student-dashboard .rectangle-42-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 114px;
  left: 961px;
  position: absolute;
  top: 154px;
  width: 388px;
}

.student-dashboard .rectangle-43-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* height: 417px; */
  right: 5%;
  position: absolute;
  top: 500px;
  width: 29%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 72px 24px 40px;
  min-height: 160px;
}


.student-dashboard .group-13-C61RwT {
  position: absolute;
  right: 5%;
  top: 290px;
  width: 29%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  max-height: 200px; /* Adjust as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent unwanted horizontal scrolling */
}

.student-dashboard .rectangle-43-C61RwL .doubt-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 4px;
}

.student-dashboard .rectangle-7-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 630px;
  position: absolute;
  top: 631px;
  width: 1px;
}

.student-dashboard .span0-BnRnwt {
  color: rgba(19, 37, 71, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

.student-dashboard .span0-SSwiU9 {
  color: rgba(126, 126, 126, 1);
  font-style: normal;
}

.student-dashboard .span0-xU0UMX {
  /* color: rgba(126,126,126,1.0); */
  color: #fff;
  font-style: normal;
  line-height: 14px;
}

.student-dashboard .span1-BnRnwt {
  color: rgba(19, 37, 71, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  padding-left: 50px;
  line-height: 27px;
}

.student-dashboard .span1-SSwiU9 {
  color: rgba(0, 0, 0, 1);
  font-style: normal;
}

.student-dashboard .span1-xU0UMX {
  color: rgba(249, 129, 49, 1);
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.student-dashboard .span2-SSwiU9 {
  color: rgba(249, 129, 49, 1);
  font-style: normal;
}

.student-dashboard .span2-xU0UMX {
  color: #fff;
  font-style: normal;
  line-height: 14px;
}

.student-dashboard .span3-xU0UMX {
  color: rgba(249, 129, 49, 1);
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.student-dashboard .span4-xU0UMX {
  color: #fff;
  font-style: normal;
  line-height: 14px;
}

.student-dashboard .stickynote2-24px-1-Cp4Be6 {
  background-color: transparent;
  height: 24px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 24px;
}

.student-dashboard .subject-C61RwL {
  background-color: transparent;
  height: auto;
  left: 23.45%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 573px;
  width: 200px;
}

.student-dashboard .subject-wise-progress-WQXeF1 {
  background-color: transparent;
  height: auto;
  left: 18px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 222px;
  width: 275px;
}

.student-dashboard .support-desk-C61RwL {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 809px;
  width: auto;
}

.student-dashboard .supportagent-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 44px;
  overflow: hidden;
  position: absolute;
  top: 807px;
  width: 24px;
}

.student-dashboard .upcoming-class-WQXeF1 {
  background-color: transparent;
  color: var(--gray);
  height: auto;
  left: 35px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 45%;
}

.student-dashboard .vector-0HfMqN {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-dashboard .vector-1G6kDx {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-dashboard .vector-1YY105 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-dashboard .vector-1ewXKa {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-dashboard .vector-26DuQS {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-dashboard .vector-2kxNnt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-dashboard .vector-30TA9U {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-dashboard .vector-3q0WPs {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-dashboard .vector-3qdjky {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-dashboard .vector-4ae2Rf {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-dashboard .vector-4fKC6i {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-dashboard .vector-4sG9FF {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-dashboard .vector-4vfNnN {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-dashboard .vector-5S9pFx {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-dashboard .vector-5YlXKz {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-dashboard .vector-5r1RGr {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-dashboard .vector-5x4wyG {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-dashboard .vector-6XJ1dC {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}

.student-dashboard .vector-7QdKbH {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-dashboard .vector-7pUNkI {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-dashboard .vector-7txfVx {
  background-color: transparent;
  height: 58.33%;
  left: 20.83%;
  position: absolute;
  top: 20.83%;
  width: 58.33%;
}

.student-dashboard .vector-7uUfdl {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-dashboard .vector-8JNBpx {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-dashboard .vector-8RWWXx {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-dashboard .vector-8SehwL {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-dashboard .vector-8U01yU {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-dashboard .vector-8XEWy4 {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-dashboard .vector-8mXtiA {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-dashboard .vector-8xAgQn {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-dashboard .vector-9opORi {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-dashboard .vector-9vYPU5 {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-dashboard .vector-AONfqU {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-dashboard .vector-Ambq12 {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-dashboard .vector-AuhUI1 {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-dashboard .vector-B0zdg8 {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-dashboard .vector-BVoyFW {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-dashboard .vector-C61RwL {
  background-color: transparent;
  height: 2.34%;
  left: 3.26%;
  position: absolute;
  top: 33.69%;
  width: 1.67%;
}

.student-dashboard .vector-CLdsug {
  background-color: transparent;
  height: 82.14%;
  left: 16.67%;
  position: absolute;
  top: 9.97%;
  width: 66.67%;
}

.student-dashboard .vector-CMx3Uj {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-dashboard .vector-CZ3XDU {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-dashboard .vector-ChPPby {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-dashboard .vector-DcBIgL {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-dashboard .vector-Dn0VOv {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-dashboard .vector-E3VqMR {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-dashboard .vector-E9Z3Qz {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-dashboard .vector-FDKNYY {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-dashboard .vector-Fb0JQL {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-dashboard .vector-FxTcxt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-dashboard .vector-G0yBDR {
  background-color: transparent;
  height: 83.33%;
  left: 16.67%;
  position: absolute;
  top: 8.33%;
  width: 66.67%;
}

.student-dashboard .vector-Gv5bm4 {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-dashboard .vector-H4kFYy {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-dashboard .vector-Hc5Phf {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-dashboard .vector-JWb765 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-dashboard .vector-K12spc {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-dashboard .vector-KLBpwU {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-dashboard .vector-KLFwa3 {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-dashboard .vector-KRXr9B {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-dashboard .vector-KWN2RE {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-dashboard .vector-KcOtnV {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-dashboard .vector-LCuxKV {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-dashboard .vector-LMly0o {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-dashboard .vector-LgtYic {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-dashboard .vector-MWjU8S {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-dashboard .vector-MtJxOL {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-dashboard .vector-NLtoul {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-dashboard .vector-OB8oOM {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-dashboard .vector-OSIBbx {
  background-color: transparent;
  height: 1.19%;
  left: 49.4%;
  position: absolute;
  top: 49.4%;
  width: 1.19%;
}

.student-dashboard .vector-OUAngl {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-dashboard .vector-OYLvDa {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}

.student-dashboard .vector-OsxaKh {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-dashboard .vector-Ozaj6l {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-dashboard .vector-QxuFcp {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-dashboard .vector-SfTnen {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-dashboard .vector-SlOO8W {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-dashboard .vector-SlxGcy {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-dashboard .vector-Sw5xnU {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-dashboard .vector-T0rcQo {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-dashboard .vector-Ta8Heo {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-dashboard .vector-TlPffh {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-dashboard .vector-TmFVy3 {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-dashboard .vector-UUKtms {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-dashboard .vector-UaM55c {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-dashboard .vector-UxrCfI {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-dashboard .vector-VAjk24 {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-dashboard .vector-VJLVH1 {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-dashboard .vector-VMr6Om {
  background-color: transparent;
  height: 2.34%;
  left: 3.26%;
  position: absolute;
  top: 27.25%;
  width: 1.67%;
}

.student-dashboard .vector-VP2Rux {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-dashboard .vector-VdQhAS {
  background-color: transparent;
  height: 100%;
  left: 73.42%;
  position: absolute;
  top: 0px;
  width: 25.32%;
}

.student-dashboard .vector-W1hXZ4 {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-dashboard .vector-WAmUNE {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-dashboard .vector-WK7UJi {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-dashboard .vector-WVknxn {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-dashboard .vector-WbzDQU {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-dashboard .vector-X6Y0CP {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-dashboard .vector-XHqu7O {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-dashboard .vector-XQwS4n {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-dashboard .vector-XjFi5l {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-dashboard .vector-XqVjZB {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-dashboard .vector-XwxuE1 {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-dashboard .vector-YarvLR {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-dashboard .vector-Z0P29R {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-dashboard .vector-a2vI4G {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-dashboard .vector-a5Bf11 {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-dashboard .vector-apzjgl {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-dashboard .vector-b1ls5q {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-dashboard .vector-baKUI6 {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-dashboard .vector-bznq7Z {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-dashboard .vector-c18eCt {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-dashboard .vector-cTYyqt {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-dashboard .vector-dBr6KK {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-dashboard .vector-dLw05Z {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-dashboard .vector-dimqSz {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-dashboard .vector-dmgbrE {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-dashboard .vector-dsyqYk {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-dashboard .vector-eh9WzI {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-dashboard .vector-f6NdeG {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-dashboard .vector-fCysJq {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-dashboard .vector-fbkhx3 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-dashboard .vector-fxegOB {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-dashboard .vector-g9aAPA {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-dashboard .vector-gsSdey {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-dashboard .vector-hNGwvV {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-dashboard .vector-hPKSzt {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-dashboard .vector-hcfGEs {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-dashboard .vector-htgOUZ {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-dashboard .vector-hxa4BC {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-dashboard .vector-ib8Dlq {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-dashboard .vector-irJBpK {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-dashboard .vector-jQrdMY {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-dashboard .vector-jmCVFs {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-dashboard .vector-kK588U {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-dashboard .vector-l8qsXH {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-dashboard .vector-lWWNsc {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-dashboard .vector-lX9zMw {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-dashboard .vector-lmXTEf {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-dashboard .vector-m8nkkI {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-dashboard .vector-mmO9xv {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-dashboard .vector-mzXdH9 {
  background-color: transparent;
  height: 2.34%;
  left: 3.26%;
  position: absolute;
  top: 93.16%;
  width: 1.67%;
}

.student-dashboard .vector-n1UKnn {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-dashboard .vector-nXJ6dP {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-dashboard .vector-oOS98D {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-dashboard .vector-oRRBjL {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-dashboard .vector-oZNlX3 {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-dashboard .vector-objxfZ {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-dashboard .vector-paC7Rl {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-dashboard .vector-pagq92 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-dashboard .vector-pgMXxb {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-dashboard .vector-pmZUQc {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-dashboard .vector-pxZ96q {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-dashboard .vector-qF2ch0 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-dashboard .vector-qGJxwJ {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-dashboard .vector-qHVi3n {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-dashboard .vector-qmzfLw {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-dashboard .vector-qy1MCO {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-dashboard .vector-r18CAb {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-dashboard .vector-r4buxr {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-dashboard .vector-rnzczl {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-dashboard .vector-sCPPBx {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-dashboard .vector-tDnSYz {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-dashboard .vector-tQ6NQH {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}

.student-dashboard .vector-tSUwp7 {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-dashboard .vector-uhbuVM {
  background-color: transparent;
  height: 36.11%;
  left: 20.01%;
  position: absolute;
  top: 16.53%;
  width: 60%;
}

.student-dashboard .vector-v9Qr6G {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-dashboard .vector-vJyLWf {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-dashboard .vector-wU39qv {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-dashboard .vector-wbAI42 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-dashboard .vector-wotaxO {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-dashboard .vector-wpJ3xR {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-dashboard .vector-x7OZie {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-dashboard .vector-xKrbSU {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-dashboard .vector-xYu4ww {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-dashboard .vector-xfUToA {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-dashboard .vector-xr8pKE {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-dashboard .vector-xy9YKY {
  background-color: transparent;
  height: 11.11%;
  left: 30%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-dashboard .vector-ydL8U7 {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-dashboard .vector-ye3NYT {
  background-color: transparent;
  height: 11.11%;
  left: 60%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-dashboard .vector-ywnyBE {
  background-color: transparent;
  height: 83.33%;
  left: 8.33%;
  position: absolute;
  top: 8.33%;
  width: 83.33%;
}

.student-dashboard .vector-zJJzco {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-dashboard .vector-zLY8MO {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-dashboard .vector-zkxeqe {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-dashboard .view-all-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1284px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 739px;
  width: 53px;
}

.student-dashboard .view-all-C61RwL:hover {
  text-decoration: underline;
}

.student-dashboard .view-all-VMr6Om {
  background-color: transparent;
  height: auto;
  right: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 75px;
}

.student-dashboard .view-all-VMr6Om:hover {
  text-decoration: underline;
}

.student-dashboard .view-forum-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1230px;
  letter-spacing: 0px;
  line-height: normal;
  text-align: right;
  top: 391px;
  width: 72px;
}

.student-dashboard .view-forum-QxM5SU {
  background-color: transparent;
  height: auto;
  left: 1233px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 647px;
  width: 113px;
}

.student-dashboard .view-forum-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 1230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 467px;
  width: 113px;
}

.student-dashboard .view-forum-mzXdH9 {
  background-color: transparent;
  height: auto;
  left: 1230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 563px;
  width: 113px;
}

.student-dashboard .welcome-back-biba-AX7pWk {
  background-color: transparent;
  height: auto;
  left: 8%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 47px;
  width: 260px;
}

.student-dashboard .what-is-the-si-unit-of-current-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1034px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 360px;
  width: 290px;
}

.student-dashboard .what-is-the-sol-f-this-question-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1034px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 436px;
  width: 290px;
}

.student-dashboard .x0800-am-0900-am-WQXeF1 {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 70%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  /* text-align: center; */
  top: 122px;
  width: 160px;
}

.student-dashboard .x14-C61RwL {
  background-color: transparent;
  color: var(--wild-sand);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 1166px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 450px;
  width: auto;
}

.student-dashboard .x15-january-WQXeF1 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 66%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 75px;
  width: auto;
}

.student-dashboard .x5834-1-AX7pWk {
  background-color: transparent;
  height: 126px;
  right: 3%;
  object-fit: cover;
  position: absolute;
  top: 42px;
  width: 156px;
}

.student-dashboard .you-have-no-upcoming-tests-C61RwL {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 985px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 172px;
  width: 349px;
}

.student-dashboard .your-have-5-pen-ere-last-active-AX7pWk {
  background-color: transparent;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 22px;
  letter-spacing: 0px;
  line-height: 30px;
  position: absolute;
  top: 76px;
  width: 327px;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --coconut-2: rgba(254, 254, 255, 1);
  --gray: rgba(126, 126, 126, 1);
  /* --nobel: rgba(179,179,179,1.0); */
  --white: rgba(255, 255, 255, 1);
  --wild-sand: rgba(244, 244, 244, 1);
}

/* screen - student-mobile-dashboard */

.border-1px-coconut-2 {
  border: 1px solid var(--coconut-2);
}

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-24px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-16px {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-14px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-nobel-18px {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.student-mobile-dashboard {
  background-color: var(--coconut);
  height: 100vh;
  margin: 0px;
  min-height: 1734px;
  min-width: 414px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.student-mobile-dashboard .assignment-provided-7olElM {
  background-color: transparent;
  height: auto;
  left: 11px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 63px;
  width: 186px;
}

.student-mobile-dashboard .assignment-provided-ojBfAf {
  background-color: transparent;
  height: auto;
  left: 11px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 63px;
  width: 186px;
}

.student-mobile-dashboard .assignment-solved-7olElM {
  background-color: transparent;
  height: auto;
  left: 11px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 113px;
  width: 186px;
}

.student-mobile-dashboard .assignment-solved-ojBfAf {
  background-color: transparent;
  height: auto;
  left: 11px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 113px;
  width: 186px;
}

.student-mobile-dashboard .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 45px;
  left: 26px;
  position: absolute;
  top: 24px;
  width: 87px;
}

.student-mobile-dashboard .component-6-C61RwL {
  background-color: transparent;
  height: 18px;
  left: 362px;
  position: absolute;
  top: 37px;
  width: 23px;
}

.student-mobile-dashboard .doubt-forum-C61RwL {
  background-color: transparent;
  color: #dc3545;
  height: auto;
  left: 41px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1259px;
  width: 131px;
}

.student-mobile-dashboard .friday-C61RwL {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 241px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 359px;
  width: auto;
}

.student-mobile-dashboard .group-14-C61RwL {
  background-color: transparent;
  height: 144px;
  left: 26px;
  position: absolute;
  top: 155px;
  width: 90%;
}

.student-mobile-dashboard .group-15-C61RwL {
  background-color: transparent;
  height: 135px;
  left: 26px;
  position: absolute;
  top: 331px;
  width: 363px;
}


.student-mobile-dashboard .group-15-C61RwL {
  background-color: transparent;
  height: 135px;
  left: 26px;
  position: absolute;
  top: 310px;
  width: 363px;
}


.student-mobile-dashboard .group-15-C61RwT {
  background-color: transparent;
  position: absolute;
  left: 26px;
  top: 450px;
  width: 350px;
  height: 120px;
  
  /* Enable scrolling */
  max-height: 200px; /* Adjust as needed */
  overflow-y: auto;  /* Vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  
  /* Optional: Add a scrollbar style */
  scrollbar-width: thin;  /* Firefox */
  scrollbar-color: #ccc transparent; /* Firefox */
}

.student-mobile-dashboard .group-1G6kDx {
  background-color: transparent;
  background-image: url(./img/vector-58@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-mobile-dashboard .group-1HIdtQ {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-mobile-dashboard .group-1xwhQi {
  background-color: transparent;
  background-image: url(./img/vector-64@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-mobile-dashboard .group-30-C61RwL {
  background-color: var(--coconut);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 242px;
  left: 44px;
  position: absolute;
  top: 664px;
  width: 318px;
}

.student-mobile-dashboard .group-31-7olElM {
  background-color: var(--coconut);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 242px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 318px;
}

.student-mobile-dashboard .group-31-ojBfAf {
  background-color: var(--coconut);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 242px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 318px;
}

.student-mobile-dashboard .group-3CEgUx {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-mobile-dashboard .group-3YhNjD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-mobile-dashboard .group-3sA2dG {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-mobile-dashboard .group-4fKC6i {
  background-color: transparent;
  background-image: url(./img/vector-56@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-mobile-dashboard .group-4wATVi {
  background-color: transparent;
  background-image: url(./img/vector-57@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-mobile-dashboard .group-6fSVpU {
  background-color: transparent;
  background-image: url(./img/vector-64@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-mobile-dashboard .group-81-C61RwL {
  background-color: transparent;
  height: 242px;
  left: 44px;
  position: absolute;
  top: 664px;
  width: 328px;
}

.student-mobile-dashboard .group-82-C61RwL {
  background-color: transparent;
  height: 242px;
  left: 44px;
  position: absolute;
  top: 934px;
  width: 328px;
}

.student-mobile-dashboard .group-8RWWXx {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-mobile-dashboard .group-AkwGKB {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-mobile-dashboard .group-DhIc2U {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-mobile-dashboard .group-FDKNYY {
  background-color: transparent;
  background-image: url(./img/vector-63@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-mobile-dashboard .group-FzT2Yv {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-mobile-dashboard .group-Gv5bm4 {
  background-color: transparent;
  background-image: url(./img/vector-55@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-mobile-dashboard .group-Hc5Phf {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-mobile-dashboard .group-HkDxar {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-mobile-dashboard .group-J1LPKs {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-mobile-dashboard .group-KGNV66 {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-mobile-dashboard .group-KWN2RE {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-mobile-dashboard .group-KeVVeb {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-mobile-dashboard .group-MAY3aD {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-mobile-dashboard .group-MX3nVG {
  background-color: transparent;
  background-image: url(./img/vector-57@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-mobile-dashboard .group-OBjzVB {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-mobile-dashboard .group-Qgt8QZ {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-mobile-dashboard .group-SiDY2m {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-mobile-dashboard .group-Sw5xnU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-mobile-dashboard .group-TQdfaY {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}

.student-mobile-dashboard .group-TiRBcI {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-mobile-dashboard .group-V4arbp {
  background-color: transparent;
  background-image: url(./img/vector-58@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-mobile-dashboard .group-YThQ5g {
  background-color: transparent;
  background-image: url(./img/vector-52@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-mobile-dashboard .group-YU546y {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-mobile-dashboard .group-YvWaKP {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-mobile-dashboard .group-bCisjQ {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-mobile-dashboard .group-bznq7Z {
  background-color: transparent;
  background-image: url(./img/vector-57@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}

.student-mobile-dashboard .group-cTlYD8 {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}

.student-mobile-dashboard .group-eJDmxV {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.student-mobile-dashboard .group-eXD3Wz {
  background-color: transparent;
  background-image: url(./img/vector-55@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-mobile-dashboard .group-fLCUVP {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-mobile-dashboard .group-fxegOB {
  background-color: transparent;
  background-image: url(./img/vector-64@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}

.student-mobile-dashboard .group-fxl8th {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}

.student-mobile-dashboard .group-hqtnli {
  background-color: transparent;
  background-image: url(./img/vector-58@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}

.student-mobile-dashboard .group-hxa4BC {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}

.student-mobile-dashboard .group-jORNwm {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-mobile-dashboard .group-k0rUXO {
  background-color: transparent;
  background-image: url(./img/vector-63@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-mobile-dashboard .group-kGmDUh {
  background-color: transparent;
  background-image: url(./img/vector-56@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-mobile-dashboard .group-mC95I1 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-mobile-dashboard .group-mExCKH {
  background-color: transparent;
  background-image: url(./img/vector-52@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-mobile-dashboard .group-mnqxdV {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}

.student-mobile-dashboard .group-oEvxLs {
  background-color: transparent;
  background-image: url(./img/vector-55@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-mobile-dashboard .group-pmZUQc {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}

.student-mobile-dashboard .group-saoOf7 {
  background-color: transparent;
  background-image: url(./img/vector-56@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}

.student-mobile-dashboard .group-tDnSYz {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}

.student-mobile-dashboard .group-wbAI42 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}

.student-mobile-dashboard .group-x0o18V {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-mobile-dashboard .group-xKrbSU {
  background-color: transparent;
  background-image: url(./img/vector-52@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}

.student-mobile-dashboard .group-xbdXnH {
  background-color: transparent;
  background-image: url(./img/vector-63@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}

.student-mobile-dashboard .how-to-solve-this-C61RwL {
  background-color: transparent;
  height: auto;
  left: 95px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1448px;
  width: 290px;
}

.student-mobile-dashboard .join-7-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 37px;
  overflow: hidden;
  position: absolute;
  top: 1302px;
  width: 46px;
}

.student-mobile-dashboard .join-8-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 35px;
  overflow: hidden;
  position: absolute;
  top: 1373px;
  width: 46px;
}

.student-mobile-dashboard .join-9-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 35px;
  overflow: hidden;
  position: absolute;
  top: 1444px;
  width: 46px;
}

.student-mobile-dashboard .join-class-C61RwL {
  background-color: transparent;
  color: var(--wild-sand);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 93px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 372px;
  width: auto;
}

.student-mobile-dashboard .line-5-9p2GBv {
  background-color: transparent;
  height: 101.11%;
  left: 0.55%;
  position: absolute;
  top: -0.56%;
  width: 100%;
}

.student-mobile-dashboard .line-6-9p2GBv {
  background-color: transparent;
  height: 102.22%;
  left: -0.29%;
  position: absolute;
  top: -1.11%;
  width: 100.57%;
}

.student-mobile-dashboard .mathematcs-section-g-wz60dm {
  background-color: transparent;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  top: 45px;
  white-space: nowrap;
  width: 124px;
}

.student-mobile-dashboard .number-of-tests-given-7olElM {
  background-color: transparent;
  height: auto;
  left: 11px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 201px;
  width: 195px;
}

.student-mobile-dashboard .number-of-tests-given-ojBfAf {
  background-color: transparent;
  height: auto;
  left: 11px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 201px;
  width: 195px;
}

.student-mobile-dashboard .number-of-tests-taken-7olElM {
  background-color: transparent;
  height: auto;
  left: 11px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 155px;
  width: 195px;
}

.student-mobile-dashboard .number-of-tests-taken-ojBfAf {
  background-color: transparent;
  height: auto;
  left: 11px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 155px;
  width: 195px;
}

.student-mobile-dashboard .pending-assignments-C61RwL {
  background-color: transparent;
  color: #dc3545;
  height: auto;
  left: 28px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1529px;
  width: auto;
}

.student-mobile-dashboard .rectangle-30-AX7pWk {
  background-color: #dc3545;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 144px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}

.student-mobile-dashboard .rectangle-30-wz60dm {
  background-color: rgb(247, 195, 195);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 135px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}

.student-mobile-dashboard .rectangle-30-wz60dm {
  background-color: rgb(247, 195, 195);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 135px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}

.student-mobile-dashboard .rectangle-42-C61RwL {
  background-color: rgb(250, 228, 228);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 114px;
  left: 26px;
  position: absolute;
  top: 441px;
  width: 359px;
}

.student-mobile-dashboard .rectangle-44-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 618px;
  left: 26px;
  position: absolute;
  top: 582px;
  width: 359px;
}

.student-mobile-dashboard .rectangle-44-VMr6Om {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  min-height: 120px;
  /* height: 270px; */
  min-height: 120px;
  left: 26px;
  position: absolute;
  top: 1234px;
  width: 359px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 66px 0;
}

.student-mobile-dashboard .rectangle-44-VMr6Om .doubt-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 4px;
}

.student-mobile-dashboard .rectangle-44-mzXdH9 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 49px;
  left: 26px;
  position: absolute;
  top: 1573px;
  width: 359px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
}

.student-mobile-dashboard .rectangle-44-mzXdH9 .assignment-rowCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 36px;
}

.student-mobile-dashboard .rectangle-48-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-mobile-dashboard .rectangle-49-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 38.89%;
  width: 100%;
}

.student-mobile-dashboard .rectangle-50-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 77.78%;
  width: 100%;
}

.student-mobile-dashboard .rectangle-40-kpakse {
  left: 41px;
  position: absolute;
  top: 365px;
  width: 154px;
  height: 30px;
  width: 120px;
}

.student-mobile-dashboard .rectangle-51-C61RwL {
  background-color: rgba(5, 187, 138, 1);
  border: 1px solid var(--black);
  border-radius: 10px;
  height: 28px;
  left: 41px;
  position: absolute;
  top: 365px;
  width: 154px;
}

.student-mobile-dashboard .rectangle-52-C61RwL {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 49px;
  left: 28px;
  position: absolute;
  top: 1645px;
  width: 359px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0;
}

.student-mobile-dashboard .rectangle-52-C61RwL .assignment-rowCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 36px;
}

.student-mobile-dashboard .see-details-C61RwL {
  background-color: transparent;
  height: auto;
  left: 281px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 524px;
  width: auto;
}

.student-mobile-dashboard .span0-HCKTJj {
  color: white;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
  padding-left: 6px;
}

.student-mobile-dashboard .span0-Oq4Dwi {
  color: gray;
  font-style: normal;
  line-height: 10px;
}

.student-mobile-dashboard .span0-xU0UMX {
  color: white;
  font-style: normal;
  line-height: 10px;
}

.student-mobile-dashboard .span1-HCKTJj {
  color: white;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  padding-left: 42px;
}

.student-mobile-dashboard .span1-Oq4Dwi {
  color: rgba(249, 129, 49, 1);
  font-style: normal;
  line-height: 10px;
}

.student-mobile-dashboard .span1-xU0UMX {
  color: rgba(249, 129, 49, 1);
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
}

.student-mobile-dashboard .span2-xU0UMX {
  color: white;
  font-style: normal;
  line-height: 10px;
}

.student-mobile-dashboard .span3-xU0UMX {
  color: white;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
}

.student-mobile-dashboard .span4-xU0UMX {
  color: white;
  font-style: normal;
  line-height: 10px;
}

.student-mobile-dashboard .subject-7olElM {
  background-color: transparent;
  height: auto;
  left: 11px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 190px;
}

.student-mobile-dashboard .subject-ojBfAf {
  background-color: transparent;
  height: auto;
  left: 11px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 75px;
}

.student-mobile-dashboard .upcoming-class-wz60dm {
  background-color: transparent;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 152px;
}

.student-mobile-dashboard .vector-0HfMqN {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-mobile-dashboard .vector-1G6kDx {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-mobile-dashboard .vector-1xwhQi {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-mobile-dashboard .vector-2kxNnt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-mobile-dashboard .vector-3CLFrx {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-mobile-dashboard .vector-3q0WPs {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-mobile-dashboard .vector-3sA2dG {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-mobile-dashboard .vector-4J5u0a {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-mobile-dashboard .vector-4ScEZ0 {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-mobile-dashboard .vector-4ae2Rf {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-mobile-dashboard .vector-4fKC6i {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-mobile-dashboard .vector-4sG9FF {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-mobile-dashboard .vector-4wATVi {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-mobile-dashboard .vector-51nbt7 {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-mobile-dashboard .vector-5S9pFx {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-mobile-dashboard .vector-5XJAgU {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-mobile-dashboard .vector-6fSVpU {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-mobile-dashboard .vector-7uUfdl {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-mobile-dashboard .vector-8RWWXx {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-mobile-dashboard .vector-8vtyOX {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-mobile-dashboard .vector-8xAgQn {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-mobile-dashboard .vector-9KhT0M {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-mobile-dashboard .vector-9a4AON {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-mobile-dashboard .vector-BAhhrx {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-mobile-dashboard .vector-BGw8Ld {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-mobile-dashboard .vector-D1tlG7 {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-mobile-dashboard .vector-DcBIgL {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-mobile-dashboard .vector-Dn0VOv {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-mobile-dashboard .vector-FDKNYY {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-mobile-dashboard .vector-FdqILU {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-mobile-dashboard .vector-FzT2Yv {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-mobile-dashboard .vector-GJWeUH {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-mobile-dashboard .vector-Gv5bm4 {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-mobile-dashboard .vector-Hc5Phf {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-mobile-dashboard .vector-IJTv3C {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-mobile-dashboard .vector-JNEvYq {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-mobile-dashboard .vector-JWb765 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-mobile-dashboard .vector-KGNV66 {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-mobile-dashboard .vector-KRXr9B {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-mobile-dashboard .vector-KWN2RE {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-mobile-dashboard .vector-KeVVeb {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-mobile-dashboard .vector-LUtrJg {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}

.student-mobile-dashboard .vector-LabG6s {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-mobile-dashboard .vector-LgtYic {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-mobile-dashboard .vector-MAY3aD {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-mobile-dashboard .vector-MX3nVG {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-mobile-dashboard .vector-MxgyI7 {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-mobile-dashboard .vector-Qgt8QZ {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-mobile-dashboard .vector-R5KA0Z {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}

.student-mobile-dashboard .vector-SGsPxX {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}

.student-mobile-dashboard .vector-SiDY2m {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-mobile-dashboard .vector-Sw5xnU {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-mobile-dashboard .vector-T0rcQo {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-mobile-dashboard .vector-T73h6t {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}

.student-mobile-dashboard .vector-TQdfaY {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}

.student-mobile-dashboard .vector-TiRBcI {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-mobile-dashboard .vector-TmFVy3 {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-mobile-dashboard .vector-V4arbp {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-mobile-dashboard .vector-WOBJVX {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-mobile-dashboard .vector-WVknxn {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-mobile-dashboard .vector-WZhsgH {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-mobile-dashboard .vector-XQwS4n {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-mobile-dashboard .vector-YThQ5g {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-mobile-dashboard .vector-YU546y {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-mobile-dashboard .vector-Z2m776 {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-mobile-dashboard .vector-ZLDzLf {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}

.student-mobile-dashboard .vector-aJapjW {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}

.student-mobile-dashboard .vector-bCisjQ {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-mobile-dashboard .vector-baKUI6 {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-mobile-dashboard .vector-bujtpu {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-mobile-dashboard .vector-bznq7Z {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}

.student-mobile-dashboard .vector-cTlYD8 {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}

.student-mobile-dashboard .vector-ckXBNo {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-mobile-dashboard .vector-dBr6KK {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}

.student-mobile-dashboard .vector-dsyqYk {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}

.student-mobile-dashboard .vector-dxovlC {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}

.student-mobile-dashboard .vector-eJDmxV {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}

.student-mobile-dashboard .vector-eXD3Wz {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-mobile-dashboard .vector-fLCUVP {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-mobile-dashboard .vector-fxegOB {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}

.student-mobile-dashboard .vector-fxl8th {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}

.student-mobile-dashboard .vector-hqtnli {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}

.student-mobile-dashboard .vector-hxa4BC {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}

.student-mobile-dashboard .vector-jE5qzM {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}

.student-mobile-dashboard .vector-k0rUXO {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-mobile-dashboard .vector-kGmDUh {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-mobile-dashboard .vector-lPxavK {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}

.student-mobile-dashboard .vector-lqF6tq {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}

.student-mobile-dashboard .vector-mC95I1 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-mobile-dashboard .vector-mExCKH {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-mobile-dashboard .vector-mmO9xv {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-mobile-dashboard .vector-mzxCfc {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-mobile-dashboard .vector-nZr17K {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-mobile-dashboard .vector-nnXg6J {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}

.student-mobile-dashboard .vector-oEvxLs {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}

.student-mobile-dashboard .vector-oLnp4x {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}

.student-mobile-dashboard .vector-oXOOEL {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}

.student-mobile-dashboard .vector-p16ejN {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-mobile-dashboard .vector-pmZUQc {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}

.student-mobile-dashboard .vector-psEfvb {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-mobile-dashboard .vector-qF2ch0 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-mobile-dashboard .vector-sCPPBx {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-mobile-dashboard .vector-sYmKV7 {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}

.student-mobile-dashboard .vector-saoOf7 {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}

.student-mobile-dashboard .vector-ssqsLv {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}

.student-mobile-dashboard .vector-tDnSYz {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}

.student-mobile-dashboard .vector-uh1AoP {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-mobile-dashboard .vector-vwyGJa {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}

.student-mobile-dashboard .vector-wGhpRb {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}

.student-mobile-dashboard .vector-wbAI42 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}

.student-mobile-dashboard .vector-x95zmf {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}

.student-mobile-dashboard .vector-xKrbSU {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}

.student-mobile-dashboard .vector-xYu4ww {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}

.student-mobile-dashboard .vector-xbdXnH {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-dashboard .flex-col-vgli2x-student-dash-s {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 0.5%;
  min-height: 35%;
  position: relative;
  width: 25%;
}
.student-dashboard .group-22-GyJUNH-student-dash-s {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-end;
  min-width: 316px;
  margin-top: 45px;
  margin-right: 5%;
  position: relative;
  width: auto;
}
.student-dashboard .biba-sobti-h8V8ZR-student-dash-s {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 28%;
  min-width: 40%;
  position: relative;
  text-align: left;
  width: auto;
}
.student-dashboard .bibasobtigmailcom-h8V8ZR-student-dash-s {
  color: black;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 2%;
  min-height: 30%;
  min-width: 41%;
  position: relative;
  text-align: left;
  width: auto;
}

.bibasobtigmailcom-h8V8ZR-student-dash {
  color: black;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 2%;
  min-height: 30%;
  min-width: 41%;
  position: relative;
  text-align: left;
  width: auto;
}

.student-dashboard .vector-vgli2x-student-dash-s {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}

.student-mobile-dashboard .vector-xx5wha {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}

.student-mobile-dashboard .vector-xxJCTU {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}

.student-mobile-dashboard .vector-zGIjuw {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}

.student-mobile-dashboard .view-all-C61RwL {
  background-color: transparent;
  height: auto;
  left: 323px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1262px;
  width: 75px;
}

.student-mobile-dashboard .view-all-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 335px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1535px;
  width: auto;
}

.student-mobile-dashboard .view-forum-C61RwL {
  background-color: transparent;
  height: auto;
  left: 278px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1337px;
  width: 113px;
}

.student-mobile-dashboard .view-forum-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 278px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1403px;
  width: 113px;
}

.student-mobile-dashboard .view-forum-mzXdH9 {
  background-color: transparent;
  height: auto;
  left: 278px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1469px;
  width: 113px;
}

.student-mobile-dashboard .welcome-back-biba-C61RwL {
  background-color: transparent;
  height: auto;
  left: 60px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 180px;
  width: 148px;
}

.student-mobile-dashboard .what-is-the-si-unit-of-current-C61RwL {
  background-color: transparent;
  height: auto;
  left: 95px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1308px;
  width: 290px;
}

.student-mobile-dashboard .what-is-the-sol-f-this-question-C61RwL {
  background-color: transparent;
  height: auto;
  left: 95px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1374px;
  width: 290px;
}

.student-mobile-dashboard .x0800-am-0900-am-C61RwL {
  background-color: transparent;
  color: black;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 243px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 395px;
  width: auto;
}

.student-mobile-dashboard .x15-january-C61RwL {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 241px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 379px;
  width: auto;
}

.student-mobile-dashboard .x5834-1-C61RwL {
  background-color: transparent;
  height: 103px;
  left: 250px;
  object-fit: cover;
  position: absolute;
  top: 176px;
  width: 116px;
}

.student-mobile-dashboard .your-have-5-pen-ere-last-active-AX7pWk {
  background-color: transparent;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  top: 72px;
  width: 183px;
}

.student-mobile-dashboard .your-have-no-upcoming-tests-C61RwL {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 37px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 459px;
  width: 349px;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --coconut-2: rgba(254, 254, 255, 1);
  --gray: rgba(126, 126, 126, 1);
  /* --nobel: rgba(179,179,179,1.0); */
  --white: rgba(255, 255, 255, 1);
  --wild-sand: rgba(244, 244, 244, 1);
}

@media screen and (max-width: 599px) {
  .student-dashboard {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .student-mobile-dashboard {
    display: none;
  }
}
