/* Handling the whole content of the smart-phone layout  */
.content {
  z-index: -10;
  margin-top: 10px;

  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.end-margin-15px {
  margin: 15px;
}

.Sider-display {
  width: 25%;
  max-width: 320px;
}

@media screen and (max-width: 839px) {
  .Sider-display {
    display: none;
  }
}

@media screen and (min-width: 840px) {
  .header-display {
    display: none;
  }

  .end-margin-15px {
    display: none;
  }

  .content {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}
