.card_st_form_card {
  margin: 50px auto;
  padding: 60px;
  border-radius: 14px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.sales-trial-student-form-1-parent-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
}

.sales-trial-student-form-1-parent-div input:focus, textarea:focus, select:focus{
  outline: none;
}

.sales-trial-student-form-1-parent-div input {
  margin: 0 auto;
  width: 100%;
  height: 45px;
  padding-left: 10px;
  border-radius: 5px;
  border: 2px solid rgba(217, 83, 79, 0.4);
  font-size: 15px;
  font-weight: 400;
  font-family: 'Montserrat', Helvetica;
}

/* .row {
  margin-bottom: 30px;
} */

.send_otp {
  width: 25%;
  height: 35px;
  margin-bottom: 3px;
  font-size: 10px;
  padding-bottom: 4px;
  color: white;
  overflow: hidden;
}

.submit {
  border-radius: 9px;
  color: white;
  width: 20%;
  margin: 0 auto;
}

.login-modal {
  width: 40%;
  height: 500px;
  margin: auto;
  margin-top: 120px;
  background-color: whitesmoke;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  padding: 24px 0;
}

.login-modal .login-modal-title {
  width: 70%;
  margin: auto;
  text-align: center;
  margin-bottom: 24px;
}

@media screen and (max-width: 1200px) {
  .card_st_form_card {
    margin: 50px 100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .card_st_form_card {
    margin: 50px 50px;
  }
}


@media screen and (max-width: 650px) {
    .sider-hc-teacher-list-2sales {
    display: none;
  }
  .sales-trial-student-form-1-parent-div {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }  

  .card_st_form_card {
    width: 100%;
    padding: 40px;
    margin: 0em;
  }  

  .card_st_form_card input {
      margin-bottom: 1em;
  } 
  
  .row {
    margin-bottom: 0px; 
  }

  .submit {
    width: 40%;
  }

  .dropdown1 {
    margin-bottom: 20px;
  }

  .smtext {
    transform: translate(0, -20px);
  }
}


@media screen and (min-width: 840px) {
  .header-wdt-100sales {
    display: none;
  }

}