@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,500,600|Roboto:700,500");
/* screen - sales-dashboard */

.border-1px-blue-zodiac {
  border: 1px solid var(--blue-zodiac);
}

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-20px {
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-9px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 9px;
  font-style: normal;
  font-weight: 550;
}

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.sales-dashboard {
  align-items: flex-start;
  background-color: var(--coconut);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.sales-dashboard .accountbalance-24px-1-RH0WJ5 {
  background-color: transparent;
  height: 24px;
  left: 47px;
  overflow: hidden;
  position: absolute;
  top: 609px;
  width: 24px;
}

.sales-dashboard .analytics-24px-3-RH0WJ5 {
  background-color: transparent;
  height: 24px;
  left: 47px;
  overflow: hidden;
  position: absolute;
  top: 543px;
  width: 24px;
}

.sales-dashboard .logout-24px-3-RH0WJ5 {
  background-color: transparent;
  height: 24px;
  left: 16%;
  overflow: hidden;
  position: absolute;
  top: 609px;
  width: 24px;
}
.sales-dashboard .analytics-RH0WJ5 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 544px;
  width: auto;
}

.sales-dashboard .biba-sobti-vgli2x {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2px;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}

.sales-dashboard .bibasobtigmailcom-vgli2x {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2px;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}

.sales-dashboard .category-WxqicF {
  background-color: transparent;
  height: auto;
  left: 600px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 85px;
}

.sales-dashboard .occupy-WxqicF {
  background-color: transparent;
  height: auto;
  left: 665px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 85px;
}

.sales-dashboard .class-name-y1HRxC {
  background-color: transparent;
  height: auto;
  left: 337px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 100px;
}

.sales-dashboard .component-1-RH0WJ5 {
  background-color: transparent;
  height: 95px;
  left: 14%;
  position: absolute;
  top: 60px;
  width: 183px;
}

.sales-dashboard .dashboard-24px-1-52Wjwg {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 596px;
  overflow: hidden;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: 24px;
}

.sales-dashboard .dashboard-24px-1-y1HRxC {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 352px;
  overflow: hidden;
  padding: 0 11.8px;
  position: absolute;
  top: 49px;
  width: 24px;
}

.sales-dashboard .dashboard-RH0WJ5 {
  background-color: transparent;
  color: rgba(244, 244, 244, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 280px;
  width: auto;
}

.sales-dashboard .date-WxqicF {
  background-color: transparent;
  height: auto;
  left: 450px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 110px;
}

.sales-dashboard .date-y1HRxC {
  background-color: transparent;
  height: auto;
  left: 606px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 224px;
  width: 44px;
}

.sales-dashboard .ellipse-2-RYyMFx {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}

.sales-dashboard .flex-col-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  margin-left: 3%;
  margin-top: 51px;
  min-height: 913px;
  position: absolute;
  left: 19%;
  width: 78%;
}

.sales-dashboard .flex-row-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 167px;
  justify-content: flex-start;
  position: relative;
  width: 100%;
}

.sales-dashboard .flex-row-x1fO3U {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}

.sales-dashboard .group-117-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 66px;
  width: 728px;
}

.sales-dashboard .group-118-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 126px;
  width: 728px;
}

.sales-dashboard .group-119-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 178px;
  width: 728px;
}

.sales-dashboard .group-120-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 234px;
  width: 728px;
}

.sales-dashboard .occupy {
  background-color: #dc3545;
  color: white;
  border-radius: 7px;
  width: 65px;
  height: 20px;

  font-size: 10px;
  padding: 0px;
  align-items: center;

  display: block;
  margin: 10px 10px 10px 0px;
}

.sales-dashboard .group-121-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 290px;
  width: 728px;
}

.sales-dashboard .group-122-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 346px;
  width: 728px;
}

.sales-dashboard .group-123-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 412px;
  width: 728px;
}

.sales-dashboard .group-124-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 478px;
  width: 728px;
}

.sales-dashboard .group-14-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 167px;
  position: relative;
  width: 37vw;
}

.sales-dashboard .group-22-GyJUNH {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 14px;
  min-height: 57px;
  position: relative;
}

.sales-dashboard .group-45-52Wjwg {
  background-color: transparent;
  height: 53px;
  left: 0px;
  position: absolute;
  top: 6px;
  width: 759px;
}

/* .sales-dashboard .group-56-y1HRxC {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 36px;
  width: 750px;
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.sales-dashboard .group-56-y1HRxC-10px {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 40px;
  width: 729px;
  margin-top: 20px;
  padding: 7px 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sales-dashboard .group-AvulH5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}

.sales-dashboard .group-TNKQzV {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}

.sales-dashboard .group-YrhztS {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}

.sales-dashboard .group-xtv2yH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}

.sales-dashboard .help-24px-1-RH0WJ5 {
  background-color: transparent;
  height: 24px;
  left: 16%;
  overflow: hidden;
  position: absolute;
  top: 477px;
  width: 24px;
}

.sales-dashboard .historytoggleoff-24px-1-RH0WJ5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 16%;
  overflow: hidden;
  position: absolute;
  top: 345px;
  width: 24px;
}

.sales-dashboard .issue-status-WxqicF {
  background-color: transparent;
  height: auto;
  left: 270px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 170px;
}

.sales-dashboard .line-5-8nYYUY {
  background-color: transparent;
  height: 94px;
  left: 0px;
  position: absolute;
  top: -0px;
  width: 182px;
}

.sales-dashboard .line-6-8nYYUY {
  background-color: transparent;
  height: 96px;
  left: -1px;
  position: absolute;
  top: -2px;
  width: 184px;
}

.sales-dashboard .list-of-current-issues-0xy0vn {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 22px;
  margin-top: 43px;
  min-height: 26px;
  position: relative;
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sales-dashboard .logout-RH0WJ5 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 609px;
  width: auto;
}

.sales-dashboard .next-y1HRxC {
  background-color: transparent;
  height: auto;
  left: 689px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}

.sales-dashboard .notifications-24px-2-1-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-left: 57px;
  margin-top: 19px;
  overflow: hidden;
  position: relative;
  width: 42px;
}

.sales-dashboard .overlap-group-0xy0vn {
  background-color: transparent;
  height: 575px;
  margin-left: 22px;
  margin-top: 22px;
  position: relative;
  width: 780px;
}

.sales-dashboard .overlap-group1-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 1024px;
  position: relative;
  min-width: 190px;
  width: 18.7%;
}

.sales-dashboard .overlap-group2-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 59px;
  margin-left: 22px;
  margin-top: 21px;
  position: relative;
  width: 759px;
}

.sales-dashboard .payments-RH0WJ5 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 610px;
  width: auto;
}

.sales-dashboard .previous-issues-RH0WJ5 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 346px;
  width: auto;
}

.sales-dashboard .rectangle-1-RH0WJ5 {
  background-color: #1f2531;
  height: 100vh;
  left: 0px;
  position: fixed;
  top: 0px;
  min-width: 190px;
  width: 18.7%;
}

.sales-dashboard .rectangle-20-WxqicF {
  background-color: #dc3545;
  border-radius: 29px;
  height: 53px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 10px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sales-dashboard .rectangle-30-Uqx0kx {
  background-color: #dc3545;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 167px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 37vw;
}

.sales-dashboard .rectangle-30-y1HRxC-10px {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 553px;

  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;

  padding: 10px;
}

.klajsd-ajkh23kj {
  width: 100%;
  height: 100%;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;
}

.sales-dashboard .rectangle-7-y1HRxC {
  background-color: transparent;
  height: 1px;
  left: 247px;
  position: absolute;
  top: 242px;
  width: 1px;
}

.sales-dashboard .s-no-WxqicF {
  background-color: transparent;
  height: auto;
  left: 24px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 71px;
}

.sales-dashboard .school-24px-1-RH0WJ5 {
  background-color: transparent;
  height: 24px;
  left: 16%;
  overflow: hidden;
  position: absolute;
  top: 411px;
  width: 24px;
}

.sales-dashboard .span0-70fs2O {
  color: var(--white);
  font-style: normal;
}

.sales-dashboard .span0-mQtxrF {
  color: #dc3545;
  font-style: normal;
}

.sales-dashboard .span0-nfPVjr {
  color: rgba(126, 126, 126, 1);
  font-style: normal;
  line-height: 14px;
}

.sales-dashboard .span1-70fs2O {
  color: var(--white);
  font-style: normal;
  font-weight: 600;
}

.sales-dashboard .span1-mQtxrF {
  color: #dc3545;
  font-style: normal;
  font-weight: 600;
}

.sales-dashboard .span1-nfPVjr {
  color: rgba(249, 129, 49, 1);
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.sales-dashboard .span2-nfPVjr {
  color: rgba(126, 126, 126, 1);
  font-style: normal;
  line-height: 14px;
}

.sales-dashboard .span3-nfPVjr {
  color: rgba(249, 129, 49, 1);
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.sales-dashboard .span4-nfPVjr {
  color: rgba(126, 126, 126, 1);
  font-style: normal;
  line-height: 14px;
}

.sales-dashboard .student-classroom-WxqicF {
  background-color: transparent;
  height: auto;
  left: 110px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 183px;
}

.sales-dashboard .student-name-y1HRxC {
  background-color: transparent;
  height: auto;
  left: 112px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 118px;
}

.sales-dashboard .students-teachers-RH0WJ5 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 412px;
  width: auto;
}

.sales-dashboard .tickets-RH0WJ5 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 478px;
  width: auto;
}

.sales-dashboard .time-y1HRxC {
  background-color: transparent;
  height: auto;
  left: 702px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 48px;
}

.sales-dashboard .vector-40xw7g {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}

.sales-dashboard .vector-6nYyTO {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}

.sales-dashboard .vector-8OGum8 {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}

.sales-dashboard .vector-9xiDaP {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}

.sales-dashboard .vector-BKsCJ0 {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}

.sales-dashboard .vector-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 7px;
  position: relative;
  width: 80px;
}

.sales-dashboard .vector-K3cCCV {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}

.sales-dashboard .vector-OaTsHZ {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}

.sales-dashboard .vector-QFWJMX {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}

.sales-dashboard .vector-QXoq1a {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}

.sales-dashboard .vector-RH0WJ5 {
  background-color: transparent;
  height: 24px;
  left: 16%;
  position: absolute;
  top: 279px;
  width: 24px;
}

.sales-dashboard .vector-RYyMFx {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}

.sales-dashboard .vector-aUE2K8 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}

.sales-dashboard .vector-cr1ESP {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}

.sales-dashboard .vector-dsPYCd {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}

.sales-dashboard .vector-fOS6IR {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}

.sales-dashboard .vector-g23mTr {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}

.sales-dashboard .vector-s3i1G8 {
  background-color: transparent;
  height: 24px;
  left: 47px;
  position: absolute;
  top: 954px;
  width: 24px;
}

.sales-dashboard .vector-uCfylM {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}

.sales-dashboard .vector-x1fO3U {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}

.sales-dashboard .vector-zNYIAN {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}

.sales-dashboard .welcome-back-biba-Uqx0kx {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 10%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 23px;
  width: 45%;
}

.sales-dashboard .maindashimg {
  width: 40%;
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sales-dashboard .username-container {
  display: flex;
  position: absolute;
  right: 8%;
}

.sales-dashboard .x1-y1HRxC {
  background-color: transparent;
  height: auto;
  left: 623px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}

.sales-dashboard .x2-y1HRxC {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 645px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}

.sales-dashboard .x3-y1HRxC {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 667px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}

.sales-dashboard .payga4wk-z-bshl-Sgr7kw-10 {
  text-align: center;
  width: 50px;
}

.payga4wk-z-bshl-Sgr7kw-9-2 {
  text-align: center;
  width: 15px;
}

.sales-dashboard .x499900-Sgr7kw-10 {
  text-align: center;
  width: 120px;
}

.x499900-Sgr7kw-9-2 {
  text-align: center;
  width: 60px;
}

.sales-dashboard .x07-dec-2020-Sgr7kw-10 {
  text-align: center;
  width: 146px;
}

.x07-dec-2020-Sgr7kw-9-2 {
  text-align: center;
  width: 74px;
}
.sales-dashboard .x014009-pm-Sgr7kw-10 {
  text-align: center;
  width: 132px;
}

.x014009-pm-Sgr7kw-9-2 {
  text-align: center;
  width: 60px;
}

.andb586-Sgr7kw-9-2 {
  text-align: center;
  width: 20px;
}

.sales-dashboard .andb586-Sgr7kw-10 {
  text-align: center;
  width: 50px;
}

.sales-dashboard .andb586-Sgr7kw-10-e-c {
  text-align: center;
  width: 65px;
}

.sales-dashboard .x5834-1-Uqx0kx {
  background-color: transparent;
  object-fit: cover;
  width: 75%;
}

.sales-dashboard .your-have-5-pen-mergency-issues-Uqx0kx {
  background-color: transparent;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 22px;
  letter-spacing: 0px;
  line-height: 30px;
  position: absolute;
  top: 76px;
  width: 327px;
}

.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.roboto-medium-white-20px {
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --blue-zodiac-2: rgba(19, 36, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --crusta: rgba(249, 129, 49, 1);
  --deep-blue: rgba(18, 0, 120, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --nobel: rgba(179, 179, 179, 1);
  --white: rgba(255, 255, 255, 1);
}

/* screen - sales-dashboard-mobile */

.border-1px-blue-zodiac {
  border: 1px solid var(--blue-zodiac);
}

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-20px {
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.sales-dashboard-mobile {
  align-items: center;
  background-color: var(--coconut);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 922px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 9px;
  position: relative;
  width: 414px;
}

.sales-dashboard-mobile .class-name-759J8x {
  background-color: transparent;
  height: auto;
  left: 167px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 48px;
}

.sales-dashboard-mobile .component-1-dXBp89 {
  background-color: transparent;
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}

.sales-dashboard-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}

.sales-dashboard-mobile .dashboard-24px-1-Rbw72r {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 288px;
  overflow: hidden;
  padding: 0 5.3px;
  position: absolute;
  top: 0px;
  width: 11px;
}

.sales-dashboard-mobile .date-759J8x {
  background-color: transparent;
  height: auto;
  left: 292px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 250px;
  width: 22px;
}

.sales-dashboard-mobile .student-classroom-fx556c {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 52px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  width: 89px;
}

.sales-dashboard-mobile .date-fx556c {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 200px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  width: 39px;
}

.sales-dashboard-mobile .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}

.sales-dashboard-mobile .group-124-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 47px;
  width: 348px;
}

.sales-dashboard-mobile .group-125-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 87px;
  width: 348px;
}

.sales-dashboard-mobile .group-126-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 127px;
  width: 348px;
}

.sales-dashboard-mobile .group-127-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 167px;
  width: 348px;
}

.sales-dashboard-mobile .group-127-C61RwL {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 40px;
  min-height: 605px;
  position: relative;
  width: 379px;
}

.sales-dashboard-mobile .group-128-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 207px;
  width: 348px;
}

.sales-dashboard-mobile .group-129-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 247px;
  width: 348px;
}

.sales-dashboard-mobile .group-130-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 287px;
  width: 348px;
}

.sales-dashboard-mobile .group-131-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 327px;
  width: 348px;
}

.sales-dashboard-mobile .group-132-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 367px;
  width: 348px;
}

.sales-dashboard-mobile .group-133-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 407px;
  width: 348px;
}

.sales-dashboard-mobile .group-134-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 447px;
  width: 348px;
}

.sales-dashboard-mobile .group-14-4eduM0 {
  background-color: transparent;
  height: 144px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 361px;
}

.sales-dashboard-mobile .group-45-Rbw72r {
  background-color: transparent;
  height: 42px;
  left: 0px;
  position: absolute;
  top: 6px;
  width: 369px;
}

.sales-dashboard-mobile .group-56-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 7px;
  width: 348px;
}

.sales-dashboard-mobile .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}

.sales-dashboard-mobile .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}

.sales-dashboard-mobile .list-of-current-issues-2zqKZg {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 10px;
  min-height: 26px;
  position: relative;
  text-align: left;
  width: 308px;
}

.sales-dashboard-mobile .next-759J8x {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 322px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 31px;
}

.sales-dashboard-mobile .overlap-group-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 144px;
  margin-right: 1px;
  margin-top: 34px;
  position: relative;
  width: 361px;
}

.sales-dashboard-mobile .overlap-group1-2zqKZg {
  background-color: transparent;
  flex-shrink: 1;
  height: 511px;
  margin-left: -0px;
  margin-top: 7px;
  position: relative;
  width: 361px;
}

.sales-dashboard-mobile .overlap-group2-2zqKZg {
  background-color: transparent;
  flex-shrink: 1;
  height: 48px;
  margin-left: 0px;
  margin-top: 13px;
  position: relative;
  width: 369px;
}

.sales-dashboard-mobile .rectangle-20-fx556c-10-e {
  background-color: #dc3545;
  border-radius: 29px;
  height: 42px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 352px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;

  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.sales-dashboard-mobile .rectangle-30-759J8x-10-es {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 511px;
  width: 104;
  padding: 5px;
}

.sales-dashboard-mobile .rectangle-36-4eduM0-10-es {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  min-height: 25px;
  margin-top: 14px;
  width: 99%;
  padding: 8px 10px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.sales-dashboard-mobile .rectangle-30-Yw5tgq {
  background-color: #dc3545;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 144px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}

.sales-dashboard-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}

.sales-dashboard-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}

.sales-dashboard-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}

.sales-dashboard-mobile .s-no-759J8x {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 27px;
}

.sales-dashboard-mobile .s-no-fx556c {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 13px;
  width: 49px;
}

.sales-dashboard-mobile .span0-8s1sGx {
  color: rgba(126, 126, 126, 1);
  font-style: normal;
  line-height: 10px;
}

.sales-dashboard-mobile .span0-I9MZRq {
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.sales-dashboard-mobile .span1-8s1sGx {
  color: rgba(249, 129, 49, 1);
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
}

.sales-dashboard-mobile .span1-I9MZRq {
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.sales-dashboard-mobile .span2-8s1sGx {
  color: rgba(126, 126, 126, 1);
  font-style: normal;
  line-height: 10px;
}

.sales-dashboard-mobile .span3-8s1sGx {
  color: rgba(249, 129, 49, 1);
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
}

.sales-dashboard-mobile .span4-8s1sGx {
  color: rgba(126, 126, 126, 1);
  font-style: normal;
  line-height: 10px;
}

.sales-dashboard-mobile .student-name-759J8x {
  background-color: transparent;
  height: auto;
  left: 62px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 56px;
}

.sales-dashboard-mobile .time-759J8x {
  background-color: transparent;
  height: auto;
  left: 337px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 23px;
}

.sales-dashboard-mobile .time-fx556c {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 270px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 13px;
  width: 46px;
}

.sales-dashboard-mobile .occupy-fx556c {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 13px;
  width: 46px;
}

.sales-dashboard-mobile .vector-IrPvqm {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}

.sales-dashboard-mobile .welcome-back-biba-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 16px;
  width: 148px;
}

.sales-dashboard-mobile .x1-759J8x {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 7px;
}

.sales-dashboard-mobile .x2-759J8x {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 292px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 9px;
}

.sales-dashboard-mobile .x3-759J8x {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 307px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 9px;
}

.sales-dashboard-mobile .x5834-1-4eduM0 {
  background-color: transparent;
  height: 103px;
  left: 201px;
  object-fit: cover;
  position: absolute;
  top: 17px;
  width: 116px;
}

.sales-dashboard-mobile .your-have-5-pen-mergency-issues-Yw5tgq {
  background-color: transparent;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  top: 72px;
  width: 183px;
}

.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.roboto-medium-white-20px {
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.rectangle-30-desktop-out-box {
  width: 98%;
  padding: 5px;
  margin-top: 10px;
  min-height: 30px;

  font-size: large;
  font-weight: 500;
  color: var(--blue-zodiac);
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --blue-zodiac-2: rgba(19, 36, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --crusta: rgba(249, 129, 49, 1);
  --deep-blue: rgba(18, 0, 120, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --nobel: rgba(179, 179, 179, 1);
  --white: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 599px) {
  .sales-dashboard {
    display: none;
  }

  .rectangle-30-desktop-out-box {
    width: 95%;
    font-size: 12px;
  }

  .occupy {
    background-color: #dc3545;
    color: white;
    border-radius: 7px;
    width: 40px;
    height: 18px;

    font-size: 8px;
    padding: 0px;
    align-items: center;

    display: block;
    margin: 0px;
  }
}

@media screen and (min-width: 600px) {
  .sales-dashboard-mobile {
    display: none;
  }

  .sales-dashboard .main-container {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  }
}

.logo1122 {
  max-height: 150px;
  margin-left: 35px;
}

@media screen and (max-width: 992px) {
  .sales-dashboard {
    height: 1100px;
  }
  .sales-dashboard .flex-col-C61RwL {
    margin-left: 0;
    left: 200px;
    width: calc(100% - 200px);
  }
  .sales-dashboard .group-14-GyJUNH {
    width: 20vw;
  }
  .sales-dashboard .rectangle-30-Uqx0kx {
    width: 20vw;
  }
  .sales-dashboard .maindashimg {
    display: none;
  }
}

@media screen and (max-width: 1176px) {
  .sales-dashboard .list-of-current-issues-0xy0vn {
    width: 65vw;
  }
  .sales-dashboard .overlap-group2-0xy0vn {
    width: 65vw;
    min-width: 540px;
  }
  .sales-dashboard .overlap-group-0xy0vn {
    width: 65vw;
    min-width: 540px;
  }
  .sales-dashboard .group-56-y1HRxC-10-yu-s {
    width: 61vw;
    min-width: 516px;
  }
}