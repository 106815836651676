.main {
  width: 100%;
  background-color: #dc3545;
  padding: 0 40px;
}

.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
}

.main p {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  transition: all 0.2s ease-in;
}

.main .our_program {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.main .navLinks {
  display: flex;
  flex-direction: row;
  align-items: center; /* Center nav links vertically */
  justify-content: space-between;
  gap: 12px;
}

.logo {
  width: auto;
  height: 65px;
  display: flex;
  align-items: center; /* Center logo vertically */
}

.main .login_btn {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  transition: all 0.2s ease-in;
  display: flex;
  align-items: center; /* Center text within button vertically */
  justify-content: center;
}

.main .login_btn:hover {
  transform: scale(1.02);
  background-color: #ab2f3c;
}

.main p:hover {
  transform: scale(1.02);
}

.hamicon {
  outline: none !important;
  display: flex;
}

@media (min-width: 991px) {
  .innerNav {
    align-items: center !important;
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding: 0 16px;
  }
  .logo {
    height: 55px;
  }
}
