.status-1 {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.Dropdown-lec-notes {
    width: 25%;
}


@media screen and (max-width: 600px) {
    .status-1 {
        flex-direction: column;
    }

    .Dropdown-lec-notes {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 30px;
    }

}