@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,500,600|Roboto:700,500");
/* screen - teacher-students */

.montserrat-medium-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-gray-9px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-normal-blue-zodiac-36px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-12px {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-students {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.teacher-students .accountbalance-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-students .historytoggleoff-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-students .quiz-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-students .poweroff-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
  margin-top: 100px;
}
.teacher-students .add-7y4ohx {
  background-color: transparent;
  height: auto;
  left: 316px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-students .add-RqwtHL {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 17.59px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 37px;
}
.teacher-students .add-delete-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 287px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 87px;
}
.teacher-students .analytics-24px-3-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-students .assignment-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-students .biba-sobti-JJC5aN {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-students .bibasobtigmailcom-JJC5aN {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-students .booked-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 53px;
}
.teacher-students .booked-959ciF {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 53px;
}
.teacher-students .castforeducation-24px-1-0pN3az {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 7px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 22px;
}
.teacher-students .castforeducation-24px-1-9vzgez {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  overflow: hidden;
  width: 22px;
}
.teacher-students .castforeducation-24px-1-M6rgK6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 7px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 22px;
}
.teacher-students .class-name-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 93px;
}
.teacher-students .class-strength-14-LxUxUe {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: auto;
}
.teacher-students .class-strength-20-pckt8m {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}
.teacher-students .class-strength-5-16N0QU {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: auto;
}
.teacher-students .completed-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 200px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 79px;
}
.teacher-students .component-1-0xy0vn {
  align-self: flex-start;
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.teacher-students .component-29-4Baocj-0-0 {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  flex-shrink: 1;
  height: 36px;
  position: relative;
  text-align: center;
  padding-top: 7px;
  width: 36px;
}
.teacher-students .component-29-B6pkN3 {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-students .component-29-I0Q5xv {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-students .component-29-TX83R0 {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  flex-shrink: 1;
  height: 36px;
  position: relative;
  width: 36px;
}
.teacher-students .component-29-hGGH3E {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-students .component-29-oFh2xL {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  flex-shrink: 1;
  height: 36px;
  position: relative;
  width: 36px;
}
.teacher-students .component-29-xDzjxa-00-1 {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  width: 36px;
  text-align: center;
  padding-top: 4px;
}
.component-29-4Baocj-10-11 {
  background-color: rgb(12, 11, 58);
  margin: 0px 5px;
  width: 35px;
  height: 35px;
  border-radius: 20px;
  text-align: center;
  padding-top: 7px;
}
.teacher-students .description-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-students .ellipse-2-APAcB6 {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.teacher-students .feb-18-2021-IMMU6x {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 116px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 113px;
}
.teacher-students .flex-col-0sTO9E {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.teacher-students .flex-col-C61RwL {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 8%;
  margin-top: 45px;
  min-height: 900px;
  position: relative;
  width: 44%;
}
.teacher-students .flex-col-VMr6Om {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 8%;
  margin-top: 51px;
  min-height: 922px;
  position: relative;
  width: 44%;
}
.teacher-students .flex-row-0xy0vn {
  align-items: flex-end;
  align-self: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 36px;
  justify-content: flex-start;
  margin-left: 32px;
  margin-top: 43px;
  min-width: 476px;
  position: relative;
  width: auto;
}
.teacher-students .flex-row-WcGkdi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.teacher-students .flex-row-djxPV6 {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 23px;
  justify-content: flex-start;
  min-width: 377px;
  position: relative;
  width: auto;
}
.teacher-students .group-112-djxPV6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 726px;
  margin-top: 14px;
  position: relative;
  width: 403px;
}
.teacher-students .group-113-8pxyib {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 763px;
  position: relative;
  width: 387px;
}
.teacher-students .group-114-lcFreP {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 763px;
  justify-content: flex-start;
  margin: 79px auto 0 auto;
  min-width: 383px;
  position: relative;
  width: auto;
}
.teacher-students .group-115-lcFreP {
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  min-width: 376px;
  position: relative;
  width: auto;
  margin: auto;
}
.teacher-students .group-116-4k1vsf {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  position: relative;
  width: 100%;
}
.teacher-students .group-117-4k1vsf {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-top: 34px;
  position: relative;
  width: 661px;
}
.teacher-students .group-118-4k1vsf {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-top: 34px;
  position: relative;
  width: 661px;
}
.teacher-students .group-160-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 88px;
  min-width: 24px;
  position: relative;
  width: auto;
}
.teacher-students .group-162-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 778px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.teacher-students .group-163-L8UHM8 {
  background-color: #1f2531;
  height: 1024px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 63px;
}
.teacher-students .group-164-L8UHM8 {
  background-color: transparent;
  height: 778px;
  left: 20px;
  position: absolute;
  top: 200px;
  width: 24px;
}
.teacher-students .group-165-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 1024px;
  position: relative;
  width: 63px;
}
.teacher-students .group-166-y1HRxC {
  background-color: transparent;
  height: 36px;
  width: 36px;
}
.teacher-students .group-167-y1HRxC {
  background-color: transparent;
  height: 36px;
  width: 36px;
}
.teacher-students .group-168-NG5cxJ {
  background-color: transparent;
  height: 36px;
  left: 25px;
  position: absolute;
  top: 16px;
  width: 36px;
}
.teacher-students .group-169-0pN3az {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  left: 0px;
  min-width: 36px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-students .group-169-52Wjwg {
  background-color: transparent;
  height: 36px;
  left: 25px;
  position: absolute;
  top: 16px;
  width: 36px;
}
.teacher-students .group-169-9vzgez {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  min-width: 36px;
  width: auto;
}
.teacher-students .group-169-M6rgK6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  left: 0px;
  min-width: 36px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-students .group-176-qbZ95W {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 269px;
  position: absolute;
  top: 0px;
  width: 657px;
}
.teacher-students .group-179-qbZ95W {
  background-color: transparent;
  height: 36px;
  width: 36px;
}
.teacher-students .group-180-qbZ95W {
  background-color: transparent;
  height: 36px;
  left: 25px;
  position: absolute;
  top: 117px;
  width: 36px;
}
.teacher-students .group-181-qbZ95W {
  background-color: transparent;
  height: 36px;
  left: 25px;
  position: absolute;
  top: 218px;
  width: 36px;
}
.teacher-students .group-182-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 269px;
  margin-top: 41px;
  position: relative;
  width: 100%;
}
.teacher-students .group-22-cLbj0f {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  min-width: 316px;
  position: relative;
  width: auto;
}
.teacher-students .group-47-NG5cxJ {
  background-color: transparent;
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 661px;
}
.teacher-students .group-48-52Wjwg {
  background-color: transparent;
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 661px;
}
.teacher-students .group-8xsQWl {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-students .group-90-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 174px;
  width: 369px;
}
.teacher-students .group-91-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 371px;
}
.teacher-students .group-92-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 369px;
}
.teacher-students .group-93-8MUoy6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 11px;
  min-height: 262px;
  position: absolute;
  top: 133px;
  width: 371px;
}
.teacher-students .group-94-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 369px;
}
.teacher-students .group-95-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 369px;
}
.teacher-students .group-96-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 369px;
}
.teacher-students .group-97-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 587px;
  width: 369px;
}
.teacher-students .group-98-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 646px;
  width: 369px;
}
.teacher-students .group-9VbO77 {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  position: relative;
  width: 20px;
}
.teacher-students .group-FHHSuW {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-students .group-JWAxrT {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-students .group-LtenBZ {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-students .group-UKbWOd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.teacher-students .group-alxHTq {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-students .group-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: flex-start;
  left: 0px;
  min-width: 20px;
  position: absolute;
  top: 448px;
  width: auto;
}
.teacher-students .group-tQmilE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.teacher-students .help-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-students .historytoggleoff-24px-1-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-students .kunal-kashyap-ptxbqP {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 127px;
}
.teacher-students .line-5-HhoarN {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.teacher-students .line-6-HhoarN {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.teacher-students .mathematics-class-10-5pjTul {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: auto;
}
.teacher-students .mathematics-class-10-LxUxUe {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 190px;
}
.teacher-students .mathematics-class-9-pckt8m-ii-i {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  min-width: 185px;
}
.teacher-students .mathematics-class-9-ptxbqP {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: auto;
}
.teacher-students .mathematics-class-9-y1HRxC {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}
.teacher-students .my-classrooms-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 36px;
  position: relative;
  width: 310px;
}
.teacher-students .my-students-0xy0vn {
  align-self: flex-start;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 32px;
  margin-top: 60px;
  min-height: 36px;
  position: relative;
  width: 310px;
}
.teacher-students .notifications-24px-2-1-cLbj0f {
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.teacher-students .open-calendar-IMMU6x {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.teacher-students .opened-1ihGMx {
  background-color: transparent;
  height: auto;
  left: 304px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 53px;
}
.teacher-students .opened-K0vR9a {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-students .opened-SbEpTm {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-students .opened-TYZK0W {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-students .opened-aOipQ0 {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-students .opened-oArczI {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-students .opened-xEcNns {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-students .organic-4-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 67px;
}
.teacher-students .organic-5-wTq9AN {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 67px;
}
.teacher-students .overlap-group-0xy0vn-90 {
  background-color: transparent;
  /* flex-shrink: 1; */
  height: 67px;
  margin-right: 4px;
  margin-top: 41px;
  width: 98%;
}
.teacher-students .overlap-group-RqwtHL {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 365px;
}
.teacher-students .overlap-group1-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-top: 34px;
  position: relative;
  width: 661px;
}
.teacher-students .overlap-group2-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-top: 34px;
  position: relative;
  width: 661px;
}
.teacher-students .physics-class-9-16N0QU {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 198px;
}
.teacher-students .rectangle-31-8MUoy6 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 726px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.teacher-students .rectangle-40-16N0QU {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 657px;
}
.teacher-students .rectangle-40-5pjTul {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 657px;
}
.teacher-students .rectangle-40-8MUoy6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.teacher-students .rectangle-40-LxUxUe {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 657px;
}
.teacher-students .rectangle-40-pckt8m {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  padding: 10px 30px;
}
.teacher-students .rectangle-40-ptxbqP {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 657px;
}

.teacher-students .rectangle-40-y1HRxC-9938 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  padding: 10px 30px;
}

.teacher-students .rectangle-40-y1HRxC .studTile-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 14px;
  height: 30px;
}

.teacher-students .rectangle-40-pckt8m .classTile-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 14px;
  height: 30px;
}

.teacher-students .rectangle-41-8MUoy6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.teacher-students .rectangle-44-K0vR9a {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-students .rectangle-44-SbEpTm {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-students .rectangle-44-TYZK0W {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-students .rectangle-44-aOipQ0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-students .rectangle-44-oArczI {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-students .rectangle-44-xEcNns {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-students .rectangle-47-8MUoy6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.teacher-students .rectangle-48-wTq9AN {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-students .rectangle-49-1ihGMx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-students .rectangle-50-7y4ohx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-students .rectangle-7-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-bottom: 2.73px;
  margin-left: 164px;
  position: relative;
  width: 1px;
}
.teacher-students .school-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-students .shreyans-jain-5pjTul {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 120px;
}
.teacher-students .span0-3zxzBk {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
}
.teacher-students .span0-mFdmdo {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
}
.teacher-students .span1-3zxzBk {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}
.teacher-students .span1-mFdmdo {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}
.teacher-students .status-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 208px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.teacher-students .stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-students .time-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.teacher-students .upcoming-wTq9AN {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 76px;
}

.rectangle-40-pckt8m-10-1101 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 342px;
  height: 45px;
  padding: 2px;
  margin-bottom: 12px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.teacher-students .vatsal-agarwal-y1HRxC-10-1 {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  min-width: 140px;
}
.teacher-students .vector-0sTO9E {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.teacher-students .vector-1aJ70d {
  background-color: transparent;
  height: 1px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-students .vector-3ahjCQ {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-students .vector-56AgMz {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.teacher-students .vector-8k85HE {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 170px;
  position: relative;
  width: 24px;
}
.teacher-students .vector-90KxxC {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-students .vector-9xgjqN {
  background-color: transparent;
  height: 14px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.teacher-students .vector-APAcB6 {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.teacher-students .vector-B6pkN3 {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-students .vector-B8xRgA {
  background-color: transparent;
  height: 16px;
  left: 0px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-students .vector-BKwYFx {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.teacher-students .vector-Chnqxl {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.teacher-students .vector-EaMiW0 {
  background-color: transparent;
  height: 20px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 18px;
}
.teacher-students .vector-I0Q5xv {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-students .vector-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-students .vector-Kchglb {
  background-color: transparent;
  height: 1px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-students .vector-M7Pfwg {
  background-color: transparent;
  height: 18px;
  left: -0px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-students .vector-SCg0eG {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  position: relative;
  width: 24px;
}
.teacher-students .vector-SIAzDu {
  background-color: transparent;
  height: 20px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 16px;
}
.teacher-students .vector-TCNT1O {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-students .vector-UgkvXI {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-students .vector-WcGkdi {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.teacher-students .vector-XV2kdn {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.teacher-students .vector-dlRKJA {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-students .vector-fPPgx3 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-students .vector-grLYV9 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-students .vector-hGGH3E {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-students .vector-i9y6kK {
  background-color: transparent;
  height: 1px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-students .vector-iniHBi {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-students .vector-jWA1w5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.teacher-students .vector-kCLhu1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-students .vector-mju0OS {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-students .vector-oRqxWx {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-students .vector-s2Y5x6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.teacher-students .vector-xDzjxa {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-students .vector-xbu9jm {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.teacher-students .vector-yDoGHs {
  background-color: transparent;
  height: 18px;
  left: -0px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-students .vector-yGoR8D {
  background-color: transparent;
  height: 18px;
  left: -0px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-students .vector-yQhZ26 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-students .x10pm-SbEpTm {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 45px;
}
.teacher-students .x12pm-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 47px;
}
.teacher-students .x1pm-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 133px;
  width: 33px;
}
.teacher-students .x2pm-1ihGMx {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 39px;
}
.teacher-students .x3pm-wTq9AN {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 31px;
}
.teacher-students .x4pm-oArczI {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-students .x5pm-7y4ohx {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-students .x6pm-K0vR9a {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-students .x7pm-TYZK0W {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-students .x8pm-aOipQ0 {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-students .x9pm-xEcNns {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}

.teacher-students .students-list-comp-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
}

.teacher-students .classrooms-list-10-1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 700px;
  margin-bottom: 10px;
  overflow-y: auto;
}

:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - teacher-students-mobile */

.montserrat-medium-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-gray-9px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-normal-blue-zodiac-36px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-12px {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-students-mobile {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1492px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 414px;
}
.teacher-students-mobile .add-4XwmcE {
  background-color: transparent;
  height: auto;
  left: 283px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-students-mobile .add-delete-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 259px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 7px;
  width: 92px;
}
.teacher-students-mobile .add-jWwNYN {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 30.24px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 35px;
}
.teacher-students-mobile .assignment-1-7iGIN1 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.teacher-students-mobile .assignment-1-FVieMB {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.teacher-students-mobile .assignment-1-MiJunx {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.teacher-students-mobile .assignment-1-zHT0nt {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.teacher-students-mobile .assignment-24px-3-1VRSUu {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.teacher-students-mobile .assignment-24px-3-UEWQ0j {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.teacher-students-mobile .assignment-24px-3-poPk0w {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.teacher-students-mobile .assignment-24px-3-uiXjoi {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.teacher-students-mobile .booked-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 280px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 232px;
  width: 50px;
}
.teacher-students-mobile .booked-sjPFVK {
  background-color: transparent;
  height: auto;
  left: 280px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 51px;
  width: 50px;
}
.teacher-students-mobile .castforeducation-24px-1-Lc9Vx9 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 7px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 22px;
}
.teacher-students-mobile .castforeducation-24px-1-RLx4oc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 7px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 22px;
}
.teacher-students-mobile .castforeducation-24px-1-sTxbmJ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 7px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 22px;
}
.teacher-students-mobile .class-name-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 7px;
  width: 94px;
}
.teacher-students-mobile .class-strength-15-fmQqrv {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-students-mobile .class-strength-20-7PiewZ {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-students-mobile .class-strength-8-fyTiIa {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-students-mobile .completed-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 180px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 51px;
  width: 74px;
}
.teacher-students-mobile .component-1-PNnH4c {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.teacher-students-mobile .component-29-7iGIN1 {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.teacher-students-mobile .component-29-FVieMB {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.teacher-students-mobile .component-29-MiJunx {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.teacher-students-mobile .component-29-VOxOl6-101-1 {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  text-align: center;
  padding-top: 5px;
  top: 0px;
  width: 36px;
}
.teacher-students-mobile .component-29-WJW0Ru {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  flex-shrink: 1;
  height: 36px;
  position: relative;
  width: 36px;
}
.teacher-students-mobile .component-29-ZiDAHi {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-students-mobile .component-29-nVfcml {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  flex-shrink: 1;
  height: 36px;
  position: relative;
  width: 36px;
}
.teacher-students-mobile .component-29-ngxMdY {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  flex-shrink: 1;
  height: 36px;
  position: relative;
  width: 36px;
}
.teacher-students-mobile .component-29-sIzuEd {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-students-mobile .component-29-zHT0nt {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.teacher-students-mobile .component-6-NvvWoh {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 13px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.teacher-students-mobile .ellipse-4-1VRSUu {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.teacher-students-mobile .ellipse-4-UEWQ0j {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.teacher-students-mobile .ellipse-4-poPk0w {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.teacher-students-mobile .ellipse-4-uiXjoi {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.teacher-students-mobile .feb-18-2021-kxiL8T {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 87px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 117px;
}
.teacher-students-mobile .flex-col-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: -203px;
  margin-top: 960.69px;
  min-height: 162px;
  position: relative;
  width: 179px;
}
.teacher-students-mobile .flex-col-NvvWoh {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 133px;
  position: relative;
  width: 311px;
}
.teacher-students-mobile .flex-col-VMr6Om {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 50px;
  margin-top: 24px;
  min-height: 1457px;
  position: relative;
  width: 367px;
}
.teacher-students-mobile .flex-row-SXcK7r {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  min-height: 23px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 55px;
  min-width: 353px;
  position: relative;
  width: auto;
}
.teacher-students-mobile .flex-row-lcFreP {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 133px;
  justify-content: flex-start;
  margin-right: 8px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacher-students-mobile .group-136-TJUoI9 {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  margin-top: 13px;
  position: relative;
  width: 363px;
}
.teacher-students-mobile .group-136-Y2zMZW {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  margin-top: 13px;
  position: relative;
  width: 363px;
}
.teacher-students-mobile .group-137-TJUoI9 {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  margin-top: 13px;
  position: relative;
  width: 363px;
}
.teacher-students-mobile .group-137-Y2zMZW {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  margin-top: 13px;
  position: relative;
  width: 363px;
}
.teacher-students-mobile .group-138-cKEQA6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 228px;
  justify-content: flex-start;
  left: 0px;
  /* min-height: 228px; */
  position: absolute;
  top: -14px;
  width: 355px;
  overflow-y: auto;
}
.teacher-students-mobile .group-139-NE5SsN-00-1 {
  align-items: flex-start;
  background-color: transparent;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 43px;
  /* min-height: 176px; */
  position: relative;
  padding: 10px;
  width: 359px;
  overflow-y: auto;
}
.teacher-students-mobile .group-140-qG7pNR {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 255px;
  position: absolute;
  top: 0px;
  width: 361px;
}
.teacher-students-mobile .group-167-cKEQA6 {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 6px;
  width: 36px;
}
.teacher-students-mobile .group-169-Lc9Vx9 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  left: 0px;
  min-width: 36px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-students-mobile .group-169-RLx4oc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  left: 0px;
  min-width: 36px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-students-mobile .group-169-sTxbmJ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  left: 0px;
  min-width: 36px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-students-mobile .group-181-qG7pNR {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 85px;
  width: 36px;
}
.teacher-students-mobile .group-182-qG7pNR {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 214px;
  width: 36px;
}
.teacher-students-mobile .group-183-qG7pNR {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 148px;
  width: 36px;
}
.teacher-students-mobile .group-184-cKEQA6 {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 69px;
  width: 36px;
}
.teacher-students-mobile .group-185-cKEQA6 {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 132px;
  width: 36px;
}
.teacher-students-mobile .group-46-TJUoI9 {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  position: relative;
  width: 363px;
}
.teacher-students-mobile .group-46-Y2zMZW {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  position: relative;
  width: 363px;
}
.teacher-students-mobile .group-72-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-left: 0.67px;
  position: relative;
  width: 179px;
}
.teacher-students-mobile .group-73-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-left: 0.67px;
  margin-top: 7px;
  position: relative;
  width: 179px;
}
.teacher-students-mobile .group-74-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-left: 0.67px;
  margin-top: 7px;
  position: relative;
  width: 179px;
}
.teacher-students-mobile .group-75-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-top: 7px;
  position: relative;
  width: 179px;
}
.teacher-students-mobile .group-90-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 4px;
  position: absolute;
  top: 155px;
  width: 346px;
}
.teacher-students-mobile .group-91-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 3px;
  position: absolute;
  top: 214px;
  width: 348px;
}
.teacher-students-mobile .group-92-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 3px;
  position: absolute;
  top: 273px;
  width: 346px;
}
.teacher-students-mobile .group-93-PqMIxZ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 5px;
  min-height: 262px;
  position: absolute;
  top: 114px;
  width: 348px;
}
.teacher-students-mobile .group-94-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 5px;
  position: absolute;
  top: 391px;
  width: 346px;
}
.teacher-students-mobile .group-95-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 5px;
  position: absolute;
  top: 450px;
  width: 346px;
}
.teacher-students-mobile .group-96-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 5px;
  position: absolute;
  top: 509px;
  width: 346px;
}
.teacher-students-mobile .group-97-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 5px;
  position: absolute;
  top: 568px;
  width: 346px;
}
.teacher-students-mobile .group-98-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 5px;
  position: absolute;
  top: 627px;
  width: 346px;
}
.teacher-students-mobile .group-J6w8XJ {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-students-mobile .group-QZFi9E {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-students-mobile .group-WACxkH {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-students-mobile .kunal-kashyap-XfMpTV {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 140px;
}
.teacher-students-mobile .line-5-pUZ9pZ {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.teacher-students-mobile .line-6-pUZ9pZ {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.teacher-students-mobile .mathematics-class-10-LCtjVZ {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-students-mobile .mathematics-class-10-fmQqrv {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 165px;
}
.teacher-students-mobile .mathematics-class-9-7PiewZ {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 165px;
}
.teacher-students-mobile .mathematics-class-9-XfMpTV {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-students-mobile .mathematics-class-9-qJWHC5 {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-students-mobile .mathematics-dr-yashna-sharma-7iGIN1 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.teacher-students-mobile .mathematics-dr-yashna-sharma-FVieMB {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.teacher-students-mobile .mathematics-dr-yashna-sharma-MiJunx {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.teacher-students-mobile .mathematics-dr-yashna-sharma-zHT0nt {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.teacher-students-mobile .my-classrooms-NE5SsN {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 1px;
  min-height: 36px;
  position: relative;
  width: 310px;
}
.teacher-students-mobile .my-students-PNnH4c {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 1px;
  margin-top: 52px;
  min-height: 36px;
  position: relative;
  width: 310px;
}
.teacher-students-mobile .open-calendar-kxiL8T {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.teacher-students-mobile .opened-DRTA6S {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-students-mobile .opened-FxsTuj {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-students-mobile .opened-UxBcup {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-students-mobile .opened-VweSAY {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.teacher-students-mobile .opened-XEhIzO {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-students-mobile .opened-x92G5j {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-students-mobile .opened-ytzoYe {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-students-mobile .organic-4-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 84px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 51px;
  width: 69px;
}
.teacher-students-mobile .organic-5-Fiy3Ky {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.teacher-students-mobile .overlap-group-lcFreP {
  background-color: transparent;
  flex-shrink: 1;
  height: 692px;
  margin-left: 8px;
  margin-top: 26px;
  position: relative;
  width: 359px;
}
.teacher-students-mobile .overlap-group1-lcFreP {
  background-color: transparent;
  flex-shrink: 1;
  height: 176px;
  margin-right: 8px;
  margin-top: 43px;
  position: relative;
  width: 359px;
}
.teacher-students-mobile .overlap-group2-lcFreP {
  background-color: transparent;
  flex-shrink: 1;
  height: 255px;
  margin-right: 6px;
  margin-top: 54px;
  position: relative;
  width: 361px;
}
.teacher-students-mobile .overlap-group3-jWwNYN {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 342px;
}
.teacher-students-mobile .physics-class-9-fyTiIa {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 140px;
}
.teacher-students-mobile .rectangle-31-PqMIxZ {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 692px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-students-mobile .rectangle-40-7PiewZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-students-mobile .rectangle-40-7iGIN1 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.teacher-students-mobile .rectangle-40-FVieMB {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.teacher-students-mobile .rectangle-40-LCtjVZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-students-mobile .rectangle-40-MiJunx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.teacher-students-mobile .rectangle-40-PqMIxZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 4px;
  position: absolute;
  top: 38px;
  width: 342px;
}
.teacher-students-mobile .rectangle-40-XfMpTV {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-students-mobile .rectangle-40-fmQqrv {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-students-mobile .rectangle-40-fyTiIa {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-students-mobile .rectangle-40-qJWHC5 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-students-mobile .rectangle-40-zHT0nt {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.teacher-students-mobile .rectangle-41-PqMIxZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 3px;
  position: absolute;
  top: 156px;
  width: 342px;
}
.teacher-students-mobile .rectangle-44-DRTA6S {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-students-mobile .rectangle-44-FxsTuj {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-students-mobile .rectangle-44-UxBcup {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-students-mobile .rectangle-44-XEhIzO {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-students-mobile .rectangle-44-x92G5j {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-students-mobile .rectangle-44-ytzoYe {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-students-mobile .rectangle-47-PqMIxZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 4px;
  position: absolute;
  top: 97px;
  width: 342px;
}
.teacher-students-mobile .rectangle-48-Fiy3Ky {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-students-mobile .rectangle-48-MRvDS0 {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.teacher-students-mobile .rectangle-49-MRvDS0 {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-students-mobile .rectangle-49-VweSAY {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-students-mobile .rectangle-50-4XwmcE {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-students-mobile .rectangle-50-MRvDS0 {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-students-mobile .shreyans-jain-LCtjVZ {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 140px;
}
.teacher-students-mobile .span0-Tuiux5 {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
}
.teacher-students-mobile .span0-vfRNhi {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
}
.teacher-students-mobile .span1-Tuiux5 {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}
.teacher-students-mobile .span1-vfRNhi {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}
.teacher-students-mobile .status-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 187px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 7px;
  width: 49px;
}
.teacher-students-mobile .submitted-7iGIN1 {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-students-mobile .submitted-FVieMB {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-students-mobile .submitted-MiJunx {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-students-mobile .submitted-zHT0nt {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-students-mobile .time-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 11px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 7px;
  width: 42px;
}
.teacher-students-mobile .upcoming-Fiy3Ky {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.teacher-students-mobile .vatsal-agarwal-qJWHC5 {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 140px;
}
.teacher-students-mobile .vector-7YVAkz {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.teacher-students-mobile .vector-9en3Br {
  background-color: transparent;
  height: 18px;
  left: -0px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-students-mobile .vector-CcJI6B {
  background-color: transparent;
  height: 8px;
  left: 1px;
  position: absolute;
  top: -0px;
  width: 7px;
}
.teacher-students-mobile .vector-RChgoP {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.teacher-students-mobile .vector-UkMJc4 {
  background-color: transparent;
  height: 1px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-students-mobile .vector-VOxOl6 {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-students-mobile .vector-Vn3aY5 {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.teacher-students-mobile .vector-Z7dDGr {
  background-color: transparent;
  height: 18px;
  left: -0px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-students-mobile .vector-ZMn0CM {
  background-color: transparent;
  height: 1px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-students-mobile .vector-ZiDAHi {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-students-mobile .vector-aEjS23 {
  background-color: transparent;
  height: 18px;
  left: -0px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-students-mobile .vector-f2PIuq {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.teacher-students-mobile .vector-hEE9Rr {
  background-color: transparent;
  height: 8px;
  left: 1px;
  position: absolute;
  top: -0px;
  width: 7px;
}
.teacher-students-mobile .vector-lP2eQk {
  background-color: transparent;
  height: 8px;
  left: 1px;
  position: absolute;
  top: -0px;
  width: 7px;
}
.teacher-students-mobile .vector-ntxlEr {
  background-color: transparent;
  height: 1px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-students-mobile .vector-sIzuEd {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-students-mobile .vector-zLTigN {
  background-color: transparent;
  height: 7px;
  left: 1px;
  position: absolute;
  top: 0px;
  width: 7px;
}
.teacher-students-mobile .x10pm-DRTA6S {
  background-color: transparent;
  height: auto;
  left: 7px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 42px;
}
.teacher-students-mobile .x12pm-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 13px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 51px;
  width: 44px;
}
.teacher-students-mobile .x1pm-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 13px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 114px;
  width: 31px;
}
.teacher-students-mobile .x2pm-VweSAY {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.teacher-students-mobile .x3pm-Fiy3Ky {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.teacher-students-mobile .x4pm-ytzoYe {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-students-mobile .x5pm-4XwmcE {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-students-mobile .x6pm-x92G5j {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-students-mobile .x7pm-XEhIzO {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-students-mobile .x8pm-UxBcup {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-students-mobile .x9pm-FxsTuj {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .teacher-students {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .teacher-students-mobile {
    display: none;
  }
}
