
body {
  font-family: "Montserrat", Helvetica;
}

.relational-manager-ticket-list {
  height: auto !important;
}

.relational-manager-ticket-list .sider-hc-teacher-list {
  width: 50px;
  height: 100vh;

  background-color: #1f2531;
}

.relational-manager-ticket-list .sider-hc-teacher-list-in-wdt-80 {
  width: 100%;
  height: 70%;
  margin: 120px 0px 30px;
  color: gray;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.relational-manager-ticket-list .biba-sobti-vgli2x {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2px;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.relational-manager-ticket-list .bibasobtigmailcom-vgli2x {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2px;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}

.relational-manager-ticket-mobile {
  align-items: flex-end;
  background-color: var(--coconut);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 1552px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 6px;
  position: relative;
  width: 414px;
}
.relational-manager-ticket-mobile .flex-row-C61RwL {
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.relational-manager-ticket-mobile .component-1-dXBp89 {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.relational-manager-ticket-mobile .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.relational-manager-ticket-mobile .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.relational-manager-ticket-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.relational-manager-ticket-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.relational-manager-ticket-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.relational-manager-ticket-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.datePicker-inshc {
  border-radius: 5px;
  padding: 5px;
  margin: 0px;
  height: 40px;
}

.style-a-xdjkhs-1 {
  display: flex;
  align-items: center;
  width: 70%;
}

.div-btn-anajss01 {
  width: 120px;
  height: 39px;
  border: none;
  text-align: center;
  color: white;
  background-color: #ec3545;
  border-radius: 5px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-left: 15px;
}

.list-divhc-dajak12 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #ec3545;
  height: 40px;
}

.logo-component {
  border: 1px solid var(--blue-zodiac);
  height: 105px;
  width: 162px;
}

.body_hc_class {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  margin-left: 68px;
  margin-top: 42px;
}

.heading_hc_class{
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 105px;
  justify-content: space-between;
  margin-right: 22px;
  width: 80%;
}

/* .rggsa-293njaln {
  font-size: 10px;
  color: gray;
} */

.search_class {
  width: 100%;
  margin-top: 40px;
}

.search_text {
  color: #ec3545;
  margin: auto;
  font-size: 30px;
  font-weight: 550;
  text-align: center;
}

.drop-down {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drop-down p {
  font-size: 20px;
  font-weight: 500;
}

.classroom_content1 {
  margin: 40px 0;
  display: flex;
}

.classroom_content2 {
  display: none;
  width: 90%;
  margin: auto;
}

.create_class {
  width: 100%;
  margin: 40px 0;
}

.classroom_content3 {
  margin: 50px 0;
  width: 100%;
}

.classroom_content4 {
  width: 70%;
  margin-left: 7%;
}

.date_and_time {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create_button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.relational-manager-ticket-mobile {
  width: 100%;
  height: auto;
}

.search_class_mobile {
  width: 100%;
  margin-top: 40px;
}

.search_text_mobile {
  color: #ec3545;
  margin: auto;
  font-size: 30px;
  font-weight: 550;
  text-align: center;
}

.classroom_content1_mobile {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  width: 100%;
}

.text_mobile {
  font-size: 15px;
}

.classroom_content2_mobile {
  width: 95%;
  margin: auto;
  display: none;
}

.create_class_mobile {
  margin: 40px 0;
}

.classroom_content3_mobile {
  margin: 40px 0;
}

.classroom_content4_mobile {
  width: 80%;
  margin: 0 auto;
}

.date_and_time_mobile {
  display: flex;
  justify-content: space-between;
}

.create_button_mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .style-a-xdjkhs-1 {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .datePicker-inshc {
    padding: 10px;
    text-align: center;
  }

  .list-divhc-dajak12 {
    width: 90%;
    margin-left: 12px;
  }
}

@media screen and (max-width: 599px) {
  .relational-manager-ticket-list {
    display: none;
  }
  .sider-hc-ticket-list {
    display: none;
  }

  .Rahkfaj-sdank23k {
    padding-right: 7px;
    overflow-y: auto;
  }
}
@media screen and (min-width: 600px) {
  .relational-manager-ticket-mobile {
    display: none;
  }
}
