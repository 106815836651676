/* screen - student-quiz-detail */

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-14px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.student-quiz-detail {
  background-color: rgba(253, 254, 255, 1);
  height: 100vh;
  margin: 0px;

  width: 100%;
}
.student-quiz-detail .analytics-24px-3-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 644px;
  width: 24px;
}
.student-quiz-detail .arrowback-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 10%;
  overflow: hidden;
  position: absolute;
  top: 222px;
  width: 24px;
}
.student-quiz-detail .assigned-AvtzuR {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  right: 54px;
  text-align: left;
  top: 26px;
  width: auto;
}
.student-quiz-detail .assignment-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 348px;
  width: 24px;
}
.student-quiz-detail .biba-sobti-VdQhAS {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 92px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.student-quiz-detail .bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 47px;
  width: auto;
}
.student-quiz-detail .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;
  left: 123px;
  position: absolute;
  top: 45px;
  width: 212px;
}
.student-quiz-detail .component-29-gW6vdi {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 53px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 53px;
}
.student-quiz-detail .description-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 496px;
  width: 24px;
}
.student-quiz-detail .doubt-forum-C61RwL {
  background-color: transparent;
  color: #dc3545;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 10px;
  width: 168px;
}
.student-quiz-detail .ellipse-2-OSIBbx {
  background-color: transparent;
  height: 19.05%;
  left: 57.14%;
  position: absolute;
  top: 21.43%;
  width: 19.05%;
}
.student-quiz-detail .group-0M78Jh {
  background-color: transparent;
  background-image: url(./img/vector-200@2x.png);
  background-size: 100% 100%;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-160-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  position: absolute;
  top: 718px;
  width: 24px;
}
.student-quiz-detail .group-1G6kDx {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-quiz-detail .group-22-C61RwL {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}
.student-quiz-detail .group-26DuQS {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-quiz-detail .group-30TA9U {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail .group-3YhNjD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-3qdjky {
  background-color: transparent;
  background-image: url(./img/vector-39@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail .group-46-C61RwL {
  background-color: transparent;
  height: 346px;
  left: 12%;
  position: absolute;
  top: 297px;
  width: 45%;
}
.student-quiz-detail .group-4fKC6i {
  background-color: transparent;
  background-image: url(./img/vector-40@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-quiz-detail .group-4wy4Bx {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-5YlXKz {
  background-color: transparent;
  background-image: url(./img/vector-40@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-quiz-detail .group-5jt8cF {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-5r1RGr {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-quiz-detail .group-64-gW6vdi {
  background-color: transparent;
  height: 50%;
  left: 19.44%;
  position: absolute;
  top: 25%;
  width: 61.11%;
}
.student-quiz-detail .group-67-C61RwL {
  background-color: transparent;
  height: 53px;
  left: 13.2%;
  position: absolute;
  top: 313px;
  width: 53px;
}
.student-quiz-detail .group-8RWWXx {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}
.student-quiz-detail .group-8SehwL {
  background-color: transparent;
  background-image: url(./img/vector-47@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-quiz-detail .group-8mXtiA {
  background-color: transparent;
  background-image: url(./img/vector-47@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-quiz-detail .group-Ambq12 {
  background-color: transparent;
  background-image: url(./img/vector-48@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-quiz-detail .group-B0zdg8 {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-quiz-detail .group-BVoyFW {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-quiz-detail .group-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 22px;
  position: absolute;
  top: 422px;
  width: 20px;
}
.student-quiz-detail .group-CZ3XDU {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-quiz-detail .group-E9Z3Qz {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-quiz-detail .group-Eub1uD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-FDKNYY {
  background-color: transparent;
  background-image: url(./img/vector-47@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-quiz-detail .group-GQEqeI {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-GQNshl {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-Gv5bm4 {
  background-color: transparent;
  background-image: url(./img/vector-39@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail .group-H4kFYy {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-quiz-detail .group-Hc5Phf {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-quiz-detail .group-HkDxar {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-I6cVpt {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-Ie5dUU {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-quiz-detail .group-J1LPKs {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-K12spc {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail .group-KLBpwU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-quiz-detail .group-KWN2RE {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-quiz-detail .group-KcOtnV {
  background-color: transparent;
  background-image: url(./img/vector-47@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-quiz-detail .group-KsMB6y {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-quiz-detail .group-LCuxKV {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-quiz-detail .group-LMly0o {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-quiz-detail .group-NOXmfT {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-OUAngl {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}
.student-quiz-detail .group-SfTnen {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-quiz-detail .group-SlOO8W {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-quiz-detail .group-SlxGcy {
  background-color: transparent;
  background-image: url(./img/vector-39@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail .group-Sw5xnU {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-quiz-detail .group-W5IipO {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-WAmUNE {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-quiz-detail .group-X6Y0CP {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-quiz-detail .group-XHqu7O {
  background-color: transparent;
  background-image: url(./img/vector-39@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail .group-Z0P29R {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-quiz-detail .group-Zmqz7u {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-quiz-detail .group-a2vI4G {
  background-color: transparent;
  background-image: url(./img/vector-36@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-quiz-detail .group-b1ls5q {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-quiz-detail .group-bznq7Z {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-quiz-detail .group-cTYyqt {
  background-color: transparent;
  background-image: url(./img/vector-36@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-quiz-detail .group-ce7Ifp {
  background-color: transparent;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 20px;
}
.student-quiz-detail .group-dimqSz {
  background-color: transparent;
  background-image: url(./img/vector-40@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-quiz-detail .group-f6NdeG {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-quiz-detail .group-fbkhx3 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail .group-fxegOB {
  background-color: transparent;
  background-image: url(./img/vector-48@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-quiz-detail .group-hNGwvV {
  background-color: transparent;
  background-image: url(./img/vector-36@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-quiz-detail .group-hcfGEs {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}
.student-quiz-detail .group-hoVMgD {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-htgOUZ {
  background-color: transparent;
  height: 3px;
  left: 38px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-quiz-detail .group-hxa4BC {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-quiz-detail .group-kK588U {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-quiz-detail .group-lX9zMw {
  background-color: transparent;
  background-image: url(./img/vector-48@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-quiz-detail .group-lmXTEf {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-quiz-detail .group-m8nkkI {
  background-color: transparent;
  background-image: url(./img/vector-41@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-quiz-detail .group-mnqxdV {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-quiz-detail .group-oRRBjL {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-quiz-detail .group-paC7Rl {
  background-color: transparent;
  background-image: url(./img/vector-42@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-quiz-detail .group-pmZUQc {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-quiz-detail .group-sx6xBo {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-tDnSYz {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-quiz-detail .group-tr5zNE {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .group-wbAI42 {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail .group-wotaxO {
  background-color: transparent;
  background-image: url(./img/vector-48@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-quiz-detail .group-wpJ3xR {
  background-color: transparent;
  background-image: url(./img/vector-40@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-quiz-detail .group-xKrbSU {
  background-color: transparent;
  background-image: url(./img/vector-36@2x.png);
  background-size: 100% 100%;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-quiz-detail .group-xfUToA {
  background-color: transparent;
  height: 3px;
  left: 36px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-quiz-detail .group-zkxeqe {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 2px;
}
.student-quiz-detail .help-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 570px;
  width: 24px;
}
.student-quiz-detail .how-to-solve-this-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1031px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 439px;
  width: 290px;
}
.student-quiz-detail .join-4-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 974px;
  overflow: hidden;
  position: absolute;
  top: 261px;
  width: 46px;
}
.student-quiz-detail .join-5-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 974px;
  overflow: hidden;
  position: absolute;
  top: 348px;
  width: 46px;
}
.student-quiz-detail .join-6-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 978px;
  overflow: hidden;
  position: absolute;
  top: 435px;
  width: 46px;
}
.student-quiz-detail .join-7-C61RwL {
  background-color: transparent;
  height: 35px;
  left: 981px;
  overflow: hidden;
  position: absolute;
  top: 519px;
  width: 46px;
}
.student-quiz-detail .line-5-9p2GBv {
  background-color: transparent;
  height: 99.05%;
  left: 0.9%;
  position: absolute;
  top: 0.48%;
  width: 99.29%;
}
.student-quiz-detail .line-6-9p2GBv {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}
.student-quiz-detail .line-8-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 14%;
  position: absolute;
  top: 396px;
  width: 41.5%;
}
.student-quiz-detail .mathematics-dr-yashna-sharma-AvtzuR {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 40px;
  width: 257px;
}
.student-quiz-detail .my-quiz-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 15%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 210px;
  width: 310px;
}
.student-quiz-detail .notifications-24px-2-1-C61RwL {
  background-color: transparent;
  height: 42px;
  left: 976px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 42px;
}
.student-quiz-detail .please-check-my-approach-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1034px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 523px;
  width: 290px;
}
.student-quiz-detail .quiz-2-AvtzuR {
  background-color: transparent;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  min-width: 164px;
}
.student-quiz-detail .rectangle-24-C61RwL-stu-s1 {
  background-color: #1f2531;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 63px;

  overflow-y: scroll;
  padding: 150px 0px 20px 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.student-quiz-detail .rectangle-40-AvtzuR {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 346px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.student-quiz-detail .rectangle-41-AvtzuR {
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 35%;
  position: absolute;
  top: 115px;
  width: 175px;
}
.student-quiz-detail .rectangle-42-AvtzuR-001 {
  background-color: var(--white);
  border-radius: 5px;
  height: 30px;
  left: 35%;
  position: absolute;
  top: 243px;
  width: 175px;

  box-shadow: 0px 2px 2px #ccc;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 600;

  text-align: center;
  padding: 7px;
  color: #dc3545;
}
.student-quiz-detail .rectangle-43-AvtzuR {
  background-color: #dc3545;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 35%;
  position: absolute;
  top: 290px;
  width: 175px;
}
.student-quiz-detail .rectangle-43-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* height: 417px; */
  right: 5%;
  position: absolute;
  top: 197px;
  width: 29%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding: 72px 24px 40px;
  min-height: 160px;
}
.student-quiz-detail .rectangle-7-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 630px;
  position: absolute;
  top: 631px;
  width: 1px;
}
.student-quiz-detail .span0-F71xPZ {
  color: rgba(126, 126, 126, 1);
  font-style: normal;
}
.student-quiz-detail .span0-RD25Gb {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
}
.student-quiz-detail .span1-F71xPZ {
  color: rgba(0, 0, 0, 1);
  font-style: normal;
  font-weight: 600;
}
.student-quiz-detail .span1-RD25Gb {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
  font-weight: 600;
}
.student-quiz-detail .span2-F71xPZ {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
  font-weight: 600;
}
.student-quiz-detail .stickynote2-24px-1-Cp4Be6 {
  background-color: transparent;
  height: 24px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 24px;
}
.student-quiz-detail .submit-AvtzuR {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  right: 325px;
  text-align: left;
  top: 299px;
  width: auto;
}
.student-quiz-detail .supportagent-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 792px;
  width: 24px;
}
.student-quiz-detail .vector-0HfMqN {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}
.student-quiz-detail .vector-1G6kDx {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}
.student-quiz-detail .vector-1YY105 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}
.student-quiz-detail .vector-1ewXKa {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}
.student-quiz-detail .vector-26DuQS {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}
.student-quiz-detail .vector-2kxNnt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}
.student-quiz-detail .vector-30TA9U {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}
.student-quiz-detail .vector-3q0WPs {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}
.student-quiz-detail .vector-3qdjky {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}
.student-quiz-detail .vector-4ae2Rf {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}
.student-quiz-detail .vector-4fKC6i {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}
.student-quiz-detail .vector-4sG9FF {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}
.student-quiz-detail .vector-4vfNnN {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}
.student-quiz-detail .vector-5S9pFx {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}
.student-quiz-detail .vector-5YlXKz {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}
.student-quiz-detail .vector-5r1RGr {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}
.student-quiz-detail .vector-5x4wyG {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}
.student-quiz-detail .vector-6XJ1dC {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}
.student-quiz-detail .vector-7QdKbH {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}
.student-quiz-detail .vector-7pUNkI {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-quiz-detail .vector-7txfVx {
  background-color: transparent;
  height: 58.33%;
  left: 20.83%;
  position: absolute;
  top: 20.83%;
  width: 58.33%;
}
.student-quiz-detail .vector-7uUfdl {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}
.student-quiz-detail .vector-8JNBpx {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}
.student-quiz-detail .vector-8RWWXx {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}
.student-quiz-detail .vector-8SehwL {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}
.student-quiz-detail .vector-8U01yU {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}
.student-quiz-detail .vector-8XEWy4 {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}
.student-quiz-detail .vector-8mXtiA {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}

.student-quiz-detail .rectangle-43-C61RwL .doubt-row {
  display: flex;
  justify-content: space-evenly;
  margin: 4px;
  align-items: center;
}

.student-quiz-detail .vector-8xAgQn {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}
.student-quiz-detail .vector-9opORi {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}
.student-quiz-detail .vector-9vYPU5 {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}
.student-quiz-detail .vector-AONfqU {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}
.student-quiz-detail .vector-Ambq12 {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}
.student-quiz-detail .vector-AuhUI1 {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}
.student-quiz-detail .vector-B0zdg8 {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}
.student-quiz-detail .vector-BVoyFW {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}
.student-quiz-detail .vector-Bd1Smh {
  background-color: transparent;
  height: 101.89%;
  left: -0.94%;
  position: absolute;
  top: -0.94%;
  width: 101.89%;
}
.student-quiz-detail .vector-C61RwL {
  background-color: transparent;
  height: 2.34%;
  left: 1.32%;
  position: absolute;
  top: 92.77%;
  width: 1.67%;
}
.student-quiz-detail .vector-CLdsug {
  background-color: transparent;
  height: 82.14%;
  left: 16.67%;
  position: absolute;
  top: 9.97%;
  width: 66.67%;
}
.student-quiz-detail .vector-CMx3Uj {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}
.student-quiz-detail .vector-CZ3XDU {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}
.student-quiz-detail .vector-ChPPby {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}
.student-quiz-detail .vector-DcBIgL {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}
.student-quiz-detail .vector-Dn0VOv {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}
.student-quiz-detail .vector-E3VqMR {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}
.student-quiz-detail .vector-E9Z3Qz {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}
.student-quiz-detail .vector-FDKNYY {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}
.student-quiz-detail .vector-Fb0JQL {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}
.student-quiz-detail .vector-FxTcxt {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}
.student-quiz-detail .vector-G0yBDR {
  background-color: transparent;
  height: 83.33%;
  left: 16.67%;
  position: absolute;
  top: 8.33%;
  width: 66.67%;
}
.student-quiz-detail .vector-Gv5bm4 {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}
.student-quiz-detail .vector-H4kFYy {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}
.student-quiz-detail .vector-Hc5Phf {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}
.student-quiz-detail .vector-JWb765 {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}
.student-quiz-detail .vector-K12spc {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}
.student-quiz-detail .vector-KLBpwU {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}
.student-quiz-detail .vector-KLFwa3 {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}
.student-quiz-detail .vector-KRXr9B {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}
.student-quiz-detail .vector-KWN2RE {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}
.student-quiz-detail .vector-KcOtnV {
  background-color: transparent;
  height: 41.8%;
  left: 4.12%;
  position: absolute;
  top: 13.83%;
  width: 19.57%;
}
.student-quiz-detail .vector-LCuxKV {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}
.student-quiz-detail .vector-LMly0o {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}
.student-quiz-detail .vector-LgtYic {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}
.student-quiz-detail .vector-MWjU8S {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}
.student-quiz-detail .vector-MtJxOL {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}
.student-quiz-detail .vector-NLtoul {
  background-color: transparent;
  height: 8.96%;
  left: 25.16%;
  position: absolute;
  top: 37.19%;
  width: 13.04%;
}
.student-quiz-detail .vector-OB8oOM {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}
.student-quiz-detail .vector-OSIBbx {
  background-color: transparent;
  height: 1.19%;
  left: 49.4%;
  position: absolute;
  top: 49.4%;
  width: 1.19%;
}
.student-quiz-detail .vector-OUAngl {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}
.student-quiz-detail .vector-OYLvDa {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}
.student-quiz-detail .vector-OsxaKh {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}
.student-quiz-detail .vector-Ozaj6l {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}
.student-quiz-detail .vector-QxuFcp {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}
.student-quiz-detail .vector-SfTnen {
  background-color: transparent;
  height: 41.8%;
  left: 4.14%;
  position: absolute;
  top: 13.83%;
  width: 55.43%;
}
.student-quiz-detail .vector-SlOO8W {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}
.student-quiz-detail .vector-SlxGcy {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}
.student-quiz-detail .vector-Sw5xnU {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}
.student-quiz-detail .vector-T0rcQo {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}
.student-quiz-detail .vector-Ta8Heo {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}
.student-quiz-detail .vector-TlPffh {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}
.student-quiz-detail .vector-TmFVy3 {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}
.student-quiz-detail .vector-UUKtms {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}
.student-quiz-detail .vector-UaM55c {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-quiz-detail .vector-UxrCfI {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}
.student-quiz-detail .vector-VAjk24 {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}
.student-quiz-detail .vector-VJLVH1 {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}
.student-quiz-detail .vector-VMr6Om {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 26.76%;
  width: 1.67%;
}
.student-quiz-detail .vector-VP2Rux {
  background-color: transparent;
  height: 29.85%;
  left: 37.94%;
  position: absolute;
  top: 2.71%;
  width: 21.74%;
}
.student-quiz-detail .vector-VdQhAS {
  background-color: transparent;
  height: 100%;
  left: 73.42%;
  position: absolute;
  top: 0px;
  width: 25.32%;
}
.student-quiz-detail .vector-W1hXZ4 {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}
.student-quiz-detail .vector-WAmUNE {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}
.student-quiz-detail .vector-WK7UJi {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}
.student-quiz-detail .vector-WVknxn {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}
.student-quiz-detail .vector-WbzDQU {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-quiz-detail .vector-X6Y0CP {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}
.student-quiz-detail .vector-XHqu7O {
  background-color: transparent;
  height: 70.16%;
  left: -0.5%;
  position: absolute;
  top: 8.33%;
  width: 95.65%;
}
.student-quiz-detail .vector-XQwS4n {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}
.student-quiz-detail .vector-XjFi5l {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}
.student-quiz-detail .vector-XqVjZB {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}
.student-quiz-detail .vector-XwxuE1 {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}
.student-quiz-detail .vector-YarvLR {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}
.student-quiz-detail .vector-Z0P29R {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}
.student-quiz-detail .vector-a2vI4G {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}
.student-quiz-detail .vector-apzjgl {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}
.student-quiz-detail .vector-b1ls5q {
  background-color: transparent;
  height: 49.26%;
  left: 29.51%;
  position: absolute;
  top: 51.43%;
  width: 48.91%;
}
.student-quiz-detail .vector-baKUI6 {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}
.student-quiz-detail .vector-bznq7Z {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}
.student-quiz-detail .vector-c18eCt {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}
.student-quiz-detail .vector-cTYyqt {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}
.student-quiz-detail .vector-dBr6KK {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}
.student-quiz-detail .vector-dLw05Z {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-quiz-detail .vector-dimqSz {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}
.student-quiz-detail .vector-dmgbrE {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}
.student-quiz-detail .vector-dsyqYk {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}
.student-quiz-detail .vector-ec7mDZ {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-quiz-detail .vector-eh9WzI {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}
.student-quiz-detail .vector-f6NdeG {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}
.student-quiz-detail .vector-fCysJq {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}
.student-quiz-detail .vector-fbkhx3 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}
.student-quiz-detail .vector-fxegOB {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}
.student-quiz-detail .vector-g9aAPA {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}
.student-quiz-detail .vector-gsSdey {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}
.student-quiz-detail .vector-hNGwvV {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}
.student-quiz-detail .vector-hPKSzt {
  background-color: transparent;
  height: 19.4%;
  left: 29.26%;
  position: absolute;
  top: 81.41%;
  width: 29.35%;
}
.student-quiz-detail .vector-hcfGEs {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}
.student-quiz-detail .vector-htgOUZ {
  background-color: transparent;
  height: 29.85%;
  left: 2.17%;
  position: absolute;
  top: 8.24%;
  width: 18.48%;
}
.student-quiz-detail .vector-hxa4BC {
  background-color: transparent;
  height: 7.46%;
  left: 68.27%;
  position: absolute;
  top: 51.44%;
  width: 6.52%;
}
.student-quiz-detail .vector-ib8Dlq {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}
.student-quiz-detail .vector-irJBpK {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-quiz-detail .vector-jQrdMY {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}
.student-quiz-detail .vector-jmCVFs {
  background-color: transparent;
  height: 121.96%;
  left: -10.1%;
  position: absolute;
  top: -10.98%;
  width: 120.19%;
}
.student-quiz-detail .vector-kK588U {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}
.student-quiz-detail .vector-l8qsXH {
  background-color: transparent;
  height: 11.94%;
  left: 24.14%;
  position: absolute;
  top: 18.13%;
  width: 15.22%;
}
.student-quiz-detail .vector-lWWNsc {
  background-color: transparent;
  height: 4.05%;
  left: 74.38%;
  position: absolute;
  top: -1.45%;
  width: 7.02%;
}
.student-quiz-detail .vector-lX9zMw {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}
.student-quiz-detail .vector-lmXTEf {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}
.student-quiz-detail .vector-m8nkkI {
  background-color: transparent;
  height: 52.24%;
  left: 49.54%;
  position: absolute;
  top: 8.1%;
  width: 45.65%;
}
.student-quiz-detail .vector-mmO9xv {
  background-color: transparent;
  height: 5.97%;
  left: 36.75%;
  position: absolute;
  top: 28.23%;
  width: 3.26%;
}
.student-quiz-detail .vector-mzXdH9 {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 19.53%;
  width: 1.67%;
}
.student-quiz-detail .vector-n1UKnn {
  background-color: transparent;
  height: 110.03%;
  left: -7.82%;
  position: absolute;
  top: -5.02%;
  width: 115.63%;
}
.student-quiz-detail .vector-nXJ6dP {
  background-color: transparent;
  height: 121.98%;
  left: -1.04%;
  position: absolute;
  top: -10.99%;
  width: 102.08%;
}
.student-quiz-detail .vector-oOS98D {
  background-color: transparent;
  height: 144.3%;
  left: -3.64%;
  position: absolute;
  top: -22.15%;
  width: 107.28%;
}
.student-quiz-detail .vector-oRRBjL {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}
.student-quiz-detail .vector-oZNlX3 {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}
.student-quiz-detail .vector-objxfZ {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}
.student-quiz-detail .vector-paC7Rl {
  background-color: transparent;
  height: 29.85%;
  left: 2.13%;
  position: absolute;
  top: 8.12%;
  width: 45.65%;
}
.student-quiz-detail .vector-pagq92 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}
.student-quiz-detail .vector-pgMXxb {
  background-color: transparent;
  height: 4.48%;
  left: 12.14%;
  position: absolute;
  top: 12.28%;
  width: 8.7%;
}
.student-quiz-detail .vector-pmZUQc {
  background-color: transparent;
  height: 14.93%;
  left: 17.15%;
  position: absolute;
  top: 40.61%;
  width: 11.96%;
}
.student-quiz-detail .vector-pxZ96q {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}
.student-quiz-detail .vector-qF2ch0 {
  background-color: transparent;
  height: 34.33%;
  left: 2.51%;
  position: absolute;
  top: 22.69%;
  width: 58.7%;
}
.student-quiz-detail .vector-qGJxwJ {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}
.student-quiz-detail .vector-qHVi3n {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}
.student-quiz-detail .vector-qmzfLw {
  background-color: transparent;
  height: 121.98%;
  left: -2.21%;
  position: absolute;
  top: -10.99%;
  width: 104.42%;
}
.student-quiz-detail .vector-qy1MCO {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}
.student-quiz-detail .vector-r18CAb {
  background-color: transparent;
  height: 144.31%;
  left: -20.67%;
  position: absolute;
  top: -22.16%;
  width: 141.34%;
}
.student-quiz-detail .vector-r4buxr {
  background-color: transparent;
  height: 5.97%;
  left: 23.89%;
  position: absolute;
  top: 28.23%;
  width: 2.17%;
}
.student-quiz-detail .vector-rnzczl {
  background-color: transparent;
  height: 5.97%;
  left: 51.21%;
  position: absolute;
  top: 63.23%;
  width: 6.52%;
}
.student-quiz-detail .vector-sCPPBx {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}
.student-quiz-detail .vector-tDnSYz {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}
.student-quiz-detail .vector-tQ6NQH {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}
.student-quiz-detail .vector-tSUwp7 {
  background-color: transparent;
  height: 19.4%;
  left: 25.16%;
  position: absolute;
  top: 22.08%;
  width: 13.04%;
}
.student-quiz-detail .vector-uhbuVM {
  background-color: transparent;
  height: 36.11%;
  left: 20.01%;
  position: absolute;
  top: 16.53%;
  width: 60%;
}
.student-quiz-detail .vector-v9Qr6G {
  background-color: transparent;
  height: 29.85%;
  left: 38.05%;
  position: absolute;
  top: 2.72%;
  width: 33.7%;
}
.student-quiz-detail .vector-vJyLWf {
  background-color: transparent;
  height: 8.96%;
  left: 25.34%;
  position: absolute;
  top: 36.57%;
  width: 6.52%;
}
.student-quiz-detail .vector-wEJYwt {
  background-color: transparent;
  height: 66.67%;
  left: 16.67%;
  position: absolute;
  top: 16.67%;
  width: 66.67%;
}
.student-quiz-detail .vector-wU39qv {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}
.student-quiz-detail .vector-wbAI42 {
  background-color: transparent;
  height: 82.1%;
  left: 7.08%;
  position: absolute;
  top: 2.36%;
  width: 93.48%;
}
.student-quiz-detail .vector-wotaxO {
  background-color: transparent;
  height: 14.93%;
  left: 17.55%;
  position: absolute;
  top: 40.61%;
  width: 28.26%;
}
.student-quiz-detail .vector-wpJ3xR {
  background-color: transparent;
  height: 70.16%;
  left: -0.39%;
  position: absolute;
  top: 8.45%;
  width: 23.91%;
}
.student-quiz-detail .vector-x7OZie {
  background-color: transparent;
  height: 32.84%;
  left: 2.26%;
  position: absolute;
  top: 24.54%;
  width: 21.74%;
}
.student-quiz-detail .vector-xKrbSU {
  background-color: transparent;
  height: 101.5%;
  left: 10.87%;
  position: absolute;
  top: -1.01%;
  width: 85.87%;
}
.student-quiz-detail .vector-xYu4ww {
  background-color: transparent;
  height: 144.31%;
  left: -3.64%;
  position: absolute;
  top: -22.16%;
  width: 107.28%;
}
.student-quiz-detail .vector-xfUToA {
  background-color: transparent;
  height: 13.43%;
  left: 64.16%;
  position: absolute;
  top: 41.24%;
  width: 17.39%;
}
.student-quiz-detail .vector-xr8pKE {
  background-color: transparent;
  height: 4.48%;
  left: 11.9%;
  position: absolute;
  top: 12.28%;
  width: 29.35%;
}
.student-quiz-detail .vector-xy9YKY {
  background-color: transparent;
  height: 11.11%;
  left: 30%;
  position: absolute;
  top: 50%;
  width: 10%;
}
.student-quiz-detail .vector-ydL8U7 {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-quiz-detail .vector-ye3NYT {
  background-color: transparent;
  height: 11.11%;
  left: 60%;
  position: absolute;
  top: 50%;
  width: 10%;
}
.student-quiz-detail .vector-ywnyBE {
  background-color: transparent;
  height: 83.33%;
  left: 8.33%;
  position: absolute;
  top: 8.33%;
  width: 83.33%;
}
.student-quiz-detail .vector-zJJzco {
  background-color: transparent;
  height: 121.99%;
  left: -23.2%;
  position: absolute;
  top: -10.99%;
  width: 146.4%;
}
.student-quiz-detail .vector-zLY8MO {
  background-color: transparent;
  height: 11.94%;
  left: 23.89%;
  position: absolute;
  top: 18.13%;
  width: 8.7%;
}
.student-quiz-detail .vector-zkxeqe {
  background-color: transparent;
  height: 13.43%;
  left: 64.13%;
  position: absolute;
  top: 41.24%;
  width: 23.91%;
}
.student-quiz-detail .view-all-C61RwL {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  right: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 75px;
}
.student-quiz-detail .view-forum-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1227px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 298px;
  width: 113px;
}
.student-quiz-detail .view-forum-QxM5SU {
  background-color: transparent;
  height: auto;
  left: 1230px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 554px;
  width: 113px;
}
.student-quiz-detail .view-forum-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 1227px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 374px;
  width: 113px;
}
.student-quiz-detail .view-forum-mzXdH9 {
  background-color: transparent;
  height: auto;
  left: 1227px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 470px;
  width: 113px;
}
.student-quiz-detail .view-quiz-AvtzuR {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  right: 318px;
  text-align: left;
  top: 124px;
  width: auto;
}
.student-quiz-detail .what-is-the-si-unit-of-current-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1031px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 267px;
  width: 290px;
}
.student-quiz-detail .what-is-the-sol-f-this-question-C61RwL {
  background-color: transparent;
  height: auto;
  left: 1031px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 343px;
  width: 290px;
}
.student-quiz-detail .x-add-work-AvtzuR {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  right: 315px;
  text-align: left;
  top: 252px;
  width: auto;
}
.student-quiz-detail .x10-marks-AvtzuR {
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 75px;
  width: auto;
}
.student-quiz-detail .x14-C61RwL {
  background-color: transparent;
  color: rgba(244, 244, 244, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 1163px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 357px;
  width: auto;
}
.student-quiz-detail .your-marks-na-AvtzuR {
  background-color: transparent;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 84%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 69px;
  width: auto;
}
.student-quiz-detail .your-work-AvtzuR {
  background-color: transparent;
  height: auto;
  left: 41%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 204px;
  width: auto;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - student-quiz-detail-mobile */

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-14px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.student-quiz-detail-mobile {
  /* background-color: rgba(253, 254, 255, 1);
  height: 100vh;
  margin: 0px;
  min-height: 874px;
  min-width: 414px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%; */
  background-color: rgba(253, 254, 255, 1);
  height: auto;
  margin: 0px;
  min-height: 1009px;
  min-width: 414px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.student-quiz-detail-mobile .assigned-EGQFV0 {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  right: 33px;
  text-align: left;
  top: 26px;
  width: 53px;
}
.student-quiz-detail-mobile .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 45px;
  left: 26px;
  position: absolute;
  top: 24px;
  width: 87px;
}
.student-quiz-detail-mobile .component-29-87p3Hy {
  background-color: transparent;
  background-image: url(./img/ellipse-4-1@2x.png);
  background-size: 100% 100%;
  height: 38px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.student-quiz-detail-mobile .component-6-C61RwL {
  background-color: transparent;
  height: 18px;
  left: 362px;
  position: absolute;
  top: 37px;
  width: 23px;
}
.student-quiz-detail-mobile .doubt-forum-rFX9Nh {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 25px;
  width: 134px;
}
.student-quiz-detail-mobile .group-1pzCME {
  background-color: transparent;
  background-image: url(./img/vector-292@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-quiz-detail-mobile .group-3HWdqU {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-quiz-detail-mobile .group-3eHi1a {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-quiz-detail-mobile .group-3xCoUY {
  background-color: transparent;
  height: 3px;
  left: 37px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-quiz-detail-mobile .group-46-royatl {
  background-color: transparent;
  height: 346px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 379px;
}
.student-quiz-detail-mobile .group-4Tztbv {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail-mobile .group-5ZFw7U {
  background-color: transparent;
  background-image: url(./img/vector-293@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-quiz-detail-mobile .group-64-87p3Hy {
  background-color: transparent;
  background-image: url(./img/vector-349@2x.png);
  background-size: 100% 100%;
  height: 50%;
  left: 19.44%;
  position: absolute;
  top: 25%;
  width: 61.11%;
}
.student-quiz-detail-mobile .group-65-C61RwL {
  background-color: transparent;
  height: 38px;
  left: 36px;
  position: absolute;
  top: 216px;
  width: 36px;
}
.student-quiz-detail-mobile .group-6dsxAM {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-quiz-detail-mobile .group-6u2TAk {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-quiz-detail-mobile .group-85-C61RwL {
  background-color: transparent;
  height: 270px;
  left: 26px;
  position: absolute;
  top: 580px;
  width: 394px;
}
.student-quiz-detail-mobile .group-97-EGQFV0 {
  background-color: transparent;
  height: 25px;
  left: 110px;
  position: absolute;
  top: 134px;
  width: 99px;
}
.student-quiz-detail-mobile .group-98-C61RwL {
  background-color: transparent;
  height: 346px;
  left: 26px;
  position: absolute;
  top: 200px;
  width: 365px;
}
.student-quiz-detail-mobile .group-9neAS5 {
  background-color: transparent;
  background-image: url(./img/vector-300@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-quiz-detail-mobile .group-B3X4ab {
  background-color: transparent;
  background-image: url(./img/vector-293@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-quiz-detail-mobile .group-BpxbEL {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail-mobile .group-DhmCtC {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-quiz-detail-mobile .group-FASyuA {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail-mobile .group-HTx4Jc {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail-mobile .group-IfQt5H {
  background-color: transparent;
  background-image: url(./img/vector-301@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-quiz-detail-mobile .group-N2Y59c {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail-mobile .group-O5Cxw9 {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-quiz-detail-mobile .group-Ob1Rqs {
  background-color: transparent;
  background-image: url(./img/vector-246@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-quiz-detail-mobile .group-Qv0mZ4 {
  background-color: transparent;
  height: 3.1%;
  left: 14.75%;
  position: absolute;
  top: 59.26%;
  width: 16.74%;
}
.student-quiz-detail-mobile .group-S9AR9M {
  background-color: transparent;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail-mobile .group-T7EbAp {
  background-color: transparent;
  height: 3px;
  left: 37px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-quiz-detail-mobile .group-TdE1pD {
  background-color: transparent;
  background-image: url(./img/vector-255@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-quiz-detail-mobile .group-TtUkiV {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-quiz-detail-mobile .group-VOGBVy {
  background-color: transparent;
  background-image: url(./img/vector-298@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-quiz-detail-mobile .group-VRMQru {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail-mobile .group-WfnLBo {
  background-color: transparent;
  height: 3.1%;
  left: 9.72%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail-mobile .group-bX4Dvh {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail-mobile .group-bcFrzZ {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail-mobile .group-bof6oJ {
  background-color: transparent;
  height: 3px;
  left: 39px;
  position: absolute;
  top: 10px;
  width: 4px;
}
.student-quiz-detail-mobile .group-c0ymqA {
  background-color: transparent;
  background-image: url(./img/vector-298@2x.png);
  background-size: 100% 100%;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-quiz-detail-mobile .group-c9XjZ6 {
  background-color: transparent;
  height: 3px;
  left: 29px;
  position: absolute;
  top: 10px;
  width: 3px;
}
.student-quiz-detail-mobile .group-dS4UXQ {
  background-color: transparent;
  background-image: url(./img/vector-301@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 17.99%;
  width: 17.22%;
}
.student-quiz-detail-mobile .group-dV81ro {
  background-color: transparent;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-quiz-detail-mobile .group-dcaDkc {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-quiz-detail-mobile .group-gn7VaX {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail-mobile .group-gvV2Rx {
  background-color: transparent;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-quiz-detail-mobile .group-hhAbtl {
  background-color: transparent;
  background-image: url(./img/vector-254@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-quiz-detail-mobile .group-iieIco {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail-mobile .group-jLf4aV {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-quiz-detail-mobile .group-k7b1Mi {
  background-color: transparent;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail-mobile .group-lw8NAW {
  background-color: transparent;
  height: 3px;
  left: 39px;
  position: absolute;
  top: 10px;
  width: 4px;
}
.student-quiz-detail-mobile .group-mokeKx {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-quiz-detail-mobile .group-ms1pvS {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-quiz-detail-mobile .group-nD2hpN {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-quiz-detail-mobile .group-nzNou6 {
  background-color: transparent;
  height: 73.71%;
  left: 7.64%;
  position: absolute;
  top: 9.47%;
  width: 15.49%;
}
.student-quiz-detail-mobile .group-qGye3v {
  background-color: transparent;
  height: 3.1%;
  left: 55.69%;
  position: absolute;
  top: 59.26%;
  width: 3.85%;
}
.student-quiz-detail-mobile .group-qpftC5 {
  background-color: transparent;
  background-image: url(./img/vector-300@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 12.97%;
  width: 17.22%;
}
.student-quiz-detail-mobile .group-sku7Fc {
  background-color: transparent;
  height: 3px;
  left: 37px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.student-quiz-detail-mobile .group-tQwNUN {
  background-color: transparent;
  height: 3.1%;
  left: 46.05%;
  position: absolute;
  top: 7.95%;
  width: 10.33%;
}
.student-quiz-detail-mobile .group-toBf7r {
  background-color: transparent;
  height: 9.79%;
  left: 68.62%;
  position: absolute;
  top: 28.79%;
  width: 9.37%;
}
.student-quiz-detail-mobile .group-wQSXdG {
  background-color: transparent;
  height: 95.7%;
  left: 0px;
  position: relative;
  top: 2.15%;
  width: 100%;
}
.student-quiz-detail-mobile .group-x2R7zB {
  background-color: transparent;
  height: 3.1%;
  left: 4.8%;
  position: absolute;
  top: 59.26%;
  width: 3.84%;
}
.student-quiz-detail-mobile .group-xB1UPi {
  background-color: transparent;
  background-image: url(./img/vector-292@2x.png);
  background-size: 100% 100%;
  height: 3.1%;
  left: 28.52%;
  position: absolute;
  top: 59.26%;
  width: 25.76%;
}
.student-quiz-detail-mobile .group-xS7o0X {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-quiz-detail-mobile .group-xhwx9b {
  background-color: transparent;
  height: 17.64%;
  left: 25.44%;
  position: absolute;
  top: 22.96%;
  width: 8.46%;
}
.student-quiz-detail-mobile .group-xtOtSY {
  background-color: transparent;
  height: 6.19%;
  left: 28.52%;
  position: absolute;
  top: 57.74%;
  width: 2.97%;
}
.student-quiz-detail-mobile .group-zxfIi7 {
  background-color: transparent;
  height: 3px;
  left: 39px;
  position: absolute;
  top: 10px;
  width: 4px;
}
.student-quiz-detail-mobile .how-to-solve-this-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 70px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 214px;
  width: 296px;
}
.student-quiz-detail-mobile .join-7-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 11px;
  overflow: hidden;
  position: absolute;
  top: 68px;
  width: 47px;
}
.student-quiz-detail-mobile .join-8-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 9px;
  overflow: hidden;
  position: absolute;
  top: 139px;
  width: 47px;
}
.student-quiz-detail-mobile .join-9-rFX9Nh {
  background-color: transparent;
  height: 35px;
  left: 9px;
  overflow: hidden;
  position: absolute;
  top: 210px;
  width: 47px;
}
.student-quiz-detail-mobile .line-5-9p2GBv {
  background-color: transparent;
  height: 101.11%;
  left: 0.55%;
  position: absolute;
  top: -0.56%;
  width: 100%;
}
.student-quiz-detail-mobile .line-6-9p2GBv {
  background-color: transparent;
  height: 102.22%;
  left: -0.29%;
  position: absolute;
  top: -1.11%;
  width: 100.57%;
}
.student-quiz-detail-mobile .line-8-royatl {
  background-color: transparent;
  height: 3px;
  left: 24px;
  position: absolute;
  top: 97px;
  width: 312px;
}
.student-quiz-detail-mobile .mathematics-dr-yashna-sharma-EGQFV0 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 54px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 40px;
  width: 251px;
}
.student-quiz-detail-mobile .my-quiz-C61RwL {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 132px;
  width: 310px;
}
.student-quiz-detail-mobile .quiz-2-EGQFV0-0001 {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 54px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  min-width: 59px;
}
.student-quiz-detail-mobile .rectangle-40-EGQFV0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 346px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.student-quiz-detail-mobile .rectangle-41-kiZmGh {
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 25px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 140px;
}
.student-quiz-detail-mobile .rectangle-42-EGQFV0 {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  position: absolute;
  top: 243px;

  box-shadow: 0px 2px 2px #ccc;
  height: 30px;
  left: 115px;

  width: 140px;
  padding: 8px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: 600;

  text-align: center;
  color: #dc3545;
}
.student-quiz-detail-mobile .rectangle-43-EGQFV0-00101 {
  background-color: #dc3545;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 25px;
  left: 110px;
  position: absolute;
  top: 290px;
  width: 140px;
}
.student-quiz-detail-mobile .rectangle-44-rFX9Nh {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* height: 270px; */
  left: 0px;
  position: absolute;
  top: 0px;
  width: 367px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  min-height: 120px;

  padding: 66px 0;
}
.student-quiz-detail-mobile .rectangle-48-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.student-quiz-detail-mobile .rectangle-49-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 38.89%;
  width: 100%;
}
.student-quiz-detail-mobile .rectangle-50-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 77.78%;
  width: 100%;
}
.student-quiz-detail-mobile .span0-RD25Gb {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.student-quiz-detail-mobile .span1-RD25Gb {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.student-quiz-detail-mobile .submit-EGQFV0 {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  right: 176px;
  text-align: left;
  top: 299px;
  width: 42px;
}
.student-quiz-detail-mobile .vector-0IPvau {
  background-color: transparent;
  height: 29.85%;
  left: 37.87%;
  position: absolute;
  top: 2.72%;
  width: 34.05%;
}
.student-quiz-detail-mobile .vector-1Nxj6x {
  background-color: transparent;
  height: 121.98%;
  left: -7.1%;
  position: absolute;
  top: -10.99%;
  width: 114.19%;
}
.student-quiz-detail-mobile .vector-1TafeU {
  background-color: transparent;
  height: 121.99%;
  left: -21.65%;
  position: absolute;
  top: -10.99%;
  width: 143.3%;
}
.student-quiz-detail-mobile .vector-1oS1NN {
  background-color: transparent;
  height: 144.3%;
  left: -6.65%;
  position: absolute;
  top: -22.15%;
  width: 113.29%;
}
.student-quiz-detail-mobile .vector-1pzCME {
  background-color: transparent;
  height: 52.24%;
  left: 49.49%;
  position: absolute;
  top: 8.1%;
  width: 45.75%;
}
.student-quiz-detail-mobile .vector-1xHKrh {
  background-color: transparent;
  height: 11.94%;
  left: 23.98%;
  position: absolute;
  top: 18.13%;
  width: 8.51%;
}
.student-quiz-detail-mobile .vector-2rF7C5 {
  background-color: transparent;
  height: 110.03%;
  left: -6.59%;
  position: absolute;
  top: -5.02%;
  width: 113.19%;
}
.student-quiz-detail-mobile .vector-3HWdqU {
  background-color: transparent;
  height: 101.5%;
  left: 11.25%;
  position: absolute;
  top: -1.01%;
  width: 85.12%;
}
.student-quiz-detail-mobile .vector-3X2JZC {
  background-color: transparent;
  height: 144.31%;
  left: -4.02%;
  position: absolute;
  top: -22.16%;
  width: 108.03%;
}
.student-quiz-detail-mobile .vector-3xCoUY {
  background-color: transparent;
  height: 13.43%;
  left: 63.81%;
  position: absolute;
  top: 41.24%;
  width: 18.09%;
}
.student-quiz-detail-mobile .vector-5SSNkN {
  background-color: transparent;
  height: 29.85%;
  left: 37.64%;
  position: absolute;
  top: 2.71%;
  width: 22.34%;
}
.student-quiz-detail-mobile .vector-5YyGRW {
  background-color: transparent;
  height: 19.4%;
  left: 24.76%;
  position: absolute;
  top: 22.08%;
  width: 13.83%;
}
.student-quiz-detail-mobile .vector-5ZFw7U {
  background-color: transparent;
  height: 29.85%;
  left: 2.08%;
  position: absolute;
  top: 8.12%;
  width: 45.75%;
}
.student-quiz-detail-mobile .vector-5ZxPCv {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}
.student-quiz-detail-mobile .vector-6Pj9gj {
  background-color: transparent;
  height: 5.97%;
  left: 50.75%;
  position: absolute;
  top: 63.23%;
  width: 7.45%;
}
.student-quiz-detail-mobile .vector-6dsxAM {
  background-color: transparent;
  height: 7.46%;
  left: 67.81%;
  position: absolute;
  top: 51.44%;
  width: 7.45%;
}
.student-quiz-detail-mobile .vector-6lEMsv {
  background-color: transparent;
  height: 34.33%;
  left: 2.6%;
  position: absolute;
  top: 22.69%;
  width: 58.52%;
}
.student-quiz-detail-mobile .vector-6u2TAk {
  background-color: transparent;
  height: 41.8%;
  left: 4.2%;
  position: absolute;
  top: 13.83%;
  width: 55.33%;
}
.student-quiz-detail-mobile .vector-9neAS5 {
  background-color: transparent;
  height: 14.93%;
  left: 17.28%;
  position: absolute;
  top: 40.61%;
  width: 11.7%;
}
.student-quiz-detail-mobile .vector-ADvjvZ {
  background-color: transparent;
  height: 29.85%;
  left: 37.87%;
  position: absolute;
  top: 2.72%;
  width: 34.05%;
}
.student-quiz-detail-mobile .vector-AEuo4j {
  background-color: transparent;
  height: 4.48%;
  left: 12.21%;
  position: absolute;
  top: 12.28%;
  width: 28.73%;
}
.student-quiz-detail-mobile .vector-AKNQwM {
  background-color: transparent;
  height: 11.94%;
  left: 24.31%;
  position: absolute;
  top: 18.13%;
  width: 14.9%;
}
.student-quiz-detail-mobile .vector-AUE2eU {
  background-color: transparent;
  height: 101.25%;
  left: -4.96%;
  position: absolute;
  top: 0.47%;
  width: 109.92%;
}
.student-quiz-detail-mobile .vector-B3X4ab {
  background-color: transparent;
  height: 29.85%;
  left: 2.08%;
  position: absolute;
  top: 8.12%;
  width: 45.75%;
}
.student-quiz-detail-mobile .vector-CKdTAP {
  background-color: transparent;
  height: 144.31%;
  left: -19.17%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}
.student-quiz-detail-mobile .vector-DhmCtC {
  background-color: transparent;
  height: 13.43%;
  left: 63.32%;
  position: absolute;
  top: 41.24%;
  width: 25.53%;
}
.student-quiz-detail-mobile .vector-FBXFcM {
  background-color: transparent;
  height: 34.33%;
  left: 2.6%;
  position: absolute;
  top: 22.69%;
  width: 58.52%;
}
.student-quiz-detail-mobile .vector-FRe9kA {
  background-color: transparent;
  height: 121.98%;
  left: -7.1%;
  position: absolute;
  top: -10.99%;
  width: 114.19%;
}
.student-quiz-detail-mobile .vector-FbIeqH {
  background-color: transparent;
  height: 8.96%;
  left: 25.41%;
  position: absolute;
  top: 36.57%;
  width: 6.38%;
}
.student-quiz-detail-mobile .vector-GULZOy {
  background-color: transparent;
  height: 121.96%;
  left: -8.82%;
  position: absolute;
  top: -10.98%;
  width: 117.65%;
}
.student-quiz-detail-mobile .vector-HDxFmN {
  background-color: transparent;
  height: 8.96%;
  left: 25.3%;
  position: absolute;
  top: 37.19%;
  width: 12.77%;
}
.student-quiz-detail-mobile .vector-IfQt5H {
  background-color: transparent;
  height: 49.26%;
  left: 29.5%;
  position: absolute;
  top: 51.43%;
  width: 48.94%;
}
.student-quiz-detail-mobile .vector-IpBwGI {
  background-color: transparent;
  height: 32.84%;
  left: 2.49%;
  position: absolute;
  top: 24.54%;
  width: 21.28%;
}
.student-quiz-detail-mobile .vector-LS3dNl {
  background-color: transparent;
  height: 8.96%;
  left: 25.41%;
  position: absolute;
  top: 36.57%;
  width: 6.38%;
}
.student-quiz-detail-mobile .vector-LpgxzN {
  background-color: transparent;
  height: 19.4%;
  left: 24.76%;
  position: absolute;
  top: 22.08%;
  width: 13.83%;
}
.student-quiz-detail-mobile .vector-N2Y59c {
  background-color: transparent;
  height: 82.1%;
  left: 7.01%;
  position: absolute;
  top: 2.36%;
  width: 93.63%;
}
.student-quiz-detail-mobile .vector-NF9mxS {
  background-color: transparent;
  height: 5.97%;
  left: 23.91%;
  position: absolute;
  top: 28.23%;
  width: 2.13%;
}
.student-quiz-detail-mobile .vector-O5Cxw9 {
  background-color: transparent;
  height: 13.43%;
  left: 63.32%;
  position: absolute;
  top: 41.24%;
  width: 25.53%;
}
.student-quiz-detail-mobile .vector-OWyIvf {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}
.student-quiz-detail-mobile .vector-Ob1Rqs {
  background-color: transparent;
  height: 52.24%;
  left: 49.49%;
  position: absolute;
  top: 8.1%;
  width: 45.75%;
}
.student-quiz-detail-mobile .vector-P1lxK3 {
  background-color: transparent;
  height: 5.97%;
  left: 23.91%;
  position: absolute;
  top: 28.23%;
  width: 2.13%;
}
.student-quiz-detail-mobile .vector-PC91KU {
  background-color: transparent;
  height: 144.3%;
  left: -6.65%;
  position: absolute;
  top: -22.15%;
  width: 113.29%;
}
.student-quiz-detail-mobile .vector-PD0S0h {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.36%;
}
.student-quiz-detail-mobile .vector-PFKICe {
  background-color: transparent;
  height: 11.94%;
  left: 23.98%;
  position: absolute;
  top: 18.13%;
  width: 8.51%;
}
.student-quiz-detail-mobile .vector-PtriGM {
  background-color: transparent;
  height: 11.94%;
  left: 23.77%;
  position: absolute;
  top: 18.13%;
  width: 15.96%;
}
.student-quiz-detail-mobile .vector-QDyA6g {
  background-color: transparent;
  height: 110.03%;
  left: -6.59%;
  position: absolute;
  top: -5.02%;
  width: 113.19%;
}
.student-quiz-detail-mobile .vector-Qv0mZ4 {
  background-color: transparent;
  height: 29.85%;
  left: 2.08%;
  position: absolute;
  top: 8.12%;
  width: 45.75%;
}
.student-quiz-detail-mobile .vector-Qv8Xbp {
  background-color: transparent;
  height: 144.31%;
  left: -19.17%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}
.student-quiz-detail-mobile .vector-RYpCgT {
  background-color: transparent;
  height: 121.98%;
  left: -6.78%;
  position: absolute;
  top: -10.99%;
  width: 113.56%;
}
.student-quiz-detail-mobile .vector-S9AR9M {
  background-color: transparent;
  height: 70.16%;
  left: -0.55%;
  position: absolute;
  top: 8.33%;
  width: 95.76%;
}
.student-quiz-detail-mobile .vector-T0btkN {
  background-color: transparent;
  height: 11.94%;
  left: 23.98%;
  position: absolute;
  top: 18.13%;
  width: 8.51%;
}
.student-quiz-detail-mobile .vector-T7EbAp {
  background-color: transparent;
  height: 13.43%;
  left: 63.28%;
  position: absolute;
  top: 41.24%;
  width: 19.15%;
}
.student-quiz-detail-mobile .vector-TdE1pD {
  background-color: transparent;
  height: 49.26%;
  left: 28.97%;
  position: absolute;
  top: 51.43%;
  width: 50.01%;
}
.student-quiz-detail-mobile .vector-U0LxYw {
  background-color: transparent;
  height: 4.05%;
  left: 71.01%;
  position: absolute;
  top: -1.45%;
  width: 13.74%;
}
.student-quiz-detail-mobile .vector-UUUxJF {
  background-color: transparent;
  height: 121.96%;
  left: -8.82%;
  position: absolute;
  top: -10.98%;
  width: 117.65%;
}

.student-quiz-detail-mobile .rectangle-44-rFX9Nh .doubt-row {
  display: flex;
  justify-content: space-evenly;
  margin: 4px;
  align-items: center;
}

.student-quiz-detail-mobile .vector-VOGBVy {
  background-color: transparent;
  height: 41.8%;
  left: 3.8%;
  position: absolute;
  top: 13.83%;
  width: 20.22%;
}
.student-quiz-detail-mobile .vector-VRMQru {
  background-color: transparent;
  height: 82.1%;
  left: 7.01%;
  position: absolute;
  top: 2.36%;
  width: 93.63%;
}
.student-quiz-detail-mobile .vector-Vr5tiq {
  background-color: transparent;
  height: 29.85%;
  left: 37.64%;
  position: absolute;
  top: 2.71%;
  width: 22.34%;
}
.student-quiz-detail-mobile .vector-WCljiC {
  background-color: transparent;
  height: 19.4%;
  left: 24.76%;
  position: absolute;
  top: 22.08%;
  width: 13.83%;
}
.student-quiz-detail-mobile .vector-WfnLBo {
  background-color: transparent;
  height: 82.1%;
  left: 7.01%;
  position: absolute;
  top: 2.36%;
  width: 93.63%;
}
.student-quiz-detail-mobile .vector-WqgE0F {
  background-color: transparent;
  height: 19.4%;
  left: 29.04%;
  position: absolute;
  top: 81.41%;
  width: 29.79%;
}
.student-quiz-detail-mobile .vector-Y59V0s {
  background-color: transparent;
  height: 4.05%;
  left: 71.01%;
  position: absolute;
  top: -1.45%;
  width: 13.74%;
}
.student-quiz-detail-mobile .vector-Y9TIUK {
  background-color: transparent;
  height: 121.99%;
  left: -21.65%;
  position: absolute;
  top: -10.99%;
  width: 143.3%;
}
.student-quiz-detail-mobile .vector-YM4yxX {
  background-color: transparent;
  height: 5.97%;
  left: 36.78%;
  position: absolute;
  top: 28.23%;
  width: 3.19%;
}
.student-quiz-detail-mobile .vector-Zh51an {
  background-color: transparent;
  height: 144.3%;
  left: -6.65%;
  position: absolute;
  top: -22.15%;
  width: 113.29%;
}
.student-quiz-detail-mobile .vector-aXV5cr {
  background-color: transparent;
  height: 5.97%;
  left: 23.91%;
  position: absolute;
  top: 28.23%;
  width: 2.13%;
}
.student-quiz-detail-mobile .vector-bKRlnb {
  background-color: transparent;
  height: 110.03%;
  left: -6.59%;
  position: absolute;
  top: -5.02%;
  width: 113.19%;
}
.student-quiz-detail-mobile .vector-bnkfUA {
  background-color: transparent;
  height: 4.48%;
  left: 12.23%;
  position: absolute;
  top: 12.28%;
  width: 8.51%;
}
.student-quiz-detail-mobile .vector-bof6oJ {
  background-color: transparent;
  height: 29.85%;
  left: 1.84%;
  position: absolute;
  top: 8.24%;
  width: 19.15%;
}
.student-quiz-detail-mobile .vector-c0ymqA {
  background-color: transparent;
  height: 41.8%;
  left: 3.8%;
  position: absolute;
  top: 13.83%;
  width: 20.22%;
}
.student-quiz-detail-mobile .vector-c9XjZ6 {
  background-color: transparent;
  height: 13.43%;
  left: 63.32%;
  position: absolute;
  top: 41.24%;
  width: 25.53%;
}
.student-quiz-detail-mobile .vector-cJDtwl {
  background-color: transparent;
  height: 5.97%;
  left: 50.75%;
  position: absolute;
  top: 63.23%;
  width: 7.45%;
}
.student-quiz-detail-mobile .vector-cNJjv2 {
  background-color: transparent;
  height: 32.84%;
  left: 2.49%;
  position: absolute;
  top: 24.54%;
  width: 21.28%;
}
.student-quiz-detail-mobile .vector-d2duO5 {
  background-color: transparent;
  height: 4.05%;
  left: 71.01%;
  position: absolute;
  top: -1.45%;
  width: 13.74%;
}
.student-quiz-detail-mobile .vector-dG0Ldx {
  background-color: transparent;
  height: 5.97%;
  left: 50.75%;
  position: absolute;
  top: 63.23%;
  width: 7.45%;
}
.student-quiz-detail-mobile .vector-dS2uCE {
  background-color: transparent;
  height: 8.96%;
  left: 25.3%;
  position: absolute;
  top: 37.19%;
  width: 12.77%;
}
.student-quiz-detail-mobile .vector-dS4UXQ {
  background-color: transparent;
  height: 49.26%;
  left: 29.5%;
  position: absolute;
  top: 51.43%;
  width: 48.94%;
}
.student-quiz-detail-mobile .vector-dV81ro {
  background-color: transparent;
  height: 70.16%;
  left: -0.67%;
  position: absolute;
  top: 8.45%;
  width: 24.47%;
}
.student-quiz-detail-mobile .vector-dcaDkc {
  background-color: transparent;
  height: 14.93%;
  left: 17.32%;
  position: absolute;
  top: 40.61%;
  width: 28.73%;
}
.student-quiz-detail-mobile .vector-e2yeN1 {
  background-color: transparent;
  height: 29.85%;
  left: 37.87%;
  position: absolute;
  top: 2.72%;
  width: 34.05%;
}
.student-quiz-detail-mobile .vector-gvV2Rx {
  background-color: transparent;
  height: 70.16%;
  left: -0.67%;
  position: absolute;
  top: 8.45%;
  width: 24.47%;
}
.student-quiz-detail-mobile .vector-halcXV {
  background-color: transparent;
  height: 144.31%;
  left: -19.17%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}
.student-quiz-detail-mobile .vector-hhAbtl {
  background-color: transparent;
  height: 14.93%;
  left: 17.28%;
  position: absolute;
  top: 40.61%;
  width: 11.7%;
}
.student-quiz-detail-mobile .vector-i64Q95 {
  background-color: transparent;
  height: 19.4%;
  left: 29.04%;
  position: absolute;
  top: 81.41%;
  width: 29.79%;
}
.student-quiz-detail-mobile .vector-jLf4aV {
  background-color: transparent;
  height: 7.46%;
  left: 67.81%;
  position: absolute;
  top: 51.44%;
  width: 7.45%;
}
.student-quiz-detail-mobile .vector-k7b1Mi {
  background-color: transparent;
  height: 70.16%;
  left: -0.55%;
  position: absolute;
  top: 8.33%;
  width: 95.76%;
}
.student-quiz-detail-mobile .vector-kwqROy {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.35%;
}
.student-quiz-detail-mobile .vector-lFxDi9 {
  background-color: transparent;
  height: 19.4%;
  left: 29.04%;
  position: absolute;
  top: 81.41%;
  width: 29.79%;
}
.student-quiz-detail-mobile .vector-lj5Xre {
  background-color: transparent;
  height: 121.96%;
  left: -8.82%;
  position: absolute;
  top: -10.98%;
  width: 117.65%;
}
.student-quiz-detail-mobile .vector-lkVzGd {
  background-color: transparent;
  height: 4.48%;
  left: 12.23%;
  position: absolute;
  top: 12.28%;
  width: 8.51%;
}
.student-quiz-detail-mobile .vector-lw8NAW {
  background-color: transparent;
  height: 29.85%;
  left: 1.84%;
  position: absolute;
  top: 8.24%;
  width: 19.15%;
}
.student-quiz-detail-mobile .vector-mGXgmO {
  background-color: transparent;
  height: 4.48%;
  left: 12.23%;
  position: absolute;
  top: 12.28%;
  width: 8.51%;
}
.student-quiz-detail-mobile .vector-mokeKx {
  background-color: transparent;
  height: 14.93%;
  left: 17.32%;
  position: absolute;
  top: 40.61%;
  width: 28.73%;
}
.student-quiz-detail-mobile .vector-ms1pvS {
  background-color: transparent;
  height: 41.8%;
  left: 4.2%;
  position: absolute;
  top: 13.83%;
  width: 55.33%;
}
.student-quiz-detail-mobile .vector-nD2hpN {
  background-color: transparent;
  height: 101.5%;
  left: 11.25%;
  position: absolute;
  top: -1.01%;
  width: 85.12%;
}
.student-quiz-detail-mobile .vector-ngma9X {
  background-color: transparent;
  height: 101.25%;
  left: -4.96%;
  position: absolute;
  top: 0.47%;
  width: 109.92%;
}
.student-quiz-detail-mobile .vector-nzNou6 {
  background-color: transparent;
  height: 101.5%;
  left: 11.25%;
  position: absolute;
  top: -1.01%;
  width: 85.12%;
}
.student-quiz-detail-mobile .vector-nzuIpS {
  background-color: transparent;
  height: 5.97%;
  left: 36.78%;
  position: absolute;
  top: 28.23%;
  width: 3.19%;
}
.student-quiz-detail-mobile .vector-oafYiW {
  background-color: transparent;
  height: 121.98%;
  left: -6.78%;
  position: absolute;
  top: -10.99%;
  width: 113.56%;
}
.student-quiz-detail-mobile .vector-oez9Pb {
  background-color: transparent;
  height: 8.96%;
  left: 25.3%;
  position: absolute;
  top: 37.19%;
  width: 12.77%;
}
.student-quiz-detail-mobile .vector-pxIogv {
  background-color: transparent;
  height: 121.98%;
  left: -6.78%;
  position: absolute;
  top: -10.99%;
  width: 113.56%;
}
.student-quiz-detail-mobile .vector-qGye3v {
  background-color: transparent;
  height: 70.16%;
  left: -0.55%;
  position: absolute;
  top: 8.33%;
  width: 95.76%;
}
.student-quiz-detail-mobile .vector-qpftC5 {
  background-color: transparent;
  height: 14.93%;
  left: 17.28%;
  position: absolute;
  top: 40.61%;
  width: 11.7%;
}
.student-quiz-detail-mobile .vector-rGRikn {
  background-color: transparent;
  height: 101.25%;
  left: -4.96%;
  position: absolute;
  top: 0.47%;
  width: 109.92%;
}
.student-quiz-detail-mobile .vector-rXxPBk {
  background-color: transparent;
  height: 11.94%;
  left: 24.31%;
  position: absolute;
  top: 18.13%;
  width: 14.9%;
}
.student-quiz-detail-mobile .vector-reGMLO {
  background-color: transparent;
  height: 120.65%;
  left: -21.65%;
  position: absolute;
  top: -10.33%;
  width: 143.31%;
}
.student-quiz-detail-mobile .vector-sku7Fc {
  background-color: transparent;
  height: 13.43%;
  left: 63.81%;
  position: absolute;
  top: 41.24%;
  width: 18.09%;
}
.student-quiz-detail-mobile .vector-tQwNUN {
  background-color: transparent;
  height: 14.93%;
  left: 17.32%;
  position: absolute;
  top: 40.61%;
  width: 28.73%;
}
.student-quiz-detail-mobile .vector-toBf7r {
  background-color: transparent;
  height: 41.8%;
  left: 4.2%;
  position: absolute;
  top: 13.83%;
  width: 55.33%;
}
.student-quiz-detail-mobile .vector-tprfVp {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.36%;
}
.student-quiz-detail-mobile .vector-utKR0x {
  background-color: transparent;
  height: 144.31%;
  left: -19.18%;
  position: absolute;
  top: -22.16%;
  width: 138.36%;
}
.student-quiz-detail-mobile .vector-vPuJH9 {
  background-color: transparent;
  height: 32.84%;
  left: 2.49%;
  position: absolute;
  top: 24.54%;
  width: 21.28%;
}
.student-quiz-detail-mobile .vector-x2R7zB {
  background-color: transparent;
  height: 70.16%;
  left: -0.67%;
  position: absolute;
  top: 8.45%;
  width: 24.47%;
}
.student-quiz-detail-mobile .vector-x5Jnp7 {
  background-color: transparent;
  height: 121.99%;
  left: -21.65%;
  position: absolute;
  top: -10.99%;
  width: 143.3%;
}
.student-quiz-detail-mobile .vector-xB1UPi {
  background-color: transparent;
  height: 52.24%;
  left: 49.49%;
  position: absolute;
  top: 8.1%;
  width: 45.75%;
}
.student-quiz-detail-mobile .vector-xJcTLo {
  background-color: transparent;
  height: 121.98%;
  left: -7.1%;
  position: absolute;
  top: -10.99%;
  width: 114.19%;
}
.student-quiz-detail-mobile .vector-xMrRfb {
  background-color: transparent;
  height: 4.48%;
  left: 12.21%;
  position: absolute;
  top: 12.28%;
  width: 28.73%;
}
.student-quiz-detail-mobile .vector-xhepvl {
  background-color: transparent;
  height: 5.97%;
  left: 36.78%;
  position: absolute;
  top: 28.23%;
  width: 3.19%;
}
.student-quiz-detail-mobile .vector-xhwx9b {
  background-color: transparent;
  height: 7.46%;
  left: 67.81%;
  position: absolute;
  top: 51.44%;
  width: 7.45%;
}
.student-quiz-detail-mobile .vector-xtOtSY {
  background-color: transparent;
  height: 41.8%;
  left: 3.8%;
  position: absolute;
  top: 13.83%;
  width: 20.22%;
}
.student-quiz-detail-mobile .vector-xvjzYd {
  background-color: transparent;
  height: 4.48%;
  left: 12.21%;
  position: absolute;
  top: 12.28%;
  width: 28.73%;
}
.student-quiz-detail-mobile .vector-ymbxcA {
  background-color: transparent;
  height: 29.85%;
  left: 37.64%;
  position: absolute;
  top: 2.71%;
  width: 22.34%;
}
.student-quiz-detail-mobile .vector-zXuwwt {
  background-color: transparent;
  height: 34.33%;
  left: 2.6%;
  position: absolute;
  top: 22.69%;
  width: 58.52%;
}
.student-quiz-detail-mobile .vector-zeZVsH {
  background-color: transparent;
  height: 8.96%;
  left: 24.88%;
  position: absolute;
  top: 36.57%;
  width: 7.45%;
}
.student-quiz-detail-mobile .vector-zxfIi7 {
  background-color: transparent;
  height: 29.85%;
  left: 1.84%;
  position: absolute;
  top: 8.24%;
  width: 19.15%;
}
.student-quiz-detail-mobile .view-all-rFX9Nh {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 303px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: 77px;
}
.student-quiz-detail-mobile .view-forum-CN8bZ4 {
  background-color: transparent;
  height: auto;
  left: 257px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 235px;
  width: 115px;
}
.student-quiz-detail-mobile .view-forum-CNpLuU {
  background-color: transparent;
  height: auto;
  left: 257px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 169px;
  width: 115px;
}
.student-quiz-detail-mobile .view-forum-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 257px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 103px;
  width: 115px;
}
.student-quiz-detail-mobile .view-quiz-kiZmGh {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  right: 14px;
  text-align: center;
  top: 9px;
  width: 71px;
}
.student-quiz-detail-mobile .what-is-the-si-unit-of-current-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 70px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 74px;
  width: 296px;
}
.student-quiz-detail-mobile .what-is-the-sol-f-this-question-rFX9Nh {
  background-color: transparent;
  height: auto;
  left: 70px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 140px;
  width: 296px;
}
.student-quiz-detail-mobile .x-add-work-EGQFV0 {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  right: 165px;
  text-align: left;
  top: 252px;
  width: 62px;
}
.student-quiz-detail-mobile .x10-marks-EGQFV0-0001 {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 54px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 69px;
  width: 89px;
}
.student-quiz-detail-mobile .your-work-EGQFV0 {
  background-color: transparent;
  height: auto;
  left: 136px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 205px;
  width: 98px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .student-quiz-detail {
    display: none;
  }

  .your-marks-na-AvtzuR {
    background-color: transparent;
    color: transparent;
    font-family: "Montserrat", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    height: auto;
    left: 235px;
    letter-spacing: 0px;
    line-height: normal;
    position: absolute;
    top: 69px;
    width: auto;
  }

  .span0-F71xPZ {
    color: rgba(126, 126, 126, 1);
    font-style: normal;
  }
  .span2-F71xPZ {
    color: rgba(19, 37, 71, 1);
    font-style: normal;
    font-weight: 600;
  }
}
@media screen and (min-width: 600px) {
  .student-quiz-detail-mobile {
    display: none;
  }
}
