/* screen - student-doubts */

.border-1px-black-2 {
  border: 1px solid var(--black-2);
}

.montserrat-medium-black-18px {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gray-7px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-white-10px {
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-niagara-8px {
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}

.student-doubts {
  background-color: rgba(253, 254, 255, 1);
  height: 100vh;
  width: 100%;
}

.student-doubts .student-doubts-col {
  position: relative;
  top: 0;
  left: 63px;
  height: 100%;
  width: calc(100% - 63px);
}

.student-doubts .doubt-row-1 {
  position: absolute;
  left: 8%;
  top: 195px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
}

.student-doubts .doubt-row-2 {
  position: absolute;
  top: 300px;
  left: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
}

.student-doubts .doubt-row-3 {
  position: absolute;
  top: 425px;
  left: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
}

.student-doubts .doubt-status {
  width: 24%;
  display: flex;
}

.student-doubts .active-B6ZBuj {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts .analytics-24px-3-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 644px;
  width: 24px;
}

.student-doubts .assignment-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 348px;
  width: 24px;
}

.student-doubts .biba-sobti-B6ZBuj {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts .biba-sobti-VdQhAS {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 92px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}

.student-doubts .biba-sobti-pqE4mo {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 22px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 133px;
  width: auto;
}

.student-doubts .bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 47px;
  width: auto;
}

.student-doubts .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;
  left: 4.1%;
  position: absolute;
  top: 45px;
  width: 212px;
}

.student-doubts .component-4-B6ZBuj {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 92px;
  width: 100%;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.student-doubts .component-4-B6ZBuj .doubt-name-status {
  display: flex;
  justify-content: space-between;
}

.student-doubts .component-4-B6ZBuj .doubt-text-expand {
  display: flex;
  justify-content: space-between;
}

.student-doubts .component-4-pqE4mo {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 92px;
  left: 22px;
  position: absolute;
  top: 16px;
  width: 1097px;
}

.student-doubts .doubt-card {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 256px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 10px 24px;
  justify-content: space-evenly;
}

.student-doubts .doubt-card .doubt-post-button {
  width: 120px;
  align-self: flex-end;
}

.student-doubts .component-5-B6ZBuj {
  background-color: transparent;
  height: 276px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 1151px;
}

.student-doubts .component-5-pqE4mo {
  background-color: transparent;
  height: 276px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 1151px;
}

.student-doubts .create-doubt-j57GY0 {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;

  width: auto;
}

.student-doubts .description-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 496px;
  width: 24px;
}

.student-doubts .doubt-text-C61RwL {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
  display: flex;
  align-items: center;
}

.student-doubts .ellipse-2-OSIBbx {
  background-color: transparent;
  height: 19.05%;
  left: 57.14%;
  position: absolute;
  top: 21.43%;
  width: 19.05%;
}

.student-doubts .expand-B6ZBuj {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts .expand-pqE4mo {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 1047px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 78px;
  width: auto;
}

.student-doubts .filter-VAhwTv {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts .grade-C61RwL-10 {
  background-color: transparent;
  height: auto;
  left: 182px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 412px;
  width: auto;
}

.student-doubts .grade-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 182px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 286px;
  width: auto;
}

.student-doubts .group-0M78Jh {
  background-color: transparent;
  /* background-image: url(./img/vector-33@2x.png); */
  background-size: 100%;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-doubts .group-160-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  position: absolute;
  top: 718px;
  width: 24px;
}

.student-doubts .group-161-C61RwL {
  background-color: transparent;
  height: auto;
  width: 14%;
  text-align: center;
  padding: 8px;
}

.student-doubts .group-22-C61RwL {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}

.student-doubts .group-220-C61RwL-10x {
  background-color: transparent;
  left: 8%;
  position: absolute;
  top: 530px;
  width: 82%;
  height: 600px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.bhsks-snfasa-sd2-02 {
  position: absolute;
  top: 500px;
  left: 8%;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.student-doubts .group-221-C61RwL {
  background-color: transparent;
  height: 266px;
  left: 177px;
  position: absolute;
  top: 746px;
  width: 1151px;
}

.student-doubts .group-50-B6ZBuj {
  background-color: transparent;
  border: 0;
  height: 44px;
  padding-left: 10px;
  resize: none;
  width: 1099px;
}

.student-doubts .group-50-pqE4mo {
  background-color: transparent;
  border: 0;
  height: 44px;
  left: 22px;
  padding: 0;
  position: absolute;
  resize: none;
  top: 164px;
  width: 1099px;
}

.student-doubts .group-53-C61RwL-10 {
  background-color: transparent;
  height: 36px;
  width: 14%;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.student-doubts .group-55-B6ZBuj {
  background-color: transparent;
  height: 12.41%;
  left: 88.79%;
  position: absolute;
  top: 83.46%;
  width: 9.04%;
}

.student-doubts .group-56-C61RwL {
  background-color: transparent;
  height: 3.22%;
  left: 83.06%;
  position: absolute;
  top: 94.53%;
  width: 7.22%;
}

.student-doubts .group-5jt8cF {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-doubts .group-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 22px;
  position: absolute;
  top: 422px;
  width: 20px;
}

.student-doubts .group-NOXmfT {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-doubts .group-ce7Ifp {
  background-color: transparent;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 20px;
}

.student-doubts .help-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 570px;
  width: 24px;
}

.student-doubts .is-height-and-d-of-trignometry-pqE4mo {
  background-color: transparent;
  height: auto;
  left: 35px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 67px;
  width: auto;
}

.student-doubts .kunal-kashyap-pqE4mo {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 29px;
  width: auto;
}

.student-doubts .line-5-9p2GBv {
  background-color: transparent;
  height: 99.05%;
  left: 0.9%;
  position: absolute;
  top: 0.48%;
  width: 99.29%;
}

.student-doubts .line-6-9p2GBv {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}

.student-doubts .notifications-24px-2-1-C61RwL {
  background-color: transparent;
  height: 42px;
  left: 976px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 42px;
}

.student-doubts .post-xLrP3a {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 60.61%;
  left: 29.81%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21.21%;
  width: 37.5%;
}

.student-doubts .post-zA4C22 {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 60.61%;
  left: 29.81%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21.21%;
  width: 37.5%;
}

.student-doubts .rectangle-24-C61RwL-stu-s1 {
  background-color: #1f2531;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 63px;

  overflow-y: scroll;
  padding: 150px 0px 20px 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.student-doubts .rectangle-40-VAhwTv {
  background-color: transparent;
  height: 40px;
  left: -4px;
  position: absolute;
  top: 0px;
  width: 182px;
}

.student-doubts .rectangle-40-j57GY0 {
  background-color: transparent;
  height: 40px;
  left: -4px;
  position: absolute;
  top: 0px;
  width: 182px;
}

.student-doubts .upload-in-lec-notes {
  width: 12%;
  border: 1px solid rgb(228, 228, 228);
  box-shadow: 0px 1px 1px rgb(224, 224, 224);
  border-radius: 5px;
  height: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dc3545;
}

.student-doubts .rectangle-41-xLrP3a {
  background-color: var(--blue-zodiac);
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 98.08%;
}

.student-doubts .rectangle-41-zA4C22 {
  background-color: var(--blue-zodiac);
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 98.08%;
}

.student-doubts .rectangle-67-Fv0v2y {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 1097px;
}

.student-doubts .rectangle-67-gT7xxj {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 1097px;
}

.rectangle-68-C61RwL-10 {
  background-color: var(--white);
  border-radius: 5px;
  border: 2px solid rgba(217, 83, 79, 0.4);
  font-family: "Montserrat", Helvetica;
  height: 24px;
  left: 265px;
  position: absolute;
  top: 412px;
  width: 151px;
}

.student-doubts .rectangle-69-C61RwL-10 {
  background-color: var(--white);
  border-radius: 5px;
  border: 2px solid rgba(217, 83, 79, 0.4);
  font-family: "Montserrat", Helvetica;
  height: 24px;
  left: 565px;
  position: absolute;
  top: 408px;
  width: 175px;
}

.student-doubts .rectangle-7-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 630px;
  position: absolute;
  top: 631px;
  width: 1px;
}

.student-doubts .rectangle-70-C61RwL-10 {
  background-color: var(--white);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 32px;
  width: 70%;
}

.student-doubts .rectangle-71-C61RwL {
  background-color: var(--white);
  border: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  height: 68px;
  padding: 8px;
  resize: none;
  width: 70%;
}

.student-doubts .rectangle-72-C61RwL {
  /* background-color: var(--white); */
  border-radius: 5px;
  border: 2px solid rgba(217, 83, 79, 0.4);
  font-family: "Montserrat", Helvetica;
  position: absolute;
  height: 24px;
  left: 265px;

  top: 286px;
  width: 151px;
}

.student-doubts .rectangle-73-C61RwL {
  background-color: var(--white);
  border-radius: 5px;
  border: 2px solid rgba(217, 83, 79, 0.4);
  font-family: "Montserrat", Helvetica;
  height: 24px;
  left: 565px;

  position: absolute;
  top: 282px;
  width: 175px;
}

.student-doubts .rectangle-74-C61RwL {
  background-color: var(--white);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 24px;
  left: 900px;
  position: absolute;
  top: 286px;
  width: 175px;
}

.student-doubts .solve-doubts-C61RwL-10 {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 8%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 362px;
  width: 253px;
}

.student-doubts .solve-this-doubt-Fv0v2y {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 180px;
}

.student-doubts .solve-this-doubt-gT7xxj {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 180px;
}

.student-doubts .solved-pqE4mo {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 1058px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: auto;
}

.student-doubts .span0-z3pWM6 {
  color: rgb(220, 53, 69);
  font-style: normal;
}

.student-doubts .span1-z3pWM6 {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}

.student-doubts .status-C61RwL-10 {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  margin-right: 20px;
  width: auto;
}

.student-doubts .status-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 812px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 286px;
  width: auto;
}

.student-doubts .stickynote2-24px-1-Cp4Be6 {
  background-color: transparent;
  height: 24px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 24px;
}

.student-doubts .subject-C61RwL-10 {
  background-color: transparent;
  height: auto;
  left: 466px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 412px;
  width: auto;
}

.student-doubts .subject-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 466px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 286px;
  width: auto;
}

.student-doubts .supportagent-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  position: absolute;
  top: 792px;
  width: 24px;
}

.student-doubts .vatsal-agarwal-B6ZBuj {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts .vector-6XJ1dC {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}

.student-doubts .vector-7pUNkI {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts .vector-7txfVx {
  background-color: transparent;
  height: 58.33%;
  left: 20.83%;
  position: absolute;
  top: 20.83%;
  width: 58.33%;
}

.student-doubts .vector-C61RwL {
  background-color: transparent;
  height: 2.34%;
  left: 1.32%;
  position: absolute;
  top: 92.77%;
  width: 1.67%;
}

.student-doubts .vector-CLdsug {
  background-color: transparent;
  height: 82.14%;
  left: 16.67%;
  position: absolute;
  top: 9.97%;
  width: 66.67%;
}

.student-doubts .vector-G0yBDR {
  background-color: transparent;
  height: 83.33%;
  left: 16.67%;
  position: absolute;
  top: 8.33%;
  width: 66.67%;
}

.student-doubts .vector-OSIBbx {
  background-color: transparent;
  height: 1.19%;
  left: 49.4%;
  position: absolute;
  top: 49.4%;
  width: 1.19%;
}

.student-doubts .vector-OYLvDa {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}

.student-doubts .vector-QxuFcp {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-doubts .vector-UaM55c {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts .vector-VMr6Om {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 26.76%;
  width: 1.67%;
}

.student-doubts .vector-VdQhAS {
  background-color: transparent;
  height: 100%;
  left: 73.42%;
  position: absolute;
  top: 0px;
  width: 25.32%;
}

.student-doubts .vector-WbzDQU {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts .vector-dLw05Z {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts .vector-irJBpK {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts .vector-mzXdH9 {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 19.53%;
  width: 1.67%;
}

.student-doubts .vector-qy1MCO {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-doubts .vector-tQ6NQH {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}

.student-doubts .vector-uhbuVM {
  background-color: transparent;
  height: 36.11%;
  left: 20.01%;
  position: absolute;
  top: 16.53%;
  width: 60%;
}

.student-doubts .vector-xy9YKY {
  background-color: transparent;
  height: 11.11%;
  left: 30%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-doubts .vector-ydL8U7 {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts .vector-ye3NYT {
  background-color: transparent;
  height: 11.11%;
  left: 60%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-doubts .vector-ywnyBE {
  background-color: transparent;
  height: 83.33%;
  left: 8.33%;
  position: absolute;
  top: 8.33%;
  width: 83.33%;
}

.student-doubts .what-is-euclid-lemma-B6ZBuj {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --black-2: rgba(0, 0, 0, 0.1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --nobel: rgba(180, 180, 180, 1);
  --white: rgba(255, 255, 255, 1);
}

/* screen - student-doubts-list-mobile */

.border-1px-black-2 {
  border: 1px solid var(--black-2);
}

.montserrat-medium-black-18px {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gray-7px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-white-10px {
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-niagara-8px {
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}

.student-doubts-list-mobile {
  background-color: var(--coconut);
  min-height: 100vh;
  margin: 0px;
  min-height: 1200px;
  min-width: 414px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.student-doubts-list-mobile .active-C61RwL-10 {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 324px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 604px;
  width: 55px;
}

.student-doubts-list-mobile .apply-filters-vgf5o4 {
  background-color: transparent;
  height: 36.36%;
  left: 15.05%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21.79%;
  width: 80.65%;
}

.student-doubts-list-mobile .assignment-1-aCi14x {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}

.student-doubts-list-mobile .assignment-1-gqbUX1 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}

.student-doubts-list-mobile .assignment-1-ql7mN0 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}

.student-doubts-list-mobile .assignment-1-sDLNVS {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}

.student-doubts-list-mobile .assignment-24px-3-DVuPOa {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}

.student-doubts-list-mobile .assignment-24px-3-MxHjgv {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: absolute;
  top: 25%;
  width: 50%;
}

.student-doubts-list-mobile .assignment-24px-3-lxV7ix {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}

.student-doubts-list-mobile .assignment-24px-3-z4xZZj {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}

.student-doubts-list-mobile .biba-sobti-C61RwL-10 {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 39px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 714px;
  width: 112px;
}

.student-doubts-list-mobile .biba-sobti-VMr6Om {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 39px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1028px;
  width: 112px;
}

.student-doubts-list-mobile .choose-subject-i63353602371261-iR8muk {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 47.37%;
}

.student-doubts-list-mobile .choose-subject-i63353612371261-DRd40k {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 42.44%;
}

.student-doubts-list-mobile .choose-subject-i63353632371261-xLSyaa {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 43.27%;
}

.student-doubts-list-mobile .choose-subject-i63353692371261-MwxZhx {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 47.37%;
}

.student-doubts-list-mobile .choose-subject-i63353702371261-r3h1DQ {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 42.44%;
}

.student-doubts-list-mobile .choose-subject-i63353722371261-pI3smF {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 43.27%;
}

.student-doubts-list-mobile .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 45px;
  left: 26px;
  position: absolute;
  top: 24px;
  width: 87px;
}

.student-doubts-list-mobile .component-29-aCi14x {
  background-color: transparent;
  background-image: url(./img/ellipse-4-2@2x.png);
  background-size: 100% 100%;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}

.student-doubts-list-mobile .component-29-gqbUX1 {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}

.student-doubts-list-mobile .component-29-ql7mN0 {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}

.student-doubts-list-mobile .component-29-sDLNVS {
  background-color: transparent;
  background-image: url(./img/ellipse-4-3@2x.png);
  background-size: 100% 100%;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}

.student-doubts-list-mobile .component-30-B08mCn-001-1 {
  background-color: var(--white);
  border-radius: 10px;
  height: 33px;
  left: 0px;
  position: absolute;
  top: 0px;
  min-width: 171px;
}

.student-doubts-list-mobile .component-30-ZnSQrh {
  background-color: var(--white);
  border-radius: 10px;
  height: 33px;
  left: 0px;
  position: absolute;
  top: 0px;
  min-width: 171px;
}

.student-doubts-list-mobile .component-30-lvfzGr {
  background-color: var(--white);
  border-radius: 5px;
  height: 33px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 171px;
}

.student-doubts-list-mobile .component-30-yXv3Oh {
  background-color: var(--white);
  border-radius: 5px;
  height: 33px;
  color: gray;
  left: 0px;
  position: relative;
  top: 0px;
  width: 171px;
}

.student-doubts-list-mobile .component-31-B08mCn {
  background-color: var(--white);
  border-radius: 10px;
  height: 33px;
  left: 197px;
  position: absolute;
  top: 0px;
  width: 172px;
}

.student-doubts-list-mobile .component-31-ZnSQrh {
  background-color: var(--white);
  border-radius: 10px;
  height: 33px;
  left: 197px;
  position: absolute;
  top: 0px;
  width: 172px;
}

.student-doubts-list-mobile .component-32-C61RwL-10 {
  background-color: transparent;
  height: 276px;
  left: 18px;
  position: absolute;
  top: 569px;
  width: 379px;
}

.student-doubts-list-mobile .component-33-C61RwL-10 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 92px;
  left: 30px;
  position: absolute;
  top: 590px;
  width: 355px;
}

.student-doubts-list-mobile .component-34-C61RwL-10 {
  background-color: transparent;
  height: 276px;
  left: 18px;
  position: absolute;
  top: 883px;
  width: 379px;
}

.student-doubts-list-mobile .component-35-C61RwL-10 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 92px;
  left: 30px;
  position: absolute;
  top: 904px;
  width: 355px;
}

.student-doubts-list-mobile .component-6-C61RwL {
  background-color: transparent;
  height: 18px;
  left: 362px;
  position: absolute;
  top: 37px;
  width: 23px;
}

.student-doubts-list-mobile .create-doubt-cDarOQ {
  background-color: transparent;
  height: 36.36%;

  letter-spacing: 0px;
  line-height: normal;
}

.student-doubts-list-mobile .doubt-text-C61RwL {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 23px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: 75px;
}

.student-doubts-list-mobile .ellipse-4-MxHjgv {
  background-color: transparent;
  height: 105.46%;
  left: -1.18%;
  position: absolute;
  top: -2.73%;
  width: 102.36%;
}

.student-doubts-list-mobile .expand-C61RwL-10 {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 313px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 652px;
  width: 66px;
}

.student-doubts-list-mobile .expand-VMr6Om {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 313px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 966px;
  width: 66px;
}

.student-doubts-list-mobile .expandmore-24px-1-DRd40k {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-doubts-list-mobile .expandmore-24px-1-MwxZhx {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-doubts-list-mobile .expandmore-24px-1-iR8muk {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-doubts-list-mobile .expandmore-24px-1-pI3smF {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-doubts-list-mobile .expandmore-24px-1-r3h1DQ {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-doubts-list-mobile .expandmore-24px-1-xLSyaa {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-doubts-list-mobile .group-100-C61RwL-10 {
  background-color: transparent;
  height: 33px;
  left: 23px;
  position: absolute;
  top: 489px;
  width: 171px;
}

.student-doubts-list-mobile .group-100-VMr6Om-10 {
  background-color: transparent;
  padding: 7px;
  height: 32px;
  left: 55%;
  position: absolute;
  top: 502px;
  width: 140px;
  border-radius: 5px;
  text-align: center;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: white;
}

.student-doubts-list-mobile .group-101-C61RwL {
  background-color: transparent;
  border: 0;
  height: 44px;
  left: 30px;
  padding: 0;
  position: absolute;
  resize: none;
  top: 1052px;
  width: 357px;
}

.student-doubts-list-mobile .group-102-C61RwL {
  background-color: transparent;
  height: 1.84%;
  left: 74.64%;
  position: absolute;
  top: 93.25%;
  width: 18.24%;
}

.student-doubts-list-mobile .group-103-C61RwL-10 {
  background-color: transparent;
  height: 33px;
  left: 23px;
  position: absolute;
  top: 267px;
  width: 369px;
}

.student-doubts-list-mobile .group-104-C61RwL {
  background-color: transparent;
  height: 33px;
  left: 23px;
  position: absolute;
  top: 289px;
  width: 171px;
}

.student-doubts-list-mobile .group-105-C61RwL-10-up {
  background-color: transparent;
  padding: 7px;
  height: 32px;
  left: 240px;
  position: absolute;
  top: 322px;
  width: 140px;

  text-align: center;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: white;
}

.student-doubts-list-mobile .group-55-C61RwL-10 {
  background-color: transparent;
  height: 1.84%;
  left: 74.64%;
  position: absolute;
  top: 66.9%;
  width: 18.24%;
}

.student-doubts-list-mobile .group-57-C61RwL-10 {
  background-color: transparent;
  border: 0;
  height: 44px;
  left: 30px;
  padding: 0;
  position: absolute;
  resize: none;
  top: 738px;
  width: 357px;
}

.student-doubts-list-mobile .group-72-C61RwL {
  background-color: transparent;
  height: 35px;
  left: -203px;
  position: absolute;
  top: 961px;
  width: 179px;
}

.student-doubts-list-mobile .group-73-C61RwL {
  background-color: transparent;
  height: 35px;
  left: -203px;
  position: absolute;
  top: 1003px;
  width: 179px;
}

.student-doubts-list-mobile .group-74-C61RwL {
  background-color: transparent;
  height: 35px;
  left: -203px;
  position: absolute;
  top: 1045px;
  width: 179px;
}

.student-doubts-list-mobile .group-75-C61RwL {
  background-color: transparent;
  height: 35px;
  left: -204px;
  position: absolute;
  top: 1088px;
  width: 179px;
}

.student-doubts-list-mobile .group-94-a77sPZ {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 102.2%;

  text-align: center;
  padding: 1px;
}

.student-doubts-list-mobile .group-94-xEvN2q-10-up {
  background-color: transparent;
  height: 100%;

  width: 102.2%;
}

.student-doubts-list-mobile .group-99-C61RwL-10 {
  background-color: transparent;
  /* height: 43px; */
  left: 23px;
  position: absolute;
  top: 430px;
  width: 95%;
}

.student-doubts-list-mobile .is-height-and-d-of-trigonometry-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 954px;
  width: 219px;
}

.student-doubts-list-mobile .kunal-kashyap-C61RwL {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 917px;
  width: 189px;
}

.student-doubts-list-mobile .line-5-9p2GBv {
  background-color: transparent;
  height: 101.11%;
  left: 0.55%;
  position: absolute;
  top: -0.56%;
  width: 100%;
}

.student-doubts-list-mobile .line-6-9p2GBv {
  background-color: transparent;
  height: 102.22%;
  left: -0.29%;
  position: absolute;
  top: -1.11%;
  width: 100.57%;
}

.student-doubts-list-mobile .mathematics-dr-yashna-sharma-aCi14x {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}

.student-doubts-list-mobile .mathematics-dr-yashna-sharma-gqbUX1 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}

.student-doubts-list-mobile .upload-in-lec-notes {
  width: 40%;
  height: 40px;
  margin: 4px;
  position: absolute;
  top: 210px;
  left: 110px;
  padding: 12px 0px;
  border: 1px solid rgb(228, 228, 228);
  box-shadow: 0px 1px 1px rgb(224, 224, 224);
  border-radius: 5px;

  text-align: center;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  color: #dc3545;
}

.student-doubts-list-mobile .mathematics-dr-yashna-sharma-ql7mN0 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}

.student-doubts-list-mobile .mathematics-dr-yashna-sharma-sDLNVS {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}

.student-doubts-list-mobile .post-4W6TZx {
  background-color: transparent;
  height: 38.03%;
  left: 34.44%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23.93%;
  width: 33.11%;
}

.student-doubts-list-mobile .post-eYqyUC {
  background-color: transparent;
  height: 38.03%;
  left: 34.44%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23.93%;
  width: 33.11%;
}

.student-doubts-list-mobile .rectangle-40-aCi14x {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}

.student-doubts-list-mobile .rectangle-40-gqbUX1 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}

.student-doubts-list-mobile .rectangle-40-ql7mN0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}

.student-doubts-list-mobile .rectangle-40-sDLNVS {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}

.student-doubts-list-mobile .rectangle-41-4W6TZx-10 {
  background-color: rgb(220, 53, 69);
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 97.35%;
}

.student-doubts-list-mobile .rectangle-41-cDarOQ {
  background-color: rgb(220, 53, 69);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 97.85%;
}

.student-doubts-list-mobile .rectangle-41-eYqyUC {
  background-color: rgb(220, 53, 69);
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 97.35%;
}

.student-doubts-list-mobile .rectangle-41-vgf5o4 {
  background-color: rgb(220, 53, 69);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 97.85%;
}

.student-doubts-list-mobile .rectangle-48-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-doubts-list-mobile .rectangle-49-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 38.89%;
  width: 100%;
}

.student-doubts-list-mobile .rectangle-50-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 77.78%;
  width: 100%;
}

.student-doubts-list-mobile .rectangle-67-AwXtS8 {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 355px;
}

.student-doubts-list-mobile .rectangle-67-wAIKMX {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 355px;
}

.student-doubts-list-mobile .rectangle-71-C61RwL {
  background-color: var(--white);
  border: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 81px;
  left: 23px;
  padding: 8px;
  position: absolute;
  resize: none;
  top: 117px;
  width: 369px;
}

.student-doubts-list-mobile .solve-doubts-C61RwL-10 {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 361px;
  width: 310px;
}

.student-doubts-list-mobile .solve-this-doubt-AwXtS8 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 140px;
}

.student-doubts-list-mobile .solve-this-doubt-wAIKMX {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 140px;
}

.student-doubts-list-mobile .solved-C61RwL-10 {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 324px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 918px;
  width: 55px;
}

.student-doubts-list-mobile .span0-z3pWM6 {
  color: rgb(220, 53, 69);
  font-style: normal;
}

.student-doubts-list-mobile .span1-z3pWM6 {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}

.student-doubts-list-mobile .submitted-aCi14x {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}

.student-doubts-list-mobile .submitted-gqbUX1 {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}

.student-doubts-list-mobile .submitted-ql7mN0 {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}

.student-doubts-list-mobile .submitted-sDLNVS {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}

.student-doubts-list-mobile .vatsal-agarwal-C61RwL-10 {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 603px;
  width: 189px;
}

.student-doubts-list-mobile .vector-0hr4HB {
  background-color: transparent;
  height: 2.5%;
  left: 48.61%;
  position: absolute;
  top: 48.75%;
  width: 2.78%;
}

.student-doubts-list-mobile .vector-1zaNId {
  background-color: transparent;
  height: 32.5%;
  left: 23.47%;
  position: absolute;
  top: 34.98%;
  width: 53.06%;
}

.student-doubts-list-mobile .vector-5tYqrB {
  background-color: transparent;
  height: 86.85%;
  left: 9.68%;
  position: absolute;
  top: 2.41%;
  width: 80.64%;
}

.student-doubts-list-mobile .vector-Ghyegb {
  background-color: transparent;
  height: 2.5%;
  left: 48.6%;
  position: absolute;
  top: 48.75%;
  width: 2.79%;
}

.student-doubts-list-mobile .vector-Hw9wWZ {
  background-color: transparent;
  height: 2.5%;
  left: 48.6%;
  position: absolute;
  top: 48.75%;
  width: 2.79%;
}

.student-doubts-list-mobile .vector-McuNxD {
  background-color: transparent;
  height: 93.05%;
  left: 9.68%;
  position: absolute;
  top: -0.69%;
  width: 80.64%;
}

.student-doubts-list-mobile .vector-UwmKsc {
  background-color: transparent;
  height: 86.85%;
  left: 9.68%;
  position: absolute;
  top: 2.41%;
  width: 80.64%;
}

.student-doubts-list-mobile .vector-VOHrmv {
  background-color: transparent;
  height: 2.5%;
  left: 48.61%;
  position: absolute;
  top: 48.75%;
  width: 2.78%;
}

.student-doubts-list-mobile .vector-XDTMsf {
  background-color: transparent;
  height: 32.5%;
  left: 23.47%;
  position: absolute;
  top: 34.98%;
  width: 53.06%;
}

.student-doubts-list-mobile .vector-YDNU6K {
  background-color: transparent;
  height: 6.2%;
  left: 46.9%;
  position: absolute;
  top: 46.9%;
  width: 6.2%;
}

.student-doubts-list-mobile .vector-Z5tpua {
  background-color: transparent;
  height: 32.5%;
  left: 23.47%;
  position: absolute;
  top: 34.98%;
  width: 53.06%;
}

.student-doubts-list-mobile .vector-Z87Q8p {
  background-color: transparent;
  height: 6.2%;
  left: 46.9%;
  position: absolute;
  top: 46.9%;
  width: 6.2%;
}

.student-doubts-list-mobile .vector-b254At {
  background-color: transparent;
  height: 2.5%;
  left: 48.6%;
  position: absolute;
  top: 48.75%;
  width: 2.79%;
}

.student-doubts-list-mobile .vector-eMfSFf {
  background-color: transparent;
  height: 32.5%;
  left: 23.47%;
  position: absolute;
  top: 34.98%;
  width: 53.06%;
}

.student-doubts-list-mobile .vector-hGGfYL {
  background-color: transparent;
  height: 93.05%;
  left: 9.68%;
  position: absolute;
  top: -0.69%;
  width: 80.64%;
}

.student-doubts-list-mobile .vector-hvMrjc {
  background-color: transparent;
  height: 32.5%;
  left: 23.63%;
  position: absolute;
  top: 34.98%;
  width: 52.75%;
}

.student-doubts-list-mobile .vector-hyPzor {
  background-color: transparent;
  height: 6.2%;
  left: 46.9%;
  position: absolute;
  top: 46.9%;
  width: 6.2%;
}

.student-doubts-list-mobile .vector-x8YWNM {
  background-color: transparent;
  height: 6.2%;
  left: 46.9%;
  position: absolute;
  top: 46.9%;
  width: 6.2%;
}

.student-doubts-list-mobile .vector-xVlxUo {
  background-color: transparent;
  height: 2.5%;
  left: 48.6%;
  position: absolute;
  top: 48.75%;
  width: 2.79%;
}

.student-doubts-list-mobile .vector-xsv7LI {
  background-color: transparent;
  height: 32.5%;
  left: 23.63%;
  position: absolute;
  top: 34.98%;
  width: 52.75%;
}

.student-doubts-list-mobile .what-is-euclid-lemma-C61RwL-10 {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 640px;
  width: 219px;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --black-2: rgba(0, 0, 0, 0.1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --nobel: rgba(180, 180, 180, 1);
  --white: rgba(255, 255, 255, 1);
}

.now-is-dix-c-09sL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 369px;
  height: auto;
  min-height: 500px;

  position: absolute;
  top: 608px;
  left: 20px;
}

.newdrop1 {
  width: 75%;
  height: 45px;
}

.newdrop2 {
  width: 70%;
  height: 45px;
}

.vatsal-agarwal-B6ZBuj2131 {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

@media screen and (max-width: 599px) {
  .student-doubts {
    display: none;
  }
  .bhsks-snfasa-sd2-02 {
    position: absolute;
    top: 567px;
    left: 30px;
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .vatsal-agarwal-B6ZBuj2131 {
    font-size: 12px;
  }
}

@media screen and (min-width: 600px) {
  .student-doubts-list-mobile {
    display: none;
  }
}
