.login_modal_layout_100 {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.login-modal-2-01_33 {
  z-index: 600;
  position: fixed;
  top: 55px;
  width: 41.5%;
  padding: 1px;
  max-height: 700px;

  background-color: white;

  box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.2), 0 10px 10px 0 rgba(0, 0, 0, 0.24);
  border-radius: 14px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  transition: transform 0.3s ease-out;
}

.login_guide-div {
  width: 100%;
  height: 100%;
}

.Open-Landing {
  transform: translateY(0);
}

.Close-Landing {
  transform: translateY(-150%);
}

@media (max-width: 840px) {
  .login-modal-2-01_33 {
    width: 94%;
    top: 100px;
    left: 3%;
    max-height: 540px;

    padding: 0px;
  }

  .login_guide-div {
    width: 100%;
    height: 100%;
  }
}
