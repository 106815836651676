/* screen - student-doubts-detail-t */

.montserrat-medium-black-18px {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.like_button{
  position: relative;
  margin: 9px 0px 0px 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  
}
.Color_grey{
  color: rgba(179, 179, 179);
}
.Color_dc3545{
  color: #dc3545;
}
.montserrat-semi-bold-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.student-doubts-detail-t {
  background-color: var(--coconut);
  height: 100vh;
  margin: 0px;
  min-height: 1024px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.student-doubts-detail-t .col-1212 {
  width: 95%;
  position: absolute;
  left: 63px;
  top: 0;
}

.student-doubts-detail-t .flex-row-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 105px;
  justify-content: space-between;
  position: relative;
  width: 90%;
  padding-right: 5%;
  margin: 44px 0 0 4%;
}

.student-doubts-detail-t .component-1-GyJUNH {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}

.student-doubts-detail-t .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}

.student-doubts-detail-t .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}

.student-doubts-detail-t .group-22-GyJUNH {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  margin-top: 6px;
  min-width: 316px;
  position: relative;
  width: auto;
}

.student-doubts-detail-t .flex-col-vgli2x {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}

.student-doubts-detail-t .biba-sobti-h8V8ZR {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}

.student-doubts-detail-t .bibasobtigmailcom-h8V8ZR {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}

.student-doubts-detail-t .vector-vgli2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}

.student-doubts-detail-t .active-C61RwL {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts-detail-t .analytics-24px-3-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 644px;
  width: 24px;
}

.student-doubts-detail-t .assignment-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 348px;
  width: 24px;
}

.student-doubts-detail-t .biba-sobti-C61RwL {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: 97%;
  margin: auto;
}

.student-doubts-detail-t .biba-sobti-VdQhAS {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 92px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}

.student-doubts-detail-t .bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 47px;
  width: auto;
}

.student-doubts-detail-t .class-9-read-it-from-rd-sharma-C61RwL {
  background-color: transparent;
  height: auto;
  left: 233px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 699px;
  width: 340px;
}

.student-doubts-detail-t .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;
  left: 123px;
  position: absolute;
  top: 45px;
  width: 212px;
}

.student-doubts-detail-t .component-4-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 92px;
  left: 197px;
  position: absolute;
  top: 356px;
  width: 70%;
}

.student-doubts-detail-t .component-5-C61RwL {
  background-color: transparent;
  height: 598px;
  left: 170px;
  position: absolute;
  top: 335px;
  width: 1151px;
}

.student-doubts-detail-t .date-C61RwL {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts-detail-t .row-sub-date {
  display: flex;
  width: 84%;
  justify-content: space-between;
  margin: 40px 0 0 8%;
}

.student-doubts-detail-t .row-name-status {
  display: flex;
  width: 96%;
  justify-content: space-between;
}

.student-doubts-detail-t .row-card-1 {
  width: 97%;
  margin: 18px auto;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.student-doubts-detail-t .row-card-main {
  width: 80%;
  margin: 40px 0 0 8%;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.student-doubts-detail-t .description-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 496px;
  width: 24px;
}

.student-doubts-detail-t .ellipse-2-OSIBbx {
  background-color: transparent;
  height: 19.05%;
  left: 57.14%;
  position: absolute;
  top: 21.43%;
  width: 19.05%;
}

.student-doubts-detail-t .grade-subject-C61RwL {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts-detail-t .group-0M78Jh {
  background-color: transparent;
  background-image: url(./img/vector-15@2x.png);
  background-size: 100% 100%;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-doubts-detail-t .group-160-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  position: absolute;
  top: 718px;
  width: 24px;
}

.student-doubts-detail-t .group-22-C61RwL {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}

.student-doubts-detail-t .group-50-C61RwL {
  background-color: transparent;
  border: 0;
  height: 44px;
  left: 202px;
  padding: 0;
  resize: none;
  width: 85%;
  margin-left: 1.4%;
}

.student-doubts-detail-t .group-55-C61RwL {
  background-color: transparent;
  border-radius: 5px;
}

.student-doubts-detail-t .group-56-C61RwL {
  background-color: transparent;
  height: 44px;
  left: 202px;
  position: absolute;
  top: 499px;
  width: 1099px;
}

.student-doubts-detail-t .group-5jt8cF {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-doubts-detail-t .group-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 22px;
  position: absolute;
  top: 422px;
  width: 20px;
}

.student-doubts-detail-t .group-NOXmfT {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-doubts-detail-t .group-ce7Ifp {
  background-color: transparent;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 20px;
}

.student-doubts-detail-t .help-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 570px;
  width: 24px;
}

.student-doubts-detail-t .it-is-from-unit-1-zA4C22-0001-2 {
  background-color: transparent;

  /* position: absolute; */
  text-align: left;
  /* top: 12px; */
  min-width: 180px;
  width: auto;
}

.student-doubts-detail-t .kunal-kashyap-C61RwL {
  background-color: transparent;
  height: auto;
  /* left: 202px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 468px; */
  width: auto;
}

.student-doubts-detail-t .kunal-kashyap-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 202px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 656px;
  width: auto;
}

.student-doubts-detail-t .line-5-9p2GBv {
  background-color: transparent;
  height: 99.05%;
  left: 0.9%;
  position: absolute;
  top: 0.48%;
  width: 99.29%;
}

.student-doubts-detail-t .line-6-9p2GBv {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}

.student-doubts-detail-t .notifications-24px-2-1-C61RwL {
  background-color: transparent;
  height: 42px;
  left: 976px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 42px;
}

.student-doubts-detail-t .post-4W6TZx {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 60.61%;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: 37.5%;
}

.student-doubts-detail-t .rectangle-24-C61RwL {
  background-color: #1f2531;
  height: 1024px;
  position: absolute;
  top: 0px;
  width: 63px;
}

.student-doubts-detail-t .rectangle-41-4W6TZx {
  background-color: var(--blue-zodiac);
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 98.08%;
}

.student-doubts-detail-t .rectangle-41-PXwdVf {
  position: absolute;
  top: 435px;
  right: 16%;
}

.student-doubts-detail-mobile .rectangle-41-PXwdVf {
  position: absolute;
  top: 295px;
  left: 210px;
  padding: 0 5px !important;
}

.student-doubts-detail-t .rectangle-67-C61RwL {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 202px;
  position: absolute;
  top: 593px;
  width: 1097px;
}

.student-doubts-detail-t .rectangle-67-DxAYkY {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 1097px;
}

.student-doubts-detail-t .rectangle-67-zA4C22-0001-12 {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  min-height: 44px;
  height: auto;
  padding: 10px;
  /* left: 0px;
  position: absolute;
  top: 0px; */
  width: 1097px;
}

.student-doubts-detail-t .rectangle-68-C61RwL {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 202px;
  position: absolute;
  top: 687px;
  width: 1097px;
}

.student-doubts-detail-t .rectangle-7-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 630px;
  position: absolute;
  top: 631px;
  width: 1px;
}

.student-doubts-detail-t .solve-doubts-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  width: 310px;
  margin: 60px 0 0 8%;
}

.student-doubts-detail-t .solve-this-doubt-DxAYkY {
  background-color: transparent;
  color: rgba(180, 180, 180, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 180px;
}

.student-doubts-detail-t .span0-z3pWM6 {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
}

.student-doubts-detail-t .span1-z3pWM6 {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
  font-weight: 600;
}

.student-doubts-detail-t .stickynote2-24px-1-Cp4Be6 {
  background-color: transparent;
  height: 24px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 24px;
}

.student-doubts-detail-t .supportagent-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 792px;
  width: 24px;
}

.student-doubts-detail-t .vatsal-agarwal-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts-detail-t .vatsal-agarwal-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 202px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 562px;
  width: auto;
}

.student-doubts-detail-t .vector-6XJ1dC {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}

.student-doubts-detail-t .vector-7pUNkI {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-detail-t .vector-7txfVx {
  background-color: transparent;
  height: 58.33%;
  left: 20.83%;
  position: absolute;
  top: 20.83%;
  width: 58.33%;
}

.student-doubts-detail-t .vector-C61RwL {
  background-color: transparent;
  height: 2.34%;
  left: 1.32%;
  position: absolute;
  top: 92.77%;
  width: 1.67%;
}

.student-doubts-detail-t .vector-CLdsug {
  background-color: transparent;
  height: 82.14%;
  left: 16.67%;
  position: absolute;
  top: 9.97%;
  width: 66.67%;
}

.student-doubts-detail-t .vector-G0yBDR {
  background-color: transparent;
  height: 83.33%;
  left: 16.67%;
  position: absolute;
  top: 8.33%;
  width: 66.67%;
}

.student-doubts-detail-t .vector-OSIBbx {
  background-color: transparent;
  height: 1.19%;
  left: 49.4%;
  position: absolute;
  top: 49.4%;
  width: 1.19%;
}

.student-doubts-detail-t .vector-OYLvDa {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}

.student-doubts-detail-t .vector-QxuFcp {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-doubts-detail-t .vector-UaM55c {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-detail-t .vector-VMr6Om {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 26.76%;
  width: 1.67%;
}

.student-doubts-detail-t .vector-VdQhAS {
  background-color: transparent;
  height: 100%;
  left: 73.42%;
  position: absolute;
  top: 0px;
  width: 25.32%;
}

.student-doubts-detail-t .vector-WbzDQU {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-detail-t .vector-dLw05Z {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-detail-t .vector-irJBpK {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-detail-t .vector-mzXdH9 {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 19.53%;
  width: 1.67%;
}

.student-doubts-detail-t .vector-qy1MCO {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-doubts-detail-t .vector-tQ6NQH {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}

.student-doubts-detail-t .vector-uhbuVM {
  background-color: transparent;
  height: 36.11%;
  left: 20.01%;
  position: absolute;
  top: 16.53%;
  width: 60%;
}

.student-doubts-detail-t .vector-xy9YKY {
  background-color: transparent;
  height: 11.11%;
  left: 30%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-doubts-detail-t .vector-ydL8U7 {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-detail-t .vector-ye3NYT {
  background-color: transparent;
  height: 11.11%;
  left: 60%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-doubts-detail-t .vector-ywnyBE {
  background-color: transparent;
  height: 83.33%;
  left: 8.33%;
  position: absolute;
  top: 8.33%;
  width: 83.33%;
}

.student-doubts-detail-t .what-is-euclid-lemma-C61RwL {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
  margin-top: 12px;
}

.student-doubts-detail-t .which-class-C61RwL {
  background-color: transparent;
  height: auto;
  left: 233px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 605px;
  width: 180px;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --gray: rgba(126, 126, 126, 1);
  --white: rgba(255, 255, 255, 1);
}

/* screen - student-doubts-detail-mobile */

.montserrat-medium-black-18px {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.student-doubts-detail-mobile {
  background-color: var(--coconut);
  height: 100vh;
  margin: 0px;
  min-height: 1200px;
  min-width: 414px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.student-doubts-detail-mobile .active-C61RwL {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 324px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 264px;
  width: 55px;
}

.student-doubts-detail-mobile .arrowback-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 23px;
  overflow: hidden;
  position: absolute;
  top: 132px;
  width: 24px;
}

.student-doubts-detail-mobile .biba-sobti-C61RwL {
  background-color: transparent;
  height: auto;
  left: 39px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 695px;
  width: 112px;
}

.student-doubts-detail-mobile .class-9-read-it-from-rd-sharma-2osFvC {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 167px;
}

.student-doubts-detail-mobile .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 45px;
  left: 26px;
  position: absolute;
  top: 24px;
  width: 87px;
}

.student-doubts-detail-mobile .component-32-C61RwL {
  background-color: transparent;
  height: 610px;
  left: 18px;
  position: absolute;
  top: 229px;
  width: 379px;
}

.student-doubts-detail-mobile .component-33-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 92px;
  left: 30px;
  position: absolute;
  top: 250px;
  width: 355px;
}

.student-doubts-detail-mobile .component-6-C61RwL {
  background-color: transparent;
  height: 18px;
  left: 362px;
  position: absolute;
  top: 37px;
  width: 23px;
}

.student-doubts-detail-mobile .date-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 281px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 205px;
  width: auto;
}

.student-doubts-detail-mobile .grade-subject-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 199px;
  width: auto;
}

.student-doubts-detail-mobile .group-55-C61RwL {
  background-color: transparent;
  padding: 5px;
  border-radius: 5px;
  left: 74.64%;
  position: absolute;
  top: 65.42%;
  width: 18.24%;
  height: 30px;
}

.student-doubts-detail-mobile .group-57-C61RwL099 {
  background-color: transparent;
  min-height: 44px;
  left: 30px;
  position: absolute;
  top: 398px;
  width: 357px;
}

.student-doubts-detail-mobile .group-76-C61RwL {
  background-color: transparent;
  height: 44px;
  left: 30px;
  position: absolute;
  top: 488px;
  width: 357px;
}

.student-doubts-detail-mobile .group-77-C61RwL {
  background-color: transparent;
  height: 44px;
  left: 30px;
  position: absolute;
  top: 578px;
  width: 357px;
}

.student-doubts-detail-mobile .group-78-C61RwL {
  background-color: transparent;
  border: 0;
  height: 44px;
  left: 30px;
  padding: 0;
  position: absolute;
  resize: none;
  top: 719px;
  width: 357px;
}

.student-doubts-detail-mobile .it-is-from-unit-1-AwXtS8 {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 140px;
}

.student-doubts-detail-mobile .kunal-kashyap-C61RwL {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: 112px;
}

.student-doubts-detail-mobile .kunal-kashyap-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 39px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 554px;
  width: 112px;
}

.student-doubts-detail-mobile .line-5-9p2GBv {
  background-color: transparent;
  height: 101.11%;
  left: 0.55%;
  position: absolute;
  top: -0.56%;
  width: 100%;
}

.student-doubts-detail-mobile .line-6-9p2GBv {
  background-color: transparent;
  height: 102.22%;
  left: -0.29%;
  position: absolute;
  top: -1.11%;
  width: 100.57%;
}

.student-doubts-detail-mobile .post-4W6TZx {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  left: 34.44%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23.93%;
  width: 33.11%;
}

.student-doubts-detail-mobile .rectangle-41-4W6TZx {
  background-color: var(--blue-zodiac);
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 97.35%;
}

.student-doubts-detail-mobile .rectangle-48-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-doubts-detail-mobile .rectangle-49-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 38.89%;
  width: 100%;
}

.student-doubts-detail-mobile .rectangle-50-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 77.78%;
  width: 100%;
}

.student-doubts-detail-mobile .rectangle-67-2osFvC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 355px;
}

.student-doubts-detail-mobile .rectangle-67-AwXtS8 {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 355px;
}

.student-doubts-detail-mobile .rectangle-67-Z1JSTw {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 355px;
}

.student-doubts-detail-mobile .rectangle-67-hlxJRL {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 355px;
}

.student-doubts-detail-mobile .solve-doubts-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 59px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 121px;
  width: 310px;
}

.student-doubts-detail-mobile .solve-this-doubt-hlxJRL {
  background-color: transparent;
  color: rgba(179, 179, 179, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 167px;
}

.student-doubts-detail-mobile .span0-z3pWM6 {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
}

.student-doubts-detail-mobile .span1-z3pWM6 {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
  font-weight: 600;
}

.student-doubts-detail-mobile .vatsal-agarwal-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 263px;
  width: 189px;
}

.student-doubts-detail-mobile .vatsal-agarwal-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 39px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 464px;
  width: 112px;
}

.student-doubts-detail-mobile .vector-ec7mDZ {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-detail-mobile .vector-wEJYwt {
  background-color: transparent;
  height: 66.67%;
  left: 16.67%;
  position: absolute;
  top: 16.67%;
  width: 66.67%;
}

.student-doubts-detail-mobile .what-is-euclid-lemma-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 300px;
  width: 219px;
}

.student-doubts-detail-mobile .which-class-Z1JSTw {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 140px;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --gray: rgba(126, 126, 126, 1);
  --white: rgba(255, 255, 255, 1);
}



@media screen and (max-width: 599px) {
  .student-doubts-detail-t {
    display: none;
  }

  .rectangle-67-zA4C22 {
    background-color: var(--coconut);
    border-radius: 30px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    min-height: 44px;
    height: auto;
    padding: 10px;
    width: 340px;
    overflow-x: scroll;
  }
}

@media screen and (min-width: 600px) {
  .student-doubts-detail-mobile {
    display: none;
  }
}
