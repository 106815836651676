.rectangle-24-C61RwL-10-11-00 {
  background-color: #1f2531;
  height: 100%;
  position: fixed;
  overflow: scroll;
  top: 0px;
  width: 63px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.vector-C61RwL {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 146.87%;
  width: 1.67%;
}
.vector-VMr6Om {
  background-color: transparent;
  height: 0px;
  left: 24px;
  position: absolute;
  top: 49.59%;
  width: 0px;
}
.vector-mzXdH9 {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 66.61%;
  width: 1.67%;
}
.vector-QxM5SU {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 31.74%;
  width: 1.67%;
}
.vector-2P4qUJ {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 19.51%;
  width: 1.67%;
}
.vector-J1YQmd {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 79.84%;
  width: 1.67%;
}
.vector-HlKRTo {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 44.06%;
  width: 1.67%;
}
.group-VMr6Om {
  background-color: transparent;
  height: 24px;
  left: 24px;
  position: absolute;
  top: 879px;
  width: 24px;
}
.group-XSCsvl {
  background-color: transparent;
  height: 2.16%;
  left: 2px;
  position: absolute;
  top: -280.16%;
  width: 1.67%;
}
.vector-VkPPtx {
  background-color: transparent;
  height: 0px;
  left: 24px;
  position: absolute;
  top: 64.04%;
  width: 0px;
}
.vector-YWzA7M {
  background-color: transparent;
  height: 2.16%;
  left: 24px;
  position: absolute;
  top: 92.16%;
  width: 1.67%;
}
.group-mzXdH9 {
  background-color: transparent;
  height: 1.77%;
  left: 24px;
  position: absolute;
  top: 55.21%;
  width: 1.39%;
}
.stickynote2-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 24px;
  overflow: hidden;
  position: absolute;
  top: 104.24%;
  width: 24px;
}
.line-6-9p2GBv {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}
.line-5-9p2GBv {
  background-color: transparent;
  height: 99.05%;
  left: 0.9%;
  position: absolute;
  top: 0.48%;
  width: 99.29%;
}

.component-1-C61RwL-0001 {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;
  left: 103px;
  position: absolute;
  top: 45px;
  width: 212px;
}
.group-22-C61RwL-9982 {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}
.vector-VdQhAS {
  background-color: transparent;
  height: 100%;
  left: 73.42%;
  position: absolute;
  top: 0px;
  width: 25.32%;
}
.biba-sobti-VdQhAS {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 92px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 47px;
  width: auto;
}
.rating_stars2 {
  height: 100px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-c-class {
  width: 80%;
  height: 265px;

  padding: 20px;

  border: 1px solid #ffff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px 2px #ccc;
  background-color: white;
  text-align: center;
  position: absolute;
  top: 270px;
  left: 130px;
}

.inclqam-ds-0039 {
  font-size: 24px;
  font-weight: 700;
  font-family: "Montserrat", Helvetica;
  color: #1f2531;
}

.card-body-in-c-class {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.in-ele-btn-c {
  width: 120px;
  margin-top: 40px;
}

.card-c-class-mobile {
  display: none;
}

@media screen and (max-width: 599px) {
  .group-22-C61RwL-9982 {
    display: none;
  }
  .rectangle-24-C61RwL-10-11-00 {
    display: none;
  }

  .IDentii-tidi {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: auto;
  }

  .component-1-C61RwL-0001 {
    height: 80px;
    left: 50px;
    position: absolute;
    top: 45px;
    width: 182px;
  }

  .card-c-class {
    display: none;
  }

  .card-c-class-mobile {
    display: block;
    width: 85%;
    height: 200px;
    top: 196px;
    left: 25px;
    padding: 20px;

    border: 1px solid #ffff;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 2px #ccc;
    background-color: white;
    text-align: center;
    position: absolute;
  }

  .inclqam-ds-0039 {
    font-size: 14px;
    font-weight: 600;
  }

  .in-ele-btn-c {
    width: 80px;
    margin-top: 20px;
  }
}
