.course-details-1 h1 {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: x-large;
  font-weight: 700;
}

.content-class {
  font-family: 'Montserrat', sans-serif;
  font-size: medium;
  line-height: 1.5;
  padding: 20px auto;
  text-align: justify;
  border: 4px solid red;
  border-radius: 20px;
  margin: 20px;
  font-weight: 400;
}

.text-weight {
    font-weight: bold;
}

/* .course-details-1 p {
  font-size: 20px;
  margin: 10px 0 0 20px;
}

.course-details-1 .regular {
  border-radius: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background-image: url(./img/regular.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.course-details-1 .crash {
  border-radius: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background-image: url(./img/crash.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.course-details-1 .test {
  border-radius: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background-image: url(./img/test.png);
  background-size: cover;
  background-repeat: no-repeat;
} */



.login-modal {
    width: 40%;
    height: 500px;
    margin: auto;
    margin-top: 120px;
    background-color: whitesmoke;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    padding: 24px 0;
  }
  
  .login-modal .login-modal-title {
    width: 70%;
    margin: auto;
    text-align: center;
    margin-bottom: 24px;
  }