/*This is Handling the Outer Layout of Sider*/
.sideDrawer {
  position: fixed;
  z-index: 600;
  height: 100%;
  padding: 5px;
  width: 260px;
  max-width: 68%;
  left: 0;
  top: 0;

  background-color: #1f2531;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;

  transition: transform 0.1s ease-out;
}

/* This is handling the inner elements of the sider */
.sider-ele-mob {
  width: 100%;
  height: 22px;
  padding-left: 30px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;

  color: #f4f4f4;

  display: flex;
  align-items: center;
}

/* Handling the Style and Font of SiderElement */
.sider-ele-mob-tag {
  padding-left: 5px;
}

@media screen and (min-width: 840px) {
  .sideDrawer {
    display: none;
  }
}

.Open_SideD {
  transform: translateX(0);
}

.Close_SideD {
  transform: translateX(-100%);
}