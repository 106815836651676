/* screen - relational-manager-issue-detail */

.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.relational-manager-issue-detail {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1350px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}
.relational-manager-issue-detail .accountbalance-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-issue-detail .analytics-24px-4-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-issue-detail .arrowback-24px-1-Fjgz1C {
  align-self: flex-start;
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-left: 5px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-issue-detail .biba-sobti-h8V8ZR {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.relational-manager-issue-detail .bibasobtigmailcom-h8V8ZR {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.relational-manager-issue-detail .category-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 301px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 76px;
}
.relational-manager-issue-detail .change-class-vIrrhm {
  background-color: transparent;
  height: auto;
  left: 45px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.relational-manager-issue-detail .component-1-GyJUNH {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.relational-manager-issue-detail .create-ticket-pBIX2g {
  background-color: transparent;
  height: auto;
  left: 46px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.relational-manager-issue-detail .edit-profile-hwpQXq {
  background-color: transparent;
  height: auto;
  left: 51px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.relational-manager-issue-detail .ellipse-2-RYyMFx {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.relational-manager-issue-detail .flex-col-A6SpMB {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 20px;
  min-height: 84px;
  position: relative;
  width: 160px;
}
.relational-manager-issue-detail .flex-col-C61RwL-10-1 {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 85px;
  margin-top: 45px;
  min-height: 977px;
  position: relative;
  width: 1256px;
}
.relational-manager-issue-detail .flex-col-QkTteQ {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 422px;
  position: relative;
  width: 667px;
}
.relational-manager-issue-detail .flex-col-vgli2x {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.relational-manager-issue-detail .flex-row-0xy0vn {
  align-items: flex-start;
  align-self: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 105px;
  justify-content: flex-start;
  min-width: 1233px;
  position: relative;
  width: auto;
}
.relational-manager-issue-detail .flex-row-Fjgz1C {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 84px;
  justify-content: flex-start;
  margin-top: 26px;
  margin-bottom: -44px;
  min-width: 667px;
  position: relative;
  width: auto;
}
.relational-manager-issue-detail .flex-row-axsvJs {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.relational-manager-issue-detail .flex-row-oV5UrE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 637px;
  justify-content: flex-start;
  margin-top: 60px;
  min-width: 1213px;
  position: relative;
  width: auto;
}
.relational-manager-issue-detail .group-125-4xLMVj {
  background-color: var(--blue-zodiac);
  border-radius: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 318px;
}
.relational-manager-issue-detail .group-202-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 125px;
  min-height: 637px;
  position: relative;
  width: 421px;
}
.relational-manager-issue-detail .group-204-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-right: 40px;
  margin-top: 131px;
  position: relative;
  width: 320px;
}
.relational-manager-issue-detail .group-209-C61RwL-10-1 {
  align-items: flex-end;
  background-color: #1f2531;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 100%;
  padding: 50px 18px;
  position: fixed;
  z-index: 10;
  width: 63px;
}
.relational-manager-issue-detail .group-22-GyJUNH {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  margin-top: 6px;
  min-width: 316px;
  position: relative;
  width: auto;
}
.relational-manager-issue-detail .group-47-1UTYTe {
  background-color: transparent;
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 659px;
}
.relational-manager-issue-detail .group-91-hF0VIA {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 369px;
}
.relational-manager-issue-detail .group-92-hF0VIA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 365px;
}
.relational-manager-issue-detail .group-94-hF0VIA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 365px;
}
.relational-manager-issue-detail .group-95-hF0VIA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 365px;
}
.relational-manager-issue-detail .group-96-hF0VIA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 365px;
}
.relational-manager-issue-detail .group-99-hF0VIA {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 351px;
  width: 369px;
}
.relational-manager-issue-detail .group-AAVFy6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.relational-manager-issue-detail .group-XDTIuB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.relational-manager-issue-detail .group-aTF8dW {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.relational-manager-issue-detail .group-rAjf8W {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.relational-manager-issue-detail .help-24px-2-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-issue-detail .historytoggleoff-24px-1-D0BVcB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-issue-detail .in-progess-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 210px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 72px;
}
.relational-manager-issue-detail .issue-category-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 501px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 87px;
  width: 136px;
}
.relational-manager-issue-detail .issue-text-desc-ibing-the-issue-PXwdVf {
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 28px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 607px;
}
.relational-manager-issue-detail .kunal-kashyap-VhXzXH {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 105px;
}
.relational-manager-issue-detail .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.relational-manager-issue-detail .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.relational-manager-issue-detail .mark-as-resolved-kIRo50 {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 12px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 83px;
}
.relational-manager-issue-detail .messages-4xLMVj {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 64px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 11px;
  width: auto;
}
.relational-manager-issue-detail .notifications-24px-2-1-GyJUNH {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-bottom: 13px;
  margin-left: 641px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.relational-manager-issue-detail .open-issue-list-4FsOUs {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 5px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.relational-manager-issue-detail .overlap-group-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 114px;
  margin-top: 56px;
  position: relative;
  width: 657px;
}
.relational-manager-issue-detail .overlap-group1-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-left: 2px;
  margin-top: 51px;
  position: relative;
  width: 659px;
}
.relational-manager-issue-detail .overlap-group2-wvWlxZ {
  /* background-color: transparent; */
  flex-shrink: 1;
  height: 30px;
  position: relative;
  width: 150px;
  font-family: "Montserrat", Helvetica;
  font-weight: 600;
  font-size: small;
}
.relational-manager-issue-detail .overlap-group3-A6SpMB {
  flex-shrink: 1;
  height: 30px;
  margin-left: 41px;
  position: relative;
  width: 106px;
  font-family: "Montserrat", Helvetica;
  font-weight: 600;
  font-size: small;
}
.relational-manager-issue-detail .overlap-group4-A6SpMB {
  /* background-color: transparent; */
  flex-shrink: 1;
  height: 30px;
  position: relative;
  width: 150px;
  font-family: "Montserrat", Helvetica;
  font-weight: 600;
  font-size: small;
}
.relational-manager-issue-detail .overlap-group5-A6SpMB {
  /* background-color: transparent; */
  flex-shrink: 1;
  height: 30px;
  margin-left: 20px;
  position: relative;
  width: 150px;
  font-family: "Montserrat", Helvetica;
  font-weight: 600;
  font-size: small;
}
.relational-manager-issue-detail .overlap-group6-4FsOUs {
  background-color: transparent;
  flex-shrink: 1;
  height: 600px;
  margin-top: 14px;
  position: relative;
  width: 383px;
}
.relational-manager-issue-detail .pending-jBuAVo {
  background-color: transparent;
  height: auto;
  left: 209px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 56px;
}
.relational-manager-issue-detail .rectangle-31-hF0VIA {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 600px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.relational-manager-issue-detail .rectangle-40-PXwdVf {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 114px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 657px;
}
.relational-manager-issue-detail .rectangle-40-hF0VIA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.relational-manager-issue-detail .rectangle-40-pjV5k8 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 657px;
}
.relational-manager-issue-detail .rectangle-41-hF0VIA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.relational-manager-issue-detail .rectangle-46-vIrrhm {
  background-color: transparent;
  height: 40px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 160px;
}
.relational-manager-issue-detail .rectangle-47-hF0VIA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.relational-manager-issue-detail .rectangle-47-hwpQXq {
  background-color: transparent;
  height: 40px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 160px;
}
.relational-manager-issue-detail .rectangle-48-VhXzXH {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.relational-manager-issue-detail .rectangle-48-jBuAVo {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.relational-manager-issue-detail .rectangle-48-pBIX2g {
  background-color: transparent;
  height: 40px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 160px;
}
.relational-manager-issue-detail .rectangle-49-kIRo50 {
  background-color: transparent;
  height: 40px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 106px;
}
.relational-manager-issue-detail .rectangle-7-wvWlxZ {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-left: 48.99px;
  margin-top: 43px;
  position: relative;
  width: 1px;
}
.relational-manager-issue-detail .s-no-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.relational-manager-issue-detail .school-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-issue-detail .shreyans-jain-jBuAVo {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 93px;
}
.relational-manager-issue-detail .sos-1UTYTe {
  background-color: transparent;
  color: var(--crusta);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 550px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 27px;
  width: 150px;
}
.relational-manager-issue-detail .sos-BXj6lj {
  background-color: transparent;
  height: auto;
  left: 315px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 34px;
}
.relational-manager-issue-detail .sos-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 315px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 34px;
}
.relational-manager-issue-detail .standard-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 301px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 366px;
  width: 61px;
}
.relational-manager-issue-detail .status-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 220px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.relational-manager-issue-detail .student-name-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 83px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 107px;
}
.relational-manager-issue-detail .ticket-VhXzXH {
  background-color: transparent;
  height: auto;
  left: 211px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 47px;
}
.relational-manager-issue-detail .vatsal-agarwal-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 85px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 104px;
}
.relational-manager-issue-detail .vatsal-agarwal-pjV5k8 {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 25px;
  width: 300px;
}
.relational-manager-issue-detail .vector-1duCl1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-issue-detail .vector-2CQzkB {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.relational-manager-issue-detail .vector-2xKpiG {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-issue-detail .vector-40xw7g {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.relational-manager-issue-detail .vector-4xLMVj {
  background-color: transparent;
  height: 12px;
  left: 282px;
  position: absolute;
  top: 17px;
  width: 18px;
}
.relational-manager-issue-detail .vector-AxqJxr {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.relational-manager-issue-detail .vector-Axu9vm {
  background-color: transparent;
  height: 30px;
  left: 10px;
  position: absolute;
  top: 7px;
  width: 30px;
}
.relational-manager-issue-detail .vector-Ayxm2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  position: relative;
  width: 24px;
}
.relational-manager-issue-detail .vector-BRkMYc {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.relational-manager-issue-detail .vector-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.relational-manager-issue-detail .vector-H1IJdQ {
  background-color: transparent;
  height: 16px;
  left: 20px;
  position: absolute;
  top: 22px;
  width: 16px;
}
.relational-manager-issue-detail .vector-I29KJn {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-issue-detail .vector-RYyMFx {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.relational-manager-issue-detail .vector-WxmxmD {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.relational-manager-issue-detail .vector-XxcEsk {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-issue-detail .vector-YPcxtl {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.relational-manager-issue-detail .vector-Zppvke {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.relational-manager-issue-detail .vector-axsvJs {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.relational-manager-issue-detail .vector-uxDhU3 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.relational-manager-issue-detail .vector-vgli2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.relational-manager-issue-detail .vector-x0nTHj {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.relational-manager-issue-detail .vector-x6oIim {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.relational-manager-issue-detail .vector-xxy9YX {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-issue-detail .x1-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 15px;
}
.relational-manager-issue-detail .x2-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 130px;
  width: 15px;
}
.relational-manager-issue-detail .x23022021-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 28px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 87px;
  width: 68px;
}
.relational-manager-issue-detail .x3-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 189px;
  width: 15px;
}
.relational-manager-issue-detail .x4-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 15px;
}

.relational-manager-issue-detail .x5-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 304px;
  width: 15px;
}
.relational-manager-issue-detail .x6-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 366px;
  width: 15px;
}
.relational-manager-issue-detail .x7-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 422px;
  width: 15px;
}
.relational-manager-issue-detail .x8-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 481px;
  width: 15px;
}
.relational-manager-issue-detail .x9-hF0VIA {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 540px;
  width: 15px;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - relational-manager-issue-detail-mobile */

.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.relational-manager-issue-detail-mobile {
  align-items: center;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 2px;
  position: relative;
  width: 414px;
}
.relational-manager-issue-detail-mobile .arrowback-24px-1-0PZcXW {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-left: 1px;
  margin-top: 93px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-issue-detail-mobile .booked-MuL8Ob {
  background-color: transparent;
  height: auto;
  left: 286px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 477px;
  width: 50px;
}
.relational-manager-issue-detail-mobile .booked-mUxJ13 {
  background-color: transparent;
  height: auto;
  left: 284px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 50px;
}
.relational-manager-issue-detail-mobile .category-MuL8Ob {
  background-color: transparent;
  height: auto;
  left: 263px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 92px;
}
.relational-manager-issue-detail-mobile .change-class-Jge9fh {
  background-color: transparent;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 79px;
}
.relational-manager-issue-detail-mobile .completed-MuL8Ob {
  background-color: transparent;
  height: auto;
  left: 184px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 74px;
}
.relational-manager-issue-detail-mobile .component-1-0PZcXW {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.relational-manager-issue-detail-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 249px;
  margin-top: 13px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.relational-manager-issue-detail-mobile .create-ticket-84keex {
  background-color: transparent;
  height: auto;
  left: 54px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 84px;
}
.relational-manager-issue-detail-mobile .edit-profile-q9xEPe {
  background-color: transparent;
  height: auto;
  left: 54px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 66px;
}
.relational-manager-issue-detail-mobile .flex-col-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 162px;
  position: relative;
  width: 87px;
}
.relational-manager-issue-detail-mobile .flex-row-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 162px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.relational-manager-issue-detail-mobile .flex-row-VMr6Om {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 2px;
  margin-top: 39px;
  min-width: 368px;
  position: relative;
  width: auto;
}
.relational-manager-issue-detail-mobile .flex-row-mzXdH9 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 2px;
  margin-top: 26px;
  min-width: 368px;
  position: relative;
  width: auto;
}
.relational-manager-issue-detail-mobile .group-210-C61RwL {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 46px;
  min-height: 581px;
  position: relative;
  width: 381px;
}
.relational-manager-issue-detail-mobile .group-212-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-right: 1px;
  margin-top: 21px;
  position: relative;
  width: 361px;
}
.relational-manager-issue-detail-mobile .group-213-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 134px;
  margin-left: 4px;
  margin-top: 63px;
  position: relative;
  width: 364px;
}
.relational-manager-issue-detail-mobile .group-47-jlZH0x {
  background-color: transparent;
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 361px;
}
.relational-manager-issue-detail-mobile .group-90-MuL8Ob {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 170px;
  width: 346px;
}
.relational-manager-issue-detail-mobile .group-91-MuL8Ob {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 464px;
  width: 348px;
}
.relational-manager-issue-detail-mobile .group-92-MuL8Ob {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 287px;
  width: 346px;
}
.relational-manager-issue-detail-mobile .group-94-MuL8Ob {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 405px;
  width: 346px;
}
.relational-manager-issue-detail-mobile .group-95-MuL8Ob {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 346px;
  width: 346px;
}
.relational-manager-issue-detail-mobile .group-96-MuL8Ob {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 233px;
  width: 346px;
}
.relational-manager-issue-detail-mobile .issue-category-3oLdax {
  background-color: transparent;
  height: auto;
  left: 249px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 102px;
  width: 99px;
}
.relational-manager-issue-detail-mobile
  .issue-text-desc-ibing-the-issue-3oLdax {
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 20px;
  width: 332px;
}
.relational-manager-issue-detail-mobile .line-5-mHq818 {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.relational-manager-issue-detail-mobile .line-6-mHq818 {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.relational-manager-issue-detail-mobile .mark-as-resolved-Ic873P {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 38px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 99px;
}
.relational-manager-issue-detail-mobile .open-issue-list-Uoyr1g {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 4px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.relational-manager-issue-detail-mobile .opened-Fp9EK8 {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.relational-manager-issue-detail-mobile .opened-TnMm4P {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.relational-manager-issue-detail-mobile .opened-kjhPFT {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.relational-manager-issue-detail-mobile .opened-qk4txF {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.relational-manager-issue-detail-mobile .opened-tBs9yx {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.relational-manager-issue-detail-mobile .organic-4-MuL8Ob {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 69px;
}
.relational-manager-issue-detail-mobile .organic-5-FLtjTJ {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.relational-manager-issue-detail-mobile .overlap-group-BdpSxK {
  background-color: transparent;
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 173px;
}
.relational-manager-issue-detail-mobile .overlap-group1-BdpSxK {
  background-color: transparent;
  flex-shrink: 1;
  height: 45px;
  margin-left: 22px;
  position: relative;
  width: 173px;
}
.relational-manager-issue-detail-mobile .overlap-group2-NwNIwt {
  background-color: transparent;
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 173px;
}
.relational-manager-issue-detail-mobile .overlap-group3-NwNIwt {
  background-color: transparent;
  flex-shrink: 1;
  height: 45px;
  margin-left: 22px;
  position: relative;
  width: 173px;
}
.relational-manager-issue-detail-mobile .overlap-group4-Uoyr1g {
  background-color: transparent;
  flex-shrink: 1;
  height: 546px;
  margin-top: 12px;
  position: relative;
  width: 359px;
}
.relational-manager-issue-detail-mobile .rectangle-31-MuL8Ob {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 546px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.relational-manager-issue-detail-mobile .rectangle-40-3oLdax {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 134px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 358px;
}
.relational-manager-issue-detail-mobile .rectangle-40-43xqCp {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.relational-manager-issue-detail-mobile .rectangle-40-MuL8Ob {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 52px;
  width: 342px;
}
.relational-manager-issue-detail-mobile .rectangle-41-MuL8Ob {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 7px;
  position: absolute;
  top: 170px;
  width: 342px;
}
.relational-manager-issue-detail-mobile .rectangle-44-Fp9EK8 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-issue-detail-mobile .rectangle-44-tBs9yx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-issue-detail-mobile .rectangle-46-Jge9fh {
  background-color: transparent;
  height: 45px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.relational-manager-issue-detail-mobile .rectangle-47-MuL8Ob {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 111px;
  width: 342px;
}
.relational-manager-issue-detail-mobile .rectangle-47-q9xEPe {
  background-color: transparent;
  height: 45px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.relational-manager-issue-detail-mobile .rectangle-48-84keex {
  background-color: transparent;
  height: 45px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.relational-manager-issue-detail-mobile .rectangle-48-FLtjTJ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-issue-detail-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.relational-manager-issue-detail-mobile .rectangle-49-Ic873P {
  background-color: transparent;
  height: 45px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.relational-manager-issue-detail-mobile .rectangle-49-TnMm4P {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-issue-detail-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.relational-manager-issue-detail-mobile .rectangle-49-kjhPFT {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-issue-detail-mobile .rectangle-49-qk4txF {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-issue-detail-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.relational-manager-issue-detail-mobile .s-no-MuL8Ob {
  background-color: transparent;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 42px;
}
.relational-manager-issue-detail-mobile .sos-jlZH0x-0920 {
  background-color: transparent;
  color: var(--crusta);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 265px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: 85px;
}
.relational-manager-issue-detail-mobile .status-MuL8Ob {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 49px;
}
.relational-manager-issue-detail-mobile .student-MuL8Ob {
  background-color: transparent;
  height: auto;
  left: 78px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 94px;
}
.relational-manager-issue-detail-mobile .upcoming-FLtjTJ {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.relational-manager-issue-detail-mobile .vatsal-agarwal-43xqCp {
  background-color: transparent;
  height: auto;
  left: 22px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: 190px;
}
.relational-manager-issue-detail-mobile .vector-8OHlvz {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.relational-manager-issue-detail-mobile .vector-FTTxjh {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-issue-detail-mobile .x12pm-MuL8Ob {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 44px;
}
.relational-manager-issue-detail-mobile .x1pm-MuL8Ob {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 128px;
  width: 31px;
}
.relational-manager-issue-detail-mobile .x23022021-3oLdax {
  background-color: transparent;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 102px;
  width: 70px;
}
.relational-manager-issue-detail-mobile .x2pm-TnMm4P {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.relational-manager-issue-detail-mobile .x2pm-kjhPFT {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.relational-manager-issue-detail-mobile .x2pm-qk4txF {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.relational-manager-issue-detail-mobile .x3pm-FLtjTJ {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.relational-manager-issue-detail-mobile .x4pm-Fp9EK8 {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.relational-manager-issue-detail-mobile .x6pm-tBs9yx {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}

/* .input-group-text {
 
}
.form-control:focus {
  
} */
/* @media screen and (min-width: 768px) and (max-width: 992px)
{
.card {
    margin: 0px 50px;
    width: 400px;
    height: auto;
}
}




.card {
    margin: 5px 200px;
    padding: 60px;
    border-radius: 14px;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}
@media screen and (min-width: 768px) and (max-width: 1600px){
.card {
    margin: 5px 50px;
    width: 400px;
    height: auto;
}
}


.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
} */
.input-group-text {
  font-size: 15px;
  background-color: #dc3545;
  color: white;
}
.btn_1 {
  /* background-color: #dc3545;
    border: #dc3545 solid 2px;
    color: white; */
  /* border-radius: 4px; */
  /* font-size: 20px; */
  /* padding: 6px 6px 6px 6px; */
  margin: 20px 120px;
}

.card_1 {
  margin: 50px 200px;
  padding: 26px 26px 0px 26px;
  border-radius: 14px;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}

.card_1 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.35rem;
}
.ticket-card-body {
  flex: 1 1 auto;
  min-height: 1px;

  width: 370px;
}
@media screen and (max-width: 599px) {
  .relational-manager-issue-detail {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .relational-manager-issue-detail-mobile {
    display: none;
  }
}

.Show-div-08 {
  display: none;
}

.edit_profile {
  display: none;
}

.increase_height {
  height: 1300px;
}

.teacher-modal {
  width: 750px;
}