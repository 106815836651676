.calender-status-display {
  width: 100%;
  margin-right: 30px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.calender-status-display-only-c {
  width: 100%;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.calender-user-dis {
  min-height: 60px;
  width: 86%;
  margin: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.User-info {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}

.notification-2 {
  padding: 15px;

  font-size: 25px;
}

.user-info-in {
  padding: 15px 15px 0px 0px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: right;

  color: #120078;
}

.User-Icon {
  padding: 12px;

  border: 1px snow gainsboro;
  border-radius: 30px;
  background-color: #05bb8a;
  color: white;

  font-size: 38px;
}

.content-teacher-head {
  width: 85%;
  margin-top: 30px;

  display: flex;
  justify-content: space-between;
}

.content-teacher-head-only-c {
  width: 90%;
  margin-top: 30px;

  display: flex;
  justify-content: space-between;
}

.content-teacher-op {
  width: auto;
  height: auto;
  color: #dc3545;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.2em;
}

.content-right-date {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8em;
  line-height: 15px;

  color: #7e7e7e;
}

.status4 {
  padding: 5px;
  width: 90%;
  margin-top: 20px;
  min-height: 240px;

  border: 1px solid white;
  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.calender-status {
  width: 85%;
  /* padding-top: 6px; */

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;

  color: grey;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 45px;
}

.status-2 {
  width: 94%;
  min-height: 35px;
  margin-top: 12px;

  border: 1px solid white;
  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 8px;

  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: #152138;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
}

.calender-items-time {
  min-width: 30px;
}

.calender-items-data {
  min-width: 58px;
}
.dic-ins-s-e {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 250px;
  width: 90%;
}

.classTime-select-comp {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
}

@media only screen and (max-width: 839px) {
  .calender-user-dis {
    display: none;
  }
}

@media screen and (min-width: 840px) {
  .calender-status-display {
    padding-top: 10px;
    max-width: 32%;
    margin-right: 180px;
  }

  .classTime-select-comp {
    width: 70%;
  }

  .calender-status-display-only-c {
    width: auto;
    height: auto;
  }

  .status4 {
    width: 90%;
    min-height: 450px;
    min-width: 380px;
  }

  .content-teacher-op {
    font-size: 1.3em;
  }

  .content-right-date {
    font-size: 0.85em;
  }

  .calender-status {
    font-size: 14px;
    padding: 10px 50px;
  }

  .status-2 {
    font-size: 15px;
  }

  .calender-items-time {
    min-width: 40px;
    text-align: center;
  }

  .calender-items-data {
    min-width: 70px;
  }
}
