.status-2_iis {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.Dropdown-lec-notes-2 {
  width: 43%;
}

@media screen and (max-width: 720px) {
  .Dropdown-lec-notes-2 {
    width: 47%;
  }
}
