@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,500,600|Roboto:700,500");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.sales-student-details-1 input:focus,
textarea:focus,
select:focus {
  outline: none;
}

body {
  font-family: "Montserrat", Helvetica !important;
}

.sidenav2 {
  height: 100%;
  width: 80px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #1f2531;
  overflow-x: hidden;
  padding-top: 50px;
}

.sidelink2 {
  display: block;
  color: white;
  text-decoration: none;
  padding: 22px;
}

.head {
  display: block;
  margin-left: 80px;
  padding: 30px;
}

.label {
  margin-bottom: 8px;
  font-weight: 600;
}

.card-heading {
  border-radius: 8px;
  background-color: rgb(220, 53, 69);
  color: white;
  height: 40px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.sider-hc-teacher-list-in-wdt-80 {
  width: 100%;
  height: 70%;
  margin: 120px 0px 30px;
  color: gray;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.icon-in-hc-color {
  color: rgba(179, 179, 179, 1);
  font-size: 24px;
}
.sider-SalesStatistics-detail {
  width: 50px;
  height: 100vh;
  position: fixed;
  background-color: #1f2531;
}

.heading_line {
  font-weight: 600;
  margin: 35px 2px 10px 20px;
}

.fa {
  margin-right: 20px;
}

.comment {
  margin-left: 20px;
  border-radius: 8px;
  border: 1px solid rgba(61, 59, 59, 0.1);
  padding: 20px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

.comment_2 {
  width: 90%;
  margin: 0 20px;
  border-radius: 20px;
  border: 1px solid rgba(61, 59, 59, 0.2);
  padding: 3px 15px;
}

.rm-teacher-allocation-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}

.input-rm-teacher-all {
  max-width: 67%;
  margin: 10px;

  border-radius: 8px;
  border-color: rgb(235, 235, 235);
  outline: none;
}

.button-rm-teach-all {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
  min-width: 135px;
  margin: 5px;
}

.mob_nav {
  display: none;
}

.bottom-content-1 {
  margin-top: 50px !important;
  width: 100%;
  margin: auto;
}

.bottom-content-2 {
  border-right: 1px solid rgba(0, 0, 0, 0.9);
  margin-top: 40px;
  width: 50%;
  display: inline-block;
}

.bottom-content-3 {
  padding-left: 20px;
  width: 50%;
  display: inline-block;
}

.bottom-content-4 {
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
}

.drop2 {
  margin: 40px 0 0 10px;
  width: 60%;
  padding-top: 10px;
}

.picker-dimensions2 {
  width: 180px;
  height: 36px;
}


@media only screen and (max-width: 720px) {
  .mobile_nav {
    display: none;
  }

  .mob_nav {
    display: block;
    float: right;
    font-size: 30px;
    margin: 15px 20px 0 0;
  }

  .sidenav2 {
    display: none;
  }

  .head {
    margin-left: 0;
  }

  .card-ex {
    margin-left: 50px !important;
  }

  .rm-teacher-allocation {
    margin: 0px;
    width: 100%;
  }

  .rm-teacher-allocation-div {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .input-rm-teacher-all {
    max-width: 82%;
    margin: 0px;
  }

  .button-rm-teach-all {
    margin: 15px 0px;
    font-size: 14px !important;
  }

  .bottom-content-1 {
    width: 90%;
  }

  .drop2 {
    width: 100%;
  }

  .dropdown_text {
    font-size: 14px !important;
  }

  .warning-msg {
    margin-top: 15px;
  }

  .warning-msg-2 {
    margin-left: 0 !important;
    width: 95% !important;
  }
}

.rating {
  margin-left: 40px;
}

.trial_student {
  width: 100%;
  margin: 30px 0 30px 25px;
}

.trial_student_label {
  margin-top: 5px;
  font-size: 17px;
  font-weight: 500;
}

.user-name {
  padding-top: 25px;
}

.warning-msg {
  border: 1px solid #c74751;
  border-radius: 5px;
  padding: 4px;
}

.warning-msg-2 {
  border: 1px solid #c74751;
  border-radius: 5px;
  padding: 4px;
  margin-left: 12px;
  width: 50%;
}