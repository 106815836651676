@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,500,600|Roboto:700,500");
/* screen - higher-committee-dashboard */

.border-1px-blue-zodiac {
  border: 1px solid var(--blue-zodiac);
}

.payga4wk-z-bshl-Sgr7kw-123 {
  text-align: center;
  min-width: 50px;
}
.x499900-Sgr7kw-123 {
  text-align: center;
  min-width: 125px;
}
.x07-dec-2020-Sgr7kw-123 {
  text-align: center;
  min-width: 70px;
}
.x014009-pm-Sgr7kw-123 {
  text-align: center;
  min-width: 90px;
}
.andb586-Sgr7kw-123 {
  text-align: center;
  min-width: 90px;
}

.fnaj-sdnj23j11 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;
}

.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-09px-w {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.higher-committee-dashboard {
  align-items: flex-start;
  background-color: var(--coconut);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.higher-committee-dashboard .accountbalance-24px-1-RH0WJ5 {
  background-color: transparent;
  height: 24px;
  left: 16%;
  overflow: hidden;
  position: absolute;
  top: 609px;
  width: 24px;
}
.higher-committee-dashboard .analytics-24px-3-RH0WJ5 {
  background-color: transparent;
  height: 24px;
  left: 16%;
  overflow: hidden;
  position: absolute;
  top: 543px;
  width: 24px;
}
.higher-committee-dashboard .analytics-RH0WJ5 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 544px;
  width: auto;
}
.higher-committee-dashboard .vector-s3i1G8 {
  background-color: transparent;
  height: 24px;
  left: 16%;
  position: absolute;
  top: 675px;
  width: 24px;
}

.higher-committee-dashboard .biba-sobti-vgli2x {
  background-color: transparent;
  color: var(--deep-blue);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2px;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: right;
  width: auto;
}
.higher-committee-dashboard .bibasobtigmailcom-vgli2x {
  background-color: transparent;
  color: var(--deep-blue);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2px;
  margin-top: 4px;
  min-height: 24px;
  position: relative;
  text-align: right;
  width: auto;
}
.higher-committee-dashboard .category-WxqicF {
  background-color: transparent;
  height: auto;
  left: 625px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 85px;
}
.higher-committee-dashboard .class-name-y1HRxC {
  background-color: transparent;
  height: auto;
  left: 337px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 100px;
}
.higher-committee-dashboard .component-1-RH0WJ5 {
  background-color: transparent;
  /* border: 1px solid rgba(244, 244, 244, 1); */
  height: 95px;
  left: 14%;
  position: absolute;
  top: 60px;
  width: 183px;
}
.higher-committee-dashboard .dashboard-24px-1-52Wjwg {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 596px;
  overflow: hidden;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.higher-committee-dashboard .dashboard-24px-1-y1HRxC {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 352px;
  overflow: hidden;
  padding: 0 11.8px;
  position: absolute;
  top: 49px;
  width: 24px;
}
.higher-committee-dashboard .dashboard-RH0WJ5 {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 280px;
  width: auto;
}
.higher-committee-dashboard .date-WxqicF {
  background-color: transparent;
  height: auto;
  left: 536px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 59px;
}
.higher-committee-dashboard .date-y1HRxC {
  background-color: transparent;
  height: auto;
  left: 606px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 224px;
  width: 44px;
}
.higher-committee-dashboard .ellipse-2-RYyMFx {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.higher-committee-dashboard .flex-col-C61RwL-872 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 3%;
  margin-top: 51px;
  min-height: 913px;
  position: absolute;
  left: 19%;
  width: 78%;
}
.higher-committee-dashboard .flex-row-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 167px;
  justify-content: flex-start;
  position: relative;
  width: 100%;
}
.higher-committee-dashboard .flex-row-x1fO3U {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.higher-committee-dashboard .group-117-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 66px;
  width: 728px;
}
.higher-committee-dashboard .group-118-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 126px;
  width: 728px;
}
.higher-committee-dashboard .group-119-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 178px;
  width: 728px;
}
.higher-committee-dashboard .group-120-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 234px;
  width: 728px;
}
.higher-committee-dashboard .group-121-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 290px;
  width: 728px;
}
.higher-committee-dashboard .group-122-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 346px;
  width: 728px;
}
.higher-committee-dashboard .group-123-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 412px;
  width: 728px;
}
.higher-committee-dashboard .group-124-y1HRxC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 478px;
  width: 728px;
}
.higher-committee-dashboard .group-14-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 167px;
  position: relative;
  width: 37vw;
}
.higher-committee-dashboard .username-container {
  display: flex;
  position: absolute;
  right: 8%;
}
.higher-committee-dashboard .group-22-GyJUNH {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 14px;
  min-height: 57px;
  position: relative;
}
.higher-committee-dashboard .group-45-52Wjwg {
  background-color: transparent;
  height: 53px;
  left: 0px;
  position: absolute;
  top: 6px;
  width: 759px;
}
.higher-committee-dashboard .group-56-y1HRxC-10-yu-s {
  background-color: var(--coconut);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  /* left: 11px;
  position: absolute;
  top: 10px; */
  width: 715px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 10px 12px;
}
.higher-committee-dashboard .group-AvulH5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.higher-committee-dashboard .group-TNKQzV {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.higher-committee-dashboard .group-YrhztS {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.higher-committee-dashboard .group-xtv2yH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.higher-committee-dashboard .help-24px-1-RH0WJ5 {
  background-color: transparent;
  height: 24px;
  left: 16%;
  overflow: hidden;
  position: absolute;
  top: 477px;
  width: 24px;
}
.higher-committee-dashboard .historytoggleoff-24px-1-RH0WJ5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 16%;
  overflow: hidden;
  position: absolute;
  top: 345px;
  width: 24px;
}
.higher-committee-dashboard .issue-status-WxqicF {
  background-color: transparent;
  height: auto;
  left: 357px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 123px;
}
.higher-committee-dashboard .line-5-8nYYUY {
  background-color: transparent;
  height: 94px;
  left: 0px;
  position: absolute;
  top: -0px;
  width: 182px;
}
.higher-committee-dashboard .line-6-8nYYUY {
  background-color: transparent;
  height: 96px;
  left: -1px;
  position: absolute;
  top: -2px;
  width: 184px;
}
.higher-committee-dashboard .list-of-current-issues-0xy0vn {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 22px;
  margin-top: 43px;
  min-height: 26px;
  position: relative;
  width: 738px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.higher-committee-dashboard .logout-RH0WJ5 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 675px;
  width: auto;
}
.higher-committee-dashboard .next-y1HRxC {
  background-color: transparent;
  height: auto;
  left: 689px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}
.higher-committee-dashboard .notifications-24px-2-1-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-left: 57px;
  margin-top: 19px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.higher-committee-dashboard .overlap-group-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 575px;
  margin-left: 22px;
  margin-top: 22px;
  position: relative;
  width: 750px;
}
.higher-committee-dashboard .overlap-group1-C61RwL-2323 {
  background-color: transparent;
  flex-shrink: 1;
  height: 100%;
  overflow: hidden;
  position: fixed;
  min-width: 190px;
  width: 18.7%;
}
.higher-committee-dashboard .overlap-group2-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 59px;
  margin-left: 22px;
  margin-top: 21px;
  position: relative;
  width: 759px;
}
.higher-committee-dashboard .payments-RH0WJ5 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 610px;
  width: auto;
}
.higher-committee-dashboard .previous-issues-RH0WJ5 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 346px;
  width: auto;
}
.higher-committee-dashboard .rectangle-1-RH0WJ5-232-3 {
  background-color: #1f2531;
  height: 100%;
  left: 0px;
  position: fixed;
  overflow: hidden;
  top: 0px;
  min-width: 190px;
  width: 18.7%;
}
.higher-committee-dashboard .rectangle-20-WxqicF-chn-p-t {
  background-color: #dc3545;
  border-radius: 29px;
  height: 53px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}
.higher-committee-dashboard .rectangle-30-Uqx0kx {
  background-color: #dc3545;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 167px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 37vw;
}

.higher-committee-dashboard .rectangle-30-y1HRxC-in-chn-pw {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 575px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 12px;
}
.higher-committee-dashboard .rectangle-7-y1HRxC {
  background-color: transparent;
  height: 1px;
  left: 247px;
  position: absolute;
  top: 242px;
  width: 1px;
}
.higher-committee-dashboard .s-no-WxqicF {
  background-color: transparent;
  height: auto;
  left: 24px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 71px;
}
.higher-committee-dashboard .school-24px-1-RH0WJ5 {
  background-color: transparent;
  height: 24px;
  left: 16%;
  overflow: hidden;
  position: absolute;
  top: 411px;
  width: 24px;
}
.higher-committee-dashboard .span0-70fs2O {
  color: var(--white);
  font-style: normal;
}
.higher-committee-dashboard .span0-mQtxrF {
  color: #dc3545;
  font-style: normal;
}
.higher-committee-dashboard .span0-nfPVjr {
  color: var(--white);
  font-style: normal;
  line-height: 14px;
}
.higher-committee-dashboard .span1-70fs2O {
  color: var(--white);
  font-style: normal;
  font-weight: 600;
  display: block;
  margin-top: 20px;
}
.higher-committee-dashboard .span1-mQtxrF {
  color: #dc3545;
  font-style: normal;
  font-weight: 600;
}
.higher-committee-dashboard .span1-nfPVjr {
  color: var(--white);
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}
.higher-committee-dashboard .span2-nfPVjr {
  color: var(--white);
  font-style: normal;
  line-height: 14px;
}
.higher-committee-dashboard .span3-nfPVjr {
  color: var(--white);
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}
.higher-committee-dashboard .span4-nfPVjr {
  color: var(--white);
  font-style: normal;
  line-height: 14px;
}
.higher-committee-dashboard .student-classroom-WxqicF {
  background-color: transparent;
  height: auto;
  left: 135px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 183px;
}
.higher-committee-dashboard .student-name-y1HRxC {
  background-color: transparent;
  height: auto;
  left: 112px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 118px;
}
.higher-committee-dashboard .students-teachers-RH0WJ5 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 412px;
  width: auto;
}
.higher-committee-dashboard .tickets-RH0WJ5 {
  background-color: transparent;
  color: rgba(179, 179, 179, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 478px;
  width: auto;
}
.higher-committee-dashboard .time-y1HRxC {
  background-color: transparent;
  height: auto;
  left: 702px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 48px;
}
.higher-committee-dashboard .vector-40xw7g {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.higher-committee-dashboard .vector-6nYyTO {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.higher-committee-dashboard .vector-8OGum8 {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.higher-committee-dashboard .vector-9xiDaP {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.higher-committee-dashboard .vector-BKsCJ0 {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.higher-committee-dashboard .vector-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 7px;
  position: relative;
  width: 80px;
}
.higher-committee-dashboard .vector-K3cCCV {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.higher-committee-dashboard .vector-OaTsHZ {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.higher-committee-dashboard .vector-QFWJMX {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.higher-committee-dashboard .vector-QXoq1a {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.higher-committee-dashboard .vector-RH0WJ5 {
  background-color: transparent;
  height: 24px;
  left: 16%;
  position: absolute;
  top: 279px;
  width: 24px;
}
.higher-committee-dashboard .vector-RYyMFx {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.higher-committee-dashboard .vector-aUE2K8 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.higher-committee-dashboard .vector-cr1ESP {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.higher-committee-dashboard .vector-dsPYCd {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.higher-committee-dashboard .vector-fOS6IR {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.higher-committee-dashboard .vector-g23mTr {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}

.higher-committee-dashboard .vector-uCfylM {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.higher-committee-dashboard .vector-x1fO3U {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.higher-committee-dashboard .vector-zNYIAN {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.higher-committee-dashboard .welcome-back-biba-Uqx0kx {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 10%;
  letter-spacing: 0px;
  position: absolute;
  top: 29px;
  width: 45%;
}
.higher-committee-dashboard .x1-y1HRxC {
  background-color: transparent;
  height: auto;
  left: 623px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}
.higher-committee-dashboard .x2-y1HRxC {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 645px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}
.higher-committee-dashboard .x3-y1HRxC {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 667px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}
.higher-committee-dashboard .x5834-1-Uqx0kx {
  background-color: transparent;
  object-fit: cover;
  width: 75%;
}
.higher-committee-dashboard .maindashimg {
  width: 40%;
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.higher-committee-dashboard .your-have-5-pen-mergency-issues-Uqx0kx {
  background-color: transparent;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 22px;
  letter-spacing: 0px;
  line-height: 30px;
  position: absolute;
  top: 76px;
  width: 327px;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-white-10px-z {
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --blue-zodiac-2: rgba(19, 36, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --crusta: rgba(249, 129, 49, 1);
  --deep-blue: rgba(18, 0, 120, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --nobel: rgba(179, 179, 179, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - higher-committee-dashboard-mobile */

.border-1px-blue-zodiac {
  border: 1px solid var(--blue-zodiac);
}
.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.higher-committee-dashboard-mobile {
  align-items: center;
  background-color: var(--coconut);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 922px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 9px;
  position: relative;
  width: 414px;
}
.higher-committee-dashboard-mobile .class-name-759J8x {
  background-color: transparent;
  height: auto;
  left: 167px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 48px;
}
.higher-committee-dashboard-mobile .component-1-dXBp89 {
  background-color: transparent;
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.higher-committee-dashboard-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.higher-committee-dashboard-mobile .dashboard-24px-1-Rbw72r {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 288px;
  overflow: hidden;
  padding: 0 5.3px;
  position: absolute;
  top: 0px;
  width: 11px;
}
.higher-committee-dashboard-mobile .date-759J8x {
  background-color: transparent;
  height: auto;
  left: 292px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 250px;
  width: 22px;
}
.higher-committee-dashboard-mobile .date-fx556c {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 261px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 13px;
  width: 39px;
}
.higher-committee-dashboard-mobile .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.higher-committee-dashboard-mobile .group-124-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 47px;
  width: 348px;
}
.higher-committee-dashboard-mobile .group-125-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 87px;
  width: 348px;
}
.higher-committee-dashboard-mobile .group-126-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 127px;
  width: 348px;
}
.higher-committee-dashboard-mobile .group-127-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 167px;
  width: 348px;
}
.higher-committee-dashboard-mobile .group-127-C61RwL {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 40px;
  min-height: 605px;
  position: relative;
  width: 379px;
}
.higher-committee-dashboard-mobile .group-128-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 207px;
  width: 348px;
}
.higher-committee-dashboard-mobile .group-129-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 247px;
  width: 348px;
}
.higher-committee-dashboard-mobile .group-130-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 287px;
  width: 348px;
}
.higher-committee-dashboard-mobile .group-131-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 327px;
  width: 348px;
}
.higher-committee-dashboard-mobile .group-132-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 367px;
  width: 348px;
}
.higher-committee-dashboard-mobile .group-133-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 407px;
  width: 348px;
}
.higher-committee-dashboard-mobile .group-134-759J8x {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 447px;
  width: 348px;
}
.higher-committee-dashboard-mobile .group-14-4eduM0 {
  background-color: transparent;
  height: 144px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 361px;
}
.higher-committee-dashboard-mobile .group-45-Rbw72r {
  background-color: transparent;
  height: 42px;
  left: 0px;
  position: absolute;
  top: 6px;
  width: 369px;
}
.higher-committee-dashboard-mobile .group-56-759J8xp-9392 {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  /* left: 6px;
  position: absolute;
  top: 7px; */
  width: 345px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
}
.higher-committee-dashboard-mobile .issue-status-fx556c {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 163px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
  width: 83px;
}
.higher-committee-dashboard-mobile .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.higher-committee-dashboard-mobile .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.higher-committee-dashboard-mobile .list-of-current-issues-2zqKZg {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 10px;
  min-height: 26px;
  position: relative;
  text-align: left;
  width: 308px;
}
.higher-committee-dashboard-mobile .next-759J8x {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 322px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 31px;
}
.higher-committee-dashboard-mobile .overlap-group-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 144px;
  margin-right: 1px;
  margin-top: 34px;
  position: relative;
  width: 361px;
}
.higher-committee-dashboard-mobile .overlap-group1-2zqKZg {
  background-color: transparent;
  flex-shrink: 1;
  height: 511px;
  margin-left: -0px;
  margin-top: 7px;
  position: relative;
  width: 361px;
}
.higher-committee-dashboard-mobile .overlap-group2-2zqKZg {
  background-color: transparent;
  flex-shrink: 1;
  height: 48px;
  margin-left: 0px;
  margin-top: 13px;
  position: relative;
  width: 100%;
}
.higher-committee-dashboard-mobile .rectangle-20-fx556c-intext-5 {
  background-color: #dc3545;
  border-radius: 29px;
  height: 42px;
  left: 2%;
  position: absolute;
  top: 0px;
  width: 98%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
}
.higher-committee-dashboard-mobile .rectangle-30-759J8x-9392 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 511px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 385px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.higher-committee-dashboard-mobile .rectangle-30-Yw5tgq {
  background-color: #dc3545;
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 144px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.higher-committee-dashboard-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.higher-committee-dashboard-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.higher-committee-dashboard-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.higher-committee-dashboard-mobile .s-no-759J8x {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 27px;
}
.higher-committee-dashboard-mobile .s-no-fx556c {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 13px;
  width: 49px;
}
.higher-committee-dashboard-mobile .span0-8s1sGx {
  color: var(--white);
  font-style: normal;
  line-height: 10px;
}
.higher-committee-dashboard-mobile .span0-I9MZRq {
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.higher-committee-dashboard-mobile .span1-8s1sGx {
  color: var(--white);
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
}
.higher-committee-dashboard-mobile .span1-I9MZRq {
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-left: 113px;
}
.higher-committee-dashboard-mobile .span2-8s1sGx {
  color: var(--white);
  font-style: normal;
  line-height: 10px;
}
.higher-committee-dashboard-mobile .span3-8s1sGx {
  color: var(--white);
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
}
.higher-committee-dashboard-mobile .span4-8s1sGx {
  color: var(--white);
  font-style: normal;
  line-height: 10px;
}
.higher-committee-dashboard-mobile .student-classroom-fx556c {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 68px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 8px;
  width: 89px;
}
.higher-committee-dashboard-mobile .student-name-759J8x {
  background-color: transparent;
  height: auto;
  left: 62px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 56px;
}
.higher-committee-dashboard-mobile .time-759J8x {
  background-color: transparent;
  height: auto;
  left: 337px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 23px;
}
.higher-committee-dashboard-mobile .time-fx556c {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 308px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 13px;
  width: 46px;
}
.higher-committee-dashboard-mobile .vector-IrPvqm {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.higher-committee-dashboard-mobile .welcome-back-biba-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: 39px;
  position: absolute;
  top: 34px;
  width: 148px;
}
.higher-committee-dashboard-mobile .x1-759J8x {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 7px;
}
.higher-committee-dashboard-mobile .x2-759J8x {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 292px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 9px;
}
.higher-committee-dashboard-mobile .x3-759J8x {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 307px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 9px;
}
.higher-committee-dashboard-mobile .x5834-1-4eduM0 {
  background-color: transparent;
  height: 103px;
  left: 220px;
  object-fit: cover;
  position: absolute;
  top: 17px;
  width: 116px;
}
.higher-committee-dashboard-mobile .your-have-5-pen-mergency-issues-Yw5tgq {
  background-color: transparent;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  top: 72px;
  width: 183px;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-white-20px {
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --blue-zodiac-2: rgba(19, 36, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --crusta: rgba(249, 129, 49, 1);
  --deep-blue: rgba(18, 0, 120, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --nobel: rgba(179, 179, 179, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .higher-committee-dashboard {
    display: none;
  }

  .payga4wk-z-bshl-Sgr7kw-123 {
    text-align: center;
    min-width: 25px;
  }
  .x499900-Sgr7kw-123 {
    text-align: left;
    min-width: 68px;
  }
  .x07-dec-2020-Sgr7kw-123 {
    text-align: left;
    min-width: 35px;
  }
  .x014009-pm-Sgr7kw-123 {
    text-align: center;
    min-width: 48px;
  }
  .andb586-Sgr7kw-123 {
    text-align: center;
    min-width: 47px;
  }

  .fnaj-sdnj23j11 {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0px 8px 0px 2px;
    overflow-y: auto;
  }
}
@media screen and (min-width: 600px) {
  .higher-committee-dashboard-mobile {
    display: none;
  }
}
.higher-committee-dashboard .main-container {
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

@media screen and (max-width: 992px) {
  .higher-committee-dashboard {
    height: 1100px;
  }
  .higher-committee-dashboard .flex-col-C61RwL-872 {
    margin-left: 0;
    left: 200px;
    width: calc(100% - 200px);
  }
  .higher-committee-dashboard .group-14-GyJUNH {
    width: 20vw;
  }
  .higher-committee-dashboard .rectangle-30-Uqx0kx {
    width: 20vw;
  }
  .higher-committee-dashboard .maindashimg {
    display: none;
  }
}
@media screen and (max-width: 1176px) {
  .higher-committee-dashboard .list-of-current-issues-0xy0vn {
    width: 65vw;
  }
  .higher-committee-dashboard .overlap-group2-0xy0vn {
    width: 65vw;
    min-width: 540px;
  }
  .higher-committee-dashboard .overlap-group-0xy0vn {
    width: 65vw;
    min-width: 540px;
  }
  .higher-committee-dashboard .group-56-y1HRxC-10-yu-s {
    width: 61vw;
    min-width: 516px;
  }
}
