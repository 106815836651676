/* screen - teacher-quiz-detail */

.border-1px-blue-zodiac {
  border: 1px solid var(--blue-zodiac);
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-quiz-detail {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.teacher-quiz-detail .accountbalance-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-detail .add-KKOYJf {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 17.59px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 37px;
}
.teacher-quiz-detail .add-cfQi7W {
  background-color: transparent;
  height: auto;
  left: 316px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-quiz-detail .add-delete-ePioxc {
  background-color: transparent;
  height: auto;
  left: 287px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 87px;
}
.teacher-quiz-detail .analytics-24px-3-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-detail .arrowback-24px-1-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-quiz-detail .historytoggleoff-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-quiz-detail .quiz-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-quiz-detail .poweroff-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 100px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-quiz-detail .stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-quiz-detail .assignment-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-detail .assignment-24px-3-wx3HpW {
  background-color: transparent;
  flex-shrink: 1;
  height: 26px;
  overflow: hidden;
  position: relative;
  width: 26px;
}
.teacher-quiz-detail .biba-sobti-h8V8ZR {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-quiz-detail .bibasobtigmailcom-h8V8ZR {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-quiz-detail .booked-IxVOPE {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 53px;
}
.teacher-quiz-detail .booked-ePioxc {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 53px;
}
.teacher-quiz-detail .childs-name-i661174214828-TloosV {
  background-color: transparent;
  color: rgba(179, 179, 179, 1);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 12px;
  position: relative;
  text-align: left;
  width: 164px;
}
.teacher-quiz-detail .class-name-ePioxc {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 93px;
}
.teacher-quiz-detail .completed-ePioxc {
  background-color: transparent;
  height: auto;
  left: 200px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 79px;
}
.teacher-quiz-detail .component-1-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.teacher-quiz-detail .component-18-PXwdVf {
  align-items: flex-start;
  background-color: transparent;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: flex-end;
  left: 34%;
  padding: 0;
  position: absolute;
  resize: none;
  min-width: 120px;
  top: 244px;
  width: 27%;
  padding-left: 15px;
}
.teacher-quiz-detail .component-29-DBfxBL {
  background-color: transparent;
  background-image: url(./img/ellipse-4-1@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-quiz-detail .component-29-eRDTVr {
  align-items: center;
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 53px;
  justify-content: flex-start;
  left: 25px;
  min-width: 53px;
  padding: 0 13.2px;
  position: absolute;
  top: 17px;
  width: auto;
}
.teacher-quiz-detail .description-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-detail .ellipse-2-RYyMFx {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.teacher-quiz-detail .feb-18-2021-xQuxwr {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 116px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 113px;
}
.teacher-quiz-detail .flex-col-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 5%;
  margin-top: 45px;
  min-height: 928px;
  position: relative;
  width: 99%;
}
.teacher-quiz-detail .flex-col-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 546px;
  position: relative;
  margin-left: 4%;
  width: 48%;
}
.teacher-quiz-detail .flex-col-vgli2x {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.teacher-quiz-detail .flex-row-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 105px;
  justify-content: space-between;
  position: relative;
  width: 90%;
}
.teacher-quiz-detail .flex-row-WcGkdi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.teacher-quiz-detail .flex-row-oV5UrE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 763px;
  justify-content: flex-start;
  margin-left: 17px;
  margin-top: 60px;
  position: relative;
  width: 100%;
}
.teacher-quiz-detail .flex-row-xL4dLV {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 23px;
  justify-content: flex-start;
  min-width: 377px;
  position: relative;
  width: auto;
}
.teacher-quiz-detail .grade-quiz-eRDTVr {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 42%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 208px;
  width: auto;
}
.teacher-quiz-detail .group-112-xL4dLV {
  background-color: transparent;
  flex-shrink: 1;
  height: 726px;
  margin-top: 14px;
  position: relative;
  width: 403px;
}
.teacher-quiz-detail .group-113-eLYKxH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 763px;
  position: relative;
  width: 387px;
}
.teacher-quiz-detail .group-114-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 763px;
  justify-content: flex-start;
  margin-left: 7%;
  min-width: 383px;
  position: relative;
  width: auto;
}
.teacher-quiz-detail .group-160-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 88px;
  min-width: 24px;
  position: relative;
  width: auto;
}
.teacher-quiz-detail .group-162-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 778px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.teacher-quiz-detail .group-163-L8UHM8 {
  background-color: #1f2531;
  height: 1024px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 63px;
}
.teacher-quiz-detail .group-164-L8UHM8 {
  background-color: transparent;
  height: 778px;
  left: 20px;
  position: absolute;
  top: 200px;
  width: 24px;
}
.teacher-quiz-detail .group-165-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 1024px;
  position: relative;
  width: 63px;
}
.teacher-quiz-detail .group-167-1UTYTe {
  background-color: transparent;
  height: 36px;
  left: 25px;
  position: absolute;
  top: 16px;
  width: 36px;
}
.teacher-quiz-detail .group-22-GyJUNH {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-right: 4%;
  margin-top: 6px;
  min-width: 316px;
  position: relative;
  width: auto;
}
.teacher-quiz-detail .group-46-PXwdVf {
  background-color: transparent;
  height: 346px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-quiz-detail .group-47-1UTYTe {
  background-color: transparent;
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-quiz-detail .group-90-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 174px;
  width: 369px;
}
.teacher-quiz-detail .group-91-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 371px;
}
.teacher-quiz-detail .group-92-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 369px;
}
.teacher-quiz-detail .group-93-ePioxc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 11px;
  min-height: 262px;
  position: absolute;
  top: 133px;
  width: 371px;
}
.teacher-quiz-detail .group-94-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 369px;
}
.teacher-quiz-detail .group-95-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 369px;
}
.teacher-quiz-detail .group-96-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 369px;
}
.teacher-quiz-detail .group-97-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 587px;
  width: 369px;
}
.teacher-quiz-detail .group-98-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 646px;
  width: 369px;
}
.teacher-quiz-detail .group-9VbO77 {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  position: relative;
  width: 20px;
}
.teacher-quiz-detail .group-FHHSuW {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-quiz-detail .group-UKbWOd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.teacher-quiz-detail .group-alxHTq {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-quiz-detail .group-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: flex-start;
  left: 0px;
  min-width: 20px;
  position: absolute;
  top: 448px;
  width: auto;
}
.teacher-quiz-detail .group-tQmilE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.teacher-quiz-detail .help-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-detail .historytoggleoff-24px-1-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-detail .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.teacher-quiz-detail .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.teacher-quiz-detail .line-8-PXwdVf {
  background-color: transparent;
  height: 1px;
  left: 0;
  position: absolute;
  top: 100px;
  width: 100%;
}
.teacher-quiz-detail .mathematics-dr-yashna-sharma-eRDTVr {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 41px;
  width: 80%;
}
.teacher-quiz-detail .notifications-24px-2-1-GyJUNH {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-bottom: 13px;
  margin-left: 641px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.teacher-quiz-detail .open-calendar-xQuxwr {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.teacher-quiz-detail .opened-C2l7Eu {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-quiz-detail .opened-FwJWKX {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-quiz-detail .opened-G1xvhx {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-quiz-detail .opened-MZRcus {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-quiz-detail .opened-XCjVcD {
  background-color: transparent;
  height: auto;
  left: 304px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 53px;
}
.teacher-quiz-detail .opened-hUx6Ho {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-quiz-detail .opened-oOO7Ym {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-quiz-detail .organic-4-ePioxc {
  background-color: transparent;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 67px;
}
.teacher-quiz-detail .organic-5-Hx49NZ {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 67px;
}
.teacher-quiz-detail .overlap-group-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 346px;
  margin-top: 56px;
  position: relative;
  width: 100%;
}
.teacher-quiz-detail .overlap-group-KKOYJf {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 365px;
}
.teacher-quiz-detail .overlap-group1-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-top: 53px;
  position: relative;
  width: 100%;
}
.teacher-quiz-detail .quiz-1-eRDTVr {
  background-color: transparent;
  color: #dc3545;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 80%;
}
.teacher-quiz-detail .rectangle-31-ePioxc {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 726px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.teacher-quiz-detail .rectangle-40-ePioxc {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.teacher-quiz-detail .rectangle-40-eRDTVr {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 346px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-quiz-detail .rectangle-40-pjV5k8 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-quiz-detail .rectangle-41-ePioxc {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.teacher-quiz-detail .rectangle-43-eRDTVr {
  background-color: #dc3545;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 34.4%;
  position: absolute;
  top: 291px;
  width: 25.6%;
  min-width: 70px;
}
.teacher-quiz-detail .rectangle-44-C2l7Eu {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-detail .rectangle-44-FwJWKX {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-detail .rectangle-44-G1xvhx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-detail .rectangle-44-MZRcus {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-detail .rectangle-44-eRDTVr {
  /* background-color: #dc3545; */
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  left: 50%;
  position: absolute;
  top: 114px;
  width: 21%;
}
.teacher-quiz-detail .rectangle-44-hUx6Ho {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-detail .rectangle-44-oOO7Ym {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-detail .rectangle-47-ePioxc {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.teacher-quiz-detail .rectangle-48-Hx49NZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-detail .rectangle-49-XCjVcD {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-detail .rectangle-50-cfQi7W {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-detail .rectangle-7-PXwdVf {
  background-color: transparent;
  height: 1px;
  left: 475px;
  position: absolute;
  top: 221px;
  width: 1px;
}
.teacher-quiz-detail .school-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-detail .status-ePioxc {
  background-color: transparent;
  height: auto;
  left: 208px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.teacher-quiz-detail .stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-detail .submit-eRDTVr {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 310px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 300px;
  width: auto;
}
.teacher-quiz-detail .time-ePioxc {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.teacher-quiz-detail .ungraded-eRDTVr {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  right: 8%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 27px;
  width: auto;
}
.teacher-quiz-detail .upcoming-Hx49NZ {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 76px;
}
.teacher-quiz-detail .vatsal-agarwal-pjV5k8 {
  background-color: transparent;
  color: black;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 25px;
  width: 100%;
}
.teacher-quiz-detail .vector-3ahjCQ {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-quiz-detail .vector-40xw7g {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.teacher-quiz-detail .vector-56AgMz {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.teacher-quiz-detail .vector-8k85HE {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 170px;
  position: relative;
  width: 24px;
}
.teacher-quiz-detail .vector-90KxxC {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-quiz-detail .vector-9xgjqN {
  background-color: transparent;
  height: 14px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.teacher-quiz-detail .vector-B8xRgA {
  background-color: transparent;
  height: 16px;
  left: 0px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-quiz-detail .vector-BKwYFx {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.teacher-quiz-detail .vector-DBfxBL {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-quiz-detail .vector-EaMiW0 {
  background-color: transparent;
  height: 20px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 18px;
}
.teacher-quiz-detail .vector-H1IJdQ {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-quiz-detail .vector-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-quiz-detail .vector-KeQs0d {
  background-color: transparent;
  height: 1px;
  left: 13px;
  position: absolute;
  top: 13px;
  width: 1px;
}
.teacher-quiz-detail .vector-RYyMFx {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.teacher-quiz-detail .vector-SCg0eG {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  position: relative;
  width: 24px;
}
.teacher-quiz-detail .vector-SIAzDu {
  background-color: transparent;
  height: 20px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 16px;
}
.teacher-quiz-detail .vector-TCNT1O {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-detail .vector-UgkvXI {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-detail .vector-WcGkdi {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.teacher-quiz-detail .vector-XV2kdn {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.teacher-quiz-detail .vector-Z4cObr {
  background-color: transparent;
  height: 23px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 21px;
}
.teacher-quiz-detail .vector-dlRKJA {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-quiz-detail .vector-fPPgx3 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-detail .vector-grLYV9 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-detail .vector-iniHBi {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-detail .vector-jWA1w5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.teacher-quiz-detail .vector-kCLhu1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-detail .vector-mju0OS {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-quiz-detail .vector-oRqxWx {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-quiz-detail .vector-s2Y5x6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.teacher-quiz-detail .vector-vgli2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.teacher-quiz-detail .vector-xbu9jm {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.teacher-quiz-detail .vector-xxy9YX {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-detail .vector-yQhZ26 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-detail .view-quiz-eRDTVr {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  left: 24%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  /* text-align: center; */
  top: 114px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  width: 21%;
}
.teacher-quiz-detail .view-submission-eRDTVr {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 382px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  /* text-align: left; */
  top: 123px;
  width: auto;
}
.teacher-quiz-detail .x10-marks-eRDTVr {
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: auto;
}
.teacher-quiz-detail .x10pm-C2l7Eu {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 45px;
}
.teacher-quiz-detail .x12pm-ePioxc {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 47px;
}
.teacher-quiz-detail .x1pm-ePioxc {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 133px;
  width: 33px;
}
.teacher-quiz-detail .x2pm-XCjVcD {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 39px;
}
.teacher-quiz-detail .x3pm-Hx49NZ {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 31px;
}
.teacher-quiz-detail .x4pm-G1xvhx {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-quiz-detail .x5pm-cfQi7W {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-quiz-detail .x6pm-hUx6Ho {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-quiz-detail .x7pm-MZRcus {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-quiz-detail .x8pm-FwJWKX {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-quiz-detail .x9pm-oOO7Ym {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - teacher-quiz-detail-mobile */

/* .border-1px-blue-zodiac {
  border: 1px solid var(--blue-zodiac);
} */
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-quiz-detail-mobile {
  align-items: center;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 1338px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px;
  position: relative;
  width: 414px;
}
.teacher-quiz-detail-mobile .add-5JRWGh {
  background-color: transparent;
  height: auto;
  left: 283px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-detail-mobile .add-delete-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 264px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 7px;
  width: 92px;
}
.teacher-quiz-detail-mobile .add-zsqqLF {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 30.24px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 35px;
}
.teacher-quiz-detail-mobile .booked-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 285px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 235px;
  width: 50px;
}
.teacher-quiz-detail-mobile .booked-BJQsbv {
  background-color: transparent;
  height: auto;
  left: 285px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 54px;
  width: 50px;
}
.teacher-quiz-detail-mobile .class-name-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 79px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 7px;
  width: 94px;
}
.teacher-quiz-detail-mobile .completed-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 185px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 54px;
  width: 74px;
}
.teacher-quiz-detail-mobile .component-1-dXBp89 {
  background-color: transparent;
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.teacher-quiz-detail-mobile .component-29-3nzxAx {
  background-color: transparent;
  background-image: url(./img/ellipse-4-2@2x.svg);
  background-size: 100% 100%;
  height: 23px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 23px;
}
.teacher-quiz-detail-mobile .component-29-r38EO5 {
  background-color: transparent;
  background-image: url(./img/ellipse-4-3@2x.svg);
  background-size: 100% 100%;
  height: 34px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 34px;
}
.teacher-quiz-detail-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.teacher-quiz-detail-mobile .feb-18-2021-BdpSxK {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 87px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 117px;
}
.teacher-quiz-detail-mobile .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacher-quiz-detail-mobile .flex-row-VMr6Om {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  min-height: 23px;
  justify-content: flex-start;
  margin-right: 3px;
  margin-top: 87px;
  min-width: 353px;
  position: relative;
  width: auto;
}
.teacher-quiz-detail-mobile .grade-quiz-VBdSDa {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 145px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 149px;
  width: 75px;
}
.teacher-quiz-detail-mobile .group-155-bB7yjR {
  background-color: transparent;
  height: 242px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 375px;
}
.teacher-quiz-detail-mobile .group-156-RH0WJ5 {
  background-color: transparent;
  height: 242px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-detail-mobile .group-177-RH0WJ5 {
  background-color: transparent;
  height: 34px;
  left: 8px;
  position: absolute;
  top: 14px;
  width: 34px;
}
.teacher-quiz-detail-mobile .group-184-4a9k2Y {
  background-color: transparent;
  height: 23px;
  left: 8px;
  position: absolute;
  top: 6px;
  width: 23px;
}
.teacher-quiz-detail-mobile .group-46-4a9k2Y {
  background-color: transparent;
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 361px;
}
.teacher-quiz-detail-mobile .group-90-4eduM0 {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 159px;
  width: 346px;
}
.teacher-quiz-detail-mobile .group-91-4eduM0 {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 218px;
  width: 348px;
}
.teacher-quiz-detail-mobile .group-92-4eduM0 {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 276px;
  width: 346px;
}
.teacher-quiz-detail-mobile .group-93-4eduM0 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 10px;
  min-height: 262px;
  position: absolute;
  top: 117px;
  width: 348px;
}
.teacher-quiz-detail-mobile .group-94-4eduM0 {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 394px;
  width: 346px;
}
.teacher-quiz-detail-mobile .group-95-4eduM0 {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 453px;
  width: 346px;
}
.teacher-quiz-detail-mobile .group-96-4eduM0 {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 512px;
  width: 346px;
}
.teacher-quiz-detail-mobile .group-97-4eduM0 {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 571px;
  width: 346px;
}
.teacher-quiz-detail-mobile .group-98-4eduM0 {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 630px;
  width: 346px;
}
.teacher-quiz-detail-mobile .group-EXxxX0 {
  background-color: transparent;
  flex-shrink: 1;
  height: 17px;
  position: relative;
  width: 17px;
}
.teacher-quiz-detail-mobile .group-r38EO5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 17px;
  justify-content: flex-start;
  left: 9px;
  min-width: 17px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-quiz-detail-mobile .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.teacher-quiz-detail-mobile .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.teacher-quiz-detail-mobile .line-8-bB7yjR {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 76px;
  width: 335px;
}
.teacher-quiz-detail-mobile .mathematics-dr-yashna-sharma-VBdSDa {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 53px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 34px;
  width: 176px;
}
.teacher-quiz-detail-mobile .open-calendar-BdpSxK {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.teacher-quiz-detail-mobile .opened-KI2gln {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.teacher-quiz-detail-mobile .opened-TFPExT {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-detail-mobile .opened-TI8W5K {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-detail-mobile .opened-ZXZpfs {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-detail-mobile .opened-bsQU13 {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-detail-mobile .opened-r8LzD0 {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-detail-mobile .opened-xX7jhM {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-detail-mobile .organic-4-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 89px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 54px;
  width: 69px;
}
.teacher-quiz-detail-mobile .organic-5-fxyx1r {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.teacher-quiz-detail-mobile .overlap-group-C61RwL {
  align-self: flex-start;
  background-color: transparent;
  flex-shrink: 1;
  height: 689px;
  margin-top: 26px;
  position: relative;
  width: 359px;
}
.teacher-quiz-detail-mobile .overlap-group1-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 242px;
  margin-right: 3px;
  margin-top: 97px;
  position: relative;
  width: 359px;
}
.teacher-quiz-detail-mobile .overlap-group2-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-right: 1px;
  margin-top: 56px;
  position: relative;
  width: 361px;
}
.teacher-quiz-detail-mobile .overlap-group3-zsqqLF {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 342px;
}
.teacher-quiz-detail-mobile .quiz-1-VBdSDa {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 53px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 197px;
}
.teacher-quiz-detail-mobile .rectangle-31-4eduM0 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 689px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-detail-mobile .rectangle-40-4eduM0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 9px;
  position: absolute;
  top: 41px;
  width: 342px;
}
.teacher-quiz-detail-mobile .rectangle-40-6x6k20 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-detail-mobile .rectangle-40-VBdSDa {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 250px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-detail-mobile .rectangle-41-4eduM0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 8px;
  position: absolute;
  top: 159px;
  width: 342px;
}
.teacher-quiz-detail-mobile .rectangle-43-VBdSDa {
  background-color: #dc3545;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 131px;
  position: absolute;
  top: 196px;
  width: 87px;
}
.teacher-quiz-detail-mobile .rectangle-44-TFPExT {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-detail-mobile .rectangle-44-TI8W5K {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-detail-mobile .rectangle-44-VBdSDa {
  /* background-color: #dc3545; */
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 186px;
  position: absolute;
  top: 89px;
  width: 82px;
}
.teacher-quiz-detail-mobile .rectangle-44-ZXZpfs {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-detail-mobile .rectangle-44-bsQU13 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-detail-mobile .rectangle-44-r8LzD0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-detail-mobile .rectangle-44-xX7jhM {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-detail-mobile .rectangle-47-4eduM0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 9px;
  position: absolute;
  top: 100px;
  width: 342px;
}
.teacher-quiz-detail-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.teacher-quiz-detail-mobile .rectangle-48-fxyx1r {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-detail-mobile .rectangle-49-KI2gln {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-detail-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-quiz-detail-mobile .rectangle-50-5JRWGh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-detail-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-quiz-detail-mobile .status-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 192px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 7px;
  width: 49px;
}
.teacher-quiz-detail-mobile .submit-VBdSDa {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 161px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 206px;
  width: 42px;
}
.teacher-quiz-detail-mobile .submitted-VBdSDa {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 290px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 59px;
}
.teacher-quiz-detail-mobile .time-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 16px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 7px;
  width: 42px;
}
.teacher-quiz-detail-mobile .upcoming-fxyx1r {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.teacher-quiz-detail-mobile .vatsal-agarwal-6x6k20 {
  background-color: transparent;
  color: black;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 43px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 140px;
}
.teacher-quiz-detail-mobile .vector-3nzxAx {
  background-color: transparent;
  height: 13px;
  left: 4px;
  position: absolute;
  top: 5px;
  width: 15px;
}
.teacher-quiz-detail-mobile .vector-LiCmgR {
  background-color: transparent;
  height: 15px;
  left: 3px;
  position: absolute;
  top: -1px;
  width: 15px;
}
.teacher-quiz-detail-mobile .vector-QkzUeN {
  background-color: transparent;
  height: 15px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 15px;
}
.teacher-quiz-detail-mobile .view-quiz-VBdSDa {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 104px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 94px;
  width: 59px;
}
.teacher-quiz-detail-mobile .view-submission-VBdSDa {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 203px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 94px;
  width: 50px;
}
.teacher-quiz-detail-mobile .x10-marks-VBdSDa {
  background-color: transparent;
  color: rgba(0, 0, 0, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 53px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 58px;
  width: 46px;
}
.teacher-quiz-detail-mobile .x10pm-TI8W5K {
  background-color: transparent;
  height: auto;
  left: 7px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 42px;
}
.teacher-quiz-detail-mobile .x12pm-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 18px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 54px;
  width: 44px;
}
.teacher-quiz-detail-mobile .x1pm-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 18px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 117px;
  width: 31px;
}
.teacher-quiz-detail-mobile .x2pm-KI2gln {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.teacher-quiz-detail-mobile .x3pm-fxyx1r {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.teacher-quiz-detail-mobile .x4pm-r8LzD0 {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-detail-mobile .x5pm-5JRWGh {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-detail-mobile .x6pm-xX7jhM {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-detail-mobile .x7pm-bsQU13 {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-detail-mobile .x8pm-ZXZpfs {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-detail-mobile .x9pm-TFPExT {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .teacher-quiz-detail {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .teacher-quiz-detail-mobile {
    display: none;
  }
}
