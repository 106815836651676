/* screen - student-doubts-t */

.border-1px-black-2 {
  border: 1px solid var(--black-2);
}

.montserrat-medium-black-18px {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gray-7px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-white-10px {
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-niagara-8px {
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}

.student-doubts-t {
  background-color: var(--coconut);
  height: 100vh;
  margin: 0px;
  min-height: 1024px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
}

.student-doubts-t .active-B6ZBuj {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts-t .analytics-24px-3-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 644px;
  width: 24px;
}

.student-doubts-t .assignment-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 348px;
  width: 24px;
}

.student-doubts-t .biba-sobti-B6ZBuj {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts-t .biba-sobti-VdQhAS {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 92px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}

.student-doubts-t .biba-sobti-pqE4mo {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 22px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 133px;
  width: auto;
}

.student-doubts-t .bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 47px;
  width: auto;
}

.student-doubts-t .flex-row-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 105px;
  justify-content: space-between;
  position: relative;
  width: 90%;
  padding-right: 5%;
  margin: 44px 0 0 4%;
}

.student-doubts-t .component-1-GyJUNH {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}

.student-doubts-t .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}

.student-doubts-t .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}

.student-doubts-t .group-22-GyJUNH {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  margin-top: 6px;
  min-width: 316px;
  position: relative;
  width: auto;
}

.student-doubts-t .flex-col-vgli2x {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}

.student-doubts-t .biba-sobti-h8V8ZR {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}

.student-doubts-t .bibasobtigmailcom-h8V8ZR {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}

.student-doubts-t .vector-vgli2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}

.student-doubts-t .flex-col-1234 {
  position: absolute;
  left: 63px;
  top: 0;
  width: 95%;
}

.student-doubts-t .row-1-2 {
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  margin: 20px 0px;
}

.student-doubts-t .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;
  left: 123px;
  position: absolute;
  top: 45px;
  width: 212px;
}

.student-doubts-t .component-4-B6ZBuj {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 92px;
  width: 100%;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.student-doubts-t .component-4-B6ZBuj .doubt-name-status {
  display: flex;
  justify-content: space-between;
}

.student-doubts-t .component-4-B6ZBuj .doubt-text-expand {
  display: flex;
  justify-content: space-between;
}

.student-doubts-t .component-4-pqE4mo {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 92px;
  left: 22px;
  position: absolute;
  top: 16px;
  width: 1097px;
}

.student-doubts-t .doubt-card {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 206px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 10px 24px;
  justify-content: space-evenly;
}

.student-doubts-t .doubt-card .doubt-post-button {
  width: 120px;
  align-self: flex-end;
}

.student-doubts-t .component-5-B6ZBuj {
  background-color: transparent;
  height: 276px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 1151px;
}

.student-doubts-t .component-5-pqE4mo {
  background-color: transparent;
  height: 276px;
  left: -5px;
  position: absolute;
  top: -5px;
  width: 1151px;
}

.student-doubts-t .create-doubt-j57GY0 {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;

  width: auto;
}

.student-doubts-t .description-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 496px;
  width: 24px;
}

.student-doubts-t .doubt-text-C61RwL {
  background-color: transparent;
  height: auto;
  left: 177px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 205px;
  width: auto;
}

.student-doubts-t .ellipse-2-OSIBbx {
  background-color: transparent;
  height: 19.05%;
  left: 57.14%;
  position: absolute;
  top: 21.43%;
  width: 19.05%;
}

.student-doubts-t .expand-B6ZBuj {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts-t .expand-pqE4mo {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 1047px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 78px;
  width: auto;
}
/* 
.student-doubts-t .filter-VAhwTv {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
} */

.student-doubts-t .grade-C61RwL {
  background-color: transparent;
  height: auto;
  left: 182px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 282px;
  width: auto;
}

.student-doubts-t .grade-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 182px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 286px;
  width: auto;
}

.student-doubts-t .group-0M78Jh {
  background-color: transparent;
  background-image: url(./img/vector-33@2x.png);
  background-size: 100% 100%;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-doubts-t .group-160-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  position: absolute;
  top: 718px;
  width: 24px;
}

.student-doubts-t .group-161-C61RwL {
  background-color: transparent;
  height: 32px;
  left: 1142px;
  position: absolute;
  top: 284px;
  width: 176px;
  text-align: center;
  padding: 8px;
}

.student-doubts-t .group-22-C61RwL {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}

.student-doubts-t .group-220-C61RwL-112w {
  background-color: transparent;
  width: 85%;
  height: 600px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-left: 7%;
}

.bhsks-snfasa-sd2 {
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 0 10%;
}

.Div-all-sdj32 {
  width: 95px;
  text-align: center;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #1f2531;
  background-color: #ffff;
  cursor: pointer;
}

.Class_giving_border {
  border-bottom: 4px solid #0a0a4f;
}

.student-doubts-t .group-221-C61RwL {
  background-color: transparent;
  height: 266px;
  left: 177px;
  position: absolute;
  top: 746px;
  width: 1151px;
}

.student-doubts-t .group-50-B6ZBuj {
  background-color: transparent;
  border: 0;
  height: 44px;
  padding-left: 10px;
  resize: none;
  width: 1099px;
}

.student-doubts-t .group-50-pqE4mo {
  background-color: transparent;
  border: 0;
  height: 44px;
  left: 22px;
  padding: 0;
  position: absolute;
  resize: none;
  top: 164px;
  width: 1099px;
}

.student-doubts-t .group-53-C61RwL-1001 {
  background-color: transparent;
  height: 38px;
  width: 15%;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
}

.student-doubts-t .group-55-B6ZBuj {
  background-color: transparent;
  height: 12.41%;
  left: 88.79%;
  position: absolute;
  top: 83.46%;
  width: 9.04%;
}

.student-doubts-t .group-56-C61RwL {
  background-color: transparent;
  height: 3.22%;
  left: 83.06%;
  position: absolute;
  top: 94.53%;
  width: 7.22%;
}

.student-doubts-t .group-5jt8cF {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-doubts-t .group-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 22px;
  position: absolute;
  top: 422px;
  width: 20px;
}

.student-doubts-t .group-NOXmfT {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}

.student-doubts-t .group-ce7Ifp {
  background-color: transparent;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 20px;
}

.student-doubts-t .help-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 570px;
  width: 24px;
}

.student-doubts-t .is-height-and-d-of-trignometry-pqE4mo {
  background-color: transparent;
  height: auto;
  left: 35px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 67px;
  width: auto;
}

.student-doubts-t .kunal-kashyap-pqE4mo {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 29px;
  width: auto;
}

.student-doubts-t .line-5-9p2GBv {
  background-color: transparent;
  height: 99.05%;
  left: 0.9%;
  position: absolute;
  top: 0.48%;
  width: 99.29%;
}

.student-doubts-t .line-6-9p2GBv {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}

.student-doubts-t .notifications-24px-2-1-C61RwL {
  background-color: transparent;
  height: 42px;
  left: 976px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 42px;
}

.student-doubts-t .post-xLrP3a {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 60.61%;
  left: 29.81%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21.21%;
  width: 37.5%;
}

.student-doubts-t .post-zA4C22 {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 60.61%;
  left: 29.81%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21.21%;
  width: 37.5%;
}

.student-doubts-t .rectangle-24-C61RwL {
  background-color: #1f2531;
  height: 1024px;
  position: absolute;
  top: 0px;
  width: 63px;
}

.student-doubts-t .rectangle-40-VAhwTv {
  background-color: transparent;
  height: 40px;
  left: -4px;
  position: absolute;
  top: 0px;
  width: 182px;
}

.student-doubts-t .rectangle-40-j57GY0 {
  background-color: transparent;
  height: 40px;
  left: -4px;
  position: absolute;
  top: 0px;
  width: 182px;
}

.student-doubts-t .rectangle-41-xLrP3a {
  background-color: var(--blue-zodiac);
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 98.08%;
}

.student-doubts-t .rectangle-41-zA4C22 {
  background-color: var(--blue-zodiac);
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 98.08%;
}

.student-doubts-t .rectangle-67-Fv0v2y {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 1097px;
}

.student-doubts-t .rectangle-67-gT7xxj {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 1097px;
}

.rectangle-68-C61RwL {
  background-color: var(--white);
  border-radius: 5px;
  border: 2px solid rgba(217, 83, 79, 0.4);
  font-family: "Montserrat", Helvetica;
  height: 24px;
  left: 265px;
  position: absolute;
  top: 282px;
  width: 151px;
}

.student-doubts-t .rectangle-69-C61RwL {
  background-color: var(--white);
  border-radius: 5px;
  border: 2px solid rgba(217, 83, 79, 0.4);
  font-family: "Montserrat", Helvetica;
  height: 24px;
  left: 565px;
  position: absolute;
  top: 282px;
  width: 175px;
}

.student-doubts-t .rectangle-7-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 630px;
  position: absolute;
  top: 631px;
  width: 1px;
}

.student-doubts-t .rectangle-70-C61RwL {
  background-color: var(--white);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 40px;
  width: 19%;
}

.student-doubts-t .rectangle-71-C61RwL {
  background-color: var(--white);
  border: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  height: 68px;
  left: 315px;
  padding: 8px;
  position: absolute;
  resize: none;
  top: 182px;
  width: 1003px;
}

.student-doubts-t .rectangle-72-C61RwL {
  /* background-color: var(--white); */
  border-radius: 5px;
  border: 2px solid rgba(217, 83, 79, 0.4);
  font-family: "Montserrat", Helvetica;
  position: absolute;
  height: 24px;
  left: 265px;

  top: 286px;
  width: 151px;
}

.student-doubts-t .rectangle-73-C61RwL {
  background-color: var(--white);
  border-radius: 5px;
  border: 2px solid rgba(217, 83, 79, 0.4);
  font-family: "Montserrat", Helvetica;
  height: 24px;
  left: 565px;

  position: absolute;
  top: 282px;
  width: 175px;
}

.student-doubts-t .rectangle-74-C61RwL {
  background-color: var(--white);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 24px;
  left: 900px;
  position: absolute;
  top: 286px;
  width: 175px;
}

.student-doubts-t .solve-doubts-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: normal;
  width: 253px;
  margin: 50px 0 0 8%;
}

.student-doubts-t .solve-this-doubt-Fv0v2y {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 180px;
}

.student-doubts-t .solve-this-doubt-gT7xxj {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 180px;
}

.student-doubts-t .solved-pqE4mo {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 1058px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: auto;
}

.student-doubts-t .span0-z3pWM6 {
  color: rgb(220, 53, 69);
  font-style: normal;
}

.student-doubts-t .span1-z3pWM6 {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}

.student-doubts-t .status-C61RwL {
  background-color: transparent;
  height: auto;
  left: 812px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 282px;
  width: auto;
}

.student-doubts-t .status-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 812px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 286px;
  width: auto;
}

.student-doubts-t .stickynote2-24px-1-Cp4Be6 {
  background-color: transparent;
  height: 24px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 24px;
}

.student-doubts-t .subject-C61RwL {
  background-color: transparent;
  height: auto;
  left: 466px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 282px;
  width: auto;
}

.student-doubts-t .subject-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 466px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 286px;
  width: auto;
}

.student-doubts-t .supportagent-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 792px;
  width: 24px;
}

.student-doubts-t .vatsal-agarwal-B6ZBuj-doubt-s {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.student-doubts-t .vector-6XJ1dC {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}

.student-doubts-t .vector-7pUNkI {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-t .vector-7txfVx {
  background-color: transparent;
  height: 58.33%;
  left: 20.83%;
  position: absolute;
  top: 20.83%;
  width: 58.33%;
}

.student-doubts-t .vector-C61RwL {
  background-color: transparent;
  height: 2.34%;
  left: 1.32%;
  position: absolute;
  top: 92.77%;
  width: 1.67%;
}

.vector-C61RwL-10-1 {
  background-color: transparent;
  height: 2.34%;
  left: 20px;
  position: absolute;
  top: 22.77%;
  width: 1.67%;
}

.vector-C61RwL-10-2 {
  background-color: transparent;
  height: 2.34%;
  left: 20px;
  position: absolute;
  top: 27.77%;
  width: 1.67%;
}

.vector-C61RwL-10-3 {
  background-color: transparent;
  height: 2.34%;
  left: 20px;
  position: absolute;
  top: 32.77%;
  width: 1.67%;
}

.vector-C61RwL-10-4 {
  background-color: transparent;
  height: 2.34%;
  left: 20px;
  position: absolute;
  top: 37.77%;
  width: 1.67%;
}

.vector-C61RwL-10-5 {
  background-color: transparent;
  height: 2.34%;
  left: 20px;
  position: absolute;
  top: 42.77%;
  width: 1.67%;
}

.vector-C61RwL-10-6 {
  background-color: transparent;
  height: 2.34%;
  left: 20px;
  position: absolute;
  top: 47.77%;
  width: 1.67%;
}

.vector-C61RwL-10-7 {
  background-color: transparent;
  height: 2.34%;
  left: 20px;
  position: absolute;
  top: 52.77%;
  width: 1.67%;
}

.vector-C61RwL-10-8 {
  background-color: transparent;
  height: 2.34%;
  left: 20px;
  position: absolute;
  top: 57.77%;
  width: 1.67%;
}

.vector-C61RwL-10-9 {
  background-color: transparent;
  height: 2.34%;
  left: 20px;
  position: absolute;
  top: 62.77%;
  width: 1.67%;
}

.vector-C61RwL-10-10 {
  background-color: transparent;
  height: 2.34%;
  left: 20px;
  position: absolute;
  top: 67.77%;
  width: 1.67%;
}

.vector-C61RwL-10-11 {
  background-color: transparent;
  height: 2.34%;
  left: 20px;
  position: absolute;
  top: 72.77%;
  width: 1.67%;
}

.student-doubts-t .vector-CLdsug {
  background-color: transparent;
  height: 82.14%;
  left: 16.67%;
  position: absolute;
  top: 9.97%;
  width: 66.67%;
}

.student-doubts-t .vector-G0yBDR {
  background-color: transparent;
  height: 83.33%;
  left: 16.67%;
  position: absolute;
  top: 8.33%;
  width: 66.67%;
}

.student-doubts-t .vector-OSIBbx {
  background-color: transparent;
  height: 1.19%;
  left: 49.4%;
  position: absolute;
  top: 49.4%;
  width: 1.19%;
}

.student-doubts-t .vector-OYLvDa {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}

.student-doubts-t .vector-QxuFcp {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-doubts-t .vector-UaM55c {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-t .vector-VMr6Om {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 26.76%;
  width: 1.67%;
}

.student-doubts-t .vector-VdQhAS {
  background-color: transparent;
  height: 100%;
  left: 73.42%;
  position: absolute;
  top: 0px;
  width: 25.32%;
}

.student-doubts-t .vector-WbzDQU {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-t .vector-dLw05Z {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-t .vector-irJBpK {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-t .vector-mzXdH9 {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 19.53%;
  width: 1.67%;
}

.student-doubts-t .vector-qy1MCO {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}

.student-doubts-t .vector-tQ6NQH {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}

.student-doubts-t .vector-uhbuVM {
  background-color: transparent;
  height: 36.11%;
  left: 20.01%;
  position: absolute;
  top: 16.53%;
  width: 60%;
}

.student-doubts-t .vector-xy9YKY {
  background-color: transparent;
  height: 11.11%;
  left: 30%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-doubts-t .vector-ydL8U7 {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}

.student-doubts-t .vector-ye3NYT {
  background-color: transparent;
  height: 11.11%;
  left: 60%;
  position: absolute;
  top: 50%;
  width: 10%;
}

.student-doubts-t .vector-ywnyBE {
  background-color: transparent;
  height: 83.33%;
  left: 8.33%;
  position: absolute;
  top: 8.33%;
  width: 83.33%;
}

.student-doubts-t .what-is-euclid-lemma-B6ZBuj {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --black-2: rgba(0, 0, 0, 0.1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --nobel: rgba(180, 180, 180, 1);
  --white: rgba(255, 255, 255, 1);
}

/* screen - student-doubts-list-mobile */

.border-1px-black-2 {
  border: 1px solid var(--black-2);
}

.montserrat-medium-black-18px {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gray-7px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-white-10px {
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-niagara-8px {
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}

.student-doubts-list-mobile {
  background-color: var(--coconut);
  height: 100vh;
  margin: 0px;
  min-height: 1200px;
  min-width: 414px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.student-doubts-list-mobile .active-C61RwL {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 324px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 325px;
  width: 55px;
}

.student-doubts-list-mobile .apply-filters-vgf5o4 {
  color: white;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  width: auto;
}

.student-doubts-list-mobile .assignment-1-aCi14x {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}

.student-doubts-list-mobile .assignment-1-gqbUX1 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}

.student-doubts-list-mobile .assignment-1-ql7mN0 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}

.student-doubts-list-mobile .assignment-1-sDLNVS {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}

.student-doubts-list-mobile .assignment-24px-3-DVuPOa {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}

.student-doubts-list-mobile .assignment-24px-3-MxHjgv {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: absolute;
  top: 25%;
  width: 50%;
}

.student-doubts-list-mobile .assignment-24px-3-lxV7ix {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}

.student-doubts-list-mobile .assignment-24px-3-z4xZZj {
  background-color: transparent;
  height: 50%;
  left: 25%;
  overflow: hidden;
  position: relative;
  top: 25%;
  width: 50%;
}

.student-doubts-list-mobile .biba-sobti-C61RwL {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 39px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 410px;
  width: 112px;
}

.student-doubts-list-mobile .biba-sobti-VMr6Om {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 39px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 1028px;
  width: 112px;
}

.student-doubts-list-mobile .choose-subject-i63353602371261-iR8muk {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 47.37%;
}

.student-doubts-list-mobile .choose-subject-i63353612371261-DRd40k {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 42.44%;
}

.student-doubts-list-mobile .choose-subject-i63353632371261-xLSyaa {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 43.27%;
}

.student-doubts-list-mobile .choose-subject-i63353692371261-MwxZhx {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 47.37%;
}

.student-doubts-list-mobile .choose-subject-i63353702371261-r3h1DQ {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 42.44%;
}

.student-doubts-list-mobile .choose-subject-i63353722371261-pI3smF {
  background-color: transparent;
  height: 36.36%;
  left: 6.28%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30.3%;
  width: 43.27%;
}

.student-doubts-list-mobile .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 45px;
  left: 26px;
  position: absolute;
  top: 24px;
  width: 87px;
}

.student-doubts-list-mobile .component-29-aCi14x {
  background-color: transparent;
  background-image: url(./img/ellipse-4-2@2x.png);
  background-size: 100% 100%;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}

.student-doubts-list-mobile .component-29-gqbUX1 {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.png);
  background-size: 100% 100%;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}

.student-doubts-list-mobile .component-29-ql7mN0 {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}

.student-doubts-list-mobile .component-29-sDLNVS {
  background-color: transparent;
  background-image: url(./img/ellipse-4-3@2x.png);
  background-size: 100% 100%;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}

.student-doubts-list-mobile .component-30-B08mCn {
  background-color: var(--white);
  border-radius: 10px;
  height: 33px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 171px;
}

.student-doubts-list-mobile .component-30-ZnSQrh-01 {
  background-color: var(--white);
  border-radius: 10px;
  min-height: 33px;
  left: 0px;
  position: absolute;
  top: 0px;
  min-width: 175px;
}

.student-doubts-list-mobile .component-30-lvfzGr {
  background-color: var(--white);
  border-radius: 5px;
  height: 33px;
  left: 0px;
  position: relative;
  top: 0px;
  width: 171px;
}

.student-doubts-list-mobile .component-30-yXv3Oh {
  background-color: var(--white);
  border-radius: 5px;
  height: 33px;
  color: gray;
  left: 0px;
  position: relative;
  top: 0px;
  width: 171px;
}

.student-doubts-list-mobile .component-31-B08mCn {
  background-color: var(--white);
  border-radius: 10px;
  height: 33px;
  left: 197px;
  position: absolute;
  top: 0px;
  width: 172px;
}

.doubt-card-in-mob-202 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 500px;
  position: absolute;
  top: 360px;
  left: 25px;
}

.student-doubts-list-mobile .component-31-ZnSQrh-01 {
  background-color: var(--white);
  border-radius: 10px;
  min-height: 33px;
  left: 197px;
  position: absolute;
  top: 0px;
  min-width: 172px;
}

.student-doubts-list-mobile .component-32-C61RwL {
  background-color: transparent;
  height: 276px;
  left: 18px;
  position: absolute;
  top: 300px;
  width: 379px;
}

.student-doubts-list-mobile .component-33-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 92px;
  left: 30px;
  position: absolute;
  top: 310px;
  width: 355px;
}

.student-doubts-list-mobile .component-34-C61RwL {
  background-color: transparent;
  height: 276px;
  left: 18px;
  position: absolute;
  top: 883px;
  width: 379px;
}

.student-doubts-list-mobile .component-35-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 92px;
  left: 30px;
  position: absolute;
  top: 904px;
  width: 355px;
}

.student-doubts-list-mobile .component-6-C61RwL {
  background-color: transparent;
  height: 18px;
  left: 362px;
  position: absolute;
  top: 37px;
  width: 23px;
}

.student-doubts-list-mobile .create-doubt-cDarOQ {
  background-color: transparent;
  height: 36.36%;

  letter-spacing: 0px;
  line-height: normal;
}

.student-doubts-list-mobile .doubt-text-C61RwL {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 23px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: 75px;
}

.student-doubts-list-mobile .ellipse-4-MxHjgv {
  background-color: transparent;
  height: 105.46%;
  left: -1.18%;
  position: absolute;
  top: -2.73%;
  width: 102.36%;
}

.student-doubts-list-mobile .expand-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 313px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 360px;
  width: 66px;
}

.student-doubts-list-mobile .expand-VMr6Om {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 313px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 966px;
  width: 66px;
}

.student-doubts-list-mobile .expandmore-24px-1-DRd40k {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-doubts-list-mobile .expandmore-24px-1-MwxZhx {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-doubts-list-mobile .expandmore-24px-1-iR8muk {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-doubts-list-mobile .expandmore-24px-1-pI3smF {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-doubts-list-mobile .expandmore-24px-1-r3h1DQ {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-doubts-list-mobile .expandmore-24px-1-xLSyaa {
  background-color: transparent;
  height: 60.61%;
  left: 84.29%;
  overflow: hidden;
  position: absolute;
  top: 21.21%;
  width: 10.47%;
}

.student-doubts-list-mobile .group-100-C61RwL {
  background-color: transparent;
  height: 33px;
  left: 23px;
  position: absolute;
  top: 250px;
  width: 171px;
}

.student-doubts-list-mobile .group-100-VMr6Om-x {
  background-color: transparent;
  height: 38px;
  left: 240px;
  position: absolute;
  top: 245px;
  width: 140px;

  padding: 0px;
  border-radius: 5px;
}

.student-doubts-list-mobile .group-101-C61RwL {
  background-color: transparent;
  border: 0;
  height: 44px;
  left: 30px;
  padding: 0;
  position: absolute;
  resize: none;
  top: 1052px;
  width: 357px;
}

.student-doubts-list-mobile .group-102-C61RwL {
  background-color: transparent;
  height: 1.84%;
  left: 74.64%;
  position: absolute;
  top: 93.25%;
  width: 18.24%;
}

.student-doubts-list-mobile .group-103-C61RwL {
  background-color: transparent;
  height: 33px;
  left: 23px;
  position: absolute;
  top: 237px;
  width: 369px;
}

.student-doubts-list-mobile .group-104-C61RwL {
  background-color: transparent;
  height: 33px;
  left: 23px;
  position: absolute;
  top: 289px;
  width: 171px;
}

.student-doubts-list-mobile .group-105-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 261px;
  position: absolute;
  top: 295px;
  width: 91px;
}

.student-doubts-list-mobile .group-55-C61RwL {
  background-color: transparent;
  height: 1.84%;
  left: 74.64%;
  position: absolute;
  top: 44%;
  width: 18.24%;
}

.student-doubts-list-mobile .group-57-C61RwL {
  background-color: transparent;
  border: 0;
  height: 44px;
  left: 30px;
  padding: 0;
  position: absolute;
  resize: none;
  top: 450px;
  width: 357px;
}

.student-doubts-list-mobile .group-72-C61RwL {
  background-color: transparent;
  height: 35px;
  left: -203px;
  position: absolute;
  top: 961px;
  width: 179px;
}

.student-doubts-list-mobile .group-73-C61RwL {
  background-color: transparent;
  height: 35px;
  left: -203px;
  position: absolute;
  top: 1003px;
  width: 179px;
}

.student-doubts-list-mobile .group-74-C61RwL {
  background-color: transparent;
  height: 35px;
  left: -203px;
  position: absolute;
  top: 1045px;
  width: 179px;
}

.student-doubts-list-mobile .group-75-C61RwL {
  background-color: transparent;
  height: 35px;
  left: -204px;
  position: absolute;
  top: 1088px;
  width: 179px;
}

.student-doubts-list-mobile .group-94-a77sPZ {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 102.2%;

  text-align: center;
  padding: 1px;
}

.student-doubts-list-mobile .group-94-xEvN2q {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;

  text-align: center;
  padding-bottom: 10px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: white;
  font-weight: 500;

  padding: 10px;
}

.student-doubts-list-mobile .group-99-C61RwL {
  background-color: transparent;
  left: 23px;
  position: absolute;
  top: 170px;
  width: 389px;
}

.student-doubts-list-mobile .is-height-and-d-of-trigonometry-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 954px;
  width: 219px;
}

.student-doubts-list-mobile .kunal-kashyap-C61RwL {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 917px;
  width: 189px;
}

.student-doubts-list-mobile .line-5-9p2GBv {
  background-color: transparent;
  height: 101.11%;
  left: 0.55%;
  position: absolute;
  top: -0.56%;
  width: 100%;
}

.student-doubts-list-mobile .line-6-9p2GBv {
  background-color: transparent;
  height: 102.22%;
  left: -0.29%;
  position: absolute;
  top: -1.11%;
  width: 100.57%;
}

.student-doubts-list-mobile .mathematics-dr-yashna-sharma-aCi14x {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}

.student-doubts-list-mobile .mathematics-dr-yashna-sharma-gqbUX1 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}

.student-doubts-list-mobile .mathematics-dr-yashna-sharma-ql7mN0 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}

.student-doubts-list-mobile .mathematics-dr-yashna-sharma-sDLNVS {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}

.student-doubts-list-mobile .post-4W6TZx {
  background-color: transparent;
  height: 38.03%;
  left: 34.44%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23.93%;
  width: 33.11%;
}

.student-doubts-list-mobile .post-eYqyUC {
  background-color: transparent;
  height: 38.03%;
  left: 34.44%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23.93%;
  width: 33.11%;
}

.student-doubts-list-mobile .rectangle-40-aCi14x {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}

.student-doubts-list-mobile .rectangle-40-gqbUX1 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}

.student-doubts-list-mobile .rectangle-40-ql7mN0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}

.student-doubts-list-mobile .rectangle-40-sDLNVS {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}

.student-doubts-list-mobile .rectangle-41-4W6TZx {
  background-color: rgb(220, 53, 69);
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 97.35%;
}

.student-doubts-list-mobile .rectangle-41-cDarOQ {
  background-color: rgb(220, 53, 69);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 97.85%;
}

.student-doubts-list-mobile .rectangle-41-eYqyUC {
  background-color: rgb(220, 53, 69);
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 97.35%;
}

.student-doubts-list-mobile .rectangle-41-vgf5o4 {
  background-color: rgb(220, 53, 69);
  border-radius: 5px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 97.85%;
}

.student-doubts-list-mobile .rectangle-48-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.student-doubts-list-mobile .rectangle-49-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 38.89%;
  width: 100%;
}

.student-doubts-list-mobile .rectangle-50-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 77.78%;
  width: 100%;
}

.student-doubts-list-mobile .rectangle-67-AwXtS8 {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 355px;
}

.student-doubts-list-mobile .rectangle-67-wAIKMX {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 355px;
}

.student-doubts-list-mobile .rectangle-71-C61RwL {
  background-color: var(--white);
  border: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 81px;
  left: 23px;
  padding: 8px;
  position: absolute;
  resize: none;
  top: 117px;
  width: 369px;
}

.student-doubts-list-mobile .solve-doubts-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 121px;
  width: 310px;
}

.student-doubts-list-mobile .solve-this-doubt-AwXtS8 {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 140px;
}

.student-doubts-list-mobile .solve-this-doubt-wAIKMX {
  background-color: transparent;
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 140px;
}

.student-doubts-list-mobile .solved-C61RwL {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 324px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 918px;
  width: 55px;
}

.student-doubts-list-mobile .span0-z3pWM6 {
  color: rgb(220, 53, 69);
  font-style: normal;
}

.student-doubts-list-mobile .span1-z3pWM6 {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}

.student-doubts-list-mobile .submitted-aCi14x {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}

.student-doubts-list-mobile .submitted-gqbUX1 {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}

.student-doubts-list-mobile .submitted-ql7mN0 {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}

.student-doubts-list-mobile .submitted-sDLNVS {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}

.student-doubts-list-mobile .vatsal-agarwal-C61RwL {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 325px;
  width: 189px;
}

.student-doubts-list-mobile .vector-0hr4HB {
  background-color: transparent;
  height: 2.5%;
  left: 48.61%;
  position: absolute;
  top: 48.75%;
  width: 2.78%;
}

.student-doubts-list-mobile .vector-1zaNId {
  background-color: transparent;
  height: 32.5%;
  left: 23.47%;
  position: absolute;
  top: 34.98%;
  width: 53.06%;
}

.student-doubts-list-mobile .vector-5tYqrB {
  background-color: transparent;
  height: 86.85%;
  left: 9.68%;
  position: absolute;
  top: 2.41%;
  width: 80.64%;
}

.student-doubts-list-mobile .vector-Ghyegb {
  background-color: transparent;
  height: 2.5%;
  left: 48.6%;
  position: absolute;
  top: 48.75%;
  width: 2.79%;
}

.student-doubts-list-mobile .vector-Hw9wWZ {
  background-color: transparent;
  height: 2.5%;
  left: 48.6%;
  position: absolute;
  top: 48.75%;
  width: 2.79%;
}

.student-doubts-list-mobile .vector-McuNxD {
  background-color: transparent;
  height: 93.05%;
  left: 9.68%;
  position: absolute;
  top: -0.69%;
  width: 80.64%;
}

.student-doubts-list-mobile .vector-UwmKsc {
  background-color: transparent;
  height: 86.85%;
  left: 9.68%;
  position: absolute;
  top: 2.41%;
  width: 80.64%;
}

.student-doubts-list-mobile .vector-VOHrmv {
  background-color: transparent;
  height: 2.5%;
  left: 48.61%;
  position: absolute;
  top: 48.75%;
  width: 2.78%;
}

.student-doubts-list-mobile .vector-XDTMsf {
  background-color: transparent;
  height: 32.5%;
  left: 23.47%;
  position: absolute;
  top: 34.98%;
  width: 53.06%;
}

.student-doubts-list-mobile .vector-YDNU6K {
  background-color: transparent;
  height: 6.2%;
  left: 46.9%;
  position: absolute;
  top: 46.9%;
  width: 6.2%;
}

.student-doubts-list-mobile .vector-Z5tpua {
  background-color: transparent;
  height: 32.5%;
  left: 23.47%;
  position: absolute;
  top: 34.98%;
  width: 53.06%;
}

.student-doubts-list-mobile .vector-Z87Q8p {
  background-color: transparent;
  height: 6.2%;
  left: 46.9%;
  position: absolute;
  top: 46.9%;
  width: 6.2%;
}

.student-doubts-list-mobile .vector-b254At {
  background-color: transparent;
  height: 2.5%;
  left: 48.6%;
  position: absolute;
  top: 48.75%;
  width: 2.79%;
}

.student-doubts-list-mobile .vector-eMfSFf {
  background-color: transparent;
  height: 32.5%;
  left: 23.47%;
  position: absolute;
  top: 34.98%;
  width: 53.06%;
}

.student-doubts-list-mobile .vector-hGGfYL {
  background-color: transparent;
  height: 93.05%;
  left: 9.68%;
  position: absolute;
  top: -0.69%;
  width: 80.64%;
}

.student-doubts-list-mobile .vector-hvMrjc {
  background-color: transparent;
  height: 32.5%;
  left: 23.63%;
  position: absolute;
  top: 34.98%;
  width: 52.75%;
}

.student-doubts-list-mobile .vector-hyPzor {
  background-color: transparent;
  height: 6.2%;
  left: 46.9%;
  position: absolute;
  top: 46.9%;
  width: 6.2%;
}

.student-doubts-list-mobile .vector-x8YWNM {
  background-color: transparent;
  height: 6.2%;
  left: 46.9%;
  position: absolute;
  top: 46.9%;
  width: 6.2%;
}

.student-doubts-list-mobile .vector-xVlxUo {
  background-color: transparent;
  height: 2.5%;
  left: 48.6%;
  position: absolute;
  top: 48.75%;
  width: 2.79%;
}

.student-doubts-list-mobile .vector-xsv7LI {
  background-color: transparent;
  height: 32.5%;
  left: 23.63%;
  position: absolute;
  top: 34.98%;
  width: 52.75%;
}

.student-doubts-list-mobile .what-is-euclid-lemma-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 360px;
  width: 219px;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --black-2: rgba(0, 0, 0, 0.1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --nobel: rgba(180, 180, 180, 1);
  --white: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 599px) {
  .student-doubts-t {
    display: none;
  }

  .doubt-card {
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    height: 150px;
    width: 375px;

    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding: 10px;
    justify-content: space-evenly;
  }

  .vatsal-agarwal-B6ZBuj {
    background-color: transparent;
    color: var(--black);
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    height: auto;
    letter-spacing: 0px;
    line-height: normal;
    text-align: left;
    width: auto;
  }

  .vatsal-agarwal-B6ZBuj-doubt-s {
    background-color: transparent;
    color: var(--black);
    font-family: "Montserrat", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    height: auto;
    letter-spacing: 0px;
    line-height: normal;
    text-align: left;
    width: auto;
  }

  .active-B6ZBuj {
    background-color: transparent;
    color: rgba(249, 129, 49, 1);
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: auto;
    letter-spacing: 0px;
    line-height: normal;
    text-align: left;
    width: auto;
  }

  .what-is-euclid-lemma-B6ZBuj {
    background-color: transparent;
    height: auto;
    letter-spacing: 0px;
    line-height: normal;
    text-align: left;
    font-size: 10px;
    width: auto;
  }

  .expand-B6ZBuj {
    background-color: transparent;
    color: var(--black);
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: auto;
    letter-spacing: 0px;
    line-height: normal;
    text-align: left;
    width: auto;
  }
}

.teacher-doubt-drop1 {
  width: 65%;
}

@media screen and (min-width: 600px) {
  .student-doubts-list-mobile {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .bhsks-snfasa-sd2 {
    margin: 0px;
    position: absolute;
    top: 315px;
    left: 25px;
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
