/* screen - relational-manager-date-calendar */

.montserrat-bold-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.montserrat-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-24px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-nobel-16px {
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.relational-manager-date-calendar {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}
.relational-manager-date-calendar .accountbalance-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-date-calendar .analytics-24px-4-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.vector-Ayxm2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  position: relative;
  width: 24px;
}
.relational-manager-date-calendar .biba-sobti-h8V8ZR {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.relational-manager-date-calendar .bibasobtigmailcom-h8V8ZR {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.relational-manager-date-calendar .category-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 301px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 76px;
}
.relational-manager-date-calendar .choose-date-for-booking-Fjgz1C {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 40px;
  min-height: 36px;
  position: relative;
  text-align: left;
  width: 432px;
}
.relational-manager-date-calendar .component-1-GyJUNH {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.relational-manager-date-calendar .component-4-A6SpMB {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 194px;
  position: relative;
  width: 158px;
}
.relational-manager-date-calendar .component-5-A6SpMB {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 194px;
  margin-left: 43px;
  position: relative;
  width: 158px;
}
.relational-manager-date-calendar .component-6-fhQzNT {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 194px;
  justify-content: flex-end;
  left: 0px;
  min-width: 304px;
  padding: 37px 149.7px;
  position: absolute;
  top: 0px;
  width: auto;
}
.relational-manager-date-calendar .course-fhQzNT {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 154px;
  width: auto;
}
.relational-manager-date-calendar .ellipse-2-DPayCP {
  background-color: transparent;
  height: 8px;
  left: 15px;
  position: absolute;
  top: 27px;
  width: 8px;
}
.relational-manager-date-calendar .ellipse-2-RYyMFx {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.relational-manager-date-calendar .ellipse-3-w8ZjW9 {
  background-color: transparent;
  height: 45px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 44px;
}
.relational-manager-date-calendar .feb-2021-gI0kab {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 39px;
  position: relative;
  width: 142px;
}
.relational-manager-date-calendar .flex-col-2HqSz2 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 435px;
  position: relative;
  width: 163px;
}
.relational-manager-date-calendar .flex-col-2uH8vm {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 3.14px;
  margin-left: 50px;
  min-height: 361px;
  position: relative;
  width: 54px;
}
.relational-manager-date-calendar .flex-col-C61RwL-10-1 {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 85px;
  margin-top: 45px;
  min-height: 977px;
  position: relative;
  width: 1256px;
}
.relational-manager-date-calendar .flex-col-IrNDxV {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 3.51px;
  margin-left: 50px;
  min-height: 361px;
  position: relative;
  width: 65px;
}
.relational-manager-date-calendar .flex-col-L5w8ri {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 3.51px;
  margin-left: 47px;
  min-height: 361px;
  position: relative;
  width: 39px;
}
.relational-manager-date-calendar .flex-col-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 734px;
  position: relative;
  width: 755px;
}
.relational-manager-date-calendar .flex-col-SHV8jQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 3.51px;
  margin-left: 47px;
  min-height: 361px;
  position: relative;
  width: 48px;
}
.relational-manager-date-calendar .flex-col-cxxBQR {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 50px;
  min-height: 365px;
  position: relative;
  width: 51px;
}
.relational-manager-date-calendar .flex-col-m0tnSy {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 361px;
  position: relative;
  width: 62px;
}
.relational-manager-date-calendar .flex-col-pn1t1C {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 3.51px;
  margin-left: 46px;
  min-height: 361px;
  position: relative;
  width: 56px;
}
.relational-manager-date-calendar .flex-col-vgli2x {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.relational-manager-date-calendar .flex-row-0xy0vn {
  align-items: flex-start;
  align-self: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 105px;
  justify-content: flex-start;
  min-width: 1233px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar .flex-row-2uH8vm {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 435px;
  justify-content: flex-start;
  margin-bottom: -0.33px;
  min-width: 565px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar .flex-row-Fjgz1C {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 194px;
  justify-content: flex-start;
  margin-left: 1px;
  min-width: 704px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar .flex-row-axsvJs {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar .flex-row-gI0kab {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 365px;
  justify-content: flex-start;
  margin-top: 31px;
  min-width: 163px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar .flex-row-oV5UrE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 734px;
  justify-content: flex-start;
  margin-top: 42px;
  min-width: 1205px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar .fri-Kv35Vm {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 2.67px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 36px;
}
.relational-manager-date-calendar .grade-fhQzNT {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 111px;
  width: auto;
}
.relational-manager-date-calendar .group-125-4xLMVj {
  background-color: var(--blue-zodiac);
  border-radius: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 318px;
}
.relational-manager-date-calendar .group-202-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 29px;
  margin-top: 18px;
  min-height: 637px;
  position: relative;
  width: 421px;
}
.relational-manager-date-calendar .group-204-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-right: 40px;
  margin-top: 51px;
  position: relative;
  width: 320px;
}
.relational-manager-date-calendar .group-209-C61RwL-10-1 {
  align-items: flex-end;
  background-color: #1f2531;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 100%;
  padding: 50px 18px;
  position: fixed;
  z-index: 10;
  width: 63px;
}
.relational-manager-date-calendar .group-22-GyJUNH {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  margin-top: 6px;
  min-width: 316px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar .group-23-PXwdVf {
  /* align-items: flex-end; */
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 434px;
  justify-content: space-between;
  left: 0px;
  min-width: 754px;
  position: absolute;
  top: 0px;
  width: auto;
}
.relational-manager-date-calendar .group-91-YX6IY4 {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 369px;
}
.relational-manager-date-calendar .group-92-YX6IY4 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 365px;
}
.relational-manager-date-calendar .group-94-YX6IY4 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 365px;
}
.relational-manager-date-calendar .group-95-YX6IY4 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 365px;
}
.relational-manager-date-calendar .group-96-YX6IY4 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 365px;
}
.relational-manager-date-calendar .group-99-YX6IY4 {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 351px;
  width: 369px;
}
.relational-manager-date-calendar .group-AAVFy6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.relational-manager-date-calendar .group-XDTIuB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.relational-manager-date-calendar .group-aTF8dW {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.relational-manager-date-calendar .group-rAjf8W {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.relational-manager-date-calendar .help-24px-2-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-date-calendar .historytoggleoff-24px-1-D0BVcB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-date-calendar .in-progess-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 210px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 72px;
}
.relational-manager-date-calendar .kunal-kashyap-HoxY0i {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 105px;
}
.relational-manager-date-calendar .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.relational-manager-date-calendar .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.relational-manager-date-calendar .messages-4xLMVj {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 64px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 11px;
  width: auto;
}
.relational-manager-date-calendar .mon-DXimwk {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 62px;
}
.relational-manager-date-calendar .next-month-PXwdVf {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 503px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 5px;
  width: auto;
}
.relational-manager-date-calendar .notifications-24px-2-1-GyJUNH {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-bottom: 13px;
  margin-left: 641px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.relational-manager-date-calendar .open-issue-list-4FsOUs {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 5px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.relational-manager-date-calendar .overlap-group-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 434px;
  margin-left: 1px;
  margin-top: 30px;
  position: relative;
  width: 754px;
}
.relational-manager-date-calendar .overlap-group1-A6SpMB {
  background-color: transparent;
  flex-shrink: 1;
  height: 194px;
  margin-left: 41px;
  position: relative;
  width: 304px;
}
.relational-manager-date-calendar .overlap-group1-As8JWv {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-right: 8.96px;
  margin-top: 38px;
  position: relative;
  width: 33px;
}
.relational-manager-date-calendar .overlap-group2-oCqZvM {
  align-self: flex-start;
  background-color: transparent;
  flex-shrink: 1;
  height: 45px;
  margin-top: 31px;
  position: relative;
  width: 44px;
}
.relational-manager-date-calendar .overlap-group3-4FsOUs {
  background-color: transparent;
  flex-shrink: 1;
  height: 600px;
  margin-top: 14px;
  position: relative;
  width: 383px;
}
.relational-manager-date-calendar .pending-edUp7N {
  background-color: transparent;
  height: auto;
  left: 209px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 56px;
}
.relational-manager-date-calendar .rectangle-31-YX6IY4 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 600px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.relational-manager-date-calendar .rectangle-40-YX6IY4 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.relational-manager-date-calendar .rectangle-41-YX6IY4 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.relational-manager-date-calendar .rectangle-47-YX6IY4 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.relational-manager-date-calendar .rectangle-48-HoxY0i {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.relational-manager-date-calendar .rectangle-48-edUp7N {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.relational-manager-date-calendar .rectangle-7-PXwdVf {
  background-color: transparent;
  height: 1px;
  left: 455px;
  position: absolute;
  top: 139px;
  width: 1px;
}
.relational-manager-date-calendar .s-no-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.relational-manager-date-calendar .sat-irHcBx {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 2.67px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 45px;
}
.relational-manager-date-calendar .school-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-date-calendar .sections-teachi-g-i636278553102-Xri3Jx {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  width: auto;
}
.relational-manager-date-calendar .sections-teachi-g-i636278753102-nTYimd {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 7px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  width: auto;
}
.relational-manager-date-calendar .shreyans-jain-edUp7N {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 93px;
}
.relational-manager-date-calendar .sos-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 315px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 34px;
}
.relational-manager-date-calendar .sos-vQxkx4 {
  background-color: transparent;
  height: auto;
  left: 315px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 34px;
}
.relational-manager-date-calendar .span0-slaq6m {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
}
.relational-manager-date-calendar .span1-slaq6m {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
  font-weight: 400;
}
.relational-manager-date-calendar .standard-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 301px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 366px;
  width: 61px;
}
.relational-manager-date-calendar .status-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 220px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.relational-manager-date-calendar .student-name-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 83px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 107px;
}
.relational-manager-date-calendar .subject-fhQzNT {
  background-color: transparent;
  height: auto;
  left: 27px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 68px;
  width: auto;
}
.relational-manager-date-calendar .sun-uREHIf {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 54px;
}
.relational-manager-date-calendar .teacher-name-fhQzNT {
  background-color: transparent;
  height: auto;
  left: 27px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  width: auto;
}
.relational-manager-date-calendar .thu-oCqZvM {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 3.75px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 53px;
}
.relational-manager-date-calendar .ticket-HoxY0i {
  background-color: transparent;
  height: auto;
  left: 211px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 47px;
}
.relational-manager-date-calendar .tue-As8JWv {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 51px;
}
.relational-manager-date-calendar .vatsal-agarwal-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 85px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 104px;
}
.relational-manager-date-calendar .vector-1duCl1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-date-calendar .vector-2CQzkB {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.relational-manager-date-calendar .vector-2xKpiG {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-date-calendar .vector-40xw7g {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.relational-manager-date-calendar .vector-4xLMVj {
  background-color: transparent;
  height: 12px;
  left: 282px;
  position: absolute;
  top: 17px;
  width: 18px;
}
.relational-manager-date-calendar .vector-AxqJxr {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.relational-manager-date-calendar .vector-Axu9vm {
  background-color: transparent;
  height: 30px;
  left: 10px;
  position: absolute;
  top: 7px;
  width: 30px;
}
.relational-manager-date-calendar .vector-Ayxm2x-01-1 {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 220px;
  position: relative;
  width: 24px;
}
.relational-manager-date-calendar .vector-BRkMYc {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.relational-manager-date-calendar .vector-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.relational-manager-date-calendar .vector-I29KJn {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-date-calendar .vector-RYyMFx {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.relational-manager-date-calendar .vector-WxmxmD {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.relational-manager-date-calendar .vector-XxcEsk {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-date-calendar .vector-YPcxtl {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.relational-manager-date-calendar .vector-Zppvke {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.relational-manager-date-calendar .vector-axsvJs {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.relational-manager-date-calendar .vector-uxDhU3 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.relational-manager-date-calendar .vector-vgli2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.relational-manager-date-calendar .vector-x0nTHj {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.relational-manager-date-calendar .vector-x6oIim {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.relational-manager-date-calendar .wed-MMz0cz {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 65px;
}
.relational-manager-date-calendar .x1-Kv35Vm {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.1px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 13px;
}
.relational-manager-date-calendar .x1-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 15px;
}
.relational-manager-date-calendar .x10-uREHIf {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12.59px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 29px;
}
.relational-manager-date-calendar .x11-DXimwk {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.96px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 22px;
}
.relational-manager-date-calendar .x12-As8JWv {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 10.77px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.relational-manager-date-calendar .x13-MMz0cz {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 14.4px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.relational-manager-date-calendar .x14-w8ZjW9 {
  background-color: transparent;
  color: rgba(244, 244, 244, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 29px;
}
.relational-manager-date-calendar .x15-Kv35Vm {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.1px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.relational-manager-date-calendar .x16-irHcBx {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 3.62px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.relational-manager-date-calendar .x17-uREHIf {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 6.38px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.relational-manager-date-calendar .x18-DXimwk {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.96px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 29px;
}
.relational-manager-date-calendar .x19-As8JWv {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 10.77px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.relational-manager-date-calendar .x2-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 130px;
  width: 15px;
}
.relational-manager-date-calendar .x2-irHcBx {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 11.72px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 18px;
}
.relational-manager-date-calendar .x20-MMz0cz {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 14.4px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 35px;
}
.relational-manager-date-calendar .x21-oCqZvM {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12.46px;
  margin-top: 31px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.relational-manager-date-calendar .x22-Kv35Vm {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 6.29px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 33px;
}
.relational-manager-date-calendar .x23-irHcBx {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 1.81px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 33px;
}
.relational-manager-date-calendar .x24-uREHIf {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 2.76px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 35px;
}
.relational-manager-date-calendar .x25-DXimwk {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.96px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 33px;
}
.relational-manager-date-calendar .x26-DPayCP {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0px;
  width: 33px;
}
.relational-manager-date-calendar .x27-MMz0cz {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12.59px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 33px;
}
.relational-manager-date-calendar .x28-DXimwk {
  background-color: transparent;
  color: var(--nobel);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 3.53px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 35px;
}
.relational-manager-date-calendar .x28-oCqZvM {
  align-self: flex-start;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 4.7px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 35px;
}
.relational-manager-date-calendar .x29-As8JWv {
  background-color: transparent;
  color: var(--nobel);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 5.34px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 33px;
}
.relational-manager-date-calendar .x29-Kv35Vm {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 6.29px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 33px;
}
.relational-manager-date-calendar .x3-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 189px;
  width: 15px;
}
.relational-manager-date-calendar .x3-uREHIf {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12.59px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 18px;
}
.relational-manager-date-calendar .x30-MMz0cz {
  background-color: transparent;
  color: var(--nobel);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 7.15px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 35px;
}
.relational-manager-date-calendar .x30-irHcBx {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 35px;
}
.relational-manager-date-calendar .x31-oCqZvM {
  background-color: transparent;
  color: var(--nobel);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 5.21px;
  margin-top: 19px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.relational-manager-date-calendar .x31-uREHIf {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 6.5px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 27px;
}
.relational-manager-date-calendar .x4-DXimwk {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 10.78px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 20px;
}
.relational-manager-date-calendar .x4-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 15px;
}
.relational-manager-date-calendar .x4-i636278553103-Xri3Jx {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 42px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 79px;
  width: auto;
}
.relational-manager-date-calendar .x4-i636278653103-hPEL6O {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 78px;
  min-width: 2px;
  position: relative;
  text-align: center;
  width: auto;
}
.relational-manager-date-calendar .x4-i636278753103-nTYimd {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 79px;
  width: auto;
}
.relational-manager-date-calendar .x5-As8JWv {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.96px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 18px;
}
.relational-manager-date-calendar .x5-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 304px;
  width: 15px;
}
.relational-manager-date-calendar .x6-MMz0cz {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12.59px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 18px;
}
.relational-manager-date-calendar .x6-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 366px;
  width: 15px;
}
.relational-manager-date-calendar .x7-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 422px;
  width: 15px;
}
.relational-manager-date-calendar .x7-oCqZvM {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 10.64px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 18px;
}
.relational-manager-date-calendar .x8-Kv35Vm {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.1px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 20px;
}
.relational-manager-date-calendar .x8-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 481px;
  width: 15px;
}
.relational-manager-date-calendar .x9-YX6IY4 {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 540px;
  width: 15px;
}
.relational-manager-date-calendar .x9-irHcBx {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 11.72px;
  margin-top: 38px;
  min-height: 31px;
  position: relative;
  text-align: center;
  width: 18px;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --nobel: rgba(179, 179, 179, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - relational-manager-date-calendar-mobile */

.montserrat-bold-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.montserrat-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.montserrat-medium-blue-zodiac-16px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-24px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-nobel-16px {
  color: var(--nobel);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.relational-manager-date-calendar-mobile {
  align-items: center;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 1686px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 0;
  position: relative;
  width: 414px;
}
.relational-manager-date-calendar-mobile .booked-WqAWxg {
  background-color: transparent;
  height: auto;
  left: 284px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 50px;
}
.relational-manager-date-calendar-mobile .booked-h8LeMt {
  background-color: transparent;
  height: auto;
  left: 286px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 477px;
  width: 50px;
}
.relational-manager-date-calendar-mobile .category-h8LeMt {
  background-color: transparent;
  height: auto;
  left: 263px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 92px;
}
.relational-manager-date-calendar-mobile .choose-date-for-booking-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12px;
  margin-top: 28px;
  min-height: 26px;
  position: relative;
  text-align: left;
  width: 338px;
}
.relational-manager-date-calendar-mobile .completed-h8LeMt {
  background-color: transparent;
  height: auto;
  left: 184px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 74px;
}
.relational-manager-date-calendar-mobile .component-1-dXBp89 {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.relational-manager-date-calendar-mobile .component-4-BdpSxK {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 157px;
  position: relative;
  width: 158px;
}
.relational-manager-date-calendar-mobile .component-5-BdpSxK {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 157px;
  margin-left: 46px;
  position: relative;
  width: 158px;
}
.relational-manager-date-calendar-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.relational-manager-date-calendar-mobile .component-7-D1n0rW {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 171px;
  justify-content: flex-end;
  left: 0px;
  min-width: 304px;
  padding: 23.4px 149.7px;
  position: absolute;
  top: 0px;
  width: auto;
}
.relational-manager-date-calendar-mobile .course-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 132px;
  width: auto;
}
.relational-manager-date-calendar-mobile .ellipse-2-CuHwkr {
  background-color: transparent;
  flex-shrink: 1;
  height: 9px;
  margin-right: 0.78px;
  margin-top: 113px;
  position: relative;
  width: 4px;
}
.relational-manager-date-calendar-mobile .ellipse-3-m8hXmu {
  background-color: transparent;
  height: 40px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 35px;
}
.relational-manager-date-calendar-mobile .feb-2021-NwNIwt {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  width: 89px;
}
.relational-manager-date-calendar-mobile .flex-col-33nW0k {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 8px;
  min-height: 272px;
  position: relative;
  width: 45px;
}
.relational-manager-date-calendar-mobile .flex-col-K8H2xh {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 21px;
  min-height: 272px;
  position: relative;
  width: 48px;
}
.relational-manager-date-calendar-mobile .flex-col-W4miCj {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 22px;
  min-height: 272px;
  position: relative;
  width: 34px;
}
.relational-manager-date-calendar-mobile .flex-col-buKxXV {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 14px;
  min-height: 272px;
  position: relative;
  width: 33px;
}
.relational-manager-date-calendar-mobile .flex-col-hnq7Rk {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 12px;
  min-height: 272px;
  position: relative;
  width: 43px;
}
.relational-manager-date-calendar-mobile .flex-col-hoRLJZ {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 395px;
  position: relative;
  width: 47px;
}
.relational-manager-date-calendar-mobile .flex-col-wGVEdT {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 13px;
  min-height: 272px;
  position: relative;
  width: 48px;
}
.relational-manager-date-calendar-mobile .flex-row-33nW0k {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 395px;
  justify-content: flex-start;
  min-width: 163px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar-mobile .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar-mobile .flex-row-K8H2xh {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 395px;
  justify-content: flex-start;
  margin-bottom: -0.52px;
  min-width: 320px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar-mobile .flex-row-VMr6Om {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 157px;
  justify-content: flex-start;
  margin-top: 28px;
  min-width: 362px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar-mobile .flex-row-W4miCj {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 395px;
  justify-content: flex-start;
  min-width: 263px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar-mobile .flex-row-buKxXV {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 395px;
  justify-content: flex-start;
  min-width: 216px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar-mobile .flex-row-mzXdH9 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 23px;
  justify-content: flex-start;
  margin-right: 2px;
  margin-top: 12px;
  min-width: 360px;
  position: relative;
  width: auto;
}
.relational-manager-date-calendar-mobile .fri-UApxSP {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 33px;
}
.relational-manager-date-calendar-mobile .grade-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 95px;
  width: auto;
}
.relational-manager-date-calendar-mobile .group-210-4eduM0 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 8px;
  min-height: 581px;
  position: absolute;
  top: 455px;
  width: 381px;
}
.relational-manager-date-calendar-mobile .group-87-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 171px;
  margin-left: 10px;
  margin-top: 78px;
  position: relative;
  width: 312px;
}
.relational-manager-date-calendar-mobile .group-88-4eduM0 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 395px;
  justify-content: flex-start;
  left: 0px;
  min-width: 470px;
  position: absolute;
  top: 0px;
  width: auto;
}
.relational-manager-date-calendar-mobile .group-90-h8LeMt {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 170px;
  width: 346px;
}
.relational-manager-date-calendar-mobile .group-91-h8LeMt {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 464px;
  width: 348px;
}
.relational-manager-date-calendar-mobile .group-92-h8LeMt {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 287px;
  width: 346px;
}
.relational-manager-date-calendar-mobile .group-94-h8LeMt {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 405px;
  width: 346px;
}
.relational-manager-date-calendar-mobile .group-95-h8LeMt {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 346px;
  width: 346px;
}
.relational-manager-date-calendar-mobile .group-96-h8LeMt {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 233px;
  width: 346px;
}
.relational-manager-date-calendar-mobile .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.relational-manager-date-calendar-mobile .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.relational-manager-date-calendar-mobile .mon-CuHwkr {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 47px;
}
.relational-manager-date-calendar-mobile .next-month-NwNIwt {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 157px;
  min-height: 22px;
  min-width: 114px;
  position: relative;
  text-align: left;
  width: auto;
}
.relational-manager-date-calendar-mobile .open-issue-list-7Or7x3 {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 4px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.relational-manager-date-calendar-mobile .opened-AiWHCv {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.relational-manager-date-calendar-mobile .opened-H3Fc0b {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.relational-manager-date-calendar-mobile .opened-ILuk4C {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.relational-manager-date-calendar-mobile .opened-qAvYSQ {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.relational-manager-date-calendar-mobile .opened-w61W7y {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.relational-manager-date-calendar-mobile .organic-4-h8LeMt {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 69px;
}
.relational-manager-date-calendar-mobile .organic-5-OmvDF1 {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.relational-manager-date-calendar-mobile .overlap-group-C61RwL {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 878px;
  margin-right: -78.53px;
  margin-top: 28px;
  position: relative;
  width: 470px;
}
.relational-manager-date-calendar-mobile .overlap-group1-FbVO4r {
  background-color: transparent;
  flex-shrink: 1;
  height: 41px;
  margin-right: 9.84px;
  margin-top: 7px;
  position: relative;
  width: 35px;
}
.relational-manager-date-calendar-mobile .overlap-group2-7Or7x3 {
  background-color: transparent;
  flex-shrink: 1;
  height: 546px;
  margin-top: 12px;
  position: relative;
  width: 359px;
}
.relational-manager-date-calendar-mobile .rectangle-31-h8LeMt {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 546px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.relational-manager-date-calendar-mobile .rectangle-40-h8LeMt {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 52px;
  width: 342px;
}
.relational-manager-date-calendar-mobile .rectangle-41-h8LeMt {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 7px;
  position: absolute;
  top: 170px;
  width: 342px;
}
.relational-manager-date-calendar-mobile .rectangle-44-H3Fc0b {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-date-calendar-mobile .rectangle-44-ILuk4C {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-date-calendar-mobile .rectangle-47-h8LeMt {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 111px;
  width: 342px;
}
.relational-manager-date-calendar-mobile .rectangle-48-OmvDF1 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-date-calendar-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.relational-manager-date-calendar-mobile .rectangle-49-AiWHCv {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-date-calendar-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.relational-manager-date-calendar-mobile .rectangle-49-qAvYSQ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-date-calendar-mobile .rectangle-49-w61W7y {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-date-calendar-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.relational-manager-date-calendar-mobile .s-no-h8LeMt {
  background-color: transparent;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 42px;
}
.relational-manager-date-calendar-mobile .sat-u39z17 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 34px;
}
.relational-manager-date-calendar-mobile
  .sections-teachi-g-i636291753102-fVQN4V {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 41px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
  width: auto;
}
.relational-manager-date-calendar-mobile
  .sections-teachi-g-i636291853102-PBxgdW {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
  width: auto;
}
.relational-manager-date-calendar-mobile .span0-z5xbcu {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
}
.relational-manager-date-calendar-mobile .span1-z5xbcu {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
  font-weight: 400;
}
.relational-manager-date-calendar-mobile .status-h8LeMt {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 49px;
}
.relational-manager-date-calendar-mobile .student-h8LeMt {
  background-color: transparent;
  height: auto;
  left: 78px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 94px;
}
.relational-manager-date-calendar-mobile .subject-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 58px;
  width: auto;
}
.relational-manager-date-calendar-mobile .sun-JfYboL {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 48px;
}
.relational-manager-date-calendar-mobile .teacher-name-D1n0rW {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: auto;
}
.relational-manager-date-calendar-mobile .thu-FbVO4r {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 3px;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 42px;
}
.relational-manager-date-calendar-mobile .tue-fZbOLM {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 43px;
}
.relational-manager-date-calendar-mobile .upcoming-OmvDF1 {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.relational-manager-date-calendar-mobile .wed-59TKkb {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 31px;
  position: relative;
  text-align: left;
  width: 48px;
}
.relational-manager-date-calendar-mobile .x1-UApxSP {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 9.6px;
  margin-top: 19px;
  min-height: 30px;
  position: relative;
  text-align: left;
  width: 8px;
}
.relational-manager-date-calendar-mobile .x10-JfYboL {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 31px;
}
.relational-manager-date-calendar-mobile .x11-CuHwkr {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.58px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 22px;
}
.relational-manager-date-calendar-mobile .x12-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 63px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 146px;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x12pm-h8LeMt {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 44px;
}
.relational-manager-date-calendar-mobile .x13-59TKkb {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x14-m8hXmu {
  background-color: transparent;
  color: rgba(244, 244, 244, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 3px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x15-UApxSP {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 3.3px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x16-u39z17 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 4.25px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x17-JfYboL {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 18px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x18-CuHwkr {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 4.58px;
  margin-top: 18px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x19-fZbOLM {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 4.41px;
  margin-top: 65px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x1pm-h8LeMt {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 128px;
  width: 31px;
}
.relational-manager-date-calendar-mobile .x2-u39z17 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 7.69px;
  margin-top: 19px;
  min-height: 30px;
  position: relative;
  text-align: left;
  width: 11px;
}
.relational-manager-date-calendar-mobile .x20-59TKkb {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 4px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x21-FbVO4r {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.84px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x22-UApxSP {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 3.3px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x23-u39z17 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 4.25px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x24-JfYboL {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x25-CuHwkr {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2.58px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x26-fZbOLM {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 4.41px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x27-59TKkb {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 4px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x28-CuHwkr {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.58px;
  margin-top: 19px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 26px;
}
.relational-manager-date-calendar-mobile .x28-FbVO4r {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.84px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x29-UApxSP {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 3.3px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x29-fZbOLM {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 6.41px;
  margin-top: 19px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x2pm-AiWHCv {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.relational-manager-date-calendar-mobile .x2pm-qAvYSQ {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.relational-manager-date-calendar-mobile .x2pm-w61W7y {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.relational-manager-date-calendar-mobile .x3-JfYboL {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 8px;
  margin-top: 19px;
  min-height: 30px;
  position: relative;
  text-align: left;
  width: 11px;
}
.relational-manager-date-calendar-mobile .x30-59TKkb {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 3px;
  margin-top: 19px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 35px;
}
.relational-manager-date-calendar-mobile .x30-u39z17 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 4.25px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x31-FbVO4r {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 9.84px;
  margin-top: 19px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 29px;
}
.relational-manager-date-calendar-mobile .x31-JfYboL {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 30px;
}
.relational-manager-date-calendar-mobile .x3pm-OmvDF1 {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.relational-manager-date-calendar-mobile .x4-CuHwkr {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 11.07px;
  margin-top: 18px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 12px;
}
.relational-manager-date-calendar-mobile .x4-i636291753103-fVQN4V {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 46px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 64px;
  width: auto;
}
.relational-manager-date-calendar-mobile .x4-i636291853103-PBxgdW {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 52px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 64px;
  width: auto;
}
.relational-manager-date-calendar-mobile .x4-i636292053103-j2Gv3j {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 78px;
  min-width: 2px;
  position: relative;
  text-align: center;
  width: auto;
}
.relational-manager-date-calendar-mobile .x4pm-ILuk4C {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.relational-manager-date-calendar-mobile .x5-fZbOLM {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 9.85px;
  margin-top: 18px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 11px;
}
.relational-manager-date-calendar-mobile .x6-59TKkb {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 9.44px;
  margin-top: 18px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 11px;
}
.relational-manager-date-calendar-mobile .x6pm-H3Fc0b {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.relational-manager-date-calendar-mobile .x7-FbVO4r {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 8.28px;
  margin-top: 18px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 11px;
}
.relational-manager-date-calendar-mobile .x8-UApxSP {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 5.79px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 12px;
}
.relational-manager-date-calendar-mobile .x9-u39z17 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 7.69px;
  margin-top: 17px;
  min-height: 30px;
  position: relative;
  text-align: center;
  width: 11px;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --nobel: rgba(179, 179, 179, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .relational-manager-date-calendar {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .relational-manager-date-calendar-mobile {
    display: none;
  }
}
