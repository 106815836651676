/* screen - student-doubts-detail */

.montserrat-medium-black-18px {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.doubt-card-in-comment {
  display: flex;
  flex-direction: column;
  top: 374px;
  left: 39px;
  position: absolute;
  height: 300px;
  overflow: auto;
  width: 350px;
}

.doubt-card-in-comment-in {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.student-doubts-detail {
  background-color: var(--coconut);
  width: 100%;
}

.student-doubts-detail .student-doubts-detail-col {
  position: relative;
  top: 0;
  left: 63px;
  height: 100%;
  width: calc(100% - 63px);
}

.student-doubts-detail .doubt-detail-row-1 {
  position: absolute;
  left: 8%;
  top: 293px;
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student-doubts-detail .active-C61RwL {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 78%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 368px;
  width: auto;
}
.student-doubts-detail .analytics-24px-3-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 644px;
  width: 24px;
}
.student-doubts-detail .assignment-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 348px;
  width: 24px;
}
.student-doubts-detail .biba-sobti-C61RwL {
  background-color: transparent;
  height: auto;
  left: 12%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 808px;
  width: auto;
}
.student-doubts-detail .biba-sobti-VdQhAS {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 92px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: auto;
}
.student-doubts-detail .bibasobtigmailcom-VdQhAS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 47px;
  width: auto;
}
.student-doubts-detail .class-9-read-it-from-rd-sharma-C61RwL {
  background-color: transparent;
  height: auto;
  left: 233px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 699px;
  width: 340px;
}
.student-doubts-detail .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 105px;
  left: 4.1%;
  position: absolute;
  top: 45px;
  width: 212px;
}
.student-doubts-detail .component-4-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 130px !important;
  left: 10.5%;
  position: absolute;
  top: 356px;
  width: 75%;
}
.student-doubts-detail .component-5-C61RwL {
  background-color: transparent;
  height: 598px;
  left: 8%;
  position: absolute;
  top: 335px;
  width: 80%;
}
.student-doubts-detail .date-C61RwL {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}
.student-doubts-detail .description-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 496px;
  width: 24px;
}
.student-doubts-detail .ellipse-2-OSIBbx {
  background-color: transparent;
  height: 19.05%;
  left: 57.14%;
  position: absolute;
  top: 21.43%;
  width: 19.05%;
}
.student-doubts-detail .grade-subject-C61RwL {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}
.student-doubts-detail .group-0M78Jh {
  background-color: transparent;
  background-image: url(./img/vector-15@2x.png);
  background-size: 100% 100%;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-doubts-detail .group-160-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  position: absolute;
  top: 718px;
  width: 24px;
}
.student-doubts-detail .group-22-C61RwL {
  background-color: transparent;
  height: 7.81%;
  left: 72.22%;
  position: absolute;
  top: 4.98%;
  width: 21.94%;
}
.student-doubts-detail .group-50-C61RwL {
  background-color: transparent;
  border: 0;
  height: 44px;
  left: 12%;
  padding: 0;
  position: absolute;
  resize: none;
  top: 839px;
  width: 70%;
}
.student-doubts-detail .group-55-C61RwL {
  background-color: transparent;
  height: 35px;
  right: 18%;
  position: absolute;
  top: 860px;
  width: 80px;
}
.student-doubts-detail .group-56-C61RwL {
  background-color: transparent;
  height: 44px;
  left: 202px;
  position: absolute;
  top: 499px;
  width: 1099px;
}
.student-doubts-detail .group-5jt8cF {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.student-doubts-detail .group-C61RwL {
  background-color: transparent;
  height: 20px;
  left: 22px;
  position: absolute;
  top: 422px;
  width: 20px;
}
.student-doubts-detail .group-NOXmfT {
  background-color: transparent;
  height: 100%;
  left: 0px;
  position: relative;
  top: 0px;
  width: 100%;
}
.student-doubts-detail .group-ce7Ifp {
  background-color: transparent;
  height: 18px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 20px;
}
.student-doubts-detail .help-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 570px;
  width: 24px;
}
.student-doubts-detail .it-is-from-unit-1-zA4C22 {
  background-color: transparent;
  height: auto;
  /* left: 31px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 12px; */
  width: inherit;
}
.student-doubts-detail .kunal-kashyap-C61RwL {
  background-color: transparent;
  height: auto;
  /* left: 202px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 468px; */
  width: auto;
}
.student-doubts-detail .kunal-kashyap-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 202px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 656px;
  width: auto;
}
.student-doubts-detail .line-5-9p2GBv {
  background-color: transparent;
  height: 99.05%;
  left: 0.9%;
  position: absolute;
  top: 0.48%;
  width: 99.29%;
}
.student-doubts-detail .line-6-9p2GBv {
  background-color: transparent;
  height: 100.95%;
  left: 0px;
  position: absolute;
  top: -0.48%;
  width: 100.24%;
}
.student-doubts-detail .notifications-24px-2-1-C61RwL {
  background-color: transparent;
  height: 42px;
  left: 976px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 42px;
}
.student-doubts-detail .post-4W6TZx {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: normal;
}
.student-doubts-detail .rectangle-24-C61RwL-stu-s1 {
  background-color: #1f2531;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 63px;

  overflow-y: scroll;
  padding: 150px 0px 20px 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.student-doubts-detail .rectangle-41-4W6TZx {
  background-color: var(--blue-zodiac);
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 98.08%;
}
.student-doubts-detail .rectangle-67-C61RwL {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 202px;
  position: absolute;
  top: 593px;
  width: 1097px;
}
.student-doubts-detail .rectangle-67-DxAYkY {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 1097px;
}
.student-doubts-detail .rectangle-67-zA4C22 {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  /* left: 0px;
  position: absolute;
  top: 0px; */
  width: 1097px;
}
.Color_grey {
  color: rgba(179, 179, 179);
}
.Color_dc3545 {
  color: #dc3545;
}
.student-doubts-detail .rectangle-68-C61RwL {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 202px;
  position: absolute;
  top: 687px;
  width: 1097px;
}
.student-doubts-detail .rectangle-7-C61RwL {
  background-color: transparent;
  height: 1px;
  left: 630px;
  position: absolute;
  top: 631px;
  width: 1px;
}
.student-doubts-detail .solve-doubts-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 8%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 210px;
  width: 310px;
}
.student-doubts-detail .solve-this-doubt-DxAYkY {
  background-color: transparent;
  color: rgba(180, 180, 180, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 31px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 180px;
}
.student-doubts-detail .span0-z3pWM6 {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
}
.student-doubts-detail .span1-z3pWM6 {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
  font-weight: 600;
}
.student-doubts-detail .stickynote2-24px-1-Cp4Be6 {
  background-color: transparent;
  height: 24px;
  left: 0px;
  overflow: hidden;
  position: relative;
  top: 0px;
  width: 24px;
}
.student-doubts-detail .supportagent-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 792px;
  width: 24px;
}
.student-doubts-detail .vatsal-agarwal-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 13%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 369px;
  width: auto;
}
.student-doubts-detail .vatsal-agarwal-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 202px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 562px;
  width: auto;
}
.student-doubts-detail .vector-6XJ1dC {
  background-color: transparent;
  height: 83.33%;
  left: 12.5%;
  position: absolute;
  top: 4.17%;
  width: 75%;
}
.student-doubts-detail .vector-7pUNkI {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-doubts-detail .vector-7txfVx {
  background-color: transparent;
  height: 58.33%;
  left: 20.83%;
  position: absolute;
  top: 20.83%;
  width: 58.33%;
}
.student-doubts-detail .vector-C61RwL {
  background-color: transparent;
  height: 2.34%;
  left: 1.32%;
  position: absolute;
  top: 92.77%;
  width: 1.67%;
}
.student-doubts-detail .vector-CLdsug {
  background-color: transparent;
  height: 82.14%;
  left: 16.67%;
  position: absolute;
  top: 9.97%;
  width: 66.67%;
}
.student-doubts-detail .vector-G0yBDR {
  background-color: transparent;
  height: 83.33%;
  left: 16.67%;
  position: absolute;
  top: 8.33%;
  width: 66.67%;
}
.student-doubts-detail .vector-OSIBbx {
  background-color: transparent;
  height: 1.19%;
  left: 49.4%;
  position: absolute;
  top: 49.4%;
  width: 1.19%;
}
.student-doubts-detail .vector-OYLvDa {
  background-color: transparent;
  height: 80%;
  left: 0px;
  position: absolute;
  top: 20%;
  width: 80%;
}
.student-doubts-detail .vector-QxuFcp {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}
.student-doubts-detail .vector-UaM55c {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-doubts-detail .vector-VMr6Om {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 26.76%;
  width: 1.67%;
}
.student-doubts-detail .vector-VdQhAS {
  background-color: transparent;
  height: 100%;
  left: 73.42%;
  position: absolute;
  top: 0px;
  width: 25.32%;
}
.student-doubts-detail .vector-WbzDQU {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-doubts-detail .vector-dLw05Z {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-doubts-detail .vector-irJBpK {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-doubts-detail .vector-mzXdH9 {
  background-color: transparent;
  height: 2.34%;
  left: 1.53%;
  position: absolute;
  top: 19.53%;
  width: 1.67%;
}
.student-doubts-detail .vector-qy1MCO {
  background-color: transparent;
  height: 75%;
  left: 12.5%;
  position: absolute;
  top: 12.5%;
  width: 75%;
}
.student-doubts-detail .vector-tQ6NQH {
  background-color: transparent;
  height: 80%;
  left: 20%;
  position: absolute;
  top: 0px;
  width: 80%;
}
.student-doubts-detail .vector-uhbuVM {
  background-color: transparent;
  height: 36.11%;
  left: 20.01%;
  position: absolute;
  top: 16.53%;
  width: 60%;
}
.student-doubts-detail .vector-xy9YKY {
  background-color: transparent;
  height: 11.11%;
  left: 30%;
  position: absolute;
  top: 50%;
  width: 10%;
}
.student-doubts-detail .vector-ydL8U7 {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-doubts-detail .vector-ye3NYT {
  background-color: transparent;
  height: 11.11%;
  left: 60%;
  position: absolute;
  top: 50%;
  width: 10%;
}
.student-doubts-detail .vector-ywnyBE {
  background-color: transparent;
  height: 83.33%;
  left: 8.33%;
  position: absolute;
  top: 8.33%;
  width: 83.33%;
}
.student-doubts-detail .rectangle-41-PXwdVf {
  position: absolute;
  top: 440px;
  left: 13%;
}

.student-doubts-detail .rectangle-41-PXwdVf-2 {
  position: absolute;
  top: 440px;
  left: 20%;
}
.student-doubts-detail .what-is-euclid-lemma-C61RwL {
  background-color: transparent;
  height: auto;
  left: 13%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 412px;
  width: auto;
}
.student-doubts-detail .which-class-C61RwL {
  background-color: transparent;
  height: auto;
  left: 233px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 605px;
  width: 180px;
}
:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --gray: rgba(126, 126, 126, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - student-doubts-detail-mobile */

.montserrat-medium-black-18px {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.student-doubts-detail-mobile {
  background-color: var(--coconut);
  height: 100vh;
  margin: 0px;
  min-height: 1200px;
  min-width: 414px;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.student-doubts-detail-mobile .active-C61RwL {
  background-color: transparent;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 324px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 264px;
  width: 55px;
}
.student-doubts-detail-mobile .arrowback-24px-1-C61RwL {
  background-color: transparent;
  height: 24px;
  left: 23px;
  overflow: hidden;
  position: absolute;
  top: 132px;
  width: 24px;
}
.student-doubts-detail-mobile .biba-sobti-C61RwL {
  background-color: transparent;
  height: auto;
  left: 39px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 695px;
  width: 112px;
}
.student-doubts-detail-mobile .class-9-read-it-from-rd-sharma-2osFvC {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 167px;
}
.student-doubts-detail-mobile .component-1-C61RwL {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  height: 45px;
  left: 26px;
  position: absolute;
  top: 24px;
  width: 87px;
}
.student-doubts-detail-mobile .component-32-C61RwL {
  background-color: transparent;
  height: 610px;
  left: 18px;
  position: absolute;
  top: 229px;
  width: 379px;
}
.student-doubts-detail-mobile .component-33-C61RwL {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 112px !important;
  left: 30px;
  position: absolute;
  top: 250px;
  width: 355px;
}
.student-doubts-detail-mobile .component-6-C61RwL {
  background-color: transparent;
  height: 18px;
  left: 362px;
  position: absolute;
  top: 37px;
  width: 23px;
}
.student-doubts-detail-mobile .date-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 351px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 205px;
  width: auto;
}
.student-doubts-detail-mobile .grade-subject-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 30px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 199px;
  width: auto;
}
.student-doubts-detail-mobile .group-55-C61RwL {
  background-color: transparent;
  height: 1.84%;
  left: 74.64%;
  position: absolute;
  top: 65.42%;
  width: 18.24%;
}
.student-doubts-detail-mobile .group-57-C61RwL {
  background-color: transparent;
  height: 44px;
  left: 30px;
  position: absolute;
  top: 398px;
  width: 357px;
}
.student-doubts-detail-mobile .group-76-C61RwL {
  background-color: transparent;
  height: 44px;
  left: 30px;
  position: absolute;
  top: 488px;
  width: 357px;
}
.student-doubts-detail-mobile .group-77-C61RwL {
  background-color: transparent;
  height: 44px;
  left: 30px;
  position: absolute;
  top: 578px;
  width: 357px;
}
.student-doubts-detail-mobile .group-78-C61RwL {
  background-color: transparent;
  border: 0;
  height: 44px;
  left: 30px;
  padding: 0;
  position: absolute;
  resize: none;
  top: 719px;
  width: 357px;
}
.student-doubts-detail-mobile .it-is-from-unit-1-AwXtS8-in {
  background-color: transparent;
  height: auto;
  /* left: 10px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 16px; */
  width: inherit;
}
.student-doubts-detail-mobile .kunal-kashyap-C61RwL {
  background-color: transparent;
  height: auto;
  /* left: 39px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 374px; */
  width: 112px;
}
.student-doubts-detail-mobile .kunal-kashyap-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 39px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 554px;
  width: 112px;
}
.student-doubts-detail-mobile .line-5-9p2GBv {
  background-color: transparent;
  height: 101.11%;
  left: 0.55%;
  position: absolute;
  top: -0.56%;
  width: 100%;
}
.student-doubts-detail-mobile .line-6-9p2GBv {
  background-color: transparent;
  height: 102.22%;
  left: -0.29%;
  position: absolute;
  top: -1.11%;
  width: 100.57%;
}
.student-doubts-detail-mobile .post-4W6TZx {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: 38.03%;
  left: 34.44%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23.93%;
  width: 33.11%;
}
.student-doubts-detail-mobile .rectangle-41-4W6TZx {
  background-color: var(--blue-zodiac);
  border-radius: 10px;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 97.35%;
}
.student-doubts-detail-mobile .rectangle-48-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.student-doubts-detail-mobile .rectangle-49-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 38.89%;
  width: 100%;
}
.student-doubts-detail-mobile .rectangle-50-5mfU7d {
  background-color: var(--gray);
  height: 22.22%;
  left: 0px;
  position: absolute;
  top: 77.78%;
  width: 100%;
}
.student-doubts-detail-mobile .rectangle-67-2osFvC {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 355px;
}
.like_button {
  position: relative;
  margin: 9px 0px 0px 7px;
}
.student-doubts-detail-mobile .rectangle-67-AwXtS8 {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: auto;
  min-height: 40px;
  /* left: 0px; */
  /* position: absolute; */
  /* top: 0px; */
  width: 355px;
}
.student-doubts-detail-mobile .rectangle-67-Z1JSTw {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 355px;
}
.student-doubts-detail-mobile .rectangle-67-hlxJRL {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 355px;
}
.student-doubts-detail-mobile .solve-doubts-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  left: 59px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 121px;
  width: 310px;
}
.student-doubts-detail-mobile .solve-this-doubt-hlxJRL {
  background-color: transparent;
  color: rgba(179, 179, 179, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 167px;
}
.student-doubts-detail-mobile .span0-z3pWM6 {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
}
.student-doubts-detail-mobile .span1-z3pWM6 {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
  font-weight: 600;
}
.student-doubts-detail-mobile .vatsal-agarwal-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 263px;
  width: 189px;
}
.student-doubts-detail-mobile .vatsal-agarwal-VMr6Om {
  background-color: transparent;
  height: auto;
  left: 39px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 464px;
  width: 112px;
}
.student-doubts-detail-mobile .vector-ec7mDZ {
  background-color: transparent;
  height: 2.08%;
  left: 48.96%;
  position: absolute;
  top: 48.96%;
  width: 2.08%;
}
.student-doubts-detail-mobile .vector-wEJYwt {
  background-color: transparent;
  height: 66.67%;
  left: 16.67%;
  position: absolute;
  top: 16.67%;
  width: 66.67%;
}
.student-doubts-detail-mobile .what-is-euclid-lemma-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 300px;
  width: 219px;
}
.student-doubts-detail-mobile .which-class-Z1JSTw {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 140px;
}
.student-doubts-detail-mobile .rectangle-41-PXwdVf {
  position: absolute;
  top: 295px;
  left: 210px;
  padding: 0 5px !important;
}

.student-doubts-detail-mobile .rectangle-41-PXwdVf-2 {
  position: absolute;
  top: 295px;
  left: 280px;
  padding: 0 5px !important;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --gray: rgba(126, 126, 126, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .student-doubts-detail {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .student-doubts-detail-mobile {
    display: none;
  }
}
