.component-hc-layout-wdt-100 {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.calender-user-dis-hc-view {
  min-height: 60px;
  width: 86%;
  margin: 40px 40px 40px 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hc-content-view-2 {
  padding: 7px;
  width: 90%;
  margin-top: 20px;
  /* background-color: #dc3545; */
  background-color: white;

  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content-component-teacher-op-hc {
  color: #dc3545;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.2em;
}

.content-table-hc-list {
  width: 94%;
  padding: 5px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;

  color: grey;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-Comp-2-hc {
  width: 92%;
  min-height: 32px;
  margin-top: 12px;

  border: 1px solid white;
  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 8px;
}

.content-Comp-2-hc-in {
  padding: 8px 10px 3px 10px;

  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  color: #152138;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-item-hc-content {
  min-width: 25px;
}

.content-table-hc-list-q-a {
  width: 69%;
  padding-top: 6px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;

  color: grey;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-Comp-2-hc-q-a {
  width: 70%;
  min-height: 32px;
  margin-top: 12px;

  border: 1px solid white;
  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 8px;
}

/* .content-Comp-2-hc-in-q-a {
  padding: 8px 10px 3px 10px;

  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  color: #152138;

  display: flex;
  align-items: center;
  justify-content:space-between
} */

.button-comp-mar-col {
  margin: 55px 60px;
  width: 175px;
  height: 34px;
  align-self: flex-end;

  color: white;
  background-color: #05bb8a;
  border: 1px solid #06091b;
  box-shadow: 0px 4px 4px rgb(167, 164, 164);
  border-radius: 10px;
}

@media screen and (max-width: 840px) {
  .calender-user-dis-hc-view {
    display: none;
  }

  .button-comp-mar-col {
    display: none;
  }

  .insane-tech {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 840px) {
  .component-hc-layout-wdt-100 {
    width: 70%;
    margin-right: 130px;
    align-items: flex-start;
  }

  .hc-content-view-2 {
    width: 90%;
    padding: 10px;

    min-height: 120px;
  }

  .content-component-teacher-op-hc {
    padding: 5px;
    font-weight: 700;
    font-size: 1.4em;
  }

  .content-Comp-2-hc-in {
    font-size: 14px;
  }

  .content-table-hc-list {
    font-size: 14px;
    padding: 0px;
  }

  .content-table-hc-list-q-a {
    font-size: 16px;
  }

  .table-item-hc-content {
    min-width: 30px;
  }
}
