/* screen - relational-manager-ticket-list */

.relational-manager-ticket-list {
  align-items: flex-start;
  background-color: var(--coconut);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}
.relational-manager-ticket-list .accountbalance-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-ticket-list .analytics-24px-4-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-ticket-list .biba-sobti-vgli2x {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2px;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.relational-manager-ticket-list .bibasobtigmailcom-vgli2x {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2px;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.relational-manager-ticket-list .category-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 301px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 76px;
}
.relational-manager-ticket-list .category-fqPQjE {
  background-color: transparent;
  height: auto;
  /* left: 625px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 14px; */
  width: 85px;
}
.relational-manager-ticket-list .class-name-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 359px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 100px;
}
.relational-manager-ticket-list .component-1-GyJUNH {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.relational-manager-ticket-list .dashboard-24px-1-1UTYTe {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 596px;
  overflow: hidden;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.relational-manager-ticket-list .dashboard-24px-1-PXwdVf {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 374px;
  overflow: hidden;
  padding: 0 11.8px;
  position: absolute;
  top: 49px;
  width: 24px;
}
.relational-manager-ticket-list .date-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 628px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 224px;
  width: 44px;
}
.relational-manager-ticket-list .date-fqPQjE {
  background-color: transparent;
  height: auto;
  /* left: 536px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 16px; */
  width: 59px;
}
.relational-manager-ticket-list .ellipse-2-RYyMFx {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.relational-manager-ticket-list .flex-col-C61RwL-wwa {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 85px;
  margin-top: 45px;
  min-height: 980px;
  position: relative;
  width: 1285px;
}
.relational-manager-ticket-list .flex-col-QkTteQ {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 703px;
  position: relative;
  width: 781px;
}
.relational-manager-ticket-list .flex-row-0xy0vn {
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 105px;
  justify-content: flex-start;
  margin-right: 22px;
  min-width: 1231px;
  position: relative;
  width: auto;
}
.relational-manager-ticket-list .flex-row-axsvJs {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.relational-manager-ticket-list .flex-row-oV5UrE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 720px;
  justify-content: flex-start;
  margin-top: 62px;
  min-width: 1285px;
  position: relative;
  width: auto;
}
.relational-manager-ticket-list .group-117-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 66px;
  width: 728px;
}
.relational-manager-ticket-list .group-118-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 126px;
  width: 728px;
}
.relational-manager-ticket-list .group-119-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 178px;
  width: 728px;
}
.relational-manager-ticket-list .group-120-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 234px;
  width: 728px;
}
.relational-manager-ticket-list .group-121-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 290px;
  width: 728px;
}
.relational-manager-ticket-list .group-122-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 346px;
  width: 728px;
}
.relational-manager-ticket-list .group-123-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 412px;
  width: 728px;
}
.relational-manager-ticket-list .group-124-PXwdVf {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 33px;
  position: absolute;
  top: 478px;
  width: 728px;
}
.relational-manager-ticket-list .group-125-4xLMVj {
  background-color: var(--blue-zodiac);
  border-radius: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 318px;
}
.relational-manager-ticket-list .group-202-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 83px;
  min-height: 637px;
  position: relative;
  width: 421px;
}
.relational-manager-ticket-list .group-204-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-right: 59px;
  margin-top: 49px;
  position: relative;
  width: 320px;
}
.relational-manager-ticket-list .group-209-C61RwL-101-0 {
  align-items: flex-end;
  background-color: #1f2531;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 100%;
  padding: 50px 18px;
  position: fixed;
  overflow: hidden;
  z-index: 10;
  width: 63px;
}
.relational-manager-ticket-list .group-22-GyJUNH {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 8px;
  margin-left: 22px;
  min-height: 57px;
  position: relative;
  width: 225px;
}
.relational-manager-ticket-list .group-45-1UTYTe {
  background-color: transparent;
  height: 53px;
  left: 0px;
  position: absolute;
  top: 6px;
  width: 759px;
}
.relational-manager-ticket-list .group-56-PXwdVf-kunal {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  width: 708px;
  padding: 10px 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.relational-manager-ticket-list .group-91-C8nS5Z {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 369px;
}
.relational-manager-ticket-list .group-92-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 365px;
}
.relational-manager-ticket-list .group-94-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 365px;
}
.relational-manager-ticket-list .group-95-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 365px;
}
.relational-manager-ticket-list .group-96-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 365px;
}
.relational-manager-ticket-list .group-99-C8nS5Z {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 351px;
  width: 369px;
}
.relational-manager-ticket-list .group-AAVFy6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.relational-manager-ticket-list .group-XDTIuB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.relational-manager-ticket-list .group-aTF8dW {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.relational-manager-ticket-list .group-rAjf8W {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.relational-manager-ticket-list .help-24px-2-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-ticket-list .historytoggleoff-24px-1-D0BVcB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-ticket-list .in-progess-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 210px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 72px;
}
.relational-manager-ticket-list .issue-id-fqPQjE {
  background-color: transparent;
  height: auto;
  /* left: 24px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  /* top: 14px; */
  width: 80px;
}
.relational-manager-ticket-list .kunal-kashyap-mHjvIC {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 105px;
}
.relational-manager-ticket-list .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.relational-manager-ticket-list .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.relational-manager-ticket-list .messages-4xLMVj {
  background-color: transparent;
  height: auto;
  left: 64px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 11px;
  width: auto;
}
.relational-manager-ticket-list .my-tickets-Fjgz1C-0012 {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 22px;
  min-height: 26px;
  position: relative;
  width: 742px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.relational-manager-ticket-list .next-PXwdVf {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 711px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}
.relational-manager-ticket-list .notifications-24px-2-1-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-bottom: 13px;
  margin-left: 643px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.relational-manager-ticket-list .open-issue-list-4FsOUs {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 5px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.relational-manager-ticket-list .overlap-group-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 575px;
  margin-top: 22px;
  position: relative;
  width: 772px;
}
.relational-manager-ticket-list .overlap-group1-Fjgz1C {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 59px;
  margin-top: 21px;
  position: relative;
  width: 759px;
}
.relational-manager-ticket-list .overlap-group2-4FsOUs {
  background-color: transparent;
  flex-shrink: 1;
  height: 600px;
  margin-top: 14px;
  position: relative;
  width: 383px;
}
.relational-manager-ticket-list .pending-lb6EYp {
  background-color: transparent;
  height: auto;
  left: 209px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 56px;
}
.relational-manager-ticket-list .rectangle-20-fqPQjE-kunal-8K {
  background-color: #dc3545;
  border-radius: 29px;
  height: 53px;
  padding: 10px 25px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 749px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.relational-manager-ticket-list .rectangle-30-PXwdVf-10-1 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 575px;
  left: 20px;
  position: absolute;
  top: 0px;
  width: 760px;
  padding: 10px;
}
.relational-manager-ticket-list .rectangle-31-C8nS5Z {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 600px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.relational-manager-ticket-list .rectangle-40-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.relational-manager-ticket-list .rectangle-41-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.relational-manager-ticket-list .rectangle-47-C8nS5Z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.relational-manager-ticket-list .rectangle-48-lb6EYp {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.relational-manager-ticket-list .rectangle-48-mHjvIC {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.relational-manager-ticket-list .rectangle-7-PXwdVf {
  background-color: transparent;
  height: 1px;
  left: 525px;
  position: absolute;
  top: 274px;
  width: 1px;
}
.relational-manager-ticket-list .s-no-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.relational-manager-ticket-list .s-no-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 56px;
}
.relational-manager-ticket-list .school-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.relational-manager-ticket-list .shreyans-jain-lb6EYp {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 93px;
}
.relational-manager-ticket-list .sos-3H8fcZ {
  background-color: transparent;
  height: auto;
  left: 315px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 34px;
}
.relational-manager-ticket-list .sos-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 315px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 34px;
}
.relational-manager-ticket-list .span0-xITcw8 {
  color: #dc3545;
  font-style: normal;
}
.relational-manager-ticket-list .span1-xITcw8 {
  color: #dc3545;
  font-style: normal;
  font-weight: 600;
}
.relational-manager-ticket-list .standard-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 301px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 366px;
  width: 61px;
}
.relational-manager-ticket-list .status-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 220px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.relational-manager-ticket-list .student-classroom-fqPQjE {
  background-color: transparent;
  height: auto;
  /* left: 135px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 14px; */
  width: 183px;
}
.relational-manager-ticket-list .student-name-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 83px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 107px;
}
.relational-manager-ticket-list .student-name-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 118px;
}
.relational-manager-ticket-list .ticket-mHjvIC {
  background-color: transparent;
  height: auto;
  left: 211px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 47px;
}
.relational-manager-ticket-list .ticket-status-fqPQjE {
  background-color: transparent;
  height: auto;
  /* left: 357px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 14px; */
  width: 40px;
}
.relational-manager-ticket-list .time-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 724px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 223px;
  width: 48px;
}
.relational-manager-ticket-list .vatsal-agarwal-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 85px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 104px;
}
.relational-manager-ticket-list .vector-1duCl1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-ticket-list .vector-2CQzkB {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.relational-manager-ticket-list .vector-2xKpiG {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-ticket-list .vector-40xw7g {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.relational-manager-ticket-list .vector-4xLMVj {
  background-color: transparent;
  height: 12px;
  left: 282px;
  position: absolute;
  top: 17px;
  width: 18px;
}
.relational-manager-ticket-list .vector-AxqJxr {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.relational-manager-ticket-list .vector-Axu9vm {
  background-color: transparent;
  height: 30px;
  left: 10px;
  position: absolute;
  top: 7px;
  width: 30px;
}
.relational-manager-ticket-list .vector-Ayxm2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  position: relative;
  width: 24px;
}
.relational-manager-ticket-list .vector-BRkMYc {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.relational-manager-ticket-list .vector-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.relational-manager-ticket-list .vector-GyJUNH {
  align-self: flex-start;
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 7px;
  margin-top: 6px;
  position: relative;
  width: 80px;
}
.relational-manager-ticket-list .vector-I29KJn {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-ticket-list .vector-NlXsxj {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.relational-manager-ticket-list .vector-OUzaf1 {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.relational-manager-ticket-list .vector-RYyMFx {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.relational-manager-ticket-list .vector-WxmxmD {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.relational-manager-ticket-list .vector-XxcEsk {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.relational-manager-ticket-list .vector-YPcxtl {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.relational-manager-ticket-list .vector-Zppvke {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.relational-manager-ticket-list .vector-axsvJs {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.relational-manager-ticket-list .vector-uxDhU3 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.relational-manager-ticket-list .vector-x0nTHj {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.relational-manager-ticket-list .vector-x6oIim {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.relational-manager-ticket-list .x1-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 15px;
}
.relational-manager-ticket-list .x1-PXwdVf {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 645px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}
.relational-manager-ticket-list .x2-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 130px;
  width: 15px;
}
.relational-manager-ticket-list .x2-PXwdVf {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 667px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}
.relational-manager-ticket-list .x3-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 189px;
  width: 15px;
}
.relational-manager-ticket-list .x3-PXwdVf {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 689px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 538px;
  width: auto;
}
.relational-manager-ticket-list .x4-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 15px;
}
.relational-manager-ticket-list .x5-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 304px;
  width: 15px;
}
.relational-manager-ticket-list .x6-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 366px;
  width: 15px;
}
.relational-manager-ticket-list .x7-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 422px;
  width: 15px;
}
.relational-manager-ticket-list .x8-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 481px;
  width: 15px;
}
.relational-manager-ticket-list .x9-C8nS5Z {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 540px;
  width: 15px;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-bold-white-14px {
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - relational-manager-ticket-mobile-10 */

.relational-manager-ticket-mobile-10 {
  height: auto;

  padding: 24px 6px;
  /* position: relative; */
  min-width: 390px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: auto;
}
.relational-manager-ticket-mobile-10 .booked-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 286px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 477px;
  width: 50px;
}
.relational-manager-ticket-mobile-10 .booked-rg1zwV {
  background-color: transparent;
  height: auto;
  left: 284px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 50px;
}
.relational-manager-ticket-mobile-10 .category-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 263px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 92px;
}
.relational-manager-ticket-mobile-10 .category-aIq8ji {
  background-color: transparent;
  height: auto;
  /* left: 290px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 13px; */
  width: 66px;
}
.relational-manager-ticket-mobile-10 .class-name-eVSctS {
  background-color: transparent;
  height: auto;
  left: 167px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 48px;
}
.relational-manager-ticket-mobile-10 .completed-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 184px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 74px;
}
.relational-manager-ticket-mobile-10 .component-1-dXBp89 {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.relational-manager-ticket-mobile-10 .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.relational-manager-ticket-mobile-10 .dashboard-24px-1-759J8x {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 288px;
  overflow: hidden;
  padding: 0 5.3px;
  position: absolute;
  top: 0px;
  width: 11px;
}
.relational-manager-ticket-mobile-10 .date-aIq8ji {
  background-color: transparent;
  height: auto;
  /* left: 241px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 13px; */
  width: 35px;
}
.relational-manager-ticket-mobile-10 .date-eVSctS {
  background-color: transparent;
  height: auto;
  left: 292px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 250px;
  width: 22px;
}
.relational-manager-ticket-mobile-10 .flex-row-C61RwL {
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.relational-manager-ticket-mobile-10 .group-124-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 47px;
  width: 348px;
}
.relational-manager-ticket-mobile-10 .group-125-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 87px;
  width: 348px;
}
.relational-manager-ticket-mobile-10 .group-126-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 127px;
  width: 348px;
}
.relational-manager-ticket-mobile-10 .group-127-C61RwL-10-11 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;

  margin-top: 58px;
  min-height: 605px;
  left: 0px;
  position: relative;
  width: 360px;
}
.relational-manager-ticket-mobile-10 .group-127-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 167px;
  width: 348px;
}
.relational-manager-ticket-mobile-10 .group-128-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 207px;
  width: 348px;
}
.relational-manager-ticket-mobile-10 .group-129-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 247px;
  width: 348px;
}
.relational-manager-ticket-mobile-10 .group-130-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 287px;
  width: 348px;
}
.relational-manager-ticket-mobile-10 .group-131-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 327px;
  width: 348px;
}
.relational-manager-ticket-mobile-10 .group-132-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 367px;
  width: 348px;
}
.relational-manager-ticket-mobile-10 .group-133-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 407px;
  width: 348px;
}
.relational-manager-ticket-mobile-10 .group-134-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 30px;
  left: 6px;
  position: absolute;
  top: 447px;
  width: 348px;
}
.relational-manager-ticket-mobile-10 .group-210-C61RwL-10-101-1 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 72px;
  min-height: 581px;
  left: 0px;
  position: relative;
  width: 360px;
}
.relational-manager-ticket-mobile-10 .group-45-759J8x {
  background-color: transparent;
  height: 42px;
  left: 0px;
  position: absolute;
  top: 6px;
  width: 369px;
}
.relational-manager-ticket-mobile-10 .group-56-eVSctS {
  background-color: var(--coconut);
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 8px 14px;
  height: 30px;
  width: 340px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
}
.relational-manager-ticket-mobile-10 .group-90-SuOXvz {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 170px;
  width: 346px;
}
.relational-manager-ticket-mobile-10 .group-91-SuOXvz {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 464px;
  width: 348px;
}
.relational-manager-ticket-mobile-10 .group-92-SuOXvz {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 287px;
  width: 346px;
}
.relational-manager-ticket-mobile-10 .group-94-SuOXvz {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 405px;
  width: 346px;
}
.relational-manager-ticket-mobile-10 .group-95-SuOXvz {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 346px;
  width: 346px;
}
.relational-manager-ticket-mobile-10 .group-96-SuOXvz {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 233px;
  width: 346px;
}
.relational-manager-ticket-mobile-10 .issue-id-aIq8ji {
  background-color: transparent;
  height: auto;
  /* left: 10px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 13px; */
  width: 57px;
}
.relational-manager-ticket-mobile-10 .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.relational-manager-ticket-mobile-10 .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.relational-manager-ticket-mobile-10 .my-tickets-2zqKZg {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 10px;
  min-height: 26px;
  position: relative;
  width: 308px;
}
.relational-manager-ticket-mobile-10 .next-eVSctS {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 322px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 31px;
}
.relational-manager-ticket-mobile-10 .open-issue-list-Uoyr1g {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 4px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.relational-manager-ticket-mobile-10 .opened-8KZUmx {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.relational-manager-ticket-mobile-10 .opened-GW4GxG {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.relational-manager-ticket-mobile-10 .opened-VzbLLP {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.relational-manager-ticket-mobile-10 .opened-dKpRzw {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.relational-manager-ticket-mobile-10 .opened-qtwhT7 {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.relational-manager-ticket-mobile-10 .organic-4-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 69px;
}
.relational-manager-ticket-mobile-10 .organic-5-mVyX3I {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.relational-manager-ticket-mobile-10 .overlap-group-2zqKZg {
  background-color: transparent;
  flex-shrink: 1;
  height: 511px;
  margin-left: -0px;
  margin-top: 7px;
  position: relative;
  width: 361px;
}
.relational-manager-ticket-mobile-10 .overlap-group1-2zqKZg {
  background-color: transparent;
  flex-shrink: 1;
  height: 48px;
  margin-left: 0px;
  margin-top: 13px;
  position: relative;
  width: 369px;
}
.relational-manager-ticket-mobile-10 .overlap-group2-Uoyr1g {
  background-color: transparent;
  flex-shrink: 1;
  height: 546px;
  margin-top: 12px;
  position: relative;
  width: 359px;
}
.relational-manager-ticket-mobile-10 .rectangle-20-aIq8ji {
  background-color: #dc3545;
  border-radius: 29px;
  padding: 8px 15px;
  height: 42px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.relational-manager-ticket-mobile-10 .rectangle-30-eVSctS-10-1-002 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 570px;
  left: 0px;
  position: absolute;
  top: 4px;
  width: 370px;

  padding: 10px;
}
.relational-manager-ticket-mobile-10 .rectangle-31-SuOXvz {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 546px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.relational-manager-ticket-mobile-10 .rectangle-40-SuOXvz {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 52px;
  width: 342px;
}
.relational-manager-ticket-mobile-10 .rectangle-41-SuOXvz {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 7px;
  position: absolute;
  top: 170px;
  width: 342px;
}
.relational-manager-ticket-mobile-10 .rectangle-44-8KZUmx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-ticket-mobile-10 .rectangle-44-VzbLLP {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-ticket-mobile-10 .rectangle-47-SuOXvz {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 111px;
  width: 342px;
}
.relational-manager-ticket-mobile-10 .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.relational-manager-ticket-mobile-10 .rectangle-48-mVyX3I {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-ticket-mobile-10 .rectangle-49-GW4GxG {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-ticket-mobile-10 .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.relational-manager-ticket-mobile-10 .rectangle-49-dKpRzw {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-ticket-mobile-10 .rectangle-49-qtwhT7 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.relational-manager-ticket-mobile-10 .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.relational-manager-ticket-mobile-10 .s-no-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 42px;
}
.relational-manager-ticket-mobile-10 .s-no-eVSctS {
  background-color: transparent;
  height: auto;
  left: 0px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 27px;
}
.relational-manager-ticket-mobile-10 .span0-6gnXOk {
  color: #dc3545;
  font-style: normal;
}
.relational-manager-ticket-mobile-10 .span1-6gnXOk {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
  font-weight: 500;
}
.relational-manager-ticket-mobile-10 .span2-6gnXOk {
  color: #dc3545;
  font-style: normal;
  font-weight: 600;
}
.relational-manager-ticket-mobile-10 .status-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 49px;
}
.relational-manager-ticket-mobile-10 .student-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 78px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 94px;
}
.relational-manager-ticket-mobile-10 .student-classroom-aIq8ji {
  background-color: transparent;
  height: auto;
  left: 73px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6px;
  width: 89px;
}
.relational-manager-ticket-mobile-10 .student-name-eVSctS {
  background-color: transparent;
  height: auto;
  left: 62px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 56px;
}
.relational-manager-ticket-mobile-10 .ticket-status-aIq8ji {
  background-color: transparent;
  height: auto;
  /* left: 154px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: center;
  /* top: 6px; */
  width: 83px;
}
.relational-manager-ticket-mobile-10 .time-eVSctS {
  background-color: transparent;
  height: auto;
  left: 337px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 249px;
  width: 23px;
}
.relational-manager-ticket-mobile-10 .upcoming-mVyX3I {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.relational-manager-ticket-mobile-10 .vector-L9XHu9 {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.relational-manager-ticket-mobile-10 .x1-eVSctS {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 7px;
}
.relational-manager-ticket-mobile-10 .x12pm-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 44px;
}
.relational-manager-ticket-mobile-10 .x1pm-SuOXvz {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 128px;
  width: 31px;
}
.relational-manager-ticket-mobile-10 .x2-eVSctS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 292px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 9px;
}
.relational-manager-ticket-mobile-10 .x2pm-GW4GxG {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.relational-manager-ticket-mobile-10 .x2pm-dKpRzw {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.relational-manager-ticket-mobile-10 .x2pm-qtwhT7 {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.relational-manager-ticket-mobile-10 .x3-eVSctS {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 307px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 494px;
  width: 9px;
}
.relational-manager-ticket-mobile-10 .x3pm-mVyX3I {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.relational-manager-ticket-mobile-10 .x4pm-8KZUmx {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.relational-manager-ticket-mobile-10 .x6pm-VzbLLP {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-bold-white-14px {
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-white-20px {
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --coconut: rgba(253, 254, 255, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .relational-manager-ticket-list {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .relational-manager-ticket-mobile-10 {
    display: none;
  }
}
