.mainFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 24px;
  padding: 40px;
}

h3 {
  font-weight: 600;
  color: White;
}

.footer_link {
  margin: 24px 0;
  cursor: pointer;
}

.footer_link:hover {
  text-decoration: none;
}

.footer_link2 {
  color: white;
}

.footer_link2:hover {
  text-decoration: underline;
  color: black;
}

.footer_icons {
  margin-right: 12px;
}
.footer_icons:hover {
  color: #dc3545;
}

.location_icon {
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .mainFooter {
    flex-direction: column;
    padding: 24px 16px;
  }
}
