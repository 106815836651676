.main {
  width: 100%;
  padding: 24px 40px;
}

.card {
  background-color: #dc3545;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 16px;
  height: 290px;
  border-radius: 8px;
  transition: box-shadow 0.4s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Subtle shadow */
}

.textbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card p {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .main {
    padding: 24px 16px;
  }
}
