.totalStudentConv-layout-wdt-100 {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.div-main-heading-in {
    align-self: flex-start;
    color: rgb(220, 53, 69);
    margin: 10px 20px;

    font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.totalStudentConv-view-1 {
    padding: 10px;
    width: 90%;
    margin-top: 20px;
    /* background-color: #dc3545; */

    background-color: white;

    box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.head-teachStudConv-view-1-in {
    width: 98%;
    margin: 10px 2px 20px 2px;

    font-size: 10px;
    color: rgb(107, 107, 107);
    font-family: "Montserrat", Helvetica;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.totalStudentConv-view-1-in {

    display: flex;
    align-items: center;
    justify-content: space-around;
}

.totalStudentConv-view-2 {
    padding: 10px;
    width: 90%;
    margin-top: 20px;
    /* background-color: #dc3545; */

    background-color: white;

    box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
    border-radius: 15px;

}

.pi-in-total-stu-conv {
    width: 45%;
    max-height: 70%;
    margin: 8px;
}

.Badge-in-10px {
    width: 50%;
    margin: 7px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    color: black;
}



.Badge-in-10px-container {
    display: flex;
    align-items: flex-start;
}

.p-text-badge {
    font-size: 7px;
    font-weight: 400;
    margin: 2px;
    padding: 0px;
}

.Table-Badge-in-10px {
    width: 98%;
    min-height: 220px;
    margin: 0px;
    /* font-size: 12px;
    font-weight: 500;
    font-family: "Montserrat", Arial, Helvetica, sans-serif; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.Table-Badge-in-10px-container {
    width: 100%;
    min-height: 20px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.Table-Badge-in-10px-p-container {
    margin: 0px;
    padding: 0px;

    color: rgb(24, 24, 83);

    font-size: 12px;
    font-weight: 600;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.logo-totalStudent-in {
    width: 170px;
    height: 80px;
    align-self: flex-start;

    margin: 20px;
    border: 1px solid black;

}

@media screen and (max-width: 839px) {
    .logo-totalStudent-in {
        display: none;
    }
}

@media screen and (min-width: 840px) {
    .totalStudentConv-layout-wdt-100 {
        width: 70%;
        margin-left: 60px;
    }

    .totalStudentConv-view-1 {
        padding: 15px;
        min-height: 290px;
    }

    .totalStudentConv-view-2 {
        padding: 15px;
        min-height: 330px;
    }

    .div-main-heading-in {
        margin: 16px 20px;

        font-size: 26px;

    }

    .pi-in-total-stu-conv {
        width: 45%;
        max-height: 40%;
        margin: 12px 15px;
    }

    .p-text-badge {
        font-size: 12px;
        font-weight: 500;
        margin: 3px;
        padding: 0px;
    }

    .Table-Badge-in-10px-p-container {
        font-size: 16px;
    }

    .head-teachStudConv-view-1-in {
        margin: 10px 2px 20px 2px;
        font-size: 14px;
    }
}