.about_main {
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.about_main h1 {
  font-size: 24px;
  font-weight: 700;
  color: #dc3545;
  text-align: center;
  line-height: 1.5;
}

.about_subTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  color: green !important;
  text-align: center;
  font-style: italic;
}
.about_main p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 4px;
}

.about_main h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
}
.about_main h3 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  line-height: 1.3;
  margin-bottom: 8px;
}
.bold {
  font-weight: 600;
}
.about_main li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  margin-left: 10px;
  list-style: disc inside;
  margin-bottom: 4px;
}
.sections {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .about_main {
    padding: 28px 16px;
    gap: 24px;
  }
  .about_main h1 {
    font-size: 20px;
  }
  .about_subTitle {
    font-size: 18px;
  }
  .about_main h2 {
    font-size: 18px;
  }
  .about_main h3 {
    font-size: 16px;
  }
  .about_main p {
    font-size: 15px;
  }
  .about_main li {
    font-size: 15px;
  }
}
