@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,500,600|Roboto:700,500");
/* screen - teacher-performance */

.montserrat-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-black-72px {
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-black-96px {
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.teacher-performance {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.teacher-performance .accountbalance-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-performance .analytics-24px-3-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-performance .assignment-24px-1-KSgdom {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-performance .assignments-quizzes-rylCBo {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 28px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: auto;
}
.teacher-performance .biba-sobti-Ocgsf1 {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-performance .bibasobtigmailcom-Ocgsf1 {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}

.teacher-performance .historytoggleoff-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-performance .quiz-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-performance .poweroff-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
  margin-top: 100px;
}

.teacher-performance .cancelled-LDZ1CX {
  background-color: transparent;
  height: auto;
  right: 10%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 203px;
  width: auto;
}
.teacher-performance .classes-BoR6E9 {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 12px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 76px;
}
.teacher-performance .classes-LDZ1CX {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 28px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: auto;
}
.teacher-performance .classrooms-vNyHFn {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 12px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 133px;
}
.teacher-performance .completed-LDZ1CX {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  right: 10%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 149px;
  width: auto;
}
.teacher-performance .component-1-0xy0vn {
  align-self: flex-start;
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
  margin-left: 5%;
}
.teacher-performance .description-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-performance .donut-chart-1-LDZ1CX {
  background-color: transparent;
  height: 254px;
  left: 9%;
  object-fit: cover;
  position: absolute;
  top: 55px;
  width: 230px;
}
.teacher-performance .ellipse-2-vPUDzL {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.teacher-performance .ellipse-5-LDZ1CX {
  background-color: transparent;
  height: 12px;
  right: 24%;
  position: absolute;
  top: 149px;
  width: 12px;
}
.teacher-performance .ellipse-6-LDZ1CX {
  background-color: transparent;
  height: 12px;
  right: 24%;
  position: absolute;
  top: 176px;
  width: 12px;
}
.teacher-performance .ellipse-7-LDZ1CX {
  background-color: transparent;
  height: 12px;
  right: 24%;
  position: absolute;
  top: 203px;
  width: 12px;
}
.teacher-performance .flex-col-C61RwL {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 8%;
  margin-top: 45px;
  min-height: 942px;
  position: relative;
  width: 40%;
}
.teacher-performance .flex-col-VMr6Om {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 4.6%;
  margin-top: 70px;
  min-height: 917px;
  position: relative;
  width: 16.5%;
}
.teacher-performance .flex-col-mzXdH9 {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 4.6%;
  margin-top: 51px;
  min-height: 936px;
  position: relative;
  width: 16.5%;
}
.teacher-performance .flex-col-oVwSO3 {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.teacher-performance .flex-row-WcGkdi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.teacher-performance .group-141-IGdxlK {
  background-color: transparent;
  flex-shrink: 1;
  height: 335px;
  margin-right: 2px;
  margin-top: 40px;
  position: relative;
  width: 100%;
}
.teacher-performance .group-142-4L6wx1 {
  background-color: transparent;
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-performance .group-142-szfML3 {
  background-color: transparent;
  flex-shrink: 1;
  height: 335px;
  position: relative;
  width: 100%;
}
.teacher-performance .group-143-lcFreP {
  background-color: transparent;
  flex-shrink: 1;
  height: 335px;
  margin-top: 210px;
  position: relative;
  width: 100%;
}
.teacher-performance .group-144-IGdxlK {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 335px;
  justify-content: flex-start;
  margin-top: 146px;
  position: relative;
  width: 100%;
}
.teacher-performance .group-160-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 88px;
  min-width: 24px;
  position: relative;
  width: auto;
}
.teacher-performance .group-162-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 778px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.teacher-performance .group-163-L8UHM8 {
  background-color: #1f2531;
  height: 1024px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 63px;
}
.teacher-performance .group-164-L8UHM8 {
  background-color: transparent;
  height: 778px;
  left: 20px;
  position: absolute;
  top: 200px;
  width: 24px;
}
.teacher-performance .group-165-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 1024px;
  position: relative;
  width: 63px;
}
.teacher-performance .group-22-IGdxlK {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  min-width: 316px;
  position: relative;
  width: auto;
}
.teacher-performance .group-57-1h9m5p {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-performance .group-57-BoR6E9 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-performance .group-57-LDZ1CX {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-performance .group-57-UvBJyI {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-performance .group-57-rylCBo {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-performance .group-57-vNyHFn {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-performance .group-69-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 335px;
  margin-top: 31px;
  position: relative;
  width: 100%;
}
.teacher-performance .group-70-JtY33o {
  background-color: transparent;
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-performance .group-70-qpCQPZ {
  background-color: transparent;
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-performance .group-71-y1HRxC {
  background-color: transparent;
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-performance .group-72-iYZvhX {
  background-color: transparent;
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-performance .group-73-cKEQA6 {
  background-color: transparent;
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-performance .group-9VbO77 {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  position: relative;
  width: 20px;
}
.teacher-performance .group-FHHSuW {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-performance .group-UKbWOd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.teacher-performance .group-alxHTq {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-performance .group-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: flex-start;
  left: 0px;
  min-width: 20px;
  position: absolute;
  top: 448px;
  width: auto;
}
.teacher-performance .group-tQmilE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.teacher-performance .help-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-performance .historytoggleoff-24px-1-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-performance .line-5-HhoarN {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.teacher-performance .line-6-HhoarN {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.teacher-performance .my-analytics-0xy0vn {
  align-self: flex-start;
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 32px;
  margin-top: 60px;
  min-height: 36px;
  position: relative;
  width: 310px;
}
.teacher-performance .notifications-24px-2-1-lcFreP {
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-right: 16px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.teacher-performance .overlap-group-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 335px;
  margin-top: 30px;
  position: relative;
  width: 100%;
}
.teacher-performance .overlap-group1-lcFreP {
  background-color: transparent;
  flex-shrink: 1;
  height: 335px;
  margin-top: 40px;
  position: relative;
  width: 100%;
}
.teacher-performance .rectangle-7-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-right: 92px;
  margin-top: 19px;
  position: relative;
  width: 1px;
}
.teacher-performance .scheduled-LDZ1CX {
  background-color: transparent;
  height: auto;
  right: 10%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 176px;
  width: auto;
}
.teacher-performance .school-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-performance .span0-ppPUeW {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-performance .span1-ppPUeW {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-performance .stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-performance .students-1h9m5p {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 12px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 133px;
}
.teacher-performance .subjects-UvBJyI {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 12px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 87px;
}
.teacher-performance .total-assignments-given-rylCBo {
  background-color: transparent;
  height: auto;
  left: 16%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 185px;
  width: auto;
}
.teacher-performance .total-quizzes-given-rylCBo {
  background-color: transparent;
  height: auto;
  left: 58%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 185px;
  width: auto;
}
.teacher-performance .vector-3ahjCQ {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-performance .vector-56AgMz {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.teacher-performance .vector-8k85HE {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 170px;
  position: relative;
  width: 24px;
}
.teacher-performance .vector-90KxxC {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-performance .vector-9xgjqN {
  background-color: transparent;
  height: 14px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.teacher-performance .vector-B8xRgA {
  background-color: transparent;
  height: 16px;
  left: 0px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-performance .vector-BKwYFx {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.teacher-performance .vector-EaMiW0 {
  background-color: transparent;
  height: 20px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 18px;
}
.teacher-performance .vector-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-performance .vector-SCg0eG {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  position: relative;
  width: 24px;
}
.teacher-performance .vector-SIAzDu {
  background-color: transparent;
  height: 20px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 16px;
}
.teacher-performance .vector-TCNT1O {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-performance .vector-UgkvXI {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-performance .vector-WcGkdi {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.teacher-performance .vector-XV2kdn {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.teacher-performance .vector-dlRKJA {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-performance .vector-fPPgx3 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-performance .vector-grLYV9 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-performance .vector-iniHBi {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-performance .vector-jWA1w5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.teacher-performance .vector-kCLhu1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-performance .vector-mju0OS {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-performance .vector-oRqxWx {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-performance .vector-oVwSO3 {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.teacher-performance .vector-s2Y5x6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.teacher-performance .vector-sS3zPF {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.teacher-performance .vector-vPUDzL {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.teacher-performance .vector-xbu9jm {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.teacher-performance .vector-yQhZ26 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-performance .x10-4L6wx1 {
  background-color: transparent;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 124px;
  width: auto;
}
.teacher-performance .x10-qpCQPZ {
  background-color: transparent;
  height: auto;
  left: 35%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 124px;
  width: auto;
}
.teacher-performance .x10-y1HRxC {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 65%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 109px;
  width: auto;
}
.teacher-performance .x2-iYZvhX {
  background-color: transparent;
  height: auto;
  left: 35%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 124px;
  width: auto;
}
.teacher-performance .x3-cKEQA6 {
  background-color: transparent;
  height: auto;
  left: 35%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 124px;
  width: auto;
}
.teacher-performance .x4-y1HRxC {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 25%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 109px;
  width: auto;
}
.teacher-performance .x5-JtY33o {
  background-color: transparent;
  height: auto;
  left: 35%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 124px;
  width: auto;
}
:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - teacher-performance-mobile */

.montserrat-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-black-72px {
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-black-96px {
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.teacher-performance-mobile {
  align-items: flex-end;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 1285px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 10.9px;
  position: relative;
  width: 414px;
}
.teacher-performance-mobile .assignments-quizzes-ymUorQ {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 220px;
}
.teacher-performance-mobile .cancelled-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 254px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 134px;
  width: 54px;
}
.teacher-performance-mobile .classes-8FQU39 {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 74px;
}
.teacher-performance-mobile .classes-kLGnId {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 94px;
}
.teacher-performance-mobile .classrooms-xx0RFl {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 94px;
}
.teacher-performance-mobile .completed-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 254px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 98px;
  width: 64px;
}
.teacher-performance-mobile .component-1-0PZcXW {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.teacher-performance-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 26px;
  margin-top: 13px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.teacher-performance-mobile .donut-chart-1-8FQU39 {
  background-color: transparent;
  height: 172px;
  left: 46px;
  object-fit: cover;
  position: absolute;
  top: 36px;
  width: 155px;
}
.teacher-performance-mobile .ellipse-5-8FQU39 {
  background-color: transparent;
  height: 9px;
  left: 238px;
  position: absolute;
  top: 100px;
  width: 9px;
}
.teacher-performance-mobile .ellipse-6-8FQU39 {
  background-color: transparent;
  height: 9px;
  left: 238px;
  position: absolute;
  top: 118px;
  width: 9px;
}
.teacher-performance-mobile .ellipse-7-8FQU39 {
  background-color: transparent;
  height: 9px;
  left: 238px;
  position: absolute;
  top: 136px;
  width: 9px;
}
.teacher-performance-mobile .flex-col-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 133px;
  position: relative;
  width: 310px;
}
.teacher-performance-mobile .flex-row-C61RwL {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 133px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacher-performance-mobile .flex-row-VMr6Om {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 218px;
  justify-content: flex-start;
  margin-right: 8.14px;
  margin-top: 24px;
  min-width: 375px;
  position: relative;
  width: auto;
}
.teacher-performance-mobile .flex-row-mzXdH9 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 218px;
  justify-content: flex-start;
  margin-right: 8.14px;
  margin-top: 25px;
  min-width: 375px;
  position: relative;
  width: auto;
}
.teacher-performance-mobile .group-142-Zd8H5Z {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 218px;
  justify-content: flex-start;
  min-width: 169px;
  position: relative;
  width: auto;
}
.teacher-performance-mobile .group-142-fMSFOV {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 218px;
  justify-content: flex-start;
  min-width: 169px;
  position: relative;
  width: auto;
}
.teacher-performance-mobile .group-142-iVz8z0 {
  background-color: transparent;
  flex-shrink: 1;
  height: 218px;
  position: relative;
  width: 171px;
}
.teacher-performance-mobile .group-142-jIRyYZ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 218px;
  justify-content: flex-start;
  min-width: 169px;
  position: relative;
  width: auto;
}
.teacher-performance-mobile .group-143-Jge9fh {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 218px;
  justify-content: flex-start;
  left: 0px;
  min-width: 169px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-performance-mobile .group-144-84keex {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 218px;
  justify-content: flex-start;
  left: 0px;
  min-width: 169px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-performance-mobile .group-145-F1UMB2 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 218px;
  justify-content: flex-start;
  min-width: 169px;
  position: relative;
  width: auto;
}
.teacher-performance-mobile .group-145-NwNIwt {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 218px;
  justify-content: flex-start;
  margin-left: 37px;
  min-width: 169px;
  position: relative;
  width: auto;
}
.teacher-performance-mobile .group-218-q9xEPe {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 218px;
  justify-content: flex-start;
  left: 0px;
  min-width: 169px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-performance-mobile .group-57-8FQU39 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 225px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.teacher-performance-mobile .group-57-P36yGh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 218px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 169px;
}
.teacher-performance-mobile .group-57-kLGnId {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 218px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 169px;
}
.teacher-performance-mobile .group-57-xVQImY {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 218px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 169px;
}
.teacher-performance-mobile .group-57-xx0RFl {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 218px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 169px;
}
.teacher-performance-mobile .group-57-ymUorQ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.teacher-performance-mobile .group-69-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 225px;
  margin-top: 44px;
  position: relative;
  width: 384px;
}
.teacher-performance-mobile .group-70-5VqovO {
  background-color: transparent;
  flex-shrink: 1;
  height: 218px;
  position: relative;
  width: 171px;
}
.teacher-performance-mobile .group-70-RVY61y {
  background-color: transparent;
  flex-shrink: 1;
  height: 218px;
  position: relative;
  width: 171px;
}
.teacher-performance-mobile .group-70-wDb64m {
  background-color: transparent;
  flex-shrink: 1;
  height: 218px;
  position: relative;
  width: 171px;
}
.teacher-performance-mobile .group-70-yKZgYD {
  background-color: transparent;
  height: 218px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 171px;
}
.teacher-performance-mobile .group-71-eSNWXa {
  background-color: transparent;
  height: 335px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 382px;
}
.teacher-performance-mobile .line-5-mHq818 {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.teacher-performance-mobile .line-6-mHq818 {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.teacher-performance-mobile .my-analytics-0PZcXW {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 52px;
  min-height: 36px;
  position: relative;
  width: 310px;
}
.teacher-performance-mobile .overlap-group-BdpSxK {
  background-color: transparent;
  flex-shrink: 1;
  height: 218px;
  position: relative;
  width: 169px;
}
.teacher-performance-mobile .overlap-group1-BdpSxK {
  background-color: transparent;
  flex-shrink: 1;
  height: 218px;
  margin-left: 37px;
  position: relative;
  width: 169px;
}
.teacher-performance-mobile .overlap-group2-NwNIwt {
  background-color: transparent;
  flex-shrink: 1;
  height: 218px;
  position: relative;
  width: 169px;
}
.teacher-performance-mobile .overlap-group3-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 335px;
  margin-right: 2px;
  margin-top: 25px;
  position: relative;
  width: 382px;
}
.teacher-performance-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.teacher-performance-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-performance-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-performance-mobile .scheduled-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 254px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 116px;
  width: 57px;
}
.teacher-performance-mobile .span0-vfsNCJ {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-performance-mobile .span1-vfsNCJ {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-performance-mobile .students-P36yGh {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 94px;
}
.teacher-performance-mobile .subjects-xVQImY {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 94px;
}
.teacher-performance-mobile .total-assignments-given-ymUorQ {
  background-color: transparent;
  height: auto;
  left: 47px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 185px;
  width: 125px;
}
.teacher-performance-mobile .total-quizzes-given-ymUorQ {
  background-color: transparent;
  height: auto;
  left: 201px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 185px;
  width: 130px;
}
.teacher-performance-mobile .x10-Jge9fh {
  background-color: transparent;
  height: auto;
  left: 41px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 75px;
  width: 88px;
}
.teacher-performance-mobile .x10-eSNWXa {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 236px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 109px;
  width: 61px;
}
.teacher-performance-mobile .x10-q9xEPe {
  background-color: transparent;
  height: auto;
  left: 40px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 76px;
  width: 88px;
}
.teacher-performance-mobile .x2-yKZgYD {
  background-color: transparent;
  height: auto;
  left: 64px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 71px;
  width: 42px;
}
.teacher-performance-mobile .x3-84keex {
  background-color: transparent;
  height: auto;
  left: 41px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 75px;
  width: 88px;
}
.teacher-performance-mobile .x4-eSNWXa {
  background-color: transparent;
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 95px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 109px;
  width: 29px;
}
:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .teacher-performance {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .teacher-performance-mobile {
    display: none;
  }
}
