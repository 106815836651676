/* screen - rm-statistics */

.montserrat-medium-blue-zodiac-18px {
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-gray-7px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-black-96px {
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-niagara-8px {
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}
.rm-statistics {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 1440px;
}
.rm-statistics .accountbalance-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.rm-statistics .analytics-24px-4-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.rm-statistics .biba-sobti-8HzYQT {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.rm-statistics .bibasobtigmailcom-8HzYQT {
  background-color: transparent;
  color: vara(--white);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.rm-statistics .component-1-xKTZor {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.rm-statistics .donut-chart-1-oTpdOr {
  background-color: transparent;
  height: 392px;
  left: 147px;
  object-fit: cover;
  position: absolute;
  top: 85px;
  width: 492px;
}
.rm-statistics .ellipse-2-m1AuvR {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.rm-statistics .ellipse-5-oTpdOr {
  background-color: transparent;
  height: 19px;
  left: 758px;
  position: absolute;
  top: 229px;
  width: 27px;
}
.rm-statistics .ellipse-6-oTpdOr {
  background-color: transparent;
  height: 19px;
  left: 758px;
  position: absolute;
  top: 271px;
  width: 27px;
}
.rm-statistics .ellipse-7-oTpdOr {
  background-color: transparent;
  height: 20px;
  left: 758px;
  position: absolute;
  top: 312px;
  width: 27px;
}
.rm-statistics .ellipse-8-oTpdOr {
  background-color: transparent;
  height: 19px;
  left: 758px;
  position: absolute;
  top: 354px;
  width: 27px;
}
.rm-statistics .flex-col-0xy0vn {
  align-items: flex-end;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 41px;
  min-height: 585px;
  position: relative;
  width: 1207px;
}
.rm-statistics .flex-col-C61RwL-10-1 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 85px;
  margin-top: 45px;
  min-height: 977px;
  position: relative;
  width: 1239px;
}
.rm-statistics .flex-col-GyJUNH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 351px;
  position: relative;
  width: 902px;
}
.rm-statistics .flex-col-gkUG7I {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 14px;
  min-height: 345px;
  position: relative;
  width: 316px;
}
.rm-statistics .flex-col-j5WY2e {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.rm-statistics .flex-row-0xy0vn {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 351px;
  justify-content: flex-start;
  min-width: 1233px;
  position: relative;
  width: auto;
}
.rm-statistics .flex-row-3xHcwy-iciw {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 106px;
  justify-content: flex-start;
  min-width: 895px;
  position: relative;
  width: auto;
}
.rm-statistics .flex-row-axsvJs {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.rm-statistics .flex-row-d1Gp1h {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 192px;
  justify-content: flex-start;
  margin-top: 53px;
  min-width: 870px;
  position: relative;
  width: auto;
}
.rm-statistics .group-125-HerK4J {
  background-color: var(--blue-zodiac);
  border-radius: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 318px;
}
.rm-statistics .group-142-6apKlK {
  background-color: transparent;
  flex-shrink: 1;
  height: 192px;
  position: relative;
  width: 219px;
}
.rm-statistics .group-142-MAFAU8 {
  background-color: transparent;
  flex-shrink: 1;
  height: 192px;
  position: relative;
  width: 219px;
}
.rm-statistics .group-142-WaYlHd {
  background-color: transparent;
  flex-shrink: 1;
  height: 192px;
  position: relative;
  width: 219px;
}
.rm-statistics .group-142-qkrrvg {
  background-color: transparent;
  flex-shrink: 1;
  height: 192px;
  position: relative;
  width: 219px;
}
.rm-statistics .group-143-t3Ing0 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 192px;
  justify-content: flex-start;
  min-width: 217px;
  position: relative;
  width: auto;
}
.rm-statistics .group-161-t3Ing0 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 192px;
  justify-content: flex-start;
  margin-left: 108px;
  min-width: 217px;
  position: relative;
  width: auto;
}
.rm-statistics .group-162-t3Ing0 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 192px;
  justify-content: flex-start;
  margin-left: 109px;
  min-width: 217px;
  position: relative;
  width: auto;
}
.rm-statistics .group-163-nFOi0F {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 192px;
  justify-content: flex-start;
  margin-right: 3.71px;
  margin-top: 73px;
  min-width: 217px;
  position: relative;
  width: auto;
}
.rm-statistics .group-204-xpmbvu {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-right: 23px;
  margin-top: 25px;
  position: relative;
  width: 320px;
}
.rm-statistics .group-209-C61RwL-10-1 {
  align-items: flex-end;
  background-color: #1f2531;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 100%;
  padding: 50px 18px;
  position: fixed;
  z-index: 10;
  width: 63px;
}
.rm-statistics .group-22-nFOi0F {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  min-width: 316px;
  position: relative;
  width: auto;
}
.rm-statistics .group-57-6hhWQr {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 217px;
}
.rm-statistics .group-57-EoPRmN {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 217px;
}
.rm-statistics .group-57-cVNVVu {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 217px;
}
.rm-statistics .group-57-oTpdOr {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 516px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 1197px;
}
.rm-statistics .group-57-zE5xZQ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 217px;
}
.rm-statistics .group-69-O40pOL {
  background-color: transparent;
  height: 516px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 1207px;
}
.rm-statistics .group-70-1sM1lf {
  background-color: transparent;
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 219px;
}
.rm-statistics .group-70-GW5w5b {
  background-color: transparent;
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 219px;
}
.rm-statistics .group-70-apKywr {
  background-color: transparent;
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 219px;
}
.rm-statistics .group-70-kHK9TT {
  background-color: transparent;
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 219px;
}
.rm-statistics .group-AAVFy6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.rm-statistics .group-XDTIuB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.rm-statistics .group-aTF8dW {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.rm-statistics .group-rAjf8W {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.rm-statistics .help-24px-2-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.rm-statistics .historytoggleoff-24px-1-D0BVcB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.rm-statistics .issue-statistics-oTpdOr {
  background-color: transparent;
  color: #dc3545;
  height: auto;
  left: 60px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 35px;
  width: 145px;
}
.rm-statistics .line-5-GEiAP5 {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.rm-statistics .line-6-GEiAP5 {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.rm-statistics .messages-HerK4J {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 64px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 11px;
  width: auto;
}
.rm-statistics .my-statistics-xKTZor {
  align-self: flex-end;
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 265px;
  min-height: 36px;
  position: relative;
  width: 310px;
}
.rm-statistics .notifications-24px-2-1-xKTZor {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-bottom: 14px;
  margin-left: 66px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.rm-statistics .open-oTpdOr {
  background-color: transparent;
  height: auto;
  left: 808px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 354px;
  width: 113px;
}
.rm-statistics .overlap-group-xpmbvu {
  background-color: transparent;
  flex-shrink: 1;
  height: 516px;
  position: relative;
  width: 1207px;
}
.rm-statistics .rectangle-7-O40pOL {
  background-color: transparent;
  height: 1px;
  left: 475px;
  position: absolute;
  top: 194px;
  width: 1px;
}
.rm-statistics .resolved-by-me-cVNVVu {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 151px;
}
.rm-statistics .resolved-by-rm-oTpdOr {
  background-color: transparent;
  height: auto;
  left: 808px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 271px;
  width: 88px;
}
.rm-statistics .resolved-oTpdOr {
  background-color: transparent;
  height: auto;
  left: 808px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 230px;
  width: 126px;
}
.rm-statistics .resolved-zE5xZQ {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 121px;
}
.rm-statistics .school-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.rm-statistics .span0-liz3Y2 {
  color: #dc3545;
  font-style: normal;
}
.rm-statistics .span1-liz3Y2 {
  color: #dc3545;
  font-style: normal;
  font-weight: 600;
}
.rm-statistics .tickets-raised-EoPRmN {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 151px;
}
.rm-statistics .tickets-raised-oTpdOr {
  background-color: transparent;
  height: auto;
  left: 808px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 313px;
  width: 113px;
}
.rm-statistics .total-issues-6hhWQr {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 121px;
}
.rm-statistics .vector-1duCl1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.rm-statistics .vector-2CQzkB {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.rm-statistics .vector-2xKpiG {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.rm-statistics .vector-AxqJxr {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.rm-statistics .vector-Ayxm2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  position: relative;
  width: 24px;
}
.rm-statistics .vector-BRkMYc {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.rm-statistics .vector-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.rm-statistics .vector-HerK4J {
  background-color: transparent;
  height: 12px;
  left: 282px;
  position: absolute;
  top: 17px;
  width: 18px;
}
.rm-statistics .vector-I29KJn {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.rm-statistics .vector-Pq9x3u {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.rm-statistics .vector-SZiwRu {
  background-color: transparent;
  height: 30px;
  left: 10px;
  position: absolute;
  top: 7px;
  width: 30px;
}
.rm-statistics .vector-WxmxmD {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.rm-statistics .vector-XxcEsk {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.rm-statistics .vector-YPcxtl {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.rm-statistics .vector-Zppvke {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.rm-statistics .vector-axsvJs {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.rm-statistics .vector-j5WY2e {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.rm-statistics .vector-m1AuvR {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.rm-statistics .vector-uxDhU3 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.rm-statistics .vector-x0nTHj {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.rm-statistics .vector-x6oIim {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.rm-statistics .x10-GW5w5b {
  background-color: transparent;
  height: auto;
  left: 43px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 55px;
  width: 134px;
}
.rm-statistics .x3-apKywr {
  background-color: transparent;
  height: auto;
  left: 43px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 55px;
  width: 134px;
}
.rm-statistics .x3-kHK9TT {
  background-color: transparent;
  height: auto;
  left: 43px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 55px;
  width: 134px;
}
.rm-statistics .x7-1sM1lf {
  background-color: transparent;
  height: auto;
  left: 43px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 55px;
  width: 134px;
}
:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - rm-statistics-mobile */

.montserrat-medium-blue-zodiac-18px {
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-gray-7px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-black-96px {
  color: var(--black);
  font-family: "Montserrat", Helvetica;
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-niagara-8px {
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}
.rm-statistics-mobile {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 1476px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 0;
  position: relative;
  width: 414px;
}
.rm-statistics-mobile .assignment-1-EXDUvW {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.rm-statistics-mobile .assignment-1-LFvdd7 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.rm-statistics-mobile .assignment-1-URlHrD {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.rm-statistics-mobile .assignment-1-VqvJAl {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.rm-statistics-mobile .assignment-24px-3-1HHR9j {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.rm-statistics-mobile .assignment-24px-3-e62HuU {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.rm-statistics-mobile .assignment-24px-3-tOztDT {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.rm-statistics-mobile .assignment-24px-3-xgoJzt {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.rm-statistics-mobile .component-1-y0eQTV {
  align-self: flex-start;
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.rm-statistics-mobile .component-29-EXDUvW {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.rm-statistics-mobile .component-29-LFvdd7 {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.rm-statistics-mobile .component-29-URlHrD {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.rm-statistics-mobile .component-29-VqvJAl {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.rm-statistics-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 26px;
  margin-top: 13px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.rm-statistics-mobile .donut-chart-1-8FQU39 {
  background-color: transparent;
  height: 172px;
  left: 46px;
  object-fit: cover;
  position: absolute;
  top: 36px;
  width: 155px;
}
.rm-statistics-mobile .ellipse-4-1HHR9j {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.rm-statistics-mobile .ellipse-4-e62HuU {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.rm-statistics-mobile .ellipse-4-tOztDT {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.rm-statistics-mobile .ellipse-4-xgoJzt {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.rm-statistics-mobile .ellipse-5-8FQU39 {
  background-color: transparent;
  height: 9px;
  left: 238px;
  position: absolute;
  top: 100px;
  width: 9px;
}
.rm-statistics-mobile .ellipse-6-8FQU39 {
  background-color: transparent;
  height: 9px;
  left: 238px;
  position: absolute;
  top: 118px;
  width: 9px;
}
.rm-statistics-mobile .ellipse-7-8FQU39 {
  background-color: transparent;
  height: 9px;
  left: 238px;
  position: absolute;
  top: 136px;
  width: 9px;
}
.rm-statistics-mobile .ellipse-8-8FQU39 {
  background-color: transparent;
  height: 9px;
  left: 238px;
  position: absolute;
  top: 154px;
  width: 9px;
}
.rm-statistics-mobile .flex-col-Xi618D {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 50px;
  min-height: 1122px;
  position: relative;
  width: 310px;
}
.rm-statistics-mobile .flex-col-dXBp89 {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 23.44px;
  min-height: 162px;
  position: relative;
  width: 179px;
}
.rm-statistics-mobile .flex-row-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 1122px;
  justify-content: flex-start;
  margin-left: -203.86px;
  min-width: 589px;
  position: relative;
  width: auto;
}
.rm-statistics-mobile .group-142-7IAPPv {
  background-color: transparent;
  flex-shrink: 1;
  height: 192px;
  position: relative;
  width: 219px;
}
.rm-statistics-mobile .group-142-b16P8T {
  background-color: transparent;
  flex-shrink: 1;
  height: 192px;
  position: relative;
  width: 219px;
}
.rm-statistics-mobile .group-142-ieM5yY {
  background-color: transparent;
  flex-shrink: 1;
  height: 192px;
  position: relative;
  width: 219px;
}
.rm-statistics-mobile .group-142-zGQBKN {
  background-color: transparent;
  flex-shrink: 1;
  height: 192px;
  position: relative;
  width: 219px;
}
.rm-statistics-mobile .group-143-y0eQTV {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 192px;
  justify-content: flex-start;
  margin-right: 21.71px;
  margin-top: 61px;
  min-width: 217px;
  position: relative;
  width: auto;
}
.rm-statistics-mobile .group-161-y0eQTV {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 192px;
  justify-content: flex-start;
  margin-right: 20.71px;
  margin-top: 50px;
  min-width: 217px;
  position: relative;
  width: auto;
}
.rm-statistics-mobile .group-162-y0eQTV {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 192px;
  justify-content: flex-start;
  margin-right: 20.71px;
  margin-top: 55px;
  min-width: 217px;
  position: relative;
  width: auto;
}
.rm-statistics-mobile .group-163-y0eQTV {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 192px;
  justify-content: flex-start;
  margin-right: 20.71px;
  margin-top: 55px;
  min-width: 217px;
  position: relative;
  width: auto;
}
.rm-statistics-mobile .group-57-8FQU39 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 225px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.rm-statistics-mobile .group-57-HxxsyS {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 217px;
}
.rm-statistics-mobile .group-57-ObkCNx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 217px;
}
.rm-statistics-mobile .group-57-xvYkoy {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 217px;
}
.rm-statistics-mobile .group-57-ytlf7G {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 217px;
}
.rm-statistics-mobile .group-69-C61RwL {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 225px;
  margin-left: 10.14px;
  margin-top: 55px;
  position: relative;
  width: 386px;
}
.rm-statistics-mobile .group-70-4yUOVC {
  background-color: transparent;
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 219px;
}
.rm-statistics-mobile .group-70-ByagiY {
  background-color: transparent;
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 219px;
}
.rm-statistics-mobile .group-70-F7BMxN {
  background-color: transparent;
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 219px;
}
.rm-statistics-mobile .group-70-kq6nGX {
  background-color: transparent;
  height: 192px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 219px;
}
.rm-statistics-mobile .group-72-0PZcXW {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-left: 0.67px;
  position: relative;
  width: 179px;
}
.rm-statistics-mobile .group-73-0PZcXW {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-left: 0.67px;
  margin-top: 7px;
  position: relative;
  width: 179px;
}
.rm-statistics-mobile .group-74-0PZcXW {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-left: 0.67px;
  margin-top: 7px;
  position: relative;
  width: 179px;
}
.rm-statistics-mobile .group-75-0PZcXW {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-top: 7px;
  position: relative;
  width: 179px;
}
.rm-statistics-mobile .issue-statistics-8FQU39 {
  background-color: transparent;
  color: #dc3545;
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 138px;
}
.rm-statistics-mobile .line-5-VqzB3J {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.rm-statistics-mobile .line-6-VqzB3J {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.rm-statistics-mobile .mathematics-dr-yashna-sharma-EXDUvW {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.rm-statistics-mobile .mathematics-dr-yashna-sharma-LFvdd7 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.rm-statistics-mobile .mathematics-dr-yashna-sharma-URlHrD {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.rm-statistics-mobile .mathematics-dr-yashna-sharma-VqvJAl {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.rm-statistics-mobile .my-statistics-y0eQTV {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 52px;
  min-height: 36px;
  position: relative;
  width: 310px;
}
.rm-statistics-mobile .open-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 254px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 154px;
  width: 76px;
}
.rm-statistics-mobile .rectangle-40-EXDUvW {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.rm-statistics-mobile .rectangle-40-LFvdd7 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.rm-statistics-mobile .rectangle-40-URlHrD {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.rm-statistics-mobile .rectangle-40-VqvJAl {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.rm-statistics-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.rm-statistics-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.rm-statistics-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.rm-statistics-mobile .resolved-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 254px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 100px;
  width: 64px;
}
.rm-statistics-mobile .resolved-by-me-HxxsyS {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 151px;
}
.rm-statistics-mobile .resolved-by-rm-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 254px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 118px;
  width: 86px;
}
.rm-statistics-mobile .resolved-xvYkoy {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 121px;
}
.rm-statistics-mobile .span0-p1GhMy {
  color: #dc3545;
  font-style: normal;
}
.rm-statistics-mobile .span1-p1GhMy {
  color: #dc3545;
  font-style: normal;
  font-weight: 600;
}
.rm-statistics-mobile .submitted-EXDUvW {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.rm-statistics-mobile .submitted-LFvdd7 {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.rm-statistics-mobile .submitted-URlHrD {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.rm-statistics-mobile .submitted-VqvJAl {
  background-color: transparent;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.rm-statistics-mobile .tickets-raised-8FQU39 {
  background-color: transparent;
  height: auto;
  left: 254px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 136px;
  width: 76px;
}
.rm-statistics-mobile .tickets-raised-ObkCNx {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 151px;
}
.rm-statistics-mobile .total-issues-ytlf7G {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 121px;
}
.rm-statistics-mobile .vector-JGyev7 {
  background-color: transparent;
  height: 8px;
  left: 1px;
  position: absolute;
  top: -0px;
  width: 7px;
}
.rm-statistics-mobile .vector-VZdL8H {
  background-color: transparent;
  height: 8px;
  left: 1px;
  position: absolute;
  top: -0px;
  width: 7px;
}
.rm-statistics-mobile .vector-WcNXVM {
  background-color: transparent;
  height: 7px;
  left: 1px;
  position: absolute;
  top: 0px;
  width: 7px;
}
.rm-statistics-mobile .vector-XgFQAe {
  background-color: transparent;
  height: 8px;
  left: 1px;
  position: absolute;
  top: -0px;
  width: 7px;
}
.rm-statistics-mobile .vector-eH2jVX {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.rm-statistics-mobile .vector-sxwcxa {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.rm-statistics-mobile .vector-wEaHDs {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.rm-statistics-mobile .vector-xkta9U {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.rm-statistics-mobile .x10-4yUOVC {
  background-color: transparent;
  height: auto;
  left: 43px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 55px;
  width: 134px;
}
.rm-statistics-mobile .x3-ByagiY {
  background-color: transparent;
  height: auto;
  left: 43px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 55px;
  width: 134px;
}
.rm-statistics-mobile .x3-kq6nGX {
  background-color: transparent;
  height: auto;
  left: 43px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 55px;
  width: 134px;
}
.rm-statistics-mobile .x7-F7BMxN {
  background-color: transparent;
  height: auto;
  left: 43px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 55px;
  width: 134px;
}
:root {
  --black: rgba(0, 0, 0, 1);
  --blue-zodiac: rgba(19, 37, 71, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .rm-statistics {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .rm-statistics-mobile {
    display: none;
  }
}
