.main {
  width: 100%;
  padding: 40px;
  list-style: 1.3;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.heading {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #dc3545;
  line-height: 1.5;
}
.heading2 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: green;
  margin-bottom: 10px;
  line-height: 1.3;
}
.title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
}

p {
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
  margin-bottom: 4px;
}
.bold {
  font-weight: 600;
}
.main li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  margin-left: 10px;
  list-style: disc inside;
  margin-bottom: 4px;
}

.fromWraper {
  width: 60%;
  border: 5px solid #dc3545;
  border-radius: 8px;
  padding: 40px 32px;
}

@media screen and (max-width: 768px) {
  .main {
    gap: 24px;
    padding: 28px 16px;
  }
  .heading {
    font-size: 20px;
  }
  .heading2 {
    font-size: 18px;
  }
  p {
    font-size: 15px;
  }
  .main li {
    font-size: 15px;
  }
  .fromWraper {
    width: 100%;
    border: 3px solid #dc3545;
    border-radius: 8px;
    padding: 16px 12px;
  }
}
