.Login_outer{
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
}

.Login_Layout {
  width: 35%;
  margin-top: 80px;
  max-height: 520px;

  /* max-height: 600px;
  min-height: 520px;
  margin-top: 160px; */

  background-color: rgba(255, 255, 255, 0.966);
  box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.2), 0 10px 10px 0 rgba(0, 0, 0, 0.24);
  border-radius: 14px;
  padding: 24px 0px 0px 0px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/* .Logo_Margin {
  max-width: 140px;
  max-height: 80px;
} */

/* #E3241D */
.Login_Page_Heading {
  margin: 10px;
  padding: 10px;

  text-align: center;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 24px;

  color: #e3241d;
}

.Login_Form_Handler {
  margin: 10px;
  width: 80%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Login_Form_Input {
  width: 100%;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  outline: 0;
  font-size: 18px;

  /* background: #f2f2f2; */

  border-radius: 10px;
  border: 0;
  box-sizing: border-box;
}

.Login_Butt_Sub {
  width: 98%;
  height: 42px;
  padding: 8px;
  margin-bottom: 18px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  outline: 0;
  font-size: 18px;
  font-weight: bolder;

  border-radius: 8px;
  border: 0;
  background: #e3241d;

  color: #ffffff;
  cursor: pointer;
}

.Message {
  padding: 2px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  color: #b3b3b3;
  font-size: 14px;

  text-align: center;
}


@media screen and (max-width: 840px) {
  .Login_Layout {
    width: 90%;
    max-height: 480px;
    margin: 30px 0px;
  }

  .Logo_Margin {
    max-width: 120px;
    max-height: 60px;
  }

  .Login_Page_Heading {
    width: 90%;
    margin: 5px;
    padding: 8px;

    font-weight: 700;
    font-size: 22px;
  }

  .Login_Form_Input {
    font-size: 16px;
  }

  .Login_Butt_Sub {
    height: 35px;

    font-size: 16px;
    font-weight: bold;
  }
}
