.Hc_detail_layout {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.sider-hc-teacher-list-2 {
  width: 65px;
  height: 100vh;

  position: relative;
  background-color: #212a3b;
}

.sider-hc-teacher-list-2-in-wdt-90 {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: #212a3b;
}

.icon-in-hc-color {
  color: rgba(179, 179, 179, 1);
  font-size: 26px;
}

.content-in-hc-layout-tech-list {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.content-in-hc-layout-in-wdt-90 {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content-in-hc-layout-in-wdt-90-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.hc-teacher-list-out-view-1 {
  padding: 10px;
  width: 90%;
  margin-top: 20px;
  /* background-color: #dc3545; */

  background-color: white;

  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.hc-form-in-wdt-align-teachers-list {
  width: 90%;
  height: 150px;
  margin-top: 10px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.butt-in-hc-form-4px-teachers-list {
  width: 50%;
  height: 38px;
  margin: 10px 5px;

  border-radius: 5px;
  color: white;
  background-color: rgb(220, 53, 69);
  border: none;

  font-size: 15px;

  box-shadow: 0px 2px 2px #ffff;
}

.hc-teacher-list-out-view-2-in {
  padding: 10px;
  width: 90%;
  margin-top: 20px;
  min-height: 160px;
  /* background-color: #dc3545; */

  background-color: white;

  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.hc-calender-status {
  width: 95%;
  padding-top: 6px;
  margin: 5px 0px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;

  color: grey;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hc-status-2 {
  width: 98%;
  min-height: 35px;
  margin: 0 auto;
  margin-top: 12px;
  border: 1px solid white;
  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 8px;
}

.hc-status-2-in {
  padding: 9px 5px 2px 5px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;

  color: #152138;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hc-calender-items-data {
  min-width: 30px;
  max-width: 52px;
}

.calender-hc-in-user-dis {
  min-height: 100px;
  width: 86%;
  margin: 10px 20px 39px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hc-dropdown-in-form {
  width: 80%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 841px) {
  .sider-hc-teacher-list-2 {
    display: none;
  }

  .calender-hc-in-user-dis {
    display: none;
  }
}

@media screen and (min-width: 1100px) {
  .margin-right {
    margin-right: 50px;
  }
}

@media screen and (min-width: 840px) {
  .header-wdt-100 {
    display: none;
  }

  .Hc_detail_layout {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .content-in-hc-layout-tech-list {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .content-in-hc-layout-in-wdt-90 {
    width: 60%;
    margin-left: 48px;
  }

  .content-in-hc-layout-in-wdt-90-2 {
    width: 70%;
    margin-right: 12px;
  }

  .hc-form-in-wdt-align-teachers-list {
    height: 160px;
    font-weight: 700;
  }

  .hc-calender-status {
    width: 94%;
    font-size: 12px;
  }

  .hc-calender-items-data {
    min-width: 50px;
    max-width: 150px;
  }

  .margin-right {
    margin-right: 20px;
  }

  .hc-status-2 {
    width: 95%;
  }

  .hc-status-2-in {
    font-size: 12px;
  }
}

.rectangle-31-Sgr7kw {
  width: 100%;
}