/* screen - teacher-qiuz-students */

.montserrat-medium-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-gray-7px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-gray-9px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-12px {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-14px {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-qiuz-students {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.teacher-qiuz-students .historytoggleoff-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-qiuz-students .quiz-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-qiuz-students .poweroff-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 100px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-qiuz-students .stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-qiuz-students .accountbalance-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-qiuz-students .add-7y4ohx {
  background-color: transparent;
  height: auto;
  left: 316px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-qiuz-students .add-RqwtHL {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 17.59px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 37px;
}
.teacher-qiuz-students .add-delete-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 287px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 87px;
}
.teacher-qiuz-students .analytics-24px-3-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-qiuz-students .assignment-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-qiuz-students .biba-sobti-JJC5aN {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-qiuz-students .bibasobtigmailcom-JJC5aN {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-qiuz-students .booked-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 53px;
}
.teacher-qiuz-students .booked-959ciF {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 53px;
}
.teacher-qiuz-students .castforeducation-24px-1-6z1aPT {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 7px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 22px;
}
.teacher-qiuz-students .castforeducation-24px-1-QNQvFM {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 7px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 22px;
}
.teacher-qiuz-students .castforeducation-24px-1-excy6a {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 7px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 22px;
}
.teacher-qiuz-students .choose-a-classr-ssign-a-new-one-0xy0vn {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 36px;
  min-height: 36px;
  position: relative;
  text-align: left;
  width: 100%;
}
.teacher-qiuz-students .choose-a-studen-ssign-a-new-one-0xy0vn {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 24px;
  min-height: 36px;
  position: relative;
  text-align: left;
  width: 100%;
}
.teacher-qiuz-students .class-name-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 93px;
}
.teacher-qiuz-students .class-strength-14-wojrxS {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: auto;
}
.teacher-qiuz-students .class-strength-20-xlTRPb {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}

.teacher-quiz-students .rectangle-40-xlTRPb .classTile-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 14px;
  height: 30px;
}

.teacher-quiz-students .classrooms-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 700px;
  overflow-y: auto;
}

.teacher-qiuz-students .class-strength-5-aXqM8B {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: auto;
}
.teacher-qiuz-students .completed-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 200px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 79px;
}
.teacher-qiuz-students .component-29-6bMYwa {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  width: 36px;
}
.teacher-qiuz-students .component-29-9BT75t {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-qiuz-students .component-29-J6zohZ {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-qiuz-students .component-29-MWA2s1 {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  flex-shrink: 1;
  height: 36px;
  width: 36px;
}
.teacher-qiuz-students .component-29-Q5Yq4g {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  flex-shrink: 1;
  height: 36px;
  position: relative;
  width: 36px;
}
.teacher-qiuz-students .component-29-c1EdLB {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  flex-shrink: 1;
  height: 36px;
  position: relative;
  width: 36px;
}
.teacher-qiuz-students .component-29-r5ocKj {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-qiuz-students .description-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-qiuz-students .ellipse-2-APAcB6 {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.teacher-qiuz-students .feb-18-2021-IMMU6x {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 116px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 113px;
}
.teacher-qiuz-students .flex-col-0sTO9E {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.teacher-qiuz-students .flex-col-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 8%;
  margin-top: 44.06px;
  min-height: 870px;
  position: relative;
  width: 48%;
}
.teacher-qiuz-students .flex-col-VMr6Om {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 7%;
  margin-top: 51px;
  min-height: 922px;
  position: relative;
  width: 32%;
}
.teacher-qiuz-students .flex-row-WcGkdi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.teacher-qiuz-students .flex-row-djxPV6 {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 23px;
  justify-content: flex-start;
  min-width: 377px;
  position: relative;
  width: auto;
}
.teacher-qiuz-students .group-112-djxPV6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 726px;
  margin-top: 14px;
  position: relative;
  width: 403px;
}
.teacher-qiuz-students .group-113-8pxyib {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 763px;
  position: relative;
  width: 387px;
}
.teacher-qiuz-students .group-114-lcFreP {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 763px;
  justify-content: flex-start;
  margin-top: 79px;
  position: relative;
  width: 100%;
}
.teacher-qiuz-students .group-115-lcFreP {
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  min-width: 376px;
  position: relative;
  width: auto;
}
.teacher-qiuz-students .group-116-huXul0 {
  background-color: transparent;
  /* flex-shrink: 1; */
  height: 67px;
  width: 100%;
}
.teacher-qiuz-students .group-117-huXul0 {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-top: 34px;
  position: relative;
  width: 661px;
}
.teacher-qiuz-students .group-118-huXul0 {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-top: 34px;
  position: relative;
  width: 661px;
}
.teacher-qiuz-students .group-160-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 88px;
  min-width: 24px;
  position: relative;
  width: auto;
}
.teacher-qiuz-students .group-162-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 778px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.teacher-qiuz-students .group-163-L8UHM8 {
  background-color: #1f2531;
  height: 1024px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 63px;
}
.teacher-qiuz-students .group-164-L8UHM8 {
  background-color: transparent;
  height: 778px;
  left: 20px;
  position: absolute;
  top: 200px;
  width: 24px;
}
.teacher-qiuz-students .group-165-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 1024px;
  position: relative;
  width: 63px;
}
.teacher-qiuz-students .group-166-K97w2w {
  background-color: transparent;
  height: 36px;
  left: 25px;
  position: absolute;
  top: 16px;
  width: 36px;
}
.teacher-qiuz-students .group-167-K97w2w {
  background-color: transparent;
  height: 36px;
  width: 36px;
}
.teacher-qiuz-students .group-168-nB2ZeT {
  background-color: transparent;
  height: 36px;
  left: 25px;
  position: absolute;
  top: 16px;
  width: 36px;
}
.teacher-qiuz-students .group-169-6z1aPT {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  min-width: 36px;
  width: auto;
}
.teacher-qiuz-students .group-169-HdvN1b {
  background-color: transparent;
  height: 36px;
  left: 25px;
  position: absolute;
  top: 16px;
  width: 36px;
}
.teacher-qiuz-students .group-169-QNQvFM {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  left: 0px;
  min-width: 36px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-qiuz-students .group-169-excy6a {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  left: 0px;
  min-width: 36px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-qiuz-students .group-174-0xy0vn {
  /* align-items: flex-start; */
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* flex-shrink: 1; */
  height: 280px;
  justify-content: flex-start;
  margin-top: 19px;
  /* height: 269px; */
  position: relative;
  width: 100%;
  /* overflow-x: hidden; */
  overflow-y: auto;
}
.teacher-qiuz-students .group-176-y1HRxC {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 269px;
  position: absolute;
  top: 0px;
  width: 657px;
}
.teacher-qiuz-students .group-179-y1HRxC {
  background-color: transparent;
  height: 36px;
  width: 36px;
}
.teacher-qiuz-students .group-180-y1HRxC {
  background-color: transparent;
  height: 36px;
  left: 25px;
  position: absolute;
  top: 117px;
  width: 36px;
}
.teacher-qiuz-students .group-181-y1HRxC {
  background-color: transparent;
  height: 36px;
  left: 25px;
  position: absolute;
  top: 218px;
  width: 36px;
}
.teacher-qiuz-students .group-22-cLbj0f {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  min-width: 316px;
  position: relative;
  width: auto;
}
.teacher-qiuz-students .group-47-nB2ZeT {
  background-color: transparent;
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 661px;
}
.teacher-qiuz-students .group-48-HdvN1b {
  background-color: transparent;
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 661px;
}
.teacher-qiuz-students .group-90-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 174px;
  width: 369px;
}
.teacher-qiuz-students .group-91-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 371px;
}
.teacher-qiuz-students .group-92-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 369px;
}
.teacher-qiuz-students .group-93-8MUoy6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 11px;
  min-height: 262px;
  position: absolute;
  top: 133px;
  width: 371px;
}
.teacher-qiuz-students .group-94-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 369px;
}
.teacher-qiuz-students .group-95-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 369px;
}
.teacher-qiuz-students .group-96-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 369px;
}
.teacher-qiuz-students .group-97-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 587px;
  width: 369px;
}
.teacher-qiuz-students .group-98-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 646px;
  width: 369px;
}
.teacher-qiuz-students .group-9VbO77 {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  position: relative;
  width: 20px;
}
.teacher-qiuz-students .group-FHHSuW {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-qiuz-students .group-Ph5Hqo {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-qiuz-students .group-UKbWOd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.teacher-qiuz-students .group-alxHTq {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-qiuz-students .group-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: flex-start;
  left: 0px;
  min-width: 20px;
  position: absolute;
  top: 448px;
  width: auto;
}
.teacher-qiuz-students .group-sEt7wO {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-qiuz-students .group-t3bIGy {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-qiuz-students .group-tQmilE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.teacher-qiuz-students .help-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-qiuz-students .historytoggleoff-24px-1-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-qiuz-students .kunal-kashyap-vXsQwr {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 127px;
}
.teacher-qiuz-students .mathematics-class-10-e9mijf {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: auto;
}
.teacher-qiuz-students .mathematics-class-10-wojrxS {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 190px;
}
.teacher-qiuz-students .mathematics-class-9-K97w2w {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: auto;
}
.teacher-qiuz-students .mathematics-class-9-vXsQwr {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 37px;
  width: auto;
}
.teacher-qiuz-students .mathematics-class-9-xlTRPb {
  background-color: transparent;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: 185px;
}
.teacher-qiuz-students .my-quizzes-0xy0vn {
  align-self: flex-start;
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 39px;
  min-height: 36px;
  position: relative;
  width: 100%;
}
.teacher-qiuz-students .notifications-24px-2-1-cLbj0f {
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.teacher-qiuz-students .open-calendar-IMMU6x {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.teacher-qiuz-students .opened-1ihGMx {
  background-color: transparent;
  height: auto;
  left: 304px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 53px;
}
.teacher-qiuz-students .opened-K0vR9a {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-qiuz-students .opened-SbEpTm {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-qiuz-students .opened-TYZK0W {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-qiuz-students .opened-aOipQ0 {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-qiuz-students .opened-oArczI {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-qiuz-students .opened-xEcNns {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-qiuz-students .organic-4-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 67px;
}
.teacher-qiuz-students .organic-5-wTq9AN {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 67px;
}
.teacher-qiuz-students .overlap-group-0xy0vn {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 269px;
  margin-top: 12px;
  position: relative;
  width: 100%;
}
.teacher-qiuz-students .overlap-group-RqwtHL {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 365px;
}
.teacher-qiuz-students .overlap-group1-Y9c9n6-001 {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}
.teacher-qiuz-students .overlap-group2-Y9c9n6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-top: 34px;
  position: relative;
  width: 661px;
}
.teacher-qiuz-students .overlap-group3-Y9c9n6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-top: 34px;
  position: relative;
  width: 661px;
}
.teacher-qiuz-students .physics-class-9-aXqM8B {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 198px;
}
.teacher-qiuz-students .rectangle-31-8MUoy6 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 726px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.teacher-qiuz-students .rectangle-40-8MUoy6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.teacher-qiuz-students .rectangle-40-K97w2w {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  width: 97%;
  display: flex;
  /* margin-bottom: 30px; */
  align-items: center;
  padding: 10px 30px;
}
.teacher-qiuz-students .rectangle-40-aXqM8B {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 657px;
}
.teacher-qiuz-students .rectangle-40-e9mijf {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 657px;
}
.teacher-qiuz-students .rectangle-40-vXsQwr {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 657px;
}
.teacher-qiuz-students .rectangle-40-wojrxS {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 657px;
}
.teacher-qiuz-students .rectangle-40-xlTRPb {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  padding: 10px 30px;
}
.teacher-qiuz-students .rectangle-41-8MUoy6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.teacher-qiuz-students .rectangle-44-K0vR9a {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-qiuz-students .rectangle-44-SbEpTm {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-qiuz-students .rectangle-44-TYZK0W {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-qiuz-students .rectangle-44-aOipQ0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-qiuz-students .rectangle-44-oArczI {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-qiuz-students .rectangle-44-xEcNns {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-qiuz-students .rectangle-47-8MUoy6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.teacher-qiuz-students .rectangle-48-wTq9AN {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-qiuz-students .rectangle-49-1ihGMx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-qiuz-students .rectangle-50-7y4ohx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-qiuz-students .rectangle-7-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-left: 322.25px;
  margin-top: 5px;
  position: relative;
  width: 1px;
}
.teacher-qiuz-students .school-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-qiuz-students .shreyans-jain-e9mijf {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 120px;
}
.teacher-qiuz-students .span0-qoX1og {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-qiuz-students .span1-qoX1og {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-qiuz-students .status-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 208px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.teacher-qiuz-students .stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-qiuz-students .time-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.teacher-qiuz-students .union-0xy0vn {
  align-self: flex-start;
  background-color: transparent;
  flex-shrink: 1;
  height: 106px;
  position: relative;
  width: 213px;
  margin-left: 5%;
}

.teacher-quiz-students .students-list {
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100% !important;
  height: auto;
}

.teacher-quiz-students .rectangle-40-K97w2w .studTile-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 14px;
  height: 30px;
}

.teacher-qiuz-students .upcoming-wTq9AN {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 76px;
}
.teacher-qiuz-students .vatsal-agarwal-K97w2w {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  width: 140px;
}
.teacher-qiuz-students .vector-0sTO9E {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.teacher-qiuz-students .vector-3ahjCQ {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-qiuz-students .vector-56AgMz {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.teacher-qiuz-students .vector-6bMYwa {
  background-color: transparent;
  height: 18px;
  width: 22px;
  margin: -29px 0px 0px 5px;
}
.teacher-qiuz-students .vector-8k85HE {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 170px;
  position: relative;
  width: 24px;
}
.teacher-qiuz-students .vector-90KxxC {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-qiuz-students .vector-9BT75t {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-qiuz-students .vector-9xgjqN {
  background-color: transparent;
  height: 14px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.teacher-qiuz-students .vector-APAcB6 {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.teacher-qiuz-students .vector-B8xRgA {
  background-color: transparent;
  height: 16px;
  left: 0px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-qiuz-students .vector-BKwYFx {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.teacher-qiuz-students .vector-Chnqxl {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.teacher-qiuz-students .vector-EaMiW0 {
  background-color: transparent;
  height: 20px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 18px;
}
.teacher-qiuz-students .vector-J6zohZ {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-qiuz-students .vector-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-qiuz-students .vector-O4ZmRS {
  background-color: transparent;
  height: 18px;
  left: -0px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-qiuz-students .vector-OGQcTr {
  background-color: transparent;
  height: 18px;
  left: -0px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-qiuz-students .vector-SCg0eG {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  position: relative;
  width: 24px;
}
.teacher-qiuz-students .vector-SIAzDu {
  background-color: transparent;
  height: 20px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 16px;
}
.teacher-qiuz-students .vector-SzWn3A {
  background-color: transparent;
  height: 18px;
  width: 22px;
  margin: 7px 7px;
}
.teacher-qiuz-students .vector-TCNT1O {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-qiuz-students .vector-UgkvXI {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-qiuz-students .vector-WcGkdi {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.teacher-qiuz-students .vector-XV2kdn {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.teacher-qiuz-students .vector-dlRKJA {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-qiuz-students .vector-fPPgx3 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-qiuz-students .vector-grLYV9 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-qiuz-students .vector-iniHBi {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-qiuz-students .vector-jWA1w5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.teacher-qiuz-students .vector-kCLhu1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-qiuz-students .vector-kkwAtg {
  background-color: transparent;
  height: 1px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-qiuz-students .vector-mju0OS {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-qiuz-students .vector-oRqxWx {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-qiuz-students .vector-pdEhUg {
  background-color: transparent;
  height: 1px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-qiuz-students .vector-r5ocKj {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-qiuz-students .vector-s2Y5x6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.teacher-qiuz-students .vector-vVhYnj {
  background-color: transparent;
  height: 1px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-qiuz-students .vector-xbu9jm {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.teacher-qiuz-students .vector-yQhZ26 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-qiuz-students .x10pm-SbEpTm {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 45px;
}
.teacher-qiuz-students .x12pm-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 47px;
}
.teacher-qiuz-students .x1pm-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 133px;
  width: 33px;
}
.teacher-qiuz-students .x2pm-1ihGMx {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 39px;
}
.teacher-qiuz-students .x3pm-wTq9AN {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 31px;
}
.teacher-qiuz-students .x4pm-oArczI {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-qiuz-students .x5pm-7y4ohx {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-qiuz-students .x6pm-K0vR9a {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-qiuz-students .x7pm-TYZK0W {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-qiuz-students .x8pm-aOipQ0 {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-qiuz-students .x9pm-xEcNns {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - teacher-quiz-student-list-mobile */

.montserrat-medium-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-gray-7px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-gray-9px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-12px {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-14px {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-quiz-student-list-mobile {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1492px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 414px;
}
.teacher-quiz-student-list-mobile .add-QwDJrz {
  background-color: transparent;
  height: auto;
  left: 283px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-student-list-mobile .add-delete-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 263px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 92px;
}
.teacher-quiz-student-list-mobile .add-jWwNYN {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 30.24px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 35px;
}
.teacher-quiz-student-list-mobile .assignment-1-7iGIN1 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.teacher-quiz-student-list-mobile .assignment-1-FVieMB {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.teacher-quiz-student-list-mobile .assignment-1-MiJunx {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.teacher-quiz-student-list-mobile .assignment-1-zHT0nt {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 54px;
}
.teacher-quiz-student-list-mobile .assignment-24px-3-1VRSUu {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.teacher-quiz-student-list-mobile .assignment-24px-3-UEWQ0j {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.teacher-quiz-student-list-mobile .assignment-24px-3-poPk0w {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.teacher-quiz-student-list-mobile .assignment-24px-3-uiXjoi {
  background-color: transparent;
  height: 8px;
  left: 4px;
  overflow: hidden;
  position: absolute;
  top: 4px;
  width: 8px;
}
.teacher-quiz-student-list-mobile .booked-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 284px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 237px;
  width: 50px;
}
.teacher-quiz-student-list-mobile .booked-sjPFVK {
  background-color: transparent;
  height: auto;
  left: 284px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 56px;
  width: 50px;
}
.teacher-quiz-student-list-mobile .castforeducation-24px-1-ghPLor {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 7px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 22px;
}
.teacher-quiz-student-list-mobile .castforeducation-24px-1-uQ5Iuu {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 7px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 22px;
}
.teacher-quiz-student-list-mobile .castforeducation-24px-1-zLrBzq {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 7px;
  overflow: hidden;
  position: absolute;
  top: 7px;
  width: 22px;
}
.teacher-quiz-student-list-mobile .choose-a-classr-ssign-a-new-one-lcFreP {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 3px;
  margin-top: 44px;
  min-height: 36px;
  position: relative;
  text-align: left;
  width: 347px;
}
.teacher-quiz-student-list-mobile .choose-a-studen-ssign-a-new-one-lcFreP {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 3px;
  margin-top: 23px;
  min-height: 36px;
  position: relative;
  text-align: left;
  width: 347px;
}
.teacher-quiz-student-list-mobile .class-name-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 78px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 94px;
}
.teacher-quiz-student-list-mobile .class-strength-15-X3dBej {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-quiz-student-list-mobile .class-strength-20-VFnR3Y {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-quiz-student-list-mobile .class-strength-8-9VsX3a {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-quiz-student-list-mobile .completed-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 184px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 56px;
  width: 74px;
}
.teacher-quiz-student-list-mobile .component-1-PNnH4c {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.teacher-quiz-student-list-mobile .component-29-7iGIN1 {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.teacher-quiz-student-list-mobile .component-29-FVieMB {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.teacher-quiz-student-list-mobile .component-29-MiJunx {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.teacher-quiz-student-list-mobile .component-29-UMS24L {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  flex-shrink: 1;
  height: 36px;
  position: relative;
  width: 36px;
}
.teacher-quiz-student-list-mobile .component-29-jZMW3Q {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  flex-shrink: 1;
  height: 36px;
  position: relative;
  width: 36px;
}
.teacher-quiz-student-list-mobile .component-29-qLe0vf {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-quiz-student-list-mobile .component-29-ukHLxf {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-quiz-student-list-mobile .component-29-xmIyQR {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-quiz-student-list-mobile .component-29-z0s03U {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  flex-shrink: 1;
  height: 36px;
  position: relative;
  width: 36px;
}
.teacher-quiz-student-list-mobile .component-29-zHT0nt {
  background-color: transparent;
  height: 16px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 16px;
}
.teacher-quiz-student-list-mobile .component-6-NvvWoh {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: 13px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.teacher-quiz-student-list-mobile .ellipse-4-1VRSUu {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.teacher-quiz-student-list-mobile .ellipse-4-UEWQ0j {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.teacher-quiz-student-list-mobile .ellipse-4-poPk0w {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.teacher-quiz-student-list-mobile .ellipse-4-uiXjoi {
  background-color: transparent;
  height: 17px;
  left: -0px;
  position: absolute;
  top: -0px;
  width: 17px;
}
.teacher-quiz-student-list-mobile .feb-18-2021-kxiL8T {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 87px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 117px;
}
.teacher-quiz-student-list-mobile .flex-col-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: -203px;
  margin-top: 960.69px;
  min-height: 162px;
  position: relative;
  width: 179px;
}
.teacher-quiz-student-list-mobile .flex-col-NvvWoh {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 133px;
  position: relative;
  width: 311px;
}
.teacher-quiz-student-list-mobile .flex-col-VMr6Om {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 48px;
  margin-top: 24px;
  min-height: 1452px;
  position: relative;
  width: 365px;
}
.teacher-quiz-student-list-mobile .flex-row-SXcK7r {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  min-height: 23px;
  justify-content: flex-start;
  margin-right: 2px;
  margin-top: 24px;
  min-width: 353px;
  position: relative;
  width: auto;
}
.teacher-quiz-student-list-mobile .flex-row-lcFreP {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 133px;
  justify-content: flex-start;
  margin-left: 2px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacher-quiz-student-list-mobile .group-136-2wAK2u {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  margin-top: 13px;
  position: relative;
  width: 363px;
}
.teacher-quiz-student-list-mobile .group-136-Ux56cI {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  margin-top: 13px;
  position: relative;
  width: 363px;
}
.teacher-quiz-student-list-mobile .group-137-2wAK2u {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  margin-top: 13px;
  position: relative;
  width: 363px;
}
.teacher-quiz-student-list-mobile .group-137-Ux56cI {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  margin-top: 13px;
  position: relative;
  width: 363px;
}
.teacher-quiz-student-list-mobile .group-138-0Wopvi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 196px;
  justify-content: flex-start;
  left: 0px;
  /* min-height: 176px; */
  position: absolute;
  top: 0px;
  width: 359px;
  overflow-y: auto;
}
.teacher-quiz-student-list-mobile .group-139-BhPClO {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  /* min-height: 176px; */
  position: absolute;
  top: 0px;
  width: 359px;
  overflow-y: auto;
}
.teacher-quiz-student-list-mobile .group-167-0Wopvi {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 6px;
  width: 36px;
}
.teacher-quiz-student-list-mobile .group-169-ghPLor {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  left: 0px;
  min-width: 36px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-quiz-student-list-mobile .group-169-uQ5Iuu {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  left: 0px;
  min-width: 36px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-quiz-student-list-mobile .group-169-zLrBzq {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  left: 0px;
  min-width: 36px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-quiz-student-list-mobile .group-181-BhPClO {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 6px;
  width: 36px;
}
.teacher-quiz-student-list-mobile .group-182-BhPClO {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 135px;
  width: 36px;
}
.teacher-quiz-student-list-mobile .group-183-BhPClO {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 69px;
  width: 36px;
}
.teacher-quiz-student-list-mobile .group-184-0Wopvi {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 69px;
  width: 36px;
}
.teacher-quiz-student-list-mobile .group-185-0Wopvi {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 132px;
  width: 36px;
}
.teacher-quiz-student-list-mobile .group-186-lcFreP {
  background-color: transparent;
  flex-shrink: 1;
  height: 176px;
  margin-left: 4px;
  margin-top: 34px;
  position: relative;
  width: 359px;
}
.teacher-quiz-student-list-mobile .group-187-lcFreP {
  background-color: transparent;
  flex-shrink: 1;
  height: 176px;
  margin-top: 34px;
  position: relative;
  width: 359px;
}
.teacher-quiz-student-list-mobile .group-46-2wAK2u {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  position: relative;
  width: 363px;
}
.teacher-quiz-student-list-mobile .group-46-Ux56cI {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  position: relative;
  width: 363px;
}
.teacher-quiz-student-list-mobile .group-72-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-left: 0.67px;
  position: relative;
  width: 179px;
}
.teacher-quiz-student-list-mobile .group-73-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-left: 0.67px;
  margin-top: 7px;
  position: relative;
  width: 179px;
}
.teacher-quiz-student-list-mobile .group-74-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-left: 0.67px;
  margin-top: 7px;
  position: relative;
  width: 179px;
}
.teacher-quiz-student-list-mobile .group-75-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 35px;
  margin-top: 7px;
  position: relative;
  width: 179px;
}
.teacher-quiz-student-list-mobile .group-90-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 161px;
  width: 346px;
}
.teacher-quiz-student-list-mobile .group-91-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 220px;
  width: 348px;
}
.teacher-quiz-student-list-mobile .group-92-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 278px;
  width: 346px;
}
.teacher-quiz-student-list-mobile .group-93-PqMIxZ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 9px;
  min-height: 262px;
  position: absolute;
  top: 119px;
  width: 348px;
}
.teacher-quiz-student-list-mobile .group-94-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 396px;
  width: 346px;
}
.teacher-quiz-student-list-mobile .group-95-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 455px;
  width: 346px;
}
.teacher-quiz-student-list-mobile .group-96-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 514px;
  width: 346px;
}
.teacher-quiz-student-list-mobile .group-97-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 573px;
  width: 346px;
}
.teacher-quiz-student-list-mobile .group-98-PqMIxZ {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 632px;
  width: 346px;
}
.teacher-quiz-student-list-mobile .group-FiCJxk {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-quiz-student-list-mobile .group-P80Yiu {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-quiz-student-list-mobile .group-XTugVD {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-quiz-student-list-mobile .kunal-kashyap-vKR7BJ {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 140px;
}
.teacher-quiz-student-list-mobile .line-5-pUZ9pZ {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.teacher-quiz-student-list-mobile .line-6-pUZ9pZ {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.teacher-quiz-student-list-mobile .mathematics-class-10-H45YYJ {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-quiz-student-list-mobile .mathematics-class-10-X3dBej {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 165px;
}
.teacher-quiz-student-list-mobile .mathematics-class-9-BZlKJ0 {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-quiz-student-list-mobile .mathematics-class-9-VFnR3Y {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 165px;
}
.teacher-quiz-student-list-mobile .mathematics-class-9-vKR7BJ {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-quiz-student-list-mobile .mathematics-dr-yashna-sharma-7iGIN1 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.teacher-quiz-student-list-mobile .mathematics-dr-yashna-sharma-FVieMB {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.teacher-quiz-student-list-mobile .mathematics-dr-yashna-sharma-MiJunx {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.teacher-quiz-student-list-mobile .mathematics-dr-yashna-sharma-zHT0nt {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 19px;
  width: 83px;
}
.teacher-quiz-student-list-mobile .my-quiz-PNnH4c {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 1px;
  margin-top: 52px;
  min-height: 36px;
  position: relative;
  width: 310px;
}
.teacher-quiz-student-list-mobile .open-calendar-kxiL8T {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.teacher-quiz-student-list-mobile .opened-DRTA6S {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-student-list-mobile .opened-FxsTuj {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-student-list-mobile .opened-UxBcup {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-student-list-mobile .opened-VweSAY {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.teacher-quiz-student-list-mobile .opened-XEhIzO {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-student-list-mobile .opened-x92G5j {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-student-list-mobile .opened-ytzoYe {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-student-list-mobile .organic-4-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 56px;
  width: 69px;
}
.teacher-quiz-student-list-mobile .organic-5-Fiy3Ky {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.teacher-quiz-student-list-mobile .overlap-group-lcFreP {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 692px;
  margin-top: 21px;
  position: relative;
  width: 359px;
}
.teacher-quiz-student-list-mobile .overlap-group1-jWwNYN {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 342px;
}
.teacher-quiz-student-list-mobile .physics-class-9-9VsX3a {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 140px;
}
.teacher-quiz-student-list-mobile .rectangle-31-PqMIxZ {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 692px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-student-list-mobile .rectangle-40-7iGIN1 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.teacher-quiz-student-list-mobile .rectangle-40-9VsX3a {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-student-list-mobile .rectangle-40-BZlKJ0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-student-list-mobile .rectangle-40-FVieMB {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.teacher-quiz-student-list-mobile .rectangle-40-H45YYJ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-student-list-mobile .rectangle-40-MiJunx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.teacher-quiz-student-list-mobile .rectangle-40-PqMIxZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 43px;
  width: 342px;
}
.teacher-quiz-student-list-mobile .rectangle-40-VFnR3Y {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-student-list-mobile .rectangle-40-X3dBej {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-student-list-mobile .rectangle-40-vKR7BJ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-student-list-mobile .rectangle-40-zHT0nt {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 35px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 173px;
}
.teacher-quiz-student-list-mobile .rectangle-41-PqMIxZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 7px;
  position: absolute;
  top: 161px;
  width: 342px;
}
.teacher-quiz-student-list-mobile .rectangle-44-DRTA6S {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-student-list-mobile .rectangle-44-FxsTuj {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-student-list-mobile .rectangle-44-UxBcup {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-student-list-mobile .rectangle-44-XEhIzO {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-student-list-mobile .rectangle-44-x92G5j {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-student-list-mobile .rectangle-44-ytzoYe {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-student-list-mobile .rectangle-47-PqMIxZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 102px;
  width: 342px;
}
.teacher-quiz-student-list-mobile .rectangle-48-Fiy3Ky {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-student-list-mobile .rectangle-48-MRvDS0 {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.teacher-quiz-student-list-mobile .rectangle-49-MRvDS0 {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-quiz-student-list-mobile .rectangle-49-VweSAY {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-student-list-mobile .rectangle-50-MRvDS0 {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-quiz-student-list-mobile .rectangle-50-QwDJrz {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-student-list-mobile .shreyans-jain-H45YYJ {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 140px;
}
.teacher-quiz-student-list-mobile .span0-V08QdX {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-quiz-student-list-mobile .span1-V08QdX {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-quiz-student-list-mobile .status-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 49px;
}
.teacher-quiz-student-list-mobile .submitted-7iGIN1 {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-quiz-student-list-mobile .submitted-FVieMB {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-quiz-student-list-mobile .submitted-MiJunx {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-quiz-student-list-mobile .submitted-zHT0nt {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 134px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-quiz-student-list-mobile .time-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 42px;
}
.teacher-quiz-student-list-mobile .upcoming-Fiy3Ky {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.teacher-quiz-student-list-mobile .vatsal-agarwal-BZlKJ0 {
  background-color: transparent;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 140px;
}
.teacher-quiz-student-list-mobile .vector-4qajfp {
  background-color: transparent;
  height: 1px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-quiz-student-list-mobile .vector-7YVAkz {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.teacher-quiz-student-list-mobile .vector-AxuVxQ {
  background-color: transparent;
  height: 1px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-quiz-student-list-mobile .vector-Bs7FdS {
  background-color: transparent;
  height: 18px;
  left: -0px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-quiz-student-list-mobile .vector-CcJI6B {
  background-color: transparent;
  height: 8px;
  left: 1px;
  position: absolute;
  top: -0px;
  width: 7px;
}
.teacher-quiz-student-list-mobile .vector-D3RR4d {
  background-color: transparent;
  height: 1px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 1px;
}
.teacher-quiz-student-list-mobile .vector-G9m1B6 {
  background-color: transparent;
  height: 18px;
  left: -0px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-quiz-student-list-mobile .vector-NMvbnM {
  background-color: transparent;
  height: 18px;
  left: -0px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-quiz-student-list-mobile .vector-RChgoP {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.teacher-quiz-student-list-mobile .vector-Vn3aY5 {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.teacher-quiz-student-list-mobile .vector-f2PIuq {
  background-color: transparent;
  height: 1px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 1px;
}
.teacher-quiz-student-list-mobile .vector-hEE9Rr {
  background-color: transparent;
  height: 8px;
  left: 1px;
  position: absolute;
  top: -0px;
  width: 7px;
}
.teacher-quiz-student-list-mobile .vector-lP2eQk {
  background-color: transparent;
  height: 8px;
  left: 1px;
  position: absolute;
  top: -0px;
  width: 7px;
}
.teacher-quiz-student-list-mobile .vector-qLe0vf {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-quiz-student-list-mobile .vector-ukHLxf {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-quiz-student-list-mobile .vector-xmIyQR {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-quiz-student-list-mobile .vector-zLTigN {
  background-color: transparent;
  height: 7px;
  left: 1px;
  position: absolute;
  top: 0px;
  width: 7px;
}
.teacher-quiz-student-list-mobile .x10pm-DRTA6S {
  background-color: transparent;
  height: auto;
  left: 7px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 42px;
}
.teacher-quiz-student-list-mobile .x12pm-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 56px;
  width: 44px;
}
.teacher-quiz-student-list-mobile .x1pm-PqMIxZ {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 119px;
  width: 31px;
}
.teacher-quiz-student-list-mobile .x2pm-VweSAY {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.teacher-quiz-student-list-mobile .x3pm-Fiy3Ky {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.teacher-quiz-student-list-mobile .x4pm-ytzoYe {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-student-list-mobile .x5pm-QwDJrz {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-student-list-mobile .x6pm-x92G5j {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-student-list-mobile .x7pm-XEhIzO {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-student-list-mobile .x8pm-UxBcup {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-student-list-mobile .x9pm-FxsTuj {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .teacher-qiuz-students {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .teacher-quiz-student-list-mobile {
    display: none;
  }
}
