.main {
  width: 100%;
  padding: 40px 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main h1 {
  font-size: 24px;
  font-weight: 700;
  color: #dc3545;
  text-align: center;
  line-height: 1.5;
}

.bold {
  font-weight: 600;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.toggleButton {
  background-color: #f9f9f9;
  color: #000; /* Default text color */
  border: none; /* Default border */
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin: 0 5px;
  transition: all 0.3s ease;
  font-weight: bold;
}

.toggleButton:hover {
  background-color: #dc3545; /* Hover background */
}

.activeButton {
  background-color: #dc3545; /* Active background */
  color: #fff; /* Active text color */
}

@media screen and (max-width: 768px) {
  .main {
    padding: 40px 16px 0px;
  }
  .main h1 {
    font-size: 20px;
  }
}
