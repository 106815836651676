.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.popup-card {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
}

.student-checkbox-container {
  margin-top: 15px;
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ff7e5f #f1f1f1; /* Customize scrollbar color for Firefox */
}

/* Custom Scrollbar for Webkit Browsers (Chrome, Safari) */
.student-checkbox-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.student-checkbox-container::-webkit-scrollbar-thumb {
  background-color: #ff7e5f;
  border-radius: 10px; /* Round the scrollbar thumb */
}

.student-checkbox-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

/* Styling for student checkboxes */
.students-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 10px; /* Spacing between grid items */
}

.student-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
}
