/* screen - hcpayments */

.montserrat-medium-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-blue-zodiac-10px-99 {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.hcpayments {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.hcpayments .accountbalance-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.hcpayments .amount-dlP8Ul {
  background-color: transparent;
  height: auto;
  left: 160px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: auto;
}
.hcpayments .analytics-24px-4-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.hcpayments .andb586-dlP8Ul {
  background-color: transparent;
  height: auto;
  left: 594px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 138px;
  width: auto;
}
.hcpayments .biba-sobti-2jrdHj {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.hcpayments .bibasobtigmailcom-2jrdHj {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.hcpayments .category-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 301px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 76px;
}
.hcpayments .completed-dlP8Ul {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 515px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 317px;
  width: auto;
}
.hcpayments .component-1-GyJUNH {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.hcpayments .date-dlP8Ul {
  background-color: transparent;
  height: auto;
  left: 360px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: auto;
}
.hcpayments .ellipse-2-nY7RYD {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.hcpayments .flex-col-C61RwL {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 63px;
  padding-left: 50px;
  margin-top: 45px;
  min-height: 977px;
  position: relative;
  width: calc(100% - 63px);
}
.hcpayments .flex-col-OfAOxu {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 115px;
  position: relative;
  width: 254px;
}
.hcpayments .flex-col-R6Ddt1 {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.hcpayments .flex-row-0xy0vn {
  align-items: flex-start;
  align-self: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 123px;
  justify-content: space-between;
  position: relative;
  width: 95%;
}
.hcpayments .flex-row-BeEgsN {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 22px;
  justify-content: flex-start;
  margin-top: 9px;
  min-width: 166px;
  position: relative;
  width: auto;
}
.hcpayments .flex-row-axsvJs {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.hcpayments .flex-row-oV5UrE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 637px;
  justify-content: flex-start;
  margin-top: 42px;
  position: relative;
  width: 100%;
}
.hcpayments .flex-row-qItZ2O {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 22px;
  justify-content: flex-start;
  margin-top: 9px;
  min-width: 176px;
  position: relative;
  width: auto;
}
.hcpayments .flex-row-xqwxU1 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 22px;
  justify-content: flex-start;
  min-width: 162px;
  position: relative;
  width: auto;
}
.hcpayments .group-115-GyJUNH {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-top: 6px;
  min-width: 376px;
  position: relative;
  width: auto;
}
.hcpayments .group-125-4xLMVj {
  background-color: #dc3545;
  border-radius: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 318px;
}
.hcpayments .group-125-GyJUNH {
  align-items: flex-end;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 115px;
  justify-content: flex-start;
  margin-left: 176px;
  min-width: 295px;
  position: relative;
  width: auto;
}
.hcpayments .group-202-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 26px;
  min-height: 637px;
  position: relative;
  width: 421px;
}
.hcpayments .group-204-0xy0vn {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-right: 40px;
  margin-top: 131px;
  position: relative;
  width: 320px;
}
.hcpayments .group-209-C61RwL {
  align-items: flex-end;
  background-color: #1f2531;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 1024px;
  padding: 50px 18px;
  position: relative;
  width: 63px;
}
.hcpayments .group-22-3boKt2 {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  min-width: 316px;
  position: relative;
  width: auto;
}
.hcpayments .group-91-H1OX4C {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 369px;
}
.hcpayments .group-92-H1OX4C {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 365px;
}
.hcpayments .group-94-H1OX4C {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 365px;
}
.hcpayments .group-95-H1OX4C {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 365px;
}
.hcpayments .group-96-H1OX4C {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 365px;
}
.hcpayments .group-99-H1OX4C {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 351px;
  width: 369px;
}
.hcpayments .group-AAVFy6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.hcpayments .group-XDTIuB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.hcpayments .group-aTF8dW {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.hcpayments .group-rAjf8W {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.hcpayments .help-24px-2-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.hcpayments .historytoggleoff-24px-1-D0BVcB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.hcpayments .in-progess-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 210px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 72px;
}
.hcpayments .insertinvitation-24px-1-QkTteQ {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-left: 20px;
  margin-top: 139px;
  overflow: hidden;
  padding: 0 11.8px;
  position: relative;
  width: 24px;
}
.hcpayments .kunal-kashyap-3A77zI {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 105px;
}
.hcpayments .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.hcpayments .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.hcpayments .messages-4xLMVj {
  background-color: transparent;
  color: var(--white);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 64px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 11px;
  width: auto;
}
.hcpayments .notifications-24px-2-1-3boKt2 {
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.hcpayments .number-of-issues-per-day-xqwxU1 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 9px;
  min-height: 22px;
  min-width: 254px;
  position: relative;
  text-align: left;
  width: auto;
}
.hcpayments .open-issue-list-4FsOUs {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 5px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.hcpayments .overlap-group-QkTteQ-in-c-p {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 614px;
  position: relative;
  width: 745px;
}

.hcpayments .overlap-group-QkTteQ-input-in-f {
  width: 65%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.btn-hc-payment {
  width: 140px;
  height: 35px;
  margin: 5px;

  text-align: center;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;

  color: white;
  background-color: #dc3545;

  border: none;
  border-radius: 8px;
}

.Display-None {
  display: none;
}

.with-hc-payment-100 {
  width: 100%;
  height: auto;
}

.hcpayments .overlap-group1-4FsOUs {
  background-color: transparent;
  flex-shrink: 1;
  height: 600px;
  margin-top: 14px;
  position: relative;
  width: 383px;
}
.hcpayments .payga4wk-z-bshl-dlP8Ul {
  background-color: transparent;
  height: auto;
  left: 49px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 138px;
  width: auto;
}
.hcpayments .payment-id-dlP8Ul {
  background-color: transparent;
  height: auto;
  left: 35px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: auto;
}
.hcpayments .pending-WteuDV {
  background-color: transparent;
  height: auto;
  left: 209px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 56px;
}
.hcpayments .percentage-XDafRx {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 22px;
  min-width: 122px;
  position: relative;
  text-align: left;
  width: auto;
}
.hcpayments .recent-payments-dlP8Ul {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 31px;
  width: auto;
}
.hcpayments .rectangle-31-H1OX4C {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 600px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.hcpayments .rectangle-31-dlP8Ul-hc-pay {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 570px;
  left: 0px;
  position: absolute;
  top: 65px;
  width: 745px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.rectangle-31-dlP8Ul-hc-pay-in {
  width: 90%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hcpayments .rectangle-36-dlP8Ul-10-2e {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 47px;

  width: 681px;
  margin-top: 20px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hcpayments .rectangle-37-dlP8Ul {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 47px;
  left: 32px;
  position: absolute;
  top: 190px;
  width: 681px;
}
.hcpayments .rectangle-38-dlP8Ul {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 47px;
  left: 32px;
  position: absolute;
  top: 259px;
  width: 681px;
}
.hcpayments .rectangle-39-dlP8Ul {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 47px;
  left: 32px;
  position: absolute;
  top: 328px;
  width: 681px;
}
.hcpayments .rectangle-40-H1OX4C {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.hcpayments .rectangle-41-H1OX4C {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.hcpayments .rectangle-47-H1OX4C {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.hcpayments .rectangle-47-dlP8Ul-090 {
  background-color: var(--niagara);
  border-radius: 20px;
  height: 19px;
  left: 507px;
  position: absolute;
  top: 314px;
  width: 74px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.hcpayments .rectangle-48-3A77zI {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.hcpayments .rectangle-48-WteuDV {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.hcpayments .rectangle-48-dlP8Ul {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 47px;
  left: 32px;
  position: absolute;
  top: 397px;
  width: 681px;
}
.hcpayments .rectangle-49-dlP8Ul {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 47px;
  left: 32px;
  position: absolute;
  top: 466px;
  width: 681px;
}
.hcpayments .rectangle-50-dlP8Ul {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 47px;
  left: 32px;
  position: absolute;
  top: 535px;
  width: 681px;
}
.hcpayments .s-no-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.hcpayments .school-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.hcpayments .shreyans-jain-WteuDV {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 93px;
}
.hcpayments .solved-issues-302Mxf {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 22px;
  min-width: 138px;
  position: relative;
  text-align: left;
  width: auto;
}
.hcpayments .sos-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 315px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 34px;
}
.hcpayments .sos-Oxmxv6 {
  background-color: transparent;
  height: auto;
  left: 315px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 34px;
}
.hcpayments .span0-Kodf6P {
  color: rgba(19, 37, 71, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.hcpayments .span1-Kodf6P {
  color: rgba(19, 37, 71, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}
.hcpayments .standard-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 301px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 366px;
  width: 61px;
}
.hcpayments .status-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 220px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.hcpayments .status-dlP8Ul {
  background-color: transparent;
  height: auto;
  left: 524px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 268px;
  width: auto;
}
.hcpayments .student-name-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 83px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 107px;
}
.hcpayments .ticket-3A77zI {
  background-color: transparent;
  height: auto;
  left: 211px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 47px;
}
.hcpayments .time-dlP8Ul {
  background-color: transparent;
  height: auto;
  left: 520px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: auto;
}
.hcpayments .total-issues-cSfBx4 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 22px;
  min-width: 122px;
  position: relative;
  text-align: left;
  width: auto;
}
.hcpayments .transferred-to-dlP8Ul {
  background-color: transparent;
  height: auto;
  left: 656px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: auto;
}
.hcpayments .vatsal-agarwal-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 85px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 104px;
}
.hcpayments .vector-1duCl1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.hcpayments .vector-2CQzkB {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.hcpayments .vector-2xKpiG {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.hcpayments .vector-4xLMVj {
  background-color: transparent;
  height: 12px;
  left: 282px;
  position: absolute;
  top: 17px;
  width: 18px;
}
.hcpayments .vector-AxqJxr {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.hcpayments .vector-Axu9vm {
  background-color: transparent;
  height: 30px;
  left: 10px;
  position: absolute;
  top: 7px;
  width: 30px;
}
.hcpayments .vector-Ayxm2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 356px;
  position: relative;
  width: 24px;
}
.hcpayments .vector-BRkMYc {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.hcpayments .vector-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.hcpayments .vector-I29KJn {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.hcpayments .vector-MIOlFW {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.hcpayments .vector-R6Ddt1 {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.hcpayments .vector-WxmxmD {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.hcpayments .vector-XxcEsk {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.hcpayments .vector-YPcxtl {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.hcpayments .vector-Zppvke {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.hcpayments .vector-axsvJs {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.hcpayments .vector-bpjCos {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.hcpayments .vector-nY7RYD {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.hcpayments .vector-uxDhU3 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.hcpayments .vector-x0nTHj {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.hcpayments .vector-x6oIim {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.hcpayments .x014009-pm-dlP8Ul {
  background-color: transparent;
  height: auto;
  left: 460px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 138px;
  width: auto;
}
.hcpayments .x07-dec-2020-dlP8Ul {
  background-color: transparent;
  height: auto;
  left: 325px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 138px;
  width: auto;
}
.hcpayments .x1-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 15px;
}
.hcpayments .x2-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 130px;
  width: 15px;
}
.hcpayments .x3-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 189px;
  width: 15px;
}
.hcpayments .x4-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 15px;
}
.hcpayments .x40-302Mxf {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 11px;
  min-height: 22px;
  min-width: 27px;
  position: relative;
  text-align: left;
  width: auto;
}
.hcpayments .x43-cSfBx4 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 15px;
  min-height: 22px;
  min-width: 25px;
  position: relative;
  text-align: left;
  width: auto;
}
.hcpayments .x499900-dlP8Ul {
  background-color: transparent;
  height: auto;
  left: 208px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 138px;
  width: auto;
}
.hcpayments .x5-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 304px;
  width: 15px;
}
.hcpayments .x6-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 366px;
  width: 15px;
}
.hcpayments .x7-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 422px;
  width: 15px;
}
.hcpayments .x7-OfAOxu {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-bottom: 0.74px;
  margin-left: 15px;
  min-height: 21px;
  position: relative;
  text-align: left;
  width: 12px;
}
.hcpayments .x8-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 481px;
  width: 15px;
}
.hcpayments .x9-H1OX4C {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 540px;
  width: 15px;
}
.hcpayments .x97-XDafRx {
  background-color: transparent;
  color: var(--niagara);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 5px;
  min-height: 22px;
  min-width: 39px;
  position: relative;
  text-align: left;
  width: auto;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - sales-mobile-payments */

.montserrat-medium-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-blue-zodiac-12px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.sales-mobile-payments {
  align-items: center;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 668px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 7px;
  position: relative;
  width: 414px;
}
.sales-mobile-payments .amount-agT0Rh {
  background-color: transparent;
  height: auto;
  left: 102px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 55px;
  width: 55px;
}
.sales-mobile-payments .booked-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 286px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 477px;
  width: 50px;
}
.sales-mobile-payments .booked-Cb1J56 {
  background-color: transparent;
  height: auto;
  left: 284px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 50px;
}
.sales-mobile-payments .category-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 263px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 92px;
}
.sales-mobile-payments .completed-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 184px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 74px;
}
.sales-mobile-payments .component-1-dXBp89 {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.sider-hc-teacher-list {
  width: 60px;
  height: 100%;
  overflow: hidden;
  position: fixed;
  background-color: #1f2531;

  z-index: 250;
}

.sider-hc-teacher-list-in-wdt-80 {
  width: 100%;
  height: 70%;
  margin: 120px 0px 30px;
  color: gray;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.icon-in-hc-color {
  color: rgba(179, 179, 179, 1);
  font-size: 26px;
}
.sales-mobile-payments .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.sales-mobile-payments .date-agT0Rh {
  background-color: transparent;
  height: auto;
  left: 171px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 55px;
  width: 30px;
}
.sales-mobile-payments .flex-row-C61RwL {
  align-items: center;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.sales-mobile-payments .group-124-kWlObw {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 172px;
  justify-content: flex-start;
  left: 0px;
  min-width: 359px;
  position: absolute;
  top: 0px;
  width: auto;
}
.sales-mobile-payments .group-125-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 172px;
  margin-right: 0px;
  margin-top: 48px;
  position: relative;
  width: 375px;
}

.hc-mobile-input-filter {
  width: 100%;
  margin-top: 20px;
}

.hc-mobile-input-filter-in {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rectangle-31-agT0Rh-02-7 {
  width: 90%;
  margin-left: 12px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sales-mobile-payments .group-210-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 40px;
  min-height: 581px;
  position: relative;
  width: 381px;
}
.sales-mobile-payments .group-211-C61RwL {
  background-color: transparent;

  height: 399px;
  /* margin-right: 10px; */
  margin-top: 20px;
  position: relative;
  width: 371px;
}
.sales-mobile-payments .group-38-fXlfDP {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 172px;
  position: relative;
  width: 359px;
}
.sales-mobile-payments .group-39-agxh6K {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 172px;
  justify-content: flex-start;
  min-width: 359px;
  position: relative;
  width: auto;
}
.sales-mobile-payments .group-40-7vmrSC {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 172px;
  justify-content: flex-start;
  min-width: 359px;
  position: relative;
  width: auto;
}
.sales-mobile-payments .group-41-e1mbBS {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 172px;
  justify-content: flex-start;
  min-width: 359px;
  position: relative;
  width: auto;
}
.sales-mobile-payments .group-90-32Z2fi {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 170px;
  width: 346px;
}
.sales-mobile-payments .group-91-32Z2fi {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 464px;
  width: 348px;
}
.sales-mobile-payments .group-92-32Z2fi {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 287px;
  width: 346px;
}
.sales-mobile-payments .group-94-32Z2fi {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 405px;
  width: 346px;
}
.sales-mobile-payments .group-95-32Z2fi {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 346px;
  width: 346px;
}
.sales-mobile-payments .group-96-32Z2fi {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 233px;
  width: 346px;
}
.sales-mobile-payments .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.sales-mobile-payments .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.sales-mobile-payments .number-of-issues-per-day-kWlObw {
  background-color: transparent;
  height: auto;
  left: 14px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 134px;
  width: 308px;
}
.sales-mobile-payments .open-issue-list-Uoyr1g {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 4px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.sales-mobile-payments .opened-GcMqx5 {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.sales-mobile-payments .opened-Gfzi7S {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.sales-mobile-payments .opened-eI2rAV {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.sales-mobile-payments .opened-t6sl2a {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.sales-mobile-payments .opened-xy5Uks {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.sales-mobile-payments .organic-4-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 69px;
}
.sales-mobile-payments .organic-5-4Mx5vA {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.sales-mobile-payments .overlap-group-Uoyr1g {
  background-color: transparent;
  flex-shrink: 1;
  height: 546px;
  margin-top: 12px;
  position: relative;
  width: 359px;
}
.sales-mobile-payments .payment-id-agT0Rh {
  background-color: transparent;
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 55px;
  width: 69px;
}
.sales-mobile-payments .percentage-kWlObw {
  background-color: transparent;
  height: auto;
  left: 14px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 94px;
  width: 143px;
}
.sales-mobile-payments .recent-payments-agT0Rh {
  background-color: transparent;
  color: #dc3545;
  height: auto;
  left: 16px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 173px;
}
.sales-mobile-payments .rectangle-31-32Z2fi {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 546px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.sales-mobile-payments .rectangle-31-agT0Rh {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 395px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.sales-mobile-payments .rectangle-36-agT0Rh-8-on {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 32px;
  /* left: 16px;
  position: absolute;
  top: 0px; */
  width: 330px;

  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-left: 12px;

  padding: 7px 10px;
}

.montserrat-semi-bold-blue-zodiac-10px-8 {
  font-size: 9px;
}

.payga4wk-z-bshl-Sgr7kw-8-66 {
  min-width: 50px;
}

.payga4wk-z-bshl-Sgr7kw-98 {
  min-width: 75px;
}

.sales-mobile-payments .rectangle-37-agT0Rh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 31px;
  left: 16px;
  position: absolute;
  top: 124px;
  width: 323px;
}
.sales-mobile-payments .rectangle-38-agT0Rh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 32px;
  left: 16px;
  position: absolute;
  top: 170px;
  width: 323px;
}
.sales-mobile-payments .rectangle-39-agT0Rh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 32px;
  left: 16px;
  position: absolute;
  top: 216px;
  width: 323px;
}
.sales-mobile-payments .rectangle-40-32Z2fi {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 52px;
  width: 342px;
}
.sales-mobile-payments .rectangle-40-agT0Rh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 32px;
  left: 20px;
  position: absolute;
  top: 262px;
  width: 323px;
}
.sales-mobile-payments .rectangle-41-32Z2fi {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 7px;
  position: absolute;
  top: 170px;
  width: 342px;
}
.sales-mobile-payments .rectangle-41-agT0Rh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 31px;
  left: 20px;
  position: absolute;
  top: 309px;
  width: 323px;
}
.sales-mobile-payments .rectangle-42-agT0Rh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 32px;
  left: 20px;
  position: absolute;
  top: 355px;
  width: 323px;
}
.sales-mobile-payments .rectangle-44-GcMqx5 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.sales-mobile-payments .rectangle-44-xy5Uks {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.sales-mobile-payments .rectangle-47-32Z2fi {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 111px;
  width: 342px;
}
.sales-mobile-payments .rectangle-48-4Mx5vA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.sales-mobile-payments .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.sales-mobile-payments .rectangle-49-Gfzi7S {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.sales-mobile-payments .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.sales-mobile-payments .rectangle-49-eI2rAV {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.sales-mobile-payments .rectangle-49-t6sl2a {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.sales-mobile-payments .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.sales-mobile-payments .s-no-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 42px;
}
.sales-mobile-payments .solved-issues-kWlObw {
  background-color: transparent;
  height: auto;
  left: 14px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 53px;
  width: 182px;
}
.sales-mobile-payments .status-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 49px;
}
.sales-mobile-payments .student-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 78px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21px;
  width: 94px;
}
.sales-mobile-payments .time-agT0Rh {
  background-color: transparent;
  height: auto;
  left: 221px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 55px;
  width: 36px;
}
.sales-mobile-payments .total-issues-kWlObw {
  background-color: transparent;
  height: auto;
  left: 14px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 154px;
}
.sales-mobile-payments .transferred-to-agT0Rh {
  background-color: transparent;
  height: auto;
  left: 262px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 55px;
  width: 79px;
}
.sales-mobile-payments .upcoming-4Mx5vA {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.sales-mobile-payments .x12pm-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 65px;
  width: 44px;
}
.sales-mobile-payments .x1pm-32Z2fi {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 128px;
  width: 31px;
}
.sales-mobile-payments .x2pm-Gfzi7S {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.sales-mobile-payments .x2pm-eI2rAV {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.sales-mobile-payments .x2pm-t6sl2a {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.sales-mobile-payments .x3pm-4Mx5vA {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.sales-mobile-payments .x40-kWlObw {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 200px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 53px;
  width: 65px;
}
.sales-mobile-payments .x43-kWlObw {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 181px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 28px;
}
.sales-mobile-payments .x4pm-GcMqx5 {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.sales-mobile-payments .x6pm-xy5Uks {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.sales-mobile-payments .x7-kWlObw-98 {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 304px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 134px;
  width: 15px;
}
.sales-mobile-payments .x97-kWlObw {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 175px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 94px;
  width: 46px;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .hcpayments {
    display: none;
  }
  .btn-hc-payment {
    width: 100px;
    font-size: 10px;
  }
}
@media screen and (min-width: 600px) {
  .sales-mobile-payments {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .hcpayments .overlap-group-QkTteQ-in-c-p {
    width: 87%;
  }
  .hcpayments .rectangle-31-dlP8Ul-hc-pay {
    width: 100%;
  }
  .hcpayments .rectangle-36-dlP8Ul-10-2e {
    width: 95%;
  }
}