.cont {
  /* background-image: radial-gradient(#dc3545 2px, transparent 2px);
  background-size: 32px 32px; */
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 5%;
  padding-bottom: 5%;
}
/* .bg {
  backgroundimage: `url(${}) `;
  backgroundposition: "center";
  backgroundsize: "cover";
  backgroundrepeat: "repeat";
} */

.course-card {
  width: 350px;
  height: 300px;
  margin: 20px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.course-card:hover {
  transform: scale(1.1, 1.1);
  cursor: pointer;
  z-index: 100;
}
