.Status-layout-wdt-100 {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.logo-in-hc-st-teacher-view {
  width: 160px;
  height: 80px;

  margin: 20px;
  border: 1px solid black;
  align-self: flex-start;
}

.hc-status-view-1 {
  padding: 10px;
  width: 90%;
  margin-top: 20px;
  /* background-color: #dc3545; */

  background-color: white;

  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.hc-status-view-in {
  padding: 5px;
  width: 95%;
  margin-top: 5px;
  background-color: #dc3545;
  /* background-color: white; */

  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 5px;

  color: rgb(255, 255, 255);

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.8em;

  display: flex;
  align-items: center;
}

.hc-status-head-in {
  width: 144px;
}

.hc-status-view-2 {
  padding: 10px;
  width: 90%;
  margin-top: 20px;
  min-height: 120px;
  /* background-color: #dc3545; */
  background-color: white;

  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content-teacher-op-hc {
  color: #dc3545;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.2em;
}

.content-table-hc {
  width: 90%;
  padding-top: 6px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;

  color: grey;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-2-hc {
  width: 94%;
  min-height: 32px;
  margin-top: 12px;

  border: 1px solid white;
  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 8px;
}

.status-2-hc-in {
  padding: 8px 5px 2px 5px;

  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  color: #152138;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-item-hc-status {
  min-width: 68px;
}

.table-item-hc-status-2 {
  min-width: 15px;
}

.button-comp-mar-col-2 {
  margin: 15px 30px 5px 5px;
  width: 120px;
  height: 25px;
  align-self: flex-end;

  color: white;
  background-color: #05bb8a;
  border: 1px solid #06091b;
  box-shadow: 0px 4px 4px rgb(167, 164, 164);
  border-radius: 8px;

  font-size: 10px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 841px) {
  .logo-in-hc-st-teacher-view {
    display: none;
  }
}

@media screen and (min-width: 840px) {
  .Status-layout-wdt-100 {
    width: 70%;
  }
  .button-comp-mar-col-2 {
    display: none;
  }

  .hc-status-view-1 {
    padding: 15px;
    min-height: 240px;
  }

  .hc-status-view-in {
    font-weight: 500;
    font-style: normal;
    font-size: 1.2em;
  }

  .hc-status-head-in {
    width: 250px;
  }

  .hc-status-view-2 {
    padding: 15px;
    min-height: 200px;
  }

  .content-teacher-op-hc {
    padding: 7px;
    font-weight: 700;
    font-size: 1.4em;
  }

  .content-table-hc {
    font-size: 16px;
  }

  .status-2-hc-in {
    font-weight: 550;
    font-size: 14px;
  }

  .table-item-hc-status {
    min-width: 80px;
  }

  .table-item-hc-status-2 {
    min-width: 22px;
  }
}
