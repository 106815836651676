.faq_main {
  width: 100%;
  padding: 40px 40px;
}

.faq_heading {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 32px;
}
.sections {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.faq_title {
  font-size: 20px;
  font-weight: 600;
}
.content_text {
  font-size: 18px;
  font-weight: 500;
}
.faq_main p {
  font-size: 16px;
  font-weight: 400;
  list-style: 1.3;
  margin-bottom: 4px;
}

.bold {
  font-weight: 600;
}
.accordion-button::after {
  margin-left: 10px !important;
}

@media screen and (max-width: 768px) {
  .faq_main {
    width: 100%;
    padding: 28px 16px;
  }
  .faq_heading {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .faq_title {
    font-size: 18px;
    font-weight: 600;
  }
  .content_text {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .faq_main p {
    font-size: 15px;
  }
}
