@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
  }
}

.main-nav {
  margin-left: 7%;
  margin-right: 7%;
}
.main-cont {
  background-color: #dc3545;
  box-shadow: 1px 8px 10px 3px #dddddd;
}
.logo-img {
  width: 200px;
}

.main-text {
  font-size: 20;
  font-weight: 600;
  color: #fff;
}
.main-text2 {
  font-size: 20;
  font-weight: 600;
  color: #dc3545;
}

.login-btn {
  font-size: 20;
  font-weight: 600;
  color: #fff;
  margin-left: 20px;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}
.login-btn:hover {
  transform: scale(1.1);
  background-color: #ab2f3c;
}

.dropdown-toggle::after {
  display: none !important;
}
