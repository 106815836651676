/* screen - teacher-quiz-list */

.Assignment-name-head-div-8-3 {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.Assignment-name-in-desk-8-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92%;
  margin: 10px 30px;
}

.Assignment-name-head-div-8 {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;

  width: 300px;
  margin-right: 70px;
}

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-white-12px {
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-quiz-list {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  /* height: 1024px; */
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.teacher-quiz-list .historytoggleoff-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-quiz-list .quiz-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-quiz-list .poweroff-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
  margin-top: 100px;
}

.teacher-quiz-list .accountbalance-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .add-7y4ohx {
  background-color: transparent;
  height: auto;
  left: 316px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-quiz-list .add-RqwtHL {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 17.59px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 37px;
}
.teacher-quiz-list .add-delete-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 287px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 87px;
}
.teacher-quiz-list .analytics-24px-3-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .arrowback-24px-1-fKmFVR {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-bottom: 7px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .assignment-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .assignment-24px-3-5xSoMg {
  background-color: transparent;
  flex-shrink: 1;
  height: 18px;
  overflow: hidden;
  position: relative;
  width: 18px;
}
.teacher-quiz-list .back-to-all-students-fKmFVR {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 23px;
  position: relative;
  width: 372px;
}
.teacher-quiz-list .biba-sobti-JJC5aN {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-quiz-list .bibasobtigmailcom-JJC5aN {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-quiz-list .booked-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 53px;
}
.teacher-quiz-list .booked-959ciF {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 53px;
}
.teacher-quiz-list .choose-file-jexT3O {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 66px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 61px;
}
.teacher-quiz-list .choose-from-existing-quizzes-3xHcwy {
  background-color: transparent;
  color: var(--blue-zodiac);

  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;

  min-width: 267px;
}

.choose-from-existing-quizzes-3xHcwy-1 {
  background-color: transparent;
  color: var(--blue-zodiac);

  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.teacher-quiz-list .choose-subject-i66023952371261-5RfFTN {
  align-self: center;
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-bottom: 3px;
  min-height: 12px;
  min-width: 58px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-quiz-list .class-name-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 93px;
}
.teacher-quiz-list .completed-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 200px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 79px;
}
.teacher-quiz-list .component-1-xpmbvu {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
  margin-left: 5%;
}
.teacher-quiz-list .component-29-FwNJBG {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: center;
  left: 0px;
  min-width: 36px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-quiz-list .component-29-hJgtXb {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: center;
  left: 0px;
  min-width: 36px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-quiz-list .component-29-sWDKkr {
  align-items: center;
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 36px;
  justify-content: flex-start;
  left: 25px;
  min-width: 36px;
  padding: 0 9px;
  position: absolute;
  top: 16px;
  width: auto;
}
.teacher-quiz-list .component-30-nFOi0F {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 33px;
  justify-content: flex-start;
  margin-top: 44px;
  min-width: 191px;
  padding: 6px 11px;
  position: relative;
  width: auto;
}
.teacher-quiz-list .description-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .ellipse-2-APAcB6 {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.teacher-quiz-list .ellipse-4-oLg079 {
  background-color: transparent;
  flex-shrink: 1;
  height: 38px;
  margin-top: -0.95px;
  position: relative;
  width: 37px;
}
.teacher-quiz-list .ellipse-4-uhVjlw {
  background-color: transparent;
  flex-shrink: 1;
  height: 38px;
  margin-top: -0.95px;
  position: relative;
  width: 37px;
}
.teacher-quiz-list .expandmore-24px-1-5RfFTN {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  margin-left: 91px;
  overflow: hidden;
  position: relative;
  width: 20px;
}
.teacher-quiz-list .feb-18-2021-IMMU6x {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 116px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 113px;
}
.teacher-quiz-list .flex-col-0sTO9E {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.teacher-quiz-list .flex-col-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 526px;
  position: relative;
  width: 100%;
}
.teacher-quiz-list .flex-col-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* flex-shrink: 1; */
  /* height: auto; */
  justify-content: flex-start;
  margin-left: 8%;
  margin-top: 40px;
  min-height: 989px;
  /* position: relative; */
  width: 45%;
}

.flex-container-col-C61RwL-8-ex {
  width: 100%;
  height: 100%;
  min-height: 540px;
  margin-top: 30px;

  display: flex;
  flex-direction: column;

  align-items: center;
}

.teacher-quiz-list .flex-col-GyJUNH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: space-between;
  min-height: 442px;
  position: relative;
  width: 290px;
}
.teacher-quiz-list .flex-col-VMr6Om {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 8%;
  margin-top: 51px;
  min-height: 922px;
  position: relative;
  width: 35%;
}
.teacher-quiz-list .flex-col-gkUG7I {
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  top: 189px;

  justify-content: space-between;
  margin-left: 57px;
  min-height: 603px;
  position: relative;
  width: 191px;
}

.teacher-quiz-list .flex-row-9Sxrs4 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 1;
  height: 155px;
  justify-content: space-evenly;
  margin-top: 49px;
  position: relative;
  width: 100%;
  overflow: auto;
}
.teacher-quiz-list .flex-row-WcGkdi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.teacher-quiz-list .flex-row-djxPV6 {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 23px;
  justify-content: flex-start;
  min-width: 377px;
  position: relative;
  width: auto;
}
.teacher-quiz-list .flex-row-xpmbvu {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  justify-content: flex-start;
  margin-left: 32px;
  margin-top: 60px;
  position: relative;
  width: 100%;
}
.teacher-quiz-list .give-a-new-quiz-3xHcwy {
  background-color: transparent;
  color: #dc3545;
  font-family: "Montserrat", Helvetica;
  margin: 5px 5px 15px 5px;

  font-size: 34px;
  font-style: normal;
  font-weight: 600;

  align-self: flex-start;
  width: 100%;
}

.flex-container-col-C61RwL-8-ex-1-xe {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-container-col-C61RwL-8-ex-1-x {
  width: 100%;
  margin: 5px 5px 15px 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teacher-quiz-list .group-112-djxPV6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 726px;
  margin-top: 14px;
  position: relative;
  width: 403px;
}
.teacher-quiz-list .group-113-8pxyib {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 763px;
  position: relative;
  width: 100%;
}
.teacher-quiz-list .group-114-lcFreP {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 763px;
  justify-content: flex-start;
  margin-top: 79px;
  position: relative;
  width: 100%;
}
.teacher-quiz-list .group-115-lcFreP {
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  min-width: 376px;
  position: relative;
  width: auto;
}
.teacher-quiz-list .group-146-8BB6VN {
  background-color: transparent;
  height: 18px;
  left: 38px;
  position: absolute;
  top: 120px;
  width: 74px;
}
.teacher-quiz-list .group-160-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 88px;
  min-width: 24px;
  position: relative;
  width: auto;
}
.teacher-quiz-list .group-162-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 778px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.teacher-quiz-list .group-163-L8UHM8 {
  background-color: #1f2531;
  height: 1120px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 63px;
}
.teacher-quiz-list .group-164-L8UHM8 {
  background-color: transparent;
  height: 778px;
  left: 20px;
  position: absolute;
  top: 200px;
  width: 24px;
}
.teacher-quiz-list .group-165-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 1024px;
  position: relative;
  width: 63px;
}
.teacher-quiz-list .group-177-w9UJWR {
  background-color: transparent;
  height: 36px;
  left: 56px;
  position: absolute;
  top: 16px;
  width: 36px;
}
.teacher-quiz-list .group-178-JaCYsP {
  background-color: transparent;
  height: 36px;
  left: 56px;
  position: absolute;
  top: 16px;
  width: 36px;
}
.teacher-quiz-list .group-22-cLbj0f {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  min-width: 316px;
  position: relative;
  width: auto;
}
.teacher-quiz-list .group-46-xpmbvu {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 67px;
  margin-top: 54px;
  position: relative;
  width: 100%;
}
.teacher-quiz-list .group-57-w9UJWR {
  background-color: transparent;
  height: 155px;
  flex-shrink: 1;
  position: relative;
  width: 151px;
}
.teacher-quiz-list .group-58-JaCYsP {
  background-color: transparent;
  height: 155px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 153px;
}
.teacher-quiz-list .group-90-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 174px;
  width: 369px;
}
.teacher-quiz-list .group-91-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 371px;
}
.teacher-quiz-list .group-92-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 369px;
}
.teacher-quiz-list .group-93-8MUoy6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 11px;
  min-height: 262px;
  position: absolute;
  top: 133px;
  width: 371px;
}
.teacher-quiz-list .group-94-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 369px;
}
.teacher-quiz-list .group-95-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 369px;
}
.teacher-quiz-list .group-96-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 369px;
}
.teacher-quiz-list .group-97-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 587px;
  width: 369px;
}
.teacher-quiz-list .group-98-8MUoy6 {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 646px;
  width: 369px;
}
.teacher-quiz-list .group-9VbO77 {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  position: relative;
  width: 20px;
}
.teacher-quiz-list .group-FHHSuW {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .group-FwNJBG {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 18px;
  justify-content: flex-start;
  left: 9px;
  min-width: 18px;
  position: absolute;
  top: 9px;
  width: auto;
}
.teacher-quiz-list .group-UKbWOd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.teacher-quiz-list .group-Z2xJ4E {
  background-color: transparent;
  flex-shrink: 1;
  height: 18px;
  position: relative;
  width: 18px;
}
.teacher-quiz-list .group-ZTIRla {
  background-color: transparent;
  flex-shrink: 1;
  height: 18px;
  position: relative;
  width: 18px;
}
.teacher-quiz-list .group-alxHTq {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-quiz-list .group-hJgtXb {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 18px;
  justify-content: flex-start;
  left: 9px;
  min-width: 18px;
  position: absolute;
  top: 9px;
  width: auto;
}
.teacher-quiz-list .group-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: flex-start;
  left: 0px;
  min-width: 20px;
  position: absolute;
  top: 448px;
  width: auto;
}
.teacher-quiz-list .group-tQmilE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.teacher-quiz-list .help-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .historytoggleoff-24px-1-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .line-5-GcxuTD {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.teacher-quiz-list .line-6-GcxuTD {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.teacher-quiz-list .notifications-24px-2-1-cLbj0f {
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.teacher-quiz-list .open-PHCGTk {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 6px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 27px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 4px;
  width: 20px;
}
.teacher-quiz-list .open-calendar-IMMU6x {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.teacher-quiz-list .open-sn3Fku {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 6px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 66px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 124px;
  width: 20px;
}
.teacher-quiz-list .opened-1ihGMx {
  background-color: transparent;
  height: auto;
  left: 304px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 53px;
}
.teacher-quiz-list .opened-K0vR9a {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-quiz-list .opened-SbEpTm {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-quiz-list .opened-TYZK0W {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-quiz-list .opened-aOipQ0 {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-quiz-list .opened-oArczI {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-quiz-list .opened-xEcNns {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-quiz-list .or-3xHcwy-8 {
  background-color: transparent;
  /* flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 14px; */
  margin-top: 39px;
  margin-bottom: 30px;
  /* min-height: 22px;
  min-width: 31px;
  position: relative;
  text-align: left;
  width: auto; */
}
.teacher-quiz-list .organic-4-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 67px;
}
.teacher-quiz-list .organic-5-wTq9AN {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 67px;
}
.teacher-quiz-list .overlap-group-4xxXEe {
  background-color: transparent;
  flex-shrink: 1;
  height: 155px;
  position: relative;
  width: 151px;
}
.teacher-quiz-list .overlap-group-RqwtHL {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 365px;
}
.teacher-quiz-list .overlap-group1-4xxXEe {
  background-color: transparent;
  flex-shrink: 1;
  height: 155px;
  margin-left: 68px;
  position: relative;
  width: 153px;
}
.teacher-quiz-list .overlap-group2-nFOi0F {
  background-color: transparent;
  /* flex-shrink: 1; */
  height: 40px;
  /* margin-top: 101px;
  position: relative; */
  width: 210px;

  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 2px 2px #ccc;

  text-align: center;
  color: #dc3545;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
}

.Assignment-Name-input-1 {
  width: 40%;
  margin: 0px;
}

.teacher-quiz-list .overlap-group3-0xy0vn {
  background-color: transparent;
  height: 40px;
  position: relative;
  margin-top: 20px;
  width: 191px;
}
.teacher-quiz-list .quiz-1-ungraded-8BB6VN {
  background-color: transparent;
  height: auto;
  /* left: 26px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  /* top: 69px; */
  width: 99px;
}
.teacher-quiz-list .quiz-2-not-submitted-sn3Fku {
  background-color: transparent;
  height: auto;
  left: 26px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 69px;
  width: 99px;
}
.teacher-quiz-list .rectangle-31-8MUoy6 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 726px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.teacher-quiz-list .rectangle-40-8BB6VN {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 155px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 149px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.teacher-quiz-list .rectangle-40-8MUoy6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.teacher-quiz-list .rectangle-40-sWDKkr {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 67px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-quiz-list .rectangle-40-sn3Fku {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 155px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 149px;
}
.teacher-quiz-list .rectangle-41-8MUoy6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.teacher-quiz-list .rectangle-41-PHCGTk {
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 18px;
  /* left: 0px;
  position: absolute;
  top: 0px; */
  width: 72px;
}
.teacher-quiz-list .rectangle-41-sn3Fku {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 18px;
  left: 38px;
  position: absolute;
  top: 120px;
  width: 72px;
}
.teacher-quiz-list .rectangle-42-jexT3O {
  left: 0px;
  position: absolute;
  top: 0px;
  width: 191px;
}
.teacher-quiz-list .rectangle-43-GW1jy1 {
  background-color: var(--blue-zodiac);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 40px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 191px;
}
.teacher-quiz-list .rectangle-44-K0vR9a {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-list .rectangle-44-SbEpTm {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-list .rectangle-44-TYZK0W {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-list .rectangle-44-aOipQ0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-list .rectangle-44-oArczI {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-list .rectangle-44-xEcNns {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-list .rectangle-47-8MUoy6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.teacher-quiz-list .rectangle-48-wTq9AN {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-list .rectangle-49-1ihGMx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-list .rectangle-50-7y4ohx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-quiz-list .rectangle-7-nFOi0F {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  margin-left: 65.99px;
  position: relative;
  width: 1px;
}
.teacher-quiz-list .school-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .span0-5NNoRV {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-quiz-list .span0-AXJLCk {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.teacher-quiz-list .span0-Hgl77x {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-quiz-list .span0-K8Ge20 {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.teacher-quiz-list .span1-5NNoRV {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-quiz-list .span1-AXJLCk {
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.teacher-quiz-list .span1-K8Ge20 {
  color: rgba(126, 126, 126, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.teacher-quiz-list .status-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 208px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.teacher-quiz-list .stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .submit-GW1jy1 {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 66px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 61px;
}
.teacher-quiz-list .time-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.teacher-quiz-list .upcoming-wTq9AN {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 76px;
}
.teacher-quiz-list .upload-a-new-quiz-3xHcwy {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 42px;
  min-height: 22px;
  min-width: 170px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-quiz-list .vatsal-agarwal-sWDKkr {
  background-color: transparent;
  height: auto;
  left: 74px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 25px;
  width: 181px;
}
.teacher-quiz-list .vector-0sTO9E {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.teacher-quiz-list .vector-3ahjCQ {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-quiz-list .vector-541Gml {
  background-color: transparent;
  height: 16px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 16px;
}
.teacher-quiz-list .vector-56AgMz {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.teacher-quiz-list .vector-8k85HE {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 170px;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .vector-90KxxC {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-quiz-list .vector-9xgjqN {
  background-color: transparent;
  height: 14px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.teacher-quiz-list .vector-APAcB6 {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.teacher-quiz-list .vector-B8xRgA {
  background-color: transparent;
  height: 16px;
  left: 0px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-quiz-list .vector-BKwYFx {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.teacher-quiz-list .vector-Chnqxl {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.teacher-quiz-list .vector-EaMiW0 {
  background-color: transparent;
  height: 20px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 18px;
}
.teacher-quiz-list .vector-HFTNKR {
  background-color: transparent;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 7px;
  width: 10px;
}
.teacher-quiz-list .vector-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .vector-LysGKa {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-list .vector-Q7CGrB {
  background-color: transparent;
  height: 16px;
  left: -1px;
  position: absolute;
  top: 0px;
  width: 14px;
}
.teacher-quiz-list .vector-SCg0eG {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  position: relative;
  width: 24px;
}
.teacher-quiz-list .vector-SIAzDu {
  background-color: transparent;
  height: 20px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 16px;
}
.teacher-quiz-list .vector-T4S6jg {
  background-color: transparent;
  height: 1px;
  left: 9px;
  position: absolute;
  top: 9px;
  width: 1px;
}
.teacher-quiz-list .vector-TCNT1O {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-list .vector-UgkvXI {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-list .vector-WcGkdi {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.teacher-quiz-list .vector-XV2kdn {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.teacher-quiz-list .vector-cmbNsS {
  background-color: transparent;
  height: 16px;
  /* left: 3px;
  position: absolute;
  top: -1px; */
  width: 16px;
}
.teacher-quiz-list .vector-de3xvx {
  background-color: transparent;
  height: 16px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 15px;
}
.teacher-quiz-list .vector-dlRKJA {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-quiz-list .vector-ePmONu {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-quiz-list .vector-fPPgx3 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-list .vector-grLYV9 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-list .vector-iniHBi {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-list .vector-is5V5j {
  background-color: transparent;
  height: 1px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.teacher-quiz-list .vector-jWA1w5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.teacher-quiz-list .vector-kCLhu1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-list .vector-mju0OS {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-quiz-list .vector-oRqxWx {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-quiz-list .vector-p3Fi50 {
  background-color: transparent;
  height: 16px;
  left: 3px;
  position: absolute;
  top: -1px;
  width: 15px;
}
.teacher-quiz-list .vector-s2Y5x6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.teacher-quiz-list .vector-xbu9jm {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.teacher-quiz-list .vector-yQhZ26 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-list .x10pm-SbEpTm {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 45px;
}
.teacher-quiz-list .x12pm-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 47px;
}
.teacher-quiz-list .x1pm-8MUoy6 {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 133px;
  width: 33px;
}
.teacher-quiz-list .x2pm-1ihGMx {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 39px;
}
.teacher-quiz-list .x3pm-wTq9AN {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 31px;
}
.teacher-quiz-list .x4pm-oArczI {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-quiz-list .x5pm-7y4ohx {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-quiz-list .x6pm-K0vR9a {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-quiz-list .x7pm-TYZK0W {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-quiz-list .x8pm-aOipQ0 {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-quiz-list .x9pm-xEcNns {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - teacher-quiz-list-mobile */

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-white-12px {
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-quiz-list-mobile {
  align-items: center;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 2006px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 17px;
  position: relative;
  width: 414px;
}
.teacher-quiz-list-mobile .add-JxMwXJ {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 30.24px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 35px;
}
.teacher-quiz-list-mobile .add-delete-ZkK6i9 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 22px;
  min-height: 21px;
  position: relative;
  text-align: left;
  width: 92px;
}
.teacher-quiz-list-mobile .add-xjnDBx {
  background-color: transparent;
  height: auto;
  left: 283px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-list-mobile .arrowback-24px-1-bxkR86 {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-bottom: 2px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-quiz-list-mobile .back-to-all-students-bxkR86 {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 16px;
  min-height: 36px;
  position: relative;
  width: 324px;
}
.teacher-quiz-list-mobile .booked-tD1Dnp {
  background-color: transparent;
  height: auto;
  left: 283px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 50px;
}
.teacher-quiz-list-mobile .booked-xTp40i {
  background-color: transparent;
  height: auto;
  left: 283px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 204px;
  width: 50px;
}
.teacher-quiz-list-mobile .choose-file-nqcpBp {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 66px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 61px;
}
.teacher-quiz-list-mobile .choose-from-existing-quizes-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);

  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  margin-left: 13px;
  margin-top: 30px;
  margin-bottom: 7px;
  min-height: 17px;
  min-width: 201px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-quiz-list-mobile .choose-subject-i66022772371261-02ks1v {
  align-self: center;
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-bottom: 3px;
  min-height: 12px;
  min-width: 58px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-quiz-list-mobile .class-name-ZkK6i9 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 20px;
  min-height: 21px;
  position: relative;
  text-align: left;
  width: 94px;
}
.teacher-quiz-list-mobile .completed-xTp40i {
  background-color: transparent;
  height: auto;
  left: 183px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 74px;
}
.teacher-quiz-list-mobile .component-1-dXBp89 {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.teacher-quiz-list-mobile .component-29-3nzxAx {
  background-color: transparent;
  background-image: url(./img/ellipse-4@2x.svg);
  background-size: 100% 100%;
  height: 36px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 36px;
}
.teacher-quiz-list-mobile .component-29-jdx1ix {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 44px;
  justify-content: center;
  left: 0px;
  min-width: 44px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-quiz-list-mobile .component-29-xWL7Bw {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 44px;
  justify-content: center;
  left: 0px;
  min-width: 44px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-quiz-list-mobile .component-30-C61RwL {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 33px;
  justify-content: flex-start;
  margin-right: 3px;
  margin-top: 15px;
  min-width: 191px;
  padding: 6px 11px;
  position: relative;
  width: auto;
}
.teacher-quiz-list-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.teacher-quiz-list-mobile .ellipse-4-9FnjdW {
  background-color: transparent;
  flex-shrink: 1;
  height: 45px;
  margin-top: -0.5px;
  position: relative;
  width: 45px;
}
.teacher-quiz-list-mobile .ellipse-4-KjrBG4 {
  background-color: transparent;
  flex-shrink: 1;
  height: 45px;
  margin-top: -0.5px;
  position: relative;
  width: 45px;
}
.teacher-quiz-list-mobile .expandmore-24px-1-02ks1v {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  margin-left: 91px;
  overflow: hidden;
  position: relative;
  width: 20px;
}
.teacher-quiz-list-mobile .feb-18-2021-iaim4d {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 87px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 117px;
}
.teacher-quiz-list-mobile .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacher-quiz-list-mobile .flex-row-CWcvtF {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 21px;
  justify-content: flex-start;
  margin-right: 2.63px;
  margin-top: 33px;
  min-width: 340px;
  position: relative;
  width: auto;
}
.teacher-quiz-list-mobile .flex-row-RrQxtm {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 23px;
  justify-content: flex-start;
  margin-left: 3px;
  min-width: 353px;
  position: relative;
  width: auto;
}
.teacher-quiz-list-mobile .give-a-new-quiz-XvbDcl {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 36px;
  position: relative;
  width: 363px;
}
.teacher-quiz-list-mobile .group-145-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 36px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 52px;
  min-width: 364px;
  position: relative;
  width: auto;
}
.teacher-quiz-list-mobile .group-147-8LJ7gk {
  background-color: transparent;
  flex-shrink: 1;
  height: 190px;
  position: relative;
  width: 198px;
}
.teacher-quiz-list-mobile .group-147-ockMBu {
  background-color: transparent;
  flex-shrink: 1;
  height: 190px;
  position: relative;
  width: 198px;
  margin-top: 10px;
}
.teacher-quiz-list-mobile .group-148-4eduM0 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 190px;
  justify-content: flex-start;
  /* left: 0px; */
  margin-right: 4px;
  margin-top: 48px;
  min-width: 194px;
  position: relative;
  /* position: absolute; */
  /* top: 0px; */
  width: auto;
}
.teacher-quiz-list-mobile .group-149-RH0WJ5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 190px;
  justify-content: flex-start;
  left: 0px;
  min-width: 194px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-quiz-list-mobile .group-150-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 36px;
  justify-content: flex-start;
  margin-left: 1px;
  margin-top: 80px;
  min-width: 363px;
  position: relative;
  width: auto;
}
.teacher-quiz-list-mobile .group-151-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 40px;
  margin-left: 7px;
  margin-top: 15px;
  position: relative;
  width: 210px;

  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 2px 2px #ccc;

  text-align: center;
  color: #dc3545;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
}
.teacher-quiz-list-mobile .group-152-2K5hL5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 658px;
  position: relative;
  width: 371px;
}
.teacher-quiz-list-mobile .group-153-RrQxtm {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 658px;
  justify-content: flex-start;
  margin-top: 2px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacher-quiz-list-mobile .group-154-C61RwL {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 35px;
  min-height: 738px;
  position: relative;
  width: 371px;
}
.teacher-quiz-list-mobile .group-177-4eduM0 {
  background-color: transparent;
  height: 44px;
  left: 73px;
  position: absolute;
  top: 23px;
  width: 44px;
}
.teacher-quiz-list-mobile .group-184-4a9k2Y {
  background-color: transparent;
  height: 36px;
  left: 8px;
  position: absolute;
  top: 6px;
  width: 36px;
}
.teacher-quiz-list-mobile .group-185-RH0WJ5 {
  background-color: transparent;
  height: 44px;
  left: 73px;
  position: absolute;
  top: 23px;
  width: 44px;
}
.teacher-quiz-list-mobile .group-46-4a9k2Y {
  background-color: transparent;
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 363px;
}
.teacher-quiz-list-mobile .group-58-Tt2cBc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 190px;
  justify-content: flex-start;
  min-width: 194px;
  position: relative;
  width: auto;
}
.teacher-quiz-list-mobile .group-58-dBCxR8 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 1;
  height: 190px;
  justify-content: space-around;
  min-width: 194px;
  position: relative;
  width: auto;
  overflow: auto;
}
.teacher-quiz-list-mobile .group-90-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 128px;
  width: 346px;
}
.teacher-quiz-list-mobile .group-91-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 6px;
  position: absolute;
  top: 187px;
  width: 348px;
}
.teacher-quiz-list-mobile .group-92-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 6px;
  position: absolute;
  top: 245px;
  width: 346px;
}
.teacher-quiz-list-mobile .group-93-xTp40i {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 8px;
  min-height: 262px;
  position: absolute;
  top: 86px;
  width: 348px;
}
.teacher-quiz-list-mobile .group-94-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 363px;
  width: 346px;
}
.teacher-quiz-list-mobile .group-95-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 422px;
  width: 346px;
}
.teacher-quiz-list-mobile .group-96-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 481px;
  width: 346px;
}
.teacher-quiz-list-mobile .group-97-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 540px;
  width: 346px;
}
.teacher-quiz-list-mobile .group-98-xTp40i {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 599px;
  width: 346px;
}
.teacher-quiz-list-mobile .group-9VnMJa {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-quiz-list-mobile .group-g8YOdo {
  background-color: transparent;
  flex-shrink: 1;
  height: 22px;
  position: relative;
  width: 22px;
}
.teacher-quiz-list-mobile .group-jdx1ix {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 11px;
  min-width: 22px;
  position: absolute;
  top: 11px;
  width: auto;
}
.teacher-quiz-list-mobile .group-xWL7Bw {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: flex-start;
  left: 11px;
  min-width: 22px;
  position: absolute;
  top: 11px;
  width: auto;
}
.teacher-quiz-list-mobile .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.teacher-quiz-list-mobile .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.teacher-quiz-list-mobile .mathematics-class-9-6x6k20 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: auto;
}
.teacher-quiz-list-mobile .open-HBYxxn {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 153px;
  width: 29px;
}
.teacher-quiz-list-mobile .open-M9jhmS {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 153px;
  width: 29px;
}
.teacher-quiz-list-mobile .open-calendar-iaim4d {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.teacher-quiz-list-mobile .opened-1VCCbO {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-list-mobile .opened-As88TX {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-list-mobile .opened-K48Bcv {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.teacher-quiz-list-mobile .opened-TKEXTm {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-list-mobile .opened-WWbugp {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-list-mobile .opened-cwGgeD {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-list-mobile .opened-sA7dyK {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacher-quiz-list-mobile .or-C61RwL-10 {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 7px;
  margin-top: 25px;
  min-height: 17px;
  min-width: 25px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-quiz-list-mobile .organic-4-xTp40i {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 69px;
}
.teacher-quiz-list-mobile .organic-5-EpciUK {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.teacher-quiz-list-mobile .overlap-group-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 190px;
  margin-right: 4px;
  margin-top: 85px;
  position: relative;
  width: 194px;
}
.teacher-quiz-list-mobile .overlap-group1-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 190px;
  margin-right: 4px;
  margin-top: 55px;
  position: relative;
  width: 194px;
}
.teacher-quiz-list-mobile .overlap-group2-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 50px;
  margin-left: 1px;
  margin-top: 43px;
  position: relative;
  width: 363px;
}
.teacher-quiz-list-mobile .overlap-group3-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-top: 5px;
  position: relative;
  width: 191px;
}
.teacher-quiz-list-mobile .overlap-group4-JxMwXJ {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 342px;
}
.teacher-quiz-list-mobile .quiz-1-submitted-HBYxxn {
  background-color: transparent;
  height: auto;
  /* left: 34px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  /* top: 85px; */
  width: 129px;
}
.teacher-quiz-list-mobile .quiz-2-graded-M9jhmS {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 85px;
  width: 129px;
}
.teacher-quiz-list-mobile .rectangle-31-xTp40i {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 658px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-list-mobile .rectangle-40-6x6k20 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacher-quiz-list-mobile .rectangle-40-HBYxxn {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 190px;
  /* left: 0px;
  position: absolute;
  top: 0px; */
  width: 194px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.teacher-quiz-list-mobile .rectangle-40-M9jhmS {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 190px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 194px;
}
.teacher-quiz-list-mobile .rectangle-40-xTp40i {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 7px;
  position: absolute;
  top: 10px;
  width: 342px;
}
.teacher-quiz-list-mobile .rectangle-41-HBYxxn {
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 22px;
  /* left: 65px; */
  /* position: absolute; */
  /* top: 146px; */
  width: 72px;
}
.teacher-quiz-list-mobile .rectangle-41-M9jhmS {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 22px;
  left: 65px;
  position: absolute;
  top: 146px;
  width: 72px;
}
.teacher-quiz-list-mobile .rectangle-41-xTp40i {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 6px;
  position: absolute;
  top: 128px;
  width: 342px;
}
.teacher-quiz-list-mobile .rectangle-42-nqcpBp {
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 33px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 191px;
}
.teacher-quiz-list-mobile .rectangle-43-eSNWXa {
  background-color: var(--blue-zodiac);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 33px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 191px;
}
.teacher-quiz-list-mobile .rectangle-44-1VCCbO {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-list-mobile .rectangle-44-As88TX {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-list-mobile .rectangle-44-TKEXTm {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-list-mobile .rectangle-44-WWbugp {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-list-mobile .rectangle-44-cwGgeD {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-list-mobile .rectangle-44-sA7dyK {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-list-mobile .rectangle-47-xTp40i {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 7px;
  position: absolute;
  top: 69px;
  width: 342px;
}
.teacher-quiz-list-mobile .rectangle-48-EpciUK {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-list-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.teacher-quiz-list-mobile .rectangle-49-K48Bcv {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-list-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-quiz-list-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-quiz-list-mobile .rectangle-50-xjnDBx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacher-quiz-list-mobile .span0-4Nucrq {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.teacher-quiz-list-mobile .span0-HScZy5 {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-quiz-list-mobile .span0-Mq46bO {
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.teacher-quiz-list-mobile .span0-f1YMcf {
  color: rgb(220, 53, 69);
  font-style: normal;
}
.teacher-quiz-list-mobile .span1-4Nucrq {
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.teacher-quiz-list-mobile .span1-HScZy5 {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-quiz-list-mobile .span1-Mq46bO {
  color: rgba(5, 187, 138, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.teacher-quiz-list-mobile .span1-f1YMcf {
  color: rgb(220, 53, 69);
  font-style: normal;
  font-weight: 600;
}
.teacher-quiz-list-mobile .status-ZkK6i9 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 19px;
  min-height: 21px;
  position: relative;
  text-align: left;
  width: 49px;
}
.teacher-quiz-list-mobile .submit-eSNWXa {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 67px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 61px;
}
.teacher-quiz-list-mobile .time-ZkK6i9 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 21px;
  position: relative;
  text-align: left;
  width: 42px;
}
.teacher-quiz-list-mobile .upcoming-EpciUK {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.teacher-quiz-list-mobile .upload-a-new-quiz-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 1px;
  margin-top: 10px;
  min-height: 17px;
  min-width: 133px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-quiz-list-mobile .vatsal-agarwal-6x6k20 {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 55px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 9px;
  width: 140px;
}
.teacher-quiz-list-mobile .vector-3nzxAx {
  background-color: transparent;
  height: 18px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 22px;
}
.teacher-quiz-list-mobile .vector-5FRq6D {
  background-color: transparent;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 7px;
  width: 10px;
}
.teacher-quiz-list-mobile .vector-9DUZ6p {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-quiz-list-mobile .vector-djxHUs {
  background-color: transparent;
  height: 19px;
  left: -1px;
  position: absolute;
  top: 4px;
  width: 19px;
}
.teacher-quiz-list-mobile .vector-h8WU8h {
  background-color: transparent;
  height: 19px;
  left: -1px;
  position: absolute;
  top: 4px;
  width: 19px;
}
.teacher-quiz-list-mobile .vector-qTOYac {
  background-color: transparent;
  height: 19px;
  left: 4px;
  position: absolute;
  top: -1px;
  width: 19px;
}
.teacher-quiz-list-mobile .vector-tDx0rx {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-quiz-list-mobile .vector-teIxNm {
  background-color: transparent;
  height: 19px;
  /* left: 4px;
  position: absolute;
  top: -1px; */
  width: 19px;
}
.teacher-quiz-list-mobile .vector-wGeoOk {
  background-color: transparent;
  height: 1px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 1px;
}
.teacher-quiz-list-mobile .x10pm-WWbugp {
  background-color: transparent;
  height: auto;
  left: 7px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 42px;
}
.teacher-quiz-list-mobile .x12pm-xTp40i {
  background-color: transparent;
  height: auto;
  left: 16px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 23px;
  width: 44px;
}
.teacher-quiz-list-mobile .x1pm-xTp40i {
  background-color: transparent;
  height: auto;
  left: 16px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 86px;
  width: 31px;
}
.teacher-quiz-list-mobile .x2pm-K48Bcv {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.teacher-quiz-list-mobile .x3pm-EpciUK {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.teacher-quiz-list-mobile .x4pm-As88TX {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-list-mobile .x5pm-xjnDBx {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-list-mobile .x6pm-cwGgeD {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-list-mobile .x7pm-TKEXTm {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-list-mobile .x8pm-1VCCbO {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacher-quiz-list-mobile .x9pm-sA7dyK {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .teacher-quiz-list {
    display: none;
  }

  .Assignment-name-in-desk-8-2 {
    flex-direction: column;
    width: 58%;
    margin: 0px 7px;
  }
}
@media screen and (min-width: 600px) {
  .teacher-quiz-list-mobile {
    display: none;
  }
}
