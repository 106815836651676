.main {
  width: 100%;
  height: auto;
  position: relative;
}
.img {
  color: black;
  height: 500px;
  background: blueviolet;
}
