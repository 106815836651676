* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", Helvetica;
}

.form-control:focus {
  border: none;
  box-shadow: 0 0 3px 3px rgba(236, 53, 69, 0.5);
}

.navbar2 {
  /* position: fixed;
  top: 0;
  left: 0;
  width: 80px;
  height: 100%;
  border: 1px solid black; */
  height: 100%;
  width: 50px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #1f2531;
  overflow-x: hidden;
  padding-top: 50px;
}

.main {
  margin-left: 50px;
}

.input-group-text {
  font-size: 14px;
  background-color: #dc3545;
  color: white;
}

.text {
  font-size: 16px;
  font-weight: 500;
}

.hamburger-menu1
{
  display: none;
  
  
}


@media screen and (max-width: 992px) {
  .col-lg-4 {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 720px) {
  .navbar2{
    display: none;
}
.main{
  margin-left: -23px;
  margin-top: 40px;
}
  .hamburger-menu1{
    font-size: 2.2rem;
    margin: 1.2em;
    margin-right: 1em;
    display: flex;
    height: 22px;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }
  
}