@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,500,600|Roboto:700,500");

.border-1px-blue-zodiac {
  border: 1px solid var(--blue-zodiac);
}
.montserrat-medium-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-group-classroom-quiz {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.teacher-group-classroom-quiz .accountbalance-24px-1-KSgdom {
  background-color: var(--blue-zodiac);
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-group-classroom-quiz .historytoggleoff-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-group-classroom-quiz .quiz-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-group-classroom-quiz .poweroff-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
  margin-top: 100px;
}

.teacher-group-classroom-quiz .add-KKOYJf {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 17.59px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 37px;
}
.teacher-group-classroom-quiz .add-cfQi7W {
  background-color: transparent;
  height: auto;
  left: 316px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-group-classroom-quiz .add-delete-ePioxc {
  background-color: transparent;
  height: auto;
  left: 287px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 87px;
}
.teacher-group-classroom-quiz .amount-Sgr7kw {
  background-color: transparent;
  height: auto;
  left: 202px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: auto;
}
.teacher-group-classroom-quiz .analytics-24px-3-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-group-classroom-quiz .andb586-Sgr7kw {
  background-color: transparent;
  height: auto;
  /* left: 594px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: center;
  /* top: 138px; */
  width: auto;
}
.teacher-group-classroom-quiz .assignment-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-group-classroom-quiz .attendance-302Mxf {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 22px;
  min-width: 123px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-group-classroom-quiz .average-rating-Wa7qGO {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 9px;
  min-height: 22px;
  min-width: 158px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-group-classroom-quiz .biba-sobti-2jrdHj {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-group-classroom-quiz .bibasobtigmailcom-2jrdHj {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-group-classroom-quiz .booked-IxVOPE {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 53px;
}
.teacher-group-classroom-quiz .booked-ePioxc {
  background-color: transparent;
  height: auto;
  left: 314px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 251px;
  width: 53px;
}
.teacher-group-classroom-quiz .class-name-ePioxc {
  background-color: transparent;
  height: auto;
  left: 87px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 93px;
}
.teacher-group-classroom-quiz .completed-PXwdVf {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 515px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 138px;
  width: auto;
}
.teacher-group-classroom-quiz .completed-ePioxc {
  background-color: transparent;
  height: auto;
  left: 200px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 79px;
}
.teacher-group-classroom-quiz .component-1-GyJUNH {
  background-color: transparent;
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.teacher-group-classroom-quiz .date-Sgr7kw {
  background-color: transparent;
  height: auto;
  left: 340px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: auto;
}
.teacher-group-classroom-quiz .description-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-group-classroom-quiz .ellipse-2-nY7RYD {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.teacher-group-classroom-quiz .feb-18-2021-xQuxwr {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 116px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 113px;
}
.teacher-group-classroom-quiz .flex-col-C61RwL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 8%;
  margin-top: 45px;
  min-height: 928px;
  position: relative;
  width: 96%;
}
.teacher-group-classroom-quiz .flex-col-OfAOxu {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 115px;
  position: relative;
  width: 265px;
}
.teacher-group-classroom-quiz .flex-col-QkTteQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: 100%;
  justify-content: flex-start;
  min-height: 670px;
  position: relative;
  width: 55%;
}
.teacher-group-classroom-quiz .flex-col-R6Ddt1 {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.teacher-group-classroom-quiz .flex-col-cSfBx4 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 53px;
  position: relative;
  width: 162px;
}
.teacher-group-classroom-quiz .flex-row-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 123px;
  justify-content: space-between;
  position: relative;
  width: 95%;
  margin: auto;
}
.teacher-group-classroom-quiz .flex-row-Fjgz1C {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 178px;
  justify-content: flex-start;
  min-width: 793px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .flex-row-Wa7qGO {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 22px;
  justify-content: flex-start;
  min-width: 162px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .flex-row-WcGkdi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .flex-row-oV5UrE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 778px;
  justify-content: flex-start;
  margin-top: 27px;
  position: relative;
  width: 100%;
}
.teacher-group-classroom-quiz .flex-row-qItZ2O {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 22px;
  justify-content: flex-start;
  margin-top: 9px;
  min-width: 166px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .flex-row-xL4dLV {
  align-items: flex-start;
  align-self: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 23px;
  justify-content: flex-start;
  min-width: 377px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .flex-row-xqwxU1 {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 53px;
  justify-content: flex-start;
  min-width: 218px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .friday-UDxQs2 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 510px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 44px;
  width: 130px;
}
.teacher-group-classroom-quiz .group-112-xL4dLV {
  background-color: transparent;
  flex-shrink: 1;
  height: 726px;
  margin-top: 14px;
  position: relative;
  width: 403px;
}
.teacher-group-classroom-quiz .group-113-eLYKxH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 763px;
  position: relative;
  width: 387px;
  margin: auto;
}
.teacher-group-classroom-quiz .group-114-QkTteQ {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 763px;
  justify-content: flex-start;
  margin-left: 5%;
  position: relative;
  width: 35%;
}
.teacher-group-classroom-quiz .group-115-GyJUNH {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-top: 6px;
  min-width: 376px;
  position: relative;
  width: auto;
  margin-right: 2%;
}
.teacher-group-classroom-quiz .group-125-GyJUNH {
  align-items: flex-end;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 115px;
  justify-content: flex-start;
  margin-left: 160px;
  min-width: 295px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .group-126-A6SpMB {
  background-color: transparent;
  flex-shrink: 1;
  height: 172px;
  position: relative;
  width: 761px;
}
.teacher-group-classroom-quiz .group-160-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 88px;
  min-width: 24px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .group-162-odnTUH-030 {
  height: 80%;
  margin: 125px 0px 20px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.teacher-group-classroom-quiz .group-163-L8UHM8 {
  background-color: #1f2531;
  height: 100%;
  left: 0px;
  position: fixed;
  overflow: hidden;
  top: 0px;
  width: 63px;
}

.teacher-group-classroom-quiz .group-165-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 100%;
  z-index: 300;
  position: relative;
  width: 63px;
}
.teacher-group-classroom-quiz .group-188-PXwdVf {
  background-color: transparent;
  height: 453px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 1220px;
}
.teacher-group-classroom-quiz .group-22-3boKt2 {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  min-width: 316px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .group-38-j7ZAdN {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 172px;
  position: relative;
  width: 749px;
}
.teacher-group-classroom-quiz .group-39-gLH3zI {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 172px;
  justify-content: flex-start;
  min-width: 749px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .group-40-yr0SVl {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 172px;
  justify-content: flex-start;
  min-width: 749px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .group-41-gGlKTJ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 172px;
  justify-content: flex-start;
  min-width: 749px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .group-43-UDxQs2 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 172px;
  justify-content: flex-start;
  left: 0px;
  min-width: 749px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-group-classroom-quiz .group-90-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 174px;
  width: 369px;
}
.teacher-group-classroom-quiz .group-91-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 233px;
  width: 371px;
}
.teacher-group-classroom-quiz .group-92-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 292px;
  width: 369px;
}
.teacher-group-classroom-quiz .group-93-ePioxc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 11px;
  min-height: 262px;
  position: absolute;
  top: 133px;
  width: 371px;
}
.teacher-group-classroom-quiz .group-94-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 410px;
  width: 369px;
}
.teacher-group-classroom-quiz .group-95-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 469px;
  width: 369px;
}
.teacher-group-classroom-quiz .group-96-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 528px;
  width: 369px;
}
.teacher-group-classroom-quiz .group-97-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 587px;
  width: 369px;
}
.teacher-group-classroom-quiz .group-98-ePioxc {
  background-color: transparent;
  height: 44px;
  left: 11px;
  position: absolute;
  top: 646px;
  width: 369px;
}
.teacher-group-classroom-quiz .group-9VbO77 {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  position: relative;
  width: 20px;
}
.teacher-group-classroom-quiz .group-FHHSuW {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-group-classroom-quiz .group-UKbWOd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.teacher-group-classroom-quiz .group-alxHTq {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-group-classroom-quiz .group-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: flex-start;
  left: 0px;
  min-width: 20px;
  position: absolute;
  top: 448px;
  width: auto;
}
.teacher-group-classroom-quiz .group-tQmilE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.teacher-group-classroom-quiz .help-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-group-classroom-quiz .historytoggleoff-24px-1-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-group-classroom-quiz .insertinvitation-24px-1-A6SpMB {
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-left: 8px;
  overflow: hidden;
  padding: 0 11.8px;
  position: relative;
  width: 24px;
}
.teacher-group-classroom-quiz .join-class-UDxQs2 {
  background-color: transparent;
  color: var(--white);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 168px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 120px;
  width: 113px;
}
.teacher-group-classroom-quiz .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.teacher-group-classroom-quiz .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.teacher-group-classroom-quiz .mathematics-section-g-UDxQs2 {
  background-color: transparent;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 47px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 55px;
  width: 322px;
}
.teacher-group-classroom-quiz .notifications-24px-2-1-3boKt2 {
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.teacher-group-classroom-quiz .number-of-classes-per-day-xqwxU1 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 9px;
  min-height: 22px;
  min-width: 265px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-group-classroom-quiz .open-calendar-xQuxwr {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 148px;
}
.teacher-group-classroom-quiz .opened-C2l7Eu {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-group-classroom-quiz .opened-FwJWKX {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-group-classroom-quiz .opened-G1xvhx {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-group-classroom-quiz .opened-MZRcus {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-group-classroom-quiz .opened-XCjVcD {
  background-color: transparent;
  height: auto;
  left: 304px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 53px;
}
.teacher-group-classroom-quiz .opened-hUx6Ho {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-group-classroom-quiz .opened-oOO7Ym {
  background-color: transparent;
  height: auto;
  left: 305px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-group-classroom-quiz .organic-4-ePioxc {
  background-color: transparent;
  height: auto;
  left: 97px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 67px;
}
.teacher-group-classroom-quiz .organic-5-Hx49NZ {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 67px;
}
.teacher-group-classroom-quiz .overlap-group-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 453px;
  margin-top: 39px;
  position: relative;
  width: 100%;
}
.teacher-group-classroom-quiz .overlap-group-KKOYJf {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 365px;
}
.teacher-group-classroom-quiz .payga4wk-z-bshl-Sgr7kw {
  background-color: transparent;
  height: auto;
  /* left: 49px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 138px; */
  width: auto;
}
.teacher-group-classroom-quiz .previousclass-id-Sgr7kw {
  background-color: transparent;
  height: auto;
  left: 49px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: auto;
}
.teacher-group-classroom-quiz .recent-previousclasses-Sgr7kw {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 31px;
  width: auto;
}

.rectangle-12-cE321 {
  margin-top: 25px;
  width: 88%;
  height: 620px;
  padding: 15px 12px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.rectangle-31-Sgr7kw-in-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.rectangle-tingle-12 {
  width: 95%;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  height: 45px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.cast-for-edu-121-129 {
  width: auto;
  padding: 4px;
  margin-right: 7px;

  border-radius: 20px;

  background-color: #132547;
  color: #f4f4f4;
}
.div-rectangle-31-Sgr7kw {
  align-self: flex-start;
  margin: 0px 0px 15px 5px;

  font-size: 20px;
  font-weight: 500;
  font-family: "Montserrat", Helvetica;
  color: #dc3545;
}
.rectangle-tingle-12-name {
  align-self: center;
  margin: 0px 5px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", Helvetica;
}

.teacher-group-classroom-quiz .rectangle-31-Sgr7kw-02-22 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 720px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  align-items: center;
}
.teacher-group-classroom-quiz .rectangle-31-ePioxc {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 726px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 383px;
}
.teacher-group-classroom-quiz .rectangle-36-Sgr7kw {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 36px;
  width: 681px;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.teacher-group-classroom-quiz .rectangle-37-Sgr7kw {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 47px;
  left: 32px;
  position: absolute;
  top: 190px;
  width: 681px;
}
.teacher-group-classroom-quiz .rectangle-38-Sgr7kw {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 47px;
  left: 32px;
  position: absolute;
  top: 259px;
  width: 681px;
}
.teacher-group-classroom-quiz .rectangle-39-Sgr7kw {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 47px;
  left: 32px;
  position: absolute;
  top: 328px;
  width: 681px;
}
.teacher-group-classroom-quiz .rectangle-40-UDxQs2 {
  background-color: transparent;
  height: 37px;
  left: 43px;
  position: absolute;
  top: 116px;
  width: 354px;
}
.teacher-group-classroom-quiz .rectangle-40-ePioxc {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 57px;
  width: 365px;
}
.teacher-group-classroom-quiz .rectangle-41-ePioxc {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 9px;
  position: absolute;
  top: 175px;
  width: 365px;
}
.teacher-group-classroom-quiz .rectangle-44-C2l7Eu {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-group-classroom-quiz .rectangle-44-FwJWKX {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-group-classroom-quiz .rectangle-44-G1xvhx {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-group-classroom-quiz .rectangle-44-MZRcus {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-group-classroom-quiz .rectangle-44-hUx6Ho {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-group-classroom-quiz .rectangle-44-oOO7Ym {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-group-classroom-quiz .rectangle-47-PXwdVf {
  background-color: var(--niagara);
  border-radius: 20px;
  height: 19px;
  left: 507px;
  position: absolute;
  top: 135px;
  width: 74px;
}
.teacher-group-classroom-quiz .rectangle-47-ePioxc {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 10px;
  position: absolute;
  top: 116px;
  width: 365px;
}
.teacher-group-classroom-quiz .rectangle-48-Hx49NZ {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-group-classroom-quiz .rectangle-49-XCjVcD {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-group-classroom-quiz .rectangle-50-cfQi7W {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 365px;
}
.teacher-group-classroom-quiz .school-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-group-classroom-quiz .span0-68314r {
  color: rgba(126, 126, 126, 1);
  font-style: normal;
}
.teacher-group-classroom-quiz .span0-e0hoH9 {
  color: rgba(5, 187, 138, 1);
  font-style: normal;
}
.teacher-group-classroom-quiz .span0-rBkabs {
  color: rgba(19, 37, 71, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.teacher-group-classroom-quiz .span1-68314r {
  color: rgba(0, 0, 0, 1);
  font-style: normal;
}
.teacher-group-classroom-quiz .span1-e0hoH9 {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
}
.teacher-group-classroom-quiz .span1-rBkabs {
  color: rgba(19, 37, 71, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
}
.teacher-group-classroom-quiz .span2-68314r {
  color: black;
  font-style: normal;
}
.teacher-group-classroom-quiz .status-PXwdVf {
  background-color: transparent;
  height: auto;
  left: 524px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: auto;
}
.teacher-group-classroom-quiz .status-ePioxc {
  background-color: transparent;
  height: auto;
  left: 208px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 52px;
}
.teacher-group-classroom-quiz .stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-group-classroom-quiz .time-Sgr7kw {
  background-color: transparent;
  height: auto;
  left: 476px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: auto;
}
.teacher-group-classroom-quiz .time-ePioxc {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 45px;
}
.teacher-group-classroom-quiz .total-classes-hxQHcv {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 22px;
  min-width: 133px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-group-classroom-quiz .transferred-to-Sgr7kw {
  background-color: transparent;
  height: auto;
  left: 590px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 89px;
  width: auto;
}
.teacher-group-classroom-quiz .upcoming-Hx49NZ {
  background-color: transparent;
  height: auto;
  left: 191px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 76px;
}
.teacher-group-classroom-quiz .upcoming-class-UDxQs2 {
  background-color: transparent;
  color: rgb(220, 53, 69);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 47px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 271px;
}
.teacher-group-classroom-quiz .vector-3ahjCQ {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-group-classroom-quiz .vector-56AgMz {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.teacher-group-classroom-quiz .vector-8k85HE {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 170px;
  position: relative;
  width: 24px;
}
.teacher-group-classroom-quiz .vector-90KxxC {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-group-classroom-quiz .vector-9xgjqN {
  background-color: transparent;
  height: 14px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.teacher-group-classroom-quiz .vector-B8xRgA {
  background-color: transparent;
  height: 16px;
  left: 0px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-group-classroom-quiz .vector-BKwYFx {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.teacher-group-classroom-quiz .vector-EaMiW0 {
  background-color: transparent;
  height: 20px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 18px;
}
.teacher-group-classroom-quiz .vector-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-group-classroom-quiz .vector-MIOlFW {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.teacher-group-classroom-quiz .vector-R6Ddt1 {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.teacher-group-classroom-quiz .vector-SCg0eG {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  position: relative;
  width: 24px;
}
.teacher-group-classroom-quiz .vector-SIAzDu {
  background-color: transparent;
  height: 20px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 16px;
}
.teacher-group-classroom-quiz .vector-TCNT1O {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-group-classroom-quiz .vector-UgkvXI {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-group-classroom-quiz .vector-WcGkdi {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.teacher-group-classroom-quiz .vector-XV2kdn {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.teacher-group-classroom-quiz .vector-bLtNxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.teacher-group-classroom-quiz .vector-dlRKJA {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-group-classroom-quiz .vector-fPPgx3 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-group-classroom-quiz .vector-grLYV9 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-group-classroom-quiz .vector-iniHBi {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-group-classroom-quiz .vector-jWA1w5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.teacher-group-classroom-quiz .vector-kCLhu1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-group-classroom-quiz .vector-mju0OS {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-group-classroom-quiz .vector-nY7RYD {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.teacher-group-classroom-quiz .vector-oRqxWx {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-group-classroom-quiz .vector-s2Y5x6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.teacher-group-classroom-quiz .vector-xbu9jm {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.teacher-group-classroom-quiz .vector-yQhZ26 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-group-classroom-quiz .x014009-pm-Sgr7kw {
  background-color: transparent;
  height: auto;
  /* left: 460px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: center;
  /* top: 138px; */
  width: auto;
}
.teacher-group-classroom-quiz .x07-dec-2020-Sgr7kw {
  background-color: transparent;
  height: auto;
  /* left: 325px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: center;
  /* top: 138px; */
  width: auto;
}
.teacher-group-classroom-quiz .x0800-am-0900-am-UDxQs2 {
  background-color: transparent;
  color: black;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 485px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 102px;
  width: 200px;
}
.teacher-group-classroom-quiz .x10pm-C2l7Eu {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 45px;
}
.teacher-group-classroom-quiz .x12pm-ePioxc {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 70px;
  width: 47px;
}
.teacher-group-classroom-quiz .x15-january-UDxQs2 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 502px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 75px;
  width: 152px;
}
.teacher-group-classroom-quiz .x1pm-ePioxc {
  background-color: transparent;
  height: auto;
  left: 17px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 133px;
  width: 33px;
}
.teacher-group-classroom-quiz .x2pm-XCjVcD {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 39px;
}
.teacher-group-classroom-quiz .x3pm-Hx49NZ {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 31px;
}
.teacher-group-classroom-quiz .x43-5-cSfBx4 {
  background-color: transparent;
  color: transparent;
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 0px;
  min-height: 22px;
  min-width: 56px;
  position: relative;
  width: auto;
}
.teacher-group-classroom-quiz .x43-hxQHcv {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 4px;
  min-height: 22px;
  min-width: 25px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-group-classroom-quiz .x499900-Sgr7kw {
  background-color: transparent;
  height: auto;
  /* left: 208px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  /* top: 138px; */
  width: auto;
}
.teacher-group-classroom-quiz .x4pm-G1xvhx {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-group-classroom-quiz .x5pm-cfQi7W {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-group-classroom-quiz .x6pm-hUx6Ho {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-group-classroom-quiz .x7-OfAOxu {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-bottom: 0.74px;
  margin-left: 4px;
  min-height: 21px;
  position: relative;
  text-align: left;
  width: 12px;
}
.teacher-group-classroom-quiz .x7pm-MZRcus {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-group-classroom-quiz .x8pm-FwJWKX {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-group-classroom-quiz .x97-302Mxf {
  background-color: transparent;
  color: var(--niagara);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 4px;
  min-height: 22px;
  min-width: 39px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-group-classroom-quiz .x9pm-oOO7Ym {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - teacherpreviousclasses */

.border-1px-blue-zodiac {
  border: 1px solid var(--blue-zodiac);
}
.montserrat-medium-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-semi-bold-blue-zodiac-10px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-20px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-10px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-12px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-crusta-14px {
  color: var(--crusta);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacherpreviousclasses {
  align-items: center;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 5px;
  position: relative;
  width: 414px;
}
.teacherpreviousclasses .add-delete-gsPJIP {
  background-color: transparent;
  height: auto;
  left: 264px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 92px;
}
.teacherpreviousclasses .add-inhwOE {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 30.24px;
  min-height: 16px;
  position: relative;
  text-align: left;
  width: 35px;
}
.teacherpreviousclasses .add-w3YC5z {
  background-color: transparent;
  height: auto;
  left: 283px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacherpreviousclasses .amount-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 80px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 52px;
  width: 55px;
}
.teacherpreviousclasses .attendance-kWlObw {
  background-color: transparent;
  height: auto;
  left: 14px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 94px;
  width: 143px;
}
.teacherpreviousclasses .average-rating-kWlObw {
  background-color: transparent;
  height: auto;
  left: 14px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 53px;
  width: 182px;
}
.teacherpreviousclasses .booked-gsPJIP {
  background-color: transparent;
  height: auto;
  left: 285px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 237px;
  width: 50px;
}
.teacherpreviousclasses .booked-sJJVip {
  background-color: transparent;
  height: auto;
  left: 285px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 56px;
  width: 50px;
}
.teacherpreviousclasses .class-name-gsPJIP {
  background-color: transparent;
  height: auto;
  left: 79px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 94px;
}
.teacherpreviousclasses .completed-gsPJIP {
  background-color: transparent;
  height: auto;
  left: 185px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 56px;
  width: 74px;
}
.teacherpreviousclasses .component-1-dXBp89 {
  background-color: transparent;
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.teacherpreviousclasses .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.teacherpreviousclasses .date-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 171px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 55px;
  width: 30px;
}
.teacherpreviousclasses .feb-18-2021-qacxfW {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 87px;
  min-height: 22px;
  position: relative;
  text-align: left;
  width: 117px;
}
.teacherpreviousclasses .flex-row-0VemYL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 23px;
  justify-content: flex-start;
  margin-left: 5px;
  min-width: 353px;
  position: relative;
  width: auto;
}
.teacherpreviousclasses .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacherpreviousclasses .friday-jQ4qbJ {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 215px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 48px;
  width: auto;
}
.teacherpreviousclasses .group-124-kWlObw {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 172px;
  justify-content: flex-start;
  left: 0px;
  min-width: 359px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacherpreviousclasses .group-125-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 172px;
  margin-left: 13px;
  margin-top: 68px;
  position: relative;
  width: 375px;
}
.teacherpreviousclasses .group-135-C61RwL-02-20 {
  align-items: flex-start;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 25px;
  min-height: 731px;
  position: relative;
  width: 383px;
}
.teacherpreviousclasses .group-15-jQ4qbJ {
  background-color: transparent;
  height: 135px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 363px;
}
.teacherpreviousclasses .group-38-fXlfDP {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 172px;
  position: relative;
  width: 359px;
}
.teacherpreviousclasses .group-39-agxh6K {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 172px;
  justify-content: flex-start;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacherpreviousclasses .group-40-7vmrSC {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 172px;
  justify-content: flex-start;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacherpreviousclasses .group-41-e1mbBS {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 172px;
  justify-content: flex-start;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacherpreviousclasses .group-83-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 135px;
  margin-left: 5px;
  margin-top: 26px;
  position: relative;
  width: 367px;
}
.teacherpreviousclasses .group-90-gsPJIP {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 161px;
  width: 346px;
}
.teacherpreviousclasses .group-91-gsPJIP {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 220px;
  width: 348px;
}
.teacherpreviousclasses .group-92-gsPJIP {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 278px;
  width: 346px;
}
.teacherpreviousclasses .group-93-gsPJIP {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 10px;
  min-height: 262px;
  position: absolute;
  top: 119px;
  width: 348px;
}
.teacherpreviousclasses .group-94-gsPJIP {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 396px;
  width: 346px;
}
.teacherpreviousclasses .group-95-gsPJIP {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 455px;
  width: 346px;
}
.teacherpreviousclasses .group-96-gsPJIP {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 514px;
  width: 346px;
}
.teacherpreviousclasses .group-97-gsPJIP {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 573px;
  width: 346px;
}
.teacherpreviousclasses .group-98-gsPJIP {
  background-color: transparent;
  height: 44px;
  left: 10px;
  position: absolute;
  top: 632px;
  width: 346px;
}
.teacherpreviousclasses .join-class-jQ4qbJ {
  background-color: transparent;
  color: rgba(244, 244, 244, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 67px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 91px;
  width: auto;
}
.teacherpreviousclasses .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.teacherpreviousclasses .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.teacherpreviousclasses .mathematcs-section-g-V5d4cE {
  background-color: transparent;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: 14px;
  position: absolute;
  top: 45px;
  white-space: nowrap;
  width: 124px;
}
.teacherpreviousclasses .number-of-classes-per-day-kWlObw {
  background-color: transparent;
  height: auto;
  left: 14px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 134px;
  width: 308px;
}
.teacherpreviousclasses .open-calendar-qacxfW {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 149px;
}
.teacherpreviousclasses .opened-5yGlKR {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacherpreviousclasses .opened-CpJITP {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacherpreviousclasses .opened-ZFOVNr {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacherpreviousclasses .opened-fQDjib {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacherpreviousclasses .opened-mD1e0n {
  background-color: transparent;
  height: auto;
  left: 276px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 55px;
}
.teacherpreviousclasses .opened-vKzdxy {
  background-color: transparent;
  height: auto;
  left: 277px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacherpreviousclasses .opened-xhnNkw {
  background-color: transparent;
  height: auto;
  left: 275px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 51px;
}
.teacherpreviousclasses .organic-4-gsPJIP {
  background-color: transparent;
  height: auto;
  left: 89px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 56px;
  width: 69px;
}
.teacherpreviousclasses .organic-5-zJuooh {
  background-color: transparent;
  height: auto;
  left: 81px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.teacherpreviousclasses .overlap-group-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 399px;
  margin-right: 3px;
  margin-top: 35px;
  position: relative;
  width: 359px;
}
.teacherpreviousclasses .overlap-group1-0VemYL {
  background-color: transparent;
  flex-shrink: 1;
  height: 687px;
  margin-top: 21px;
  position: relative;
  width: 359px;
}
.teacherpreviousclasses .overlap-group2-inhwOE {
  background-color: transparent;
  flex-shrink: 1;
  height: 44px;
  margin-top: 202px;
  position: relative;
  width: 342px;
}
.teacherpreviousclasses .previousclass-id-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 55px;
  width: 69px;
}
.teacherpreviousclasses .recent-previousclasses-4eduM0 {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 16px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 173px;
}
.teacherpreviousclasses .rectangle-30-V5d4cE {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 135px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacherpreviousclasses .rectangle-31-4eduM0 {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 399px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 12px;
}
.teacherpreviousclasses .rectangle-31-gsPJIP {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 687px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 359px;
}
.teacherpreviousclasses .rectangle-36-4eduM0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 32px;
  width: 323px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.teacherpreviousclasses .rectangle-37-4eduM0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 31px;
  left: 16px;
  position: absolute;
  top: 124px;
  width: 323px;
}
.teacherpreviousclasses .rectangle-38-4eduM0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 32px;
  left: 16px;
  position: absolute;
  top: 170px;
  width: 323px;
}
.teacherpreviousclasses .rectangle-39-4eduM0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 32px;
  left: 16px;
  position: absolute;
  top: 216px;
  width: 323px;
}
.teacherpreviousclasses .rectangle-40-4eduM0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 32px;
  left: 20px;
  position: absolute;
  top: 262px;
  width: 323px;
}
.teacherpreviousclasses .rectangle-40-gsPJIP {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 9px;
  position: absolute;
  top: 43px;
  width: 342px;
}
.teacherpreviousclasses .rectangle-41-4eduM0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 31px;
  left: 20px;
  position: absolute;
  top: 309px;
  width: 323px;
}
.teacherpreviousclasses .rectangle-41-gsPJIP {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 8px;
  position: absolute;
  top: 161px;
  width: 342px;
}
.teacherpreviousclasses .rectangle-42-4eduM0 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 32px;
  left: 20px;
  position: absolute;
  top: 355px;
  width: 323px;
}
.teacherpreviousclasses .rectangle-44-5yGlKR {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacherpreviousclasses .rectangle-44-CpJITP {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacherpreviousclasses .rectangle-44-ZFOVNr {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacherpreviousclasses .rectangle-44-fQDjib {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacherpreviousclasses .rectangle-44-vKzdxy {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacherpreviousclasses .rectangle-44-xhnNkw {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacherpreviousclasses .rectangle-47-gsPJIP {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 9px;
  position: absolute;
  top: 102px;
  width: 342px;
}
.teacherpreviousclasses .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.teacherpreviousclasses .rectangle-48-zJuooh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacherpreviousclasses .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacherpreviousclasses .rectangle-49-mD1e0n {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacherpreviousclasses .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacherpreviousclasses .rectangle-50-w3YC5z {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 342px;
}
.teacherpreviousclasses .rectangle-51-jQ4qbJ {
  background-color: var(--niagara);
  border-radius: 10px;
  height: 28px;
  left: 15px;
  position: absolute;
  top: 84px;
  width: 154px;
}
.teacherpreviousclasses .span0-KlKwew {
  color: rgba(5, 187, 138, 1);
  font-style: normal;
}
.teacherpreviousclasses .span0-Xx53BE {
  color: rgba(126, 126, 126, 1);
  font-style: normal;
}
.teacherpreviousclasses .span1-KlKwew {
  color: rgba(19, 37, 71, 1);
  font-style: normal;
}
.teacherpreviousclasses .span1-Xx53BE {
  color: black;
  font-style: normal;
}
.teacherpreviousclasses .status-gsPJIP {
  background-color: transparent;
  height: auto;
  left: 192px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 49px;
}
.teacherpreviousclasses .time-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 221px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 55px;
  width: 36px;
}
.teacherpreviousclasses .time-gsPJIP {
  background-color: transparent;
  height: auto;
  left: 16px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 42px;
}
.teacherpreviousclasses .total-classes-kWlObw {
  background-color: transparent;
  height: auto;
  left: 14px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 154px;
}
.teacherpreviousclasses .transferred-to-4eduM0 {
  background-color: transparent;
  height: auto;
  left: 262px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 52px;
  width: 79px;
}
.teacherpreviousclasses .upcoming-class-V5d4cE {
  background-color: transparent;
  color: rgb(220, 53, 69);
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 152px;
}
.teacherpreviousclasses .upcoming-zJuooh {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 71px;
}
.teacherpreviousclasses .x0800-am-0900-am-jQ4qbJ {
  background-color: transparent;
  color: black;
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  left: 215px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 84px;
  width: auto;
}
.teacherpreviousclasses .x10pm-ZFOVNr {
  background-color: transparent;
  height: auto;
  left: 7px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 42px;
}
.teacherpreviousclasses .x12pm-gsPJIP {
  background-color: transparent;
  height: auto;
  left: 18px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 56px;
  width: 44px;
}
.teacherpreviousclasses .x15-january-jQ4qbJ {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 215px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 68px;
  width: auto;
}
.teacherpreviousclasses .x1pm-gsPJIP {
  background-color: transparent;
  height: auto;
  left: 18px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 119px;
  width: 31px;
}
.teacherpreviousclasses .x2pm-mD1e0n {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 37px;
}
.teacherpreviousclasses .x3pm-zJuooh {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 35px;
}
.teacherpreviousclasses .x43-5-kWlObw {
  background-color: transparent;
  color: transparent;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 200px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  top: 53px;
  width: 65px;
}
.teacherpreviousclasses .x43-kWlObw {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 181px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 28px;
}
.teacherpreviousclasses .x4pm-vKzdxy {
  background-color: transparent;
  height: auto;
  left: 10px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacherpreviousclasses .x5pm-w3YC5z {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacherpreviousclasses .x6pm-fQDjib {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacherpreviousclasses .x7-kWlObw {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 324px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 134px;
  width: 15px;
}
.teacherpreviousclasses .x7pm-xhnNkw {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacherpreviousclasses .x8pm-5yGlKR {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
.teacherpreviousclasses .x97-kWlObw {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 175px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 94px;
  width: 46px;
}
.teacherpreviousclasses .x9pm-CpJITP {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 35px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .teacher-group-classroom-quiz {
    display: none;
  }

  .rectangle-41-nxpXxz-pp1-010-1 {
    align-self: center;
    justify-self: flex-end;
    color: rgba(249, 129, 49, 1);
    font-family: "Montserrat", Helvetica;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
  }

  .rectangle-tingle-12-name-in-mmos {
    align-self: center;
    margin: 0px 5px;
    font-size: 10px;
    font-weight: 500;
    font-family: "Montserrat", Helvetica;
  }
}
@media screen and (min-width: 600px) {
  .teacherpreviousclasses {
    display: none;
  }
}

.rectangle-41-nxpXxz-pp1-010 {
  align-self: center;
  color: rgba(249, 129, 49, 1);
  font-family: "Montserrat", Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}
