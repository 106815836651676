.main {
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(253, 53, 69, 0.1);
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;
}

.container {
  background-color: #eee;
  height: 80vh;
  width: 50vh;
  margin-top: 5%;
  border: 5px solid #dc3545;
  border-radius: 10px;
}
.closebtn {
  display: flex;
  margin: 10px 0 0 auto;
  color: #dc3545;
  border: none;
  font-weight: 700;
}

.heading {
  color: #dc3545;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
}
