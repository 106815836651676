@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);

body {
    font-family: "Montserrat", sans-serif;
}

.content-class-study {
  font-family: 'Montserrat', sans-serif;
  font-size: medium;
  line-height: 1.5;
  padding: 20px auto;
  text-align: justify;
  border: 4px solid red;
  border-radius: 20px;
  margin: 20px;
  font-weight: 400;
}
.content-class-future {
  font-family: 'Montserrat', sans-serif;
  font-size: large;
  line-height: 1.5;
  padding: 20px auto;
  text-align: center;
  margin: 20px;
  font-weight: 600;
}

.heading_box {
    margin: 50px auto;
    width: 95%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    background-color: #ec3545;
    border-radius: 8px;
}

.heading_box p {
    font-size: 30px;
    font-weight: 500;
    color: white;
}

.boxes_container {
    margin: 80px auto;
    width: 94%;
}

.color_box {
    height: 300px;
    border-radius: 10px;
    transition: all ease-in 0.4s;
    position: relative;
}

.color_box:hover {
    transform: scale(1.1, 1.1);
    cursor: pointer;
    z-index: 100;
}

.color_box p {
    color: white;
    font-size: 32px;
    font-weight: 550;
    margin: 50px 0 0 30px;
}

.fa-chevron-right {
    font-size: 25px;
    color: white;
    position: absolute;
    bottom: 20px;
    right: 30px;
}

@media screen and (max-width: 768px) {
    .color_box {
        margin: 0 0 20px 0 !important;
    }

    .boxes_container {
        width: 85%;
    }
}