@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,500,600|Roboto:700,500");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.hc-student-details-1 input:focus, textarea:focus, select:focus{
  outline: none;
}

.sidenav {
  height: 100%;
  width: 50px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #1f2531;
  overflow-x: hidden;
  padding-top: 50px;
}

.sidelink {
  display: block;
  color: white;
  text-decoration: none;
  padding: 12px;
  height: 80px;
}

.head {
  display: block;
  margin-left: 80px;
  padding: 30px;
}
.insidesidenav{
  width: 30px;
}
.label {
  font-weight: 600;
}


.biba-sobti-h8V8ZR {
  background-color: transparent;
  color: #dc3545;
  flex-shrink: 1;
  align-items: flex-end;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.bibasobtigmailcom-h8V8ZR {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.card_heading {
  border-radius: 8px;
  background-color: #dc3545;
  color: white;
  margin-top: 25px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.heading_line {
  font-weight: 600;
  margin: 35px 2px 10px 20px;
}

.rating {
  margin-left: 40px;
}

.fa {
  margin-right: 20px;
}

.comment {
  margin-left: 20px;
  border-radius: 8px;
  border: 1px solid rgba(61, 59, 59, 0.1);
  padding: 20px;
  width: 70%;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
}

.comment_2 {
  width: 90%;
  margin: 0 20px;
  border-radius: 8px;
  border: 1px solid rgba(61, 59, 59, 0.2);
  padding: 3px 15px;
}


.group-209-C61RwL {
  align-items: flex-end;
  background-color: #1f2531;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 1024px;
  padding: 50px 18px;
  position: relative;
  width: 63px;
}
.historytoggleoff-24px-1-D0BVcB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 50px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.mob_nav {
  display: none;
}

.bottom_comment {
  display: flex;
  align-items: center;
  margin: 60px 0 0 20px;
  width: 100%;
}

.bottom_comment textarea {
  padding: 20px;
  width: 70%;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
  border-style: none;
  border-radius: 8px;
  margin-right: 90px;
}


@media only screen and (max-width: 720px) {

.mobile_nav {
  display: none;
}

.mob_nav {
  display: block;
  float: right;
  font-size: 30px;
  margin: 15px 20px 0 0; 
}

.sidenav {
  display: none;
}

.head {
  margin-left: 0;
}

.card-ex {
  margin-left: 50px !important;
}

.comment {
  width: 90%;
}

.bottom_comment {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0 0 0px;
}

.bottom_comment textarea {
  width: 90%;
  margin: 0 0 20px 0;
}

}

