@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,500,600|Roboto:700,500");
/* screen - teacher-calendar-time-mobile */

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-56px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-crusta-14px {
  color: var(--crusta);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-20px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-24px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-gray-20px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-calendar-time-mobile {
  align-items: center;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 1368px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  padding: 24px 0;
  position: relative;
  width: 414px;
}
.teacher-calendar-time-mobile .add-delete-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 294px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 90px;
}
.teacher-calendar-time-mobile .arrowback-24px-1-AYFtQ1 {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-calendar-time-mobile .booked-bC1JcG {
  background-color: transparent;
  height: auto;
  left: 308px;
  letter-spacing: 0px;
  line-height: 30px;
  position: absolute;
  text-align: left;
  top: 8px;
  white-space: nowrap;
  width: 62px;
}
.teacher-calendar-time-mobile .class-name-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 88px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 96px;
}
.teacher-calendar-time-mobile .completed-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 204px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 57px;
  width: 81px;
}
.teacher-calendar-time-mobile .component-1-dXBp89 {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 45px;
  position: relative;
  width: 87px;
}
.teacher-calendar-time-mobile .component-4-NwNIwt {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 157px;
  position: relative;
  width: 158px;
}
.teacher-calendar-time-mobile .component-5-NwNIwt {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 1;
  height: 157px;
  margin-left: 46px;
  position: relative;
  width: 158px;
}
.teacher-calendar-time-mobile .component-6-dXBp89 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-bottom: 1px;
  margin-left: 249px;
  min-height: 18px;
  position: relative;
  width: 23px;
}
.teacher-calendar-time-mobile .component-7-BdpSxK {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 157px;
  padding: 20.2px 30.1px;
  position: relative;
  width: 158px;
}
.teacher-calendar-time-mobile .component-8-BdpSxK {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 46px;
  min-height: 157px;
  padding: 20.2px 17.1px;
  position: relative;
  width: 158px;
}
.teacher-calendar-time-mobile .doubt-forum-rFX9Nh {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 15px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 25px;
  width: 131px;
}
.teacher-calendar-time-mobile .feb-18-2021-AYFtQ1 {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 148px;
  min-height: 22px;
  min-width: 110px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-calendar-time-mobile .flex-row-C61RwL {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 45px;
  justify-content: flex-start;
  margin-right: 3px;
  min-width: 359px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .flex-row-QxM5SU {
  align-items: flex-start;
  align-self: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: 17px;
  min-width: 372px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .flex-row-VMr6Om {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 157px;
  justify-content: flex-start;
  margin-top: 51px;
  min-width: 362px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .flex-row-mzXdH9 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 157px;
  justify-content: flex-start;
  margin-top: 33px;
  min-width: 362px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .go-back-AYFtQ1 {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 1px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 89px;
}
.teacher-calendar-time-mobile .group-112-C61RwL {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 402px;
  margin-right: -8px;
  margin-top: 26px;
  position: relative;
  width: 411px;
}
.teacher-calendar-time-mobile .group-1pzCME {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-3HWdqU {
  background-color: transparent;
  height: 25px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-calendar-time-mobile .group-3eHi1a {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 46px;
}
.teacher-calendar-time-mobile .group-3xCoUY {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-time-mobile .group-4Tztbv {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .group-5ZFw7U {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-6dsxAM {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 12px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-calendar-time-mobile .group-6u2TAk {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-time-mobile .group-85-C61RwL {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: 270px;
  margin-right: 3px;
  margin-top: 24px;
  position: relative;
  width: 385px;
}
.teacher-calendar-time-mobile .group-90-Ajm6uA {
  background-color: transparent;
  height: 44px;
  left: 8px;
  position: absolute;
  top: 161px;
  width: 380px;
}
.teacher-calendar-time-mobile .group-91-Ajm6uA {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 220px;
  width: 384px;
}
.teacher-calendar-time-mobile .group-92-Ajm6uA {
  background-color: transparent;
  height: 44px;
  left: 7px;
  position: absolute;
  top: 279px;
  width: 380px;
}
.teacher-calendar-time-mobile .group-93-Ajm6uA {
  background-color: transparent;
  height: 44px;
  left: 9px;
  position: absolute;
  top: 338px;
  width: 380px;
}
.teacher-calendar-time-mobile .group-9neAS5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-calendar-time-mobile .group-B3X4ab {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-BpxbEL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .group-DhmCtC {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 29px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-time-mobile .group-FASyuA {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .group-HTx4Jc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .group-IfQt5H {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-calendar-time-mobile .group-N2Y59c {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-O5Cxw9 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 29px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-time-mobile .group-Ob1Rqs {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-Qv0mZ4 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-S9AR9M {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 26px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-T7EbAp {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-time-mobile .group-TdE1pD {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-calendar-time-mobile .group-TtUkiV {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 46px;
}
.teacher-calendar-time-mobile .group-VOGBVy {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-calendar-time-mobile .group-VRMQru {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-WfnLBo {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-bX4Dvh {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .group-bcFrzZ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .group-bof6oJ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 38px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-time-mobile .group-c0ymqA {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-calendar-time-mobile .group-c9XjZ6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 29px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-time-mobile .group-dS4UXQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-calendar-time-mobile .group-dV81ro {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-dcaDkc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-calendar-time-mobile .group-gn7VaX {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .group-gvV2Rx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-hhAbtl {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-calendar-time-mobile .group-iieIco {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .group-jLf4aV {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 12px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-calendar-time-mobile .group-k7b1Mi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 26px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-lw8NAW {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 38px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-time-mobile .group-mokeKx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-calendar-time-mobile .group-ms1pvS {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-time-mobile .group-nD2hpN {
  background-color: transparent;
  height: 25px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-calendar-time-mobile .group-nzNou6 {
  background-color: transparent;
  height: 25px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-calendar-time-mobile .group-qGye3v {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 26px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-qpftC5 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-calendar-time-mobile .group-sku7Fc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-time-mobile .group-tQwNUN {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-calendar-time-mobile .group-toBf7r {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-time-mobile .group-wQSXdG {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 46px;
}
.teacher-calendar-time-mobile .group-x2R7zB {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-xB1UPi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .group-xS7o0X {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-calendar-time-mobile .group-xhwx9b {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 12px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-calendar-time-mobile .group-xtOtSY {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-calendar-time-mobile .group-zxfIi7 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 38px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-calendar-time-mobile .how-to-solve-this-rFX9Nh {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 214px;
  width: 289px;
}
.teacher-calendar-time-mobile .join-7-rFX9Nh {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 35px;
  justify-content: flex-start;
  left: 11px;
  overflow: hidden;
  padding: 0.8px 0;
  position: absolute;
  top: 68px;
  width: 46px;
}
.teacher-calendar-time-mobile .join-8-rFX9Nh {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 35px;
  justify-content: flex-start;
  left: 9px;
  overflow: hidden;
  padding: 0.8px 0;
  position: absolute;
  top: 139px;
  width: 46px;
}
.teacher-calendar-time-mobile .join-9-rFX9Nh {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 35px;
  justify-content: flex-start;
  left: 9px;
  overflow: hidden;
  padding: 0.8px 0;
  position: absolute;
  top: 210px;
  width: 46px;
}
.teacher-calendar-time-mobile .line-5-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 87px;
}
.teacher-calendar-time-mobile .line-6-Q41bBg {
  background-color: transparent;
  height: 46px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 88px;
}
.teacher-calendar-time-mobile .open-mdOnc7 {
  background-color: transparent;
  height: auto;
  left: 311px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 37px;
}
.teacher-calendar-time-mobile .open-your-slots-C61RwL {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 12px;
  margin-top: 64px;
  min-height: 26px;
  position: relative;
  text-align: left;
  width: 338px;
}
.teacher-calendar-time-mobile .opened-JoE0OI {
  background-color: transparent;
  height: auto;
  left: 308px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 54px;
}
.teacher-calendar-time-mobile .opened-OUIM6H {
  background-color: transparent;
  height: auto;
  left: 307px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 54px;
}
.teacher-calendar-time-mobile .organic-4-Ajm6uA {
  background-color: transparent;
  height: auto;
  /* left: 98px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 57px; */
  width: 69px;
}
.teacher-calendar-time-mobile .organic-5-bC1JcG {
  background-color: transparent;
  height: auto;
  left: 91px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 69px;
}
.teacher-calendar-time-mobile .rectangle-31-Ajm6uA {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 402px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 395px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 20px;
}
.teacher-calendar-time-mobile .rectangle-40-Ajm6uA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  width: 376px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.teacher-calendar-time-mobile .rectangle-41-Ajm6uA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 7px;
  position: absolute;
  top: 162px;
  width: 376px;
}
.teacher-calendar-time-mobile .rectangle-44-JoE0OI {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.teacher-calendar-time-mobile .rectangle-44-rFX9Nh {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 270px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 358px;
}
.teacher-calendar-time-mobile .rectangle-47-Ajm6uA {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 8px;
  position: absolute;
  top: 103px;
  width: 376px;
}
.teacher-calendar-time-mobile .rectangle-48-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  position: relative;
  width: 23px;
}
.teacher-calendar-time-mobile .rectangle-48-bC1JcG {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.teacher-calendar-time-mobile .rectangle-49-OUIM6H {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.teacher-calendar-time-mobile .rectangle-49-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-calendar-time-mobile .rectangle-50-X4qzjs {
  background-color: var(--gray);
  flex-shrink: 1;
  height: 4px;
  margin-top: 3px;
  position: relative;
  width: 23px;
}
.teacher-calendar-time-mobile .rectangle-50-mdOnc7 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 44px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 376px;
}
.teacher-calendar-time-mobile .sections-teachi-g-i650401253102-6cs336 {
  background-color: transparent;
  height: auto;
  left: 41px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .sections-teachi-g-i650401353102-biGoLv {
  background-color: transparent;
  height: auto;
  left: 19px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
  width: auto;
}
.teacher-calendar-time-mobile .sections-teachi-g-i650401453102-jQXOzQ {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 24px;
  min-width: 96px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-calendar-time-mobile .sections-teachi-g-i650401553102-mbQvQG {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 24px;
  min-width: 121px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-calendar-time-mobile .status-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 250px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 54px;
}
.teacher-calendar-time-mobile .time-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 80px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 17px;
  width: 46px;
}
.teacher-calendar-time-mobile .upcoming-bC1JcG {
  background-color: transparent;
  height: auto;
  left: 197px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
  width: 78px;
}
.teacher-calendar-time-mobile .vector-0IPvau {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-calendar-time-mobile .vector-1Nxj6x {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-1TafeU {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-1oS1NN {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-1pzCME {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-calendar-time-mobile .vector-1xHKrh {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-2rF7C5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-38tHsM {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-3HWdqU {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-calendar-time-mobile .vector-3X2JZC {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-3xCoUY {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-5SSNkN {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 11px;
}
.teacher-calendar-time-mobile .vector-5YyGRW {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-calendar-time-mobile .vector-5ZFw7U {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-calendar-time-mobile .vector-5ZxPCv {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-calendar-time-mobile .vector-6Pj9gj {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-6dsxAM {
  background-color: transparent;
  height: 3px;
  left: 31px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-6lEMsv {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-calendar-time-mobile .vector-6u2TAk {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 26px;
}
.teacher-calendar-time-mobile .vector-9neAS5 {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 6px;
}
.teacher-calendar-time-mobile .vector-ADvjvZ {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-calendar-time-mobile .vector-AEuo4j {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-calendar-time-mobile .vector-AKNQwM {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-calendar-time-mobile .vector-AUE2eU {
  background-color: transparent;
  height: 25px;
  left: -0px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-calendar-time-mobile .vector-B3X4ab {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-calendar-time-mobile .vector-CKdTAP {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-calendar-time-mobile .vector-CaPVo9 {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 12px;
}
.teacher-calendar-time-mobile .vector-DhmCtC {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 12px;
}
.teacher-calendar-time-mobile .vector-FBXFcM {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-calendar-time-mobile .vector-FRe9kA {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-FbIeqH {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-GULZOy {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-HDxFmN {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-calendar-time-mobile .vector-IfQt5H {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-calendar-time-mobile .vector-IpBwGI {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-calendar-time-mobile .vector-LS3dNl {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-LpgxzN {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-calendar-time-mobile .vector-MNlTtX {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-N2Y59c {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-calendar-time-mobile .vector-NF9mxS {
  background-color: transparent;
  height: 2px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 1px;
}
.teacher-calendar-time-mobile .vector-O5Cxw9 {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 12px;
}
.teacher-calendar-time-mobile .vector-OWyIvf {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-calendar-time-mobile .vector-Ob1Rqs {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-calendar-time-mobile .vector-P1lxK3 {
  background-color: transparent;
  height: 2px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 1px;
}
.teacher-calendar-time-mobile .vector-PC91KU {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-PD0S0h {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-PFKICe {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-PtriGM {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-calendar-time-mobile .vector-QDyA6g {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-Qv0mZ4 {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-calendar-time-mobile .vector-Qv8Xbp {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-calendar-time-mobile .vector-RYpCgT {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-S9AR9M {
  background-color: transparent;
  height: 24px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 45px;
}
.teacher-calendar-time-mobile .vector-Srtp8D {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-T0btkN {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-T7EbAp {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-TdE1pD {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-calendar-time-mobile .vector-U0LxYw {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-UUUxJF {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-VOGBVy {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-VRMQru {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-calendar-time-mobile .vector-Vr5tiq {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 11px;
}
.teacher-calendar-time-mobile .vector-WCljiC {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-calendar-time-mobile .vector-WfnLBo {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-calendar-time-mobile .vector-WnZAcK {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 8px;
}
.teacher-calendar-time-mobile .vector-WqgE0F {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-calendar-time-mobile .vector-Y59V0s {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-Y9TIUK {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-YM4yxX {
  background-color: transparent;
  height: 2px;
  left: 17px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-Zh51an {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-aXV5cr {
  background-color: transparent;
  height: 2px;
  left: 11px;
  position: absolute;
  top: 9px;
  width: 1px;
}
.teacher-calendar-time-mobile .vector-bKRlnb {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-bnkfUA {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-bof6oJ {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-c0ymqA {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-c9XjZ6 {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 12px;
}
.teacher-calendar-time-mobile .vector-cJDtwl {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-cKyO6s {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-cNJjv2 {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-calendar-time-mobile .vector-d2duO5 {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-dG0Ldx {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-dS2uCE {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-calendar-time-mobile .vector-dS4UXQ {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-calendar-time-mobile .vector-dV81ro {
  background-color: transparent;
  height: 24px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 12px;
}
.teacher-calendar-time-mobile .vector-dcaDkc {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-calendar-time-mobile .vector-e2yeN1 {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-calendar-time-mobile .vector-eF7kig {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 12px;
}
.teacher-calendar-time-mobile .vector-gvV2Rx {
  background-color: transparent;
  height: 24px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 12px;
}
.teacher-calendar-time-mobile .vector-halcXV {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-calendar-time-mobile .vector-hhAbtl {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 6px;
}
.teacher-calendar-time-mobile .vector-i64Q95 {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-calendar-time-mobile .vector-j0bfoT {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-calendar-time-mobile .vector-jLf4aV {
  background-color: transparent;
  height: 3px;
  left: 31px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-jaA0fc {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-k7b1Mi {
  background-color: transparent;
  height: 24px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 45px;
}
.teacher-calendar-time-mobile .vector-kwqROy {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-calendar-time-mobile .vector-lFxDi9 {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-calendar-time-mobile .vector-lj5Xre {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-lkVzGd {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-lw8NAW {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-mGXgmO {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-mokeKx {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-calendar-time-mobile .vector-ms1pvS {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 26px;
}
.teacher-calendar-time-mobile .vector-nD2hpN {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-calendar-time-mobile .vector-ngma9X {
  background-color: transparent;
  height: 25px;
  left: -0px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-calendar-time-mobile .vector-nzNou6 {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-calendar-time-mobile .vector-nzuIpS {
  background-color: transparent;
  height: 2px;
  left: 17px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-oafYiW {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-oez9Pb {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-calendar-time-mobile .vector-pummtu {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-pxIogv {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-qGye3v {
  background-color: transparent;
  height: 24px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 45px;
}
.teacher-calendar-time-mobile .vector-qpftC5 {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 6px;
}
.teacher-calendar-time-mobile .vector-r0opiw {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 12px;
}
.teacher-calendar-time-mobile .vector-rGRikn {
  background-color: transparent;
  height: 25px;
  left: -0px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-calendar-time-mobile .vector-rXxPBk {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-calendar-time-mobile .vector-reGMLO {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-sku7Fc {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-tFceyr {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-calendar-time-mobile .vector-tQwNUN {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-calendar-time-mobile .vector-toBf7r {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 26px;
}
.teacher-calendar-time-mobile .vector-tprfVp {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-utKR0x {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-vPuJH9 {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-calendar-time-mobile .vector-x2R7zB {
  background-color: transparent;
  height: 24px;
  left: -1px;
  position: absolute;
  top: 3px;
  width: 12px;
}
.teacher-calendar-time-mobile .vector-x5Jnp7 {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-calendar-time-mobile .vector-xB1UPi {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 22px;
}
.teacher-calendar-time-mobile .vector-xJcTLo {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-calendar-time-mobile .vector-xL5OUN {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 8px;
}
.teacher-calendar-time-mobile .vector-xMrRfb {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-calendar-time-mobile .vector-xOJZuX {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-xQdiT6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-xhepvl {
  background-color: transparent;
  height: 2px;
  left: 17px;
  position: absolute;
  top: 9px;
  width: 1px;
}
.teacher-calendar-time-mobile .vector-xhwx9b {
  background-color: transparent;
  height: 3px;
  left: 31px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-xtOtSY {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-calendar-time-mobile .vector-xvjzYd {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-calendar-time-mobile .vector-ymbxcA {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 11px;
}
.teacher-calendar-time-mobile .vector-zXuwwt {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-calendar-time-mobile .vector-zeZVsH {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-calendar-time-mobile .vector-zxfIi7 {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-calendar-time-mobile .view-all-rFX9Nh {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 296px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 28px;
  width: 75px;
}
.teacher-calendar-time-mobile .view-forum-CN8bZ4 {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 251px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 235px;
  width: 113px;
}
.teacher-calendar-time-mobile .view-forum-CNpLuU {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 251px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 169px;
  width: 113px;
}
.teacher-calendar-time-mobile .view-forum-rFX9Nh {
  background-color: transparent;
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 251px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 103px;
  width: 113px;
}
.teacher-calendar-time-mobile .what-is-the-si-unit-of-current-rFX9Nh {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 74px;
  width: 289px;
}
.teacher-calendar-time-mobile .what-is-the-sol-f-this-question-rFX9Nh {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: auto;
  left: 69px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 140px;
  width: 289px;
}
.teacher-calendar-time-mobile .x12pm-Ajm6uA {
  background-color: transparent;
  height: auto;
  /* left: 16px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 57px; */
  width: 48px;
}
.teacher-calendar-time-mobile .x1pm-Ajm6uA {
  background-color: transparent;
  height: auto;
  left: 16px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 120px;
  width: 34px;
}
.teacher-calendar-time-mobile .x2pm-OUIM6H {
  background-color: transparent;
  height: auto;
  left: 8px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 16px;
  width: 40px;
}
.teacher-calendar-time-mobile .x3pm-bC1JcG {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14px;
  width: 32px;
}
.teacher-calendar-time-mobile .x4-i650401253103-6cs336 {
  background-color: transparent;
  height: auto;
  left: 46px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 64px;
  width: auto;
}
.teacher-calendar-time-mobile .x4-i650401353103-biGoLv {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 52px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 64px;
  width: auto;
}
.teacher-calendar-time-mobile .x4-i650401453103-jQXOzQ {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 1.43px;
  margin-top: 19px;
  min-height: 68px;
  min-width: 68px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-calendar-time-mobile .x4-i650401553103-mbQvQG {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 1.43px;
  margin-top: 19px;
  min-height: 68px;
  min-width: 56px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-calendar-time-mobile .x4pm-JoE0OI {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 38px;
}
.teacher-calendar-time-mobile .x5pm-mdOnc7 {
  background-color: transparent;
  height: auto;
  left: 9px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 12px;
  width: 38px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
/* screen - teacher-time-calendar */

.montserrat-semi-bold-blue-zodiac-18px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-blue-zodiac-56px {
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-crusta-14px {
  color: var(--crusta);
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-16px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-20px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-gray-24px {
  color: var(--gray);
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.roboto-bold-gray-16px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.roboto-medium-blue-zodiac-14px {
  color: var(--blue-zodiac);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-gray-20px {
  color: var(--gray);
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.roboto-medium-niagara-14px {
  color: var(--niagara);
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.teacher-time-calendar {
  align-items: flex-start;
  background-color: rgba(253, 254, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1024px;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.teacher-time-calendar .accountbalance-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-time-calendar .add-delete-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 564px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: 110px;
}

.teacher-time-calendar .picker-dimensions {
  width: 180px;
  height: 48px;
}

.teacher-calendar-time-mobile .picker-dimensions {
  width: 180px;
  height: 48px;
}

.teacher-time-calendar .analytics-24px-3-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-time-calendar .arrowback-24px-1-A6SpMB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
  margin-top: 3px;
}
.teacher-time-calendar .assignment-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-time-calendar .biba-sobti-h8V8ZR {
  background-color: transparent;
  color: rgb(220, 53, 69);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 131px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-time-calendar .bibasobtigmailcom-h8V8ZR {
  background-color: transparent;
  color: var(--black);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 4px;
  min-height: 24px;
  min-width: 223px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-time-calendar .booked-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 607px;
  letter-spacing: 0px;
  line-height: 30px;
  position: absolute;
  text-align: left;
  top: 269px;
  white-space: nowrap;
  width: 60px;
}
.teacher-time-calendar .booked-uipJNK {
  background-color: transparent;
  height: auto;
  left: 607px;
  letter-spacing: 0px;
  line-height: 30px;
  position: absolute;
  text-align: left;
  top: 86px;
  white-space: nowrap;
  width: 60px;
}
.teacher-time-calendar .class-name-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 179px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 26px;
  width: 124px;
}
.teacher-time-calendar .completed-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 386px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 94px;
  width: 73px;
}
.teacher-time-calendar .component-1-GyJUNH {
  background-color: transparent;
  /* border: 1px solid var(--blue-zodiac); */
  flex-shrink: 1;
  height: 105px;
  position: relative;
  width: 212px;
}
.teacher-time-calendar .component-4-gty3z9 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 194px;
  padding: 25px 21.1px;
  position: relative;
  width: 158px;
}
.teacher-time-calendar .component-5-gty3z9 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 194px;
  padding: 25px 21.1px;
  position: relative;
  width: 158px;
  margin-left: 24px;
}
.teacher-time-calendar .component-6-gty3z9 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 194px;
  padding: 25px 21.1px;
  position: relative;
  width: 158px;
  margin-left: 24px;
}
.teacher-time-calendar .component-7-gty3z9 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 24px;
  min-height: 194px;
  padding: 25px 5.1px;
  position: relative;
  width: 158px;
}
.teacher-time-calendar .description-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-time-calendar .doubt-forum-PxY2KT {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 38px;
  position: relative;
  text-align: left;
  width: 165px;
}
.teacher-time-calendar .ellipse-2-RYyMFx {
  background-color: transparent;
  height: 8px;
  left: 24px;
  position: absolute;
  top: 9px;
  width: 8px;
}
.teacher-time-calendar .feb-18-2021-A6SpMB {
  align-self: flex-end;
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  position: absolute;
  right: 10px;
  text-align: left;
}
.teacher-time-calendar .flex-col-C61RwL-10-1 {
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 83px;
  margin-top: 45px;
  min-height: 942px;
  position: relative;
  width: calc(100% - 93px);
}
.teacher-time-calendar .flex-col-QkTteQ11 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 795px;
  position: absolute;
  width: 52%;
}
.teacher-time-calendar .flex-col-XumvGH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-left: 10px;
  min-height: 236px;
  position: relative;
  width: 306px;
}
.teacher-time-calendar .flex-col-cArJhj {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 206px;
  position: relative;
  width: 45px;
}
.teacher-time-calendar .flex-col-vgli2x {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  margin-top: 5px;
  min-height: 57px;
  position: relative;
  width: 223px;
}
.teacher-time-calendar .flex-col-xr0pJV {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 107px;
  position: relative;
  width: 373px;
}
.teacher-time-calendar .flex-row-0xy0vn {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 105px;
  justify-content: space-between;
  position: relative;
  width: 90%;
  margin: 0 auto;
}
.teacher-time-calendar .flex-row-Fjgz1C {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  justify-content: flex-start;
  margin-top: 14px;
  position: relative;
  width: 100%;
}
.teacher-time-calendar .flex-row-WcGkdi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 7px;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: 2px;
  min-width: 16px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .flex-row-ZMjfPy {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 35px;
  justify-content: flex-start;
  margin-top: 7px;
  min-width: 341px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .flex-row-oV5UrE {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 795px;
  justify-content: flex-start;
  margin-top: 42px;
  position: relative;
  width: 100%;
}
.teacher-time-calendar .flex-row-xiIako {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 38px;
  justify-content: flex-start;
  margin-left: 2.95px;
  min-width: 370px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .flex-row-xr0pJV {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 236px;
  justify-content: flex-start;
  margin-top: 20px;
  min-width: 362px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .go-back-A6SpMB {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 13px;
  min-height: 29px;
  position: relative;
  text-align: left;
  width: auto;
}
.teacher-time-calendar .group-108-Fjgz1C11 {
  background-color: transparent;
  box-sizing: border-box;
  flex-shrink: 1;
  height: auto;
  position: relative;
  min-width: 705px;
  width: 100%;
  display: flex;
}
.teacher-time-calendar .group-card-1 {
  width: 50%;
  display: flex;
}
.teacher-time-calendar .group-card-2 {
  width: 50%;
  display: flex;
}
.teacher-time-calendar .group-109-BwMYwN {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 12px;
  min-height: 363px;
  position: absolute;
  top: 19px;
  width: 391px;
}
.teacher-time-calendar .group-110-QkTteQ {
  background-color: transparent;
  flex-shrink: 1;
  height: 417px;
  margin-top: 100px;
  margin-left: 8%;
  position: relative;
  width: 383px;
}
.teacher-time-calendar .group-111-PXwdVf {
  background-color: transparent;
  height: 448px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.teacher-time-calendar .group-160-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 88px;
  min-width: 24px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-162-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 778px;
  position: absolute;
  top: 0px;
  width: 24px;
}
.teacher-time-calendar .group-163-L8UHM8 {
  background-color: #1f2531;
  height: 1024px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 63px;
}
.teacher-time-calendar .group-164-L8UHM8 {
  background-color: transparent;
  height: 778px;
  left: 20px;
  position: absolute;
  top: 200px;
  width: 24px;
}
.teacher-time-calendar .group-165-C61RwL {
  background-color: transparent;
  flex-shrink: 1;
  height: 1024px;
  position: relative;
  width: 100%;
}
.teacher-time-calendar .group-16Khc2 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-time-calendar .group-1JpEx0 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-1ny3L7 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-time-calendar .group-22-GyJUNH {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 80px;
  justify-content: flex-start;
  margin-left: 22px;
  margin-top: 6px;
  min-width: 316px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-3dScpn {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 45px;
}
.teacher-time-calendar .group-46bm20 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-time-calendar .group-4F35dY {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 25px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-4Z4942 {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 45px;
}
.teacher-time-calendar .group-5lySqH {
  background-color: transparent;
  height: 25px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-time-calendar .group-7QFS1B {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-83Yr4z {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-time-calendar .group-9VbO77 {
  background-color: transparent;
  flex-shrink: 1;
  height: 20px;
  position: relative;
  width: 20px;
}
.teacher-time-calendar .group-9wu4pF {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-BFbMNf {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 28px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-D4n9h3 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 28px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-FHHSuW {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-time-calendar .group-G679iN {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-GaEI1C {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-GlqIOT {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-time-calendar .group-HyFwxc {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 11px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-time-calendar .group-IrjDNq {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 11px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-time-calendar .group-JtzqGz {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-time-calendar .group-LuYcoQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 37px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-M0hcNx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-time-calendar .group-MEBLc8 {
  background-color: transparent;
  height: 25px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-time-calendar .group-MITxpU {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-MYm5hx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-time-calendar .group-MtBasH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-NDHHIo {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 37px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-O4gqum {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-time-calendar .group-PERdpP {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 25px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-R4LQ1d {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 2px;
  justify-content: center;
  left: 13px;
  min-width: 1px;
  position: absolute;
  top: 19px;
  width: auto;
}
.teacher-time-calendar .group-RKWM9h {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-RRI8oF {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-RZLlaE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-S4UQb6 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-SRexLE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-time-calendar .group-STrfv2 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 28px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-SwuvRw {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-time-calendar .group-TUpb5t {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 37px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-UIjmCP {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 45px;
}
.teacher-time-calendar .group-UKbWOd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: auto;
  justify-content: flex-start;
  min-height: 21px;
  position: relative;
  width: 20px;
}
.teacher-time-calendar .group-UVHQ0x {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-UZNSzL {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 4px;
  width: auto;
}
.teacher-time-calendar .group-WTl7eI {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-time-calendar .group-WzpLqr {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-XBeUHU {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-Y5KBOi {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-YXAQmW {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-YjSviP {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-ZXfsGl {
  background-color: transparent;
  height: 25px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-time-calendar .group-a6CDxQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-akFH0G {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 25px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-alxHTq {
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: flex-start;
  left: 0px;
  min-width: 24px;
  padding: 0 11.8px;
  position: absolute;
  top: 0px;
  width: auto;
}
.teacher-time-calendar .group-c2diXx {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 11px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-time-calendar .group-cLQSPR {
  background-color: transparent;
  flex-shrink: 1;
  height: 33px;
  margin-left: 0px;
  position: relative;
  width: 45px;
}
.teacher-time-calendar .group-exxPBD {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-g3mGbQ {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-gMdI0g {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-h0jdqw {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-hYbfWD {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-hkiXFE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 8px;
  position: absolute;
  top: 6px;
  width: auto;
}
.teacher-time-calendar .group-hoHiNP {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 7px;
  min-width: 8px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-i5xXxW {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 21px;
  min-width: 5px;
  position: absolute;
  top: 3px;
  width: auto;
}
.teacher-time-calendar .group-izzKEy {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 3px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-jNxM4e {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-kfoHEV {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 2px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-mq7DiT {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 1px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-neKnjb {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .group-oSfYNE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 6px;
  justify-content: center;
  left: 11px;
  min-width: 4px;
  position: absolute;
  top: 8px;
  width: auto;
}
.teacher-time-calendar .group-odnTUH {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 20px;
  justify-content: flex-start;
  left: 0px;
  min-width: 20px;
  position: absolute;
  top: 448px;
  width: auto;
}
.teacher-time-calendar .group-qNx0vd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-r0VFK4 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 25px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-rf75vt {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-sZYLSI {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 36px;
  min-width: 1px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-tQmilE {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 21px;
  justify-content: flex-start;
  left: 2px;
  min-width: 20px;
  position: absolute;
  top: 1px;
  width: auto;
}
.teacher-time-calendar .group-uBxOa7 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 28px;
  min-width: 2px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-ub80V1 {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: flex-start;
  left: 37px;
  min-width: 3px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-xC4MCy {
  background-color: transparent;
  height: 25px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}
.teacher-time-calendar .group-xajqQV {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 13px;
  min-width: 12px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-yf4YaK {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: center;
  left: 4px;
  min-width: 2px;
  position: absolute;
  top: 20px;
  width: auto;
}
.teacher-time-calendar .group-zM6V3J {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 3px;
  justify-content: center;
  left: 31px;
  min-width: 4px;
  position: absolute;
  top: 10px;
  width: auto;
}
.teacher-time-calendar .group-zPGbNd {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 3px;
  justify-content: center;
  min-width: 2px;
  position: relative;
  width: auto;
}
.teacher-time-calendar .help-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-time-calendar .historytoggleoff-24px-1-KSgdom {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 24px;
  justify-content: flex-start;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-time-calendar .how-to-solve-this-ikg45c {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 40px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 285px;
}
.teacher-time-calendar .join-4-xFMjMJ {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 35px;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0.8px 0;
  position: relative;
  width: 45px;
}
.teacher-time-calendar .join-5-zFQ1K8 {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 35px;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0.8px 0;
  position: relative;
  width: 45px;
}
.teacher-time-calendar .join-6-zFQ1K8 {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 35px;
  justify-content: flex-start;
  margin-left: 0px;
  margin-top: 52px;
  overflow: hidden;
  padding: 0.8px 0;
  position: relative;
  width: 45px;
}
.teacher-time-calendar .join-7-zFQ1K8 {
  align-items: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  height: 35px;
  justify-content: flex-start;
  margin-left: 0px;
  margin-top: 49px;
  overflow: hidden;
  padding: 0.8px 0;
  position: relative;
  width: 45px;
}
.teacher-time-calendar .line-5-qWvJ7Z {
  background-color: transparent;
  height: 104px;
  left: 1px;
  position: absolute;
  top: -1px;
  width: 211px;
}
.teacher-time-calendar .line-6-qWvJ7Z {
  background-color: transparent;
  height: 106px;
  left: -2px;
  position: absolute;
  top: -1px;
  width: 213px;
}
.teacher-time-calendar .notifications-24px-2-1-GyJUNH {
  align-self: center;
  background-color: transparent;
  flex-shrink: 1;
  height: 42px;
  margin-bottom: 13px;
  margin-left: 641px;
  overflow: hidden;
  position: relative;
  width: 42px;
}
.teacher-time-calendar .open-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 619px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 220px;
  width: 37px;
}
.teacher-time-calendar .open-uipJNK {
  background-color: transparent;
  height: auto;
  left: 621px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 340px;
  width: 35px;
}
.teacher-time-calendar .open-your-slots-Fjgz1C {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 40px;
  min-height: 36px;
  position: relative;
  text-align: left;
  width: 100%;
}
.teacher-time-calendar .organic-4-Gu4Ywh {
  background-color: transparent;
  height: auto;
  /* left: 208px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 94px; */
  width: 66px;
}
.teacher-time-calendar .organic-5-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 208px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 276px;
  width: 66px;
}
.teacher-time-calendar .overlap-group-Fjgz1C {
  background-color: transparent;
  flex-shrink: 1;
  height: 348px;
  margin-top: 350px;
  position: relative;
  width: 52%;
}
.teacher-time-calendar .please-check-my-approach-ikg45c {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 2.95px;
  margin-top: 28px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 285px;
}
.teacher-time-calendar .rectangle-31-Gu4Ywh {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 345px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
}

.teacher-time-calendar .rectangle-40-Gu4Ywh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  width: 672px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.teacher-time-calendar .rectangle-41-Gu4Ywh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 15px;
  position: absolute;
  top: 201px;
  width: 672px;
}
.teacher-time-calendar .rectangle-43-BwMYwN {
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 417px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 380px;
}
.teacher-time-calendar .rectangle-44-Gu4Ywh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 15px;
  position: absolute;
  top: 328px;
  width: 672px;
}
.teacher-time-calendar .rectangle-47-Gu4Ywh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 17px;
  position: absolute;
  top: 140px;
  width: 672px;
}
.teacher-time-calendar .rectangle-48-Gu4Ywh {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  height: 45px;
  left: 15px;
  position: absolute;
  top: 261px;
  width: 672px;
}
.teacher-time-calendar .rectangle-7-PXwdVf {
  background-color: transparent;
  height: 1px;
  left: 455px;
  position: absolute;
  top: 92px;
  width: 1px;
}
.teacher-time-calendar .school-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-time-calendar .sections-teachi-g-i650343653102-bRT5et {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 114px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-time-calendar .sections-teachi-g-i650343753102-xE3TZb {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 29px;
  min-width: 145px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-time-calendar .sections-teachi-g-i650343853102-hCvtm5 {
  background-color: transparent;
  height: auto;
  left: 34px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  min-width: 114px;
  width: auto;
}
.teacher-time-calendar .sections-teachi-g-i650343953102-maxTmP {
  background-color: transparent;
  height: auto;
  left: 7px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 25px;
  width: auto;
  min-width: 114px;
}
.teacher-time-calendar .status-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 60%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 64px;
}
.teacher-time-calendar .stickynote2-24px-1-rw4mxC {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}
.teacher-time-calendar .time-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 27%;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 18px;
  width: 54px;
}
.teacher-time-calendar .upcoming-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 394px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 276px;
  width: 68px;
}
.teacher-time-calendar .vector-16Khc2 {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-time-calendar .vector-1JFQDU {
  background-color: transparent;
  height: 2px;
  left: 10px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-time-calendar .vector-1JpEx0 {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-time-calendar .vector-1ny3L7 {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-time-calendar .vector-3Nfv1V {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-time-calendar .vector-3ahjCQ {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-time-calendar .vector-3p3L5x {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-time-calendar .vector-40xw7g {
  background-color: transparent;
  height: 35px;
  left: 7px;
  position: absolute;
  top: 4px;
  width: 28px;
}
.teacher-time-calendar .vector-46bm20 {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 5px;
}
.teacher-time-calendar .vector-4F35dY {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 43px;
}
.teacher-time-calendar .vector-4gLJc3 {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-4xg0yV {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-time-calendar .vector-56AgMz {
  background-color: transparent;
  flex-shrink: 1;
  height: 1px;
  position: relative;
  width: 1px;
}
.teacher-time-calendar .vector-5OLsmu {
  background-color: transparent;
  height: 2px;
  left: 10px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-time-calendar .vector-5lySqH {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-time-calendar .vector-69kxC4 {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-time-calendar .vector-6QcxrY {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-time-calendar .vector-6VlXU0 {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-time-calendar .vector-6cpeOi {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-time-calendar .vector-6eLVlg {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-time-calendar .vector-6f4nV7 {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 1px;
}
.teacher-time-calendar .vector-6gs4Wu {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 3px;
}
.teacher-time-calendar .vector-7NbGmK {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-time-calendar .vector-7PsD3J {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-time-calendar .vector-7XOvaA {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-time-calendar .vector-7sxXQF {
  background-color: transparent;
  height: 25px;
  left: -1px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-time-calendar .vector-7vrTPx {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-time-calendar .vector-7xxTmO {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-time-calendar .vector-83Yr4z {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-time-calendar .vector-8Obj6Z {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-time-calendar .vector-8ZQBrK {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-time-calendar .vector-8k85HE {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 170px;
  position: relative;
  width: 24px;
}
.teacher-time-calendar .vector-90KxxC {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-time-calendar .vector-9MzYLL {
  background-color: transparent;
  height: 25px;
  left: -1px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-time-calendar .vector-9txzyU {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-time-calendar .vector-9wu4pF {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-time-calendar .vector-9xgjqN {
  background-color: transparent;
  height: 14px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 14px;
}
.teacher-time-calendar .vector-A8cxNR {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 10px;
}
.teacher-time-calendar .vector-B8xRgA {
  background-color: transparent;
  height: 16px;
  left: 0px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-time-calendar .vector-BFbMNf {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 11px;
}
.teacher-time-calendar .vector-BKwYFx {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-Bfcjh0 {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 13px;
}
.teacher-time-calendar .vector-CQtYiv {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-time-calendar .vector-CnjbaB {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-time-calendar .vector-CqWgmd {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-time-calendar .vector-CyVeky {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-time-calendar .vector-D4n9h3 {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 11px;
}
.teacher-time-calendar .vector-DxPoMg {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-time-calendar .vector-EEsOKd {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-time-calendar .vector-ESE32V {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 10px;
}
.teacher-time-calendar .vector-EaMiW0 {
  background-color: transparent;
  height: 20px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 18px;
}
.teacher-time-calendar .vector-EbMdYq {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-time-calendar .vector-EjYPkC {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-ElAP8U {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-time-calendar .vector-G4NtpZ {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-time-calendar .vector-G679iN {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 25px;
}
.teacher-time-calendar .vector-G8Rrje {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-time-calendar .vector-GaEI1C {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 25px;
}
.teacher-time-calendar .vector-GlqIOT {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 5px;
}
.teacher-time-calendar .vector-HCu4iF {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 6px;
}
.teacher-time-calendar .vector-HFkt2i {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-time-calendar .vector-HWlTpT {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-time-calendar .vector-HnHijf {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-time-calendar .vector-Hxk7SJ {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-HyFwxc {
  background-color: transparent;
  height: 3px;
  left: 30px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-time-calendar .vector-I6aeJ6 {
  background-color: transparent;
  height: 2px;
  left: 16px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-time-calendar .vector-IrjDNq {
  background-color: transparent;
  height: 3px;
  left: 30px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-time-calendar .vector-JFKabD {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 6px;
}
.teacher-time-calendar .vector-JcirEQ {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 10px;
}
.teacher-time-calendar .vector-JtzqGz {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-time-calendar .vector-KJixgN {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-time-calendar .vector-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  position: relative;
  width: 24px;
}
.teacher-time-calendar .vector-LuYcoQ {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-time-calendar .vector-Lv5MfT {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 13px;
}
.teacher-time-calendar .vector-M0hcNx {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 5px;
}
.teacher-time-calendar .vector-MEBLc8 {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-time-calendar .vector-MH3YcV {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-time-calendar .vector-MITxpU {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-time-calendar .vector-MOHKUD {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 5px;
}
.teacher-time-calendar .vector-MYm5hx {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-time-calendar .vector-N7fzHu {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 3px;
}
.teacher-time-calendar .vector-N9vNKP {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 13px;
}
.teacher-time-calendar .vector-NDHHIo {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-time-calendar .vector-O4gqum {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-time-calendar .vector-OxW8px {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-time-calendar .vector-PERdpP {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 43px;
}
.teacher-time-calendar .vector-PFSxFv {
  background-color: transparent;
  height: 2px;
  left: 16px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-time-calendar .vector-Q3gg4I {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-time-calendar .vector-Q8oaQj {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-time-calendar .vector-R4LQ1d {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 9px;
}
.teacher-time-calendar .vector-RHtQ13 {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-time-calendar .vector-RRI8oF {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 8px;
}
.teacher-time-calendar .vector-RYyMFx {
  background-color: transparent;
  height: 1px;
  left: 21px;
  position: absolute;
  top: 21px;
  width: 1px;
}
.teacher-time-calendar .vector-S4UQb6 {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 11px;
}
.teacher-time-calendar .vector-SCg0eG {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  position: relative;
  width: 24px;
}
.teacher-time-calendar .vector-SIAzDu {
  background-color: transparent;
  height: 20px;
  left: 4px;
  position: absolute;
  top: 2px;
  width: 16px;
}
.teacher-time-calendar .vector-SL9FS5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-SRexLE {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-time-calendar .vector-STrfv2 {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 11px;
}
.teacher-time-calendar .vector-SwuvRw {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-time-calendar .vector-TCNT1O {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-time-calendar .vector-TQWxxn {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-time-calendar .vector-TUpb5t {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-time-calendar .vector-TYKwws {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 1px;
}
.teacher-time-calendar .vector-TYiPVO {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 6px;
}
.teacher-time-calendar .vector-UU5YlW {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-time-calendar .vector-UVHQ0x {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 11px;
}
.teacher-time-calendar .vector-UXmNIq {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-UZNSzL {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 5px;
}
.teacher-time-calendar .vector-UgkvXI {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-time-calendar .vector-UxT80r {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-time-calendar .vector-VRx09Q {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-time-calendar .vector-W8MsGc {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-time-calendar .vector-WTl7eI {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-time-calendar .vector-WcGkdi {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 20px;
}
.teacher-time-calendar .vector-Wm5rXL {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-time-calendar .vector-WxSlYB {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-time-calendar .vector-WzpLqr {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-time-calendar .vector-XAXDzJ {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 1px;
}
.teacher-time-calendar .vector-XT2BP4 {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-time-calendar .vector-XV2kdn {
  background-color: transparent;
  height: 18px;
  left: 1px;
  position: absolute;
  top: 3px;
  width: 22px;
}
.teacher-time-calendar .vector-XYtD88 {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-time-calendar .vector-XZC1s0 {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-time-calendar .vector-XpPdbp {
  background-color: transparent;
  height: 25px;
  left: -1px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-time-calendar .vector-YFf26F {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-time-calendar .vector-YXAQmW {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 25px;
}
.teacher-time-calendar .vector-YjSviP {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 11px;
}
.teacher-time-calendar .vector-ZPLvJz {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-ZXfsGl {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-time-calendar .vector-ZvegJc {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-time-calendar .vector-a4MdRB {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-time-calendar .vector-a6CDxQ {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-time-calendar .vector-akFH0G {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 43px;
}
.teacher-time-calendar .vector-bgPlld {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-time-calendar .vector-c2diXx {
  background-color: transparent;
  height: 3px;
  left: 30px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-time-calendar .vector-cBmfXh {
  background-color: transparent;
  height: 7px;
  left: 13px;
  position: absolute;
  top: 27px;
  width: 14px;
}
.teacher-time-calendar .vector-cLNMqB {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-time-calendar .vector-cZxuGw {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-time-calendar .vector-dWDJ7d {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-dlRKJA {
  background-color: transparent;
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}
.teacher-time-calendar .vector-eEaWoc {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-time-calendar .vector-eZpKdd {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 1px;
}
.teacher-time-calendar .vector-er0y5C {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-time-calendar .vector-exxPBD {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-time-calendar .vector-fPPgx3 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-time-calendar .vector-fY7aUj {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-time-calendar .vector-fhuBX1 {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 13px;
}
.teacher-time-calendar .vector-g3mGbQ {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 8px;
}
.teacher-time-calendar .vector-gJT1mR {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-time-calendar .vector-gMdI0g {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-time-calendar .vector-grLYV9 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-time-calendar .vector-gzAway {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-time-calendar .vector-hLDWH3 {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-time-calendar .vector-hevJWS {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-time-calendar .vector-hijk5f {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-time-calendar .vector-hkiXFE {
  background-color: transparent;
  height: 17px;
  left: 13px;
  position: absolute;
  top: 17px;
  width: 23px;
}
.teacher-time-calendar .vector-hmx0lh {
  background-color: transparent;
  height: 2px;
  left: 16px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-time-calendar .vector-hoHiNP {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-time-calendar .vector-i5xXxW {
  background-color: transparent;
  height: 6px;
  left: 8px;
  position: absolute;
  top: 13px;
  width: 13px;
}
.teacher-time-calendar .vector-iniHBi {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-time-calendar .vector-iuXovq {
  background-color: transparent;
  height: 11px;
  left: 17px;
  position: absolute;
  top: 0px;
  width: 10px;
}
.teacher-time-calendar .vector-jNxM4e {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-time-calendar .vector-jWA1w5 {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: 2px;
  position: relative;
  width: 20px;
}
.teacher-time-calendar .vector-jqOJ4Y {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-time-calendar .vector-kCLhu1 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-time-calendar .vector-kPHf5o {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-time-calendar .vector-kUM3e4 {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}
.teacher-time-calendar .vector-kfoHEV {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 11px;
}
.teacher-time-calendar .vector-kopF7z {
  background-color: transparent;
  height: 2px;
  left: 16px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-time-calendar .vector-l3I5eu {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-lLKDIK {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-time-calendar .vector-ldvHNF {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-mhgmlc {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-top: -0.55px;
  position: relative;
  width: 5px;
}
.teacher-time-calendar .vector-mju0OS {
  background-color: transparent;
  height: 16px;
  left: 4px;
  position: absolute;
  top: 0px;
  width: 16px;
}
.teacher-time-calendar .vector-oRqxWx {
  background-color: transparent;
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}
.teacher-time-calendar .vector-oSfYNE {
  background-color: transparent;
  height: 3px;
  left: 30px;
  position: absolute;
  top: 17px;
  width: 4px;
}
.teacher-time-calendar .vector-oVHuau {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-time-calendar .vector-oXEhCC {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-time-calendar .vector-oxSxlc {
  background-color: transparent;
  height: 2px;
  left: 10px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-time-calendar .vector-pAkFAe {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.teacher-time-calendar .vector-pkW65H {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-pmFxn8 {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 7px;
}
.teacher-time-calendar .vector-qFGBM2 {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 6px;
}
.teacher-time-calendar .vector-qGODdx {
  background-color: transparent;
  height: 2px;
  left: 10px;
  position: absolute;
  top: 9px;
  width: 2px;
}
.teacher-time-calendar .vector-qNx0vd {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-time-calendar .vector-r0VFK4 {
  background-color: transparent;
  height: 24px;
  left: -0px;
  position: absolute;
  top: 3px;
  width: 43px;
}
.teacher-time-calendar .vector-rYz2hx {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 3px;
}
.teacher-time-calendar .vector-rf75vt {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 8px;
}
.teacher-time-calendar .vector-s2Y5x6 {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-sIliKK {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-time-calendar .vector-sOJHEx {
  background-color: transparent;
  height: 7px;
  left: 11px;
  position: absolute;
  top: 7px;
  width: 7px;
}
.teacher-time-calendar .vector-sTxJeP {
  background-color: transparent;
  height: 2px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 5px;
}
.teacher-time-calendar .vector-sZYLSI {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 8px;
}
.teacher-time-calendar .vector-suqQAx {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-tZZeFM {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 8px;
  width: 10px;
}
.teacher-time-calendar .vector-uBxOa7 {
  background-color: transparent;
  height: 5px;
  left: 29px;
  position: absolute;
  top: 14px;
  width: 11px;
}
.teacher-time-calendar .vector-uSqcr8 {
  background-color: transparent;
  height: 25px;
  left: -1px;
  position: absolute;
  top: 0px;
  width: 8px;
}
.teacher-time-calendar .vector-ub80V1 {
  background-color: transparent;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 9px;
}
.teacher-time-calendar .vector-uvLEpZ {
  background-color: transparent;
  height: 12px;
  left: 1px;
  position: absolute;
  top: 7px;
  width: 27px;
}
.teacher-time-calendar .vector-v1lfks {
  background-color: transparent;
  flex-shrink: 1;
  height: 3px;
  margin-top: -0.46px;
  position: relative;
  width: 3px;
}
.teacher-time-calendar .vector-vGILXb {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 2px;
}
.teacher-time-calendar .vector-vgli2x {
  background-color: transparent;
  flex-shrink: 1;
  height: 80px;
  margin-left: 9px;
  position: relative;
  width: 80px;
}
.teacher-time-calendar .vector-wxTjfr {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 9px;
}
.teacher-time-calendar .vector-x3pNXh {
  background-color: transparent;
  height: 2px;
  left: 23px;
  position: absolute;
  top: 21px;
  width: 3px;
}
.teacher-time-calendar .vector-x60R6G {
  background-color: transparent;
  flex-shrink: 1;
  height: 4px;
  margin-top: -0.36px;
  position: relative;
  width: 4px;
}
.teacher-time-calendar .vector-xC4MCy {
  background-color: transparent;
  height: 35px;
  left: 5px;
  position: absolute;
  top: -1px;
  width: 39px;
}
.teacher-time-calendar .vector-xE0Qjy {
  background-color: transparent;
  height: 4px;
  left: 11px;
  position: absolute;
  top: 6px;
  width: 4px;
}
.teacher-time-calendar .vector-xajqQV {
  background-color: transparent;
  height: 18px;
  left: 22px;
  position: absolute;
  top: 2px;
  width: 21px;
}
.teacher-time-calendar .vector-xbu9jm {
  background-color: transparent;
  flex-shrink: 1;
  height: 7px;
  margin-left: 3px;
  position: relative;
  width: 4px;
}
.teacher-time-calendar .vector-xmLuZq {
  background-color: transparent;
  flex-shrink: 1;
  height: 2px;
  margin-top: -0.48px;
  position: relative;
  width: 2px;
}
.teacher-time-calendar .vector-xnrUqI {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 7px;
}
.teacher-time-calendar .vector-xxuhvU {
  background-color: transparent;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 12px;
  width: 4px;
}
.teacher-time-calendar .vector-yQhZ26 {
  background-color: transparent;
  height: 1px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 1px;
}
.teacher-time-calendar .vector-yf4YaK {
  background-color: transparent;
  height: 28px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 43px;
}
.teacher-time-calendar .vector-zM6V3J {
  background-color: transparent;
  height: 15px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 25px;
}
.teacher-time-calendar .view-all-PxY2KT {
  background-color: transparent;
  color: var(--gray);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-bottom: 2px;
  margin-left: 131px;
  min-height: 29px;
  position: relative;
  text-align: left;
  width: 74px;
}
.teacher-time-calendar .view-forum-1W4WGH {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2.95px;
  margin-top: 8px;
  min-height: 25px;
  position: relative;
  text-align: left;
  width: 111px;
}
.teacher-time-calendar .view-forum-cpKS9R {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: 8px;
  min-height: 25px;
  position: relative;
  text-align: left;
  width: 111px;
}
.teacher-time-calendar .view-forum-ikg45c {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 2.95px;
  margin-top: 8px;
  min-height: 25px;
  position: relative;
  text-align: left;
  width: 111px;
}
.teacher-time-calendar .view-forum-xiIako {
  align-self: flex-end;
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 13.75px;
  margin-top: 2px;
  min-height: 25px;
  position: relative;
  text-align: left;
  width: 111px;
}
.teacher-time-calendar .what-is-the-si-unit-of-current-xFMjMJ {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 10px;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 285px;
}
.teacher-time-calendar .what-is-the-sol-f-this-question-ikg45c {
  background-color: transparent;
  flex-shrink: 1;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  min-height: 23px;
  position: relative;
  text-align: left;
  width: 285px;
}
.teacher-time-calendar .x12pm-Gu4Ywh {
  background-color: transparent;
  height: auto;
  /* left: 32px; */
  letter-spacing: 0px;
  line-height: normal;
  /* position: absolute; */
  text-align: left;
  /* top: 94px; */
  width: 38px;
}
.teacher-time-calendar .x1pm-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 158px;
  width: 31px;
}
.teacher-time-calendar .x2pm-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 218px;
  width: 31px;
}
.teacher-time-calendar .x3pm-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 275px;
  width: 31px;
}

.teacher-time-calendar .historytoggleoff-24px-1-D0BVcB {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-time-calendar .quiz-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  margin-top: 32px;
  overflow: hidden;
  position: relative;
  width: 24px;
}

.teacher-time-calendar .poweroff-24px-1-KSgdom {
  background-color: transparent;
  flex-shrink: 1;
  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;
  margin-top: 100px;
}

.teacher-time-calendar .x4-i650343653103-bRT5et {
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-right: 18.23px;
  margin-top: 25px;
  min-height: 78px;
  min-width: 77px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-time-calendar .x4-i650343753103-xE3TZb {
  align-self: center;
  background-color: transparent;
  color: var(--blue-zodiac);
  flex-shrink: 1;
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  letter-spacing: 0px;
  line-height: normal;
  margin-left: 2.43px;
  margin-top: 25px;
  min-height: 78px;
  min-width: 77px;
  position: relative;
  text-align: center;
  width: auto;
}
.teacher-time-calendar .x4-i650343853103-hCvtm5 {
  background-color: transparent;
  color: var(--blue-zodiac);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 42px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 79px;
  width: auto;
}
.teacher-time-calendar .x4-i650343953103-maxTmP {
  background-color: transparent;
  color: var(--niagara);
  font-family: "Montserrat", Helvetica;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  left: 48px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 79px;
  width: auto;
}
.teacher-time-calendar .x4pm-Gu4Ywh {
  background-color: transparent;
  height: auto;
  left: 32px;
  letter-spacing: 0px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 340px;
  width: 31px;
}
:root {
  --blue-zodiac: rgba(19, 37, 71, 1);
  --crusta: rgba(249, 129, 49, 1);
  --gray: rgba(126, 126, 126, 1);
  --niagara: rgba(5, 187, 138, 1);
  --white: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 599px) {
  .teacher-time-calendar {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .teacher-calendar-time-mobile {
    display: none;
  }
}

@media screen and (max-width: 930px) {
  .teacher-time-calendar {
    height: 1600px;
  }
  .teacher-time-calendar .flex-row-oV5UrE {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }
  .teacher-time-calendar .flex-col-QkTteQ11 {
    width: 80%;
  }
  .teacher-time-calendar .overlap-group-Fjgz1C {
    width: 80%;
    margin-top: 600px;
  }
  .teacher-time-calendar .group-110-QkTteQ {
    width: 80%;
    margin-top: 30px;
  }
  .teacher-time-calendar .group-108-Fjgz1C11 {
    flex-direction: column;
    margin-left: 50px;
    height: auto;
  }
  .teacher-time-calendar .group-card-2 {
    margin-top: 40px;
  }
  .teacher-time-calendar .component-6-gty3z9 {
    margin-left: 0;
  }
}
