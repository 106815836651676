.fame, .about {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: x-large;
    text-align: center;
}

.faculty-details-1 p {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    padding: 20px;
    font-weight: 300;
    
}

.detail-card {
    line-height: 1.25;
}

.faculty-details-1 .crd {
    width: 80%;
    display: inline-block;
    margin: 0 0 80px 140px;
}

@media screen and (min-width: 992px) and (max-width: 1390px) {
   .faculty-details-1 .crd {
       margin: 0 0 80px 90px !important; 
   }
}

@media screen and (min-width: 830px) and (max-width: 992px) {
    .faculty-details-1 .crd {
        margin: 0 0 80px 50px !important; 
    }
 }

 @media screen and (min-width: 720px) and (max-width: 830px) {
    .faculty-details-1 .crd {
        margin: 0 0 80px 40px !important; 
    }

    .faculty-details-1 .crdbody {
        padding: 20px !important;
    }

 }

 @media screen and (max-width: 720px) {
     .faculty-details-1 .crd {
         display: block !important;
         margin: 0 auto 80px auto !important;
     }

     .faculty-details-1 .innercrd {
         margin: 0 auto !important;
     }
 }

.faculty-details-1 #teachers {
    margin-bottom: 50px;
}

.login-modal {
    width: 40%;
    height: 500px;
    margin: auto;
    margin-top: 120px;
    background-color: whitesmoke;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    padding: 24px 0;
  }
  
  .login-modal .login-modal-title {
    width: 70%;
    margin: auto;
    text-align: center;
    margin-bottom: 24px;
  }