.rectangle-20-Cd7ClN-in-accounts-employee {
    background-color: #dc3545;
    border-radius: 29px;
    height: 53px;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 1131px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rectangle-30-y1HRxC-10-c-accounts-employee {
    background-color: var(--white);
    border-radius: 20px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    height: 555px;
    width: 1150px;
    padding: 10px;
    margin-top: 30px;
}
.group-56-y1HRxC-9-ew-accounts-employee {
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    height: 36px;
    width: 1110px;
    margin-top: 20px;
    padding: 10px 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 4px;
}
.group-203-dlP8Ul-employee {
  align-items: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  left: 0px;
  min-height: 794px;
  position: absolute;
  top: 0px;
  width: 737px;
  margin-top: auto;
}
.body_accounts-employee {
  /* align-items: flex-end; */
  background-color: transparent;
  box-sizing: border-box;
  display: block;
  /* flex-direction: column;
  flex-shrink: 1; */
  height: 1520px;
  width: 100%;
  justify-content: flex-start;
  margin-left: 70px;
    margin-top: 42px;
}
.list-of-students-5GLwEc-accounts-employee{
    background-color: transparent;
    color: #dc3545;
    flex-shrink: 1;
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    height: auto;
    letter-spacing: 0px;
    line-height: normal;
    min-height: 26px;
    position: relative;
    text-align: left;
    width: 868px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin-top: 67px;
}
.list-of-students-5GLwEc-accounts-employee-mob {
    background-color: transparent;
    color: #dc3545;
    flex-shrink: 1;
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    height: auto;
    letter-spacing: 0px;
    line-height: normal;
    min-height: 26px;
    position: relative;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin-top: 67px;
}
.table-scr {
  width: 100%;
  overflow: auto;
}
.group-45-5GLwEc-ly-employee {
  background-color: transparent;

  height: 53px;
  margin-top: 19px;
  position: relative;
  width: 737px;
}
.form-group{
  margin-top: 7%;
}
