.hc_detail_layout {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.div-hc-tech-modal {
  position: absolute;
  top: 25%;

  z-index: 700;

  width: 95%;
  max-width: 370px;
  height: 310px;

  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;

  transition: transform 0.3s ease-out;
}

.icon-to-cancel-modal {
  align-self: flex-end;
  color: rgb(224, 224, 224);
  margin: 0px 0px 10px 5px;
  cursor: pointer;
}

.Display-in-tech {
  transform: translateY(0);
}

.Display-out-tech {
  transform: translateY(-240%);
}

.header-wdt-100 {
  width: 100%;
}

.sider-hc-detail {
  width: 50px;
  height: 100%;
  position: fixed;
  overflow: hidden;

  background-color: #1f2531;
}

.content-in-hc-layout {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.status1 {
  padding: 7px;
  width: 90%;
  margin-top: 20px;
  background-color: #dc3545;

  box-shadow: 0px 0px 1px 1px rgb(233, 230, 230);
  border-radius: 20px;

  display: flex;
  justify-content: space-between;
}

.sidelink {
  display: block;
  color: white;
  text-decoration: none;
  padding: 12px;
  height: 80px;
}

.hc-form-in-wdt-align-tech-23 {
  width: 100%;
  max-width: 310px;
  height: 240px;
  margin: 10px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.butt-in-hc-form-4px-tech {
  /* width: 125px;
  height: 30px;
  margin: 10px 0px;

  border-radius: 8px;
  color: white;
  background-color: rgb(220, 53, 69);
  border: none;
  box-shadow: 0px 2px 2px #ffff; */

  margin: 10px 0px;
  width: 125px;
  height: 30px;

  color: white;
  background-color: #05bb8a;
  border: 1px solid #06091b;
  box-shadow: 0px 4px 4px rgb(167, 164, 164);
  border-radius: 10px;
}

@media screen and (max-width: 841px) {
  .sider-hc-detail {
    display: none;
  }
}

@media screen and (min-width: 840px) {
  .header-wdt-100 {
    display: none;
  }

  .div-hc-tech-modal {
    position: absolute;
    top: 25%;
    left: 37%;
    width: 465px;
    height: 320px;
    padding: 5px;
  }

  .hc-form-in-wdt-align-tech-23 {
    font-weight: 700;
    height: 250px;
    max-width: 320px;
  }

  .butt-in-hc-form-4px-tech {
    width: 150px;
    font-weight: 700;
  }

  .hc_detail_layout {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .content-in-hc-layout {
    width: 100%;
    margin-left: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

/* .Header {
  z-index: -10;
  background: white;
  height: 52px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin: 1.2em;
  font-size: 18px;
}

.heder-men-not {
  font-size: 18px;

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.notification {
  margin: 1em;
}

.menu {
  margin: 1.2em;
} */
